import { Combobox } from '@headlessui/react'
import classNames from 'classnames'
import { useEffect, useState } from 'react'

import ComboboxOptionButton from '../shared/ComboboxOptionButton'
import { ComboboxOptionItem } from '../shared/ComboboxOptionItem'

import { ComboBoxBaseProps } from '@/modules/shared/components/combobox/types'

interface ComboboxClientProps<DataType> extends ComboBoxBaseProps<DataType> {
  keyFilter: keyof DataType
  resetSelectItem?: number
}

function ComboboxClient<T>(props: ComboboxClientProps<T>) {
  const {
    placeholder,
    keyFilter,
    keyExtractor,
    loading,
    disabled,
    className,
    onSelected,
    defaultValue,
    hasError,
    errorMessage,
    items = [],
    testId,
    resetSelectItem,
  } = props

  const [query, setQuery] = useState('')
  const [selectedItem, setSelectedItem] = useState<T | null>(null)

  const filteredItems = () => {
    if (!query) return items

    return items.filter((item) => {
      const val = String(item[keyFilter])
      return val.toLowerCase().includes(query.toLowerCase())
    })
  }

  const getSelectedItem = (item: T) => {
    return item && String(item[keyFilter])
  }

  useEffect(() => {
    if (selectedItem) {
      onSelected(selectedItem)
    }
  }, [selectedItem])

  useEffect(() => {
    if (defaultValue) {
      setSelectedItem(defaultValue)
    }
  }, [defaultValue])

  useEffect(() => {
    if (resetSelectItem) {
      setSelectedItem(null)
    }
  }, [resetSelectItem])

  return (
    <Combobox
      as="div"
      className={classNames(className, 'w-full')}
      disabled={disabled || loading}
      value={selectedItem}
      onChange={setSelectedItem}
    >
      <div className={classNames('relative', 'mt-1', { 'pb-1': hasError })}>
        {/* Headless UI does not support open on focus so this is just a workaround by wrapping `Combobox.Input` with `Combobox.Button` as a `div` */}
        <Combobox.Button as="div">
          <Combobox.Input
            aria-invalid={hasError ? 'true' : 'false'}
            autoComplete="off"
            data-testid={`combobox-input${testId ? `-${testId}` : ''}`}
            className={classNames(
              'w-full rounded-md border border-gray-300 py-3 pl-3 pr-10 shadow-sm transition focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary sm:text-sm',
              {
                'bg-gray-200/50': loading,
                'text-gray-300': loading,
                'cursor-not-allowed': disabled,
                'border-error': hasError,
                'bg-white': !disabled || loading,
                'bg-gray-200': disabled,
              }
            )}
            onChange={(event) => setQuery(event.target.value)}
            placeholder={placeholder}
            displayValue={(item: T) => getSelectedItem(item)}
          />
        </Combobox.Button>

        <ComboboxOptionButton testId={testId} disabled={disabled} loading={loading} />

        {filteredItems().length > 0 && (
          <Combobox.Options
            data-testid={`options-wrapper${testId ? `-${testId}` : ''}`}
            className="absolute z-10 mt-1 max-h-72 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-primary/5 focus:outline-none sm:text-sm"
          >
            {filteredItems().map((item, index) => (
              <ComboboxOptionItem
                key={`combobox-option-item-${index}`}
                item={item}
                testId={testId}
                keyExtractor={keyExtractor}
                getSelectedItem={getSelectedItem}
                isClient={true}
              />
            ))}
          </Combobox.Options>
        )}
      </div>
      {hasError && (
        <span className="text-sm text-error" role="alert">
          {errorMessage}
        </span>
      )}
    </Combobox>
  )
}

export default ComboboxClient
