import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { handleSupplierConnectModalText } from './SupplierDirectoryUtils'

import { Supplier } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Button, Modal } from '@/modules/shared/components'
import SupplierDetails from '@/modules/shared/components/supplier-details'
import { NewTabIcon } from '@/modules/shared/icons'

interface SupplierConnectModalProps {
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<number | null>>
  supplier: Supplier
}

function SupplierConnectModal({ showModal, setShowModal, supplier }: SupplierConnectModalProps) {
  const { name } = supplier
  const { t } = useTranslation()

  const onCloseModal = () => {
    setShowModal(null)
  }

  const { connectModalTitle, connectModalInfo } = handleSupplierConnectModalText(supplier as Supplier, name)

  return (
    <Modal showModal={showModal} onCloseModal={onCloseModal} dataTestId="supplier-connect-modal">
      <Modal.Panel
        as="div"
        className="flex max-h-[80vh] w-full flex-col overflow-hidden rounded-md bg-white shadow-xl transition-all md:max-w-[600px]"
      >
        <Modal.Title title={connectModalTitle || ''} onCloseModal={onCloseModal} />

        <Modal.Body>
          {!supplier?.supplierRelationship ? (
            <>
              <p className="font-bold">
                {t('supplierDirectory.connectModal.connectSubtitle', 'Initiate Supplier Connection')}
              </p>
              <p className="text-gray-500">
                {t(
                  'supplierDirectory.connectModal.connectInfo',
                  'To initiate a trading relationship with this Supplier, please contact the Supplier using the details below. The supplier will require you to formally open an account with them outside of this system in order to begin trading. Once the Supplier has agreed to start a trading relationship with you, you will find them in your My Suppliers area, and you can begin using the Supplier in your Requisitions.'
                )}
              </p>
              <SupplierDetails supplier={supplier} />
              <p className="mt-5 font-bold">
                {t('supplierDirectory.connectModal.connectCredit', 'Credit Application')}
              </p>
              <p className="text-gray-500">
                {t(
                  'supplierDirectory.connectModal.connectCreditInfo',
                  'The Supplier can choose to provide a link here to their Credit Application Form. If a link has been provided, you can follow the link, complete the form and submit it directly to the Supplier to get your trade relationship initiated.'
                )}
              </p>
              {supplier?.creditApplicationUrl ? (
                <a
                  href={supplier?.creditApplicationUrl}
                  target="_blank"
                  rel="noreferrer"
                  className="mt-3 flex cursor-pointer items-center justify-between rounded-md border border-primary p-4 text-primary"
                >
                  <p>{t('supplierDirectory.connectModal.connectCreditLink', 'Visit Credit Application Link')}</p>
                  <NewTabIcon className="h-6 w-6" />
                </a>
              ) : (
                <div className="mt-2 w-full rounded-md border bg-gray-100 px-3 py-5 text-center text-gray-500">
                  {t(
                    'supplierDirectory.connectModal.connectCreditNoLink',
                    'The Supplier has not provided a link. Please contact the Supplier.'
                  )}
                </div>
              )}
              <div className="mt-5 rounded-md bg-gray-100 px-5 py-4">
                <p className="font-bold">{t('supplierDirectory.connectModal.connectWhatNext', 'What Happens Next?')}</p>
                <p>
                  {t(
                    'supplierDirectory.connectModal.connectWhatNextInfo',
                    'Once the Supplier has agreed to trade with your organisation they will add you as a Purchaser and assign trading catalogues to you that contain the products and prices you have agreed they will supply to you. Once this is complete your system administrator can enable the Supplier, and you can begin trading with the Supplier.'
                  )}
                </p>
              </div>
            </>
          ) : (
            <>
              <div className="rounded-md bg-gray-100 px-5 py-4">
                {connectModalInfo}
                {!supplier?.supplierRelationship?.isEnabled && (
                  <>
                    <a
                      href="https://www.purchaseplus.com/contact-us"
                      target="_blank"
                      rel="noreferrer"
                      className="cursor-pointer text-primary"
                    >
                      {t('supplierDirectory.connectModal.disabledInfoLink', 'friendly customer success team')}
                    </a>
                    {t(
                      'supplierDirectory.connectModal.disabledInfoEnd',
                      ' if you wish to have this Supplier re-enabled and begin trading with them again.'
                    )}
                  </>
                )}
              </div>
              <p className="mt-5 font-bold">
                {t('supplierDirectory.connectModal.supplierDetails', 'Supplier Details')}
              </p>
              <p className="text-gray-500">
                {t(
                  'supplierDirectory.connectModal.supplierDetailsInfo',
                  'Here are the contact details for this Supplier.'
                )}
              </p>
              <SupplierDetails supplier={supplier} />
            </>
          )}
        </Modal.Body>
        <Modal.Footer className="flex items-center justify-end">
          <Button
            type="button"
            className="h-11 w-full rounded-md bg-gray-200 px-8 text-sm md:w-fit"
            onClick={onCloseModal}
            data-testid="supplier-connect-close"
          >
            {t('supplierDirectory.connectModal.close', 'Close')}
          </Button>
        </Modal.Footer>
      </Modal.Panel>
    </Modal>
  )
}

export default SupplierConnectModal
