import { useQuery } from '@apollo/client'
import { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Link } from 'react-router-dom'
import { StringParam, useQueryParam, withDefault } from 'use-query-params'

import TableView from './table-view'
import TileView from './tile-view'

import EmptyInvoices from '@/assets/images/empty-invoices.svg'
import {
  GetAllInvoicesDocument,
  Invoice,
  RansackDirection,
  RansackMatcher,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { ProductListEmptyState, Spinner } from '@/modules/requisitions/components'
import { Breadcrumb, FilterTabs, MoreOptionsMenu, QueryResult, TitleBar, Tooltip } from '@/modules/shared/components'
import AdvancedSearch from '@/modules/shared/components/advanced-search/AdvancedSearch'
import SearchAndFilter, { OnFilter } from '@/modules/shared/components/search-input/SearchAndFilter'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { useWindowSize } from '@/modules/shared/hooks'
import { HomeIcon, InvoiceIcon, OptionIcon, PlusIcon } from '@/modules/shared/icons'
import SearchContainer from '@/modules/shared/search-container'
import { Document } from '@/modules/shared/types'
import { FilterTabItem, QueryParameter } from '@/modules/shared/types'
import { changeDateForQuery, checkNetworkStatus, extractEdges } from '@/modules/shared/utils'

export default function AllInvoices() {
  const { t } = useTranslation()
  const { isLargeScreen } = useWindowSize()

  const [searchTermURLParam] = useQueryParam(QueryParameter.Search, StringParam)
  const [filterURLParam] = useQueryParam(QueryParameter.Status, withDefault(StringParam, 'all'))
  const [fromDateURLParam] = useQueryParam(QueryParameter.From, StringParam)
  const [toDateURLParam] = useQueryParam(QueryParameter.To, StringParam)
  const [showAdvancedSearch, setShowAdvancedSearch] = useState<boolean>(!!fromDateURLParam || !!toDateURLParam)

  const { data, networkStatus, error, refetch, fetchMore } = useQuery(GetAllInvoicesDocument, {
    variables: {
      after: null,
      filter: {
        q: [
          {
            property: 'invoice_number_or_reference_cont',
            value: searchTermURLParam ?? '',
          },
          {
            property: 'state_eq',
            value: filterURLParam === 'all' ? '' : filterURLParam,
          },
          {
            property: 'invoiceDate_gt',
            value: changeDateForQuery(QueryParameter.From, fromDateURLParam),
          },
          {
            property: 'invoiceDate_lt',
            value: changeDateForQuery(QueryParameter.To, toDateURLParam),
          },
        ],
      },
      sort: [
        {
          property: 'invoiceDate',
          direction: RansackDirection.Desc,
        },
      ],
    },
    context: { uri: PURCHASING_GRAPHQL_API },
    notifyOnNetworkStatusChange: true,
  })

  const { setVariablesLoading, refetchLoading, loading } = checkNetworkStatus(networkStatus)

  const filterTabs: FilterTabItem[] = [
    {
      key: t('invoices.allInvoices.filter.viewAll', 'View All'),
      // Not an actual state
      filterValue: 'all',
    },
    {
      key: t('invoices.allInvoices.filter.open', 'Open'),
      filterValue: 'open',
    },
    {
      key: t('invoices.allInvoices.filter.flagged', 'Flagged'),
      filterValue: 'flagged',
    },
    {
      key: t('invoices.allInvoices.filter.reconciled', 'Reconciled'),
      filterValue: 'reconciled',
    },
    {
      key: t('invoices.allInvoices.filter.processing', 'Processing'),
      filterValue: 'processing',
    },
    {
      key: t('invoices.allInvoices.filter.processed', 'Processed'),
      filterValue: 'processed',
    },
    {
      key: t('invoices.allInvoices.filter.cancelled', 'Cancelled'),
      filterValue: 'cancelled',
    },
    {
      key: t('invoices.allInvoices.filter.parked', 'Parked'),
      filterValue: 'parked',
    },
  ]

  const invoices = extractEdges<Invoice>(data?.currentPurchaser?.invoices)

  const onFetchMoreRequisitions = () => {
    fetchMore({
      variables: {
        after: data?.currentPurchaser?.invoices?.pageInfo.endCursor,
      },
    })
  }

  const onFilter = ({ searchValue, filterValue, fromDate, toDate, supplierIds }: OnFilter) => {
    let filter: RansackMatcher[] = []
    if (searchValue) {
      filter.push({
        property: 'invoice_number_or_reference_cont',
        value: searchValue.trim(),
      })
    }
    if (filterValue) {
      filter.push({
        property: 'state_eq',
        value: filterValue === 'all' ? '' : filterValue,
      })
    }
    if (fromDate) {
      filter.push({
        property: 'invoiceDate_gt',
        value: fromDate,
      })
    }
    if (toDate) {
      filter.push({
        property: 'invoiceDate_lt',
        value: toDate,
      })
    }
    if (supplierIds) {
      filter.push({
        property: 'supplier_id_in',
        value: supplierIds,
      })
    }
    refetch({
      filter: {
        q: [...filter],
      },
    })
  }

  return (
    <>
      <Helmet>
        <title>{t('invoices.allInvoices.pageTitle', 'All Invoices')}</title>
      </Helmet>

      <main className="mx-auto space-y-5 px-4">
        <Breadcrumb className="overflow-x-auto rounded-md bg-gray-200 px-3 py-2">
          <Breadcrumb.Item icon={HomeIcon} href="/dashboard"></Breadcrumb.Item>
          <Breadcrumb.Item href="/invoices">{t('breadcrumbs.invoices', 'Invoices')}</Breadcrumb.Item>
          <Breadcrumb.Item>{filterTabs.find((e) => e.filterValue === filterURLParam)?.key}</Breadcrumb.Item>
        </Breadcrumb>
        <section className="space-y-2">
          <TitleBar
            icon={<InvoiceIcon className="h-10 w-10" />}
            title={t('invoices.allInvoices.pageTitle', 'All Invoices')}
            subtitle={t('invoices.allInvoices.viewAllInvoices', 'View All Invoices')}
          >
            <div className="flex items-center gap-x-2">
              <Tooltip content={t('createInvoice.createTooltip', 'Create a New Invoice')}>
                <Link to="/invoices/create">
                  <PlusIcon className="h-11 w-11 cursor-pointer rounded-full bg-gray-200 p-1 text-gray-600" />
                </Link>
              </Tooltip>
              <MoreOptionsMenu showHelp showOpenInPPlus allInvoices>
                <OptionIcon className="h-11 w-11 rounded-full bg-gray-200 p-1.5 text-gray-600 outline-none hover:brightness-95" />
              </MoreOptionsMenu>
            </div>
          </TitleBar>
          <FilterTabs tabs={filterTabs} />
        </section>
        <SearchContainer showAdvancedSearch={showAdvancedSearch} setShowAdvancedSearch={setShowAdvancedSearch}>
          <SearchAndFilter
            testId="invoices-search-input"
            placeholder={t('invoices.allInvoices.searchPlaceholder', 'Search by Invoice Number or Reference')}
            ariaLabel={t('invoices.allInvoices.ariaLabel', 'Searching for Invoices')}
            onFilter={onFilter}
            queryParamFilterType={QueryParameter.Status}
          />
        </SearchContainer>
        {showAdvancedSearch && <AdvancedSearch type={Document.Invoice} showSupplier />}
        <section>
          <QueryResult loading={loading || refetchLoading || setVariablesLoading} error={error}>
            {data?.currentPurchaser && (
              <>
                <InfiniteScroll
                  dataLength={invoices.length}
                  next={onFetchMoreRequisitions}
                  hasMore={!!data?.currentPurchaser?.invoices?.pageInfo.hasNextPage}
                  loader={<Spinner className="mt-5 h-14 md:w-16" />}
                >
                  {isLargeScreen ? <TableView invoices={invoices} /> : <TileView invoices={invoices} />}
                </InfiniteScroll>
                {!setVariablesLoading && invoices.length === 0 && (
                  <ProductListEmptyState
                    image={EmptyInvoices}
                    message={t('invoices.allInvoices.noInvoices', "We couldn't find any Invoices to display.")}
                  />
                )}
              </>
            )}
          </QueryResult>
        </section>
        <div className="h-96" />
      </main>
    </>
  )
}
