import classNames from 'classnames'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

export interface VarianceDisplayProps {
  value: string
  totalHasVariance: boolean
}

const VarianceDisplay = ({ value, totalHasVariance }: VarianceDisplayProps) => {
  const { t } = useTranslation()
  const showValue = Number(value) !== 0 && totalHasVariance

  return (
    <div
      className={classNames('w-28 overflow-hidden rounded-md border px-3 py-2 text-right', {
        'border-success bg-success/10 text-success': !totalHasVariance,
        'border-error bg-error/10 text-error': totalHasVariance,
        'text-right': showValue,
        'text-center': !showValue,
      })}
    >
      {showValue ? value : t('invoices.invoice.balance.modal.table.noVariance', 'No Variance')}
    </div>
  )
}

export default memo(VarianceDisplay)
