import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import PurchaseOrderLineItemInvoicedStatus from '../PurchaseOrderLineItemInvoicedStatus'
import PurchaseOrderLineItemReceivedStatus from '../PurchaseOrderLineItemReceivedStatus'

import { PurchaseOrder, PurchaseOrderLineItem, Supplier } from '@/graphql/purchasing/generated/purchasing_graphql'
import { formatProductItem } from '@/modules/requisitions/utils'
import { TableRowSingle } from '@/modules/shared/components/page-header-details-table'
import ProductModal from '@/modules/shared/components/product-modal/ProductModal'
import { DetailsContext } from '@/modules/shared/contexts/DetailsContext'
import { useMoney } from '@/modules/shared/hooks'

interface LinesBySuppliersTileViewProps {
  line: PurchaseOrderLineItem
  supplier: Supplier
  purchaseOrder: PurchaseOrder
}

export default function LinesBySuppliersTileView({ line, supplier, purchaseOrder }: LinesBySuppliersTileViewProps) {
  const {
    orderInstruction,
    department,
    account,
    product,
    unitPrice,
    quantity,
    taxPercentage,
    receivedStatus,
    invoicedStatus,
    lineTotal,
    receivedQuantity,
  } = line
  const { itemMeasure, itemPackName, itemSize, concatenatedSellUnit, brand, itemDescription } = product || {}
  const { t } = useTranslation()
  const { formatMoney } = useMoney()
  const [showProductModal, setShowProductModal] = useState(false)
  const hasDeptAndAccount = department && account
  const sameDeptAndAccount =
    department?.id === purchaseOrder?.department?.id && account?.id === purchaseOrder?.account?.id

  return (
    <>
      <div className="flex flex-col rounded-md bg-white shadow-sm" data-testid="summary-items-res">
        <div className="flex justify-between px-5 py-4">
          <div className="flex flex-col">
            <span
              className="cursor-pointer text-primary"
              onClick={() => setShowProductModal(true)}
              data-testid="product-details-button-res"
            >
              {brand} {itemDescription}
            </span>
            <span className="text-xs text-gray-500">
              <p>
                {formatProductItem({
                  itemMeasure: itemMeasure,
                  itemPackName: itemPackName,
                  itemSize: itemSize,
                  itemSellUnit: concatenatedSellUnit,
                })}
              </p>
            </span>
            <strong className="mt-3 text-base">{`${formatMoney(unitPrice)}`}</strong>
            <span className="text-xs">
              {t('shopPage.cart.summary.plusTax', 'Plus {{ taxPercentage }}% Tax', {
                taxPercentage: taxPercentage || 0,
              })}
            </span>
            <ProductModal
              line={line}
              showModal={showProductModal}
              setShowModal={setShowProductModal}
              supplier={supplier}
            />
          </div>
        </div>
        {(orderInstruction || hasDeptAndAccount) && (
          <div className="mb-3">
            <>
              {orderInstruction && (
                <div className="px-5">
                  <div
                    data-testid="order-instruction-block-res"
                    className="flex cursor-pointer items-center justify-between rounded-md bg-primary/20 p-2 md:gap-x-3"
                  >
                    <div className="flex flex-col md:grow md:flex-row md:justify-between">
                      <p className="text-sm font-bold text-primary">
                        {t('shopPage.cart.summary.table.orderInstruction', 'Order Instruction')}
                      </p>
                      <p className="text-sm text-primary">{orderInstruction}</p>
                    </div>
                  </div>
                </div>
              )}
              {hasDeptAndAccount && !sameDeptAndAccount && (
                <div className="px-5 pt-2" data-testid="dept-account-block-res">
                  <div
                    data-testid="order-instruction-block-res"
                    className="flex cursor-pointer items-center justify-between rounded-md bg-primary/20 p-2 md:gap-x-3"
                  >
                    <div className="flex flex-col md:grow md:flex-row md:justify-between">
                      <p className="text-sm font-bold text-primary">
                        {t('shopPage.cart.summary.table.deptAndAccount', 'Department and Account Code')}
                      </p>
                      <p className="text-sm text-primary">
                        {department.name} {account.code}
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </>
          </div>
        )}
        <hr />
        <section className="p-5">
          <DetailsContext.Provider value={{ tooltip: false }}>
            <div className="w-full divide-y divide-gray-300 overflow-auto border border-gray-300">
              <TableRowSingle
                titleText={t('purchaseOrders.purchaseOrder.item.invoicedStatus', 'Invoiced Status')}
                testId="invoicedStatus"
                value={invoicedStatus && <PurchaseOrderLineItemInvoicedStatus status={invoicedStatus} />}
              />
              <TableRowSingle
                titleText={t('purchaseOrders.purchaseOrder.item.receivedStatus', 'Received Status')}
                testId="receivedStatus"
                value={receivedStatus && <PurchaseOrderLineItemReceivedStatus status={receivedStatus} />}
              />
              <TableRowSingle
                titleText={t('purchaseOrders.purchaseOrder.item.receivedQty', 'Received Qty')}
                testId="receivedQty"
                value={String(receivedQuantity)}
              />
              <TableRowSingle
                titleText={t('purchaseOrders.purchaseOrder.item.orderedQty', 'Ordered Qty')}
                testId="orderedQty"
                value={quantity}
              />
              <TableRowSingle
                titleText={t('purchaseOrders.purchaseOrder.item.poUnitPrice', 'PO Unit Price')}
                testId="poUnitPrice"
                value={`${formatMoney(unitPrice)} (${t(
                  'shopPage.cart.summary.plusTax',
                  'Plus {{ taxPercentage }}% Tax',
                  {
                    taxPercentage: taxPercentage || 0,
                  }
                )})`}
              />
            </div>
          </DetailsContext.Provider>
        </section>
        <div className="flex justify-between border-t border-gray-200">
          <span className="p-3 px-5">
            <div className="flex items-center gap-x-2">
              <input value={String(quantity)} className="h-10 w-12 border bg-gray-100 text-center" readOnly />
              <p>{t('purchaseOrders.purchaseOrder.quantity', 'Ordered Qty')}</p>
            </div>
          </span>
          <div className="flex flex-col p-3 px-5 text-right">
            <strong>{`${formatMoney(lineTotal)}`}</strong>
            <span className="text-xs">
              {t('shopPage.cart.summary.plusTax', 'Plus {{ taxPercentage }}% Tax', {
                taxPercentage: taxPercentage || 0,
              })}
            </span>
          </div>
        </div>
      </div>
    </>
  )
}
