import { useTranslation } from 'react-i18next'

import NoImage from '@/assets/images/no-image.svg'
import { PricedCataloguedProduct, Product } from '@/graphql/purchasing/generated/purchasing_graphql'
import { formatProductItem } from '@/modules/requisitions/utils'
import { useMoney } from '@/modules/shared/hooks'
import { ChevronDownIcon } from '@/modules/shared/icons'

interface ProductPreviewProps {
  product: Product
  pricedCataloguedProduct?: PricedCataloguedProduct
}

export default function ProductPreview({ product, pricedCataloguedProduct }: ProductPreviewProps) {
  const { brand, itemDescription, itemMeasure, itemPackName, itemSize, concatenatedSellUnit, category } = product
  const { sellUnitPrice, sellUnitTaxPercentage, image } = pricedCataloguedProduct || {}
  const { t } = useTranslation()
  const { formatMoney } = useMoney()

  return (
    <section data-testid="product-preview">
      <p className="flex justify-between text-lg font-bold">
        <span>{t('catalogs.selfManagedCatalog.product.productPreview', 'Product Preview')}</span>
        <ChevronDownIcon className="h-6 w-6" />
      </p>
      <hr className="mb-4 mt-2" />
      <div className="flex justify-between gap-x-2 rounded-md border bg-white p-4 text-sm">
        <div className="flex gap-x-3">
          <img className="h-24 w-24 flex-none border" src={image || NoImage} alt="product" />
          <div className="flex flex-col justify-center">
            <p>
              {brand} {itemDescription}
            </p>
            <p className="text-xs text-gray-500">
              {formatProductItem({
                itemMeasure: itemMeasure,
                itemPackName: itemPackName,
                itemSize: itemSize,
                itemSellUnit: concatenatedSellUnit,
              })}
            </p>
            <p className="text-xs">
              {category?.parent?.name} {'>'} {category?.name}
            </p>
          </div>
        </div>
        {pricedCataloguedProduct && (
          <div className="flex flex-col justify-center">
            <p className="text-right text-base font-bold">{formatMoney(sellUnitPrice)}</p>
            <p className="text-right text-xs text-gray-500">
              {t('shopPage.productModal.productDetails.plusTax', 'Plus {{ taxPercentage }}% Tax', {
                taxPercentage: sellUnitTaxPercentage,
              })}
            </p>
          </div>
        )}
      </div>
    </section>
  )
}
