import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { DeliveryChargeConfiguration, Supplier } from '@/graphql/purchasing/generated/purchasing_graphql'
import { DeliveryFees } from '@/modules/requisitions/components'
import { Button, Modal } from '@/modules/shared/components'
import SupplierDetails from '@/modules/shared/components/supplier-details'

interface SupplierDetailsModalProps {
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<number | null>>
  supplier: Supplier
}

function SupplierDetailsModal(props: SupplierDetailsModalProps) {
  const { showModal, setShowModal, supplier } = props
  const { t } = useTranslation()

  const onCloseModal = () => {
    setShowModal(null)
  }

  return (
    <Modal showModal={showModal} onCloseModal={onCloseModal} dataTestId="supplier-details-modal">
      <Modal.Panel
        as="div"
        className="flex max-h-[80vh] w-full flex-col overflow-hidden rounded-md bg-white shadow-xl transition-all md:max-w-[600px]"
      >
        <Modal.Title title={supplier.name || ''} onCloseModal={onCloseModal} />

        <Modal.Body>
          <section>
            <h2 className="font-semibold">{t('shopPage.cart.summary.details.supplierDetails', 'Supplier Details')}</h2>
            <p className="text-gray-500">
              {t(
                'shopPage.cart.summary.details.supplierDetailsDescription',
                'You can contact the supplier using the details below.'
              )}
            </p>
            <SupplierDetails supplier={supplier} showLess />
          </section>
          <section data-testid="supplier-delivery-fees">
            <h2 className="mt-5 font-semibold">{t('shopPage.cart.summary.details.deliveryFees', 'Delivery Fees')}</h2>
            <p className="text-gray-500">
              {t(
                'shopPage.cart.summary.details.deliveryFeesDescription',
                'The table below shows delivery fees for this supplier by total order value, excluding tax. The delivery fees are based on your trading agreement with this Supplier. Delivery fees will only appear on the Purchase Order once the Requisition has been submitted and approved. Delivery fees shown here are a general guide and the supplier reserves the right to charge additional delivery fees based on weight or volume or certain products.'
              )}
            </p>
            <DeliveryFees
              deliveryChargeConfiguration={supplier?.deliveryChargeConfiguration as DeliveryChargeConfiguration}
            />
          </section>
        </Modal.Body>
        <Modal.Footer className="flex items-center justify-end">
          <Button
            type="button"
            className="h-11 w-full rounded-md bg-gray-200 px-8 text-sm md:w-fit"
            onClick={onCloseModal}
            data-testid="supplier-details-close"
          >
            {t('shopPage.cart.settings.cancelModalBtn', 'Cancel')}
          </Button>
        </Modal.Footer>
      </Modal.Panel>
    </Modal>
  )
}

export default SupplierDetailsModal
