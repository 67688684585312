import classNames from 'classnames'
import { useState } from 'react'

import { Price, Product, Supplier } from '@/graphql/purchasing/generated/purchasing_graphql'
import SupplierQuotesModal from '@/modules/shared/components/supplier-quotes-modal/SupplierQuotesModal'
import { useMoney } from '@/modules/shared/hooks'
import { CheckAltIcon } from '@/modules/shared/icons'

export interface PRLineBestBuyProps {
  livePremium: number | null | undefined
  cataloguedProductId: number | null | undefined
  isAddedToCart: (cataloguedProductId: number) => boolean
  product?: Product | null
  prices?: Price[] | null
  supplier?: Supplier | null
  lineId?: number | null
  image?: string | null
  quantity?: number | null
}

export default function BestBuyButton({
  isAddedToCart,
  product,
  prices,
  supplier,
  lineId,
  image,
  livePremium,
  cataloguedProductId,
  quantity,
}: PRLineBestBuyProps) {
  const { formatMoney } = useMoney()
  const [showSupplierQuotesModal, setShowSupplierQuotesModal] = useState<boolean>(false)

  return (
    <>
      <div
        className={classNames('mx-auto flex h-9 items-center justify-center rounded-md p-2 ', {
          'w-9 bg-success text-white': isAddedToCart(Number(cataloguedProductId)) && livePremium === 0,
          'w-fit bg-error text-white': isAddedToCart(Number(cataloguedProductId)) && livePremium !== 0,
          'w-9 bg-gray-200': !isAddedToCart(Number(cataloguedProductId)),
          'cursor-pointer': product && prices,
        })}
        data-testid="best-buy-button"
        onClick={() => product && prices && setShowSupplierQuotesModal(true)}
      >
        {isAddedToCart(Number(cataloguedProductId)) && livePremium !== 0 && formatMoney(livePremium)}
        {isAddedToCart(Number(cataloguedProductId)) && livePremium === 0 && <CheckAltIcon className="h-5 w-5" />}
        {!isAddedToCart(Number(cataloguedProductId)) && '-'}
      </div>
      {product && prices && (
        <SupplierQuotesModal
          showModal={showSupplierQuotesModal}
          setShowModal={setShowSupplierQuotesModal}
          product={product}
          prices={prices}
          currentSupplierId={supplier?.id}
          lineId={lineId || undefined}
          image={image}
          cataloguedProductId={cataloguedProductId}
          originalQuantity={quantity}
        />
      )}
    </>
  )
}
