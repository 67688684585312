import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { GetRequisitionInvoicesDocument, Invoice } from '@/graphql/purchasing/generated/purchasing_graphql'
import { QueryResult } from '@/modules/shared/components'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { ControlBarIcon } from '@/modules/shared/icons'
import { InvoiceTable } from '@/modules/shared/layouts'
import { extractEdges } from '@/modules/shared/utils'

export default function Invoices() {
  const { t } = useTranslation()
  const { requisitionId } = useParams<{ requisitionId: string }>()
  const { data, loading, error } = useQuery(GetRequisitionInvoicesDocument, {
    variables: {
      requisitionId: Number(requisitionId),
    },
    context: { uri: PURCHASING_GRAPHQL_API },
  })
  const invoices = extractEdges<Invoice>(data?.currentPurchaser?.requisition?.invoices)

  return (
    <QueryResult loading={loading} error={error}>
      <div className="flex items-center">
        <ControlBarIcon className=" h-10 w-10" />
        <p className="ml-3 text-sm">
          {data?.currentPurchaser?.requisition?.requisitionNumber} {t('invoices.details.invoices', 'Invoices')}
        </p>
      </div>
      <InvoiceTable invoices={invoices} loading={loading} />
    </QueryResult>
  )
}
