import { useTranslation } from 'react-i18next'

import BestBuy from '@/modules/requisitions/pages/shop/product-list/BestBuy'
import AdvancedSearchFilter from '@/modules/shared/components/advanced-search/filters-section/AdvancedSearchFilter'

export default function MoreOptions() {
  const { t } = useTranslation()
  return (
    <AdvancedSearchFilter
      buttonTestId="more-options-section"
      buttonTitle={t('shopPage.productList.moreOptions', 'More Options')}
    >
      <BestBuy reverse />
    </AdvancedSearchFilter>
  )
}
