import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { ReceivingDocumentLineItem } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Accordion, Fallback, PPNewDetailLink } from '@/modules/shared/components'
import { TableLineItem } from '@/modules/shared/components'
import { ENTERPRISE_RECEIVING_TRANSFER, ENTERPRISE_STOCK_LOCATION_URL } from '@/modules/shared/constants'
import { useMoney } from '@/modules/shared/hooks'
import { formatDate } from '@/modules/shared/utils'

interface ReceivingNotesProps {
  receivingDocumentLineItems: ReceivingDocumentLineItem[]
}

export default function ReceivingNotes(props: ReceivingNotesProps) {
  const { receivingDocumentLineItems } = props
  const { t } = useTranslation()
  const { purchaseOrderId } = useParams<{ purchaseOrderId: string }>()
  const { formatMoney } = useMoney()

  // This is a workaround to only show the receivining notes that have quantity > 0. Currently, graphql doesn't support to filter on quantity > 0.
  const receivingNotesQtyGreaterThanZero = receivingDocumentLineItems.filter((line) => (line.quantity || 0) > 0)
  return (
    <section>
      <strong className="text-sm">
        {t('purchaseOrders.purchaseOrder.details.poLineModal.receivingNotes.receivingNotes', 'Receiving Notes')}
      </strong>
      <p className="text-sm text-gray-500">
        {t(
          'purchaseOrders.purchaseOrder.details.poLineModal.receivingNotes.receivingNotesDesc',
          'Details of the Receiving Notes that include this Purchase Order line.'
        )}
      </p>
      {receivingNotesQtyGreaterThanZero.length === 0 && (
        <div className="mt-4 border bg-gray-100 py-4">
          <p className="text-center text-sm text-gray-500">
            {t(
              'purchaseOrders.purchaseOrder.details.poLineModal.receivingNotes.noReceivingNotesFound',
              'No Receiving Notes Found'
            )}
          </p>
        </div>
      )}
      {receivingNotesQtyGreaterThanZero.length > 0 &&
        receivingNotesQtyGreaterThanZero.map((line) => {
          const { receivingDocument, receivedToInventory, stockLocation, unitPrice, quantity, id, totalValue } = line
          return (
            <Accordion
              key={line.id}
              title={receivingDocument?.receivingDocumentNumber || ''}
              buttonClassName="px-3 h-11 mt-4"
              titleClassName="font-bold"
            >
              <div className="mt-4 border" data-testid="po-line-receivingnotes">
                <TableLineItem
                  title={t(
                    'purchaseOrders.purchaseOrder.details.poLineModal.receivingNotes.receivingNote',
                    'Receiving Note'
                  )}
                  tooltip={t(
                    'purchaseOrders.purchaseOrder.details.poLineModal.receivingNotes.receivingNoteTooltip',
                    'The Receiving Note document that includes this Purchase Order Line.'
                  )}
                  value={
                    <PPNewDetailLink
                      type="receiving-document"
                      secondaryId={purchaseOrderId}
                      id={String(receivingDocument?.id)}
                      openNewTab
                    >
                      {receivingDocument?.receivingDocumentNumber || ''}
                    </PPNewDetailLink>
                  }
                />
                <TableLineItem
                  title={t('purchaseOrders.purchaseOrder.details.poLineModal.receivingNotes.receivedBy', 'Received By')}
                  tooltip={t(
                    'purchaseOrders.purchaseOrder.details.poLineModal.receivingNotes.receivedByTooltip',
                    'The user who created the Receiving Note.'
                  )}
                  value={receivingDocument?.receiver?.fullName}
                />
                <TableLineItem
                  title={t('purchaseOrders.purchaseOrder.details.poLineModal.receivingNotes.receivedOn', 'Received On')}
                  tooltip={t(
                    'purchaseOrders.purchaseOrder.details.poLineModal.receivingNotes.receivedOnTooltip',
                    'The date and time that the Receiving Note was submitted.'
                  )}
                  value={formatDate('DD MMM h:mma', receivingDocument?.receivedDate)}
                />
                <TableLineItem
                  title={t(
                    'purchaseOrders.purchaseOrder.details.poLineModal.receivingNotes.receivedQty',
                    'Received Qty'
                  )}
                  tooltip={t(
                    'purchaseOrders.purchaseOrder.details.poLineModal.receivingNotes.receivedQtyTooltip',
                    'The quantity that is recorded as received on the Receiving Note.'
                  )}
                  value={quantity}
                />
                <TableLineItem
                  title={t('general.purchaseOrderLineId', 'Purchase Order Line ID')}
                  tooltip={t(
                    'purchaseOrders.purchaseOrder.details.poLineModal.receivingNotes.purchaseOrderLineIdTooltip',
                    'The unique identifier of the Purchase Order line.'
                  )}
                  value={id}
                />
                <TableLineItem
                  title={t('general.receivingUnitPrice', 'Receiving Unit Price')}
                  tooltip={t(
                    'purchaseOrders.purchaseOrder.details.poLineModal.receivingNotes.receivingUnitPriceTooltip',
                    'The tax exclusive Unit Price recorded on the Receiving Note line.'
                  )}
                  value={formatMoney(unitPrice)}
                />

                <TableLineItem
                  title={t(
                    'purchaseOrders.purchaseOrder.details.poLineModal.receivingNotes.receivingTotalValue',
                    'Receiving Total Value'
                  )}
                  tooltip={t(
                    'purchaseOrders.purchaseOrder.details.poLineModal.receivingNotes.receivingTotalValueTooltip',
                    'This is the total value of the Receiving Line, recorded on the Receiving Note. It is the Receiving Quantity multiplied by the Receiving Unit Price.'
                  )}
                  value={formatMoney(totalValue)}
                />
                <TableLineItem
                  title={t(
                    'purchaseOrders.purchaseOrder.details.poLineModal.receivingNotes.receiveToInventory',
                    'Receive to Inventory'
                  )}
                  tooltip={t(
                    'purchaseOrders.purchaseOrder.details.poLineModal.receivingNotes.receiveToInventoryTooltip',
                    'If the Receiving Note was Received to an Inventory Stock Location.'
                  )}
                  value={receivedToInventory ? t('general.yes', 'Yes') : t('general.no', 'No')}
                />
                <TableLineItem
                  title={t(
                    'purchaseOrders.purchaseOrder.details.poLineModal.receivingNotes.stockLocation',
                    'Stock Location'
                  )}
                  tooltip={t(
                    'purchaseOrders.purchaseOrder.details.poLineModal.receivingNotes.stockLocationTooltip',
                    'The Stock Location that the items were received to, if the Receiving Note was Received to Inventory.'
                  )}
                  value={
                    <Fallback condition={!!stockLocation}>
                      <a
                        className="flex items-center text-sm"
                        href={`${ENTERPRISE_STOCK_LOCATION_URL}/${stockLocation?.id}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {stockLocation?.name}
                      </a>
                    </Fallback>
                  }
                />
                <TableLineItem
                  title={t('general.receivingTransfer', 'Receiving Transfer')}
                  tooltip={t(
                    'purchaseOrders.purchaseOrder.details.poLineModal.receivingNotes.receivingTransferTooltip',
                    'If the Receiving Note was Received to an Inventory Stock Location, a link to the Receiving Transfer log will be shown here.'
                  )}
                  value={
                    <Fallback condition={!!receivingDocument?.transfer}>
                      <a
                        className="flex items-center text-sm"
                        href={`${ENTERPRISE_RECEIVING_TRANSFER}/${receivingDocument?.transfer?.id}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {receivingDocument?.transfer?.transferNumber}
                      </a>
                    </Fallback>
                  }
                />
              </div>
            </Accordion>
          )
        })}
    </section>
  )
}
