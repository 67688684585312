import classNames from 'classnames'
import { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import InvoicesModal from '../InvoicesModal'
import MatchInvoiceModal from '../MatchInvoiceModal'
import PurchaseOrderInvoicedStauts from '../PurchaseOrderInvoicedStatus'
import PurchaseOrderReceivedStatus from '../PurchaseOrderReceivedStatus'
import PurchaseOrderStatus from '../PurchaseOrderStatus'

import { PurchaseOrder } from '@/graphql/purchasing/generated/purchasing_graphql'
import { PurchaseOrderStatuses } from '@/modules/purchase-orders/types'
import { statusText } from '@/modules/purchase-orders/utils/statusText'
import PurchaseOrderModal from '@/modules/requisitions/pages/confirmation/requisition-purchase-orders/PurchaseOrderModal'
import { Button, Fallback, MoreOptionsMenu, PPNewDetailLink, Tooltip } from '@/modules/shared/components'
import Table from '@/modules/shared/components/table/Table'
import PurchaseOrderReceiptsButton from '@/modules/shared/components/table/table-column-items/PurchaseOrderReceiptsButton'
import { useMoney } from '@/modules/shared/hooks'
import { ArrowUpIcon, MatchIcon, OptionIcon } from '@/modules/shared/icons'
import { Dates } from '@/modules/shared/types'
import { formatDate } from '@/modules/shared/utils'

interface TableViewProps {
  purchaseOrders: PurchaseOrder[]
}

export const isPOMatchable = (purchaseOrder: PurchaseOrder) => {
  return (
    purchaseOrder.status !== PurchaseOrderStatuses.Closed && purchaseOrder.status !== PurchaseOrderStatuses.Cancelled
  )
}

function TableView(props: TableViewProps) {
  const { purchaseOrders } = props
  const { t } = useTranslation()
  const { formatMoney } = useMoney()
  const [isOpen, setIsOpen] = useState<number | null>(null)
  const [showInvoices, setShowInvoices] = useState<number | null>(null)
  const [showMatchInvoice, setShowMatchInvoice] = useState<number | null>(null)

  const renderPurchaseOrder = ({ purchaseOrderNumber, id }: PurchaseOrder) => {
    return (
      <PPNewDetailLink type="purchase-order" id={String(id)}>
        {purchaseOrderNumber}
      </PPNewDetailLink>
    )
  }

  const renderReference = ({ requisition }: PurchaseOrder) => {
    return <Fallback condition={!!requisition?.reference}>{requisition?.reference}</Fallback>
  }

  const renderRequisition = ({ requisition }: PurchaseOrder) => {
    return (
      <Fallback condition={!!requisition}>
        <PPNewDetailLink type="requisition" id={String(requisition?.id)}>
          {requisition?.requisitionNumber}
        </PPNewDetailLink>
      </Fallback>
    )
  }

  const renderSupplier = ({ supplier }: PurchaseOrder) => {
    return <p className="break-words">{supplier?.name}</p>
  }

  const renderAccountCode = ({ department, account }: PurchaseOrder) => {
    return (
      <Fallback as="div" className="break-words" condition={!!department || !!account}>
        <p>{department?.name}</p>
        <p className="text-xs">{account?.code}</p>
      </Fallback>
    )
  }

  const renderStatus = (record: PurchaseOrder) => {
    const { sentDate, status, id } = record
    return (
      <>
        <span className="cursor-pointer" onClick={() => setIsOpen(id)} data-testid="purchase-order-send">
          <PurchaseOrderStatus status={statusText(status, sentDate)} />
        </span>
        <PurchaseOrderModal showModal={isOpen === id} setShowModal={setIsOpen} purchaseOrder={record} />
      </>
    )
  }

  const renderReceived = ({ receivedStatus }: PurchaseOrder) => {
    return <span>{receivedStatus && <PurchaseOrderReceivedStatus receivedStatus={receivedStatus} />}</span>
  }

  const renderInvoiced = (record: PurchaseOrder) => {
    return <span>{record.invoicedStatus && <PurchaseOrderInvoicedStauts purchaseOrder={record} />}</span>
  }

  const renderExpectedDeliveryDate = ({ expectedDeliveryDate }: PurchaseOrder) => {
    return <Fallback condition={!!expectedDeliveryDate}>{formatDate(Dates.Short, expectedDeliveryDate)}</Fallback>
  }

  const renderInvoices = (record: PurchaseOrder) => {
    const hasInvoices = Boolean(record.invoices?.edges?.length)
    return (
      <>
        <Button
          onClick={() =>
            hasInvoices || !isPOMatchable(record) ? setShowInvoices(record.id) : setShowMatchInvoice(record.id)
          }
          className={classNames(`m-auto flex h-10 w-10 items-center justify-center rounded-full`, {
            'bg-gray-200 text-gray-500 hover:bg-gray-300': !hasInvoices,
            'bg-primary/10 text-primary hover:bg-primary/20': hasInvoices,
          })}
          data-testid="invoice-modal-button"
        >
          {hasInvoices || !isPOMatchable(record) ? record?.invoices?.edges?.length : <MatchIcon className="h-6 w-6" />}
        </Button>
        <InvoicesModal
          purchaseOrder={record}
          showModal={showInvoices === record.id}
          setShowModal={setShowInvoices}
          setShowMatchModal={setShowMatchInvoice}
        />
        <MatchInvoiceModal
          purchaseOrder={record}
          showModal={showMatchInvoice === record.id}
          setShowModal={setShowMatchInvoice}
        />
      </>
    )
  }

  const renderTotal = ({ totalValue }: PurchaseOrder) => {
    return (
      <>
        <p className="text-base font-bold">{formatMoney(totalValue)}</p>
        <p className="text-xs text-gray-600">{t('purchaseOrders.taxInclusive', 'Tax Inclusive')}</p>
      </>
    )
  }

  const renderActions = ({ sentDate, id }: PurchaseOrder) => {
    const sent = !!sentDate
    return (
      <Tooltip
        content={
          sent
            ? t('purchaseOrders.allPurchaseOrders.table.tooltips.POSent', 'Purchase Order has been marked as Sent')
            : t(
                'purchaseOrders.allPurchaseOrders.table.tooltips.PONotSent',
                'Click to send the Purchase Order to the Supplier'
              )
        }
        showArrow={false}
        delay={100}
      >
        <span>
          <Button onClick={() => setIsOpen(id)} data-testid="purchase-order-send-2">
            <ArrowUpIcon
              className={classNames('h-9 w-9 cursor-pointer rounded-full p-1.5', {
                'bg-success text-white': sent,
                'bg-gray-200 text-gray-500': !sent,
              })}
            />
          </Button>
        </span>
      </Tooltip>
    )
  }

  const renderMoreOptions = ({ id }: PurchaseOrder) => {
    return (
      <MoreOptionsMenu showPrint showOpenInPPlus showHelp showPurchaseOrderLink purchaseOrderId={id}>
        <OptionIcon className="h-10 w-10 text-gray-500" />
      </MoreOptionsMenu>
    )
  }

  return (
    <Table
      dataTestId="purchase-orders-table-view"
      dataSource={purchaseOrders}
      keyExtractor={(record, index) => String(`${record.id}_${index}`)}
      columns={[
        {
          title: t('general.purchaseOrder', 'Purchase Order'),
          key: 'purchaseOrder',
          headerCellStyles: 'px-4 py-3 ',
          cellStyles: 'px-4 py-3',
          minWidth: 130,
          maxWidth: 130,
          render: renderPurchaseOrder,
        },
        {
          title: t('general.reference', 'Reference'),
          key: 'reference',
          headerCellStyles: 'px-2 py-3 ',
          cellStyles: 'px-2 py-3',
          minWidth: 180,
          maxWidth: 220,
          render: renderReference,
        },
        {
          title: t('general.requisition', 'Requisition'),
          key: 'requisition',
          headerCellStyles: 'pl-4 pr-0 py-3 ',
          cellStyles: 'pl-4 pr-0 py-3 ',
          minWidth: 110,
          maxWidth: 110,
          render: renderRequisition,
        },
        {
          title: t('general.supplier', 'Supplier'),
          key: 'supplier',
          headerCellStyles: 'px-2 py-3 ',
          cellStyles: 'px-2 py-3 ',
          minWidth: 200,
          maxWidth: 220,
          render: renderSupplier,
        },
        {
          title: t('general.accountCode', 'Account Code'),
          key: 'accountCode',
          headerCellStyles: 'px-4 py-3 ',
          cellStyles: 'px-4 py-3 ',
          minWidth: 240,
          grow: true,
          render: renderAccountCode,
        },
        {
          title: t('general.status', 'Status'),
          key: 'status',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 110,
          maxWidth: 110,
          render: renderStatus,
        },
        {
          title: t('general.received', 'Received'),
          key: 'received',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 140,
          maxWidth: 140,
          render: renderReceived,
        },
        {
          title: t('general.invoiced', 'Invoiced'),
          key: 'invoiced',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 140,
          maxWidth: 140,
          render: renderInvoiced,
        },
        {
          title: t('general.deliveryDate', 'Delivery Date'),
          key: 'deliveryDate',
          headerCellStyles: 'px-1 py-3 text-center',
          cellStyles: 'px-1 py-3 text-center',
          minWidth: 120,
          maxWidth: 120,
          render: renderExpectedDeliveryDate,
        },
        {
          title: t('general.receipts', 'Receipts'),
          key: 'receipts',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 70,
          maxWidth: 70,
          render: (record) => (
            <div className="flex items-center justify-center">
              <PurchaseOrderReceiptsButton purchaseOrder={record} />
            </div>
          ),
        },
        {
          title: t('general.invoices', 'Invoices'),
          key: 'invoices',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 70,
          maxWidth: 70,
          render: renderInvoices,
        },
        {
          title: t('general.total', 'Total'),
          key: 'total',
          headerCellStyles: 'px-4 py-3 text-right',
          cellStyles: 'px-4 py-3 text-right',
          minWidth: 128,
          maxWidth: 150,
          render: renderTotal,
        },
        {
          title: t('general.actions', 'Actions'),
          key: 'actions',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 70,
          maxWidth: 70,
          render: renderActions,
        },
        {
          title: t('general.more', 'More'),
          key: 'more',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 70,
          maxWidth: 70,
          render: renderMoreOptions,
        },
      ]}
    />
  )
}

export default memo(TableView)
