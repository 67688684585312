export enum PurchaseOrderStatuses {
  NotSent = 'Not Sent',
  Sent = 'Sent',
  Received = 'Received',
  Invoiced = 'Invoiced',
  Closed = 'Closed',
  Cancelled = 'Cancelled',
}

export enum PurchaseOrderReceiveds {
  Received = 'Received',
  PartlyReceived = 'Partly Received',
  NotReceived = 'Not Received',
}

export enum PurchaseOrderReceivedTranslate {
  Received = 'received',
  PartlyReceived = 'partlyReceived',
  NotReceived = 'notReceived',
}

// TODO: This is how it currently comes in from BE, update when BE is updated
export enum PurchaseOrderReceivedBE {
  Received = 'All products have been fully received.',
  PartlyReceived = 'At least one product has not been fully received on this purchase order.',
  NotReceived = 'No products have been received from this purchase order.',
}

export enum PurchaseOrderInvoicedTranslate {
  Invoiced = 'invoiced',
  PartlyInvoiced = 'partlyInvoiced',
  NotInvoiced = 'notInvoiced',
  Unknown = 'unknown',
}

export enum DeliveryState {
  Sent = 'sent',
  Resent = 'resent',
  Failed = 'failed',
  QueuedForSending = 'Queued for sending',
}

export type ReceivingDocumentLineItemInput = {
  comment: string
  overrideUnitValue: number
  expDate: string
  temperature: string
  vehicleTemperature: string
}
