import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, generatePath } from 'react-router-dom'

import { MenuPopover, PPNewDetailLink } from '@/modules/shared/components'
import { ENTERPRISE_REQUISITION_URL, SUPPORT_LINK } from '@/modules/shared/constants'
import { GetHelpIcon, InfoIcon, NewTabIcon, OptionIcon, XCircleIcon } from '@/modules/shared/icons'
import SubmitIcon from '@/modules/shared/icons/SubmitIcon'

interface DraftMoreOptionsMenuProps {
  requisitionId: number
  setIsOpen: Dispatch<SetStateAction<boolean>>
}

export default function DraftMoreOptionsMenu({ requisitionId, setIsOpen }: DraftMoreOptionsMenuProps) {
  const { t } = useTranslation()
  const MoreOptionContent = () => (
    <div className="py-1">
      <PPNewDetailLink
        type="requisition"
        testId="view-requisition"
        id={String(requisitionId)}
        className="flex w-full items-center px-4 py-2 text-left text-sm text-gray-700 transition hover:bg-gray-100 hover:text-gray-900"
      >
        <InfoIcon className="mr-2 h-5 w-5" />
        {t('requisition.viewRequisition', 'View Requisition')}
      </PPNewDetailLink>

      <Link
        className="flex w-full items-center px-4 py-2 text-left text-sm text-gray-700 transition hover:bg-gray-100 hover:text-gray-900"
        data-testid="submit-requisition"
        to={generatePath('/requisitions/:id/submit', {
          id: String(requisitionId),
        })}
      >
        <SubmitIcon className="mr-2 h-5 w-5" />
        {t('requisition.details.submitRequisition', 'Submit Requisition')}
      </Link>
      <button
        className="flex w-full items-center px-4 py-2 text-left text-sm text-gray-700 transition hover:bg-gray-100 hover:text-gray-900"
        onClick={() => setIsOpen(true)}
        data-testid="cancel-requisition"
      >
        <XCircleIcon className="mr-2 h-5 w-5" />
        {t('requisition.details.cancelRequisition', 'Cancel Requisition')}
      </button>
      <a
        className="flex w-full items-center px-4 py-2 text-left text-sm text-gray-700 transition hover:bg-gray-100 hover:text-gray-900"
        data-testid="view-in-enterprise"
        href={`${ENTERPRISE_REQUISITION_URL}/${requisitionId}`}
        target="_blank"
        rel="noreferrer"
      >
        <NewTabIcon className="mr-2 h-5 w-5" />
        {t('requisition.openInPPlusSignEnterprise', 'Open in P+ Enterprise')}
      </a>
      <a
        className="flex w-full items-center px-4 py-2 text-left text-sm text-gray-700 transition hover:bg-gray-100 hover:text-gray-900"
        href={SUPPORT_LINK}
        data-testid="view-support-link"
        target="_blank"
        rel="noreferrer"
      >
        <GetHelpIcon className="mr-2 h-5 w-5" />
        {t('requisition.getHelp', 'Get Help')}
      </a>
    </div>
  )

  return (
    <MenuPopover content={<MoreOptionContent />}>
      <OptionIcon className="h-10 w-10 text-gray-500" data-testid="draft-more-options-button" />
    </MenuPopover>
  )
}
