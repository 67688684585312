import classNames from 'classnames'
import { Dispatch, PropsWithChildren, SetStateAction, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '../components'

import { QueryParameter } from '@/modules/shared/types'

interface SearchContainerProps extends PropsWithChildren {
  showAdvancedSearch: boolean
  setShowAdvancedSearch: Dispatch<SetStateAction<boolean>>
  className?: string
}

export default function SearchContainer({
  children,
  showAdvancedSearch,
  setShowAdvancedSearch,
  className,
}: SearchContainerProps) {
  const { t } = useTranslation()

  // Check for advanced search query parameters
  const getQueryParams = () => {
    const searchParams = new URLSearchParams(window.location.search)
    const queryParams: { [key in QueryParameter]?: string } = {}

    // if the query param is in this array, then the advanced search will load in the opened state
    const queryParametersArray = [
      QueryParameter.From,
      QueryParameter.To,
      QueryParameter.Enabled,
      QueryParameter.Categories,
      QueryParameter.Suppliers,
      QueryParameter.SupplierProductCode,
      QueryParameter.PurchaserProductCode,
      QueryParameter.PPlusProductCode,
      QueryParameter.MatchedInvoice,
      QueryParameter.ReceivingNotes,
      QueryParameter.PeriodYear,
      QueryParameter.PeriodMonth,
      QueryParameter.BestBuy,
      QueryParameter.StockLocations,
    ]

    queryParametersArray.forEach((key) => {
      const value = searchParams.get(key)
      if (value !== null) {
        queryParams[key] = value
      }
    })
    return queryParams
  }

  // Check if there are any query parameters with non-undefined values
  const queryParamsPresent = Object.values(getQueryParams()).some((value) => value !== undefined)

  useEffect(() => {
    if (queryParamsPresent && !showAdvancedSearch) {
      setShowAdvancedSearch(true)
    }
  }, [queryParamsPresent])

  return (
    <section className="flex flex-col-reverse gap-2 lg:flex-row">
      <Button
        className={classNames('shrink-0 rounded-md px-4 py-3 text-sm', className, {
          'bg-gray-200': !queryParamsPresent,
          'bg-primary/10 text-primary hover:bg-primary/20': queryParamsPresent,
        })}
        onClick={() => setShowAdvancedSearch(!showAdvancedSearch)}
        data-testid="show-advanced-search"
      >
        {t('shopPage.productList.advancedSearchBtn', 'Advanced Search')}
      </Button>
      {children}
    </section>
  )
}
