import { useTranslation } from 'react-i18next'
import { BooleanParam, useQueryParam } from 'use-query-params'

import { Checkbox, Tooltip } from '@/modules/shared/components'
import { QuestionMarkIcon } from '@/modules/shared/icons'
import { QueryParameter } from '@/modules/shared/types'

interface BestBuyProps {
  reverse?: boolean
}

export default function BestBuy({ reverse }: BestBuyProps) {
  const { t } = useTranslation()
  const [bestBuyURLParam, setBestBuyURLParam] = useQueryParam(QueryParameter.BestBuy, BooleanParam)

  const checkbox = () => (
    <span
      className="ml-1 mr-2"
      onClick={() => {
        if (!!bestBuyURLParam) setBestBuyURLParam(undefined)
        else setBestBuyURLParam(true)
      }}
      data-testid="best-buy-button"
    >
      <Checkbox
        isSelected={!!bestBuyURLParam}
        defaultSelected={!!bestBuyURLParam}
        aria-label={t('shopPage.productList.showBestBuy', 'Show Best Buy Only')}
      />
    </span>
  )

  return (
    <div className="flex items-center">
      {reverse && checkbox()}
      <p className="text-sm">{t('shopPage.productList.showBestBuy', 'Show Best Buy Only')}</p>
      <Tooltip
        content={t(
          'shopPage.productList.showBestBuyTooltip',
          'Show only the lowest priced product where there are multiple instances of the same product'
        )}
      >
        <span className="ml-0.5 flex items-center">
          <QuestionMarkIcon className="inline w-5" />
        </span>
      </Tooltip>
      {!reverse && checkbox()}
    </div>
  )
}
