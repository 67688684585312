import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import DeliveryVehicleTemperature from './DeliveryVehicleTemperature'
import LinkedInvoiceLines from './LinkedInvoiceLines'
import OrderQtySummary from './OrderQtySummary'
import ProductExpiryDate from './ProductExpiryDate'
import ProductSummary from './ProductSummary'
import ProductTemperature from './ProductTemperature'
import ReceivingNoteLineComment from './ReceivingNoteLineComment'
import ReceivingUnitPrice from './ReceivingUnitPrice'

import { ReceivingDocumentLineItem } from '@/graphql/purchasing/generated/purchasing_graphql'
import { ReceivingDocumentLineItemInput } from '@/modules/purchase-orders/types'
import { useUpdateReceivingDocumentLine } from '@/modules/receiving-notes/hooks'
import { ReceivingNoteState } from '@/modules/receiving-notes/types'
import { Button, Modal } from '@/modules/shared/components'
import { SUPPORT_LINK } from '@/modules/shared/constants'
import { Dates } from '@/modules/shared/types'
import { formModifiedValues } from '@/modules/shared/utils'
import { formatDate } from '@/modules/shared/utils'

interface AdvancedModalProps {
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<boolean>>
  receivingDocumentLineItem: ReceivingDocumentLineItem
}

export default function AdvancedModal({ showModal, setShowModal, receivingDocumentLineItem }: AdvancedModalProps) {
  const {
    product,
    id: lineId,
    receivingDocument,
    comment,
    overrideUnitValue,
    expDate,
    temperature,
    vehicleTemperature,
  } = receivingDocumentLineItem
  const { t } = useTranslation()
  const [inputFocus, setInputFocus] = useState(false)

  const formMethods = useForm<ReceivingDocumentLineItemInput>({
    defaultValues: {
      comment: comment || '',
      expDate: formatDate(Dates.ShortYear, expDate),
      temperature: temperature || '',
      vehicleTemperature: vehicleTemperature || '',
      overrideUnitValue: overrideUnitValue || undefined,
    },
  })

  const [updateReceivingDocumentLine, { loading }] = useUpdateReceivingDocumentLine()

  const onCloseModal = () => {
    formMethods.reset()
    setShowModal(false)
    setInputFocus(false)
  }

  const onSubmit: SubmitHandler<ReceivingDocumentLineItemInput> = (data) => {
    updateReceivingDocumentLine({
      variables: {
        input: {
          id: lineId,
          productId: product?.id,
          receivingDocumentId: receivingDocument?.id,
          ...formModifiedValues(formMethods.formState.dirtyFields, data),
        },
      },
      onCompleted() {
        setShowModal(false)
        setInputFocus(false)
      },
    })
  }

  const isReadOnly = receivingDocument?.workflowState === ReceivingNoteState.Received

  useEffect(() => {
    formMethods.reset({
      comment: comment || '',
      overrideUnitValue: overrideUnitValue || undefined,
      expDate: formatDate(Dates.ShortYear, expDate),
      temperature: temperature || '',
      vehicleTemperature: vehicleTemperature || '',
    })
  }, [comment, overrideUnitValue, temperature, vehicleTemperature])

  return (
    <Modal showModal={showModal} onCloseModal={onCloseModal}>
      <Modal.Panel
        as="form"
        onSubmit={formMethods.handleSubmit(onSubmit)}
        className="flex h-[80vh] w-full flex-col overflow-hidden rounded-md bg-white shadow-xl transition-all md:max-w-3xl"
      >
        <FormProvider {...formMethods}>
          <Modal.Title
            title={`${product?.brand || ''} ${product?.itemDescription || ''}`}
            onCloseModal={onCloseModal}
          />
          <Modal.Body className="space-y-4">
            <ProductSummary receivingDocumentLineItem={receivingDocumentLineItem} />
            <OrderQtySummary receivingDocumentLineItem={receivingDocumentLineItem} />
            <LinkedInvoiceLines receivingDocumentLineItem={receivingDocumentLineItem} />
            <ReceivingUnitPrice
              receivingDocumentLineItem={receivingDocumentLineItem}
              setInputFocus={setInputFocus}
              isReadOnly={isReadOnly}
              inputFocus={inputFocus}
            />
            <ReceivingNoteLineComment isReadOnly={isReadOnly} setInputFocus={setInputFocus} />
            <ProductExpiryDate isReadOnly={isReadOnly} expDate={expDate} setInputFocus={setInputFocus} />
            <DeliveryVehicleTemperature
              isReadOnly={isReadOnly}
              vehicleTemperature={vehicleTemperature || ''}
              setInputFocus={setInputFocus}
            />
            <ProductTemperature isReadOnly={isReadOnly} temperature={temperature || ''} setInputFocus={setInputFocus} />
            <div className="h-12" />
          </Modal.Body>
          <Modal.Footer className="flex flex-col-reverse items-center justify-between gap-2 md:flex-row">
            <a
              className="text-center text-sm text-primary"
              data-testid="learn-more-related-docs-link"
              href={SUPPORT_LINK}
              target="_blank"
              rel="noreferrer"
            >
              {t('general.learnMore', 'Learn More about Related Documents')}
            </a>
            <div className="flex w-full flex-col-reverse gap-2 md:w-fit md:flex-row">
              <Button
                className="h-11 w-full rounded-md bg-gray-200 px-5 text-sm md:w-fit"
                onClick={() => onCloseModal()}
              >
                {t('general.closeBtn', 'Close')}
              </Button>
              {!isReadOnly && (
                <Button
                  loading={loading}
                  disabled={!inputFocus}
                  className="h-11 w-full rounded-md bg-primary px-5 text-sm text-white md:w-fit"
                  type="submit"
                  data-testid="advanced-modal-submit-button"
                >
                  {t('general.applyChanges', 'Apply Changes')}
                </Button>
              )}
            </div>
          </Modal.Footer>
        </FormProvider>
      </Modal.Panel>
    </Modal>
  )
}
