import { useTranslation } from 'react-i18next'

import { Button } from '@/modules/shared/components'
import { TruckIcon } from '@/modules/shared/icons'

interface POReceivingNotesEmptyStateProps {
  createReceivingDocument: () => void
  createReceivingDocumentLoading: boolean
}

export default function POReceivingNotesEmptyState(props: POReceivingNotesEmptyStateProps) {
  const { createReceivingDocument, createReceivingDocumentLoading } = props
  const { t } = useTranslation()
  return (
    <div className="flex flex-col items-center justify-center gap-y-2 rounded-md border border-gray-300 p-5 py-8">
      <span className="mb-2 text-center">
        <strong className="leading-none">{t('receivingNotes.emptyState.title', 'No Receiving Notes')}</strong>
        <p className="text-sm text-gray-400">
          {t(
            'receivingNotes.emptyState.message',
            'No goods have been received against this Purchase Order yet. Click Receive Goods to create a Receiving Note.'
          )}
        </p>
      </span>
      <Button
        loading={createReceivingDocumentLoading}
        className="flex h-11 items-center gap-x-2 rounded-md bg-gray-200 px-4 text-sm"
        data-testid="receive-goods-btn"
        onClick={() => createReceivingDocument()}
      >
        {t('receivingNotes.receiveGoods', 'Receive Goods')}
        <TruckIcon className="h-5 w-5" />
      </Button>
    </div>
  )
}
