import { useTranslation } from 'react-i18next'

import EmptyFlags from '@/assets/images/empty-flags.svg'
// import InvoiceFlagsModal from './InvoiceFlagsModal'
import { useGetInvoice } from '@/modules/invoices/hooks'
import { translateFlagCode } from '@/modules/invoices/utils/translateFlagCode'
import { ProductListEmptyState } from '@/modules/requisitions/components'
import { FlagIcon } from '@/modules/shared/icons'

function InvoiceFlags() {
  const { t } = useTranslation()
  const { invoiceNumber, flaggingMessages } = useGetInvoice()

  // TODO: Add InvoiceFlagsModal once Peter has completed writing the content and emuns have been added to the flags
  // const [showInvoiceFlagsModal, setShowInvoiceFlagsModal] = useState(false)

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <FlagIcon className="h-10 w-10" />
          <p className="ml-3 text-sm">{`${invoiceNumber} ${t('invoice.flags.label', 'Flags')}`}</p>
        </div>
      </div>
      {flaggingMessages?.length ? (
        <div className="w-full bg-white text-left text-sm">
          <div data-testid="flagging-message-table" className="flex flex-col border">
            {flaggingMessages.map((flaggingMessage) => (
              <div
                className="flex items-center border-b" // cursor-pointer border-b hover:bg-gray-100 once modal is added
                key={flaggingMessage.type}
                // onClick={() => setShowInvoiceFlagsModal(true)}
              >
                <div className="px-4 py-3">
                  <FlagIcon className="h-10 w-10 text-error" />
                </div>
                <div data-testid="flagging-message" className="w-full py-3 pr-4 text-left">
                  <p>{translateFlagCode(flaggingMessage.type, flaggingMessage.values)}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <ProductListEmptyState
          image={EmptyFlags}
          message={t('invoice.flags.empty', 'All Flags have now been resolved.')}
        />
      )}
      {/* <InvoiceFlagsModal show={showInvoiceFlagsModal} setShow={setShowInvoiceFlagsModal} /> */}
    </>
  )
}

export default InvoiceFlags
