import { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'

import InventoryModal from '../purchase-order/receiving-notes/InventoryModal'
import PurchaseOrderReceivedStatus from './PurchaseOrderReceivedStatus'

import {
  PurchaseOrder,
  PurchaseOrderReceivedStatusEnum,
  ReceivingDocument,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { ReceivingNoteStatus } from '@/modules/receiving-notes/components'
import { Button, Modal, PPNewDetailLink } from '@/modules/shared/components'
import Table from '@/modules/shared/components/table/Table'
import { SUPPORT_LINK } from '@/modules/shared/constants'
import { NewTabIcon } from '@/modules/shared/icons'
import { extractEdges } from '@/modules/shared/utils'

interface ReceiptsModalProps {
  purchaseOrder: PurchaseOrder
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<boolean>>
}

export default function ReceiptsModal({ purchaseOrder, showModal, setShowModal }: ReceiptsModalProps) {
  const { t } = useTranslation()
  const receipts = extractEdges<ReceivingDocument>(purchaseOrder.receivingDocuments)

  const [openInventoryModal, setOpenInventoryModal] = useState(false)

  const onCloseModal = () => {
    setShowModal(false)
  }

  const purchaseOrderStatus = () => {
    switch (purchaseOrder.receivedStatus) {
      case PurchaseOrderReceivedStatusEnum.FullyReceived:
        return {
          title: t('general.isReceivedStatus', '{{ number }} is Received', {
            number: purchaseOrder.purchaseOrderNumber,
          }),
          body: t(
            'purchaseOrders.allPurchaseOrders.table.receiptsModal.receivedBody',
            'All items on this Purchase Order have been fully Received. This means that the Ordered Quantity of each Purchase Order line has been marked as Received on one or more Receiving Notes. The Receiving Notes are shown below.'
          ),
        }
      case PurchaseOrderReceivedStatusEnum.PartlyReceived:
        return {
          title: t('general.isPartlyReceivedStatus', '{{ number }} is Partly Received', {
            number: purchaseOrder.purchaseOrderNumber,
          }),
          body: t(
            'purchaseOrders.allPurchaseOrders.table.receiptsModal.partlyReceivedBody',
            'Some of the items on this Purchase Order have been Received. This means that some of the Ordered Quantity of the Purchase Order lines has been marked as Received on one or more Receiving Notes. The Receiving Notes are shown below.'
          ),
        }
      case PurchaseOrderReceivedStatusEnum.NotReceived:
        return {
          title: t('general.isNotReceivedStatus', '{{ number }} is Not Received', {
            number: purchaseOrder.purchaseOrderNumber,
          }),
          body: t(
            'purchaseOrders.allPurchaseOrders.table.receiptsModal.notReceivedBody',
            'None of the items on this Purchase Order have been Received. This means that none of the Ordered Quantity of the Purchase Order lines has been marked as Received on any Receiving Notes, or it could mean that the Receiving Notes are still in a Draft state. The Receiving Notes are shown below.'
          ),
        }
      default:
        return {
          title: t('general.unknown', 'Unknown'),
          body: t('general.unknown', 'Unknown'),
        }
    }
  }

  return (
    <>
      <Modal showModal={showModal} onCloseModal={onCloseModal} dataTestId="receipts-modal">
        <Modal.Panel
          as="div"
          className="flex max-h-[80vh] w-full flex-col overflow-hidden rounded-md bg-white shadow-xl transition-all md:max-w-[600px]"
        >
          <Modal.Title title={purchaseOrderStatus().title} onCloseModal={onCloseModal} />
          <Modal.Body className="space-y-4 text-sm">
            <section>
              <p className="font-bold">{t('general.receivedStatus', 'Received Status')}</p>
              <p className="text-gray-500">{purchaseOrderStatus().body}</p>
              <div className="mt-2 rounded-md border bg-gray-100 p-4">
                <span>
                  {purchaseOrder.receivedStatus && (
                    <PurchaseOrderReceivedStatus receivedStatus={purchaseOrder.receivedStatus} />
                  )}
                </span>
              </div>
            </section>
            <section>
              <p className="font-bold">{t('general.receivingNotes', 'Receiving Notes')}</p>
              <p className="text-gray-500">
                {t(
                  'purchaseOrders.allPurchaseOrders.table.receiptsModal.receivingNotesDesc',
                  'The following Receiving Notes have already been created against this Purchase Order. You can create another Receiving Note using the button below.'
                )}
              </p>
              <Table
                dataSource={receipts}
                dataTestId="receiving-notes-table"
                keyExtractor={(record) => String(record.id)}
                tableHeadStyles="border-none"
                columns={[
                  {
                    title: t('general.receivingNote', 'Receiving Note'),
                    key: 'receivingNote',
                    headerCellStyles: 'pl-3 pr-4 py-4',
                    cellStyles: 'pl-3 pr-4 py-4',
                    minWidth: 140,
                    grow: true,
                    render(record) {
                      return (
                        <PPNewDetailLink
                          testId="receiving-note-number"
                          openNewTab
                          className="flex items-center gap-x-1 text-primary"
                          type="receiving-document"
                          secondaryId={String(purchaseOrder.id)}
                          id={String(record.id)}
                        >
                          <span>{record.receivingDocumentNumber}</span>
                          <NewTabIcon className="h-5 w-5" />
                        </PPNewDetailLink>
                      )
                    },
                  },
                  {
                    title: t('general.status', 'Status'),
                    key: 'status',
                    headerCellStyles: 'py-4 text-center',
                    cellStyles: 'py-4 text-center',
                    minWidth: 120,
                    maxWidth: 120,
                    render(record) {
                      return <ReceivingNoteStatus state={String(record.workflowState)} />
                    },
                  },
                ]}
              />
            </section>
          </Modal.Body>
          <Modal.Footer className="flex flex-col-reverse items-center justify-between gap-2 md:flex-row">
            <a
              className="text-center text-sm text-primary"
              data-testid="learn-more-related-docs-link"
              href={SUPPORT_LINK}
              target="_blank"
              rel="noreferrer"
            >
              {t('general.learnMoreReceivingGoods', 'Learn More about Receiving Goods')}
            </a>
            <div className="space-x-2">
              <Button
                type="button"
                className="w-full rounded-md bg-gray-200 px-8 py-2.5 text-sm md:w-auto"
                onClick={onCloseModal}
                data-testid="match-invoice-close"
              >
                {t('general.close', 'Close')}
              </Button>
              <Button
                type="button"
                data-testid="create-another-receiving-note"
                className="w-full rounded-md bg-primary px-5 py-2.5 text-sm text-white md:w-auto"
                onClick={() => setOpenInventoryModal(true)}
              >
                {t('general.createAnother', 'Create Another')}
              </Button>
            </div>
          </Modal.Footer>
        </Modal.Panel>
      </Modal>
      <InventoryModal
        showModal={openInventoryModal}
        setShowModal={setOpenInventoryModal}
        purchaseOrderId={String(purchaseOrder.id)}
      />
    </>
  )
}
