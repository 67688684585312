import classNames from 'classnames'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, generatePath } from 'react-router-dom'

import OrderOptions from './OrderOptions'
import PurchaseOrderModal from './PurchaseOrderModal'

import { PurchaseOrder, Requisition } from '@/graphql/purchasing/generated/purchasing_graphql'
import { PurchaseOrderStatuses } from '@/modules/purchase-orders/types'
import { Button } from '@/modules/shared/components'
import { useMoney } from '@/modules/shared/hooks'
import { CheckAltIcon, OptionIcon } from '@/modules/shared/icons'
import { extractEdges } from '@/modules/shared/utils'

interface RequisitionPurchaseOrdersProps {
  requisition: Requisition
}

export default function RequisitionPurchaseOrders(props: RequisitionPurchaseOrdersProps) {
  const { requisition } = props
  const { t } = useTranslation()
  const { formatMoney } = useMoney()
  const [showSentModal, setShowSentModal] = useState<number | null>(null)

  return (
    <div
      className="mb-5 mt-2 flex w-full flex-col divide-y divide-gray-200 shadow-sm"
      data-testid="requisition-purchase-orders"
    >
      {extractEdges<PurchaseOrder>(requisition?.purchaseOrders).map((purchaseOrder) => (
        <div
          key={purchaseOrder.id}
          className="flex justify-between gap-x-2 bg-white p-4 first:rounded-t-md last:rounded-b-md"
        >
          <div className="flex flex-col">
            <p className="text-sm font-bold sm:text-base">{formatMoney(purchaseOrder.totalValue)}</p>
            <Link
              to={generatePath('/purchase-orders/:purchaseOrderId', { purchaseOrderId: String(purchaseOrder.id) })}
              className="text-xs text-primary sm:text-sm"
              target="_blank"
              rel="noreferrer"
            >
              {purchaseOrder.purchaseOrderNumber} {purchaseOrder.supplier?.name}
            </Link>
          </div>
          <div className="flex items-center gap-x-3">
            <Button
              className={classNames(
                `flex  h-11 w-24 items-center justify-center gap-x-2 rounded-md text-xs sm:w-28 sm:text-sm `,
                {
                  'bg-success text-white': purchaseOrder.status === PurchaseOrderStatuses.Sent,
                  'bg-gray-200': purchaseOrder.status === PurchaseOrderStatuses.NotSent,
                }
              )}
              onClick={() => setShowSentModal(purchaseOrder.id)}
            >
              {purchaseOrder.status === PurchaseOrderStatuses.Sent ? (
                <>
                  {t('confirmation.POModal.sent', 'PO Sent')} <CheckAltIcon className="inline w-4 sm:w-5" />
                </>
              ) : (
                t('confirmation.POModal.sendPOButton', 'Send PO')
              )}
            </Button>
            <OrderOptions purchaseOrderId={String(purchaseOrder?.id)}>
              <OptionIcon className="w-8 text-gray-400 hover:text-gray-500 sm:w-10" />
            </OrderOptions>
          </div>
          <PurchaseOrderModal
            purchaseOrder={purchaseOrder}
            showModal={showSentModal === purchaseOrder.id}
            setShowModal={setShowSentModal}
          />
        </div>
      ))}
    </div>
  )
}
