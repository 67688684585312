import classNames from 'classnames'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, generatePath } from 'react-router-dom'

import { Catalogue } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Fallback, MoreOptionsMenu, SimpleTileViewItem } from '@/modules/shared/components'
import { OptionIcon } from '@/modules/shared/icons'
import { Dates } from '@/modules/shared/types'
import { formatDate } from '@/modules/shared/utils'

interface TileViewProps {
  buyLists: Catalogue[]
}

function TileView(props: TileViewProps) {
  const { buyLists } = props
  const { t } = useTranslation()

  return (
    <>
      {buyLists.map((buyList) => {
        const { id, title, owner, updatedAt, chargingDefinitions } = buyList
        return (
          <div key={id} className="mb-6 rounded-md border bg-white">
            <div className="flex flex-col p-4">
              <span className="break-all text-base font-bold">{title}</span>
              <p className="text-sm text-gray-500">{owner?.name}</p>
            </div>
            <hr />
            <section data-testid="buy-list-details" className="p-4 text-sm">
              <SimpleTileViewItem className="mb-3" title={t('buyLists.details.owner', 'Owner')} value={owner?.name} />
              <SimpleTileViewItem
                className="mb-3"
                title={t('buyLists.details.lastUpdatedDate', 'Last Updated Date')}
                value={formatDate(Dates.Short, updatedAt)}
              />
              <SimpleTileViewItem
                className="mb-3"
                title={t('buyLists.details.lastUpdatedTime', 'Last Updated Time')}
                value={formatDate(Dates.Time, updatedAt)}
              />
              <SimpleTileViewItem
                className="mb-3"
                title={t('buyLists.details.department', 'Department')}
                value={
                  <Fallback condition={!!chargingDefinitions?.edges![0]?.node?.department?.name}>
                    {chargingDefinitions?.edges![0]?.node?.department?.name}
                  </Fallback>
                }
              />
              <SimpleTileViewItem
                className="mb-3"
                title={t('buyLists.details.account', 'Account')}
                value={
                  <Fallback condition={!!chargingDefinitions?.edges![0]?.node?.account?.accountName}>
                    {chargingDefinitions?.edges![0]?.node?.account?.accountName}
                  </Fallback>
                }
              />
            </section>

            <hr />
            <div className={classNames('flex w-full items-center justify-between p-4')}>
              <Link
                data-testid="view-buy-list-link-res"
                to={generatePath('/buy-lists/:id/products', {
                  id: id.toString(),
                })}
                className="flex h-12 items-center justify-center rounded-md bg-gray-200 p-4 text-center text-sm transition hover:brightness-95"
              >
                {t('buyLists.viewBuyList', 'View Buy List')}
              </Link>
              <MoreOptionsMenu showOpenInPPlus showHelp buyListId={id}>
                <OptionIcon className="h-10 w-10 text-gray-500" />
              </MoreOptionsMenu>
            </div>
          </div>
        )
      })}
    </>
  )
}

export default memo(TileView)
