import { useSetAtom } from 'jotai'
import { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import AddItem from '../AddItem'
import AddToBuyListModal from '../AddToBuyListModal'

import NoImage from '@/assets/images/no-image.svg'
import { RequisitionLine, Supplier } from '@/graphql/purchasing/generated/purchasing_graphql'
import { useDeleteRequisitionLine, useUpdateRequisitionLine } from '@/modules/requisitions/hooks'
import { isChangingProductQtyAtom } from '@/modules/requisitions/state'
import { MoreOptionsMenu, Stepper } from '@/modules/shared/components'
import ProductModal from '@/modules/shared/components/product-modal/ProductModal'
import Table from '@/modules/shared/components/table/Table'
import { ProductImage, ProductItem, SupplierQuotesButton } from '@/modules/shared/components/table/table-column-items'
import { useMoney } from '@/modules/shared/hooks'
import { OptionIcon } from '@/modules/shared/icons'

interface ListViewProps {
  possibleRequisitionLines: RequisitionLine[]
  lines: RequisitionLine[]
}

function RequisitionLinesListView({ possibleRequisitionLines, lines }: ListViewProps) {
  const { t } = useTranslation()
  const { formatMoney } = useMoney()

  const [showProductDetailsModal, setShowProductDetailsModal] = useState(false)
  const [showAddToBuyListModal, setShowAddToBuyListModal] = useState(false)
  const [loadingId, setLoadingId] = useState<number | null>(null)
  const [activeLineId, setActiveLineId] = useState<number | null>(null)

  const [updateRequisitionLine, { loading: loadingUpdate }] = useUpdateRequisitionLine()
  const [deleteRequisitionLine, { loading: loadingDelete }] = useDeleteRequisitionLine()

  const onOpenProductDetailsModal = (activeId: number) => {
    setActiveLineId(activeId)
    setShowProductDetailsModal(true)
  }

  const onOpenAddToBuyListModal = (activeId: number) => {
    setActiveLineId(activeId)
    setShowAddToBuyListModal(true)
  }
  const setIsUpdatingQuantity = useSetAtom(isChangingProductQtyAtom)
  const onUpdateRequisitionLine = (input: {
    id: number
    productId: number
    quantity: number
    cataloguedProductId: number
  }) => {
    setLoadingId(input.id)
    setIsUpdatingQuantity(true)
    if (input.quantity) {
      updateRequisitionLine({
        variables: {
          input: {
            ...input,
          },
        },
        onCompleted() {
          setIsUpdatingQuantity(false)
        },
      })
    } else {
      deleteRequisitionLine({
        variables: {
          input: {
            id: input.id,
          },
        },
        onCompleted() {
          setIsUpdatingQuantity(false)
        },
      })
    }
  }

  const renderSupplier = ({ supplier }: RequisitionLine) => (
    <p className="truncate text-sm leading-5">{supplier?.name}</p>
  )

  const renderUnitPrice = ({ taxPercentage, unitPrice }: RequisitionLine) => (
    <span>
      <p className="text-base font-bold leading-tight">{formatMoney(unitPrice)}</p>
      <p className="text-xs text-gray-500">
        {taxPercentage
          ? t('shopPage.productList.productCard.taxPercentage', 'Plus {{ percent }}% Tax', {
              percent: taxPercentage,
            })
          : t('shopPage.productList.productCard.taxFree', 'Tax Free')}
      </p>
    </span>
  )

  const renderAddItem = (record: RequisitionLine) => {
    const { quantity, cataloguedProductId, id, productId } = record
    const isAddedToCart = lines.find((line) => line.cataloguedProductId === cataloguedProductId)
    return (
      <div className="flex items-center justify-center">
        {isAddedToCart ? (
          <Stepper
            loading={(loadingUpdate || loadingDelete) && loadingId === id}
            value={quantity || 0}
            stepperAriaLabel={t('shopPage.stepperAriaLabel', 'Entering number of products')}
            onChange={(newQty: number) => {
              onUpdateRequisitionLine({
                id: Number(id),
                productId: Number(productId),
                quantity: newQty,
                cataloguedProductId: Number(cataloguedProductId),
              })
            }}
          />
        ) : (
          <AddItem line={record} />
        )}
      </div>
    )
  }

  const renderMoreOptions = (record: RequisitionLine) => {
    const { cataloguedProductId } = record
    return (
      <>
        <MoreOptionsMenu
          showDetails={() => onOpenProductDetailsModal(Number(cataloguedProductId))}
          showAddToBuyList={() => onOpenAddToBuyListModal(Number(cataloguedProductId))}
        >
          <OptionIcon className="h-10 w-10 text-gray-500" />
        </MoreOptionsMenu>
        <AddToBuyListModal
          requisitionLine={record}
          showModal={showAddToBuyListModal && activeLineId === record.cataloguedProductId}
          setShowModal={setShowAddToBuyListModal}
        />
        <ProductModal
          showModal={showProductDetailsModal && activeLineId === record.cataloguedProductId}
          setShowModal={setShowProductDetailsModal}
          line={record}
          supplier={record?.supplier as Supplier}
        />
      </>
    )
  }

  return (
    <>
      <Table
        dataSource={possibleRequisitionLines}
        keyExtractor={(record) => String(record.cataloguedProductId)}
        columns={[
          {
            title: t('shopPage.productList.productCard.imageTableHeader', 'Image'),
            key: 'image',
            headerCellStyles: 'px-4 py-3 text-center',
            cellStyles: 'px-4 py-3 text-center',
            minWidth: 90,
            maxWidth: 90,
            render: (record) => (
              <ProductImage
                image={record?.image || NoImage}
                clickToOpenModal
                line={record}
                supplier={record?.supplier as Supplier}
              />
            ),
          },
          {
            title: t('shopPage.productList.productCard.itemTableHeader', 'Item'),
            key: 'item',
            headerCellStyles: 'pl-0 pr-4 py-3',
            cellStyles: 'pl-0 pr-4 py-3',
            minWidth: 280,
            maxWidth: 300,
            render: (record: RequisitionLine) =>
              record?.product && (
                <ProductItem product={record?.product} clickToOpenModal supplier={record?.supplier} line={record} />
              ),
          },
          {
            title: t('shopPage.productList.productCard.supplierTableHeader', 'Supplier'),
            key: 'supplier',
            headerCellStyles: 'pl-0 pr-4 py-3',
            cellStyles: 'pl-0 pr-4 py-3',
            minWidth: 240,
            grow: true,
            render: renderSupplier,
          },
          {
            title: t('general.quotes', 'Quotes'),
            key: 'quotes',
            headerCellStyles: 'py-3 text-center',
            cellStyles: 'text-center py-3',
            minWidth: 70,
            maxWidth: 70,
            render: ({ availableQuotes, product, supplier, image, quantity }: RequisitionLine) => (
              <SupplierQuotesButton
                product={product}
                prices={availableQuotes}
                currentSupplierId={supplier?.id}
                image={image}
                basicShop
                quantity={quantity}
              />
            ),
          },
          {
            title: t('shopPage.productList.productCard.unitPriceTableHeader', 'Unit Price'),
            key: 'unitPrice',
            headerCellStyles: 'px-4 py-3 text-right',
            cellStyles: 'min-w-[110px] max-w-[150px] px-4 py-3 text-right',
            minWidth: 110,
            maxWidth: 150,
            render: renderUnitPrice,
          },
          {
            title: t('shopPage.productList.productCard.addItemTableHeader', 'Add Item'),
            key: 'addItem',
            headerCellStyles: 'py-3 text-center',
            cellStyles: 'py-3 text-center',
            minWidth: 180,
            maxWidth: 180,
            render: renderAddItem,
          },
          {
            title: t('shopPage.productList.productCard.moreTableHeader', 'More'),
            key: 'more',
            headerCellStyles: ' py-3 text-center',
            cellStyles: 'py-3 text-center',
            minWidth: 70,
            maxWidth: 70,
            render: renderMoreOptions,
          },
        ]}
      />
    </>
  )
}

export default memo(RequisitionLinesListView)
