import { useLazyQuery } from '@apollo/client'
import { Dispatch, SetStateAction, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import LineDetails from './LineDetails'
import POLineItem from './POLineItem'
import RelatedInvoiceLineItems from './RelatedInvoiceLineItems'
import RelatedReceivingDocumentLines from './RelatedReceivingDocumentLines'

import {
  GetInvoiceLineItemDocument,
  InvoiceLineItem,
  PurchaseOrderLineItem,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { Button, Modal, QueryResult } from '@/modules/shared/components'
import { PURCHASING_GRAPHQL_API, SUPPORT_LINK } from '@/modules/shared/constants'

interface InvoiceLineModalProps {
  show: boolean
  setShow: Dispatch<SetStateAction<boolean>>
  invoiceLineItemId: number
  invoiceId: number
}

export default function InvoiceLineModal({ show, setShow, invoiceLineItemId, invoiceId }: InvoiceLineModalProps) {
  const { t } = useTranslation()

  const [fetchInvoiceLine, { data, loading, error }] = useLazyQuery(GetInvoiceLineItemDocument, {
    variables: {
      invoiceId,
      invoiceLineItemId,
    },
    context: { uri: PURCHASING_GRAPHQL_API },
  })

  useEffect(() => {
    if (show) fetchInvoiceLine()
  }, [show])

  const onCloseModal = () => setShow(false)
  const invoiceLineItem = data?.currentPurchaser?.invoice?.invoiceLineItem as InvoiceLineItem
  const purchaseOrderLineItem = data?.currentPurchaser?.invoice?.invoiceLineItem
    ?.purchaseOrderLineItem as PurchaseOrderLineItem

  return (
    <Modal showModal={show} onCloseModal={onCloseModal}>
      <Modal.Panel className="flex h-[80vh] w-full flex-col overflow-hidden rounded-md bg-white shadow-xl transition-all md:max-w-[600px]">
        <Modal.Title
          title={t('invoices.invoice.invoiceLineModal.title', 'Invoice Line Info')}
          onCloseModal={onCloseModal}
        />

        <Modal.Body className="space-y-4">
          <QueryResult loading={loading} error={error}>
            <section className="rounded-md bg-gray-200 p-4">
              <p className="text-sm">
                {t(
                  'invoices.invoice.invoiceLineModal.description',
                  'Here you can browse detailed information relating to this Invoice line. This can be useful to understand the Linked and Received status of the line, and understand any variances between Invoice price and Purchase Order price.'
                )}
              </p>
            </section>
            <LineDetails invoiceLineItem={invoiceLineItem} />
            <POLineItem purchaseOrderLineItem={purchaseOrderLineItem} />
            <RelatedReceivingDocumentLines invoiceLineItem={invoiceLineItem} />
            <RelatedInvoiceLineItems invoiceLineItem={invoiceLineItem} />
          </QueryResult>
        </Modal.Body>
        <Modal.Footer className="flex flex-col-reverse items-center  justify-center gap-2 md:flex-row md:justify-between">
          <a
            className="text-sm text-primary"
            data-testid="learn-more-related-docs-link"
            href={SUPPORT_LINK}
            target="_blank"
            rel="noreferrer"
          >
            {t('invoices.invoice.invoiceLineModal.getHelp', 'Get Help with Invoice Lines')}
          </a>
          <Button className="h-11 w-full rounded-md bg-gray-200 px-5 text-sm md:w-fit" onClick={onCloseModal}>
            {t('general.close', 'Close')}
          </Button>
        </Modal.Footer>
      </Modal.Panel>
    </Modal>
  )
}
