import classNames from 'classnames'
import { useState } from 'react'

import Button from '../../button'

import { Price, Product, Supplier } from '@/graphql/purchasing/generated/purchasing_graphql'
import SupplierQuotesModal from '@/modules/shared/components/supplier-quotes-modal/SupplierQuotesModal'
import { ChevronDownIcon } from '@/modules/shared/icons'

export interface SupplierSelectorButtonProps {
  supplier: Supplier | null | undefined
  product?: Product | null
  prices?: Price[] | null
  lineId?: number | null
  refetch: () => void
  image?: string | null
  cataloguedProductId?: number | null
  quantity?: number | null
}

export default function SupplierSelectorButton({
  supplier,
  product,
  prices,
  lineId,
  refetch,
  image,
  cataloguedProductId,
  quantity,
}: SupplierSelectorButtonProps) {
  const { name, id } = supplier || {}
  const [showSupplierQuotesModal, setShowSupplierQuotesModal] = useState<boolean>(false)

  return (
    <>
      <Button
        data-testid={`filter-by-supplier-button`}
        className={classNames('flex h-11 w-full items-center justify-between rounded-md border px-4', {
          'cursor-pointer bg-white': lineId,
          'bg-gray-200': !lineId,
        })}
        onClick={() => product && prices && setShowSupplierQuotesModal(true)}
      >
        <p className="truncate text-sm leading-5">{name}</p>
        <ChevronDownIcon className="h-5 w-5" />
      </Button>
      {product && prices && (
        <SupplierQuotesModal
          showModal={showSupplierQuotesModal}
          setShowModal={setShowSupplierQuotesModal}
          product={product}
          prices={prices}
          currentSupplierId={id || undefined}
          lineId={lineId || undefined}
          image={image}
          cataloguedProductId={cataloguedProductId}
          originalQuantity={quantity}
        />
      )}
    </>
  )
}
