import { useTranslation } from 'react-i18next'
import { StringParam, useQueryParam } from 'use-query-params'

import AdvancedSearchFilter from './AdvancedSearchFilter'

import { Checkbox, Tooltip } from '@/modules/shared/components'
import { QuestionMarkIcon } from '@/modules/shared/icons'
import { QueryParameter, ReceivingNotesReceivedState } from '@/modules/shared/types'

export const FilterByReceivingNotes = () => {
  const [receivingNotesURLParam, setReceivingNotesURLParam] = useQueryParam(QueryParameter.ReceivingNotes, StringParam)

  const handleOptionChange = (option: ReceivingNotesReceivedState) => {
    const optionValue = receivingNotesURLParam === option ? null : option
    setReceivingNotesURLParam(optionValue)
  }

  const { t } = useTranslation()
  return (
    <AdvancedSearchFilter
      buttonTestId="filters-section"
      buttonTitle={t('advancedSearch.filterByReceivingNotes.filterTitle', 'Filter by Receiving Notes')}
    >
      <div className="flex flex-col gap-2">
        <Checkbox
          onChange={() => handleOptionChange(ReceivingNotesReceivedState.received)}
          isSelected={receivingNotesURLParam === ReceivingNotesReceivedState.received}
        >
          <span data-testid="po-received">
            {t('advancedSearch.matchedInvoices.receivedLabel', 'At least one completed Receiving Note')}
          </span>
          <Tooltip
            content={t(
              'advancedSearch.matchedInvoices.receivedTooltip',
              'Show only Purchase Orders that have at least one completed Goods Receiving Note'
            )}
          >
            <QuestionMarkIcon className="h-5 w-5" />
          </Tooltip>
        </Checkbox>
        <Checkbox
          onChange={() => handleOptionChange(ReceivingNotesReceivedState.notReceived)}
          isSelected={receivingNotesURLParam === ReceivingNotesReceivedState.notReceived}
        >
          <span data-testid="po-not-received">
            {t('advancedSearch.noMatchedInvoices.notReceivedLabel', 'No completed Receiving Notes')}
          </span>
          <Tooltip
            content={t(
              'advancedSearch.noMatchedInvoices.notReceivedTooltip',
              'Show only Purchase Orders that have no completed Goods Receiving Notes'
            )}
          >
            <QuestionMarkIcon className="h-5 w-5" />
          </Tooltip>
        </Checkbox>
      </div>
    </AdvancedSearchFilter>
  )
}
