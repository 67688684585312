import { Helmet } from 'react-helmet-async'

import CartHeader from '../view-cart/page-header/CartHeader'
import ShopCarousel from './ShopCarousel'
import { useGetPossibleRequisitionLines } from './hooks/useGetPossibleRequisitionLines'
import ProductList from './product-list'

import { useCurrentOrganisation } from '@/modules/purchasing/hooks'
import { ShopHeader } from '@/modules/requisitions/layouts'
import { QueryResult } from '@/modules/shared/components'

function Shop() {
  const {
    loading,
    requisition,
    error,
    requisitionId,
    currentPurchaser,
    onFilter,
    possibleRequisitionLines,
    onFetchMoreProduct,
    hasNextPage,
    lines,
    searchProductLoading,
  } = useGetPossibleRequisitionLines()
  const { currentOrganisation } = useCurrentOrganisation()

  return (
    <>
      <Helmet>
        <title>{requisition?.requisitionNumber}</title>
      </Helmet>
      <ShopHeader />
      <div className="mx-auto w-full p-4 lg:max-w-[1800px]">
        <QueryResult loading={loading} error={error}>
          {requisitionId && currentPurchaser && (
            <div className="flex flex-col gap-5">
              <CartHeader isShop />
              {currentOrganisation?.featureFlags?.promoBanners && <ShopCarousel />}
              <ProductList
                prNumber={requisition?.requisitionNumber}
                prReference={requisition?.reference || ''}
                possibleRequisitionLines={possibleRequisitionLines}
                onFetchMoreProduct={onFetchMoreProduct}
                searchProductLoading={searchProductLoading}
                hasMoreProduct={Boolean(hasNextPage)}
                lines={lines}
                onFilter={onFilter}
              />
            </div>
          )}
        </QueryResult>
      </div>
    </>
  )
}

export default Shop
