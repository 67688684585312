import { useTranslation } from 'react-i18next'
import { Link, generatePath } from 'react-router-dom'

import { InvoiceLineItem, ReceivingDocumentLineItem } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Accordion, TableLineItem } from '@/modules/shared/components'
import { useMoney } from '@/modules/shared/hooks'
import { NewTabIcon } from '@/modules/shared/icons'
import { Dates } from '@/modules/shared/types'
import { extractEdges, formatDate } from '@/modules/shared/utils'

interface LinkedInvoiceLinesProps {
  receivingDocumentLineItem: ReceivingDocumentLineItem
}

export default function LinkedInvoiceLines({ receivingDocumentLineItem }: LinkedInvoiceLinesProps) {
  const { t } = useTranslation()
  const { formatMoney } = useMoney()
  const linkedInvoicedLines = extractEdges<InvoiceLineItem>(
    receivingDocumentLineItem.purchaseOrderLineItem?.invoiceLineItems
    // TODO: could not sort the list using ransack
  ).sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())

  return (
    <section>
      <strong className="text-sm">{t('general.linkedInvoiceLines', 'Linked Invoice Lines')}</strong>
      <p className="text-sm text-gray-500">
        {t(
          'receivingNotes.advancedModal.linkedInvoiceLinesDesc',
          'If this Purchase Order Line has been linked to one or more Invoice Lines, each linked Invoice Line will be shown here. This can be helpful to compare the quantity and unit price on the Purchase Order, with the quantity and unit price that has been Invoiced.'
        )}
      </p>
      {linkedInvoicedLines.map((linkedInvoicedLine, index) => {
        const { id, invoice, quantity, unitPrice, taxPercentage } = linkedInvoicedLine
        return (
          <Accordion
            key={id}
            testId={`linked-invoice-line-${index}`}
            title={invoice?.invoiceNumber || ''}
            buttonClassName="px-3 h-11 mt-4"
            titleClassName="font-bold"
          >
            <div className="mt-4 border" data-testid={`linked-invoice-line-content-${index}`}>
              <TableLineItem
                title={t('general.invoice', 'Invoice')}
                tooltip={t(
                  'receivingNotes.advancedModal.invoiceTooltip',
                  'The Invoice that contains the linked Invoice Line.'
                )}
                value={
                  <Link
                    className="flex items-center text-sm text-primary"
                    to={generatePath('/invoices/:id/summary', { id: String(invoice?.id) })}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {invoice?.invoiceNumber}
                    <NewTabIcon className="ml-1 h-5 w-5" />
                  </Link>
                }
              />
              <TableLineItem
                title={t('general.invoiceQty', 'Invoice Quantity')}
                tooltip={t(
                  'receivingNotes.advancedModal.invoiceQtyTooltip',
                  'The quantity on the linked Invoice Line.'
                )}
                value={quantity}
              />
              <TableLineItem
                title={t('general.invoiceUnitPrice', 'Invoice Unit Price')}
                tooltip={t(
                  'receivingNotes.advancedModal.linkedInvoiceUnitPriceTooltip',
                  'The unit price on the linked Invoice Line.'
                )}
                value={formatMoney(unitPrice)}
              />
              <TableLineItem
                title={t('general.invoiceTaxPercantage', 'Invoice Tax Percantage')}
                tooltip={t(
                  'receivingNotes.advancedModal.invoiceTaxPercantageTooltip',
                  'The tax percentage on the linked Invoice Line.'
                )}
                value={`${taxPercentage}%`}
              />
              <TableLineItem
                title={t('general.createdDate', 'Created Date')}
                tooltip={t(
                  'receivingNotes.advancedModal.createdDateTooltip',
                  'The time and date that Invoice Line was created.'
                )}
                value={formatDate(Dates.Long, linkedInvoicedLine.createdAt)}
              />
            </div>
          </Accordion>
        )
      })}
      {linkedInvoicedLines.length === 0 && (
        <div className="mt-4 rounded-md border bg-gray-100 p-4">
          <p className="text-center text-sm text-gray-500">
            {t(
              'receivingNotes.advancedModal.noLinkedInvoiceLines',
              'No Invoice Lines have been linked to this Purchase Order Line yet. It may be that an Invoice has yet to be matched to this Purchase Order, or if an Invoice has been matched, no Invoice Line bas been linked to this Purchase Order Line. You can still receive this product, and the Purchase Order unit price will be recorded as the Receiving unit price.'
            )}
          </p>
        </div>
      )}
    </section>
  )
}
