import { Dispatch, ReactNode, SetStateAction } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { InvoiceActionTypes, InvoiceStatuses } from '@/modules/invoices/types'
import SuccessModal from '@/modules/shared/components/success-modal'
import { CheckIcon, FlagIcon } from '@/modules/shared/icons'

interface InvoiceActionSuccessModalProps {
  state: InvoiceStatuses
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<boolean>>
  invoiceAction: InvoiceActionTypes
}

type InvoiceActionSuccessType = {
  title: string
  body: string | ReactNode
  icon: JSX.Element | string
}

export default function InvoiceActionSuccessModal({
  state,
  showModal,
  setShowModal,
  invoiceAction,
}: InvoiceActionSuccessModalProps) {
  const { t } = useTranslation()

  const getReconciledBody = () => {
    switch (invoiceAction) {
      case InvoiceActionTypes.Halt:
        return (
          <Trans t={t} i18nKey="invoices.invoice.actionSuccess.reconciled.bodyHalt">
            You have successfully Halted the Invoice and it has now been reverted to a Reconciled state. Changes cannot
            be made to the Invoice while it is in a Reconciled state. If you do wish to make changes you will need to
            Re-Open the Invoice.
          </Trans>
        )
      case InvoiceActionTypes.Complete:
        return (
          <Trans t={t} i18nKey="invoices.invoice.actionSuccess.reconciled.bodyComplete">
            The status of the Invoice has been updated to Reconciled. The Reconciled state means there were no Flags
            raised and the Invoice was automatically approved. Changes can no longer be made to the Invoice while it is
            in a Reconciled state. You may wish to mark the Invoice as Ready for Processing, and it will be made
            available in your Accounts Payable Hub for inclusion in an upcoming Export. If you do wish to make changes
            to the Invoice you will need to Re-Open it.
          </Trans>
        )
      case InvoiceActionTypes.FinancialApprove:
        return (
          <Trans t={t} i18nKey="invoices.invoice.actionSuccess.reconciled.bodyFinancialApprove">
            You have successfully granted Financial Approval for this Invoice and the Invoice has been updated to
            Reconciled. Changes can no longer be made to the Invoice while it is in a Reconciled state. You may wish to
            mark the Invoice as Ready for Processing, and this will place the Invoice into your PurchasePlus Accounts
            Payable Hub, ready to be processed. Once the Invoice has been exported from Accounts Payable Hub to your
            external Accounting system it will be automatically marked as Processed.
          </Trans>
        )
      case InvoiceActionTypes.OperationalApprove:
        return (
          <Trans t={t} i18nKey="invoices.invoice.actionSuccess.reconciled.bodyOperationalApprove">
            You have successfully granted Operational Approval for this Invoice and the Invoice has been updated to
            Reconciled. Changes can no longer be made to the Invoice while it is in a Reconciled state. You may wish to
            mark the Invoice as Ready for Processing, and this will place the Invoice into your PurchasePlus Accounts
            Payable Hub, ready to be processed. Once the Invoice has been exported from Accounts Payable Hub to your
            external Accounting system it will be automatically marked as Processed.'
          </Trans>
        )
      default:
        return null
    }
  }

  const getFlaggedState = () => {
    switch (invoiceAction) {
      case InvoiceActionTypes.Complete:
        return {
          title: t('invoices.invoice.actionSuccess.flagged.titleComplete', 'Invoice is Flagged'),
          body: (
            <Trans t={t} i18nKey="invoices.invoice.actionSuccess.flagged.bodyComplete">
              The status of the Invoice has been updated to Flagged. There may be a variety of reasons why the Invoice
              has been Flagged, and you will now be able to visit the Flags tab of the Invoice to inspect each of the
              Flag reasons. There may also be Flags on each Invoice line. A Flagged Invoice requires both Financial and
              Operational Approval by one or more users at your organisation. You may begin resolving each of the Flags,
              but the Flags do not need to be resolved prior to approval being granted.
            </Trans>
          ),
          icon: <FlagIcon className="h-12 w-12 rounded-full bg-error/10 p-2 text-error" />,
        }
      case InvoiceActionTypes.FinancialApprove:
        return {
          title: t('invoices.invoice.actionSuccess.flagged.titleFinancial', 'Financial Approval Granted'),
          body: (
            <Trans t={t} i18nKey="invoices.invoice.actionSuccess.flagged.bodyFinancial">
              You have successfully granted Financial Approval for this Invoice and now an Operational Approval is
              required. The Invoice will remain in the Flagged state until this Operational Approval is granted. When
              both an Operational Approval and a Financial Approval have been granted, the Invoice will progress to a
              Reconciled state.
            </Trans>
          ),
          icon: <CheckIcon className="h-12 w-12 text-success" />,
        }
      case InvoiceActionTypes.OperationalApprove:
        return {
          title: t('invoices.invoice.actionSuccess.flagged.titleOperational', 'Operational Approval Granted'),
          body: (
            <Trans t={t} i18nKey="invoices.invoice.actionSuccess.flagged.bodyOperational">
              You have successfully granted Operational Approval for this Invoice and now a Financial Approval is
              required. The Invoice will remain in the Flagged state until this Financial Approval is granted. When both
              a Financial Approval and an Operational Approval have been granted, the Invoice will progress to a
              Reconciled state.
            </Trans>
          ),
          icon: <CheckIcon className="h-12 w-12 text-success" />,
        }
      default:
        return { title: '', body: '', icon: '' }
    }
  }

  const getInvoiceActionSuccessText = (): InvoiceActionSuccessType => {
    switch (state) {
      case InvoiceStatuses.Open:
        return {
          title: t('invoices.invoice.actionSuccess.open.title', 'Invoice is Open'),
          body: (
            <Trans t={t} i18nKey="invoices.invoice.actionSuccess.open.body">
              You have successfully Re-Opened the Invoice and you can now make changes to it as needed. When you are
              ready, you can Mark the Invoice as Complete and it will be checked and placed in a Flagged state if flags
              are raised, or placed in a Reconciled state if no flags are raised.
            </Trans>
          ),
          icon: <CheckIcon className="h-12 w-12 text-primary" />,
        }
      case InvoiceStatuses.Cancelled:
        return {
          title: t('invoices.invoice.actionSuccess.cancelled.title', 'Invoice is Cancelled'),
          body: (
            <Trans t={t} i18nKey="invoices.invoice.actionSuccess.cancelled.body">
              You have successfully Cancelled the Invoice and changes can no longer be made while it is in a Cancelled
              state. If you need to, you can Re-Open the Invoice to resume making changes to it.
            </Trans>
          ),
          icon: <CheckIcon className="h-12 w-12 text-gray-600" />,
        }
      case InvoiceStatuses.Parked:
        return {
          title: t('invoices.invoice.actionSuccess.parked.title', 'Invoice is Parked'),
          body: (
            <Trans t={t} i18nKey="invoices.invoice.actionSuccess.parked.body">
              You have successfully Parked the Invoice and changes can no longer be made while it is in a Parked state.
              If you need to, you can Re-Open the Invoice to resume making changes to it, or you can Cancel it.
            </Trans>
          ),
          icon: <CheckIcon className="h-12 w-12 rounded-full text-gray-300" />,
        }
      case InvoiceStatuses.Reconciled:
        return {
          title: t('invoices.invoice.actionSuccess.reconciled.title', 'Invoice is Reconciled'),
          body: getReconciledBody(),
          icon: <CheckIcon className="h-12 w-12 text-success" />,
        }
      case InvoiceStatuses.Processing:
        return {
          title: t('invoices.invoice.actionSuccess.processing.title', 'Invoice is Processing'),
          body: (
            <Trans t={t} i18nKey="invoices.invoice.actionSuccess.processing.body">
              You have successfully marked the Invoice as Ready for Processing, and it is now available in your Accounts
              Payable Hub for inclusion in an upcoming Export. Once the Invoice has been included in an Export, it will
              be updated to Processed. Changes cannot be made to the Invoice while it is in a Processing state. If you
              do wish to revert the Invoice to a Reconciled state, you will need to Halt the Invoice.
            </Trans>
          ),
          icon: <CheckIcon className="h-12 w-12 text-orange" />,
        }
      case InvoiceStatuses.Flagged:
        return {
          title: getFlaggedState().title,
          body: getFlaggedState().body,
          icon: getFlaggedState().icon,
        }
      default:
        return { title: '', body: '', icon: '' }
    }
  }

  return (
    <SuccessModal
      showModal={showModal}
      setShowModal={setShowModal}
      icon={getInvoiceActionSuccessText().icon}
      title={getInvoiceActionSuccessText().title}
      body={getInvoiceActionSuccessText().body}
    />
  )
}
