import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import MatchPOToInvoiceModal from '../page-header/MatchPOToInvoiceModal'
import BalanceContainerRow from '../page-header/balance-panel/BalanceContainerRow'
import InvoiceLinesTableView from './table-view/InvoiceLinesTableView'

import { Supplier } from '@/graphql/purchasing/generated/purchasing_graphql'
import { useGetInvoice } from '@/modules/invoices/hooks'
import { InvoiceInceptionTypes, InvoiceStatuses } from '@/modules/invoices/types'
import SupplierDetailsModal from '@/modules/requisitions/pages/view-cart/summary/SupplierDetailsModal'
import { QueryResult } from '@/modules/shared/components'
import { useMoney } from '@/modules/shared/hooks'
import { ControlBarIcon } from '@/modules/shared/icons'

function InvoiceSummary() {
  const { formatMoney } = useMoney()
  const { t } = useTranslation()
  const [activeSupplierDetailsModal, setActiveSupplierDetailsModal] = useState<number | null>(null)
  const [showMatchPOModal, setShowMatchPOModal] = useState<boolean>(false)

  const {
    loading,
    error,
    invoiceNumber,
    supplier,
    lineTotal,
    lineTaxTotal,
    deliveryChargeExTax,
    deliveryChargeTax,
    totalValueExTax,
    totalValue,
    totalTaxValue,
    adjustmentExTax,
    adjustmentTax,
    state,
    purchaseOrderId,
    invoiceLinesWithFlags,
    invoiceId,
    flaggedLines,
    inception,
  } = useGetInvoice()

  const showFlagsColumn = !!flaggedLines.length && (state === InvoiceStatuses.Flagged || state === InvoiceStatuses.Open)
  const isEditable =
    (state === InvoiceStatuses.Open || state === InvoiceStatuses.Flagged) && inception === InvoiceInceptionTypes.Manual

  return (
    <>
      <QueryResult loading={loading} error={error}>
        <div className="flex items-center">
          <ControlBarIcon className=" h-10 w-10" />
          <p className="ml-3 text-sm">{invoiceNumber}</p>
        </div>
        <section className="mt-4 flex flex-col gap-y-12" data-testid="invoice-info">
          <div className="flex flex-col gap-y-3">
            <div className="flex justify-between rounded-md bg-white px-5 py-3 shadow-sm">
              <strong className="text-sm">{supplier?.name}</strong>
              <p
                onClick={() => setActiveSupplierDetailsModal(Number(supplier?.id))}
                className="cursor-pointer text-sm text-primary"
                data-testid="supplier-details-button"
              >
                {t('shopPage.cart.summary.details.button', 'View Supplier Details')}
              </p>
            </div>
            <div className="block text-sm lg:hidden">{/* TODO: Responsive view */}</div>
            <div className="hidden text-sm lg:block">
              <InvoiceLinesTableView
                invoiceLines={invoiceLinesWithFlags}
                state={state as InvoiceStatuses}
                supplier={supplier as Supplier}
                purchaseOrderId={purchaseOrderId}
                isEditable={isEditable}
                showFlagsColumn={showFlagsColumn}
              />
              {invoiceLinesWithFlags.length === 0 && (
                <div className="rounded-md border border-gray-200 py-8 text-center text-gray-600">
                  {t('invoice.invoice.noLines', 'There are no Lines on this Invoice.')}
                </div>
              )}
            </div>
            <div className="rounded-md bg-white shadow-sm">
              <div className="flex flex-col gap-3 p-3 text-sm" data-testid="invoice-payment">
                <BalanceContainerRow
                  title={t('invoice.invoice.invoiceLinesTotalExTax', 'Invoice Lines Total (Excluding Tax)')}
                  value={(Number(lineTotal) || 0) - (Number(lineTaxTotal) || 0)}
                />
                <BalanceContainerRow
                  title={t('invoice.invoice.invoiceLinesTax', 'Invoice Lines Total Tax')}
                  value={lineTaxTotal}
                />
                <BalanceContainerRow
                  title={t('invoice.invoice.deliveryFeesExTax', 'Delivery Fees (Excluding Tax)')}
                  value={deliveryChargeExTax}
                />
                <BalanceContainerRow
                  title={t('invoice.invoice.deliveryFeesTax', 'Delivery Fees Tax')}
                  value={deliveryChargeTax}
                />
                {(!!adjustmentExTax || !!adjustmentTax) && (
                  <>
                    <BalanceContainerRow
                      title={t('invoice.invoice.adjustmentsExTax', 'Adjustments (Excluding Tax)')}
                      value={adjustmentExTax}
                    />
                    <BalanceContainerRow
                      title={t('invoice.invoice.adjustmentsTax', 'Adjustments Tax')}
                      value={adjustmentTax}
                    />
                  </>
                )}
              </div>
              <hr />
              <div className="flex flex-col gap-3 p-3 text-sm" data-testid="invoice-payment">
                <BalanceContainerRow
                  title={t('invoice.invoice.totalExTax', 'Invoice Total (Excluding Tax)')}
                  value={totalValueExTax}
                />
                <BalanceContainerRow title={t('invoice.invoice.totalTax', 'Invoice Total Tax')} value={totalTaxValue} />
                <div className="flex items-center justify-between px-2 text-lg font-bold" data-testid="invoice-total">
                  <p>{t('invoices.invoice.balance.invoiceTotal', 'Invoice Total')}</p>
                  {formatMoney(totalValue)}
                </div>
              </div>
            </div>
            <SupplierDetailsModal
              supplier={supplier as Supplier}
              showModal={activeSupplierDetailsModal === supplier?.id}
              setShowModal={setActiveSupplierDetailsModal}
            />
            <MatchPOToInvoiceModal
              invoiceId={invoiceId}
              invoiceNumber={invoiceNumber}
              supplierId={supplier?.id}
              supplierName={supplier?.name}
              showModal={showMatchPOModal}
              setShowModal={setShowMatchPOModal}
            />
          </div>
        </section>
      </QueryResult>
    </>
  )
}

export default InvoiceSummary
