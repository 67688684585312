import { useTranslation } from 'react-i18next'
import { Link, generatePath } from 'react-router-dom'

import { PurchaseOrder, ReceivingDocument } from '@/graphql/purchasing/generated/purchasing_graphql'
import { ReceivingNoteStatus } from '@/modules/receiving-notes/components'
import { Fallback } from '@/modules/shared/components'
import { TableRowDouble, TableRowSingle } from '@/modules/shared/components/page-header-details-table'
import { ENTERPRISE_RECEIVING_TRANSFER } from '@/modules/shared/constants'
import { Dates } from '@/modules/shared/types'
import { formatDate } from '@/modules/shared/utils'

interface DetailsProps {
  receivingNote: ReceivingDocument
  purchaseOrder: PurchaseOrder
}

export default function Details({ receivingNote, purchaseOrder }: DetailsProps) {
  const { receivingDocumentNumber, receivedDate, workflowState, receiver, destination, transfer } = receivingNote

  const { t } = useTranslation()

  return (
    <section className="flex items-center justify-between rounded-md  bg-white p-4 text-sm shadow-sm">
      <div
        className="w-full divide-y divide-gray-300 overflow-auto rounded-md border border-gray-300"
        data-testid="details-table"
      >
        <TableRowDouble
          leftTitle={t('receivingNotes.details.grNumber', 'GR Number')}
          leftTooltip={t(
            'receivingNotes.details.grNumberTooltip',
            'The unique number to identify this Goods Receiving Note.'
          )}
          leftTestId="grNumber"
          leftValue={receivingDocumentNumber}
          rightTitle={t('receivingNotes.details.purchaseOrder', 'Purchase Order')}
          rightTooltip={t(
            'receivingNotes.details.purchaseOrderTooltip',
            'The Purchase Order that this Goods Receiving Note was created against.'
          )}
          rightTestId="purchaseOrder"
          rightValue={
            <Link
              to={generatePath('/purchase-orders/:purchaseOrderId/summary', {
                purchaseOrderId: String(purchaseOrder.id),
              })}
              className="text-primary"
            >
              {purchaseOrder?.purchaseOrderNumber}
            </Link>
          }
        />
        <TableRowSingle
          titleText={t('receivingNotes.details.receivedDate', 'Received Date')}
          tooltipText={t(
            'receivingNotes.details.receivedDateTooltip',
            'The time and date that the Goods Receiving Note was created.'
          )}
          testId="receiviedDate"
          value={formatDate(Dates.Long, receivedDate)}
        />
        <TableRowSingle
          titleText={t('receivingNotes.details.status', 'Status')}
          tooltipText={t(
            'receivingNotes.details.statusTooltip',
            'The current status of this Goods Receiving Note. Click to see an explanation of this status.'
          )}
          testId="status"
          value={<ReceivingNoteStatus state={workflowState} />}
        />
        <TableRowSingle
          titleText={t('receivingNotes.details.supplier', 'Supplier')}
          tooltipText={t(
            'receivingNotes.details.supplierTooltip',
            'The Supplier from whom the goods are being received.'
          )}
          testId="supplier"
          value={purchaseOrder?.supplier?.name}
        />
        <TableRowSingle
          titleText={t('receivingNotes.details.receivedBy', 'Received By')}
          tooltipText={t('receivingNotes.details.receivedByTooltip', 'The user who created the Goods Receiving Note.')}
          testId="receivedBy"
          value={receiver?.fullName}
        />
        <TableRowSingle
          titleText={t('receivingNotes.details.receivedToInventory', 'Received To Inventory')}
          tooltipText={t(
            'receivingNotes.details.receivedToInventoryTooltip',
            'Indicates if the user who created the Goods Receiving Note chose to receive the goods to a Stock Location within your Inventory.'
          )}
          testId="receivedToInventory"
          value={destination?.name ? t('general.yes', 'Yes') : t('general.no', 'No')}
        />
        <TableRowSingle
          titleText={t('receivingNotes.details.stockLocation', 'Stock Location')}
          tooltipText={t(
            'receivingNotes.details.stockLocationTooltip',
            'If the goods were received to Inventory, the Stock Location that the goods were received to will appear here.'
          )}
          testId="stockLocation"
          value={<Fallback condition={!!destination?.name}>{destination?.name}</Fallback>}
        />
        <TableRowSingle
          titleText={t('receivingNotes.details.receivingTransfer', 'Receiving Transfer')}
          tooltipText={t(
            'receivingNotes.details.receivingTransferTooltip',
            'If the goods were received to Inventory, the Inventory Transfer file that accompanies this Goods Receiving Note will appear here.'
          )}
          testId="receivingTransfer"
          value={
            <Fallback condition={!!transfer?.transferNumber}>
              <Link
                to={`${ENTERPRISE_RECEIVING_TRANSFER}/${transfer?.id}`}
                className="text-primary"
                target="_blank"
                rel="noreferrer"
              >
                {transfer?.transferNumber}
              </Link>
            </Fallback>
          }
        />
      </div>
    </section>
  )
}
