import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import AddItem from '../../AddItem'
import AddToBuyListModal from '../../AddToBuyListModal'

import NoImage from '@/assets/images/no-image.svg'
import { RequisitionLine, Supplier } from '@/graphql/purchasing/generated/purchasing_graphql'
import { useChangeRequisitionLineQuantity } from '@/modules/requisitions/hooks'
import { formatProductItem } from '@/modules/requisitions/utils'
import { MoreOptionsMenu, Stepper } from '@/modules/shared/components'
import ProductModal from '@/modules/shared/components/product-modal/ProductModal'
import { SupplierQuotesButton } from '@/modules/shared/components/table/table-column-items'
import { useMoney } from '@/modules/shared/hooks'
import { OptionIcon } from '@/modules/shared/icons'

interface ProductCardTileViewProps {
  requisitionLine: RequisitionLine
  isAddedToCart: boolean
}

function ProductCardTileView({ requisitionLine, isAddedToCart }: ProductCardTileViewProps) {
  const {
    id: lineId,
    productId,
    image,
    unitPrice,
    taxPercentage,
    supplier,
    quantity,
    product,
    availableQuotes,
    cataloguedProductId,
  } = requisitionLine
  const { itemDescription, brand, itemSize, itemMeasure, itemPackName, concatenatedSellUnit } = product || {}
  const { formatMoney } = useMoney()
  const { t } = useTranslation()
  const [showProductDetailsModal, setShowProductDetailsModal] = useState(false)
  const [showAddToBuyListModal, setShowAddToBuyListModal] = useState(false)

  const { onUpdateRequisitionLineQuantity, loading } = useChangeRequisitionLineQuantity()

  return (
    <div data-testid="product-detail" className="rounded-md border bg-white shadow-sm">
      <div
        className="flex cursor-pointer items-center border-b border-gray-200 p-3 sm:px-5 sm:py-4"
        data-testid="tileview-click-area"
      >
        <div
          className="mr-5 h-[68px] w-[68px] flex-none border border-gray-200 sm:h-24 sm:w-24"
          onClick={() => setShowProductDetailsModal(true)}
        >
          <img className="h-full w-full" src={image || NoImage} alt="product" />
        </div>
        <div className="overflow-hidden md:w-64" onClick={() => setShowProductDetailsModal(true)}>
          <span className="space-y-1">
            <p className="break-words text-xs text-primary sm:text-sm">
              {brand} {itemDescription}
            </p>
            <p className="text-xxs text-gray-500 sm:text-xs">
              {formatProductItem({
                itemSize: itemSize,
                itemMeasure: itemMeasure,
                itemPackName: itemPackName,
                itemSellUnit: concatenatedSellUnit,
              })}
            </p>
            <p className="text-xxs sm:text-xs md:truncate">{supplier?.name}</p>
          </span>
        </div>
        <div className="mb-auto ml-auto">
          <MoreOptionsMenu
            showDetails={() => setShowProductDetailsModal(true)}
            showAddToBuyList={() => setShowAddToBuyListModal(true)}
          >
            <OptionIcon className="-mr-2 h-6 w-6 text-gray-300" data-testid="more-options-menu" />
          </MoreOptionsMenu>
        </div>
      </div>
      <div className="p-3 md:px-5 md:py-4">
        <div className="flex items-center justify-between">
          <span>
            <p className="text-base font-bold leading-tight sm:text-lg">{formatMoney(unitPrice)}</p>
            <p className="text-xxs text-gray-500 sm:text-xs">
              {taxPercentage
                ? t('shopPage.productList.productCard.taxPercentage', 'Plus {{ percent }}% Tax', {
                    percent: taxPercentage,
                  })
                : t('shopPage.productList.productCard.taxFree', 'Tax Free')}
            </p>
          </span>
          <div className="flex items-center space-x-2">
            <SupplierQuotesButton
              product={product}
              prices={availableQuotes}
              currentSupplierId={supplier?.id}
              image={image}
              basicShop
              quantity={quantity}
            />
            {isAddedToCart ? (
              <Stepper
                loading={loading}
                value={quantity || 0}
                stepperAriaLabel={t('shopPage.stepperAriaLabel', 'Entering number of products')}
                onChange={(newQty: number) =>
                  onUpdateRequisitionLineQuantity({ qty: newQty, lineId, productId, cataloguedProductId })
                }
              />
            ) : (
              <AddItem line={requisitionLine} />
            )}
          </div>
        </div>
      </div>
      <ProductModal
        line={requisitionLine}
        showModal={showProductDetailsModal}
        setShowModal={setShowProductDetailsModal}
        supplier={requisitionLine?.supplier as Supplier}
      />
      <AddToBuyListModal
        requisitionLine={requisitionLine}
        showModal={showAddToBuyListModal}
        setShowModal={setShowAddToBuyListModal}
      />
    </div>
  )
}

export default ProductCardTileView
