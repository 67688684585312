import { useMutation } from '@apollo/client'
import classNames from 'classnames'
import { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import InvoiceLineReceivedStatus from '../InvoiceLineReceivedStatus'
import LinkInvoiceModal from '../link-invoice-modal/LinkInvoiceModal'
import InvoiceLineModal from './invoice-line-modal'
import UpdateInvoiceLineModal from './update-invoice-line-modal'

import { InvoiceLineItem, ReopenInvoiceDocument, Supplier } from '@/graphql/purchasing/generated/purchasing_graphql'
import { useInvoiceLineItemModalText } from '@/modules/invoices/hooks'
import { InvoiceReceivedStatuses, InvoiceStatuses } from '@/modules/invoices/types'
import { translateFlagCode } from '@/modules/invoices/utils/translateFlagCode'
import { formatProductItem } from '@/modules/requisitions/utils'
import { Button, ConfirmDialog, Fallback, Tooltip } from '@/modules/shared/components'
import ProductModal from '@/modules/shared/components/product-modal/ProductModal'
import Table from '@/modules/shared/components/table/Table'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { useMoney } from '@/modules/shared/hooks'
import { ChainIcon, FlagIcon, InfoIcon } from '@/modules/shared/icons'
import { Themes } from '@/modules/shared/types'

interface InvoiceLinesTableViewProps {
  invoiceLines: InvoiceLineItem[]
  state: InvoiceStatuses
  purchaseOrderId: number | null | undefined
  supplier: Supplier
  isEditable: boolean
  showFlagsColumn: boolean
}

function InvoiceLinesTableView({
  invoiceLines,
  state,
  supplier,
  purchaseOrderId,
  isEditable,
  showFlagsColumn,
}: InvoiceLinesTableViewProps) {
  const { t } = useTranslation()
  const { formatMoney } = useMoney()

  const [showLinkInvoiceModal, setShowLinkInvoiceModal] = useState(false)
  const [showProductModal, setShowProductModal] = useState(false)
  const [showInvoiceLineModal, setShowInvoiceLineModal] = useState(false)
  const [showUpdateInvoiceLineModal, setShowUpdateInvoiceLineModal] = useState(false)
  const [activeInvoiceLine, setActiveInvoiceLine] = useState<number | null>(null)

  const { title, description, secondaryButton } = useInvoiceLineItemModalText(state)

  const [reopenInvoice] = useMutation(ReopenInvoiceDocument, {
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
  })

  const onHandleLineAction = ({ invoiceId }: InvoiceLineItem) => {
    reopenInvoice({ variables: { input: { id: Number(invoiceId) } } })
  }

  const isEditableInvoiceLine = (record: InvoiceLineItem) => {
    if (isEditable) {
      setShowUpdateInvoiceLineModal(true)
      setActiveInvoiceLine(record.id)
    }
  }

  const renderLinkedPO = (record: InvoiceLineItem) => {
    const { purchaseOrderLineItemId } = record
    // show if line is linked or state is open or flagged when line is unlinked but invoice is linked to a purchase order
    const allowShowInvoiceModal =
      !!purchaseOrderLineItemId ||
      (!purchaseOrderLineItemId && state === InvoiceStatuses.Open) ||
      (!purchaseOrderLineItemId && state === InvoiceStatuses.Flagged)
    return (
      <>
        <ChainIcon
          className={classNames('mx-auto h-11 w-11 cursor-pointer rounded-full p-2', {
            'bg-primary text-white': !!purchaseOrderLineItemId,
            'bg-gray-200 text-gray-500': !purchaseOrderLineItemId,
          })}
          data-testid="link-button"
          onClick={() => {
            setActiveInvoiceLine(record.id)
            setShowLinkInvoiceModal(true)
          }}
        />
        {showLinkInvoiceModal &&
          (allowShowInvoiceModal ? (
            <LinkInvoiceModal
              line={record}
              supplier={supplier}
              showModal={showLinkInvoiceModal && activeInvoiceLine === record.id}
              setShowModal={setShowLinkInvoiceModal}
              purchaseOrderId={purchaseOrderId}
            />
          ) : (
            <ConfirmDialog
              theme={Themes.Primary}
              isOpen={showLinkInvoiceModal && activeInvoiceLine === record.id}
              setIsOpen={setShowLinkInvoiceModal}
              title={title}
              description={description}
              primaryButtonLabel={t('invoices.invoice.okayGotIt', 'Okay, Got it')}
              onPrimaryBtnClick={() => setShowLinkInvoiceModal(false)}
              secondaryButtonLabel={secondaryButton}
              onSecondaryBtnClick={() => onHandleLineAction(record)}
            />
          ))}
      </>
    )
  }

  const renderProduct = (record: InvoiceLineItem) => {
    const { brand, itemDescription, itemMeasure, itemPackName, itemSize, concatenatedSellUnit } = record?.product || {}
    return (
      <>
        <span
          className="cursor-pointer text-primary"
          onClick={() => {
            setActiveInvoiceLine(record.id)
            setShowProductModal(true)
          }}
        >
          {brand} {itemDescription}
        </span>
        <span className="text-xxs text-gray-500 sm:text-xs">
          <p>
            {formatProductItem({
              itemMeasure: itemMeasure,
              itemPackName: itemPackName,
              itemSize: itemSize,
              itemSellUnit: concatenatedSellUnit,
            })}
          </p>
        </span>
        <ProductModal
          line={record}
          showModal={showProductModal && activeInvoiceLine === record.id}
          setShowModal={setShowProductModal}
          supplier={supplier}
        />
      </>
    )
  }

  const renderFlags = ({ flaggingMessages }: InvoiceLineItem) => {
    // Allows the tooltip to display the messages with line breaks
    const flagMessageText = (
      <div className="space-y-1">
        {/* The first string in the flagging messages array is the name of the product and must be excluded */}
        {flaggingMessages?.map((message, index) => (
          <p className="text-center" key={index}>
            {translateFlagCode(message.type, message.values)}
          </p>
        ))}
      </div>
    )

    return (
      <div className="flex items-center justify-center" data-testid="invoice-line-flag">
        <Fallback condition={!!flaggingMessages?.length}>
          <Tooltip content={flagMessageText}>
            <div className="flex h-10 w-10 items-center justify-center rounded-full bg-error/10 text-error">
              <FlagIcon className="h-6 w-6" />
            </div>
          </Tooltip>
        </Fallback>
      </div>
    )
  }

  const renderReceived = ({ receivedStatus, totalInvoicedQuantity, totalReceivedQuantity }: InvoiceLineItem) => {
    return (
      <InvoiceLineReceivedStatus
        receivedStatus={receivedStatus as InvoiceReceivedStatuses}
        totalInvoicedQuantity={Number(totalInvoicedQuantity)}
        totalReceivedQuantity={Number(totalReceivedQuantity)}
        purchaseOrderId={Number(purchaseOrderId)}
      />
    )
  }

  const renderInfo = ({ invoiceId, id: lineId }: InvoiceLineItem) => {
    return (
      <div className="flex items-center justify-center">
        <Button
          data-testid="invoice-line-modal-button"
          className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-200"
          onClick={() => {
            setActiveInvoiceLine(lineId)
            setShowInvoiceLineModal(true)
          }}
        >
          <InfoIcon className="h-6 w-6" />
        </Button>
        <InvoiceLineModal
          show={showInvoiceLineModal && activeInvoiceLine === lineId}
          setShow={setShowInvoiceLineModal}
          invoiceId={invoiceId}
          invoiceLineItemId={lineId}
        />
      </div>
    )
  }

  const renderQuantity = (record: InvoiceLineItem) => {
    const { quantity } = record
    return (
      <p
        data-testid="invoice-line-quantity"
        className={classNames({
          'm-auto w-fit min-w-[60px] cursor-pointer rounded-md border px-4 py-3 text-center transition duration-200 hover:bg-gray-200':
            isEditable,
        })}
        onClick={() => isEditableInvoiceLine(record)}
      >
        {quantity}
      </p>
    )
  }

  const renderUnitPrice = (record: InvoiceLineItem) => {
    const { unitPrice } = record
    return (
      <div className="flex items-center justify-end">
        <p
          data-testid="invoice-line-price"
          className={classNames({
            'w-fit min-w-[100px] cursor-pointer rounded-md border py-3 pl-4 pr-2 text-right transition duration-200 hover:bg-gray-200':
              isEditable,
          })}
          onClick={() => isEditableInvoiceLine(record)}
        >{`${formatMoney(unitPrice)}`}</p>
      </div>
    )
  }

  const renderTax = (record: InvoiceLineItem) => {
    const { taxPercentage } = record
    return (
      <>
        <p
          data-testid="invoice-line-tax"
          className={classNames({
            'm-auto w-fit min-w-[60px] cursor-pointer rounded-md border px-4 py-3 text-center transition duration-200 hover:bg-gray-200':
              isEditable,
          })}
          onClick={() => isEditableInvoiceLine(record)}
        >
          {`${taxPercentage}%`}
        </p>
        <UpdateInvoiceLineModal
          invoiceLine={record}
          showModal={showUpdateInvoiceLineModal && activeInvoiceLine === record.id}
          setShowModal={setShowUpdateInvoiceLineModal}
        />
      </>
    )
  }

  const renderTotal = ({ lineTotal }: InvoiceLineItem) => {
    return <strong>{`${formatMoney(lineTotal)}`}</strong>
  }

  return (
    <Table
      dataSource={invoiceLines}
      keyExtractor={(record) => String(record.id)}
      columnsHidden={!showFlagsColumn ? ['flags'] : []}
      columns={[
        {
          title: t('invoices.invoice.lineLabels.linked', 'Linked'),
          key: 'linked',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 70,
          maxWidth: 70,
          render: renderLinkedPO,
        },
        {
          title: t('general.product', 'Product'),
          key: 'product',
          headerCellStyles: 'px-4 py-3',
          cellStyles: 'px-4 py-3',
          minWidth: 220,
          grow: true,
          render: renderProduct,
        },
        {
          title: t('general.flags', 'Flags'),
          key: 'flags',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 70,
          maxWidth: 70,
          render: renderFlags,
        },
        {
          title: t('general.received', 'Received'),
          key: 'received',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 140,
          maxWidth: 140,
          render: renderReceived,
        },
        {
          title: t('general.info', 'Info'),
          key: 'info',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 70,
          maxWidth: 70,
          render: renderInfo,
        },
        {
          title: t('general.quantity', 'Quantity'),
          key: 'quantity',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 70,
          maxWidth: 70,
          render: renderQuantity,
        },
        {
          title: t('general.unitPrice', 'Unit Price'),
          key: 'unitPrice',
          headerCellStyles: 'py-3 pr-2 text-right',
          cellStyles: 'py-3 pr-2 text-right',
          minWidth: 90,
          maxWidth: 120,
          render: renderUnitPrice,
        },
        {
          title: t('general.tax', 'Tax'),
          key: 'tax',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 70,
          maxWidth: 70,
          render: renderTax,
        },
        {
          title: t('general.total', 'Total'),
          key: 'total',
          headerCellStyles: 'py-3 px-4 text-center',
          cellStyles: 'py-3 px-4 text-center',
          minWidth: 100,
          maxWidth: 130,
          render: renderTotal,
        },
      ]}
    />
  )
}

export default memo(InvoiceLinesTableView)
