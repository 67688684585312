import { matchRoutes, useLocation } from 'react-router-dom'

import {
  buyListRoutes,
  catalogRoutes,
  inventoryRoutes,
  invoiceRoutes,
  purchaseOrderRoutes,
  requisitionRoutes,
  supplierRoutes,
  systemSettingRoutes,
} from '@/modules/shared/constants'

const useFindMatchRoutes = () => {
  const location = useLocation()

  const allRoutes = [
    requisitionRoutes,
    purchaseOrderRoutes,
    invoiceRoutes,
    buyListRoutes,
    catalogRoutes,
    supplierRoutes,
    inventoryRoutes,
    systemSettingRoutes,
  ]

  const matchedRouteIndex = allRoutes.findIndex((route) => matchRoutes(route, location))

  const routeMatches = allRoutes.map((route, index) => index === matchedRouteIndex)

  return {
    requisitionRoutesMatch: routeMatches[0],
    purchaseOrdersRoutesMatch: routeMatches[1],
    invoiceRoutesMatch: routeMatches[2],
    buyListRoutesMatch: routeMatches[3],
    catalogRoutesMatch: routeMatches[4],
    supplierRoutesMatch: routeMatches[5],
    inventoryRoutesMatch: routeMatches[6],
    systemSettingRoutesMatch: routeMatches[7],
    accordionDefaultKey: matchedRouteIndex !== -1 ? matchedRouteIndex : null,
  }
}

export default useFindMatchRoutes
