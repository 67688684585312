import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import RequisitionStatus from '../RequisitionStatus'

import { PageInfo, Requisition } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Fallback, MoreOptionsMenu, PPNewDetailLink } from '@/modules/shared/components'
import Table from '@/modules/shared/components/table/Table'
import { useMoney } from '@/modules/shared/hooks'
import { OptionIcon } from '@/modules/shared/icons'
import { Dates } from '@/modules/shared/types'
import { formatDate } from '@/modules/shared/utils'

interface TableViewProps {
  requisitions: Requisition[]
  paginationLoading: boolean
  pageInfo: Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'endCursor'>
  onNextPage: () => void
  onPreviousPage: () => void
}

function TableView(props: TableViewProps) {
  const {
    requisitions,
    pageInfo: { hasNextPage, hasPreviousPage, endCursor },
    onNextPage,
    onPreviousPage,
    paginationLoading,
  } = props
  const { t } = useTranslation()
  const { formatMoney } = useMoney()

  const renderRequisition = ({ id, requisitionNumber }: Requisition) => {
    return (
      <PPNewDetailLink type="requisition" id={String(id)}>
        {requisitionNumber}
      </PPNewDetailLink>
    )
  }
  const renderReference = ({ reference }: Requisition) => {
    return <Fallback condition={!!reference}>{reference}</Fallback>
  }
  const renderRequestor = ({ creator }: Requisition) => {
    return <Fallback condition={!!creator}>{creator?.fullName}</Fallback>
  }
  const renderType = ({ originName }: Requisition) => {
    return <Fallback condition={!!originName}>{originName}</Fallback>
  }
  const renderAccount = ({ account, department }: Requisition) => {
    return (
      <Fallback condition={!!department || !!account}>
        <p>{department?.name}</p>
        <p className="text-xs">{account?.accountName}</p>
      </Fallback>
    )
  }
  const renderStatus = ({ state, id }: Requisition) => {
    return state && <RequisitionStatus state={state} requisitionId={id} />
  }
  const renderDeliveryDate = ({ expectedDeliveryDate }: Requisition) => {
    return <Fallback condition={!!expectedDeliveryDate}>{formatDate(Dates.Short, expectedDeliveryDate)}</Fallback>
  }
  const renderSendPO = ({ sendToSupplier }: Requisition) => {
    return sendToSupplier ? t('requisition.details.sendPOYes', 'Yes') : t('requisition.details.sendPONo', 'No')
  }
  const renderTotal = ({ totalValue }: Requisition) => {
    return (
      <>
        <p className="text-base font-bold">{formatMoney(totalValue)}</p>
        <p className="text-xs text-gray-600">{t('requisition.details.taxInclusive', 'Tax Inclusive')}</p>
      </>
    )
  }
  const renderMoreOptions = ({ id }: Requisition) => {
    return (
      <MoreOptionsMenu showOpenInPPlus showPrint showHelp showViewRequisition requisitionId={id}>
        <OptionIcon className="h-10 w-10 text-gray-500" />
      </MoreOptionsMenu>
    )
  }

  return (
    <>
      <Table
        dataTestId="requisitions-table-view"
        dataSource={requisitions}
        keyExtractor={(record) => String(record.id)}
        pagination={{
          hasNextPage,
          hasPreviousPage,
          onNextPage: onNextPage,
          onPreviousPage: onPreviousPage,
          loading: paginationLoading,
          endCursor: endCursor,
        }}
        columns={[
          {
            title: t('requisition.details.requisitionTableHeader', 'Requisition'),
            key: 'requisition',
            headerCellStyles: 'px-4 py-3',
            cellStyles: 'px-4 py-3',
            minWidth: 130,
            maxWidth: 130,
            render: renderRequisition,
          },
          {
            title: t('requisition.details.reference', 'Reference'),
            key: 'reference',
            headerCellStyles: 'px-2 py-3',
            cellStyles: 'px-2 py-3',
            minWidth: 180,
            maxWidth: 220,
            render: renderReference,
          },
          {
            title: t('requisition.details.requestor', 'Requestor'),
            key: 'requestor',
            headerCellStyles: 'px-2 py-3',
            cellStyles: 'px-2 py-3',
            minWidth: 200,
            maxWidth: 200,
            render: renderRequestor,
          },
          {
            title: t('requisition.details.typeTableHeader', 'Type'),
            key: 'type',
            headerCellStyles: 'px-2 py-3',
            cellStyles: 'px-2 py-3',
            minWidth: 220,
            maxWidth: 240,
            render: renderType,
          },
          {
            title: t('requisition.details.accountTableHeader', 'Account Code'),
            key: 'account',
            headerCellStyles: 'px-2 py-3',
            cellStyles: 'px-2 py-3',
            minWidth: 240,
            grow: true,
            render: renderAccount,
          },
          {
            title: t('requisition.details.statusTableHeader', 'Status'),
            key: 'status',
            headerCellStyles: 'py-3 text-center',
            cellStyles: 'py-3 text-center',
            minWidth: 160,
            maxWidth: 160,
            render: renderStatus,
          },
          {
            title: t('requisition.details.deliveryDate', 'Delivery Date'),
            key: 'deliveryDate',
            headerCellStyles: 'px-2 py-3 text-center',
            cellStyles: 'px-2 py-3 text-center',
            minWidth: 120,
            maxWidth: 120,
            render: renderDeliveryDate,
          },
          {
            title: t('requisition.details.sendPOTableHeader', 'Send PO'),
            key: 'sendPO',
            headerCellStyles: 'px-1 py-3 text-center',
            cellStyles: 'px-1 py-3 text-center',
            minWidth: 80,
            maxWidth: 80,
            render: renderSendPO,
          },
          {
            title: t('requisition.details.totalValue', 'Total Value'),
            key: 'totalValue',
            headerCellStyles: 'px-1 py-3 text-right',
            cellStyles: 'px-1 py-3 text-right',
            minWidth: 110,
            maxWidth: 150,
            render: renderTotal,
          },
          {
            title: t('requisition.details.moreTableHeader', 'More'),
            key: 'more',
            headerCellStyles: 'px-1 py-3 text-center',
            cellStyles: 'px-1 py-3 text-center',
            minWidth: 70,
            maxWidth: 70,
            render: renderMoreOptions,
          },
        ]}
      />
    </>
  )
}

export default memo(TableView)
