import { graphql } from 'msw'

import { UpdateSupplierRelationshipMutation } from '@/graphql/purchasing/generated/purchasing_graphql'

export const UpdateSupplierRelationship = graphql.mutation<UpdateSupplierRelationshipMutation>(
  'UpdateSupplierRelationship',
  (_, res, ctx) => {
    return res(
      ctx.data({
        updateSupplierRelationship: {
          supplierRelationship: {
            id: 67987,
            supplierNumber: '062014156 test',
            department: {
              id: 85127,
              name: 'Beverage',
              __typename: 'Purchaser',
            },
            account: {
              id: 96391,
              accountName: '0060007 - Operating Account - 7',
              __typename: 'Account',
            },
            defaultCategory: {
              id: 109446,
              name: 'Beer',
              familyCategoryId: 1,
              __typename: 'Category',
            },
            __typename: 'SupplierRelationship',
          },
          __typename: 'UpdateSupplierRelationshipMutationPayload',
        },
      })
    )
  }
)
