import { useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'

import {
  GetAllPurchaseOrdersDocument,
  PurchaseOrder,
  PurchaseOrderReceivedStatusEnum,
  RansackDirection,
  RansackMatcher,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { PurchaseOrderStatuses } from '@/modules/purchase-orders/types'
import { OnFilter } from '@/modules/shared/components/search-input/SearchAndFilter'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { checkNetworkStatus, extractEdges } from '@/modules/shared/utils'

export default function useGetPurchaseOrders() {
  const [filteredPurchaseOrders, setFilteredPurchaseOrders] = useState<PurchaseOrder[]>([])
  const [receivingNotesState, setReceivingNotesState] = useState<string | null | undefined>(null)

  const { data, networkStatus, refetch, fetchMore, error } = useQuery(GetAllPurchaseOrdersDocument, {
    variables: {
      after: null,
      filter: {},
      first: 20,
      sort: [
        {
          property: 'expectedDeliveryDate',
          direction: RansackDirection.Desc,
        },
      ],
    },
    context: { uri: PURCHASING_GRAPHQL_API },
    notifyOnNetworkStatusChange: true,
  })

  const { setVariablesLoading, refetchLoading, fetchMoreLoading, loading } = checkNetworkStatus(networkStatus)

  const purchaseOrders = extractEdges<PurchaseOrder>(data?.currentPurchaser?.purchaseOrders)

  const onFetchMorePurchaseOrders = () => {
    fetchMore({
      variables: {
        after: data?.currentPurchaser?.purchaseOrders?.pageInfo.endCursor,
      },
    })
  }

  const onFilter = ({
    searchValue,
    filterValue,
    supplierIds,
    fromDate,
    toDate,
    matchedInvoice,
    receivingNotes,
  }: OnFilter) => {
    let filter: RansackMatcher[] = []
    if (searchValue) {
      filter.push({
        property: 'purchaseOrderNumber_or_requisition_reference_cont',
        value: searchValue.trim(),
      })
    }
    if (filterValue === PurchaseOrderStatuses.NotSent) {
      filter.push({
        property: 'sent_date_null',
        value: true,
      })
    }

    if (filterValue === PurchaseOrderStatuses.Sent) {
      filter.push({
        property: 'sent_date_not_null',
        value: true,
      })
    }

    if (filterValue === PurchaseOrderStatuses.Cancelled) {
      filter.push({
        property: 'cancelled_eq',
        value: true,
      })
    }

    if (filterValue === PurchaseOrderStatuses.Closed) {
      filter.push({
        property: 'state_eq',
        value: 'closed',
      })
    }

    if (supplierIds && supplierIds.length > 0) {
      filter.push({
        property: 'supplier_id_in',
        value: supplierIds,
      })
    }

    if (fromDate) {
      filter.push({
        property: 'expected_delivery_date_gt',
        value: fromDate,
      })
    }

    if (toDate) {
      filter.push({
        property: 'expected_delivery_date_lt',
        value: toDate,
      })
    }

    if (matchedInvoice === 'matched') {
      filter.push({
        property: 'invoices_count_gt',
        value: 0,
      })
    } else if (matchedInvoice === 'noMatched') {
      filter.push({
        property: 'invoices_count_eq',
        value: 0,
      })
    }

    // TODO: replace this with better filtering
    if (receivingNotes !== receivingNotesState) {
      setReceivingNotesState(receivingNotes)
    }

    refetch({
      filter: {
        q: [...filter],
      },
    })
  }

  // TODO: This is a terrible hack, but the Ransack doesn't support filtering by receivedStatus so this is the best
  //  we can do for now. Replace this with filtering with actual GraphQL query filtering when possible.
  // Filter purchaseOrders based on receivedStatus and set the filteredPurchaseOrders state
  const filterPurchaseOrders = (purchaseOrders: PurchaseOrder[], receivedStatus?: string | null) => {
    if (receivedStatus === 'received') {
      setFilteredPurchaseOrders(
        purchaseOrders.filter((po) => po.receivedStatus !== PurchaseOrderReceivedStatusEnum.NotReceived)
      )
    } else if (receivedStatus === 'notReceived') {
      setFilteredPurchaseOrders(
        purchaseOrders.filter((po) => po.receivedStatus === PurchaseOrderReceivedStatusEnum.NotReceived)
      )
    } else {
      setFilteredPurchaseOrders(purchaseOrders)
    }
  }

  // Update filteredPurchaseOrders whenever purchaseOrders changes
  useEffect(() => {
    filterPurchaseOrders(purchaseOrders, receivingNotesState)
  }, [data?.currentPurchaser?.purchaseOrders, receivingNotesState])

  return {
    refetchLoading,
    setVariableLoading: setVariablesLoading,
    fetchMoreLoading,
    loading,
    data,
    error,
    purchaseOrders: filteredPurchaseOrders,
    onFetchMorePurchaseOrders,
    onFilter,
  }
}
