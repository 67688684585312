import { graphql } from 'msw'

import { GetAllUsersQuery } from '@/graphql/access/generated/access_graphql'

const baseAllUsersObject: GetAllUsersQuery = {
  currentOrganisationUnit: {
    id: 44926,
    users: {
      edges: [
        {
          node: {
            id: 7831,
            fullName: 'BAU Marketboomer',
            email: 'bau@marketboomer.com',
            defaultOrganisation: {
              id: 1,
              name: 'Marketboomer',
              __typename: 'Organisation',
            },
            telephone: '',
            lastSignInAt: '2022-08-11T19:46:12+10:00',
            isDeleted: false,
            __typename: 'User',
          },
          __typename: 'UserEdge',
        },
        {
          node: {
            id: 22553,
            fullName: 'Bob Thompson',
            email: 'bob.thompson@ihg.com',
            defaultOrganisation: {
              id: 44926,
              name: 'Test Account',
              __typename: 'Organisation',
            },
            telephone: '',
            lastSignInAt: '2021-09-29T22:55:44+10:00',
            isDeleted: true,
            __typename: 'User',
          },
          __typename: 'UserEdge',
        },
      ],
      pageInfo: {
        endCursor: 'NQ',
        hasNextPage: false,
        __typename: 'PageInfo',
      },
      __typename: 'UserConnection',
    },
    __typename: 'Organisation',
  },
}

export const GetAllUsers = graphql.query<GetAllUsersQuery>('GetAllUsers', (_, res, ctx) => {
  return res(ctx.data(baseAllUsersObject))
})

export default baseAllUsersObject
