import { useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, generatePath, matchPath, useLocation, useNavigate, useParams } from 'react-router-dom'

import Details from './Details'

import { GetPricedCatalogueDocument, PricedCatalogue } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Breadcrumb, Button, QueryResult, ThemeWrapper, Tooltip } from '@/modules/shared/components'
import { MoreOptionsMenu } from '@/modules/shared/components'
import HeaderTabs, { TabItem } from '@/modules/shared/components/header-tabs'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { CatalogIcon, HomeIcon, InfoIcon, OptionIcon } from '@/modules/shared/icons'
import { Themes } from '@/modules/shared/types'
import { findActiveRoute } from '@/modules/shared/utils'

export default function SelfManagedProductHeader() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { pricedCatalogueId } = useParams<{ pricedCatalogueId: string }>()
  const [showInfo, setShowInfo] = useState(false)

  const location = useLocation()

  const { data, loading } = useQuery(GetPricedCatalogueDocument, {
    variables: {
      pricedCatalogueId: Number(pricedCatalogueId),
    },
    context: { uri: PURCHASING_GRAPHQL_API },
  })

  const pricedCatalog = data?.currentPurchaser?.pricedCatalogue
  const actionList: TabItem[] = [
    { target: 'products', label: 'Products' },
    { target: 'audit-log', label: t('auditLog.title', 'Audit Log'), hiddenOnHeaderTabs: true },
  ]

  const isRedirect = matchPath('/catalogs/self-managed/:pricedCatalogueId', location.pathname)

  useEffect(() => {
    if (isRedirect) {
      navigate(
        generatePath('/catalogs/self-managed/:pricedCatalogueId/products', {
          pricedCatalogueId: String(pricedCatalogueId),
        })
      )
    }
  }, [])

  return (
    <QueryResult loading={loading}>
      <div className="mx-auto max-w-[1400px] px-4">
        <Breadcrumb className="rounded-md bg-gray-200 px-3 py-2" data-testid="self-managed-catalog-breadcrumb">
          <Breadcrumb.Item icon={HomeIcon} href="/dashboard"></Breadcrumb.Item>
          <Breadcrumb.Item href="/catalogs/self-managed">{t('catalogs.catalogs', 'Catalogs')}</Breadcrumb.Item>
          <Breadcrumb.Item href="/catalogs/self-managed">{t('catalogs.selfManaged', 'Self-Managed')}</Breadcrumb.Item>
          <Breadcrumb.Item href="#">{pricedCatalog?.title}</Breadcrumb.Item>
          <Breadcrumb.Item>{findActiveRoute(actionList, location.pathname)}</Breadcrumb.Item>
        </Breadcrumb>
        <section className="my-5 flex flex-col gap-y-2">
          <section className="flex justify-between rounded-md bg-white p-4 shadow-sm">
            <div className="flex items-center">
              <ThemeWrapper theme={Themes.Primary} className="flex h-14 w-14 items-center justify-center rounded-md ">
                <CatalogIcon className="h-10 w-10 " />
              </ThemeWrapper>
              <span className="ml-3">
                <p className="font-bold">{pricedCatalog?.title}</p>
                <p className="text-sm text-gray-500">
                  {t('catalogs.selfManagedCatalog.pageTitle', 'Self-Managed Catalog')}
                </p>
              </span>
            </div>
            <div className="flex items-center gap-x-2">
              <Tooltip
                content={t('purchaseOrders.purchaseOrder.details.info', 'Show or hide details')}
                showArrow={false}
              >
                <Button
                  data-testid="show-details-button"
                  className="flex h-11 w-11 items-center justify-center rounded-full bg-gray-200"
                  onClick={() => setShowInfo(!showInfo)}
                >
                  <InfoIcon className="h-8 w-8 text-gray-600" />
                </Button>
              </Tooltip>
              <MoreOptionsMenu
                showAuditLog
                showOpenInPPlus
                showHelp
                pricedCatalogId={pricedCatalogueId}
                buttonTheme="dark"
              >
                <OptionIcon className="h-8 w-8" />
              </MoreOptionsMenu>
            </div>
          </section>
          {showInfo && <Details pricedCatalogue={data?.currentPurchaser?.pricedCatalogue as PricedCatalogue} />}
          <section className="flex justify-between rounded-md bg-white p-4 text-sm shadow-sm">
            <p>{t('catalogs.selfManagedCatalog.supplier', 'Supplier')}</p>
            <p>{pricedCatalog?.supplier?.name}</p>
          </section>
          <HeaderTabs actionList={actionList.filter((action) => !action.hiddenOnHeaderTabs)} />
        </section>
        <Outlet />
      </div>
    </QueryResult>
  )
}
