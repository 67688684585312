import classNames from 'classnames'
import { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import SupplierConnectModal from '../SupplierConnectModal'
import { handleSupplierDirectoryText } from '../SupplierDirectoryUtils'

import { Supplier } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Button, PPNewDetailLink } from '@/modules/shared/components'
import { MoreOptionsMenu } from '@/modules/shared/components'
import Table from '@/modules/shared/components/table/Table'
import { OptionIcon } from '@/modules/shared/icons'

interface TableViewProps {
  suppliers: Supplier[]
}

function TableView(props: TableViewProps) {
  const { t } = useTranslation()
  const { suppliers } = props
  const [showConnect, setShowConnect] = useState<null | number>(null)

  const renderSupplier = (supplier: Supplier) => {
    return (
      <>
        <PPNewDetailLink type="supplier-directory" id={String(supplier.id)}>
          {supplier.name}
        </PPNewDetailLink>

        <p className="text-xs">{supplier.businessNumber}</p>
      </>
    )
  }
  const renderContactInfo = (supplier: Supplier) => {
    return (
      <>
        <p>{supplier.telephone}</p>
        <p>{supplier.email}</p>
      </>
    )
  }
  const renderProducts = ({ id }: Supplier) => {
    return (
      <PPNewDetailLink id={String(id)} className="rounded-md bg-gray-100 px-4 py-3" type="supplier-directory-products">
        {t('supplierDirectory.table.view', 'View')}
      </PPNewDetailLink>
    )
  }
  const renderConnect = (supplier: Supplier) => {
    return (
      <>
        <Button
          className={classNames('w-24 rounded-md py-3', {
            'bg-gray-100': !supplier?.supplierRelationship,
            'bg-error text-white': !supplier?.supplierRelationship?.isEnabled && supplier?.supplierRelationship,
            'bg-success text-white': supplier?.supplierRelationship?.isEnabled,
          })}
          onClick={() => setShowConnect(supplier?.id)}
        >
          {handleSupplierDirectoryText(supplier as Supplier)}
        </Button>
        <SupplierConnectModal
          showModal={showConnect === supplier?.id}
          setShowModal={setShowConnect}
          supplier={supplier}
        />
      </>
    )
  }
  const renderMoreOptions = () => {
    return (
      <MoreOptionsMenu supplier showOpenInPPlus>
        <OptionIcon className="h-10 w-10 text-gray-500" />
      </MoreOptionsMenu>
    )
  }

  return (
    <Table
      dataTestId="supplier-directory-table-view"
      dataSource={suppliers}
      keyExtractor={(record) => String(record.id)}
      columns={[
        {
          title: t('supplierDirectory.table.supplier', 'Supplier'),
          key: 'supplier',
          headerCellStyles: 'px-4 py-3',
          cellStyles: 'px-4 py-3',
          minWidth: 230,
          maxWidth: 280,
          render: renderSupplier,
        },
        {
          title: t('supplierDirectory.table.contactInfo', 'Contact Info'),
          key: 'contactInfo',
          headerCellStyles: 'px-4 py-3',
          cellStyles: 'px-4 py-3',
          minWidth: 230,
          grow: true,
          render: renderContactInfo,
        },
        {
          title: t('supplierDirectory.table.products', 'Products'),
          key: 'products',
          headerCellStyles: 'px-0 py-3 text-center',
          cellStyles: 'px-0 py-3 text-center',
          minWidth: 100,
          maxWidth: 100,
          render: renderProducts,
        },
        {
          title: t('supplierDirectory.table.connect', 'Connect'),
          key: 'connect',
          headerCellStyles: 'px-0 py-3 text-center',
          cellStyles: 'px-0 py-3 text-center',
          minWidth: 120,
          maxWidth: 120,
          render: renderConnect,
        },
        {
          title: t('supplierDirectory.table.more', 'More'),
          key: 'more',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 70,
          maxWidth: 70,
          render: renderMoreOptions,
        },
      ]}
    />
  )
}

export default memo(TableView)
