import { useTranslation } from 'react-i18next'
import { Link, generatePath } from 'react-router-dom'

import { InvoiceLineItem, ReceivingDocumentLineItem } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Accordion, Fallback, TableLineItem } from '@/modules/shared/components'
import { ENTERPRISE_STOCK_LOCATION_URL } from '@/modules/shared/constants'
import { ENTERPRISE_RECEIVING_TRANSFER } from '@/modules/shared/constants'
import { useMoney } from '@/modules/shared/hooks'
import { NewTabIcon } from '@/modules/shared/icons'
import { Dates } from '@/modules/shared/types'
import { extractEdges, formatDate } from '@/modules/shared/utils'

interface RelatedReceivingDocumentLinesProps {
  invoiceLineItem: InvoiceLineItem
}

export default function RelatedReceivingDocumentLines({ invoiceLineItem }: RelatedReceivingDocumentLinesProps) {
  const { t } = useTranslation()
  const { formatMoney } = useMoney()
  const relatedReceivingDocumentLines = extractEdges<ReceivingDocumentLineItem>(
    invoiceLineItem.relatedReceivingDocuments
  )
  const hasRelatedReceivingDocumentLines = relatedReceivingDocumentLines.length > 0

  return (
    <section>
      <strong className="text-sm">{t('general.receivingNoteLines', 'Receiving Note Lines')}</strong>
      <p className="text-sm text-gray-500">
        {t(
          'invoices.invoice.invoiceLineModal.receivingNoteLinesDesc',
          'Details of the Goods Receiving Note lines that relate to this Invoice line.'
        )}
      </p>
      {!hasRelatedReceivingDocumentLines && (
        <div className="mt-1 border bg-gray-100 py-4">
          <p className="text-center text-sm text-gray-500">
            {t('invoices.invoice.invoiceLineModal.noReceivingNoteLines', 'No Receiving Note Lines found.')}
          </p>
        </div>
      )}
      {hasRelatedReceivingDocumentLines &&
        relatedReceivingDocumentLines.map((receivingDocumentLineItem) => {
          const {
            id,
            receivingDocument,
            receivedQuantity,
            unitPrice,
            totalValue,
            receivedToInventory,
            stockLocation,
            purchaseOrderLineItem,
          } = receivingDocumentLineItem
          return (
            <Accordion
              key={id}
              testId="accordion-receiving-document-line-item"
              title={receivingDocument?.receivingDocumentNumber || ''}
              buttonClassName="px-3 h-11 mt-4"
              titleClassName="font-bold"
            >
              <div className="mt-4 border" data-testid="receiving-document-line-details">
                <TableLineItem
                  title={t('general.receivingNote', 'Receiving Note')}
                  tooltip={t(
                    'invoices.invoice.invoiceLineModal.receivingNoteTooltip',
                    'The Receiving Note document that includes this Receiving Note line.'
                  )}
                  value={
                    <Link
                      className="flex items-center text-sm text-primary"
                      to={generatePath('/purchase-orders/:purchaseOrderId/receiving-notes/:receivingDocumentId', {
                        purchaseOrderId: String(receivingDocument?.purchaseOrderId),
                        receivingDocumentId: String(receivingDocument?.id),
                      })}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {receivingDocument?.receivingDocumentNumber}
                      <NewTabIcon className="ml-1 h-5 w-5" />
                    </Link>
                  }
                />
                <TableLineItem
                  title={t('general.receivedBy', 'Received By')}
                  tooltip={t(
                    'invoices.invoice.invoiceLineModal.receivedByTooltip',
                    'The user who created the Receiving Note.'
                  )}
                  value={receivingDocument?.receiver?.fullName}
                />
                <TableLineItem
                  title={t('general.receivedOn', 'Received On')}
                  tooltip={t(
                    'invoices.invoice.invoiceLineModal.receivedOnTooltip',
                    'The date and time that the Receiving Note was submitted.'
                  )}
                  value={formatDate(Dates.Long, receivingDocument?.receivedDate)}
                />
                <TableLineItem
                  title={t('general.receivedQty', 'Received Qty')}
                  tooltip={t(
                    'invoices.invoice.invoiceLineModal.receivedQtyTooltip',
                    'The quantity recorded as received on the Receiving Note line.'
                  )}
                  value={receivedQuantity}
                />
                <TableLineItem
                  title={t('general.purchaseOrderLineId', 'Purchase Order Line ID')}
                  tooltip={t(
                    'invoices.invoice.invoiceLineModal.receivingDocLinePurchaseOrderLineIdTooltip',
                    'The unique identifier of the Purchase Order line that this Goods Receiving Note Line is linked to.'
                  )}
                  value={purchaseOrderLineItem?.id}
                />
                <TableLineItem
                  title={t('general.receivingUnitPrice', 'Receiving Unit Price')}
                  tooltip={t(
                    'invoices.invoice.invoiceLineModal.receivingUnitPriceTooltip',
                    'The tax exclusive Unit Price recorded on the Receiving Note line.'
                  )}
                  value={formatMoney(unitPrice)}
                />
                <TableLineItem
                  title={t('general.receivingTotalValue', 'Receiving Total Value')}
                  tooltip={t(
                    'invoices.invoice.invoiceLineModal.receivingTotalValueTooltip',
                    'The total value of the Receiving line, recorded on the Receiving Note. It is the Receiving Quantity multiplied by the Receiving Unit Price.'
                  )}
                  value={formatMoney(totalValue)}
                />
                <TableLineItem
                  title={t('general.receiveToInventory', 'Receive To Inventory')}
                  tooltip={t(
                    'invoices.invoice.invoiceLineModal.receiveToInventoryTooltip',
                    'Indicates if the Receiving Note line was Received to an Inventory Stock Location.'
                  )}
                  value={receivedToInventory ? t('general.yes', 'Yes') : t('general.no', 'No')}
                />
                <TableLineItem
                  title={t('general.stockLocation', 'Stock Location')}
                  tooltip={t(
                    'invoices.invoice.invoiceLineModal.stockLocationTooltip',
                    'The Stock Location that the items were received to, if the Receiving Note was Received to Inventory.'
                  )}
                  value={
                    <Fallback condition={!!stockLocation}>
                      <a
                        className="flex items-center text-sm"
                        href={`${ENTERPRISE_STOCK_LOCATION_URL}/${stockLocation?.id}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {stockLocation?.name}
                      </a>
                    </Fallback>
                  }
                />
                <TableLineItem
                  title={t('general.receivingTransfer', 'Receiving Transfer')}
                  tooltip={t(
                    'invoices.invoice.invoiceLineModal.receivingTransferTooltip',
                    'If the Receiving Note was Received to an Inventory Stock Location, a link to the Receiving Transfer Note will be shown here.'
                  )}
                  value={
                    <Fallback condition={!!receivingDocument?.transfer}>
                      <a
                        className="flex items-center text-sm"
                        href={`${ENTERPRISE_RECEIVING_TRANSFER}/${receivingDocument?.transfer?.id}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {receivingDocument?.transfer?.transferNumber}
                      </a>
                    </Fallback>
                  }
                />
              </div>
            </Accordion>
          )
        })}
    </section>
  )
}
