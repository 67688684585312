import { useState } from 'react'

import AddToBuyListModal from '../../shop/product-list/AddToBuyListModal'

import { RequisitionLine } from '@/graphql/purchasing/generated/purchasing_graphql'
import { MoreOptionsMenu } from '@/modules/shared/components'
import { OptionIcon } from '@/modules/shared/icons'

interface PRLineMoreOptionProps {
  requisitionLine: RequisitionLine
}

export default function PRLineMoreOption({ requisitionLine }: PRLineMoreOptionProps) {
  const [showAddToBuyListModal, setShowAddToBuyListModal] = useState(false)
  return (
    <>
      <MoreOptionsMenu showAddToBuyList={() => setShowAddToBuyListModal(true)}>
        <OptionIcon className="h-10 w-10 text-gray-500" />
      </MoreOptionsMenu>
      <AddToBuyListModal
        requisitionLine={requisitionLine}
        showModal={showAddToBuyListModal}
        setShowModal={setShowAddToBuyListModal}
      />
    </>
  )
}
