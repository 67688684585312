import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { InvoiceActionTypes, InvoiceStatuses } from '@/modules/invoices/types'
import { ConfirmDialog } from '@/modules/shared/components'
import { Themes } from '@/modules/shared/types'

interface InvoiceActionConfirmDialogProps {
  invoiceAction: InvoiceActionTypes
  showConfirmActionDialog: boolean
  setShowConfirmActionDialog: Dispatch<SetStateAction<boolean>>
  confirmActionLoading: boolean
  onConfirmInvoiceAction: () => void
  state: InvoiceStatuses
}

type InvoiceActionConfirmDialogType = {
  title: string
  description: string
  primaryButtonLabel: string
  secondaryButtonLabel: string
}

export default function InvoiceActionConfirmDialog({
  invoiceAction,
  showConfirmActionDialog,
  setShowConfirmActionDialog,
  confirmActionLoading,
  onConfirmInvoiceAction,
  state,
}: InvoiceActionConfirmDialogProps) {
  const { t } = useTranslation()

  const getInvoiceActionDialogText = (): InvoiceActionConfirmDialogType => {
    switch (invoiceAction) {
      case InvoiceActionTypes.Reopen:
        return {
          title: t('invoices.invoice.action.reopen.title', 'Re-Open Invoice'),
          description: t(
            'invoices.invoice.action.reopen.description',
            'Please confirm below if you wish to Re-Open the Invoice. Once Re-Opened, you will be able to make changes to it and mark is as Complete.'
          ),
          primaryButtonLabel: t('invoices.invoice.action.reopen.primaryButtonLabel', 'Re-Open Invoice'),
          secondaryButtonLabel:
            state === InvoiceStatuses.Cancelled
              ? t('invoices.invoice.action.reopen.secondaryButtonLabelCancelled', 'Keep as Cancelled')
              : t('invoices.invoice.action.reopen.secondaryButtonLabelReconciled', 'Keep as Reconciled'),
        }
      case InvoiceActionTypes.Cancel:
        return {
          title: t('invoices.invoice.action.cancel.title', 'Cancel Invoice?'),
          description: t(
            'invoices.invoice.action.cancel.description',
            'Please confirm below if you wish to Cancel the Invoice. Once Cancelled, you will not be able to make changes to the Invoice. You will be able to Re-Open the Cancelled Invoice in the future if needed.'
          ),
          primaryButtonLabel: t('invoices.invoice.action.cancel.primaryButtonLabel', 'Cancel Invoice'),
          secondaryButtonLabel:
            state === InvoiceStatuses.Open
              ? t('invoices.invoice.action.cancel.secondaryButtonLabelOpen', 'Keep as Open')
              : t('invoices.invoice.action.cancel.secondaryButtonLabelFlagged', 'Keep as Flagged'),
        }
      case InvoiceActionTypes.Halt:
        return {
          title: t('invoices.invoice.action.halt.title', 'Halt Invoice?'),
          description: t(
            'invoices.invoice.action.halt.description',
            'Please confirm below if you wish to Halt the Invoice. By choosing Halt, the Invoice will be removed from the Ready for Processing pool, and will be reverted to a Reconciled state. Once in a Reconciled state, you will be able to Re-Open the Invoice if you wish to make changes to it.'
          ),
          primaryButtonLabel: t('invoices.invoice.action.halt.primaryButtonLabel', 'Halt Invoice'),
          secondaryButtonLabel: t('invoices.invoice.action.halt.secondaryButtonLabel', 'Keep as Processing'),
        }
      case InvoiceActionTypes.Park:
        return {
          title: t('invoices.invoice.action.park.title', 'Park Invoice?'),
          description: t(
            'invoices.invoice.action.park.description',
            'Please confirm below if you wish to Park the Invoice. Once Parked, you will not be able to make changes to the Invoice. You will be able to Re-Open the Parked Invoice in the future when you wish to resume making changes to it.'
          ),
          primaryButtonLabel: t('invoices.invoice.action.park.primaryButtonLabel', 'Park Invoice'),
          secondaryButtonLabel:
            state === InvoiceStatuses.Open
              ? t('invoices.invoice.action.park.secondaryButtonLabelOpen', 'Keep as Open')
              : t('invoices.invoice.action.park.secondaryButtonLabelFlagged', 'Keep as Flagged'),
        }
      case InvoiceActionTypes.Complete:
        return {
          title: t('invoices.invoice.action.notBalanced.title', 'Invoice Not Balanced'),
          description: t(
            'invoices.invoice.action.notBalanced.description',
            'The Invoice cannot be Marked as Complete while it does not balance. The sum of the Invoice parts does not currently balance with the Invoice Total. Please visit the balance panel at the top of the Invoice to update the Invoice totals and balance the Invoice.'
          ),
          primaryButtonLabel: t('invoices.invoice.action.notBalanced.primaryButtonLabel', 'Okay, Got it'),
          secondaryButtonLabel: '',
        }
      default:
        return { title: '', description: '', primaryButtonLabel: '', secondaryButtonLabel: '' }
    }
  }
  return (
    <ConfirmDialog
      title={getInvoiceActionDialogText().title}
      description={getInvoiceActionDialogText().description}
      primaryButtonLabel={getInvoiceActionDialogText().primaryButtonLabel}
      onPrimaryBtnClick={() => {
        invoiceAction === InvoiceActionTypes.Complete ? setShowConfirmActionDialog(false) : onConfirmInvoiceAction()
      }}
      secondaryButtonLabel={getInvoiceActionDialogText().secondaryButtonLabel}
      theme={Themes.Primary}
      isOpen={showConfirmActionDialog}
      setIsOpen={setShowConfirmActionDialog}
      btnPrimaryLoading={confirmActionLoading}
      testId={`${invoiceAction}-dialog`}
    />
  )
}
