import { useTranslation } from 'react-i18next'

import NoImage from '@/assets/images/no-image.svg'
import { ReceivingDocumentLineItem } from '@/graphql/purchasing/generated/purchasing_graphql'
import { formatProductItem } from '@/modules/requisitions/utils'

interface ProductSummaryProps {
  receivingDocumentLineItem: ReceivingDocumentLineItem
}

export default function ProductSummary({ receivingDocumentLineItem }: ProductSummaryProps) {
  const { product, purchaseOrderLineItem } = receivingDocumentLineItem
  const { t } = useTranslation()
  return (
    <section>
      <strong className="text-sm">{t('receivingNotes.advancedModal.productSummary', 'Product Summary')}</strong>
      <p className="text-sm text-gray-500">
        {t(
          'receivingNotes.advancedModal.productSummaryDesc',
          'A product preview and summary of the relevant quantities is shown below.'
        )}
      </p>
      <div className="mt-4 flex items-center gap-x-4 rounded-md border p-4">
        <img className="h-24 w-24 flex-none border" src={purchaseOrderLineItem?.image || NoImage} alt="product" />
        <span>
          <p className="text-sm text-primary">
            {product?.brand} {product?.itemDescription}
          </p>
          <p className="text-xs text-gray-500">
            {formatProductItem({
              itemSize: product?.itemSize,
              itemMeasure: product?.itemMeasure,
              itemPackName: product?.itemPackName,
              itemSellUnit: product?.concatenatedSellUnit,
            })}
          </p>
        </span>
      </div>
    </section>
  )
}
