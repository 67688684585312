import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { PricedCataloguedProduct } from '@/graphql/purchasing/generated/purchasing_graphql'
import { UpdateProductFormInputs } from '@/modules/catalogs/types'
import { useCurrentPurchaser } from '@/modules/purchasing/hooks'
import NumberInput from '@/modules/shared/components/number-input'
import { ChevronDownIcon } from '@/modules/shared/icons'

interface PriceAndTaxProps {
  pricedCataloguedProduct: PricedCataloguedProduct
}

export default function PriceAndTax({ pricedCataloguedProduct }: PriceAndTaxProps) {
  const { t } = useTranslation()
  const { currentPurchaser } = useCurrentPurchaser()

  const formMethods = useFormContext<UpdateProductFormInputs>()

  return (
    <section>
      <p className="flex justify-between text-lg font-bold">
        <span>{t('catalogs.selfManagedCatalog.product.priceAndTax', 'Price and Tax Percentage')}</span>
        <ChevronDownIcon className="h-6 w-6" />
      </p>
      <hr className="mb-4 mt-2" />
      <div className="text-sm">
        <p className="font-bold">{t('catalogs.selfManagedCatalog.product.catalogPrice', 'Catalog Price')}</p>
        <p className="text-gray-500">
          {t(
            'catalogs.selfManagedCatalog.product.catalogPriceDescription',
            'Set the price for this product in the Catalog. The price is exclusive of tax, and the tax percentage to be added to the price is managed in the field below.'
          )}
        </p>
        <div className="relative">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center justify-center pl-2">
            <span className="-mt-2 w-16 rounded-md bg-gray-200 px-3 py-1.5 text-center">
              {currentPurchaser?.currency}
            </span>
          </div>
          <Controller
            control={formMethods.control}
            name="sellUnitPrice"
            rules={{ required: true }}
            defaultValue={pricedCataloguedProduct?.sellUnitPrice || 0}
            render={({ field }) => (
              <NumberInput
                className="mb-4 mt-2 w-full rounded-md border border-gray-200 p-3 pl-20 text-sm focus:border-primary/30 focus:outline-none xl:w-1/2"
                data-testid="sell-unit-price"
                aria-label={t('catalogs.selfManagedCatalog.product.catalogPrice', 'Catalog Price')}
                defaultValue={pricedCataloguedProduct?.sellUnitPrice || 0}
                onChange={(e) => {
                  if (isNaN(e)) {
                    // To disable the apply changes button when no input is provided.
                    field.onChange(null)
                  } else {
                    field.onChange(e)
                  }
                }}
              />
            )}
          />
        </div>
        <p className="font-bold">{t('catalogs.selfManagedCatalog.product.catalogTax', 'Catalog Tax Percentage')}</p>
        <p className="text-gray-500">
          {t(
            'catalogs.selfManagedCatalog.product.catalogTaxDescription',
            'Set the tax percentage for this product in the Catalog. The tax percentage is the amount that will be added to the tax exclusive unit price above.'
          )}
        </p>
        <div className="relative">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center justify-center pl-2">
            <span className="-mt-2 w-16 rounded-md bg-gray-200 px-3 py-1.5 text-center">
              {t('catalogs.selfManagedCatalog.product.catalogTaxInput', 'Tax %')}
            </span>
          </div>
          <Controller
            control={formMethods.control}
            name="sellUnitTaxPercentage"
            defaultValue={pricedCataloguedProduct?.sellUnitTaxPercentage || 0}
            render={({ field }) => (
              <NumberInput
                className="mb-4 mt-2 w-full rounded-md border border-gray-200 p-3 pl-20 text-sm focus:border-primary/30 focus:outline-none xl:w-1/2"
                data-testid="sell-unit-tax-percentage"
                aria-label={t('catalogs.selfManagedCatalog.product.catalogTax', 'Catalog Tax Percentage')}
                defaultValue={pricedCataloguedProduct?.sellUnitTaxPercentage || 0}
                onChange={(e) => field.onChange(e)}
              />
            )}
          />
        </div>
      </div>
    </section>
  )
}
