import { useMutation } from '@apollo/client'
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import UserDetailsFormField from './UserDetailsFormField'

import { UpdateUserDocument } from '@/graphql/access/generated/access_graphql'
import { Organisation, User, UserOrganisation } from '@/graphql/purchasing/generated/purchasing_graphql'
import { UserFormInputs } from '@/modules/access/types'
import { ComboboxClient } from '@/modules/shared/components'
import FormCheckBoxField from '@/modules/shared/components/create-form/FormCheckBoxField'
import { extractEdges } from '@/modules/shared/utils'
import SaveFooter from '@/modules/suppliers/pages/supplier/supplier-details/form-elements/SaveFooter'

interface UserFormProps {
  user: User
  setKey: (key: number) => void
  keyVal: number
}
export default function UserForm(props: UserFormProps) {
  const { user, setKey, keyVal } = props
  const {
    autoSendPurchaseOrder,
    enforceBuyList,
    fullName,
    position,
    telephone,
    staffCode,
    defaultOrganisation,
    userOrganisations,
  } = user || {}
  const defaultValues: UserFormInputs = {
    fullName,
    position: String(position),
    telephone: String(telephone),
    staffCode: String(staffCode),
    autoSendPurchaseOrder: Boolean(autoSendPurchaseOrder),
    enforceBuyList: Boolean(enforceBuyList),
    defaultOrganisationId: Number(defaultOrganisation?.id),
  }
  const { t } = useTranslation()
  const { userId } = useParams<{ userId: string }>()
  const [updateUser, { loading }] = useMutation(UpdateUserDocument)

  const formMethods = useForm<UserFormInputs>({ defaultValues })
  const { reset, register, control, formState } = formMethods

  const onSubmit: SubmitHandler<UserFormInputs> = (data) => {
    updateUser({
      variables: {
        input: {
          id: Number(userId),
          ...data,
        },
      },
      onCompleted: (data) => {
        const { fullName, position, telephone, staffCode, autoSendPurchaseOrder, enforceBuyList, defaultOrganisation } =
          data?.updateUser?.user || {}
        reset({
          fullName,
          position: String(position),
          telephone: String(telephone),
          staffCode: String(staffCode),
          autoSendPurchaseOrder: Boolean(autoSendPurchaseOrder),
          enforceBuyList: Boolean(enforceBuyList),
          defaultOrganisationId: Number(defaultOrganisation?.id),
        })
      },
    })
  }

  const onDiscard = () => {
    reset(defaultValues)
    setKey(keyVal + 1)
  }

  return (
    <form onSubmit={formMethods.handleSubmit(onSubmit)}>
      <FormProvider {...formMethods}>
        <section className="flex flex-col gap-y-5">
          <UserDetailsFormField
            title={t('systemSettings.userInfo.fullName', 'Full Name')}
            body={t('systemSettings.userInfo.fullNameBody', 'The first and last name of the user.')}
            placeholder={t('systemSettings.userInfo.fullNamePlaceholder', 'Enter Full Name')}
            registerString="fullName"
            register={register}
          />
          <UserDetailsFormField
            title={t('systemSettings.userInfo.position', 'Position')}
            body={t(
              'systemSettings.userInfo.positionBody',
              "The user's position at your Organisation. This field is optional."
            )}
            placeholder={t('systemSettings.userInfo.positionPlaceholder', 'Enter Position')}
            registerString="position"
            register={register}
          />
          <UserDetailsFormField
            title={t('systemSettings.userInfo.mobile', 'Mobile Phone Number')}
            body={t('systemSettings.userInfo.mobileBody', "The user's mobile phone number. This field is optional.")}
            placeholder={t('systemSettings.userInfo.mobilePlaceholder', 'Enter Mobile Phone Number')}
            registerString="telephone"
            register={register}
          />
          <UserDetailsFormField
            title={t('systemSettings.userInfo.staffCode', 'Staff Code')}
            body={t(
              'systemSettings.userInfo.staffCodeBody',
              'The number used to identify this user. This field is optional.'
            )}
            placeholder={t('systemSettings.userInfo.staffCodePlaceholder', 'Enter Staff Code')}
            registerString="staffCode"
            register={register}
          />
          <div className="text-sm">
            <p className="font-bold">{t('systemSettings.userInfo.defaultOrganisation', 'Default Organisation')}</p>
            <p className="mb-2 text-gray-500">
              {t(
                'systemSettings.userInfo.defaultOrganisationBody',
                'When the user signs in to PurchasePlus, they will begin their session in their Default Organisation.'
              )}
            </p>
            <Controller
              control={control}
              name="defaultOrganisationId"
              rules={{ required: true }}
              render={({ field }) => (
                <ComboboxClient
                  testId="organisation"
                  loading={false}
                  defaultValue={defaultOrganisation!}
                  placeholder={t(
                    'systemSettings.userInfo.defaultOrganisationPlaceholder',
                    'Select Default Organisation'
                  )}
                  keyFilter="name"
                  keyExtractor={(e) => String(e.id)}
                  items={extractEdges<UserOrganisation>(userOrganisations).map((e) => e.organisation) as Organisation[]}
                  onSelected={(e) => field.onChange(e.id)}
                />
              )}
            />
          </div>
          <FormCheckBoxField
            title={t('systemSettings.userInfo.autoSendPO', 'Auto-Send Purchase Orders?')}
            body={t(
              'systemSettings.userInfo.autoSendPOBody',
              "When a Purchase Requisition is Approved, and the user is the creator of the Purchase Requisition, decide if you'd the Purchase Orders to be born in a Sent state. If you leave this option un-checked, Purchase Orders will be born in a Not Sent state, and they will need to be manually Sent."
            )}
            text={t('systemSettings.userInfo.autoSendPOText', 'Auto-Send Purchase Orders')}
            register="autoSendPurchaseOrder"
          />
          <FormCheckBoxField
            title={t('systemSettings.userInfo.buyList', 'Shop Using Buy List Only')}
            body={t(
              'systemSettings.userInfo.buyListBody',
              '  When this user is creating a Purchase Requisition, if this option is checked they will only be able to create a Purchase Requisition using a Buy List. This may be useful in order to control purchasing behaviour.'
            )}
            text={t('systemSettings.userInfo.buyListText', 'Shop Using Buy List Only')}
            register="enforceBuyList"
          />
          {formState.isDirty && <SaveFooter onDiscardChanges={onDiscard} loading={loading} />}
        </section>
      </FormProvider>
    </form>
  )
}
