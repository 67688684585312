import classNames from 'classnames'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import PurchaseOrderInvoicedModal from './PurchaseOrderInvoicedModal'

import { PurchaseOrder, PurchaseOrderInvoicedStatusEnum } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Tooltip } from '@/modules/shared/components'
import { StatusMapping } from '@/modules/shared/types'

interface PurchaseOrderInvoicedProps {
  purchaseOrder: PurchaseOrder
  clickable?: boolean
}

export default function PurchaseOrderInvoicedStatus(props: PurchaseOrderInvoicedProps) {
  const { purchaseOrder, clickable = true } = props
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)

  const status: StatusMapping<PurchaseOrderInvoicedStatusEnum> = {
    [PurchaseOrderInvoicedStatusEnum.FullyInvoiced]: {
      content: t('purchaseOrders.allPurchaseOrders.table.invoicedStatus.invoiced', 'Invoiced'),
      tooltip: t(
        'purchaseOrders.allPurchaseOrders.table.invoicedStatus.tooltips.invoiced',
        'All products on this Purchase Order have been invoiced.'
      ),
      customStyles: 'bg-success text-white',
    },
    [PurchaseOrderInvoicedStatusEnum.PartlyInvoiced]: {
      content: t('purchaseOrders.allPurchaseOrders.table.invoicedStatus.partlyInvoiced', 'Partly Invoiced'),
      tooltip: t(
        'purchaseOrders.allPurchaseOrders.table.invoicedStatus.tooltips.partlyInvoiced',
        'Some products on this Purchase Order have been Invoiced.'
      ),
      customStyles: 'bg-[#EEB020] text-white',
    },
    [PurchaseOrderInvoicedStatusEnum.NotInvoiced]: {
      content: t('purchaseOrders.allPurchaseOrders.table.invoicedStatus.notInvoiced', 'Not Invoiced'),
      tooltip: t(
        'purchaseOrders.allPurchaseOrders.table.invoicedStatus.tooltips.notInvoiced',
        'No products on this Purchase Order have been Invoiced.'
      ),
      customStyles: 'bg-gray-200',
    },
  }

  if (!purchaseOrder?.invoicedStatus) return null

  const { tooltip, content, customStyles } = status[purchaseOrder?.invoicedStatus]

  return (
    <Tooltip content={tooltip} showArrow={false} delay={100}>
      <span
        className={classNames('rounded-full px-3 py-1.5 text-xs', customStyles)}
        onClick={() => setIsOpen(true)}
        data-testid="invoiced-status"
      >
        {content}
      </span>
      {isOpen && clickable && (
        <PurchaseOrderInvoicedModal showModal={isOpen} setShowModal={setIsOpen} purchaseOrder={purchaseOrder} />
      )}
    </Tooltip>
  )
}
