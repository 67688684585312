import { t } from 'i18next'
import { useState } from 'react'
import { Outlet } from 'react-router-dom'

import { useGetAdvancedPossibleRequisitionLines } from '../../hooks/useGetAdvancedPossibleRequisitionLines'
import Details from '../requisition/page-header/Details'
import CartActions from '../view-cart/page-header/CartActions'
import ShopHeader from './ShopHeader'

import { Requisition } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Button, QueryResult, TitleBar, Tooltip } from '@/modules/shared/components'
import HeaderTabs, { TabItem } from '@/modules/shared/components/header-tabs'
import { useMoney } from '@/modules/shared/hooks'
import { CartIcon, InfoIcon } from '@/modules/shared/icons'

export default function AdvancedShopLayout() {
  const { loadingStates, error, requisition } = useGetAdvancedPossibleRequisitionLines()
  const { formatMoney } = useMoney()
  const [showInfo, setShowInfo] = useState(false)
  const actionList: TabItem[] = [
    { target: '', label: t('requisitionInformation.summary.title', 'Product Summary') },
    { target: 'settings', label: t('requisitionInformation.setting.title', 'Settings') },
    {
      target: 'comments',
      label: t('requisitionInformation.comment.title', 'Comments'),
      count: Number(requisition?.commentsCount),
    },
    { target: 'attachments', label: t('requisitionInformation.attachments.title', 'Attachments') },
  ]

  return (
    <>
      <ShopHeader />
      <main className="mx-auto w-full space-y-5 px-4 py-11 lg:max-w-[2200px]">
        <QueryResult loading={loadingStates.loading} error={error}>
          <div className="space-y-2">
            <TitleBar
              dataTestId="advanced-shop-title-bar"
              title={formatMoney(requisition?.totalValue)}
              subtitle={t('general.plusTaxValue', 'Plus Tax of {{ value }}', {
                value: formatMoney(requisition?.totalTaxValue),
              })}
              icon={<CartIcon className="h-10 w-10 " />}
            >
              <div className="flex items-center">
                <Tooltip content={t('general.infoTooltip', 'Show or hide details')} showArrow={false}>
                  <Button
                    data-testid="show-details-button"
                    className="flex h-11 w-11 items-center justify-center rounded-full bg-gray-200"
                    onClick={() => setShowInfo && setShowInfo(!showInfo)}
                  >
                    <InfoIcon className="h-8 w-8 text-gray-600" />
                  </Button>
                </Tooltip>
              </div>
            </TitleBar>
            {showInfo && <Details requisition={requisition as Requisition} hidePurchaseOrders />}
            <CartActions requisition={requisition as Requisition} />
            <HeaderTabs actionList={actionList} />
          </div>
          <Outlet />
        </QueryResult>
      </main>
    </>
  )
}
