import PurchaserNavigation from './PurchaserNavigation'
import SupplierNavigation from './SupplierNavigation'

import { useCurrentLegalEntity } from '@/modules/purchasing/hooks'

export default function SideNavigation() {
  const { currentLegalEntity } = useCurrentLegalEntity()

  if (currentLegalEntity?.isASupplier) return <SupplierNavigation />

  if (currentLegalEntity?.isAPurchaser) return <PurchaserNavigation />

  return null
}
