import { useQuery } from '@apollo/client'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, generatePath, matchPath, useLocation, useNavigate, useParams } from 'react-router-dom'

import { GetUserDocument } from '@/graphql/purchasing/generated/purchasing_graphql'
import UserStatus from '@/modules/access/pages/all-users/UserStatus'
import { useCurrentOrganisation } from '@/modules/purchasing/hooks'
import { Breadcrumb, QueryResult, ThemeWrapper } from '@/modules/shared/components'
import { MoreOptionsMenu } from '@/modules/shared/components'
import HeaderTabs, { TabItem } from '@/modules/shared/components/header-tabs'
import { HomeIcon, OptionIcon, UserIcon } from '@/modules/shared/icons'
import { Themes } from '@/modules/shared/types'
import { findActiveRoute } from '@/modules/shared/utils'

export default function UserPageHeader() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { userId } = useParams<{ userId: string }>()
  const { currentOrganisation } = useCurrentOrganisation()

  const location = useLocation()

  const { data, loading } = useQuery(GetUserDocument, {
    variables: {
      userId: Number(userId),
    },
  })

  const user = data?.currentOrganisationUnit?.user
  const { id, fullName, isDeleted } = user || {}
  const actionList: TabItem[] = [{ target: 'details', label: t('systemSettings.userInfo.details', 'Details') }]

  const isRedirect = matchPath('/users/:userId', location.pathname)

  useEffect(() => {
    if (isRedirect) {
      navigate(generatePath('/users/:userId/details', { userId: String(userId) }))
    }
  }, [])

  return (
    <>
      <QueryResult loading={loading}>
        <div className="mx-auto max-w-[1400px] px-4">
          <Breadcrumb className="rounded-md bg-gray-200 px-3 py-2" data-testid="invoice-breadcrumb">
            <Breadcrumb.Item icon={HomeIcon} href="/dashboard"></Breadcrumb.Item>
            <Breadcrumb.Item href="/users">{t('breadcrumbs.users', 'Users')}</Breadcrumb.Item>
            <Breadcrumb.Item href="#">{fullName}</Breadcrumb.Item>
            <Breadcrumb.Item>{findActiveRoute(actionList, location.pathname)}</Breadcrumb.Item>
          </Breadcrumb>
          <section className="my-5 flex flex-col gap-y-2">
            <section className="flex items-center justify-between rounded-md bg-white p-4 shadow-sm">
              <div className="flex items-center gap-4">
                <ThemeWrapper theme={Themes.Primary} className="flex items-center justify-center rounded-md p-2">
                  <UserIcon className="h-10 w-10 text-primary" />
                </ThemeWrapper>
                <div className="flex justify-between">
                  <span>
                    <p className="text-xl font-bold" data-testid="cart-value">
                      {fullName}
                    </p>
                    <p className="text-sm">
                      {t('systemSettings.userInfo.organisation', 'User at {{ organisation }}', {
                        organisation: currentOrganisation?.name,
                      })}
                    </p>
                  </span>
                </div>
              </div>
              <div className="flex gap-x-2">
                <MoreOptionsMenu showOpenInPPlus showHelp userId={id}>
                  <OptionIcon className="h-11 w-11 rounded-full bg-gray-200 p-1.5 text-gray-600 outline-none hover:brightness-95" />
                </MoreOptionsMenu>
              </div>
            </section>
            <section className="flex items-center justify-between rounded-md bg-white p-4 text-sm shadow-sm">
              <p>{t('systemSettings.userInfo.status', 'Status')}</p>
              <UserStatus isDeleted={Boolean(isDeleted)} />
            </section>
            <section>
              <HeaderTabs actionList={actionList} />
            </section>
          </section>
          <Outlet />
        </div>
      </QueryResult>
    </>
  )
}
