import { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'

import { LinkInvoiceTableTypes } from '@/modules/invoices/types'

interface LineInvoiceModalTableProps extends PropsWithChildren {
  type: LinkInvoiceTableTypes
  canBeUpdated: boolean
}

export default function LinkInvoiceModalTable({ type, children, canBeUpdated }: LineInvoiceModalTableProps) {
  const { t } = useTranslation()

  return (
    <table className="w-full">
      <thead className="text-xs text-gray-500">
        <tr>
          <th className="py-3 pl-4 text-left">
            {type === LinkInvoiceTableTypes.Invoice
              ? t('invoices.invoice.linkModal.table.invoiceLine', 'Invoice Line')
              : t('invoices.invoice.linkModal.table.purchaseOrderLine', 'Purchase Order Line')}
          </th>
          <th>{t('invoices.invoice.linkModal.table.quantity', 'Quantity')}</th>
          <th>{t('invoices.invoice.linkModal.table.unitPrice', 'Unit Price')}</th>
          <th>{t('invoices.invoice.linkModal.table.tax%', 'Tax %')}</th>
          {canBeUpdated && <th>{t('invoices.invoice.linkModal.table.link', 'Link')}</th>}
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  )
}
