import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { Fallback, PPNewDetailLink, SimpleTileViewItem } from '../../components'
import MoreOptionsMenu from '../../components/more-options/MoreOptionsMenu'
import { OptionIcon } from '../../icons'

import { Invoice } from '@/graphql/purchasing/generated/purchasing_graphql'
import InvoiceStatus from '@/modules/invoices/pages/all-invoices/InvoiceStatus'
import { useMoney } from '@/modules/shared/hooks'
import { Dates } from '@/modules/shared/types'
import { formatDate } from '@/modules/shared/utils'

interface TileViewProps {
  invoices: Invoice[]
}

function TileView(props: TileViewProps) {
  const { invoices } = props
  const { t } = useTranslation()
  const { formatMoney } = useMoney()
  return (
    <>
      {invoices.map((invoice) => {
        const {
          id,
          invoiceNumber,
          reference,
          totalTaxValue,
          totalValue,
          purchaseOrder,
          state,
          invoiceDate,
          department,
          account,
        } = invoice
        return (
          <div key={id} className="mb-6 rounded-md border bg-white">
            <div className="flex justify-between p-4">
              <div>
                <span className="break-all text-base font-bold">{invoiceNumber}</span>
                <p className="text-sm text-gray-500">{reference || invoiceNumber}</p>
              </div>
              <div>
                <div className="mt-0 text-right">
                  <span className="text-base font-bold">{`${formatMoney(totalValue)}`}</span>
                  <p className="text-sm text-gray-500">
                    {t('requisition.total', 'Total')} ({t('requisition.includingTax', 'Including Tax')})
                  </p>
                </div>
              </div>
            </div>
            <hr />
            <section data-testid="invoice-details" className="p-4 text-sm">
              <SimpleTileViewItem
                className="mb-3"
                title={t('invoices.details.purchaseOrder', 'Purchase Order')}
                value={purchaseOrder?.purchaseOrderNumber}
                purchaseOrder={purchaseOrder}
                po={true}
              />
              <SimpleTileViewItem
                className="mb-3"
                title={t('invoices.details.status', 'Status')}
                value={<InvoiceStatus state={state || ''} />}
              />
              <SimpleTileViewItem
                className="mb-3"
                title={t('invoices.details.invoiceDate', 'Invoice Date')}
                value={<Fallback condition={!!invoiceDate}>{formatDate(Dates.Short, invoiceDate)}</Fallback>}
              />
              <SimpleTileViewItem
                className="mb-3"
                title={t('invoices.details.department', 'Department')}
                value={department?.name}
              />
              <SimpleTileViewItem
                className="mb-3"
                title={t('invoices.details.accountCode', 'Account Code')}
                value={account?.accountName}
              />
              <SimpleTileViewItem
                className="mb-3"
                title={t('invoices.details.tax', 'Tax')}
                value={formatMoney(totalTaxValue)}
              />
            </section>

            <hr />
            <div className="flex w-full items-center justify-between p-4">
              <PPNewDetailLink
                type="invoice"
                testId="view-invoice-link-res"
                id={String(id)}
                className="flex h-12 items-center justify-center rounded-md bg-gray-200 p-4 text-center text-sm transition hover:brightness-95"
              >
                {t('invoices.viewInvoice', 'View Invoice')}
              </PPNewDetailLink>
              <MoreOptionsMenu showHelp showOpenInPPlus showInvoiceLink showPrint invoiceId={id}>
                <OptionIcon className="h-10 w-10 text-gray-500" />
              </MoreOptionsMenu>
            </div>
          </div>
        )
      })}
    </>
  )
}

export default memo(TileView)
