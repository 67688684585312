import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import PurchaseOrderModal from '../../../confirmation/requisition-purchase-orders/PurchaseOrderModal'

import { PurchaseOrder } from '@/graphql/purchasing/generated/purchasing_graphql'
import PurchaseOrderInvoicedStatus from '@/modules/purchase-orders/pages/all-purchase-order/PurchaseOrderInvoicedStatus'
import PurchaseOrderReceivedStatus from '@/modules/purchase-orders/pages/all-purchase-order/PurchaseOrderReceivedStatus'
import PurchaseOrderStatus from '@/modules/purchase-orders/pages/all-purchase-order/PurchaseOrderStatus'
import { statusText } from '@/modules/purchase-orders/utils/statusText'
import { Fallback, MoreOptionsMenu, PPNewDetailLink } from '@/modules/shared/components'
import { useMoney } from '@/modules/shared/hooks'
import { OptionIcon } from '@/modules/shared/icons'
import { Dates } from '@/modules/shared/types'
import { formatDate } from '@/modules/shared/utils'

interface PurchaseOrderRowItemProps {
  purchaseOrder: PurchaseOrder
}

export default function PurchaseOrderRowItem(props: PurchaseOrderRowItemProps) {
  const { purchaseOrder } = props
  const {
    id,
    purchaseOrderNumber,
    requisition,
    supplier,
    sentDate,
    status,
    expectedDeliveryDate,
    totalValue,
    receivedStatus,
    invoicedStatus,
  } = purchaseOrder
  const { t } = useTranslation()
  const { formatMoney } = useMoney()

  const [isOpen, setIsOpen] = useState<number | null>(null)

  return (
    <tr className="border-b bg-white">
      <td className="w-[190px] p-4">
        <PPNewDetailLink type="purchase-order" id={String(id)}>
          {purchaseOrderNumber}
        </PPNewDetailLink>
        <p className="break-all text-xs">{requisition?.reference}</p>
      </td>
      <td className="w-[190px] whitespace-nowrap p-4">
        <p className="break-words">{supplier?.name}</p>
      </td>
      <td className="w-44 whitespace-nowrap p-4 text-center">
        <span className="cursor-pointer" onClick={() => setIsOpen(purchaseOrder.id)} data-testid="purchase-order-send">
          <PurchaseOrderStatus status={statusText(status, sentDate)} />
        </span>
      </td>
      <td className="w-44 whitespace-nowrap p-4 text-center">
        <span>{receivedStatus && <PurchaseOrderReceivedStatus receivedStatus={receivedStatus} />}</span>
      </td>

      <td className="w-44 whitespace-nowrap p-4 text-center">
        <span>{invoicedStatus && <PurchaseOrderInvoicedStatus purchaseOrder={purchaseOrder} />}</span>
      </td>
      <td className="w-44 whitespace-nowrap py-4 text-center">
        <Fallback condition={!!expectedDeliveryDate}>{formatDate(Dates.Short, expectedDeliveryDate)}</Fallback>
      </td>
      <td className="w-32 whitespace-nowrap p-4 text-right">
        <p className="text-base font-bold">{formatMoney(totalValue)}</p>
        <p className="text-xs text-gray-600">{t('purchaseOrders.taxInclusive', 'Tax Inclusive')}</p>
      </td>
      <td className="w-16 whitespace-nowrap py-4 text-center">
        <MoreOptionsMenu showPrint showOpenInPPlus showHelp showPurchaseOrderLink purchaseOrderId={id}>
          <OptionIcon className="h-10 w-10 text-gray-500" />
        </MoreOptionsMenu>
      </td>
      <td className="hidden">
        <PurchaseOrderModal
          showModal={isOpen === purchaseOrder.id}
          setShowModal={setIsOpen}
          purchaseOrder={purchaseOrder}
        />
      </td>
    </tr>
  )
}
