import { Trans, useTranslation } from 'react-i18next'

import FormCheckBoxField from '@/modules/shared/components/create-form/FormCheckBoxField'
import { Env } from '@/modules/shared/types'

export default function SelectAdvanced() {
  const { t } = useTranslation()
  if (process.env.REACT_APP_BUILD_ENV === Env.Production) return null

  return (
    <div className="mt-8">
      <FormCheckBoxField
        title={t('createRequisition.enableAdvancedShopping', 'Enable Advanced Shopping')}
        body={
          <Trans t={t} key="createRequisition.enableAdvancedShoppingDesc">
            Decide if you'd like to enable Advanced Shopping mode for this Requisition. Advanced Shopping provides a
            powerful way to view and compare prices from your Suppliers, for every product. It is suited to experienced
            users of PurchasePlus and most helpful when creating a Requisition using a Buy List.
          </Trans>
        }
        text={t('createRequisition.enableAdvancedShopping', 'Enable Advanced Shopping')}
        register="advanced"
        testId="toggle-advanced"
      />
    </div>
  )
}
