import { Route, Routes } from 'react-router-dom'

import { AppLayout } from '@/modules/shared/layouts'
import { NotFound, Root } from '@/modules/shared/pages'

export default function SupplierRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Root />} />
      <Route element={<AppLayout />}>
        <Route path="/dashboard" element={null} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
