import { Trans, useTranslation } from 'react-i18next'

import { Header } from '@/modules/requisitions/layouts'
import { SUPPORT_LINK } from '@/modules/shared/constants'

export default function Home() {
  const { t } = useTranslation()
  return (
    <>
      <Header />
      <div className="mx-auto w-full px-4 py-32 sm:px-8 lg:w-[500px] lg:px-0">
        <div className="flex flex-col items-center">
          <h1 className="my-3 text-center text-xl font-bold">
            {t('access.signOutDetails.title', 'Welcome to the New PurchasePlus')}
          </h1>
          <p className="text-center text-sm text-gray-500">
            <Trans t={t} i18nKey="access.signOutDetails.message">
              We're busy creating a new PurchasePlus here that will be awesome! This product is under development but
              you're welcome to sign in above to use any of the features that we've been working on. You may find that
              your organisation is not configured to access this product yet, but you can learn more about the New
              PurchasePlus project over{' '}
              <a
                data-testid="view-support-link"
                href={SUPPORT_LINK}
                target="_blank"
                rel="noreferrer"
                className="text-primary"
              >
                here
              </a>
              .
            </Trans>
          </p>
          <p className="mt-8 text-center text-sm text-gray-500">
            {t(
              'access.signOutDetails.lookForExistingPPlus',
              "If you're looking for the existing PurchasePlus, click the button below."
            )}
          </p>
          <a
            className="mt-8 w-full cursor-pointer rounded-md bg-gray-200 p-3 text-center text-sm transition hover:brightness-95"
            data-testid="view-home-link"
            href={process.env.REACT_APP_NINJA_API_HOST}
          >
            {t('access.signOutDetails.takeToExistingPPlus', 'Take me to the existing PurchasePlus Instead')}
          </a>
        </div>
      </div>
    </>
  )
}
