import { Transition } from '@headlessui/react'
import classNames from 'classnames'
import { Fragment, ReactNode } from 'react'

import { Loading } from '../../icons-special'
import PaginationControl from './PaginationControl'

interface ColumnDefinition<T> {
  /** Table head title */
  title: string

  /** Keys to identify columns and it should be unique */
  key: string

  /** Classnames for the header cells */
  headerCellStyles?: string

  /** Min width for a cell (px) */
  minWidth?: number

  /** Max width for a cell (px)*/
  maxWidth?: number

  /** Cell growing width */
  grow?: boolean

  /** Classnames for the body cells */
  cellStyles?: string

  /** Render the content of a cell */
  render: (record: T, index: number) => ReactNode
}

export interface PaginationDefinition {
  /** Disable/Enable the next page button */
  hasNextPage?: boolean

  /** Disable/Enable the previous page button */
  hasPreviousPage?: boolean

  /** Rows per page */
  rowsPerPage?: number

  /** Overlay loading */
  loading?: boolean

  /** function fires when click on the next page button */
  onNextPage?: () => void

  /** function fires when click on the previous page button */
  onPreviousPage?: () => void

  /** return selectors rows per page */
  onRowsPerPageChange?: (rowsPerPage: number) => void

  /** current pagination cursor */
  endCursor?: string | null
}

interface TableProps<T> {
  /** Column definition*/
  columns: ColumnDefinition<T>[]

  /** Data that will be mapped in the table */
  dataSource: T[]

  /** Table will be automatically scrollable when there are too many columns in smaller viewport */
  isScrollable?: boolean

  /** Classnames for the table */
  tableStyles?: string

  /** Classnames for the table body  */
  tableBodyStyles?: string

  /** Classnames for the table head  */
  tableHeadStyles?: string

  /** Classnames for the table rows */
  tableRowStyles?: string

  /** An array of column keys that will be hidden in the table*/
  columnsHidden?: string[]

  /** An array of column keys that will be ordered in the table (NOTE: All column keys are required) */
  columnsOrder?: string[]

  /** Used to extract a unique key for a given item at the specified index */
  keyExtractor: (record: T, index: number) => string

  /** Render the content in the extra line of each row */
  renderExtraLine?: (record: T, index: number) => ReactNode

  /** Checks if each row has an extra line */
  hasExtraLine?: (record: T) => boolean

  /** Pagination definition */
  pagination?: PaginationDefinition

  /** Test id for the table */
  dataTestId?: string
}

export default function Table<T>({
  isScrollable = true,
  tableStyles,
  tableBodyStyles,
  tableHeadStyles,
  tableRowStyles,
  columns,
  columnsHidden,
  columnsOrder,
  renderExtraLine,
  hasExtraLine,
  keyExtractor,
  dataSource,
  dataTestId,
  pagination,
}: TableProps<T>) {
  const filteredColumns = columnsHidden ? columns.filter((column) => !columnsHidden.includes(column.key)) : columns

  const sortedColumns = columnsOrder
    ? columnsOrder.map((key) => filteredColumns.find((column) => column.key === key))
    : filteredColumns

  return (
    <>
      {!!pagination && <PaginationControl {...pagination} />}
      <div className={classNames('relative', { 'overflow-x-auto': isScrollable })} data-testid={dataTestId}>
        <Transition
          show={!!pagination?.loading}
          enter="transition-opacity duration-100"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          data-testid="overlay-loading"
        >
          <div className="absolute z-10 flex h-full w-full justify-center transition-all">
            <Loading className="mt-12 h-12 w-12 fill-gray-200 text-gray-300" />
          </div>
        </Transition>
        {dataSource.length > 0 && (
          <table
            className={classNames(tableStyles, 'w-full bg-white text-left text-sm', {
              'opacity-50': pagination?.loading,
            })}
          >
            <thead className={classNames(tableHeadStyles, 'border text-xs text-gray-500')}>
              <tr>
                {sortedColumns.map((header) => (
                  <th
                    key={header?.key}
                    scope="col"
                    className={classNames(header?.headerCellStyles, 'whitespace-nowrap')}
                  >
                    {header?.title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className={classNames(tableBodyStyles, 'border')}>
              {dataSource.map((record, index) => (
                <Fragment key={keyExtractor(record, index)}>
                  <tr
                    className={classNames(tableRowStyles, {
                      'border-b': hasExtraLine ? !hasExtraLine(record) : true,
                    })}
                  >
                    {sortedColumns.map((column) => (
                      <td
                        key={column?.key}
                        // tailwind css does not support dynamic values (Ref: https://v2.tailwindcss.com/docs/just-in-time-mode#arbitrary-value-support)
                        style={{ maxWidth: `${column?.maxWidth}px`, minWidth: `${column?.minWidth}px` }}
                        className={classNames(column?.cellStyles, 'break-words', {
                          'w-full': column?.grow,
                        })}
                      >
                        {column?.render(record, index)}
                      </td>
                    ))}
                  </tr>
                  {renderExtraLine && hasExtraLine && hasExtraLine(record) && (
                    <tr className={classNames({ 'border-b': index !== dataSource.length - 1 })}>
                      <td colSpan={columns.length} className="space-y-2 px-4 pb-4">
                        {renderExtraLine(record, index)}
                      </td>
                    </tr>
                  )}
                </Fragment>
              ))}
            </tbody>
          </table>
        )}
      </div>
      {dataSource.length > 0 && !!pagination && <PaginationControl {...pagination} />}
    </>
  )
}
