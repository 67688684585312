import { useMoney } from '@/modules/shared/hooks'

export interface UnitPriceProps {
  unitPrice: number | null | undefined
}

export default function UnitPrice({ unitPrice }: UnitPriceProps) {
  const { formatMoney } = useMoney()

  return <strong>{formatMoney(unitPrice)}</strong>
}
