import { useQuery } from '@apollo/client'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { GetRequisitionsApprovedDocument, Requisition } from '@/graphql/purchasing/generated/purchasing_graphql'
import { RequisitionFormInputs } from '@/modules/requisitions/types'
import { ComboboxServer } from '@/modules/shared/components'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { extractEdges } from '@/modules/shared/utils'

export default function SelectRequisition() {
  const { t } = useTranslation()
  const {
    control,
    formState: { errors },
  } = useFormContext<RequisitionFormInputs>()
  const { data, networkStatus, fetchMore, refetch } = useQuery(GetRequisitionsApprovedDocument, {
    variables: {
      after: null,
      term: '',
    },
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
    notifyOnNetworkStatusChange: true,
  })

  const onSearchRequisitions = (searchValue: string) => {
    refetch({ term: searchValue })
  }

  const onFetchMoreRequisitions = () => {
    fetchMore({
      variables: {
        after: data?.currentPurchaser?.requisitions?.pageInfo.endCursor,
      },
    })
  }

  return (
    <section className="mt-8">
      <h2 className="text-sm font-semibold">
        {t('createRequisition.selectRequisition.title', 'Select a Requisition')}
      </h2>
      <p className="text-sm text-gray-500">
        {t(
          'createRequisition.selectRequisition.explain',
          'Please select the Requisition you wish to re-order products from'
        )}
      </p>
      <div className="mt-2">
        <Controller
          control={control}
          name="originId"
          rules={{ required: true }}
          render={({ field }) => (
            <ComboboxServer
              networkStatus={networkStatus}
              placeholder={t('createRequisition.selectRequisition.placeholder', 'Select a Requisition...')}
              keyExtractor={(e) => String(e.id)}
              onDisplay={(e) => String(`${e.requisitionNumber} ${e.reference}`)}
              items={extractEdges<Requisition>(data?.currentPurchaser?.requisitions)}
              onInputChange={onSearchRequisitions}
              onSelected={(e) => field.onChange(e.id)}
              hasError={Boolean(errors.originId)}
              errorMessage={t('createRequisition.selectRequisition.required', 'You must select a Requisition')}
              onFetchMore={onFetchMoreRequisitions}
              hasMore={Boolean(data?.currentPurchaser?.requisitions?.pageInfo.hasNextPage)}
              testId="select-requisition"
            />
          )}
        />
      </div>
    </section>
  )
}
