import classNames from 'classnames'
import { Fragment, memo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import InvoicesModal from './InvoicesModal'
import MatchInvoiceModal from './MatchInvoiceModal'
import PurchaseOrderInvoicedStatus from './PurchaseOrderInvoicedStatus'
import PurchaseOrderReceivedStatus from './PurchaseOrderReceivedStatus'
import PurchaseOrderStatus from './PurchaseOrderStatus'
import { isPOMatchable } from './table-view'

import { PurchaseOrder, PurchaseOrderReceivedStatusEnum } from '@/graphql/purchasing/generated/purchasing_graphql'
import { statusText } from '@/modules/purchase-orders/utils/statusText'
import PurchaseOrderModal from '@/modules/requisitions/pages/confirmation/requisition-purchase-orders/PurchaseOrderModal'
import { Button, Fallback, PPNewDetailLink, SimpleTileViewItem, Tooltip } from '@/modules/shared/components'
import { MoreOptionsMenu } from '@/modules/shared/components'
import PurchaseOrderReceiptsButton from '@/modules/shared/components/table/table-column-items/PurchaseOrderReceiptsButton'
import { useMoney } from '@/modules/shared/hooks'
import { ArrowUpIcon, MatchIcon, OptionIcon } from '@/modules/shared/icons'
import { Dates } from '@/modules/shared/types'
import { formatDate } from '@/modules/shared/utils'

interface TileViewProps {
  purchaseOrders: PurchaseOrder[]
}

function TileView(props: TileViewProps) {
  const { purchaseOrders } = props
  const { t } = useTranslation()
  const { formatMoney } = useMoney()
  const [showInvoices, setShowInvoices] = useState<number | null>(null)
  const [isOpen, setIsOpen] = useState<number | null>(null)
  const [showMatchInvoice, setShowMatchInvoice] = useState<number | null>(null)

  return (
    <>
      {purchaseOrders.map((purchaseOrder, index) => {
        const {
          id,
          purchaseOrderNumber,
          requisition,
          totalValue,
          supplier,
          status,
          sentDate,
          invoicedStatus,
          expectedDeliveryDate,
          department,
          account,
          receivedStatus,
        } = purchaseOrder

        const sent = Boolean(sentDate)
        const hasInvoices = Boolean(purchaseOrder.invoices?.edges?.length)

        return (
          <Fragment key={`${id}_${index}`}>
            <div className="rounded-md border bg-white" data-testid="purchase-order-tile-view">
              <div className="flex justify-between p-4">
                <div>
                  <span className="break-all text-base font-bold">{requisition?.reference || purchaseOrderNumber}</span>
                  <p className="text-sm text-gray-500">{purchaseOrderNumber}</p>
                </div>
                <div>
                  <div className="mt-0 text-right">
                    <span className="text-base font-bold">{`${formatMoney(totalValue)}`}</span>
                    <p className="text-sm text-gray-500">
                      {t('requisition.total', 'Total')} ({t('requisition.includingTax', 'Including Tax')})
                    </p>
                  </div>
                </div>
              </div>
              <hr />
              <section className="space-y-3 p-4 text-sm" data-testid="purchase-order-details">
                <SimpleTileViewItem title={t('general.supplier', 'Supplier')} value={supplier?.name} />
                <SimpleTileViewItem
                  title={t('general.status', 'Status')}
                  value={
                    <span
                      className="cursor-pointer"
                      onClick={() => setIsOpen(id)}
                      data-testid="purchase-order-status-tile-view"
                    >
                      <PurchaseOrderStatus status={statusText(status, sentDate)} />
                    </span>
                  }
                />
                <SimpleTileViewItem
                  title={t('general.received', 'Received')}
                  value={
                    <PurchaseOrderReceivedStatus receivedStatus={receivedStatus as PurchaseOrderReceivedStatusEnum} />
                  }
                />
                <SimpleTileViewItem
                  title={t('general.invoiced', 'Invoiced')}
                  value={invoicedStatus && <PurchaseOrderInvoicedStatus purchaseOrder={purchaseOrder} />}
                />
                <SimpleTileViewItem
                  title={t('general.deliveryDate', 'Delivery Date')}
                  value={
                    <Fallback condition={!!expectedDeliveryDate}>
                      {formatDate(Dates.Short, expectedDeliveryDate)}
                    </Fallback>
                  }
                />
                <SimpleTileViewItem
                  title={t('general.requisition', 'Requisition')}
                  value={requisition?.requisitionNumber}
                />
                <SimpleTileViewItem
                  title={t('general.accountCode', 'Account Code')}
                  value={
                    !(department && account)
                      ? department?.name || account?.code
                      : `${department.name} - ${account.code}`
                  }
                />
              </section>
              <hr />
              <section className="flex items-center justify-between p-4">
                <strong className="text-sm">
                  {t('purchaseOrders.purchaseOrder.details.matchedInvoices', 'Matched Invoices')}
                </strong>
                <Button
                  onClick={() =>
                    hasInvoices || !isPOMatchable(purchaseOrder) ? setShowInvoices(id) : setShowMatchInvoice(id)
                  }
                  className={classNames(`flex h-10 w-10 items-center justify-center rounded-full`, {
                    'bg-gray-200 text-gray-500 hover:bg-gray-300': !hasInvoices,
                    'bg-primary/10 text-primary hover:bg-primary/20': hasInvoices,
                  })}
                  data-testid="invoice-modal-button-tile-view"
                >
                  {hasInvoices || !isPOMatchable(purchaseOrder) ? (
                    purchaseOrder?.invoices?.edges?.length
                  ) : (
                    <MatchIcon className="h-6 w-6" />
                  )}
                </Button>
              </section>
              <hr />
              <section className="flex items-center justify-between p-4">
                <strong className="text-sm">{t('general.receipts', 'Receipts')}</strong>
                <PurchaseOrderReceiptsButton purchaseOrder={purchaseOrder} />
              </section>
              <hr />
              <section className="flex items-center justify-between p-4">
                <strong className="text-sm">{t('general.actions', 'Actions')}</strong>
                <Tooltip
                  content={
                    sent
                      ? t(
                          'purchaseOrders.allPurchaseOrders.table.tooltips.POSent',
                          'Purchase Order has been marked as Sent'
                        )
                      : t(
                          'purchaseOrders.allPurchaseOrders.table.tooltips.PONotSent',
                          'Click to send the Purchase Order to the Supplier'
                        )
                  }
                  showArrow={false}
                  delay={100}
                >
                  <Button onClick={() => setIsOpen(id)} data-testid="purchase-order-send-tile-view">
                    <ArrowUpIcon
                      className={classNames('h-9 w-9 cursor-pointer rounded-full p-1.5', {
                        'bg-success text-white': sent,
                        'bg-gray-200 text-gray-500': !sent,
                      })}
                    />
                  </Button>
                </Tooltip>
              </section>
              <hr />
              <section className="flex items-center justify-between p-4">
                <PPNewDetailLink
                  type="purchase-order"
                  id={String(id)}
                  className="flex h-11 items-center justify-center rounded-md bg-gray-200 p-4 text-center text-sm transition hover:brightness-95"
                >
                  {t('purchaseOrders.purchaseOrder.viewPurchaseOrder', 'View Purchase Order')}
                </PPNewDetailLink>

                <MoreOptionsMenu showPrint showOpenInPPlus showHelp showPurchaseOrderLink purchaseOrderId={id}>
                  <OptionIcon className="h-10 w-10 text-gray-500" />
                </MoreOptionsMenu>
              </section>
            </div>
            <PurchaseOrderModal
              showModal={isOpen === purchaseOrder.id}
              setShowModal={setIsOpen}
              purchaseOrder={purchaseOrder}
            />
            <InvoicesModal
              purchaseOrder={purchaseOrder}
              showModal={showInvoices === id}
              setShowModal={setShowInvoices}
              setShowMatchModal={setShowMatchInvoice}
            />
            <MatchInvoiceModal
              purchaseOrder={purchaseOrder}
              showModal={showMatchInvoice === id}
              setShowModal={setShowMatchInvoice}
            />
          </Fragment>
        )
      })}
    </>
  )
}

export default memo(TileView)
