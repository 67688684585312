import { Menu, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { Fragment, PropsWithChildren, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { useCancelRequisition, useGetRequisition, useUpdateRequisition } from '../../hooks'
import { generateShopPath } from '../../utils'
import UserMenu from '../UserMenu'

import { User } from '@/graphql/access/generated/access_graphql'
import { Purchaser } from '@/graphql/purchasing/generated/purchasing_graphql'
import { ConfirmDialog } from '@/modules/shared/components'
import { Button } from '@/modules/shared/components'
import { PURCHASING_GRAPHQL_API, SUPPORT_LINK } from '@/modules/shared/constants'
import { ArrowsRightLeftIcon, GetHelpIcon, NewTabIcon, XCircleIcon } from '@/modules/shared/icons'
import { Themes } from '@/modules/shared/types'

interface Props extends PropsWithChildren {
  currentUser?: User
  currentOrganisation?: Purchaser
}

export default function OptionDetails({ children, currentUser, currentOrganisation }: Props) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false)
  let { requisitionId } = useParams()
  const [cancelRequisition] = useCancelRequisition()
  const [updateRequisition] = useUpdateRequisition()
  const { requisition } = useGetRequisition()

  const enterpriseRequisitionUrl = `${process.env.REACT_APP_NINJA_API_HOST}/purchasing/en/requisitions/${requisitionId}`

  const onCancelRequisition = () => {
    cancelRequisition({
      variables: { input: { id: Number(requisitionId) } },
      context: {
        uri: PURCHASING_GRAPHQL_API,
      },
      onCompleted() {
        setIsOpen(false)
        navigate('/dashboard')
      },
      onError() {
        setIsOpen(false)
      },
    })
  }

  const onToggleAdvanced = () => {
    updateRequisition({
      variables: { input: { id: Number(requisitionId), advanced: !requisition?.advanced } },
      onCompleted(data) {
        const { id, advanced } = data?.updateRequisition?.requisition || {}
        navigate(generateShopPath(id, advanced))
      },
    })
  }

  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button
        data-testid="shop-header-options"
        className="flex h-11 w-11 items-center justify-center rounded-full bg-gray-100 transition duration-200 hover:bg-gray-200"
      >
        {children}
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-auto origin-top-right divide-y divide-gray-100 rounded-md border bg-white shadow-lg focus:outline-none">
          <div className="py-1" data-testid="menu-details">
            <Menu.Item>
              <div>
                <UserMenu />
              </div>
            </Menu.Item>
          </div>
          <div className="py-1" data-testid="menu-details-actions">
            <Menu.Item>
              {({ active }) => (
                <a
                  className={classNames('flex w-full items-center px-4 py-2 text-left text-sm text-gray-700', {
                    'bg-gray-100 text-gray-900': active,
                  })}
                  target="_blank"
                  href={enterpriseRequisitionUrl}
                  rel="noreferrer"
                >
                  <NewTabIcon className="mr-2 h-5 w-5" />
                  {t('requisition.openInPPlusSignEnterprise', 'Open in P+ Enterprise')}
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Button
                  className={classNames('flex w-full items-center px-4 py-2 text-left text-sm text-gray-700', {
                    'bg-gray-100 text-gray-900': active,
                  })}
                  onClick={onToggleAdvanced}
                  data-testid="switch-shopping-button"
                >
                  <ArrowsRightLeftIcon className="mr-2 h-5 w-5" />
                  {requisition?.advanced
                    ? t('general.switchToBasic', 'Switch to Basic Shopping')
                    : t('general.switchToAdvanced', 'Switch to Advanced Shopping')}
                </Button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Button
                  className={classNames('flex w-full items-center px-4 py-2 text-left text-sm text-gray-700', {
                    'bg-gray-100 text-gray-900': active,
                  })}
                  onClick={() => setIsOpen(true)}
                >
                  <XCircleIcon className="mr-2 h-5 w-5" />
                  {t('access.shop.action.cancel', 'Cancel Requisition')}
                </Button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  className={classNames(
                    'flex w-full items-center px-4 py-2 text-left text-sm text-gray-700 transition',
                    {
                      'bg-gray-100 text-gray-900': active,
                    }
                  )}
                  href={SUPPORT_LINK}
                  target="_blank"
                  rel="noreferrer"
                >
                  <GetHelpIcon className="mr-2 h-5 w-5" />
                  {t('access.shop.action.help', 'Get Help')}
                </a>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
      <ConfirmDialog
        title={t('access.shop.confirm.cancelRequisition', 'Cancel Requisition?')}
        description={t(
          'access.shop.confirm.cancelDescription',
          'Please confirm below if you wish to cancel the Requisition. Once cancelled it will be removed from your drafts and will no longer be available.'
        )}
        primaryButtonLabel={t('access.shop.action.cancelRequisition', 'Cancel Requisition?')}
        secondaryButtonLabel={t('access.shop.action.keepAsDraft', 'Keep as Draft')}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onPrimaryBtnClick={onCancelRequisition}
        theme={Themes.Primary}
      />
    </Menu>
  )
}
