import { useTranslation } from 'react-i18next'
import { StringParam, useQueryParam } from 'use-query-params'

import AdvancedSearchFilter from './AdvancedSearchFilter'

import { Checkbox, Tooltip } from '@/modules/shared/components'
import { QuestionMarkIcon } from '@/modules/shared/icons'
import { QueryParameter } from '@/modules/shared/types'

export const FilterByMatchedInvoices = () => {
  const [matchedInvoiceURLParam, setMatchedInvoiceURLParam] = useQueryParam(QueryParameter.MatchedInvoice, StringParam)

  const handleOptionChange = (option: string) => {
    const optionValue = matchedInvoiceURLParam === option ? null : option
    setMatchedInvoiceURLParam(optionValue)
  }

  const { t } = useTranslation()
  return (
    <AdvancedSearchFilter
      buttonTestId="filters-section"
      buttonTitle={t('advancedSearch.filterByMatchedInvoices.filterTitle', 'Filter by Matched Invoices')}
    >
      <div className="flex flex-col gap-2">
        <Checkbox onChange={() => handleOptionChange('matched')} isSelected={matchedInvoiceURLParam === 'matched'}>
          <span data-testid="one-matched-invoice">
            {t('advancedSearch.matchedInvoices.matchedLabel', 'At least one matched invoice')}
          </span>
          <Tooltip
            content={t(
              'advancedSearch.matchedInvoices.matchedTooltip',
              'Show only Purchase Orders that have at least one matched Invoice'
            )}
          >
            <QuestionMarkIcon className="h-5 w-5" />
          </Tooltip>
        </Checkbox>
        <Checkbox
          onChange={() => handleOptionChange('noMatched')}
          isSelected={matchedInvoiceURLParam === 'noMatched'}
          data-testid="no-matched-invoice"
        >
          <span data-testid="no-matched-invoice">
            {t('advancedSearch.noMatchedInvoices.noMatchedLabel', 'No matched invoices')}
          </span>
          <Tooltip
            content={t(
              'advancedSearch.noMatchedInvoices.noMatchedTooltip',
              'Show only Purchase Orders that have no matched invoices'
            )}
          >
            <QuestionMarkIcon className="h-5 w-5" />
          </Tooltip>
        </Checkbox>
      </div>
    </AdvancedSearchFilter>
  )
}
