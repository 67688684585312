import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { InvoiceReceivedStatuses } from '@/modules/invoices/types'

interface InvoiceReceivedStatusProps {
  status: string
}

export default function InvoiceReceivedStatus({ status }: InvoiceReceivedStatusProps) {
  const { t } = useTranslation()

  const statusLowercase = status.toUpperCase()

  // TODO: Will be replaced with the enum (currently don't have enum for this field)
  const handleReceivedStatusText = (status: string) => {
    switch (status) {
      case InvoiceReceivedStatuses.NotReceived:
        return t('invoices.invoice.invoiceLineModal.notReceivedStatus', 'Not Received')
      case InvoiceReceivedStatuses.UnderReceived:
      case InvoiceReceivedStatuses.PartlyReceived:
        return t('invoices.invoice.invoiceLineModal.partlyReceivedStatus', 'Partly Received')
      case InvoiceReceivedStatuses.FullyReceived:
        return t('invoices.invoice.invoiceLineModal.fullyReceivedStatus', 'Received')
      default:
        return ''
    }
  }

  return (
    <span
      className={classNames('inline-flex rounded-full px-3 py-1.5 text-center text-xs', {
        'bg-success text-white': statusLowercase === InvoiceReceivedStatuses.FullyReceived,
        'bg-gray-200': statusLowercase === InvoiceReceivedStatuses.NotReceived,
        'bg-[#EEB020] text-white':
          statusLowercase === InvoiceReceivedStatuses.UnderReceived ||
          statusLowercase === InvoiceReceivedStatuses.PartlyReceived,
      })}
    >
      {handleReceivedStatusText(statusLowercase)}
    </span>
  )
}
