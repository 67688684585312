import { useLazyQuery } from '@apollo/client'
import { Listbox } from '@headlessui/react'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { DelimitedNumericArrayParam, useQueryParam } from 'use-query-params'

import {
  GetSupplierRelationshipsWithCatalogueDocument,
  SupplierRelationship,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { Spinner } from '@/modules/requisitions/components'
import { Button, Checkbox, Input, Modal, QueryResult } from '@/modules/shared/components'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { useDebounce } from '@/modules/shared/hooks'
import { MagnifyingGlassIcon } from '@/modules/shared/icons'
import { Loading } from '@/modules/shared/icons-special'
import { QueryParameter } from '@/modules/shared/types'
import { checkNetworkStatus, extractEdges } from '@/modules/shared/utils'

interface FilterBySupplierModalProps {
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<boolean>>
}

export default function FilterBySupplierModal(props: FilterBySupplierModalProps) {
  const { showModal, setShowModal } = props

  const { t } = useTranslation()
  const [suppliersURLParam, setSuppliersURLParam] = useQueryParam(QueryParameter.Suppliers, DelimitedNumericArrayParam)

  const [searchValue, setSearchValue] = useState('')
  const [inputFocus, setInputFocus] = useState(false)
  const [selectedSuppliers, setSelectedSuppliers] = useState<number[]>([])

  const debouncedQuery = useDebounce(searchValue, 500)
  const [fetchSupplier, { data, refetch, fetchMore, networkStatus, error }] = useLazyQuery(
    GetSupplierRelationshipsWithCatalogueDocument,
    {
      variables: {
        first: 10,
        after: null,
        searchText: '',
        filter: {},
      },
      context: {
        uri: PURCHASING_GRAPHQL_API,
      },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    }
  )

  const { setVariablesLoading, loading: networkLoading } = checkNetworkStatus(networkStatus)
  const loading = networkLoading || setVariablesLoading

  const suppliers = extractEdges<SupplierRelationship>(data?.currentPurchaser?.supplierRelationshipsWithCatalogue)
  const hasNextPage = data?.currentPurchaser?.supplierRelationshipsWithCatalogue?.pageInfo?.hasNextPage

  useEffect(() => {
    if (showModal) {
      setSearchValue('')
      // only call fetchSupplier() when the modal is shown
      fetchSupplier()
      setSelectedSuppliers((suppliersURLParam as number[]) || [])
    }
  }, [showModal])

  useEffect(() => {
    if (inputFocus) onSearchSupplier(debouncedQuery)
  }, [debouncedQuery])

  const onCloseModal = () => {
    setShowModal(false)
  }

  const onSearchSupplier = (searchText: string) => {
    refetch({ searchText })
  }

  const onFetchMoreSupplier = () => {
    fetchMore({
      variables: { after: data?.currentPurchaser?.supplierRelationshipsWithCatalogue?.pageInfo?.endCursor },
    })
  }

  const onSelectSuppliers = () => {
    onCloseModal()
    if (selectedSuppliers.length === 0) return setSuppliersURLParam(undefined)
    setSuppliersURLParam(selectedSuppliers)
  }

  return (
    <Modal showModal={showModal} onCloseModal={onCloseModal}>
      <Modal.Panel
        data-tesid="filter-by-supplier-modal"
        className="flex h-[60vh] w-full flex-col overflow-hidden rounded-md bg-white shadow-xl transition-all md:max-w-[600px]"
      >
        <Modal.Title
          title={t('shopPage.productList.supplierFilter.title', 'Filter by Supplier')}
          onCloseModal={onCloseModal}
        />

        <Modal.Body className="space-y-3 overflow-scroll" id="InfiniteScroll">
          <span className="text-sm">
            <h1>
              <strong>{t('shopPage.productList.supplierFilter.supplierList', 'Supplier List')}</strong>
            </h1>
            <p className="text-gray-500">
              {t('shopPage.productList.supplierFilter.description', 'Find and add or remove Suppliers to your filter.')}
            </p>
          </span>
          <Input
            data-testid="input-search-supplier"
            className="w-full rounded-md border border-gray-300 p-3 text-sm"
            suffixIcon={MagnifyingGlassIcon}
            placeholder={t('shopPage.productList.supplierFilter.search', 'Search')}
            onChange={(e) => setSearchValue(e.trim())}
            onFocus={() => setInputFocus(true)}
            aria-label={t('shopPage.productList.supplierFilter.search', 'Search')}
          />
          {loading && (
            <div className="flex items-center justify-center">
              <Loading className="h-10 w-10 fill-gray-200 text-gray-300" />
            </div>
          )}
          {!loading && suppliers.length === 0 && (
            <p className="text-center text-sm text-gray-500">
              {t('shopPage.productList.supplierFilter.noSuppliers', 'No Suppliers found.')}
            </p>
          )}
          <QueryResult error={error}>
            {!loading && suppliers.length > 0 && (
              <InfiniteScroll
                dataLength={suppliers.length}
                next={onFetchMoreSupplier}
                hasMore={!!hasNextPage}
                loader={<Spinner className="mt-5 h-10 md:w-16" />}
                scrollableTarget="InfiniteScroll"
              >
                <div className="rounded-md border bg-gray-100">
                  <Listbox
                    data-testid="supplier-filter-options"
                    value={selectedSuppliers}
                    onChange={(e) => setSelectedSuppliers(e)}
                    multiple
                  >
                    <Listbox.Options static>
                      {suppliers.map((supplier) => {
                        const isChecked = selectedSuppliers?.includes(Number(supplier.supplierId))
                        return (
                          <Listbox.Option
                            key={supplier.supplierId}
                            value={supplier.supplierId}
                            className="flex cursor-pointer items-center gap-x-2 border-b p-3 text-sm last:border-b-0"
                          >
                            <Checkbox aria-label={String(supplier?.supplierName)} isSelected={isChecked}>
                              {supplier.supplierName}
                            </Checkbox>
                          </Listbox.Option>
                        )
                      })}
                    </Listbox.Options>
                  </Listbox>
                </div>
              </InfiniteScroll>
            )}
          </QueryResult>
        </Modal.Body>
        <Modal.Footer className="flex flex-col items-center justify-between gap-y-2 md:flex-row">
          <Button
            data-testid="supplier-filter-clear"
            className="h-11 w-full rounded-md bg-gray-200 px-4 text-sm md:w-fit"
            disabled={!Boolean(suppliersURLParam)}
            onClick={() => {
              onCloseModal()
              setSuppliersURLParam(undefined)
            }}
          >
            {t('shopPage.productList.supplierFilter.clearFilter', 'Clear Filter')}
          </Button>
          <div className="flex w-full flex-col-reverse gap-2 md:w-fit md:flex-row">
            <Button className="h-11 w-full rounded-md bg-gray-200 px-4 text-sm md:w-fit" onClick={onCloseModal}>
              {t('shopPage.productList.supplierFilter.cancel', 'Cancel')}
            </Button>
            <Button
              className="h-11 w-full rounded-md bg-primary px-4 text-sm text-white md:w-fit"
              disabled={selectedSuppliers.length === 0}
              onClick={() => onSelectSuppliers()}
              data-testid="supplier-filter-confirm"
            >
              {t('shopPage.productList.supplierFilter.applyFilter', 'Apply Filter')}
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Panel>
    </Modal>
  )
}
