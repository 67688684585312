import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { GetPurchaserDocument } from '@/graphql/purchasing/generated/purchasing_graphql'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'

export default function useGetPurchaser() {
  const { organisationId } = useParams<{ organisationId: string }>()

  const { data, loading, error } = useQuery(GetPurchaserDocument, {
    variables: {
      purchaserId: Number(organisationId),
    },
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
  })

  return {
    purchaser: data?.purchaser,
    error,
    loading,
  }
}
