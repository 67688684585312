import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, generatePath } from 'react-router-dom'

import { ReceivingDocument } from '@/graphql/purchasing/generated/purchasing_graphql'
import { ReceivingNoteRoute } from '@/modules/purchase-orders/utils/receivingNoteRoute'
import { ReceivingNoteStatus } from '@/modules/receiving-notes/components'
import { ReceivingNoteState } from '@/modules/receiving-notes/types'
import { Fallback } from '@/modules/shared/components'
import Table from '@/modules/shared/components/table/Table'
import { ENTERPRISE_RECEIVING_TRANSFER } from '@/modules/shared/constants'
import { useMoney } from '@/modules/shared/hooks'
import { Dates } from '@/modules/shared/types'
import { formatDate } from '@/modules/shared/utils'

interface TableViewProps {
  receivingDocs: ReceivingDocument[]
}

function TableView(props: TableViewProps) {
  const { receivingDocs } = props
  const { t } = useTranslation()
  const { formatMoney } = useMoney()

  const renderReceivingNote = ({
    workflowState,
    id,
    purchaseOrderId,
    receivingDocumentNumber,
    receiver,
  }: ReceivingDocument) => {
    return (
      <>
        <Link
          className="cursor-pointer break-words text-sm leading-5 text-primary"
          to={generatePath(
            `/purchase-orders/:purchaseOrderId/receiving-notes/:receivingNoteId/${ReceivingNoteRoute(
              workflowState as ReceivingNoteState
            )}`,
            {
              receivingNoteId: String(id),
              purchaseOrderId: String(purchaseOrderId),
            }
          )}
        >
          {receivingDocumentNumber}
        </Link>
        <p className="text-xs">
          {t('receivingNotes.receivingNoteByUser', 'By {{ name }}', { name: receiver?.fullName })}
        </p>
      </>
    )
  }
  const renderStatus = ({ workflowState }: ReceivingDocument) => {
    return workflowState && <ReceivingNoteStatus state={workflowState} />
  }
  const renderDestination = ({ destination }: ReceivingDocument) => {
    return <Fallback condition={!!destination?.name}>{destination?.name}</Fallback>
  }
  const renderReceivedOn = ({ receivedDate, workflowState }: ReceivingDocument) => {
    return (
      <Fallback condition={workflowState !== ReceivingNoteState.Open}>
        <p className="break-words text-sm leading-5">{formatDate(Dates.Short, new Date(receivedDate))}</p>
        <span className="text-xs text-gray-500">{formatDate(Dates.Time, new Date(receivedDate))}</span>
      </Fallback>
    )
  }
  const renderTransfer = ({ transfer }: ReceivingDocument) => {
    return (
      <Fallback condition={!!(transfer?.id && transfer?.transferNumber)}>
        <a
          className="text-primary"
          href={`${ENTERPRISE_RECEIVING_TRANSFER}/${transfer?.id}`}
          target="_blank"
          rel="noreferrer"
        >
          {transfer?.transferNumber}
        </a>
      </Fallback>
    )
  }
  const renderTotal = ({ receivingTotalValue }: ReceivingDocument) => {
    return formatMoney(receivingTotalValue)
  }

  return (
    <Table
      dataTestId="receiving-notes-table"
      dataSource={receivingDocs}
      keyExtractor={(record) => String(record.id)}
      columns={[
        {
          title: t('receivingNotes.receivingNote', 'Receiving Note'),
          key: 'receivingNote',
          headerCellStyles: 'px-4 py-3',
          cellStyles: 'px-4 py-3',
          minWidth: 220,
          grow: true,
          render: renderReceivingNote,
        },
        {
          title: t('receivingNotes.status', 'Status'),
          key: 'status',
          headerCellStyles: 'text-center py-3',
          cellStyles: 'text-center py-3',
          minWidth: 120,
          maxWidth: 120,
          render: renderStatus,
        },
        {
          title: t('receivingNotes.destination', 'Destination'),
          key: 'destination',
          headerCellStyles: 'text-center px-2 py-3',
          cellStyles: 'text-center px-2 py-3',
          minWidth: 150,
          maxWidth: 150,
          render: renderDestination,
        },
        {
          title: t('receivingNotes.receivedOn', 'Received On'),
          key: 'receivedOn',
          headerCellStyles: 'text-center py-3',
          cellStyles: 'text-center py-3',
          minWidth: 130,
          maxWidth: 130,
          render: renderReceivedOn,
        },
        {
          title: t('receivingNotes.transfer', 'Transfer'),
          key: 'transfer',
          headerCellStyles: 'text-center py-3',
          cellStyles: 'text-center py-3',
          minWidth: 120,
          maxWidth: 120,
          render: renderTransfer,
        },
        {
          title: t('receivingNotes.total', 'Total'),
          key: 'total',
          headerCellStyles: 'text-right pr-4 pl-1 py-3',
          cellStyles: 'text-right pr-4 pl-1 py-3',
          minWidth: 90,
          maxWidth: 140,
          render: renderTotal,
        },
      ]}
    />
  )
}

export default memo(TableView)
