import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import PurchaseOrderRowItem from './PurchaseOrderRowItem'

import { PurchaseOrder } from '@/graphql/purchasing/generated/purchasing_graphql'

interface TableViewProps {
  purchaseOrders: PurchaseOrder[]
}

function TableView(props: TableViewProps) {
  const { purchaseOrders } = props
  const { t } = useTranslation()

  if (purchaseOrders.length === 0) return null

  return (
    <div className="overflow-x-auto" data-testid="purchase-orders-table-view">
      <table className="w-full text-left text-sm">
        <thead className="whitespace-nowrap border bg-white text-xs text-gray-500">
          <tr>
            <th scope="col" className="px-4 py-3">
              {t('general.purchaseOrder', 'Purchase Order')}
            </th>
            <th scope="col" className="px-4 py-3">
              {t('general.supplier', 'Supplier')}
            </th>
            <th scope="col" className="p-4 text-center">
              {t('general.status', 'Status')}
            </th>
            <th scope="col" className="p-4 text-center">
              {t('general.received', 'Received')}
            </th>
            <th scope="col" className="p-4 text-center">
              {t('general.invoiced', 'Invoiced')}
            </th>
            <th scope="col" className="p-4 text-center">
              {t('general.deliveryDate', 'Delivery Date')}
            </th>
            <th scope="col" className="p-4 text-right">
              {t('general.total', 'Total')}
            </th>
            <th scope="col" className="p-4 text-center">
              {t('general.more', 'More')}
            </th>
          </tr>
        </thead>
        <tbody className="border">
          {purchaseOrders.map((purchaseOrder) => (
            <PurchaseOrderRowItem key={purchaseOrder.id} purchaseOrder={purchaseOrder} />
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default memo(TableView)
