import { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'

import UserDetails from './UserDetails'
import SwitchOrganisation from './switch-organisation'

import LogoAStore from '@/assets/astore/logo.svg'
import LogoPPlus from '@/assets/pplus/logo.svg'
import { User } from '@/graphql/access/generated/access_graphql'
import { Purchaser } from '@/graphql/purchasing/generated/purchasing_graphql'
import { useSession } from '@/modules/access/hooks'
import { useCurrentOrganisation } from '@/modules/purchasing/hooks'
import { Button } from '@/modules/shared/components'
import { ChevronDownIcon, MenuIcon, UserIcon } from '@/modules/shared/icons'
import { ElementSwitch } from '@/modules/shared/layouts'

interface HeaderProps {
  setDrawerOpen?: Dispatch<SetStateAction<boolean>>
  toggleSidebar?: boolean
  setToggleSidebar?: Dispatch<SetStateAction<boolean>>
  showSidebarButtons?: boolean
}

export default function Header(props: HeaderProps) {
  const { setDrawerOpen, toggleSidebar = true, showSidebarButtons = false, setToggleSidebar } = props
  const { t } = useTranslation()
  const { currentUser, loading } = useSession()
  const { currentOrganisation } = useCurrentOrganisation()
  const [openSwitchOrg, setOpenSwitchOrg] = useState(false)
  const signinUrl = `${process.env.REACT_APP_NINJA_API_HOST}/access/users/sign_in?redirect_url=${window.location}`
  const isAuthenticated = currentUser && currentOrganisation && !loading

  return (
    <header className="fixed z-10 flex w-full items-center justify-between border-b bg-white px-4 py-3">
      <div className="h-11">
        <div className="flex items-center justify-center gap-x-3">
          {showSidebarButtons && (
            <>
              <Button
                data-testid="open-drawer-btn"
                className="flex h-11 items-center justify-between gap-6 rounded-md bg-gray-100 px-3 py-1 xl:hidden"
                onClick={() => setDrawerOpen && setDrawerOpen(true)}
              >
                <MenuIcon className="h-7 w-7 rotate-180" />
              </Button>
              <Button
                data-testid="toggle-sidebar-btn"
                className=" hidden h-11 items-center justify-between space-x-2 rounded-md bg-gray-100 px-3 py-1 xl:flex"
                onClick={() => setToggleSidebar && setToggleSidebar(!toggleSidebar)}
              >
                <MenuIcon className="h-7 w-7 rotate-180" />
                <p className="text-sm">{t('general.menu', 'Menu')}</p>
              </Button>
            </>
          )}
          {!loading && (
            <a href="/">
              <ElementSwitch
                aStoreElement={<img src={LogoAStore} className="h-full" alt="astore" />}
                defaultElement={<img src={LogoPPlus} className="h-full" alt="purchase-plus" />}
              />
            </a>
          )}
        </div>
      </div>
      <nav className="flex">
        {!isAuthenticated && (
          <a
            className="rounded-md bg-primary px-6 py-3 text-sm text-white hover:opacity-95"
            data-testid="signin-link"
            href={signinUrl}
          >
            {t('access.signIn', 'Sign In')}
          </a>
        )}
        {isAuthenticated && (
          <>
            <UserDetails
              currentUser={currentUser as User}
              currentOrganisation={currentOrganisation as Purchaser}
              setOpenSwitchOrg={setOpenSwitchOrg}
            >
              <UserIcon className="h-7 w-7" />
              <div className="pl-2 pr-6 text-left">
                <p className="text-xxs font-semibold">{currentUser.fullName}</p>
                <p className="text-xs text-primary">{currentOrganisation.name}</p>
              </div>
              <ChevronDownIcon className="h-6 w-6" aria-hidden="true" />
            </UserDetails>

            <SwitchOrganisation isOpen={openSwitchOrg} setIsOpen={setOpenSwitchOrg} />
          </>
        )}
      </nav>
    </header>
  )
}
