import { Helmet } from 'react-helmet-async'
import { Trans, useTranslation } from 'react-i18next'

import { useSession } from '@/modules/access/hooks'
import { useCurrentOrganisation } from '@/modules/purchasing/hooks'
import { Header } from '@/modules/requisitions/layouts'
import { SUPPORT_LINK } from '@/modules/shared/constants'

interface AccessDeniedProps {
  showHeader?: boolean
}

function AccessDenied({ showHeader = false }: AccessDeniedProps) {
  const { currentUser } = useSession()
  const { currentOrganisation } = useCurrentOrganisation()
  const { t } = useTranslation()
  return (
    <>
      <Helmet>
        <title>{t('access.accessNotPermitted', 'Access Not Permitted')}</title>
      </Helmet>
      {showHeader && <Header />}
      <div className="mx-auto w-full px-4 py-32 sm:px-8 lg:w-[500px]">
        <div className="flex flex-col items-center">
          <h1 className="my-3 text-center text-xl font-bold">
            {t('access.accessNotPermitted', 'Access Not Permitted')}
          </h1>
          <p className="text-center text-sm text-gray-500">
            <Trans t={t} i18nKey="access.notPermittedMessage">
              Hmmm. It looks like your account has not been configured to access this application. This might be because
              your Organisation has not been granted access yet. You can go to PurchasePlus Enterprise using the button
              below, or{' '}
              <a
                data-testid="view-support-link"
                href={SUPPORT_LINK}
                target="_blank"
                rel="noreferrer"
                className="text-primary"
              >
                contact our friendly support team
              </a>{' '}
              for assistance with your account configuration.
            </Trans>
          </p>
          <div data-testid="user-details" className=" mt-8 flex flex-col items-center text-center">
            <div className="flex">
              <p className="text-sm text-gray-500">{t('access.currentUser', 'Current User')}:</p>
              <p className="ml-2 text-sm text-gray-500">{currentUser?.fullName}</p>
            </div>
            <div className="flex">
              <p className="text-sm text-gray-500">{t('access.emailAddress', 'Email Address')}:</p>
              <p className="ml-2 text-sm text-gray-500">{currentUser?.email}</p>
            </div>
            <div className="flex">
              <p className="text-sm text-gray-500">{t('access.organisation', 'Organisation')}:</p>
              <p className="ml-2 text-sm text-gray-500">{currentOrganisation?.name}</p>
            </div>
          </div>
          <a
            className="mt-8 w-full cursor-pointer rounded-md bg-gray-200 p-3 text-center text-sm transition hover:brightness-95"
            data-testid="view-pplusenterprise-link"
            href={process.env.REACT_APP_NINJA_API_HOST}
          >
            {t('access.goToPPlusEnterprise', 'Go to PurchasePlus Enterprise')}
          </a>
        </div>
      </div>
    </>
  )
}

export default AccessDenied
