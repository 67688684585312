import { Fragment, memo } from 'react'
import { useTranslation } from 'react-i18next'

import { PricedCatalogue } from '@/graphql/purchasing/generated/purchasing_graphql'
import { MoreOptionsMenu, PPNewDetailLink, SimpleTileViewItem } from '@/modules/shared/components'
import { OptionIcon } from '@/modules/shared/icons'
import { Dates } from '@/modules/shared/types'
import { formatDate } from '@/modules/shared/utils'

interface TileViewProps {
  pricedCatalogs: PricedCatalogue[]
}

function TileView({ pricedCatalogs }: TileViewProps) {
  const { t } = useTranslation()
  return (
    <Fragment>
      {pricedCatalogs.map((pricedCatalog) => {
        const { id, title, owner, supplier, updatedAt } = pricedCatalog
        return (
          <div key={id} className="mb-6 rounded-md border bg-white">
            <div className="flex flex-col p-4">
              <span className="break-all text-base font-bold">{title}</span>
              <p className="text-sm text-gray-500">{owner?.name}</p>
            </div>
            <hr />
            <section className="p-4 text-sm" data-testid="self-managed-catalog-card-details">
              <SimpleTileViewItem className="mb-3" title={t('catalogs.supplier', 'Supplier')} value={supplier?.name} />
              <SimpleTileViewItem className="mb-3" title={t('catalogs.owner', 'Owner')} value={owner?.name} />
              <SimpleTileViewItem
                className="mb-3"
                title={t('catalogs.lastUpdatedDate', 'Last Updated Date')}
                value={formatDate(Dates.Short, updatedAt)}
              />
              <SimpleTileViewItem
                className="mb-3"
                title={t('catalogs.lastUpdatedTime', 'Last Updated Time')}
                value={formatDate(Dates.Time, updatedAt)}
              />
            </section>
            <hr />
            <div className="flex w-full items-center justify-between p-4">
              <PPNewDetailLink
                type="catalog"
                id={String(id)}
                className="flex h-12 items-center justify-center rounded-md bg-gray-200 p-4 text-center text-sm transition hover:brightness-95"
              >
                {t('catalogs.viewCatalog', 'View Catalog')}
              </PPNewDetailLink>

              <MoreOptionsMenu showOpenInPPlus showHelp pricedCatalogId={id}>
                <OptionIcon className="h-10 w-10 text-gray-500" data-testid="more-options-button-card" />
              </MoreOptionsMenu>
            </div>
          </div>
        )
      })}
    </Fragment>
  )
}

export default memo(TileView)
