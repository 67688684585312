import { useTranslation } from 'react-i18next'

import { Button, Modal } from '@/modules/shared/components'

interface LinkInvoiceModalFooterProps {
  onCloseModal: () => void
  updateLineLoading: boolean
  hasLines: boolean
  canBeUpdated: boolean
  purchaseOrderLineItemId: number
  purchaseOrderId: number
  onLinkLine: () => void
  selectedLineId: number
}

export default function LinkInvoiceModalFooter({
  updateLineLoading,
  hasLines,
  canBeUpdated,
  onCloseModal,
  purchaseOrderId,
  purchaseOrderLineItemId,
  onLinkLine,
  selectedLineId,
}: LinkInvoiceModalFooterProps) {
  const { t } = useTranslation()

  return (
    <Modal.Footer className="flex flex-col items-center justify-between gap-2 md:flex-row">
      <div className="w-full">
        {/* TODO: Unlinking needs a BE fix to work, should work as is when done */}
        {/* {!!purchaseOrderLineItemId && canBeUpdated && (
       <Button
         onClick={() => onLinkLine(true)}
         className="h-11 w-full rounded-md bg-gray-200 px-5 text-sm md:w-fit"
         loading={updateLineLoading}
       >
         {t('invoices.invoice.linkModal.unlinkLine', 'Unlink Line')}
       </Button>
      )} */}
      </div>
      <div className="flex w-full flex-col-reverse justify-end gap-2 md:flex-row">
        <Button
          className="h-11 w-full rounded-md bg-gray-200 px-5 text-sm md:w-fit"
          onClick={onCloseModal}
          data-testid="close-button"
        >
          {!purchaseOrderLineItemId && purchaseOrderId && canBeUpdated
            ? t('general.cancel', 'Cancel')
            : t('general.close', 'Close')}
        </Button>
        {hasLines && canBeUpdated && (
          <Button
            className="h-11 w-full rounded-md bg-primary px-5 text-sm text-white md:w-fit"
            onClick={() => onLinkLine()}
            data-testid="apply-link"
            loading={updateLineLoading}
            disabled={!selectedLineId || purchaseOrderLineItemId === selectedLineId}
          >
            {!!purchaseOrderLineItemId
              ? t('invoices.invoice.lineModal.updateLink', 'Update Link')
              : t('invoices.invoice.lineModal.applyLink', 'Apply Link')}
          </Button>
        )}
      </div>
    </Modal.Footer>
  )
}
