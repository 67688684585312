import { NetworkStatus, useQuery } from '@apollo/client'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate, useParams } from 'react-router-dom'

import ReceivingNoteSummary from './summary'

import { GetPoReceivingNoteDocument } from '@/graphql/purchasing/generated/purchasing_graphql'
import { ReceivingNoteState } from '@/modules/receiving-notes/types'
import { Breadcrumb, QueryResult } from '@/modules/shared/components'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { HomeIcon } from '@/modules/shared/icons'

export default function ReceivingNote() {
  const { receivingNoteId, purchaseOrderId } = useParams<{ receivingNoteId: string; purchaseOrderId: string }>()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const {
    data: GRData,
    networkStatus,
    previousData: GRPreviousData,
    error,
  } = useQuery(GetPoReceivingNoteDocument, {
    variables: {
      receivingDocumentId: Number(receivingNoteId),
      purchaseOrderId: Number(purchaseOrderId),
    },
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
    notifyOnNetworkStatusChange: true,
  })

  const loading = networkStatus === NetworkStatus.loading

  const data = GRData || GRPreviousData

  const receivingDoc = data?.currentPurchaser?.purchaseOrder?.receivingDocument

  useEffect(() => {
    if (data) {
      data.currentPurchaser?.purchaseOrder?.receivingDocument?.workflowState === ReceivingNoteState.Open &&
        navigate(
          generatePath('/purchase-orders/:purchaseOrderId/receiving-notes/:receivingNoteId/draft', {
            purchaseOrderId: String(purchaseOrderId),
            receivingNoteId: String(receivingNoteId),
          })
        )
    }
  }, [data])

  return (
    <QueryResult loading={loading} error={error}>
      <div className="mx-auto max-w-[1400px] space-y-5 px-4">
        <Breadcrumb className="rounded-md bg-gray-200 px-3 py-2" data-testid="purchase-order-breadcrumb">
          <Breadcrumb.Item icon={HomeIcon} href="/dashboard"></Breadcrumb.Item>
          <Breadcrumb.Item href="/purchase-orders/receiving-notes">
            {t('breadcrumbs.receivingNotes', 'Receiving Notes')}
          </Breadcrumb.Item>
          <Breadcrumb.Item href={`/purchase-orders/${data?.currentPurchaser?.purchaseOrder?.id}`}>
            {data?.currentPurchaser?.purchaseOrder?.purchaseOrderNumber}
          </Breadcrumb.Item>
          <Breadcrumb.Item>{receivingDoc?.receivingDocumentNumber}</Breadcrumb.Item>
        </Breadcrumb>
        <ReceivingNoteSummary />
      </div>
    </QueryResult>
  )
}
