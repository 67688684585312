import { useLazyQuery, useMutation } from '@apollo/client'
import { RadioGroup } from '@headlessui/react'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'

import {
  ChangeOrganisationDocument,
  GetOrganisationsDocument,
  Organisation,
  RansackDirection,
} from '@/graphql/access/generated/access_graphql'
import { Purchaser } from '@/graphql/purchasing/generated/purchasing_graphql'
import { useSession } from '@/modules/access/hooks'
import { useCurrentOrganisation } from '@/modules/purchasing/hooks'
import { Spinner } from '@/modules/requisitions/components'
import { Button, Modal } from '@/modules/shared/components'
import SearchAndFilter, { OnFilter } from '@/modules/shared/components/search-input/SearchAndFilter'
import { CheckboxIcon } from '@/modules/shared/icons-special'
import { checkNetworkStatus, extractEdges } from '@/modules/shared/utils'

interface Props {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
}

export default function SwitchOrganisation({ isOpen, setIsOpen }: Props) {
  const { t } = useTranslation()

  const [selectedType, setSelectedType] = useState('')
  const { loading: loadingCurrentOrganisation } = useSession()
  const { currentOrganisation } = useCurrentOrganisation()

  const [fetchOrganisations, { data, refetch, fetchMore, networkStatus }] = useLazyQuery(GetOrganisationsDocument, {
    variables: {
      filter: {
        q: [
          {
            property: 'name_cont',
            value: '',
          },
        ],
      },
      sort: [
        {
          property: 'name',
          direction: RansackDirection.Asc,
        },
      ],
      first: 25,
      after: null,
    },
    notifyOnNetworkStatusChange: true,
  })

  const { setVariablesLoading, loading: networkLoading } = checkNetworkStatus(networkStatus)

  const [changeOrganisation, { loading }] = useMutation(ChangeOrganisationDocument)

  const onFetchMore = () => {
    fetchMore({
      variables: {
        after: data?.organisations?.pageInfo?.endCursor,
      },
    })
  }

  const onFilter = ({ searchValue }: OnFilter) => {
    refetch({
      filter: {
        q: [
          {
            property: 'name_cont',
            value: searchValue,
          },
        ],
      },
    })
  }

  const onConfirm = () => {
    changeOrganisation({
      variables: {
        input: {
          id: Number(selectedType),
        },
      },
      onCompleted() {
        window.location.assign('/')
      },
    })
  }

  const generateOrgName = (organisation: Purchaser | Organisation) => {
    if (!organisation) return null

    let org = organisation
    let name = org.name
    while (org.parent) {
      name = `${org.parent.name} > ${name}`
      org = org.parent
    }
    return name
  }

  const onCloseModal = () => {
    setSelectedType('')
    setIsOpen(false)
  }

  const organisationsList = extractEdges<Organisation>(data?.organisations)

  useEffect(() => {
    if (isOpen) {
      fetchOrganisations()
    }
  }, [isOpen])

  return (
    <Modal showModal={isOpen} onCloseModal={onCloseModal}>
      <Modal.Panel
        data-testid="switch-organisation"
        className="flex h-[80vh] w-full flex-col overflow-hidden rounded-md bg-white text-sm shadow-xl transition-all md:max-w-[900px]"
      >
        <Modal.Title
          title={t('access.switchOrganisation.title', 'Switch Organisation')}
          onCloseModal={onCloseModal}
          testId="switch-organisation-title"
        />

        <Modal.Body id="InfiniteScroll">
          <p className="font-bold">{t('access.switchOrganisation.currentOrganisation', 'Current Organisation')}</p>
          <p>
            {t(
              'access.switchOrganisation.currentOrganisationDescription',
              "You're currently using PurchasePlus at the following organisation."
            )}
          </p>
          <p
            data-testid="current-organisation-name"
            className="my-2 w-full rounded-md border border-gray-200 bg-gray-100 p-3"
          >
            {!loadingCurrentOrganisation && generateOrgName(currentOrganisation as Purchaser)}
          </p>
          <p className="mt-6 font-bold">{t('access.switchOrganisation.organisationList', 'Organisation List')}</p>
          <p data-testid="switch-organisation-description">
            {t(
              'access.switchOrganisation.organisationListDescription',
              "Please select an organisation that you wish to switch to. An organisation is a business, department or property that you are allowed to use PurchasePlus on behalf of. When switching to a different organisation, your entire session will change to that organisation. You can switch between organisations at any time. Please scroll the list below or search for an organisation that you're able to switch to."
            )}
          </p>
          <div className="my-2">
            <SearchAndFilter
              testId="organisation-search-input"
              placeholder={t('access.switchOrganisation.search', 'Search')}
              ariaLabel={t('access.switchOrganisation.ariaLabel', 'Searching for organizations')}
              onFilter={onFilter}
            />
          </div>

          {networkLoading || setVariablesLoading ? (
            <div className="mt-5 flex justify-center">
              <Spinner className="h-10 w-10" />
            </div>
          ) : (
            <RadioGroup data-testid="switch-organisation-options" className="rounded-md border border-gray-200">
              <InfiniteScroll
                dataLength={organisationsList.length}
                next={onFetchMore}
                hasMore={!!data?.organisations?.pageInfo?.hasNextPage}
                loader={<Spinner className="mt-5 h-14 md:w-16" />}
                scrollableTarget="InfiniteScroll"
                className="divide-y"
              >
                {organisationsList.map((organisation) => {
                  return (
                    <RadioGroup.Option
                      value={organisation.id}
                      key={organisation.id}
                      onClick={() => setSelectedType(String(organisation.id))}
                      className="w-full cursor-pointer bg-gray-100 p-3 first:rounded-t-md last:rounded-b-md"
                    >
                      {({ checked }) => (
                        <div className="flex items-center gap-x-3">
                          <span className="shrink-0">
                            <CheckboxIcon isSelected={checked} />
                          </span>
                          <span>{generateOrgName(organisation as Organisation)}</span>
                        </div>
                      )}
                    </RadioGroup.Option>
                  )
                })}
              </InfiniteScroll>
            </RadioGroup>
          )}
        </Modal.Body>
        <Modal.Footer className="flex flex-col-reverse items-center justify-end gap-2 md:flex-row">
          <Button
            className=" h-11 w-full rounded-md bg-gray-200 px-5 py-3 text-sm md:w-fit"
            data-testid="switch-organisation-cancel"
            onClick={onCloseModal}
          >
            {t('access.switchOrganisation.cancel', 'Cancel')}
          </Button>
          <Button
            disabled={selectedType === ''}
            className=" h-11 w-full rounded-md bg-primary px-5 py-3 text-sm text-white md:w-fit"
            onClick={onConfirm}
            loading={loading}
            data-testid="switch-organisation-confirm"
          >
            {t('access.switchOrganisation.title', 'Switch Organisation')}
          </Button>
        </Modal.Footer>
      </Modal.Panel>
    </Modal>
  )
}
