import { useParams } from 'react-router-dom'

import { Price } from '@/graphql/purchasing/generated/purchasing_graphql'
import {
  useCreateAdvancedRequisitionLine,
  useDeleteAdvancedRequisitionLine,
  useUpdateRequisitionLine,
} from '@/modules/requisitions/hooks'

interface useSupplierQuotesModalActionsProps {
  onCloseModal: () => void
  cataloguedProductId: number
}

export interface OnCreateRequisitionLine {
  productId: number
  selectedSupplier: Price
  quantity: number
}

export interface UpdateSupplier {
  lineId?: number | null
  quantity?: number | null
  selectedSupplier?: Price | null
}

export const useSupplierQuotesModalActions = ({
  onCloseModal,
  cataloguedProductId,
}: useSupplierQuotesModalActionsProps) => {
  const { requisitionId } = useParams<{ requisitionId: string }>()

  const [updateRequisitionLine, { loading: updateLoading }] = useUpdateRequisitionLine()
  const [createAdvancedRequisitionLine, { loading: createLoading }] = useCreateAdvancedRequisitionLine()
  const [deleteAdvancedRequisitionLine, { loading: deleteLoading }] = useDeleteAdvancedRequisitionLine(
    Number(requisitionId),
    cataloguedProductId
  )

  const onCreateRequisitionLine = ({ productId, selectedSupplier, quantity }: OnCreateRequisitionLine) => {
    createAdvancedRequisitionLine({
      variables: {
        input: {
          cataloguedProductId: selectedSupplier.cataloguedProductId,
          productId: productId,
          supplierId: selectedSupplier?.supplierId,
          unitPrice: selectedSupplier?.unitPrice,
          requisitionId: Number(requisitionId),
          taxPercentage: selectedSupplier?.taxPercentage,
          quantity: quantity,
        },
      },
      onCompleted() {
        onCloseModal()
      },
    })
  }

  const updateSupplier = ({ lineId, quantity, selectedSupplier }: UpdateSupplier) => {
    if (quantity === 0 && lineId) {
      deleteAdvancedRequisitionLine({
        variables: {
          input: {
            id: Number(lineId),
          },
        },
        onCompleted() {
          onCloseModal()
        },
      })
    } else {
      updateRequisitionLine({
        variables: {
          input: {
            id: Number(lineId),
            unitPrice: selectedSupplier?.unitPrice,
            taxPercentage: selectedSupplier?.taxPercentage,
            supplierId: selectedSupplier?.supplierId,
            productId: selectedSupplier?.productId,
            cataloguedProductId: selectedSupplier?.cataloguedProductId,
            quantity: quantity,
          },
        },
        onCompleted() {
          onCloseModal()
        },
      })
    }
  }
  return {
    onCreateRequisitionLine,
    updateSupplier,
    loading: updateLoading || createLoading || deleteLoading,
  }
}
