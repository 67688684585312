import { useTranslation } from 'react-i18next'

import { Dates } from '../types'
import { camelCaseToWords, formatDate, snakeCaseToWords } from '../utils'

import { Activity } from '@/graphql/purchasing/generated/purchasing_graphql'

interface AuditLogTableProps {
  auditLogsList: Activity[]
}

export default function AuditLogTable(props: AuditLogTableProps) {
  const { t } = useTranslation()
  const { auditLogsList } = props

  return (
    <table className="w-full text-left text-sm">
      <thead className="whitespace-nowrap border bg-white text-xs text-gray-500">
        <tr>
          <th scope="col" className="px-4 py-3 text-left">
            {t('auditLog.action', 'Action')}
          </th>
          <th scope="col" className="px-4 py-3 text-left">
            {t('auditLog.item', 'Item')}
          </th>
          <th scope="col" className="px-4 py-3 text-center">
            {t('auditLog.user', 'User')}
          </th>
          <th scope="col" className="px-4 py-3 text-center">
            {t('auditLog.actionDate', 'Action Date')}
          </th>
          <th scope="col" className="px-4 py-3 text-center">
            {t('auditLog.actionTime', 'Action Time')}
          </th>
          <th scope="col" className="px-4 py-3 text-center">
            {t('auditLog.state', 'State')}
          </th>
        </tr>
      </thead>
      <tbody className="border">
        {auditLogsList
          .sort((a, b) => new Date(b.updatedAt).valueOf() - new Date(a.updatedAt).valueOf())
          .map((log) => (
            <tr className="whitespace-nowrap border-b bg-white" key={log.id}>
              <td className="px-4 py-3 text-left">{snakeCaseToWords(log.action?.split('_').join(' ') || '')}</td>
              <td className="px-4 py-3 text-left">{camelCaseToWords(log.contextType?.split('::').at(-1) || '')}</td>
              <td className="px-4 py-3 text-center">{log?.user?.fullName}</td>
              <td className="px-4 py-3 text-center">{formatDate(Dates.Short, log.updatedAt)}</td>
              <td className="px-4 py-3 text-center">{formatDate(Dates.Time, log.updatedAt)}</td>
              <td className="px-4 py-3 text-center">{log.state ? snakeCaseToWords(log.state) : '---'}</td>
            </tr>
          ))}
      </tbody>
    </table>
  )
}
