import i18next from 'i18next'

import { InvoiceStatuses } from '../../types'

/**
 * returns invoice filter text
 *
 * @param {string} status
 * @returns {string}
 */
export const invoiceFilterText = (status: string): string => {
  switch (status) {
    case InvoiceStatuses.Open:
      return i18next.t('invoices.allInvoices.filter.open', 'Open')
    case InvoiceStatuses.Flagged:
      return i18next.t('invoices.allInvoices.filter.flagged', 'Flagged')
    case InvoiceStatuses.Reconciled:
      return i18next.t('invoices.allInvoices.filter.reconciled', 'Reconciled')
    case InvoiceStatuses.Processing:
      return i18next.t('invoices.allInvoices.filter.processing', 'Processing')
    case InvoiceStatuses.Processed:
      return i18next.t('invoices.allInvoices.filter.processed', 'Processed')
    case InvoiceStatuses.Cancelled:
      return i18next.t('invoices.allInvoices.filter.cancelled', 'Cancelled')
    case InvoiceStatuses.Parked:
      return i18next.t('invoices.allInvoices.filter.parked', 'Parked')
    default:
      return i18next.t('invoices.allInvoices.filter.viewAll', 'View All')
  }
}
