import { useTranslation } from 'react-i18next'

import { Button, Input, Tooltip } from '@/modules/shared/components'
import { QuestionMarkIcon } from '@/modules/shared/icons'

type TableValuesState = {
  id: number
  value: string
  ariaLabel: string
  onChange?: (value: string) => void
  isDisabled?: boolean
  register?: boolean
}

interface BalanceTableRowProps {
  title: string
  tooltip: string
  onCalculateTotals?: () => void
  inputs?: TableValuesState[]
}

export default function BalanceTableRow({ title, tooltip, onCalculateTotals, inputs }: BalanceTableRowProps) {
  const { t } = useTranslation()

  return (
    <>
      <div className="flex w-full justify-between">
        <div className="flex items-center gap-x-1">
          {title}
          <Tooltip content={tooltip}>
            <span>
              <QuestionMarkIcon className="h-4 w-4" />
            </span>
          </Tooltip>
        </div>
        {onCalculateTotals && (
          <Button
            className="mr-4 rounded-md bg-success px-4 py-2 text-white"
            onClick={onCalculateTotals}
            data-testid="calculate-button"
          >
            {t('invoices.invoice.balance.modal.table.calculate', 'Calculate')}
          </Button>
        )}
      </div>
      {inputs && (
        <div className="flex items-center gap-x-3">
          {inputs.map(({ id, value, ariaLabel, onChange, isDisabled }) => (
            <Input
              key={id}
              className="w-28 rounded-md border-gray-200 text-right text-sm arrow-hide"
              value={value}
              isDisabled={isDisabled}
              onChange={(e) => onChange && onChange(e)}
              aria-label={ariaLabel}
              type="number"
            />
          ))}
        </div>
      )}
    </>
  )
}
