import { useQuery } from '@apollo/client'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useParams } from 'react-router-dom'

import Details from '../../requisition/page-header/Details'
import CartActions from './CartActions'
import CartHeader from './CartHeader'

import { Requisition } from '@/graphql/purchasing/generated/purchasing_graphql'
import { GetRequisitionDocument } from '@/graphql/purchasing/generated/purchasing_graphql'
import { ShopHeader } from '@/modules/requisitions/layouts'
import { QueryResult } from '@/modules/shared/components'
import HeaderTabs, { TabItem } from '@/modules/shared/components/header-tabs'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'

function PageHeader() {
  const { t } = useTranslation()
  const { requisitionId } = useParams<{ requisitionId: string }>()
  const [showInfo, setShowInfo] = useState<boolean>(false)

  const { data, loading } = useQuery(GetRequisitionDocument, {
    variables: { requisitionId: Number(requisitionId) },
    context: { uri: PURCHASING_GRAPHQL_API },
  })
  const actionList: TabItem[] = [
    { target: 'summary', label: t('requisitionInformation.summary.title', 'Product Summary') },
    { target: 'settings', label: t('requisitionInformation.setting.title', 'Settings') },
    {
      target: 'comments',
      label: t('requisitionInformation.comment.title', 'Comments'),
      count: Number(data?.currentPurchaser?.requisition?.commentsCount),
    },
    { target: 'attachments', label: t('requisitionInformation.attachments.title', 'Attachments') },
  ]

  return (
    <>
      <ShopHeader isCart />
      <QueryResult loading={loading}>
        <div className="mx-auto max-w-[1400px] space-y-5 p-4">
          <section className="flex flex-col gap-y-2">
            <CartHeader showInfo={showInfo} setShowInfo={setShowInfo} />
            {showInfo && (
              <Details requisition={data?.currentPurchaser?.requisition as Requisition} hidePurchaseOrders />
            )}
            <CartActions requisition={data?.currentPurchaser?.requisition as Requisition} />
            <HeaderTabs actionList={actionList} />
          </section>
          <Outlet context={{ readonly: false }} />
        </div>
      </QueryResult>
    </>
  )
}

export default PageHeader
