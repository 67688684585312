import classNames from 'classnames'
import { t } from 'i18next'
import { useFormContext } from 'react-hook-form'

interface FormInputProps {
  title: string
  description: string
  register: string
  placeholder: string
  disabled?: boolean
  required?: boolean
  requiredError?: string
  maxLength?: number
}

export default function FormInput({
  disabled,
  description,
  register,
  placeholder,
  title,
  required = false,
  requiredError,
  maxLength = 50,
}: FormInputProps) {
  const formMethods = useFormContext()

  return (
    <section className="mt-8">
      <h2 className="text-sm font-semibold">{title}</h2>
      <p className="text-sm text-gray-500">{description}</p>
      <div className="mt-4">
        <input
          disabled={disabled}
          data-testid={`${register}-input`}
          className={classNames('block w-full rounded-md border border-gray-300 p-3 shadow-sm sm:text-sm', {
            'border-error focus:border-error focus:outline-none focus:ring-error':
              formMethods.formState.errors[register],
            'focus:border-primary focus:outline-none focus:ring-primary': !formMethods.formState.errors[register],
            'bg-gray-200': disabled,
          })}
          type="text"
          placeholder={placeholder}
          aria-invalid={!!formMethods.formState.errors[register]}
          {...formMethods.register(register, { maxLength, required })}
        />
        {formMethods.formState.errors[register] && formMethods.formState.errors[register]?.type === 'maxLength' && (
          <span className="mt-2 block text-sm text-error" role="alert">
            {t('createRequisition.reference.maxLength', 'Maximum character length is 50.')}
          </span>
        )}
        {required &&
          formMethods.formState.errors[register] &&
          formMethods.formState.errors[register]?.type === 'required' && (
            <span className="mt-2 block text-sm text-error" role="alert">
              {requiredError}
            </span>
          )}
      </div>
    </section>
  )
}
