import { graphql } from 'msw'

import { GetSupplierRelationshipQuery } from '@/graphql/purchasing/generated/purchasing_graphql'

export const GetSupplierRelationship = graphql.query<GetSupplierRelationshipQuery>(
  'GetSupplierRelationship',
  (_, res, ctx) => {
    return res(
      ctx.data({
        currentPurchaser: {
          id: 44926,
          supplierRelationship: {
            id: 67987,
            supplierNumber: '062014156',
            isEnabled: true,
            department: {
              id: 85127,
              name: 'Beverage',
              __typename: 'Purchaser',
            },
            account: {
              id: 96391,
              accountName: '0060007 - Operating Account - 7',
              __typename: 'Account',
            },
            supplier: {
              id: 168,
              name: 'Zeus Wholesale',
              billingAddressLine1: '1 Missenden',
              billingAddressLine2: 'Building 100 , Harbour bridge crescent street,',
              billingAddressCity: 'Surry Hills',
              billingAddressStateProvince: 'NSW',
              billingAddressPostalCode: '2011',
              postalAddressLine1: '1 Missenden',
              postalAddressLine2: 'Building 100 , Harbour bridge crescent street, ',
              postalAddressCity: 'Surry Hills',
              postalAddressStateProvince: 'NSW',
              postalAddressPostalCode: '2011',
              telephone: '+611300411014',
              email: 'zeuswholesale@gmail.com',
              businessNumber: '062014156',
              deliveryChargeConfiguration: {
                deliveryChargeExTax: 25.55,
                deliveryChargeTaxPercentage: 0,
                minimumOrderValueExTax: 85.5,
                __typename: 'DeliveryChargeConfiguration',
              },
              __typename: 'Supplier',
            },
            defaultCategory: {
              id: 109446,
              name: 'Beer',
              familyCategoryId: 1,
              __typename: 'Category',
            },
            __typename: 'SupplierRelationship',
          },
          __typename: 'Purchaser',
        },
      })
    )
  }
)
