import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import DraftRequisitionRow from './DraftRequisitionRow'

import { Requisition } from '@/graphql/purchasing/generated/purchasing_graphql'
import { useCheckShoppingAvailable } from '@/modules/requisitions/hooks'
import { generateShopPath } from '@/modules/requisitions/utils'

interface TableViewProps {
  draftList: Requisition[]
}

function TableView(props: TableViewProps) {
  const { draftList } = props
  const { t } = useTranslation()
  const { ShoppingNotAvailableModal, modalProps, onShowModal, onCheckShoppingAvailable } = useCheckShoppingAvailable()

  if (!draftList.length) {
    return (
      <div className="mt-5 rounded-md border py-8 text-center text-sm" data-testid="dashboard-no-drafts">
        {t('dashboard.draftRequisitions.noDrafts', "You don't have any Draft Requisitions")}
      </div>
    )
  }

  return (
    <div className="rounded-md shadow-sm" data-testid="dashboard-draft-requisitions-table">
      {draftList.map((requisition) => {
        return (
          <div
            key={requisition?.id}
            className="items-center border-b bg-white p-4 shadow first:rounded-t-md last:rounded-b-md last:border-b-0"
          >
            <Link
              to={generateShopPath(requisition?.id, requisition?.advanced)}
              onClick={(e) => {
                if (!onCheckShoppingAvailable(requisition)) {
                  e.preventDefault()
                  onShowModal()
                }
              }}
              className="sm:hidden"
            >
              <div className="flex cursor-pointer items-center">
                <DraftRequisitionRow requisition={requisition} />
              </div>
            </Link>
            <div className="hidden items-center sm:flex">
              <DraftRequisitionRow requisition={requisition} showActions />
            </div>
          </div>
        )
      })}
      <ShoppingNotAvailableModal {...modalProps} />
    </div>
  )
}

export default memo(TableView)
