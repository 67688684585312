import i18next from 'i18next'

import { Supplier } from '@/graphql/purchasing/generated/purchasing_graphql'

enum SupplierRelationshipStatus {
  Enabled = 'enabled',
  Disabled = 'disabled',
  Connect = 'connect',
  NotConnected = 'notConnected',
}

type handleSupplierConnectModalTextResponse = {
  connectModalTitle?: string
  connectModalInfo?: string
  supplierTitle?: string
  supplierInfo?: string
}

export const getSupplierConnectTranslation = (supplier: Supplier, responsive?: boolean) => {
  if (supplier?.supplierRelationship) {
    return supplier?.supplierRelationship?.isEnabled
      ? SupplierRelationshipStatus.Enabled
      : SupplierRelationshipStatus.Disabled
  }
  return responsive ? SupplierRelationshipStatus.NotConnected : SupplierRelationshipStatus.Connect
}

/**
 * Returns supplierDirectory.table translations
 *
 * @param {Supplier} supplier
 * @param {boolean} [reactive=false]
 *
 * @return {string}
 */
export const handleSupplierDirectoryText = (supplier: Supplier, reactive: boolean = false): string => {
  switch (getSupplierConnectTranslation(supplier, reactive)) {
    case SupplierRelationshipStatus.Enabled:
      return i18next.t('supplierDirectory.table.enabled', 'Enabled')
    case SupplierRelationshipStatus.Disabled:
      return i18next.t('supplierDirectory.table.disabled', 'Disabled')
    case SupplierRelationshipStatus.Connect:
      return i18next.t('supplierDirectory.table.connect', 'Connect')
    default:
      return i18next.t('supplierDirectory.table.notConnected', 'Not Connected')
  }
}

/**
 * Returns supplierDirectory.connectModal, and supplierDirectory.supplier translations
 *
 * @param {Supplier} supplier
 * @param {string} [name]
 *
 * @return {object} {connectModalTitle: string, connectModalInfo: string}
 */
export const handleSupplierConnectModalText = (
  supplier: Supplier,
  name?: string | null
): handleSupplierConnectModalTextResponse => {
  let connectModalTitle, connectModalInfo, supplierTitle, supplierInfo
  switch (getSupplierConnectTranslation(supplier)) {
    case SupplierRelationshipStatus.Enabled:
      connectModalTitle = i18next.t('supplierDirectory.connectModal.enabledTitle', '{{ name }} is Enabled', { name })
      connectModalInfo = i18next.t(
        'supplierDirectory.connectModal.enabledInfo',
        '{{ name }} is already connected to your Organisation. The Supplier is Enabled, meaning that you can place orders with this Supplier already. The Supplier can assign catalogues, products and prices to your organisation, and you can add these to your Cart when creating a Requisition. Speak with an administrator at your organisation if you wish to disable this Supplier',
        { name }
      )
      supplierTitle = i18next.t('supplierDirectory.supplier.enabledTitle', 'Trading Enabled')
      supplierInfo = i18next.t(
        'supplierDirectory.supplier.enabledInfo',
        'Zeus Wholesale is already connected to your Organisation. The Supplier is Enabled, meaning that you can place orders with this Supplier already. The Supplier can assign catalogues, products and prices to your organisation, and you can add these to your Cart when creating a Requisition. Speak with an administrator at your organisation if you wish to disable this Supplier.'
      )
      break
    case SupplierRelationshipStatus.Disabled:
      connectModalTitle = i18next.t('supplierDirectory.connectModal.disabledTitle', '{{ name }} is Disabled', { name })
      connectModalInfo = i18next.t(
        'supplierDirectory.connectModal.disabledInfo',
        "{{ name }} is already connected to your Organisation, but they are currently Disabled. This means that you can't place orders with this Supplier at the moment. Speak with an administrator at your organisation or contact our ",
        { name }
      )
      supplierTitle = i18next.t('supplierDirectory.supplier.disabledTitle', 'Trading Disabled')
      supplierInfo = i18next.t(
        'supplierDirectory.supplier.disabledInfo',
        "Zeus Wholesale is already connected to your Organisation, but they are currently Disabled. This means that you can't place orders with this Supplier at the moment. Speak with an administrator at your organisation or contact our friendly customer success team if you wish to have this Supplier re-enabled and begin trading with them again."
      )
      break
    default:
      connectModalTitle = i18next.t('supplierDirectory.connectModal.connectTitle', 'Connect with Another Supplier')
      connectModalInfo = i18next.t(
        'supplierDirectory.connectModal.connectInfo',
        'To initiate a trading relationship with this Supplier, please contact the Supplier using the details below. The supplier will require you to formally open an account with them outside of this system in order to begin trading. Once the Supplier has agreed to start a trading relationship with you, you will find them in your My Suppliers area, and you can begin using the Supplier in your Requisitions.'
      )
      supplierTitle = i18next.t('supplierDirectory.supplier.connectTitle', 'Not Connected')
      supplierInfo = i18next.t(
        'supplierDirectory.supplier.connectInfo',
        'To initiate a trading relationship with this Supplier, please contact the Supplier using the details shown. The supplier will require you to formally open an account with them outside of the PurchasePlus system in order to begin trading. Once the Supplier has agreed to start a trading relationship with you, you will find them in your My Suppliers area, and you can begin using the Supplier in your Requisitions.'
      )
      break
  }
  return { connectModalTitle, connectModalInfo, supplierTitle, supplierInfo }
}
