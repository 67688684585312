import { useTranslation } from 'react-i18next'

import { PricedCatalogue } from '@/graphql/purchasing/generated/purchasing_graphql'
import { TableRowSingle } from '@/modules/shared/components/page-header-details-table'
import { Dates } from '@/modules/shared/types'
import { formatDate } from '@/modules/shared/utils'

interface DetailsProps {
  pricedCatalogue: PricedCatalogue
}

function Details({ pricedCatalogue }: DetailsProps) {
  const { t } = useTranslation()
  const { title, supplier, updatedAt, id, createdAt } = pricedCatalogue

  return (
    <section
      className="flex items-center justify-between rounded-md  bg-white p-4 text-sm shadow-sm"
      data-testid="self-managed-catalog-details-tab"
    >
      <div className="w-full divide-y divide-gray-300 overflow-auto rounded-md border border-gray-300">
        <TableRowSingle
          titleText={t('catalogs.selfManagedCatalog.detailsTable.name', 'Catalog Name')}
          tooltipText={t(
            'catalogs.selfManagedCatalog.detailsTable.nameTooltip',
            'The name of the Self-Managed Catalog.'
          )}
          testId="name"
          value={title}
        />
        <TableRowSingle
          titleText={t('catalogs.selfManagedCatalog.detailsTable.supplier', 'Supplier')}
          tooltipText={t(
            'catalogs.selfManagedCatalog.detailsTable.supplierTooltip',
            'The supplier to which the Self-Managed Catalog is assigned. The products and prices included in the Catalog will be made available for purchasing from this Supplier.'
          )}
          testId="supplier"
          value={supplier?.name}
        />
        <TableRowSingle
          titleText={t('catalogs.selfManagedCatalog.detailsTable.lastUpdated', 'Last Updated')}
          tooltipText={t(
            'catalogs.selfManagedCatalog.detailsTable.lastUpdatedTooltip',
            'The time and date of the last update made to the Catalog.'
          )}
          testId="lastUpdated"
          value={formatDate(Dates.Long, updatedAt)}
        />
        <TableRowSingle
          titleText={t('catalogs.selfManagedCatalog.detailsTable.catalogId', 'Catalog ID')}
          tooltipText={t(
            'catalogs.selfManagedCatalog.detailsTable.catalogIdTooltip',
            'The unique identifier for the Catalog.'
          )}
          testId="catalogId"
          value={id}
        />
        <TableRowSingle
          titleText={t('catalogs.selfManagedCatalog.detailsTable.createdOn', 'Created On')}
          tooltipText={t(
            'catalogs.selfManagedCatalog.detailsTable.createdOnTooltip',
            'The time and date the Catalog was created.'
          )}
          testId="createdOn"
          value={formatDate(Dates.Long, createdAt)}
        />
      </div>
    </section>
  )
}

export default Details
