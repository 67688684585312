import { useTranslation } from 'react-i18next'

import { formatProductItem } from '../../../requisitions/utils'
import { Line } from './ProductModal'

import NoImage from '@/assets/images/no-image.svg'
import { Supplier } from '@/graphql/purchasing/generated/purchasing_graphql'
import { DetailsPanelItem } from '@/modules/shared/components'
import { useMoney } from '@/modules/shared/hooks'
import { titleCase } from '@/modules/shared/utils'

interface ProductDetailsProps<T extends Line> {
  line: T
  supplier?: Supplier
}

export default function ProductDetails<T extends Line>({ line, supplier }: ProductDetailsProps<T>) {
  const { unitPrice, image, supplierProductCode, taxPercentage, product, longDescription } = line
  const {
    id: productId,
    itemDescription,
    brand,
    itemSize,
    itemMeasure,
    itemPackName,
    concatenatedSellUnit,
    category: productCategory,
  } = product || {}
  const { t } = useTranslation()
  const { formatMoney } = useMoney()
  return (
    <>
      <section data-testid="product-info" className="flex flex-col gap-4 sm:flex-row sm:items-center">
        <img className="h-full w-full flex-none border sm:h-64 sm:w-64" src={image || NoImage} alt="product" />
        <div className="flex flex-col gap-4">
          <span>
            <strong className="text-base">
              {brand} {itemDescription}
            </strong>
            <p className="text-sm text-gray-500">
              {formatProductItem({
                itemSize: itemSize,
                itemMeasure: itemMeasure,
                itemPackName: itemPackName,
                itemSellUnit: concatenatedSellUnit,
              })}
            </p>
          </span>
          <span>
            <strong className="text-2xl">{formatMoney(unitPrice)}</strong>
            <p className="text-xs text-gray-500">
              {taxPercentage
                ? t('shopPage.productList.productCard.taxPercentage', 'Plus {{ percent }}% Tax', {
                    percent: taxPercentage,
                  })
                : t('shopPage.productList.productCard.taxFree', 'Tax Free')}
            </p>
          </span>
          {supplier && (
            <span data-testid="product-info-supplier">
              <strong className="text-sm">{t('general.supplier', 'Supplier')}</strong>
              <p className="text-xs text-gray-800">{supplier?.name}</p>
            </span>
          )}
        </div>
      </section>
      <section className="mt-4">
        {supplier && (
          <>
            <strong className="text-sm">{t('shopPage.productModal.productDetails.description', 'Description')}</strong>
            <p className="text-sm">
              {longDescription ||
                t(
                  'shopPage.productModal.productDetails.noLongDescription',
                  'The Supplier has not added a description.'
                )}
            </p>
          </>
        )}
        <div data-testid="product-table-info" className="mt-2 border">
          <DetailsPanelItem
            title={t('shopPage.productModal.productDetails.itemName', 'Item Name')}
            value={itemDescription}
          />
          <DetailsPanelItem title={t('shopPage.productModal.productDetails.brand', 'Brand')} value={brand} />
          <DetailsPanelItem
            title={t('shopPage.productModal.productDetails.category', 'Category')}
            value={productCategory?.parent?.name}
          />
          <DetailsPanelItem
            title={t('shopPage.productModal.productDetails.subCategory', 'Sub-Category')}
            value={productCategory?.name}
          />
          <DetailsPanelItem
            title={t('shopPage.productModal.productDetails.unitSize', 'Unit Size')}
            value={itemSize}
            description={t(
              'shopPage.productModal.productDetails.unitSizeDesc',
              'The size of 1 unit of this item e.g. “375” for a 375ml Can'
            )}
          />
          <DetailsPanelItem
            title={t('shopPage.productModal.productDetails.unitOfMeasure', 'Unit of Measure')}
            value={itemMeasure}
            description={t(
              'shopPage.productModal.productDetails.unitOfMeasureDesc',
              'The unit of measure used for the unit size e.g. “ml” for a 375ml Can'
            )}
          />
          <DetailsPanelItem
            title={t('shopPage.productModal.productDetails.itemPackname', 'Item Pack Name')}
            value={itemPackName}
            description={t(
              'shopPage.productModal.productDetails.itemPacknameDesc',
              'If applicable, the name given to the pack that the item is contained in e.g “Can” for a 375ml Can'
            )}
          />
          <DetailsPanelItem
            title={t('shopPage.productModal.productDetails.sellUnit', 'Sell Unit')}
            value={titleCase(concatenatedSellUnit || '')}
            description={t(
              'shopPage.productModal.productDetails.sellUnitDesc',
              'The number of units contained in the Sell Pack e.g. “Carton of 24” for 24 x 375ml Can'
            )}
          />
          <DetailsPanelItem
            title={t('shopPage.productModal.productDetails.taxPercentage', 'Tax Percentage')}
            value={taxPercentage ? `${taxPercentage}%` : null}
            description={t(
              'shopPage.productModal.productDetails.taxPercentageDesc',
              'The percentage of tax that will be added to the item price'
            )}
          />
          <DetailsPanelItem
            title={t('shopPage.productModal.productDetails.purchasePlusID', 'PurchasePlus ID')}
            value={productId}
            description={t(
              'shopPage.productModal.productDetails.purchasePlusIDDesc',
              'The identification code for this Product in the PurchasePlus database.'
            )}
          />
          {supplier && (
            <DetailsPanelItem
              title={t('shopPage.productModal.productDetails.supplierCode', 'Supplier Code')}
              value={supplierProductCode}
              description={t(
                'shopPage.productModal.productDetails.supplierCodeDesc',
                'If applicable, the code assigned to this item by the Supplier'
              )}
            />
          )}
        </div>
      </section>
    </>
  )
}
