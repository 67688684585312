import { useTranslation } from 'react-i18next'

import CategoryFiltersSection from './filters-section/CategoryFiltersSection'
import DateRange from './filters-section/DateRange'
import { FilterByMatchedInvoices } from './filters-section/FilterByMatchedInvoices'
import { FilterByReceivingNotes } from './filters-section/FilterByReceivingNotes'
import FilterByStockLocation from './filters-section/FilterByStockLocation'
import PeriodRange from './filters-section/PeriodRange'
import SupplierFiltersSection from './filters-section/SupplierFiltersSection'
import MoreOptions from './more-options-section/MoreOptions'
import SearchSection from './search-section/SearchSection'

import { Document } from '@/modules/shared/types'

// TODO: If this list gets too long change the config to a list of strings instead of individual props
interface AdvancedSearchProps {
  type?: Document
  showSupplier?: boolean
  showCategory?: boolean
  showSearch?: boolean
  showMoreOptions?: boolean
  shopPage?: boolean
  showMatchedInvoices?: boolean
  showReceivingNotes?: boolean
  showFilterByStockLocation?: boolean
  showFilterByCountStatus?: boolean
}

export default function AdvancedSearch({
  type,
  showSupplier,
  showCategory,
  showSearch,
  showMoreOptions,
  shopPage,
  showMatchedInvoices,
  showReceivingNotes,
  showFilterByStockLocation,
}: AdvancedSearchProps) {
  const { t } = useTranslation()

  const getDateRangeTitle = () => {
    switch (type) {
      case Document.Invoice:
        return t('invoices.allInvoices.searchByDate', 'Search By Invoice Date')
      case Document.Requisition:
      case Document.PurchaseOrder:
        return t('allRequisitions.filter.searchByDate', 'Search by Delivery Date')
      default:
        return null
    }
  }

  return (
    <div className="h-full divide-y rounded-md bg-white shadow-sm">
      {!!getDateRangeTitle() && (
        <div className="pt-1">
          <DateRange title={getDateRangeTitle() || ''} />
        </div>
      )}
      {type === Document.Stocktake && (
        <PeriodRange title={t('stocktake.allStocktake.searchByPeriod', 'Search By Period')} />
      )}
      {showSupplier && <SupplierFiltersSection shopPage={shopPage} />}
      {showCategory && <CategoryFiltersSection />}
      {showMatchedInvoices && <FilterByMatchedInvoices />}
      {showReceivingNotes && <FilterByReceivingNotes />}
      {showSearch && <SearchSection />}
      {showMoreOptions && <MoreOptions />}
      {showFilterByStockLocation && <FilterByStockLocation />}
      {/* TODO: This feature is on hold pending backend support */}
      {/* {showFilterByCountStatus && <FilterByCountStatus />} */}
    </div>
  )
}
