import classNames from 'classnames'
import { ReactNode, useRef } from 'react'
import { AriaNumberFieldProps, mergeProps, useKeyboard } from 'react-aria'
import { useLocale, useNumberField } from 'react-aria'
import { useNumberFieldState } from 'react-stately'

interface NumberInputProps extends AriaNumberFieldProps {
  showStepper?: boolean
  className?: string
  loading?: boolean
  rightUnit?: string | ReactNode
  leftUnit?: string | ReactNode
  hasError?: boolean
  errorMessage?: string
}

export default function NumberInput(props: NumberInputProps) {
  const { locale } = useLocale()
  const state = useNumberFieldState({ ...props, locale })

  const inputRef = useRef<HTMLInputElement>(null)
  const { keyboardProps } = useKeyboard({
    onKeyDown(e) {
      if (e.key === 'Enter') {
        // onChange of react-aria number input is triggered when blurring the input so when hitting enter we also need to blur the input in order to trigger the onChange
        inputRef.current?.blur()
      }
    },
  })
  const { inputProps, errorMessageProps } = useNumberField(props, state, inputRef)

  const removeMarginClassName = (classes: string = '', isReturnMarginClassName: boolean = false) => {
    const classNames = classes.split(' ')
    return classNames
      .filter((className) => {
        if (isReturnMarginClassName) {
          return className.match(/(mt|mx|my|mb|mr|ml)-\S+/)
        }
        return !className.match(/(mt|mx|my|mb|mr|ml)-\S+/)
      })
      .join(' ')
  }

  return (
    // Should add margins to the container because leftUnit and rightUnit are absolute so it will always stay at its position even we add margin to the input
    <div className={removeMarginClassName(props.className, true)}>
      <div className="relative">
        <div className="pointer-events-none absolute inset-y-0 left-0 top-1/2 flex -translate-y-1/2 items-center pl-3">
          {props.leftUnit && (
            <span className="w-16 rounded-md bg-gray-200 px-3 py-1.5 text-center">{props.leftUnit}</span>
          )}
        </div>
        <input
          {...mergeProps(inputProps, keyboardProps)}
          ref={inputRef}
          className={classNames(removeMarginClassName(props.className), {
            'pl-[85px]': !!props.leftUnit,
            'pr-[85px]': !!props.rightUnit,
            'border-error focus:border-error focus:outline-none focus:ring-error': props.hasError,
            'border-gray-200 focus:border-primary/30 focus:outline-none': !props.hasError,
            'cursor-not-allowed border-gray-300 bg-gray-200': props.isDisabled,
            'cursor-progress bg-gray-200': props.loading,
          })}
        />
        <div className="pointer-events-none absolute inset-y-0 right-0 top-1/2 flex -translate-y-1/2 items-center pr-3">
          {props.rightUnit && (
            <span className="w-16 rounded-md bg-gray-200 px-3 py-1.5 text-center">{props.rightUnit}</span>
          )}
        </div>
      </div>
      {props.hasError && props.errorMessage && (
        <span {...errorMessageProps} className="mt-2 block text-sm text-error">
          {props.errorMessage}
        </span>
      )}
    </div>
  )
}
