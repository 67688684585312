import { graphql } from 'msw'

import { CreateInvoiceMutation } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseCreateInvoice: CreateInvoiceMutation = {
  createInvoice: {
    invoice: {
      id: 7524286,
      __typename: 'Invoice',
    },
    __typename: 'CreateInvoiceMutationPayload',
  },
}

export const CreateInvoice = graphql.mutation<CreateInvoiceMutation>('CreateInvoice', (_, res, ctx) => {
  return res(ctx.data(baseCreateInvoice))
})

export default baseCreateInvoice
