import { useLazyQuery } from '@apollo/client'
import { Dispatch, SetStateAction, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import Invoices from './Invoices'
import LineDetails from './LineDetail'
import ReceivingNotes from './ReceivingNotes'

import {
  GetPurchaseOrderLineItemDocument,
  InvoiceLineItem,
  PurchaseOrderLineItem,
  ReceivingDocumentLineItem,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { Button, Modal, QueryResult } from '@/modules/shared/components'
import { PURCHASING_GRAPHQL_API, SUPPORT_LINK } from '@/modules/shared/constants'
import { extractEdges } from '@/modules/shared/utils'

interface POLineInfoModalProps {
  show: boolean
  setShow: Dispatch<SetStateAction<boolean>>
  purchaseOrderLineItemId: number
  purchaseOrderId: number
}

export default function POLineInfoModal(props: POLineInfoModalProps) {
  const { show, setShow, purchaseOrderLineItemId, purchaseOrderId } = props
  const { t } = useTranslation()

  const [fetchPurchaseOrderLine, { data, loading, error }] = useLazyQuery(GetPurchaseOrderLineItemDocument, {
    variables: {
      purchaseOrderId,
      purchaseOrderLineItemId,
      receivingDocumentLinesfilter: {
        q: [
          {
            property: 'receivingDocument_workflowState_eq',
            value: 'received',
          },
          {
            property: 'quantity_gt',
            value: 0,
          },
        ],
      },
    },
    context: { uri: PURCHASING_GRAPHQL_API },
  })

  useEffect(() => {
    if (show) {
      fetchPurchaseOrderLine()
    }
  }, [show])

  const onCloseModal = () => setShow(false)

  const purchaseOrderLineItem = data?.currentPurchaser?.purchaseOrder?.purchaseOrderLineItem as PurchaseOrderLineItem

  const invoiceLineItems = extractEdges<InvoiceLineItem>(
    data?.currentPurchaser?.purchaseOrder?.purchaseOrderLineItem?.invoiceLineItems
  )

  const receivingDocumentLineItems = data?.currentPurchaser?.purchaseOrder?.purchaseOrderLineItem
    ?.receivingDocumentLineItems?.nodes as ReceivingDocumentLineItem[]

  return (
    <Modal showModal={show} onCloseModal={() => setShow(false)}>
      <Modal.Panel className="flex h-[80vh] w-full flex-col overflow-hidden rounded-md bg-white shadow-xl transition-all md:max-w-[600px]">
        <Modal.Title
          title={t('purchaseOrders.purchaseOrder.details.poLineModal.title', 'Purchase Order Line Info')}
          onCloseModal={onCloseModal}
        />

        <Modal.Body className="space-y-4">
          <QueryResult loading={loading} error={error}>
            <section className="rounded-md bg-gray-200 p-4">
              <p className="text-sm">
                {t(
                  'purchaseOrders.purchaseOrder.details.poLineModal.description',
                  'Here you can browse detailed information relating to this Purchase Order line. This can be useful to understand the Invoiced and Received status of the item, as well as the three-way matching logic applied to the line.'
                )}
              </p>
            </section>
            <LineDetails purchaseOrderLineItem={purchaseOrderLineItem} />
            <Invoices invoiceLineItems={invoiceLineItems} />
            <ReceivingNotes receivingDocumentLineItems={receivingDocumentLineItems} />
          </QueryResult>
        </Modal.Body>
        <Modal.Footer className="flex flex-col-reverse items-center  justify-center gap-2 md:flex-row md:justify-between">
          <a
            className="text-sm text-primary"
            data-testid="learn-more-related-docs-link"
            href={SUPPORT_LINK}
            target="_blank"
            rel="noreferrer"
          >
            {t('requisition.relatedDocs.learnMore', 'Learn More about Related Documents')}
          </a>
          <Button className="h-11 w-full rounded-md bg-gray-200 px-5 text-sm md:w-fit" onClick={() => onCloseModal()}>
            {t('requisition.relatedDocs.closeBtn', 'Close')}
          </Button>
        </Modal.Footer>
      </Modal.Panel>
    </Modal>
  )
}
