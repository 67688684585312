import { useTranslation } from 'react-i18next'

import PurchaseOrderLineItemInvoicedStatus from '../../PurchaseOrderLineItemInvoicedStatus'
import PurchaseOrderLineItemReceivedStatus from '../../PurchaseOrderLineItemReceivedStatus'

import NoImage from '@/assets/images/no-image.svg'
import { InvoiceLineItem, PurchaseOrderLineItem } from '@/graphql/purchasing/generated/purchasing_graphql'
import { formatProductItem } from '@/modules/requisitions/utils'
import { Fallback, TableLineItem } from '@/modules/shared/components'
import { useMoney } from '@/modules/shared/hooks'
import { extractEdges, formatNumber } from '@/modules/shared/utils'

interface LineDetailsProps {
  purchaseOrderLineItem: PurchaseOrderLineItem
}

export default function LineDetails(props: LineDetailsProps) {
  const { purchaseOrderLineItem } = props
  const { product, unitPrice, taxPercentage, receivedStatus, receivedQuantity, quantity, image, invoicedStatus } =
    purchaseOrderLineItem
  const { t } = useTranslation()
  const { formatMoney } = useMoney()
  const yetToReceive = (quantity || 0) - (receivedQuantity || 0)
  const totalInvoicedQuantity = extractEdges<InvoiceLineItem>(purchaseOrderLineItem.invoiceLineItems).reduce(
    (total, line) => total + (line.quantity || 0),
    0
  )
  return (
    <section>
      <strong className="text-sm">
        {t('purchaseOrders.purchaseOrder.details.poLineModal.lineDetails.lineOverview', 'Line Overview')}
      </strong>
      <p className="text-sm text-gray-500">
        {t(
          'purchaseOrders.purchaseOrder.details.poLineModal.lineDetails.lineOverviewDesc',
          'A summary of the Purchase Order line.'
        )}
      </p>
      <div className="mt-4 flex items-center gap-x-4 rounded-md border p-4">
        <img className="h-24 w-24 flex-none border" src={image || NoImage} alt="product" />
        <span>
          <p className="text-sm text-primary">
            {product?.brand} {product?.itemDescription}
          </p>
          <p className="text-xs text-gray-500">
            {formatProductItem({
              itemSize: product?.itemSize,
              itemMeasure: product?.itemMeasure,
              itemPackName: product?.itemPackName,
              itemSellUnit: product?.concatenatedSellUnit,
            })}
          </p>
          <p className="text-sm font-bold">{formatMoney(unitPrice)}</p>
          <p className="text-xxs text-gray-500 sm:text-xs">
            {taxPercentage
              ? t('general.plusTax', 'Plus {{ percent }}% Tax', {
                  percent: taxPercentage,
                })
              : t('general.taxFree', 'Tax Free')}
          </p>
        </span>
      </div>
      <div className="mt-4 border" data-testid="po-line-details">
        <TableLineItem
          title={t('purchaseOrders.purchaseOrder.details.poLineModal.lineDetails.invoicedStatus', 'Invoiced Status')}
          tooltip={t(
            'purchaseOrders.purchaseOrder.details.poLineModal.lineDetails.invoicedStatusTooltip',
            'The Invoiced status indicates if the Purchase Order Line is Not Invoiced, Partly Invoiced or Fully Invoiced.'
          )}
          value={invoicedStatus && <PurchaseOrderLineItemInvoicedStatus status={invoicedStatus} />}
        />
        <TableLineItem
          title={t('purchaseOrders.purchaseOrder.details.poLineModal.lineDetails.receivedStatus', 'Received Status')}
          tooltip={t(
            'purchaseOrders.purchaseOrder.details.poLineModal.lineDetails.receivedStatusTooltip',
            'The Received status indicates if the Purchase Order Line is Not Received, Partly Received or Fully Received.'
          )}
          value={receivedStatus && <PurchaseOrderLineItemReceivedStatus status={receivedStatus} />}
        />
        <TableLineItem
          title={t('purchaseOrders.purchaseOrder.details.poLineModal.lineDetails.orderedQty', 'Ordered Qty')}
          tooltip={t(
            'purchaseOrders.purchaseOrder.details.poLineModal.lineDetails.orderedQtyTooltip',
            'The quantity on the Purchase Order.'
          )}
          value={quantity || 0}
        />
        <TableLineItem
          title={t('general.invoicedQty', 'Invoiced Qty')}
          tooltip={t(
            'purchaseOrders.purchaseOrder.details.poLineModal.lineDetails.invoicedQtyTooltip',
            'The total quantity of all Invoice Lines linked to this Purchase Order line.'
          )}
          value={totalInvoicedQuantity || 0}
        />
        <TableLineItem
          title={t('purchaseOrders.purchaseOrder.details.poLineModal.lineDetails.receivedQty', 'Received Qty')}
          tooltip={t(
            'purchaseOrders.purchaseOrder.details.poLineModal.lineDetails.receivedQtyTooltip',
            'The quantity that has already been received on one or more Receiving Notes.'
          )}
          value={receivedQuantity || 0}
        />
        <TableLineItem
          title={t('purchaseOrders.purchaseOrder.details.poLineModal.lineDetails.yetToReceive', 'Yet to Receive Qty')}
          tooltip={t(
            'purchaseOrders.purchaseOrder.details.poLineModal.lineDetails.yetToReceiveTooltip',
            'The quantity still outstanding and yet to be received.'
          )}
          value={
            <Fallback condition={yetToReceive >= 0} fallbackValue="0">
              {formatNumber(yetToReceive, 3)}
            </Fallback>
          }
        />
        <TableLineItem
          title={t('purchaseOrders.purchaseOrder.details.poLineModal.lineDetails.poUnitPrice', 'PO Unit Price')}
          tooltip={t(
            'purchaseOrders.purchaseOrder.details.poLineModal.lineDetails.poUnitPriceTooltip',
            'The tax exclusive Unit Price on the Purchase Order.'
          )}
          value={formatMoney(unitPrice)}
        />
        <TableLineItem
          title={t('purchaseOrders.purchaseOrder.details.poLineModal.lineDetails.poTaxPercentage', 'PO Tax Percentage')}
          tooltip={t(
            'purchaseOrders.purchaseOrder.details.poLineModal.lineDetails.poTaxPercentageTooltip',
            'The tax percentage on the Purchase Order that will be added to the Unit Price.'
          )}
          value={`${taxPercentage || 0}%`}
        />
      </div>
    </section>
  )
}
