import { graphql } from 'msw'

import { FlaggingMessageTypeEnum, UpdateInvoiceMutation } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseUpdateInvoice: UpdateInvoiceMutation = {
  updateInvoice: {
    invoice: {
      id: 7524140,
      adjustmentExTax: 6,
      adjustmentTax: 4,
      deliveryChargeTax: 2,
      deliveryChargeExTax: 20,
      totalTaxValue: 11,
      totalValue: 82,
      totalValueExTax: '71',
      outOfBalance: true,
      reference: '',
      invoiceNumber: 'abc',
      invoiceDate: '2020-05-20',
      departmentId: 44926,
      accountId: 96388,
      department: null,
      account: null,
      flaggingMessages: [],
      flaggedInvoiceLines: {
        edges: [],
        __typename: 'FlaggedInvoiceLineConnection',
      },
      lines: {
        edges: [
          {
            node: {
              id: 24586766,
              flaggingMessages: [
                {
                  type: FlaggingMessageTypeEnum.InvoiceFlaggedLineBlockage,
                  values: [],
                },
              ],
              __typename: 'InvoiceLineItem',
            },
            __typename: 'InvoiceLineItemEdge',
          },
        ],
        __typename: 'InvoiceLineItemConnection',
      },
      __typename: 'Invoice',
    },
    __typename: 'UpdateInvoiceMutationPayload',
  },
}

export const UpdateInvoice = graphql.mutation<UpdateInvoiceMutation>('UpdateInvoice', (_, res, ctx) => {
  return res(ctx.data(baseUpdateInvoice))
})

export default baseUpdateInvoice
