import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, generatePath } from 'react-router-dom'

import { ReceivingDocument } from '@/graphql/purchasing/generated/purchasing_graphql'
import { ReceivingNoteRoute } from '@/modules/purchase-orders/utils/receivingNoteRoute'
import { ReceivingNoteStatus } from '@/modules/receiving-notes/components'
import { ReceivingNoteState } from '@/modules/receiving-notes/types'
import { Fallback, MoreOptionsMenu } from '@/modules/shared/components'
import Table from '@/modules/shared/components/table/Table'
import { ENTERPRISE_RECEIVING_TRANSFER } from '@/modules/shared/constants'
import { useMoney } from '@/modules/shared/hooks'
import { OptionIcon } from '@/modules/shared/icons'

export interface ReceivingNotesTableViewProps {
  receivingDocs: ReceivingDocument[]
}

function ReceivingNotesTableView({ receivingDocs }: ReceivingNotesTableViewProps) {
  const { t } = useTranslation()
  const { formatMoney } = useMoney()

  const renderReceivingNote = ({ workflowState, id, purchaseOrder, receivingDocumentNumber }: ReceivingDocument) => {
    return (
      <Link
        className="cursor-pointer break-words text-sm leading-5 text-primary"
        to={generatePath(
          `/purchase-orders/:purchaseOrderId/receiving-notes/:receivingNoteId/${ReceivingNoteRoute(
            workflowState as ReceivingNoteState
          )}`,
          {
            receivingNoteId: String(id),
            purchaseOrderId: String(purchaseOrder?.id),
          }
        )}
        data-testid="receiving-note-link"
      >
        {receivingDocumentNumber}
      </Link>
    )
  }
  const renderPurchaseOrder = ({ purchaseOrder }: ReceivingDocument) => {
    return (
      <Link
        to={generatePath('/purchase-orders/:purchaseOrderId/summary', {
          purchaseOrderId: String(purchaseOrder?.id),
        })}
        className="text-primary"
        data-testid="view-po-link"
      >
        {purchaseOrder?.purchaseOrderNumber}
      </Link>
    )
  }
  const renderSupplier = ({ purchaseOrder }: ReceivingDocument) => {
    return purchaseOrder?.supplier?.name
  }
  const renderStatus = ({ workflowState }: ReceivingDocument) => {
    return workflowState && <ReceivingNoteStatus state={workflowState} />
  }
  const renderDestination = ({ destination }: ReceivingDocument) => {
    return <Fallback condition={!!destination?.name}>{destination?.name}</Fallback>
  }
  const renderReceiver = ({ receiver }: ReceivingDocument) => {
    return receiver?.fullName
  }
  const renderTransfer = ({ transfer }: ReceivingDocument) => {
    return (
      <Fallback condition={!!(transfer?.id && transfer?.transferNumber)}>
        <a
          className="text-primary"
          href={`${ENTERPRISE_RECEIVING_TRANSFER}/${transfer?.id}`}
          target="_blank"
          rel="noreferrer"
        >
          {transfer?.transferNumber}
        </a>
      </Fallback>
    )
  }
  const renderTotal = ({ receivingTotalValue }: ReceivingDocument) => {
    return formatMoney(receivingTotalValue)
  }

  const renderMoreOption = ({ id, purchaseOrder, workflowState }: ReceivingDocument) => {
    return (
      <MoreOptionsMenu
        showReceivingNoteLink
        receivingNoteId={id}
        purchaseOrderId={purchaseOrder?.id}
        receivingNoteState={workflowState as ReceivingNoteState}
      >
        <OptionIcon className="h-10 w-10 text-gray-500" />
      </MoreOptionsMenu>
    )
  }

  return (
    <Table
      dataTestId="receiving-notes-table"
      dataSource={receivingDocs}
      keyExtractor={(record) => String(record.id)}
      columns={[
        {
          title: t('receivingNotes.receivingNote', 'Receiving Note'),
          key: 'receivingNote',
          headerCellStyles: 'px-4 py-3',
          cellStyles: 'px-4 py-3',
          minWidth: 120,
          maxWidth: 120,
          render: renderReceivingNote,
        },
        {
          title: t('receivingNotes.purchaseOrder', 'Purchase Order'),
          key: 'purchaseOrder',
          headerCellStyles: 'px-4 py-3',
          cellStyles: 'px-4 py-3',
          minWidth: 130,
          maxWidth: 130,
          render: renderPurchaseOrder,
        },
        {
          title: t('receivingNotes.supplier', 'Supplier'),
          key: 'supplier',
          headerCellStyles: 'py-3',
          cellStyles: 'py-3',
          minWidth: 220,
          grow: true,
          render: renderSupplier,
        },
        {
          title: t('receivingNotes.status', 'Status'),
          key: 'status',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 120,
          maxWidth: 120,
          render: renderStatus,
        },
        {
          title: t('receivingNotes.destination', 'Destination'),
          key: 'destination',
          headerCellStyles: 'px-2 py-3 text-center',
          cellStyles: 'px-2 py-3 text-center',
          minWidth: 150,
          maxWidth: 150,
          render: renderDestination,
        },
        {
          title: t('receivingNotes.receivedBy', 'Received By'),
          key: 'receivedOn',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 130,
          maxWidth: 130,
          render: renderReceiver,
        },
        {
          title: t('receivingNotes.transfer', 'Transfer'),
          key: 'transfer',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 120,
          maxWidth: 120,
          render: renderTransfer,
        },
        {
          title: t('receivingNotes.total', 'Total'),
          key: 'total',
          headerCellStyles: 'py-3 pr-4 pl-1 text-right',
          cellStyles: 'py-3 pr-4 pl-1 text-right',
          minWidth: 90,
          maxWidth: 140,
          render: renderTotal,
        },
        {
          title: t('receivingNotes.more', 'More'),
          key: 'more',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 70,
          maxWidth: 70,
          render: renderMoreOption,
        },
      ]}
    />
  )
}

export default memo(ReceivingNotesTableView)
