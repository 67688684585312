import { graphql } from 'msw'

import { GetSubCategoriesQuery } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseGetSubCatagories: GetSubCategoriesQuery = {
  categories: {
    edges: [
      {
        node: {
          id: 109453,
          name: 'Anaesthesia Respiratory and Resuscitation',
          __typename: 'Category',
        },
        __typename: 'CategoryEdge',
      },
      {
        node: {
          id: 109470,
          name: 'Automotive',
          __typename: 'Category',
        },
        __typename: 'CategoryEdge',
      },
      {
        node: {
          id: 109445,
          name: 'Bakery',
          __typename: 'Category',
        },
        __typename: 'CategoryEdge',
      },
      {
        node: {
          id: 109446,
          name: 'Beer',
          __typename: 'Category',
        },
        __typename: 'CategoryEdge',
      },
    ],
    pageInfo: {
      endCursor: 'MjU',
      hasNextPage: false,
      __typename: 'PageInfo',
    },
    __typename: 'CategoryConnection',
  },
}

export const GetSubCategories = graphql.query<GetSubCategoriesQuery>('GetSubCategories', (_, res, ctx) => {
  return res(ctx.data(baseGetSubCatagories))
})

export default baseGetSubCatagories
