import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import LinkInvoiceModalFooter from './LinkInvoiceModalFooter'
import LinkInvoiceModalLinkableLines from './LinkInvoiceModalLinkableLines'
import LinkInvoiceModalTable from './LinkInvoiceModalTable'
import LinkInvoiceModalTableRow from './LinkInvoiceModalTableRow'

import {
  InvoiceLineItem,
  PurchaseOrderLineItem,
  RequisitionLine,
  Supplier,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { useFetchLinkableLines, useGetInvoice, useUpdateInvoiceLineItem } from '@/modules/invoices/hooks'
import { InvoiceStatuses, LinkInvoiceTableTypes } from '@/modules/invoices/types'
import { Modal, QueryResult } from '@/modules/shared/components'

interface LinkInvoiceModalProps {
  line: InvoiceLineItem
  supplier: Supplier
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<boolean>>
  purchaseOrderId: number | null | undefined
}

export default function LinkInvoiceModal({
  line,
  supplier,
  showModal,
  setShowModal,
  purchaseOrderId,
}: LinkInvoiceModalProps) {
  const { t } = useTranslation()
  const { purchaseOrderLineItemId, purchaseOrderLineItem } = line
  const [selectedLineId, setSelectedLineId] = useState<number | null>(purchaseOrderLineItemId)
  const { invoiceId } = useParams<{ invoiceId: string }>()

  const { fetchLinkableLines, linkableLines, loading, error } = useFetchLinkableLines(
    String(invoiceId),
    Number(purchaseOrderId)
  )
  const [updateInvoiceLineItem, { loading: updateLineLoading }] = useUpdateInvoiceLineItem(
    String(invoiceId),
    Number(purchaseOrderId),
    fetchLinkableLines
  )
  const { state } = useGetInvoice()

  const onCloseModal = () => {
    setShowModal(false)
  }

  const onLinkLine = (unlink?: boolean) => {
    updateInvoiceLineItem({
      variables: { input: { id: line.id, purchaseOrderLineItemId: unlink ? null : selectedLineId } },
    })
  }

  useEffect(() => {
    if (purchaseOrderId && canBeUpdated) {
      fetchLinkableLines()
    }
  }, [])

  const hasLines = !!linkableLines?.length
  const canBeUpdated = state === InvoiceStatuses.Open || state === InvoiceStatuses.Flagged

  return (
    <Modal showModal={showModal} onCloseModal={onCloseModal}>
      <Modal.Panel
        as="div"
        className="flex max-h-[80vh] w-full flex-col overflow-hidden rounded-md bg-white shadow-xl transition-all md:max-w-[900px]"
        data-testid="link-invoice-modal"
      >
        <Modal.Title
          title={
            !!purchaseOrderLineItemId
              ? t('invoices.invoice.linkModal.linkedTitle', 'Linked Invoice Line')
              : t('invoices.invoice.linkModal.unlinkedTitle', 'Link Invoice Line')
          }
          onCloseModal={onCloseModal}
        />

        <Modal.Body className="flex flex-col gap-y-5 text-sm">
          {canBeUpdated && (
            <section className="rounded-md bg-gray-200 px-5 py-3">
              {!!purchaseOrderLineItemId && canBeUpdated ? (
                <Trans t={t} i18nKey="invoices.invoice.linkModal.alreadyLinkedUpdatable">
                  This Invoice line is already linked to a Purchase Order line. If you need to, you can update the link
                  by linking it to another available Purchase Order line, or you can Unlink the line completely.
                </Trans>
              ) : (
                <Trans t={t} i18nKey="invoices.invoice.linkModal.linkDesc">
                  When a Purchase Order is matched to an Invoice, PurchasePlus will automatically link each Purchase
                  Order line to an Invoice line when it can find a logical match. When PurchasePlus can't automatically
                  link a line, you can manually link it below.
                </Trans>
              )}
            </section>
          )}
          <section>
            <p className="font-bold">{t('invoices.invoice.linkModal.selectedLine', 'Selected Invoice Line')}</p>
            <p className="text-gray-500">
              {t('invoices.invoice.linkModal.selectedLineDesc', 'Details of the selected Invoice line.')}
            </p>
            <LinkInvoiceModalTable type={LinkInvoiceTableTypes.Invoice} canBeUpdated={canBeUpdated}>
              <LinkInvoiceModalTableRow
                line={line as unknown as RequisitionLine}
                supplier={supplier}
                selectedLineId={line.id}
                canBeUpdated={canBeUpdated}
              />
            </LinkInvoiceModalTable>
          </section>
          <QueryResult loading={loading} error={error}>
            <LinkInvoiceModalLinkableLines
              supplier={supplier}
              purchaseOrderId={Number(purchaseOrderId)}
              purchaseOrderLineItemId={Number(purchaseOrderLineItemId)}
              purchaseOrderLineItem={purchaseOrderLineItem as PurchaseOrderLineItem}
              selectedLineId={selectedLineId}
              setSelectedLineId={setSelectedLineId}
              linkableLines={linkableLines as PurchaseOrderLineItem[]}
              hasLines={hasLines}
              canBeUpdated={canBeUpdated}
            ></LinkInvoiceModalLinkableLines>
          </QueryResult>
        </Modal.Body>
        <LinkInvoiceModalFooter
          purchaseOrderId={Number(purchaseOrderId)}
          purchaseOrderLineItemId={Number(purchaseOrderLineItemId)}
          selectedLineId={Number(selectedLineId)}
          onCloseModal={onCloseModal}
          onLinkLine={onLinkLine}
          hasLines={hasLines}
          canBeUpdated={canBeUpdated}
          updateLineLoading={updateLineLoading}
        ></LinkInvoiceModalFooter>
      </Modal.Panel>
    </Modal>
  )
}
