import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, generatePath, matchPath, useLocation, useNavigate, useParams } from 'react-router-dom'

import BuyListDetailsPanel from '../BuyListDetailsPanel'
import { useGetBuyList } from '../hooks/useGetBuyList'

import { Breadcrumb, Button, MoreOptionsMenu, QueryResult, ThemeWrapper, Tooltip } from '@/modules/shared/components'
import HeaderTabs, { TabItem } from '@/modules/shared/components/header-tabs'
import { BuyListIcon, HomeIcon, InfoIcon, OptionIcon } from '@/modules/shared/icons'
import { Themes } from '@/modules/shared/types'
import { checkNetworkStatus, findActiveRoute } from '@/modules/shared/utils'

export default function BuyListPageHeader() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [showInfo, setShowInfo] = useState(false)

  const { buyListId } = useParams<{ buyListId: string }>()

  const location = useLocation()

  const { buyList, error, networkStatus } = useGetBuyList(buyListId || '')

  const { loading } = checkNetworkStatus(networkStatus)

  const { title } = buyList || {}
  const actionList: TabItem[] = [{ target: 'products', label: t('buyLists.headerTabs.products', 'Products') }]

  const isRedirect = matchPath('/buy-lists/:buyListId', location.pathname)

  useEffect(() => {
    if (isRedirect) {
      navigate(generatePath('/buy-lists/:buyListId/products', { buyListId: String(buyListId) }))
    }
  }, [])

  return (
    <>
      <QueryResult loading={loading} error={error}>
        <div className="mx-auto max-w-[1400px] space-y-5 px-4">
          <Breadcrumb className="rounded-md bg-gray-200 px-3 py-2" data-testid="buy-list-breadcrumb">
            <Breadcrumb.Item icon={HomeIcon} href="/"></Breadcrumb.Item>
            <Breadcrumb.Item href="/buy-lists">{t('breadcrumbs.buyLists', 'Buy Lists')}</Breadcrumb.Item>
            <Breadcrumb.Item href="#">{title}</Breadcrumb.Item>
            <Breadcrumb.Item>{findActiveRoute(actionList, location.pathname)}</Breadcrumb.Item>
          </Breadcrumb>
          <section className="flex flex-col gap-y-3">
            <section className="flex items-center justify-between rounded-md bg-white p-4 shadow-sm">
              <div className="flex items-center gap-4">
                <ThemeWrapper theme={Themes.Primary} className="flex items-center justify-center rounded-md p-2">
                  <BuyListIcon className="h-10 w-10 text-primary" />
                </ThemeWrapper>
                <div className="flex justify-between">
                  <span>
                    <p className="font-bold">{title}</p>
                    <p className="text-sm">
                      {t('buyLists.totalProducts', '{{ quantity }} products', {
                        quantity: buyList?.cataloguedProducts?.edges?.length,
                      })}
                    </p>
                  </span>
                </div>
              </div>
              <div className="flex items-center justify-center gap-x-2">
                <Tooltip content={t('buyList.headerButtons.info', 'Show or hide details')} showArrow={false}>
                  <Button
                    data-testid="show-details-button"
                    className="flex h-11 w-11 items-center justify-center rounded-full bg-gray-200"
                    onClick={() => setShowInfo(!showInfo)}
                  >
                    <InfoIcon className="h-8 w-8 text-gray-600" />
                  </Button>
                </Tooltip>
                <MoreOptionsMenu buyListId={buyListId} showOpenInPPlus buttonTheme="dark">
                  <OptionIcon className="h-8 w-8 text-gray-500" />
                </MoreOptionsMenu>
              </div>
            </section>
            {showInfo && buyListId && <BuyListDetailsPanel buyListId={buyListId} />}
            <HeaderTabs actionList={actionList} />
          </section>
          <Outlet />
        </div>
      </QueryResult>
    </>
  )
}
