import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import NoImage from '@/assets/images/no-image.svg'
import { PricedCataloguedProduct, Supplier } from '@/graphql/purchasing/generated/purchasing_graphql'
import { formatProductItem } from '@/modules/requisitions/utils'
import { Fallback } from '@/modules/shared/components'
import ProductModal from '@/modules/shared/components/product-modal/ProductModal'
import Table from '@/modules/shared/components/table/Table'
import { useMoney } from '@/modules/shared/hooks'

interface TableViewProps {
  products: PricedCataloguedProduct[]
  supplier: Supplier
}

export default function SupplierPublicProductsTableView({ products, supplier }: TableViewProps) {
  const { t } = useTranslation()
  const [activeLineId, setActiveLineId] = useState<number | null>(null)
  const [showProductDetailsModal, setShowProductDetailsModal] = useState(false)
  const { formatMoney } = useMoney()

  const onOpenProductDetailsModal = (activeId: number) => {
    setActiveLineId(activeId)
    setShowProductDetailsModal(true)
  }

  const renderImage = ({ id, image }: PricedCataloguedProduct) => (
    <div
      className="mx-auto h-[60px] w-[60px] cursor-pointer border border-gray-200"
      onClick={() => onOpenProductDetailsModal(Number(id))}
      data-testid="product-image"
    >
      <img src={image || NoImage} alt="product" />
    </div>
  )

  const renderItem = (record: PricedCataloguedProduct) => {
    const { itemDescription, brand, itemSize, itemMeasure, itemPackName, concatenatedSellUnit } = record.product || {}
    return (
      <>
        <p
          className="cursor-pointer break-words text-sm leading-5 text-primary"
          onClick={() => onOpenProductDetailsModal(Number(record?.id))}
        >
          {brand} {itemDescription}
        </p>
        <span className="text-xs">
          {formatProductItem({
            itemSize: itemSize,
            itemMeasure: itemMeasure,
            itemPackName: itemPackName,
            itemSellUnit: concatenatedSellUnit,
          })}
        </span>
      </>
    )
  }

  const renderCategory = ({ product }: PricedCataloguedProduct) => (
    <span>
      <p>{product?.category?.parent?.name}</p>
      <p className="text-xs">{product?.category?.name}</p>
    </span>
  )

  const renderUnitPrice = ({ sellUnitTaxPercentage, sellUnitPrice }: PricedCataloguedProduct) => (
    <span>
      <p className="text-base font-bold leading-tight">
        <Fallback condition={!!sellUnitPrice}>{formatMoney(sellUnitPrice)}</Fallback>
      </p>
      <p className="text-xs text-gray-500">
        <Fallback
          condition={!!sellUnitPrice}
          fallbackValue={t('supplierDirectory.products.noPublicPrice', 'No Public Price')}
        >
          <Fallback
            condition={!!sellUnitTaxPercentage}
            fallbackValue={t('shopPage.productList.productCard.taxFree', 'Tax Free')}
          >
            {t('shopPage.productList.productCard.taxPercentage', 'Plus {{ percent }}% Tax', {
              percent: sellUnitTaxPercentage,
            })}
          </Fallback>
        </Fallback>
      </p>
    </span>
  )

  return (
    <>
      <Table
        dataTestId="supplier-public-products-table"
        dataSource={products}
        keyExtractor={({ id }) => String(id)}
        columns={[
          {
            title: t('supplierDirectory.products.table.image', 'Image'),
            key: 'image',
            headerCellStyles: 'px-2 py-3 text-center',
            cellStyles: 'px-2 py-3',
            minWidth: 60,
            maxWidth: 80,
            render: renderImage,
          },
          {
            title: t('supplierDirectory.products.table.item', 'Item'),
            key: 'item',
            headerCellStyles: 'px-2 py-3 ',
            cellStyles: 'px-2 py-3',
            minWidth: 240,
            render: renderItem,
          },
          {
            title: t('supplierDirectory.products.table.category', 'Category'),
            key: 'category',
            headerCellStyles: 'pl-4 pr-0 py-3 ',
            cellStyles: 'pl-4 pr-0 py-3 ',
            minWidth: 240,
            grow: true,
            render: renderCategory,
          },
          {
            title: t('supplierDirectory.products.table.unitPrice', 'Unit Price'),
            key: 'unitPrice',
            headerCellStyles: 'p-3 text-right',
            cellStyles: 'p-3 text-right',
            minWidth: 120,
            render: renderUnitPrice,
          },
        ]}
      />
      <ProductModal
        showModal={showProductDetailsModal}
        setShowModal={setShowProductDetailsModal}
        line={products.find((product) => product.id === activeLineId) || {}}
        supplier={supplier as Supplier}
      />
    </>
  )
}
