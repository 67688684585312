import { Popover } from '@headlessui/react'
import { parseDate } from '@internationalized/date'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { MutableRefObject, createContext, useEffect, useRef, useState } from 'react'
import { useDatePicker } from 'react-aria'
import { useDatePickerState } from 'react-stately'

import Calendar from './Calendar'

import { CalendarIcon } from '@/modules/shared/icons'
import { Dates } from '@/modules/shared/types'
import { formatDate } from '@/modules/shared/utils'

type CloseCalendarFunc = (focusableElement?: HTMLElement | MutableRefObject<HTMLElement | null> | undefined) => void
export const CalendarContext = createContext<CloseCalendarFunc>(() => {})

// If canBeEmpty, provide a placeholder and isSelected
interface DatePickerProps {
  label: string
  onSelected?: (e: string) => void
  defaultDate?: string
  disabled?: boolean
  canBeEmpty?: boolean
  classNames?: string
  placeholder?: string
  showRight?: boolean
  isSelected?: boolean
  hasSelectedColor?: boolean
}

function DatePicker(props: DatePickerProps) {
  const {
    onSelected,
    label,
    defaultDate,
    disabled,
    canBeEmpty,
    placeholder,
    showRight,
    isSelected,
    hasSelectedColor = true,
    classNames: inputClassNames,
  } = props
  const [selectedDate, setSelectedDate] = useState<string>(formatDate(Dates.ShortYear, dayjs().add(1, 'days')))
  let state = useDatePickerState(props)
  let ref = useRef(null)
  let { fieldProps, calendarProps } = useDatePicker(props, state, ref)
  const makeDate = (year: number, month: number, day: number) =>
    formatDate(Dates.ShortYear, new Date(year, month - 1, day))

  useEffect(() => {
    if (fieldProps.value) {
      const { day, month, year } = fieldProps.value
      setSelectedDate(makeDate(year, month, day))
      onSelected && onSelected(makeDate(year, month, day))
    }
  }, [fieldProps.value])

  useEffect(() => {
    if (defaultDate) {
      setSelectedDate(defaultDate)
    }
  }, [defaultDate])

  return (
    <>
      <Popover className="relative">
        <Popover.Button className="w-full focus:outline-none">
          <div className={classNames('relative rounded-md', { 'shadow-sm': !canBeEmpty })}>
            <input
              type="text"
              data-testid="date-picker-input"
              aria-label={label}
              className={classNames(
                inputClassNames,
                'w-full cursor-pointer rounded-md border border-gray-300 p-3 text-sm focus:outline-none focus:ring-primary',
                {
                  'bg-gray-200': disabled,
                  'border-primary bg-primary/10 text-primary': canBeEmpty && isSelected && hasSelectedColor,
                  'shadow-sm': !canBeEmpty,
                  'w-full lg:w-80': canBeEmpty,
                }
              )}
              placeholder={placeholder}
              value={canBeEmpty && !isSelected ? '' : formatDate(Dates.Short, selectedDate)}
              readOnly
              disabled={disabled}
            />
            <div className="absolute inset-y-0 right-0 flex cursor-pointer items-center pr-3">
              <CalendarIcon
                className={classNames('h-5 w-5 text-gray-400', {
                  'text-primary': canBeEmpty && isSelected && hasSelectedColor,
                })}
                aria-hidden="true"
              />
            </div>
          </div>
        </Popover.Button>
        {!disabled && (
          <Popover.Panel
            data-testid="date-picker-panel"
            className={classNames('absolute z-10 mt-3 w-auto rounded-md bg-white p-4 shadow-md', {
              'left-0': showRight,
              'right-0': !showRight,
            })}
          >
            {({ close }) => (
              <CalendarContext.Provider value={close}>
                <Calendar {...calendarProps} value={canBeEmpty && !isSelected ? undefined : parseDate(selectedDate)} />
              </CalendarContext.Provider>
            )}
          </Popover.Panel>
        )}
      </Popover>
    </>
  )
}

export default DatePicker
