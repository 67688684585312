import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { RequisitionLine } from '@/graphql/purchasing/generated/purchasing_graphql'
import AddOrderInstructionModal from '@/modules/requisitions/components/lines-by-suppliers/AddOrderInstructionModal'
import { useUpdateAdvancedRequisitionLine } from '@/modules/requisitions/hooks'
import ExtraLine from '@/modules/shared/components/table/ExtraLine'
import { Themes } from '@/modules/shared/types'

interface PRLineOrderInstructionProps {
  requisitionLine: RequisitionLine
}

export default function PRLineOrderInstruction({ requisitionLine }: PRLineOrderInstructionProps) {
  const { t } = useTranslation()
  const { orderInstruction } = requisitionLine

  const [showAddOrderInstructionModal, setShowAddOrderInstructionModal] = useState(false)
  const [updateAdvancedRequisitionLine, { loading }] = useUpdateAdvancedRequisitionLine()

  const onRemoveOrderInstruction = (input: RequisitionLine) => {
    const { id, productId, cataloguedProductId } = input
    updateAdvancedRequisitionLine({
      variables: {
        input: {
          id: Number(id),
          productId: Number(productId),
          cataloguedProductId: Number(cataloguedProductId),
          orderInstruction: null,
        },
      },
    })
  }
  return (
    <>
      <ExtraLine
        isResponsive={false}
        theme={Themes.Primary}
        dataTestId="order-instruction-block"
        removeActionTestId="remove-order-instruction-btn"
        title={t('shopPage.cart.summary.table.orderInstruction', 'Order Instruction')}
        value={orderInstruction || ''}
        showRemoveLineAction
        removeLineLoading={loading}
        removeLineTooltipContent={t(
          'shopPage.cart.summary.table.removeOrderInstructionTootipContent',
          'Remove Order Instruction'
        )}
        onLineClick={() => setShowAddOrderInstructionModal(true)}
        onRemoveLine={() => onRemoveOrderInstruction(requisitionLine)}
      />
      <AddOrderInstructionModal
        showModal={showAddOrderInstructionModal}
        setShowModal={setShowAddOrderInstructionModal}
        line={requisitionLine}
      />
    </>
  )
}
