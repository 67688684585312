import { useTranslation } from 'react-i18next'
import { Link, generatePath } from 'react-router-dom'

import { useApproveRequisition } from '@/modules/requisitions/hooks'
import { Button, Tooltip } from '@/modules/shared/components'
import { CheckAltIcon, CloseIcon } from '@/modules/shared/icons'
import { Loading } from '@/modules/shared/icons-special'

interface ApproveOrDeclineHeaderProps {
  requisitionId: number
}

export default function ApproveOrDeclineHeader({ requisitionId }: ApproveOrDeclineHeaderProps) {
  const [approveRequisition, { loading: approveLoading }] = useApproveRequisition()
  const { t } = useTranslation()

  const onApprove = (requisitionId: number) => {
    approveRequisition({
      variables: {
        input: {
          id: requisitionId,
        },
      },
    })
  }

  return (
    <section
      className="flex items-center justify-between rounded-md bg-white p-4 text-sm shadow-sm"
      data-testid="approve-decline-requisition"
    >
      <p>{t('requisition.canApproveOrDecline', 'You can Approve or Decline this Requisition')}</p>
      <div className="flex items-center gap-x-2">
        <Tooltip content={t('requisition.details.tooltipActionApprove', 'Click to Approve')}>
          <Button
            data-testid="approve-button"
            disabled={approveLoading}
            className="flex h-11 w-11 items-center justify-center rounded-full bg-success text-white"
            onClick={() => onApprove && onApprove(requisitionId)}
          >
            {approveLoading ? (
              <Loading className="h-7 w-7 fill-white text-gray-300" />
            ) : (
              <CheckAltIcon className="h-5 w-5" />
            )}
          </Button>
        </Tooltip>
        <Tooltip content={t('requisition.details.tooltipActionDecline', 'Click to Decline')}>
          <Link
            data-testid="decline-button"
            to={generatePath('/requisitions/:id/decline', {
              id: String(requisitionId),
            })}
            className="flex h-11 w-11 items-center justify-center rounded-full bg-error text-white transition hover:brightness-90"
          >
            <CloseIcon className="h-6 w-6" />
          </Link>
        </Tooltip>
      </div>
    </section>
  )
}
