import { graphql } from 'msw'

import { GetBuyListQuery } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseGetBuyList: GetBuyListQuery = {
  currentPurchaser: {
    id: 44926,
    catalogue: {
      id: 182672,
      title: 'test sort',
      cataloguedProducts: {
        edges: [
          {
            node: {
              id: 22675827,
              department: {
                id: 100617,
                name: 'Accounts',
                __typename: 'Purchaser',
              },
              account: {
                accountName: '0060005 - Operating Account - 5',
                id: 96389,
                __typename: 'Account',
              },
              product: {
                brand: '',
                id: 1,
                itemDescription: 'Watermelon',
                itemMeasure: 'kg',
                itemSize: 1,
                itemSellPackName: 'each',
                itemSellQuantity: 1,
                concatenatedSellUnit: 'each of 1',
                prices: {
                  edges: [
                    {
                      node: {
                        supplierName: 'Zeus Wholesale',
                        unitPrice: 12,
                        taxPercentage: 0,
                        __typename: 'Price',
                      },
                      __typename: 'PriceEdge',
                    },
                    {
                      node: {
                        supplierName: 'Local Supermarket',
                        unitPrice: 14,
                        taxPercentage: 0,
                        __typename: 'Price',
                      },
                      __typename: 'PriceEdge',
                    },
                    {
                      node: {
                        supplierName: 'Demo - Shanghai Chunhui Tourist Travel Products',
                        unitPrice: 15,
                        taxPercentage: 0,
                        __typename: 'Price',
                      },
                      __typename: 'PriceEdge',
                    },
                  ],
                  __typename: 'PriceConnection',
                },
                category: {
                  name: 'Fruit',
                  id: 109878,
                  parent: {
                    id: 109460,
                    name: 'Fruit and Vegetables',
                    __typename: 'Category',
                  },
                  __typename: 'Category',
                },
                __typename: 'Product',
              },
              __typename: 'CataloguedProduct',
            },
            __typename: 'CataloguedProductEdge',
          },
        ],
        pageInfo: {
          endCursor: 'MTM',
          hasNextPage: false,
          __typename: 'PageInfo',
        },
        __typename: 'CataloguedProductConnection',
      },
      __typename: 'Catalogue',
    },
    __typename: 'Purchaser',
  },
}

export const GetBuyList = graphql.query<GetBuyListQuery>('GetBuyList', (_, res, ctx) => {
  return res(ctx.data(baseGetBuyList))
})

export default baseGetBuyList
