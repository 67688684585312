import { graphql } from 'msw'

import { GetCurrentLegalEntityQuery } from '@/graphql/purchasing/generated/purchasing_graphql'

export const GetCurrentLegalEntity = graphql.query<GetCurrentLegalEntityQuery>(
  'GetCurrentLegalEntity',
  (_, res, ctx) => {
    return res(
      ctx.data({
        currentLegalEntity: {
          id: 44926,
          name: 'Test Account',
          isASupplier: false,
          isAPurchaser: true,
          currency: 'AUD',
          isInventoryEnabled: true,
          ppnewAccess: true,
          __typename: 'Purchaser',
        },
      })
    )
  }
)
