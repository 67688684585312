import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { GetAllStockLocationsDocument, StockLocation } from '@/graphql/inventory/generated/inventory_graphql'
import { RansackDirection } from '@/graphql/purchasing/generated/purchasing_graphql'
import { useCurrentOrganisation } from '@/modules/purchasing/hooks'
import { ComboboxServer } from '@/modules/shared/components'
import { INVENTORY_GRAPHQL_API } from '@/modules/shared/constants'
import { extractEdges } from '@/modules/shared/utils'

interface SelectInventoryProps {
  onSelectedInventory: (inventoryId: number) => void
}

export default function SelectInventory({ onSelectedInventory }: SelectInventoryProps) {
  const { t } = useTranslation()
  const { currentOrganisation } = useCurrentOrganisation()
  const { data, networkStatus, fetchMore, refetch } = useQuery(GetAllStockLocationsDocument, {
    variables: {
      after: null,
      filter: {},
      first: 5,
      organisationId: Number(currentOrganisation?.id),
      sort: [
        {
          property: 'name',
          direction: RansackDirection.Asc,
        },
      ],
    },
    context: { uri: INVENTORY_GRAPHQL_API },
    notifyOnNetworkStatusChange: true,
  })

  const stockLocations = extractEdges<StockLocation>(data?.legalHolder?.stockLocations)

  const onFetchMoreStockLocations = () => {
    fetchMore({
      variables: {
        after: data?.legalHolder?.stockLocations?.pageInfo.endCursor,
      },
    })
  }

  const onSearchStockLocations = (query: string) => {
    refetch({
      filter: {
        q: [
          {
            property: 'name_cont',
            value: query,
          },
        ],
      },
    })
  }

  return (
    <section className="mt-8">
      <h2 className="text-sm font-semibold">
        {t('purchaseOrders.purchaseOrder.inventoryModal.stockLocation', 'Stock Location')}
      </h2>
      <p className="text-sm text-gray-500">
        {t(
          'purchaseOrders.purchaseOrder.inventoryModal.stockLocationDesc',
          'Select the destination stock location where these items will be received.'
        )}
      </p>
      <div className="mt-4">
        <ComboboxServer
          testId="inventory"
          networkStatus={networkStatus}
          hasMore={data?.legalHolder?.stockLocations?.pageInfo.hasNextPage}
          onFetchMore={onFetchMoreStockLocations}
          placeholder={t(
            'purchaseOrders.purchaseOrder.inventoryModal.stockLocationPlaceholder',
            'Select a stock location'
          )}
          items={stockLocations}
          onDisplay={(stockLocation) => `${stockLocation.name}`}
          onSelected={(stockLocation) => onSelectedInventory(stockLocation.id)}
          onInputChange={onSearchStockLocations}
          keyExtractor={(stockLocation) => `${stockLocation.id}`}
        />
      </div>
    </section>
  )
}
