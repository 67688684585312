import { useMutation, useQuery } from '@apollo/client'
import { useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import {
  Account,
  DeliveryAddress,
  GetPurchaseOrderDocument,
  PurchaseOrder,
  Purchaser,
  Supplier,
  UpdatePurchaseOrderDocument,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { PurchaseOrderStatuses } from '@/modules/purchase-orders/types'
import { formatStateForLocale } from '@/modules/purchase-orders/utils/formatStateForLocale'
import { statusText } from '@/modules/purchase-orders/utils/statusText'
import { DeptAndAccount, SelectAddress, SendPurchaseOrder } from '@/modules/requisitions/pages/create/form-elements'
import SupplierDetailsModal from '@/modules/requisitions/pages/view-cart/summary/SupplierDetailsModal'
import { PurchaseOrderFormInputs } from '@/modules/requisitions/types'
import { PPNewDetailLink, QueryResult, SimpleTableItem } from '@/modules/shared/components'
import FormDate from '@/modules/shared/components/create-form/FormDate'
import FormInput from '@/modules/shared/components/create-form/FormInput'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { useMoney } from '@/modules/shared/hooks'
import { Dates } from '@/modules/shared/types'
import { formatDate } from '@/modules/shared/utils'
import SaveFooter from '@/modules/suppliers/pages/supplier/supplier-details/form-elements/SaveFooter'

export default function Settings() {
  const { t } = useTranslation()
  const { purchaseOrderId } = useParams<{ purchaseOrderId: string }>()
  const { formatMoney } = useMoney()
  const [remount, setRemount] = useState(0)
  const [showSupplierDetailsModal, setShowSupplierDetailsModal] = useState<number | null>(0)
  const [updatePurchaseOrder, { loading: updateLoading }] = useMutation(UpdatePurchaseOrderDocument, {
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
  })

  const { data, loading, error } = useQuery(GetPurchaseOrderDocument, {
    variables: {
      purchaseOrderId: Number(purchaseOrderId),
    },
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
  })

  const {
    purchaseOrderNumber,
    supplier,
    expectedDeliveryDate,
    totalValueExcludingTax,
    totalTaxValue,
    totalValue,
    requisition,
    sendToSupplier,
    deliveryAddress,
    department,
    account,
    status,
    sentDate,
  } = data?.currentPurchaser?.purchaseOrder || {}
  const disabled = status !== PurchaseOrderStatuses.NotSent

  const getDefaultValues = (purchaseOrder: PurchaseOrder) => {
    return {
      accountId: purchaseOrder?.account?.id,
      departmentId: purchaseOrder?.department?.id,
      deliveryAddressId: purchaseOrder?.deliveryAddress?.id,
      reference: purchaseOrder?.requisition?.reference || '',
      expectedDeliveryDate: purchaseOrder?.expectedDeliveryDate,
      sendToSupplier: !!purchaseOrder?.sendToSupplier,
    }
  }

  const formMethods = useForm<PurchaseOrderFormInputs>({
    defaultValues: getDefaultValues(data?.currentPurchaser?.purchaseOrder as PurchaseOrder),
  })

  const onSubmit: SubmitHandler<PurchaseOrderFormInputs> = (data) => {
    // TODO: reference will be updateable in future ticket
    const { accountId, departmentId, deliveryAddressId, expectedDeliveryDate, sendToSupplier } = data
    updatePurchaseOrder({
      variables: {
        input: {
          id: Number(purchaseOrderId),
          accountId,
          departmentId,
          deliveryAddressId,
          expectedDeliveryDate,
          sendToSupplier,
        },
      },
      onCompleted(data) {
        formMethods.reset(getDefaultValues(data?.updatePurchaseOrder?.purchaseOrder as PurchaseOrder))
      },
    })
  }

  const onDiscard = () => {
    formMethods.reset(getDefaultValues(data?.currentPurchaser?.purchaseOrder as PurchaseOrder))
    setRemount(remount + 1)
  }

  return (
    <QueryResult loading={loading} error={error}>
      <section className="flex flex-col-reverse justify-between gap-10 xl:flex-row" data-testid="po-editable-settings">
        <section className="mb-20 xl:w-[840px]">
          <p className="my-3 text-lg font-bold">{t('requisition.general', 'General')}</p>
          <hr />
          {status && disabled && (
            <p className="mt-8 rounded-md bg-gray-200 px-5 py-4 text-sm" data-testid="not-editable-message">
              {t(
                'purchaseOrders.purchaseOrder.settings.notEditableMessage',
                "Changes can't be made to the Settings of a Purchase Order in a {{status}} state.",
                {
                  status: formatStateForLocale(statusText(status, sentDate)),
                }
              )}
            </p>
          )}
          <FormProvider {...formMethods}>
            <form key={remount} onSubmit={formMethods.handleSubmit(onSubmit)}>
              <FormInput
                disabled
                title={t('purchaseOrders.purchaseOrder.settings.form.reference.title', 'Reference')}
                description={t(
                  'purchaseOrders.purchaseOrder.settings.form.reference.desc',
                  'A brief description of the Purchase Order. The Reference will be copied from the Purchase Requisition that this Purchase Order was created from. The Reference will be visible to Suppliers and anyone at your organisation. You can make changes to the Reference here if needed while the Purchase Order is in a Not Sent state.'
                )}
                register="reference"
                placeholder={t('createRequisition.reference.placeholder', 'E.g. Beverage Order')}
              />
              <SendPurchaseOrder
                disabled={disabled}
                defaultValue={!!sendToSupplier}
                label={t(
                  'purchaseOrders.purchaseOrder.settings.form.sendPOElectronically.label',
                  'Send Purchase Order Electronically'
                )}
                title={t(
                  'purchaseOrders.purchaseOrder.settings.form.sendPOElectronically.title',
                  'Send Purchase Order to Supplier?'
                )}
                description={t(
                  'purchaseOrders.purchaseOrder.settings.form.sendPOElectronically.desc',
                  "When sending the Purchase Order, you can uncheck the option below to prevent the Supplier from receiving it electronically. There may be reasons why you don't need the Supplier to receive it, for example you may have phoned ahead and placed your order and you don't wish to send the Supplier a duplicate order."
                )}
              />
              <SelectAddress
                disabled={disabled}
                defaultValue={deliveryAddress as DeliveryAddress}
                title={t('purchaseOrders.purchaseOrder.settings.form.deliveryAddress.title', 'Delivery Address')}
                description={t(
                  'purchaseOrders.purchaseOrder.settings.form.deliveryAddress.desc',
                  'Select a delivery address from the list below, this will be the Delivery Address where the Supplier will deliver the ordered items. You can make changes to the Delivery Address here while the Purchase Order is in a Not Sent state.'
                )}
              />
              <FormDate
                disabled={disabled}
                defaultDate={expectedDeliveryDate}
                title={t('purchaseOrders.purchaseOrder.settings.form.deliveryDate.title', 'Delivery Date')}
                description={t(
                  'purchaseOrders.purchaseOrder.settings.form.deliveryDate.desc',
                  "Please select the date you'd like to take delivery of this order. This will be the date that the Supplier will expected to deliver the ordered items. You can make changes to the Delivery Date here while the Purchase Order is in a Not Sent state."
                )}
                register="expectedDeliveryDate"
              />
              <DeptAndAccount
                disabled={disabled}
                key={`${department}${account}`}
                defaultDepartmentValue={department as Purchaser}
                defaultAccountValue={account as Account}
                title={t(
                  'purchaseOrders.purchaseOrder.settings.form.deptAndAccount.title',
                  'Department and Account Code'
                )}
                description={t(
                  'purchaseOrders.purchaseOrder.settings.form.deptAndAccount.desc',
                  'If applicable, you can set a Department then an Account Code to record this Purchase Order against. Your organisation may use Department and Account Codes for recording expenses. The Department and Account Code will be copied from the Purchase Requisition that this Purchase Order was created from. You can make changes to these fields here if needed while the Purchase Order is in a Not Sent state.'
                )}
              />
              <div className="h-96" />
              {formMethods.formState.isDirty && <SaveFooter onDiscardChanges={onDiscard} loading={updateLoading} />}
            </form>
          </FormProvider>
        </section>
        <section className="xl:w-96">
          <section className="divide-y rounded-md bg-white shadow-sm">
            <h2 className="px-5 py-4 font-bold">
              {t('purchaseOrders.purchaseOrder.settings.purchaseOrderDetails', 'Purchase Order Details')}
            </h2>
            <div className="flex flex-col gap-y-3 p-5 text-sm" data-testid="po-information">
              <SimpleTableItem
                title={t('purchaseOrders.purchaseOrder.settings.poNumber', 'PO Number')}
                value={purchaseOrderNumber}
              />
              <SimpleTableItem
                title={t('purchaseOrders.purchaseOrder.settings.supplier', 'Supplier')}
                value={
                  <span className="cursor-pointer text-primary" onClick={() => setShowSupplierDetailsModal(1)}>
                    {supplier?.name}
                  </span>
                }
              />
              <SimpleTableItem
                title={t('purchaseOrders.purchaseOrder.settings.deliveryDate', 'Delivery Date')}
                value={formatDate(Dates.Medium, expectedDeliveryDate)}
              />
              <SimpleTableItem
                title={t('purchaseOrders.purchaseOrder.settings.totalExTax', 'Total Excluding Tax')}
                value={formatMoney(totalValueExcludingTax)}
              />
              <SimpleTableItem
                title={t('purchaseOrders.purchaseOrder.settings.tax', 'Tax')}
                value={formatMoney(totalTaxValue)}
              />
              <SimpleTableItem
                title={t('purchaseOrders.purchaseOrder.settings.totalInTax', 'Total Including Tax')}
                value={formatMoney(totalValue)}
              />
              <SimpleTableItem
                title={t('purchaseOrders.purchaseOrder.settings.requisition', 'Requisition')}
                value={
                  <PPNewDetailLink openNewTab type="requisition" id={String(requisition?.id)}>
                    {requisition?.requisitionNumber}
                  </PPNewDetailLink>
                }
              />
              <SupplierDetailsModal
                supplier={supplier as Supplier}
                showModal={!!showSupplierDetailsModal}
                setShowModal={setShowSupplierDetailsModal}
              />
            </div>
          </section>
        </section>
      </section>
    </QueryResult>
  )
}
