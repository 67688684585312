import { useQuery } from '@apollo/client'
import { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

import ListView from './approval-list/list-view'
import TableView from './approval-list/table-view'

import EmptyStateImg from '@/assets/images/empty-requisitions-stars.svg'
import { GetRequisitionsAwaitingApprovalDocument, Requisition } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Breadcrumb, QueryResult, ThemeWrapper } from '@/modules/shared/components'
import EmptyState from '@/modules/shared/components/empty-state'
import SearchAndFilter from '@/modules/shared/components/search-input/SearchAndFilter'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { useWindowSize } from '@/modules/shared/hooks'
import { ClockIcon, HomeIcon } from '@/modules/shared/icons'
import { QueryParameter, Themes } from '@/modules/shared/types'
import { extractEdges } from '@/modules/shared/utils'

export default function AwaitingMyApproval() {
  const { t } = useTranslation()
  const { isLargeScreen } = useWindowSize()
  const [searchValue, setSearchValue] = useState('')

  const { loading, error, data } = useQuery(GetRequisitionsAwaitingApprovalDocument, {
    variables: {
      filter: {
        q: [
          {
            property: 'requisitionNumber_or_reference_cont',
            value: searchValue.trim(),
          },
        ],
      },
    },
    fetchPolicy: 'cache-and-network',
    context: { uri: PURCHASING_GRAPHQL_API },
  })

  const approvalList = extractEdges<Requisition>(data?.currentPurchaser?.requisitionsAwaitingMyApproval)

  return (
    <>
      <Helmet>
        <title>{t('requisition.awaitingApprovalPageTitle', 'Awaiting My Approval')}</title>
      </Helmet>

      <main className="mx-auto space-y-5 px-4">
        <Breadcrumb className="overflow-x-auto rounded-md bg-gray-200 px-3 py-2">
          <Breadcrumb.Item icon={HomeIcon} href="/dashboard"></Breadcrumb.Item>
          <Breadcrumb.Item href="/requisitions">{t('breadcrumbs.requisitions', 'Requisitions')}</Breadcrumb.Item>
          <Breadcrumb.Item>{t('breadcrumbs.awaitingMyApproval', 'Awaiting My Approval')}</Breadcrumb.Item>
        </Breadcrumb>
        <section className="flex rounded-md bg-white p-4 shadow-sm " data-testid="awaiting-my-approval-header">
          <div className="flex items-center">
            <ThemeWrapper theme={Themes.Primary} className="flex h-14 w-14 items-center justify-center rounded-md">
              <ClockIcon className="h-10 w-10 " />
            </ThemeWrapper>
            <span className="ml-3">
              <p className="font-bold">
                {t('requisition.numberOfPRsAwaiting', '{{ number }} Requisitions', { number: approvalList.length })}
              </p>
              <p className="text-sm text-gray-500">{t('requisition.awaitingMyApproval', 'Awaiting My Approval')}</p>
            </span>
          </div>
        </section>
        <SearchAndFilter
          testId="awaiting-my-approval-search-input"
          placeholder={t('allRequisitions.searchPlaceholder', 'Search by PR Number or Reference')}
          ariaLabel={t('allRequisitions.ariaLabel', 'Searching for requisitions')}
          onFilter={(filter) => setSearchValue(filter.searchValue)}
          queryParamFilterType={QueryParameter.Status}
        />
        <QueryResult loading={loading} error={error}>
          <section>
            {isLargeScreen ? <TableView approvalList={approvalList} /> : <ListView approvalList={approvalList} />}
            {approvalList.length === 0 && (
              <EmptyState
                img={EmptyStateImg}
                title={t('requisition.allDone', 'All Done!')}
                message={t('requisition.noPRsRemaining', 'There are no Requisitions awaiting your approval.')}
              />
            )}
          </section>
        </QueryResult>
      </main>
    </>
  )
}
