import { Disclosure } from '@headlessui/react'
import classNames from 'classnames'
import { Dispatch, SetStateAction, useState } from 'react'

import { Category } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Checkbox } from '@/modules/shared/components'
import { CheckboxGroup, CheckboxGroupItem } from '@/modules/shared/components/checkbox-group/checkbox-group'
import { ChevronDownIcon } from '@/modules/shared/icons'
import { extractEdges } from '@/modules/shared/utils'

interface CheckboxTreeProps {
  category: Category
  selectedCategories: number[]
  setSelectedCategories: Dispatch<SetStateAction<number[]>>
}

function CheckboxTree(props: CheckboxTreeProps) {
  const subCategories = extractEdges<Category>(props.category?.categories)
  let result: number[] = subCategories
    .map((category) => category.id)
    .filter((id) => props.selectedCategories.includes(id))

  const [checkedList, setCheckedList] = useState<number[]>(result)
  const [indeterminate, setIndeterminate] = useState(
    checkedList.length > 0 && checkedList.length < subCategories.length
  )
  const [checkAll, setCheckAll] = useState(checkedList.length === subCategories.length)

  const onChangeCheckboxGroup = (list: number[], subCategoriesLength: number) => {
    setCheckedList(list)
    setIndeterminate(Boolean(list.length) && list.length < subCategoriesLength)
    setCheckAll(list.length === subCategoriesLength)
    const subCategoryIds = subCategories.map((sub) => sub.id)
    const removedSameCategoryItems = props.selectedCategories.filter((item) => !subCategoryIds.includes(item))
    const concat = removedSameCategoryItems.concat(list)
    const findUnique = concat.filter((item, pos) => concat.indexOf(item) === pos)
    props.setSelectedCategories(findUnique)
  }

  const onCheckAllChange = (value: boolean, checkedList: number[]) => {
    setCheckedList(value ? checkedList : [])
    setIndeterminate(false)
    setCheckAll(value)
    const concat = props.selectedCategories.concat(checkedList)
    const findUnique = concat.filter((item, pos) => concat.indexOf(item) === pos)
    props.setSelectedCategories(value ? findUnique : [])
  }

  return (
    <div className="flex flex-col gap-y-2">
      <Disclosure defaultOpen={false}>
        {({ open }) => (
          <>
            <div className="flex items-center justify-between rounded-md border bg-gray-100 p-2">
              <Checkbox
                aria-label={props.category.name?.toString()}
                isIndeterminate={indeterminate}
                onChange={(value) => {
                  const checkedList = subCategories.map((cat) => cat.id)
                  onCheckAllChange(value, checkedList)
                }}
                isSelected={checkAll}
              >
                {props.category.name}
              </Checkbox>
              <Disclosure.Button className="flex grow justify-end gap-x-2 py-1">
                <div className="flex items-center gap-x-2">
                  {checkedList.length > 0 && (
                    <span className="text-sm text-gray-500">{checkedList.length} Selected</span>
                  )}
                  <ChevronDownIcon
                    className={classNames('h-5 w-5 transition-all duration-300', {
                      'rotate-180 transform': open,
                    })}
                  />
                </div>
              </Disclosure.Button>
            </div>
            <Disclosure.Panel>
              <div className="ml-8 mt-1 rounded-md border bg-gray-100">
                {/* CheckboxGroup and CheckGroupItem needs string, we have to cast original data to match */}
                <CheckboxGroup
                  aria-label={props.category.name}
                  value={checkedList.map((e) => e.toString())}
                  onChange={(list) => {
                    onChangeCheckboxGroup(
                      list.map((e) => Number(e)),
                      subCategories.length
                    )
                  }}
                >
                  {subCategories.map((subCategory, index) => (
                    <div
                      key={subCategory.id}
                      className={classNames('p-2 first:rounded-t-md last:rounded-b-md', { 'border-t': index > 0 })}
                    >
                      <CheckboxGroupItem
                        key={subCategory.id}
                        aria-label={subCategory.name?.toString()}
                        value={subCategory.id.toString()}
                      >
                        {subCategory.name}
                      </CheckboxGroupItem>
                    </div>
                  ))}
                </CheckboxGroup>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  )
}

export default CheckboxTree
