import { useQuery } from '@apollo/client'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { Outlet, generatePath, matchPath, useLocation, useNavigate, useParams } from 'react-router-dom'

import PurchaseOrderStatus from '../../all-purchase-order/PurchaseOrderStatus'
import Details from './Details'

import { GetPurchaseOrderDocument, PurchaseOrder } from '@/graphql/purchasing/generated/purchasing_graphql'
import { useCreateReceivingDocument } from '@/modules/purchase-orders/hooks'
import { statusText } from '@/modules/purchase-orders/utils/statusText'
import PurchaseOrderModal from '@/modules/requisitions/pages/confirmation/requisition-purchase-orders/PurchaseOrderModal'
import RelatedDocumentsModal from '@/modules/requisitions/pages/requisition/page-header/related-documents-modal'
import { Breadcrumb, Button, QueryResult, ThemeWrapper, Tooltip } from '@/modules/shared/components'
import { MoreOptionsMenu } from '@/modules/shared/components'
import HeaderTabs, { TabItem } from '@/modules/shared/components/header-tabs'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { useMoney } from '@/modules/shared/hooks'
import {
  ArrowUpIcon,
  HomeIcon,
  InfoIcon,
  OptionIcon,
  PurchaseOrderIcon,
  TreeDiagramIcon,
  TruckIcon,
} from '@/modules/shared/icons'
import { Themes } from '@/modules/shared/types'
import { findActiveRoute } from '@/modules/shared/utils'

function POPageHeader() {
  const { t } = useTranslation()
  const { formatMoney } = useMoney()
  const navigate = useNavigate()
  const { purchaseOrderId } = useParams<{ purchaseOrderId: string }>()
  const [showInfo, setShowInfo] = useState(false)
  const [isOpen, setIsOpen] = useState<number | null>(null)
  const [showRelatedDocModal, setShowRelatedDocModal] = useState(false)

  const location = useLocation()

  const { data, loading } = useQuery(GetPurchaseOrderDocument, {
    variables: {
      purchaseOrderId: Number(purchaseOrderId),
    },
    context: { uri: PURCHASING_GRAPHQL_API },
  })

  const { onCreateReceivingDocument, createReceivingDocumentLoading, modalProps, InventoryModal } =
    useCreateReceivingDocument()

  const purchaseOrder = data?.currentPurchaser?.purchaseOrder
  const { id, purchaseOrderNumber, totalValue, sentDate, status } = purchaseOrder || {}
  const actionList: TabItem[] = [
    { target: 'summary', label: t('requisitionInformation.summary.title', 'Product Summary') },
    { target: 'settings', label: t('requisitionInformation.setting.title', 'Settings') },
    {
      target: 'comments',
      label: t('requisitionInformation.comment.title', 'Comments'),
      count: Number(data?.currentPurchaser?.purchaseOrder?.commentsCount),
    },
    { target: 'invoices', label: t('invoices.details.invoices', 'Invoices') },
    { target: 'receiving-notes', label: t('receivingNotes.receivingNotes', 'Receiving Notes') },
    { target: 'advanced', label: t('purchaseOrders.purchaseOrder.sendEvents.advanced', 'Advanced') },
    { target: 'audit-log', label: t('auditLog.title', 'Audit Log'), hiddenOnHeaderTabs: true },
  ]
  const sent = Boolean(sentDate)

  const isRedirect = matchPath('/purchase-orders/:purchaseOrderId', location.pathname)

  useEffect(() => {
    if (isRedirect) {
      navigate(generatePath('/purchase-orders/:purchaseOrderId/summary', { purchaseOrderId: String(purchaseOrderId) }))
    }
  }, [])

  return (
    <>
      <Helmet>
        <title>{`${t('general.purchaseOrder', 'Purchase Order')} ${purchaseOrder?.purchaseOrderNumber}`}</title>
      </Helmet>
      <QueryResult loading={loading}>
        <div className="mx-auto max-w-[1400px] space-y-5 px-4">
          <Breadcrumb className="rounded-md bg-gray-200 px-3 py-2" data-testid="purchase-order-breadcrumb">
            <Breadcrumb.Item icon={HomeIcon} href="/dashboard"></Breadcrumb.Item>
            <Breadcrumb.Item href="/purchase-orders">
              {t('breadcrumbs.purchaseOrders', 'Purchase Orders')}
            </Breadcrumb.Item>
            <Breadcrumb.Item href="#">{purchaseOrderNumber}</Breadcrumb.Item>
            <Breadcrumb.Item>{findActiveRoute(actionList, location.pathname)}</Breadcrumb.Item>
          </Breadcrumb>
          <section className="flex flex-col gap-y-2">
            <section className="flex w-full flex-col justify-between rounded-md bg-white shadow-sm sm:flex-row">
              <div className="flex items-center gap-4 p-4">
                <ThemeWrapper theme={Themes.Primary} className="flex items-center justify-center rounded-md p-2">
                  <PurchaseOrderIcon className="h-10 w-10 text-primary" />
                </ThemeWrapper>
                <div className="flex justify-between">
                  <span>
                    <p className="text-xl font-bold" data-testid="cart-value">
                      {formatMoney(totalValue)}
                    </p>
                    <p className="text-sm">{purchaseOrderNumber}</p>
                  </span>
                </div>
              </div>
              <hr className="sm:hidden" />
              <div className="flex items-center justify-between p-4 sm:justify-center">
                <p className="text-sm text-gray-500 sm:hidden">{t('general.actions')}</p>
                <div className="flex items-center justify-center gap-x-2">
                  <Tooltip
                    content={
                      sent
                        ? t(
                            'purchaseOrders.allPurchaseOrders.table.tooltips.POSent',
                            'Purchase Order has been marked as Sent'
                          )
                        : t(
                            'purchaseOrders.allPurchaseOrders.table.tooltips.PONotSent',
                            'Click to send the Purchase Order to the Supplier'
                          )
                    }
                    showArrow={false}
                    delay={100}
                  >
                    <span>
                      <Button
                        className="flex items-center justify-center"
                        onClick={() => setIsOpen(Number(id))}
                        data-testid="purchase-order-send-2"
                      >
                        <ArrowUpIcon
                          className={classNames('h-11 w-11 cursor-pointer rounded-full p-1.5', {
                            'bg-success text-white': sent,
                            'bg-gray-200 text-gray-600': !sent,
                          })}
                        />
                      </Button>
                    </span>
                  </Tooltip>
                  <Tooltip
                    content={t('purchaseOrders.purchaseOrder.details.receiveGoods', 'Receive Goods')}
                    showArrow={false}
                  >
                    <Button
                      data-testid="receive-goods-button"
                      loading={createReceivingDocumentLoading}
                      className="flex h-11 w-11 items-center justify-center rounded-full bg-gray-200"
                      onClick={() => onCreateReceivingDocument(purchaseOrderId)}
                    >
                      <TruckIcon className="h-8 w-8 text-gray-600" />
                    </Button>
                  </Tooltip>
                  <Tooltip
                    content={t('purchaseOrders.purchaseOrder.details.info', 'Show or hide details')}
                    showArrow={false}
                  >
                    <Button
                      data-testid="show-details-button"
                      className="flex h-11 w-11 items-center justify-center rounded-full bg-gray-200"
                      onClick={() => setShowInfo(!showInfo)}
                    >
                      <InfoIcon className="h-8 w-8 text-gray-600" />
                    </Button>
                  </Tooltip>
                  <Tooltip content={t('requisition.relatedDocs.tooltip', 'View Related Documents')} showArrow={false}>
                    <Button
                      data-testid="related-docs-btn"
                      className="flex h-11 w-11 items-center justify-center rounded-full bg-gray-200"
                      onClick={() => setShowRelatedDocModal(true)}
                    >
                      <TreeDiagramIcon className="h-8 w-8 text-gray-600" />
                    </Button>
                  </Tooltip>
                  <MoreOptionsMenu
                    showPrint
                    showAuditLog
                    showOpenInPPlus
                    showHelp
                    purchaseOrderId={id}
                    buttonTheme="dark"
                  >
                    <OptionIcon className="h-8 w-8" />
                  </MoreOptionsMenu>
                </div>
              </div>

              <PurchaseOrderModal
                showModal={isOpen === id}
                setShowModal={setIsOpen}
                purchaseOrder={purchaseOrder as PurchaseOrder}
              />
              <RelatedDocumentsModal
                showModal={showRelatedDocModal}
                setShowModal={setShowRelatedDocModal}
                purchaseOrder={purchaseOrder as PurchaseOrder}
              />
            </section>
            {showInfo && <Details purchaseOrder={purchaseOrder as PurchaseOrder} />}
            <section className=" flex items-center justify-between rounded-md bg-white px-4 py-3 text-sm shadow-sm">
              <p>{t('general.status', 'Status')}</p>
              <PurchaseOrderStatus status={statusText(status, sentDate)} />
            </section>
            <HeaderTabs actionList={actionList.filter((action) => !action.hiddenOnHeaderTabs)} />
          </section>
          <InventoryModal {...modalProps} purchaseOrderId={String(purchaseOrderId)} />
          <Outlet />
        </div>
      </QueryResult>
    </>
  )
}

export default POPageHeader
