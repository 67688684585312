import { useQuery } from '@apollo/client'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, generatePath, useLocation, useNavigate, useParams } from 'react-router-dom'

import RequisitionStatus from '../../all-requisitions/RequisitionStatus'
import ApproveOrDeclineHeader from './ApproveOrDeclineHeader'
import Details from './Details'
import RelatedDocumentsModal from './related-documents-modal'

import { GetRequisitionDocument, Requisition } from '@/graphql/purchasing/generated/purchasing_graphql'
import { useCheckShoppingAvailable } from '@/modules/requisitions/hooks'
import { REQUISITION_STATUS } from '@/modules/requisitions/types'
import { generateShopPath } from '@/modules/requisitions/utils'
import { Breadcrumb, Button, QueryResult, ThemeWrapper, Tooltip } from '@/modules/shared/components'
import { MoreOptionsMenu } from '@/modules/shared/components'
import HeaderTabs, { TabItem } from '@/modules/shared/components/header-tabs'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { useMoney } from '@/modules/shared/hooks'
import { CartIcon, HomeIcon, InfoIcon, OptionIcon, TreeDiagramIcon } from '@/modules/shared/icons'
import SubmitIcon from '@/modules/shared/icons/SubmitIcon'
import { Themes } from '@/modules/shared/types'
import { findActiveRoute } from '@/modules/shared/utils'

function RequisitionPageHeader() {
  const { t } = useTranslation()
  const { formatMoney } = useMoney()
  const [showRelatedDocModal, setShowRelatedDocModal] = useState(false)

  const [showInfo, setShowInfo] = useState(false)

  const navigate = useNavigate()
  const { requisitionId } = useParams<{ requisitionId: string }>()
  const location = useLocation()

  const { data, loading, error } = useQuery(GetRequisitionDocument, {
    variables: { requisitionId: Number(requisitionId) },
    context: { uri: PURCHASING_GRAPHQL_API },
  })
  const { ShoppingNotAvailableModal, modalProps, onShowModal, onCheckShoppingAvailable } = useCheckShoppingAvailable()
  const { state, advanced, awaitingMyApproval, totalValue, commentsCount, requisitionNumber } =
    data?.currentPurchaser?.requisition || {}

  const actionList: TabItem[] = [
    { target: 'summary', label: t('requisitionInformation.summary.title', 'Product Summary') },
    { target: 'settings', label: t('requisitionInformation.setting.title', 'Settings') },
    {
      target: 'comments',
      label: t('requisitionInformation.comment.title', 'Comments'),
      count: Number(commentsCount),
    },
    { target: 'purchase-orders', label: t('requisitionInformation.purchaseOrders.title') },
    { target: 'invoices', label: t('requisitionInformation.invoices.title', 'Invoices') },
    { target: 'attachments', label: t('requisitionInformation.attachments.title') },
    { target: 'audit-log', label: t('auditLog.title'), hiddenOnHeaderTabs: true },
  ]

  const onNavigateToShop = (url: string) => {
    if (!onCheckShoppingAvailable(data?.currentPurchaser?.requisition as Requisition)) {
      onShowModal()
    } else {
      navigate(url)
    }
  }

  return (
    <>
      <QueryResult loading={loading} error={error}>
        <div className="mx-auto max-w-[1400px] space-y-5 px-4">
          <Breadcrumb data-testid="requisition-breadcrumb" className="rounded-md bg-gray-200 px-3 py-2">
            <Breadcrumb.Item icon={HomeIcon} href="/dashboard"></Breadcrumb.Item>
            <Breadcrumb.Item href="/requisitions">{t('breadcrumbs.requisitions', 'Requisitions')}</Breadcrumb.Item>
            <Breadcrumb.Item href="#">{requisitionNumber}</Breadcrumb.Item>
            <Breadcrumb.Item>{findActiveRoute(actionList, location.pathname)}</Breadcrumb.Item>
          </Breadcrumb>
          <section className="flex flex-col gap-y-2">
            <section className="flex w-full flex-col justify-between rounded-md bg-white shadow-sm sm:flex-row">
              <div className="flex items-center gap-4 p-4">
                <ThemeWrapper theme={Themes.Primary} className="flex h-14 w-14 items-center justify-center rounded-md">
                  <CartIcon className="h-10 w-10" />
                </ThemeWrapper>
                <span>
                  <p className="text-xl font-bold" data-testid="cart-value">
                    {formatMoney(totalValue)}
                  </p>
                  <p className="text-sm">{t('requisitionInformation.cart.totalWithTax', 'Total (Tax Inclusive)')}</p>
                </span>
              </div>
              <hr className="sm:hidden" />
              <div className="flex items-center justify-between p-4 sm:justify-center">
                <p className="text-sm text-gray-500 sm:hidden">{t('general.actions', 'Actions')}</p>
                <div className="flex items-center justify-center gap-x-2">
                  {(state === REQUISITION_STATUS.OPEN ||
                    state === REQUISITION_STATUS.DECLINED ||
                    awaitingMyApproval) && (
                    <Tooltip
                      content={t('requisition.details.tooltipActionResume', 'Resume Shopping')}
                      showArrow={false}
                    >
                      <Button
                        data-testid="resume-shopping-btn"
                        className="flex h-11 w-11 items-center justify-center rounded-full bg-gray-200"
                        onClick={() => onNavigateToShop(generateShopPath(requisitionId, advanced))}
                      >
                        <CartIcon className="h-8 w-8 text-gray-600" />
                      </Button>
                    </Tooltip>
                  )}
                  {state === REQUISITION_STATUS.OPEN && (
                    <Tooltip
                      content={t('requisition.details.tooltipActionSubmit', 'Submit for Approval')}
                      showArrow={false}
                    >
                      <Button
                        data-testid="submit-for-approval-btn"
                        className="flex h-11 w-11 items-center justify-center rounded-full bg-gray-200"
                        onClick={() =>
                          onNavigateToShop(
                            generatePath('/requisitions/:id/submit', {
                              id: String(requisitionId),
                            })
                          )
                        }
                      >
                        <SubmitIcon className="h-8 w-8 text-gray-600" />
                      </Button>
                    </Tooltip>
                  )}
                  <Tooltip
                    content={t('purchaseOrders.purchaseOrder.details.info', 'Show or hide details')}
                    showArrow={false}
                  >
                    <Button
                      data-testid="show-details-button"
                      className="flex h-11 w-11 items-center justify-center rounded-full bg-gray-200"
                      onClick={() => setShowInfo(!showInfo)}
                    >
                      <InfoIcon className="h-8 w-8 text-gray-600" />
                    </Button>
                  </Tooltip>
                  <Tooltip content={t('requisition.relatedDocs.tooltip', 'View Related Documents')} showArrow={false}>
                    <Button
                      data-testid="related-docs-btn"
                      className="flex h-11 w-11 items-center justify-center rounded-full bg-gray-200"
                      onClick={() => setShowRelatedDocModal(true)}
                    >
                      <TreeDiagramIcon className="h-8 w-8 text-gray-600" />
                    </Button>
                  </Tooltip>

                  <MoreOptionsMenu
                    showHelp
                    showPrint
                    showOpenInPPlus
                    showAuditLog
                    requisitionId={requisitionId}
                    buttonTheme="dark"
                  >
                    <OptionIcon className="h-8 w-8" />
                  </MoreOptionsMenu>
                </div>
              </div>
            </section>
            {showInfo && <Details requisition={data?.currentPurchaser?.requisition as Requisition} />}
            {data?.currentPurchaser?.requisition?.awaitingMyApproval && (
              <ApproveOrDeclineHeader requisitionId={Number(requisitionId)} />
            )}
            <div
              className="flex items-center justify-between gap-x-2 rounded-md bg-white px-4 py-3 text-sm shadow-sm"
              data-testid="requisition-status"
            >
              <p>{t('general.status', 'Status')}</p>
              <RequisitionStatus state={String(state)} requisitionId={Number(requisitionId)} />
            </div>
            <HeaderTabs actionList={actionList.filter((action) => !action.hiddenOnHeaderTabs)} />
          </section>
          <Outlet context={{ readonly: true }} />
        </div>
        <RelatedDocumentsModal
          showModal={showRelatedDocModal}
          setShowModal={setShowRelatedDocModal}
          requisition={data?.currentPurchaser?.requisition as Requisition}
        />
        <ShoppingNotAvailableModal {...modalProps} />
      </QueryResult>
    </>
  )
}

export default RequisitionPageHeader
