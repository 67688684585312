import classNames from 'classnames'
import { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import MatchPOToInvoiceModal from '../../invoice/page-header/MatchPOToInvoiceModal'

import { Invoice } from '@/graphql/purchasing/generated/purchasing_graphql'
import InvoiceStatus from '@/modules/invoices/pages/all-invoices/InvoiceStatus'
import { InvoiceStatuses } from '@/modules/invoices/types'
import {
  Button,
  ConfirmDialog,
  Fallback,
  MoreOptionsMenu,
  PPNewDetailLink,
  SimpleTileViewItem,
} from '@/modules/shared/components'
import { useMoney } from '@/modules/shared/hooks'
import { MatchIcon, OptionIcon } from '@/modules/shared/icons'
import { Dates, Themes } from '@/modules/shared/types'
import { formatDate } from '@/modules/shared/utils'

interface TileViewProps {
  invoices: Invoice[]
}

function TileView({ invoices }: TileViewProps) {
  const { t } = useTranslation()
  const { formatMoney } = useMoney()
  const [showMatchPOModal, setShowMatchPOModal] = useState(false)
  const [showCannotMatchPOModal, setShowCannotMatchPOModal] = useState(false)
  const [activeInvoiceId, setActiveInvoiceId] = useState<number | null>(null)

  return (
    <>
      {invoices.map((invoice) => {
        const {
          id,
          invoiceNumber,
          reference,
          totalTaxValue,
          totalValue,
          supplier,
          purchaseOrder,
          state,
          invoiceDate,
          department,
          account,
        } = invoice
        const isMatchable = state === InvoiceStatuses.Flagged || state === InvoiceStatuses.Open
        return (
          <div key={id} className="mb-6 rounded-md border bg-white">
            <div className="flex justify-between p-4">
              <div>
                <span className="break-all text-base font-bold">{invoiceNumber}</span>
                <p className="text-sm text-gray-500">{reference || invoiceNumber}</p>
              </div>
              <div>
                <div className="mt-0 text-right">
                  <span className="text-base font-bold">{`${formatMoney(totalValue)}`}</span>
                  <p className="text-sm text-gray-500">
                    {t('requisition.total', 'Total')} ({t('requisition.includingTax', 'Including Tax')})
                  </p>
                </div>
              </div>
            </div>
            <hr />
            <section data-testid="invoice-details" className="p-4 text-sm">
              <SimpleTileViewItem
                title={t('invoices.details.supplier', 'Supplier')}
                value={supplier?.name}
                className="mb-3"
              />
              <SimpleTileViewItem
                className="mb-3"
                purchaseOrder={purchaseOrder}
                title={t('invoices.details.purchaseOrder', 'Purchase Order')}
                value={
                  <>
                    <Fallback
                      condition={!!purchaseOrder}
                      fallbackValue={
                        <Button
                          data-testid="match-po"
                          className={classNames('h-9 w-9 cursor-pointer rounded-full bg-gray-200 p-1.5 text-gray-500', {
                            'opacity-50': !isMatchable,
                          })}
                          onClick={() => {
                            setActiveInvoiceId(id)
                            if (isMatchable) {
                              setShowMatchPOModal(true)
                            } else {
                              setShowCannotMatchPOModal(true)
                            }
                          }}
                        >
                          <MatchIcon className="h-6 w-6" />
                        </Button>
                      }
                    >
                      <PPNewDetailLink type="purchase-order" id={String(purchaseOrder?.id)}>
                        {purchaseOrder?.purchaseOrderNumber}
                      </PPNewDetailLink>
                    </Fallback>
                    <MatchPOToInvoiceModal
                      invoiceId={String(id)}
                      invoiceNumber={invoiceNumber}
                      supplierId={supplier?.id}
                      supplierName={supplier?.name}
                      showModal={showMatchPOModal && activeInvoiceId === id}
                      setShowModal={setShowMatchPOModal}
                    />
                    <ConfirmDialog
                      theme={Themes.Primary}
                      isOpen={showCannotMatchPOModal && activeInvoiceId === id}
                      setIsOpen={setShowCannotMatchPOModal}
                      title={t('invoices.details.cannotMatchPO', 'Cannot Match Purchase Order')}
                      description={t(
                        'invoices.details.cannotMatchPODesc',
                        'A Purchase Order can only be matched to an Invoice when the Invoice is in an Open or Flagged state.'
                      )}
                      primaryButtonLabel={t('general.okayGotIt', 'Okay, Got It')}
                      onPrimaryBtnClick={() => setShowCannotMatchPOModal(false)}
                    />
                  </>
                }
              />
              <SimpleTileViewItem
                className="mb-3"
                title={t('invoices.details.status', 'Status')}
                value={<InvoiceStatus state={state || ''} />}
              />
              <SimpleTileViewItem
                className="mb-3"
                title={t('invoices.details.invoiceDate', 'Invoice Date')}
                value={<Fallback condition={!!invoiceDate}>{formatDate(Dates.Short, invoiceDate)}</Fallback>}
              />
              <SimpleTileViewItem
                className="mb-3"
                title={t('invoices.details.department', 'Department')}
                value={department?.name}
              />
              <SimpleTileViewItem
                className="mb-3"
                title={t('invoices.details.accountCode', 'Account Code')}
                value={account?.accountName}
              />
              <SimpleTileViewItem
                className="mb-3"
                title={t('invoices.details.tax', 'Tax')}
                value={formatMoney(totalTaxValue)}
              />
            </section>

            <hr />
            <div className={classNames('flex w-full items-center justify-between p-4')}>
              <PPNewDetailLink
                type="invoice"
                testId="view-invoice-link-res"
                id={String(id)}
                className="flex h-12 items-center justify-center rounded-md bg-gray-200 p-4 text-center text-sm transition hover:brightness-95"
              >
                {t('invoices.viewInvoice', 'View Invoice')}
              </PPNewDetailLink>
              <MoreOptionsMenu showHelp showOpenInPPlus showInvoiceLink showPrint invoiceId={id}>
                <OptionIcon className="h-10 w-10 text-gray-500" />
              </MoreOptionsMenu>
            </div>
          </div>
        )
      })}
    </>
  )
}

export default memo(TileView)
