import { useTranslation } from 'react-i18next'
import { Link, generatePath } from 'react-router-dom'

import { InvoiceLineItem } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Accordion, TableLineItem } from '@/modules/shared/components'
import { useMoney } from '@/modules/shared/hooks'
import { NewTabIcon } from '@/modules/shared/icons'

interface InvoicesProps {
  invoiceLineItems: InvoiceLineItem[]
}

export default function Invoices({ invoiceLineItems }: InvoicesProps) {
  const { t } = useTranslation()
  const { formatMoney } = useMoney()
  return (
    <section>
      <strong className="text-sm">
        {t('purchaseOrders.purchaseOrder.details.poLineModal.invoices.invoices', 'Invoices')}
      </strong>
      <p className="text-sm text-gray-500">
        {t(
          'purchaseOrders.purchaseOrder.details.poLineModal.invoices.invoicesDesc',
          'Details of the Invoice Lines that have been linked to this Purchase Order line.'
        )}
      </p>
      {invoiceLineItems.length === 0 && (
        <div className="mt-4 border bg-gray-100 py-4">
          <p className="text-center text-sm text-gray-500">
            {t(
              'purchaseOrders.purchaseOrder.details.poLineModal.invoices.noMatchedInvoices',
              'No Matched Invoice Lines Found'
            )}
          </p>
        </div>
      )}
      {invoiceLineItems.length > 0 &&
        invoiceLineItems.map((line) => {
          const { invoice, quantity, unitPrice, taxPercentage, invoicePriceVariance } = line
          return (
            <Accordion
              key={line.id}
              title={invoice?.invoiceNumber || ''}
              buttonClassName="px-3 h-11 mt-4"
              titleClassName="font-bold"
            >
              <div className="mt-4 border" data-testid="po-line-invoices">
                <TableLineItem
                  title={t('purchaseOrders.purchaseOrder.details.poLineModal.invoices.invoice', 'Invoice')}
                  tooltip={t(
                    'purchaseOrders.purchaseOrder.details.poLineModal.invoices.InvoiceTooltip',
                    'The Invoice document that includes the Linked Invoice Line.'
                  )}
                  value={
                    <Link
                      className="flex items-center text-sm text-primary"
                      to={generatePath('/invoices/:invoiceId', {
                        invoiceId: String(invoice?.id),
                      })}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {invoice?.invoiceNumber || ''}
                      <NewTabIcon className="ml-1 h-5 w-5" />
                    </Link>
                  }
                />
                <TableLineItem
                  title={t('purchaseOrders.purchaseOrder.details.poLineModal.invoices.invoicedQty', 'Invoiced Qty')}
                  tooltip={t(
                    'purchaseOrders.purchaseOrder.details.poLineModal.invoices.invoicedQtyTooltip',
                    'The Invoiced quantity that is shown on the Linked Invoice Line.'
                  )}
                  value={quantity}
                />
                <TableLineItem
                  title={t(
                    'purchaseOrders.purchaseOrder.details.poLineModal.invoices.invoicedUnitPrice',
                    'Invoice Unit Price'
                  )}
                  tooltip={t(
                    'purchaseOrders.purchaseOrder.details.poLineModal.invoices.invoicedUnitPriceTooltip',
                    'The tax exclusive Unit Price on the Linked Invoice Line.'
                  )}
                  value={formatMoney(unitPrice)}
                />
                <TableLineItem
                  title={t(
                    'purchaseOrders.purchaseOrder.details.poLineModal.invoices.invoicedUnitTaxPercentage',
                    'Invoice Tax Percentage'
                  )}
                  tooltip={t(
                    'purchaseOrders.purchaseOrder.details.poLineModal.invoices.invoicedUnitTaxPercentageTooltip',
                    'The Tax Percentage on the Linked Invoice line that will be added to the Invoice Unit Price.'
                  )}
                  value={`${taxPercentage || 0}%`}
                />
                <TableLineItem
                  title={t(
                    'purchaseOrders.purchaseOrder.details.poLineModal.invoices.invoicedPricedVariance',
                    'Invoice Price Variance'
                  )}
                  tooltip={t(
                    'purchaseOrders.purchaseOrder.details.poLineModal.invoices.invoicedPricedVarianceTooltip',
                    'This is the Invoice Unit Price minus the Purchase Order Unit Price. It shows the increase if any, of the Invoice Unit Price relative to the Purchase Order Unit price.'
                  )}
                  value={formatMoney(invoicePriceVariance)}
                />
              </div>
            </Accordion>
          )
        })}
    </section>
  )
}
