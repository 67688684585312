import { useQuery } from '@apollo/client'
import { Fragment } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { StringParam, useQueryParam } from 'use-query-params'

import TableView from './table-view'
import TileView from './tile-view'

import UsersEmptyState from '@/assets/images/empty-users.svg'
import { GetAllUsersDocument, RansackDirection, User } from '@/graphql/access/generated/access_graphql'
import { Spinner } from '@/modules/requisitions/components'
import { Breadcrumb, QueryResult } from '@/modules/shared/components'
import { TitleBar } from '@/modules/shared/components'
import SearchAndFilter from '@/modules/shared/components/search-input/SearchAndFilter'
import { useWindowSize } from '@/modules/shared/hooks'
import { HomeIcon, UserIcon } from '@/modules/shared/icons'
import { QueryParameter } from '@/modules/shared/types'
import { checkNetworkStatus, extractEdges } from '@/modules/shared/utils'

function AllUsers() {
  const { t } = useTranslation()
  const { isLargeScreen } = useWindowSize()

  const [searchTermURLParam] = useQueryParam(QueryParameter.Search, StringParam)
  // TODO: uncomment this when we're able to filter users by status
  // const [filterURLParam] = useQueryParam('deleted', withDefault(StringParam, 'all'))

  const { data, networkStatus, error, fetchMore } = useQuery(GetAllUsersDocument, {
    variables: {
      filter: {
        q: [
          {
            property: 'fullName_cont',
            value: searchTermURLParam || '',
          },
          // TODO: uncomment this when we're able to filter users by status
          // {
          //   property: 'isDeleted_eq',
          //   value: filterURLParam === 'all' ? '' : filterURLParam,
          // },
        ],
      },
      after: null,
      sort: [
        {
          direction: RansackDirection.Asc,
          property: 'fullName',
        },
      ],
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  })

  // TODO: uncomment this when we're able to filter users by status
  // const filterTabs: FilterTabItem[] = [
  //   {
  //     key: t('systemSettings.allUsers', 'All Users'),
  //     filterValue: 'all',
  //   },
  //   {
  //     key: t('systemSettings.userStatus.current', 'Current'),
  //     filterValue: 'false',
  //   },
  //   {
  //     key: t('systemSettings.userStatus.deleted', 'Deleted'),
  //     filterValue: 'true',
  //   },
  // ]

  const { setVariablesLoading, loading } = checkNetworkStatus(networkStatus)

  const users = extractEdges<User>(data?.currentOrganisationUnit?.users)

  const onFetchMoreUsers = () => {
    fetchMore({
      variables: {
        after: data?.currentOrganisationUnit?.users?.pageInfo.endCursor,
      },
    })
  }

  return (
    <Fragment>
      <Helmet>
        <title>{t('systemSettings.viewAllUsers', 'View All Users')}</title>
      </Helmet>
      <main className="mx-auto space-y-5 px-4">
        <Breadcrumb className="overflow-x-auto rounded-md bg-gray-200 px-3 py-2">
          <Breadcrumb.Item icon={HomeIcon} href="/dashboard"></Breadcrumb.Item>
          <Breadcrumb.Item href="#">{t('systemSettings.systemSettings', 'System Settings')}</Breadcrumb.Item>
          <Breadcrumb.Item href="/users">{t('systemSettings.users', 'Users')}</Breadcrumb.Item>
          {/* // TODO: uncomment this when we're able to filter users by status */}
          {/* <Breadcrumb.Item>{filterTabs.find((e) => e.filterValue === filterURLParam)?.key}</Breadcrumb.Item> */}
        </Breadcrumb>
        <section className="space-y-2">
          <TitleBar
            icon={<UserIcon className="h-10 w-10 " />}
            title={t('systemSettings.allUsers', 'All Users')}
            subtitle={t('systemSettings.viewAllUsers', 'View All Users')}
          />
          {/* // TODO: uncomment this when we're able to filter users by status */}
          {/* <FilterTabs
            tabs={filterTabs}
            onSelectedFilter={(filterValue) => setFilterURLParam(filterValue === 'all' ? null : filterValue)}
            currentFilterValue={filterURLParam}
          /> */}
        </section>
        <SearchAndFilter
          testId="users-search-input"
          placeholder={t('systemSettings.searchUsersPlaceholder', 'Search Users')}
          ariaLabel={t('systemSettings.searchUsersPlaceholder', 'Search Users')}
          queryParamFilterType={QueryParameter.Status}
        />
        <QueryResult loading={loading || setVariablesLoading} error={error}>
          <InfiniteScroll
            dataLength={users.length}
            next={onFetchMoreUsers}
            hasMore={!!data?.currentOrganisationUnit?.users?.pageInfo.hasNextPage}
            loader={<Spinner className="mt-5 h-14 md:w-16" />}
          >
            {isLargeScreen ? <TableView users={users} /> : <TileView users={users} />}
          </InfiniteScroll>
          {!setVariablesLoading && users.length === 0 && (
            <div className="mb-3 flex flex-col items-center justify-center">
              <img src={UsersEmptyState} className="h-64 md:h-72" alt="" />
              <div className="text-center">
                <p className="text-xs text-gray-500 md:text-sm">
                  {t('systemSettings.usersEmptyState', 'There are no users that match your search.')}
                </p>
              </div>
            </div>
          )}
        </QueryResult>
      </main>
    </Fragment>
  )
}

export default AllUsers
