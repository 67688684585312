import { Menu, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { Dispatch, Fragment, PropsWithChildren, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@/modules/shared/components'
import { NoteIcon, SettingsIcon, TrashIcon } from '@/modules/shared/icons'

interface MoreOptionMenuProps extends PropsWithChildren {
  setActiveLineOrderInstructionIndex: Dispatch<SetStateAction<boolean>>
  setActiveLineDeptAndAccountIndex: Dispatch<SetStateAction<boolean>>
  onRemoveRequisitionLine: Dispatch<SetStateAction<number | null>>
  lineIndex: number
}

function LinesBySuppliersMoreOptionsMenu(props: MoreOptionMenuProps) {
  const {
    children,
    setActiveLineOrderInstructionIndex,
    setActiveLineDeptAndAccountIndex,
    onRemoveRequisitionLine,
    lineIndex,
  } = props
  const { t } = useTranslation()

  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button data-testid="product-summary-options">{children}</Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className="absolute right-0 z-10 mt-2 w-auto min-w-[240px] origin-top-right divide-y divide-gray-100 rounded-md border bg-white shadow-lg focus:outline-none"
          data-testid="product-summary-options-menu"
        >
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <Button
                  className={classNames('flex w-full items-center px-4 py-2 text-left text-sm text-gray-700', {
                    'bg-gray-100 text-gray-900': active,
                  })}
                  data-testid="add-order-instruction-btn-res"
                  onClick={() => setActiveLineOrderInstructionIndex(true)}
                >
                  <NoteIcon className="mr-2 h-5 w-5" />
                  {t('shopPage.cart.summary.table.orderInstructionModalTitle', 'Add Order Instruction')}
                </Button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Button
                  className={classNames('flex w-full items-center px-4 py-2 text-left text-sm text-gray-700', {
                    'bg-gray-100 text-gray-900': active,
                  })}
                  data-testid="add-dept-account-btn-res"
                  onClick={() => setActiveLineDeptAndAccountIndex(true)}
                >
                  <SettingsIcon className="mr-2 h-5 w-5" />
                  {t('shopPage.cart.summary.table.accountCode', 'Add Account Code')}
                </Button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Button
                  data-testid="remove-from-requisition-btn-res"
                  className={classNames(
                    'flex w-full items-center px-4 py-2 text-left text-sm text-gray-700 transition',
                    {
                      'bg-gray-100 text-gray-900': active,
                    }
                  )}
                  onClick={() => onRemoveRequisitionLine(lineIndex)}
                >
                  <TrashIcon className="mr-2 h-5 w-5" />
                  {t('shopPage.cart.summary.table.removeFrom', 'Remove from Requisition')}
                </Button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default LinesBySuppliersMoreOptionsMenu
