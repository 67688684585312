import { useTranslation } from 'react-i18next'
import { Link, generatePath } from 'react-router-dom'

import { Invoice } from '@/graphql/purchasing/generated/purchasing_graphql'
import InvoiceStatus from '@/modules/invoices/pages/all-invoices/InvoiceStatus'
import { Fallback, MoreOptionsMenu } from '@/modules/shared/components'
import { useMoney } from '@/modules/shared/hooks'
import { OptionIcon } from '@/modules/shared/icons'
import { Dates } from '@/modules/shared/types'
import { formatDate } from '@/modules/shared/utils'

interface InvoiceRowItemProps {
  invoice: Invoice
}

export default function InvoiceRowItem(props: InvoiceRowItemProps) {
  const { invoice } = props
  const {
    invoiceNumber,
    id,
    purchaseOrder,
    reference,
    account,
    department,
    state,
    invoiceDate,
    totalTaxValue,
    totalValue,
  } = invoice
  const { t } = useTranslation()
  const { formatMoney } = useMoney()
  return (
    <tr className="border-b bg-white">
      <td className="w-[190px] px-4 py-3">
        <Link
          to={generatePath('/invoices/:invoiceId/summary', {
            invoiceId: String(id),
          })}
          className="text-primary"
        >
          {invoiceNumber}
        </Link>
        <p className="break-all text-xs">{reference}</p>
      </td>
      <td className="w-[190px] whitespace-nowrap px-4 py-3">
        <Link
          to={generatePath('/purchase-orders/:purchaseOrderId/summary', {
            purchaseOrderId: String(purchaseOrder?.id),
          })}
          className="text-primary"
        >
          {purchaseOrder?.purchaseOrderNumber}
        </Link>
        <p className="break-words text-xs">{purchaseOrder?.requisition?.reference}</p>
      </td>
      <td className="whitespace-nowrap px-4 py-3">
        <span className="break-words">
          <Fallback condition={!!department || !!account}>
            <p>{department?.name}</p>
            <p className="text-xs">{account?.accountName}</p>
          </Fallback>
        </span>
      </td>
      <td className="w-44 whitespace-nowrap px-2 py-3 text-center">{state && <InvoiceStatus state={state} />}</td>
      <td className="w-44 whitespace-nowrap px-2 py-3 text-center">
        <p>
          <Fallback condition={!!invoiceDate}>{formatDate(Dates.Short, invoiceDate)}</Fallback>
        </p>
      </td>
      <td className=" w-28 whitespace-nowrap py-3 text-right">
        <p className="text-base font-bold">{formatMoney(totalTaxValue)}</p>
        <p className="text-xs text-gray-600">{t('invoices.details.tax', 'Tax')}</p>
      </td>
      <td className="w-32 whitespace-nowrap px-4 py-3 text-right">
        <p className="text-base font-bold">{formatMoney(totalValue)}</p>
        <p className="text-xs text-gray-600">{t('invoices.details.taxInclusive', 'Tax Inclusive')}</p>
      </td>
      <td className="w-16 whitespace-nowrap py-3 text-center">
        <MoreOptionsMenu showHelp showOpenInPPlus showInvoiceLink showPrint invoiceId={id}>
          <OptionIcon className="h-10 w-10 text-gray-500" />
        </MoreOptionsMenu>
      </td>
    </tr>
  )
}
