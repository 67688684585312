import { useQuery } from '@apollo/client'
import { createContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import TableView from './table-view'

import {
  GetRequisitionsDraftDocument,
  RansackDirection,
  Requisition,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { useSession } from '@/modules/access/hooks'
import { QueryResult, Tooltip } from '@/modules/shared/components'
import { PURCHASING_GRAPHQL_API, SOURCE_APPLICATION } from '@/modules/shared/constants'
import { CartIcon, QuestionMarkIcon } from '@/modules/shared/icons'
import { extractEdges } from '@/modules/shared/utils'

export const RefetchContext = createContext<null | VoidFunction>(null)

export default function DraftRequisitions() {
  const { t } = useTranslation()

  const { currentUser } = useSession()

  const { data, loading, error, refetch } = useQuery(GetRequisitionsDraftDocument, {
    fetchPolicy: 'cache-and-network',
    variables: {
      first: 5,
      filter: {
        q: [
          { property: 'workflow_state_eq', value: 'open' },
          { property: 'requestor_id_eq', value: currentUser?.id },
          { property: 'source_application_eq', value: SOURCE_APPLICATION },
        ],
      },
      sort: [
        {
          property: 'locationName',
          direction: RansackDirection.Desc,
        },
      ],
    },
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
  })

  const onRefetch = () => {
    refetch()
  }

  const draftList = extractEdges<Requisition>(data?.currentPurchaser?.requisitions)

  return (
    <>
      <div className="flex flex-col justify-center space-y-5 pb-2">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <CartIcon className="h-9 w-9" />
            <p className="ml-3 text-sm" data-testid="dashboard-draft-requisitions">
              {t('dashboard.draftRequisitions.myDrafts', 'My Draft Requisitions')}
              <Tooltip
                content={t(
                  'dashboard.draftRequisitions.tooltip',
                  'Drafts are Requisitions that you saved and closed and are yet to be submitted. You can resume shopping these at anytime.'
                )}
              >
                <span className="ml-1">
                  <QuestionMarkIcon className="inline w-5" />
                </span>
              </Tooltip>
            </p>
          </div>
          <Link
            to={'/requisitions/my-drafts'}
            className="hidden rounded-md bg-gray-200 px-5 py-3 text-center text-sm transition duration-200 hover:bg-gray-300 sm:block"
          >
            <span>{t('dashboard.draftRequisitions.manage', 'Manage My Drafts')}</span>
          </Link>
        </div>
        <RefetchContext.Provider value={onRefetch}>
          <QueryResult loading={loading} error={error}>
            <TableView draftList={draftList} />
            <Link
              to={'/requisitions/my-drafts'}
              className="block w-full rounded-md bg-gray-200 px-3 py-4 text-center text-sm hover:bg-gray-300 sm:hidden"
            >
              <span>{t('dashboard.draftRequisitions.manage')}</span>
            </Link>
          </QueryResult>
        </RefetchContext.Provider>
      </div>
    </>
  )
}
