import { graphql } from 'msw'

import { GetPricedCataloguesQuery } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseGetPricedCataloguesObject: GetPricedCataloguesQuery = {
  currentPurchaser: {
    id: 44926,
    pricedCatalogues: {
      edges: [
        {
          node: {
            id: 137123,
            title: 'Self Managed - New Yen Yen',
            supplier: {
              id: 26627,
              name: 'Bezzera',
              __typename: 'Supplier',
            },
            owner: {
              id: 44926,
              name: 'Test Account',
              __typename: 'Purchaser',
            },
            updatedAt: '2019-07-24T11:39:10+10:00',
            __typename: 'PricedCatalogue',
          },
          __typename: 'PricedCatalogueEdge',
        },
        {
          node: {
            id: 137283,
            title: 'Self Managed - AAPC',
            supplier: {
              id: 67087,
              name: 'AAPC Limited',
              __typename: 'Supplier',
            },
            owner: {
              id: 44926,
              name: 'Test Account',
              __typename: 'Purchaser',
            },
            updatedAt: '2022-01-27T09:15:05+11:00',
            __typename: 'PricedCatalogue',
          },
          __typename: 'PricedCatalogueEdge',
        },
        {
          node: {
            id: 171659,
            title: 'Self Managed - Beer',
            supplier: {
              id: 568,
              name: 'Coca Cola Amatil P/L',
              __typename: 'Supplier',
            },
            owner: {
              id: 44926,
              name: 'Test Account',
              __typename: 'Purchaser',
            },
            updatedAt: '2021-06-21T12:45:07+10:00',
            __typename: 'PricedCatalogue',
          },
          __typename: 'PricedCatalogueEdge',
        },
      ],
      pageInfo: {
        endCursor: 'Nw',
        hasNextPage: false,
        __typename: 'PageInfo',
      },
    },
    __typename: 'Purchaser',
  },
}

export const GetPricedCatalogues = graphql.query<GetPricedCataloguesQuery>('GetPricedCatalogues', (_, res, ctx) => {
  return res(ctx.data(baseGetPricedCataloguesObject))
})

export default baseGetPricedCataloguesObject
