import { useQuery } from '@apollo/client'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import TableView from './table-view'
import TileView from './tile-view'

import {
  GetPurchaseOrderDocument,
  PurchaseOrder,
  PurchaseOrderLineItem,
  Supplier,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { useCreateReceivingDocument } from '@/modules/purchase-orders/hooks'
import SupplierDetailsModal from '@/modules/requisitions/pages/view-cart/summary/SupplierDetailsModal'
import { Button, QueryResult } from '@/modules/shared/components'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { useWindowSize } from '@/modules/shared/hooks'
import { useMoney } from '@/modules/shared/hooks'
import { ControlBarIcon, TruckIcon } from '@/modules/shared/icons'
import { extractEdges } from '@/modules/shared/utils'

function Summary() {
  const { formatMoney } = useMoney()
  const { t } = useTranslation()
  const { isLargeScreen } = useWindowSize()

  const [activeSupplierDetailsModal, setActiveSupplierDetailsModal] = useState<number | null>(null)

  const { onCreateReceivingDocument, createReceivingDocumentLoading, modalProps, InventoryModal } =
    useCreateReceivingDocument()

  const { purchaseOrderId } = useParams<{ purchaseOrderId: string }>()

  const { loading, error, data } = useQuery(GetPurchaseOrderDocument, {
    variables: {
      purchaseOrderId: Number(purchaseOrderId),
    },
    context: { uri: PURCHASING_GRAPHQL_API },
  })

  const {
    purchaseOrderNumber,
    supplier,
    deliveryChargeExTax,
    deliveryChargeTax,
    totalValueExcludingTax,
    totalTaxValue,
    totalValue,
    lines,
  } = data?.currentPurchaser?.purchaseOrder || {}
  const POLines = extractEdges<PurchaseOrderLineItem>(lines)

  return (
    <>
      <QueryResult loading={loading} error={error}>
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <ControlBarIcon className=" h-10 w-10" />
            <p className="ml-3 text-sm">
              {purchaseOrderNumber} {t('general.productSummary', 'Product Summary')}
            </p>
          </div>
          <Button
            loading={createReceivingDocumentLoading}
            data-testid="receive-goods-btn"
            className="flex h-10 items-center gap-x-2 rounded-md bg-gray-200 px-4 text-sm"
            onClick={() => onCreateReceivingDocument(purchaseOrderId)}
          >
            {t('receivingNotes.receiveGoods', 'Receive Goods')}
            <TruckIcon className="h-6 w-6" />
          </Button>
        </div>
        <section className="mt-4 flex flex-col gap-y-12" data-testid="purchase-order-info">
          <div className="flex flex-col gap-y-3">
            <div className="flex justify-between rounded-md bg-white px-5 py-3 shadow-sm">
              <strong className="text-sm">{supplier?.name}</strong>
              <p
                onClick={() => setActiveSupplierDetailsModal(Number(supplier?.id))}
                className="cursor-pointer text-sm text-primary"
                data-testid="supplier-details-button"
              >
                {t('shopPage.cart.summary.details.button', 'View Supplier Details')}
              </p>
            </div>
            {isLargeScreen ? (
              <TableView
                poLines={POLines}
                supplier={supplier as Supplier}
                purchaseOrder={data?.currentPurchaser?.purchaseOrder as PurchaseOrder}
              />
            ) : (
              <div className="flex flex-col gap-y-3 text-sm">
                {POLines.map((line) => (
                  <TileView
                    line={line}
                    supplier={supplier as Supplier}
                    key={line.id}
                    purchaseOrder={data?.currentPurchaser?.purchaseOrder as PurchaseOrder}
                  />
                ))}
              </div>
            )}
            <div
              className="flex flex-col gap-3 rounded-md bg-white py-3 text-sm shadow-sm"
              data-testid="purchase-order-payment"
            >
              <div className="flex justify-between px-5">
                <span className="text-gray-500">
                  {t('purchaseOrders.purchaseOrder.deliveryFees', 'Delivery Fees (Tax Exclusive)')}
                </span>
                <span>{`${formatMoney(deliveryChargeExTax)}`}</span>
              </div>
              <div className="flex justify-between px-5">
                <span className="text-gray-500">
                  {t('purchaseOrders.purchaseOrder.deliveryFeesTax', 'Delivery Fees Tax')}
                </span>
                <span>{`${formatMoney(deliveryChargeTax)}`}</span>
              </div>
              <div className="flex justify-between px-5">
                <span className="text-gray-500">
                  {t('purchaseOrders.purchaseOrder.subTotal', 'Total (Tax Exclusive)')}
                </span>
                <span>{`${formatMoney(totalValueExcludingTax)}`}</span>
              </div>
              <div className="flex justify-between px-5">
                <span className="text-gray-500">{t('purchaseOrders.purchaseOrder.totalTax', 'Total Tax')}</span>
                <span>{`${formatMoney(totalTaxValue)}`}</span>
              </div>
              <hr />
              <div className="flex justify-between px-5">
                <strong>{t('purchaseOrders.purchaseOrder.total', 'Order Total (Tax Inclusive)')}</strong>
                <strong>{`${formatMoney(totalValue)}`}</strong>
              </div>
            </div>
            <SupplierDetailsModal
              supplier={supplier as Supplier}
              showModal={activeSupplierDetailsModal === supplier?.id}
              setShowModal={setActiveSupplierDetailsModal}
            />
          </div>
        </section>
        <InventoryModal {...modalProps} purchaseOrderId={String(purchaseOrderId)} />
      </QueryResult>
    </>
  )
}

export default Summary
