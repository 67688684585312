import { useMutation, useQuery } from '@apollo/client'
import { useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import PriceAndTax from './PriceAndTax'
import ProductPreview from './ProductPreview'

import NoImage from '@/assets/images/no-image.svg'
import {
  GetPricedCatalogueProductDocument,
  PricedCataloguedProduct,
  Product,
  UpdatePricedCataloguedProductDocument,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { UpdateProductFormInputs } from '@/modules/catalogs/types'
import { QueryResult } from '@/modules/shared/components'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import SaveFooter from '@/modules/suppliers/pages/supplier/supplier-details/form-elements/SaveFooter'

export default function SelfManagedProductDetails() {
  const { pricedCatalogueId, productId } = useParams<{ pricedCatalogueId: string; productId: string }>()
  const [remount, setRemount] = useState(0)

  const { data, loading, error } = useQuery(GetPricedCatalogueProductDocument, {
    variables: {
      pricedCatalogueId: Number(pricedCatalogueId),
      pricedCataloguedProductId: Number(productId),
    },
    context: { uri: PURCHASING_GRAPHQL_API },
  })

  const [updatePricedCatalogue, { loading: updateLoading }] = useMutation(UpdatePricedCataloguedProductDocument, {
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
  })

  const { pricedCataloguedProduct } = data?.currentPurchaser?.pricedCatalogue || {}

  const { id: cataloguedProductId, product, image } = pricedCataloguedProduct || {}

  const getDefaultValues = (product: PricedCataloguedProduct) => {
    return {
      sellUnitPrice: Number(product?.sellUnitPrice),
      sellUnitTaxPercentage: product?.sellUnitTaxPercentage !== null ? Number(product?.sellUnitTaxPercentage) : null,
    }
  }

  const formMethods = useForm<UpdateProductFormInputs>({
    defaultValues: getDefaultValues(pricedCataloguedProduct as PricedCataloguedProduct),
  })

  const onSubmit: SubmitHandler<UpdateProductFormInputs> = (data) => {
    updatePricedCatalogue({
      variables: {
        input: {
          id: Number(cataloguedProductId),
          sellUnitPrice: Number(data.sellUnitPrice),
          sellUnitTaxPercentage: data.sellUnitTaxPercentage !== null ? Number(data.sellUnitTaxPercentage) : null,
        },
      },
      onCompleted(data) {
        formMethods.reset(
          getDefaultValues(data?.updatePricedCataloguedProduct?.pricedCataloguedProduct as PricedCataloguedProduct)
        )
      },
    })
  }

  const onDiscard = () => {
    formMethods.reset(getDefaultValues(pricedCataloguedProduct as PricedCataloguedProduct))
    setRemount(remount + 1)
  }

  return (
    <QueryResult loading={loading} error={error}>
      <div className="mt-5 flex flex-col justify-between gap-10 xl:flex-row">
        <FormProvider {...formMethods}>
          <form key={remount} onSubmit={formMethods.handleSubmit(onSubmit)}>
            <section className="flex flex-col gap-y-5 xl:w-[840px]">
              <ProductPreview
                product={product as Product}
                pricedCataloguedProduct={pricedCataloguedProduct as PricedCataloguedProduct}
              />
              <PriceAndTax pricedCataloguedProduct={pricedCataloguedProduct as PricedCataloguedProduct} />
              {formMethods.formState.isValid && formMethods.formState.isDirty && (
                <SaveFooter loading={updateLoading} onDiscardChanges={onDiscard} />
              )}
            </section>
          </form>
        </FormProvider>
        <section className="w-full sm:w-96">
          <img className="w-full rounded-md border" src={image || NoImage} alt="product" />
        </section>
      </div>
    </QueryResult>
  )
}
