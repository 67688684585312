import { Transition } from '@headlessui/react'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'

import BestBuy from './BestBuy'
import RequisitionLinesListView from './list-view/RequisitionLinesListView'
import TileView from './tile-view'

import { RequisitionLine } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Spinner } from '@/modules/requisitions/components'
import { ProductListEmptyState } from '@/modules/requisitions/components'
import { PRODUCT_VIEW } from '@/modules/requisitions/types'
import { Button } from '@/modules/shared/components'
import AdvancedSearch from '@/modules/shared/components/advanced-search/AdvancedSearch'
import SearchAndFilter, { OnFilter } from '@/modules/shared/components/search-input/SearchAndFilter'
import { useWindowSize } from '@/modules/shared/hooks'
import { ControlBarIcon } from '@/modules/shared/icons'
import SearchContainer from '@/modules/shared/search-container'
import { Breakpoints } from '@/modules/shared/types'

interface ProductListProps {
  prNumber?: string
  prReference?: string
  possibleRequisitionLines: RequisitionLine[]
  lines: RequisitionLine[]
  onFetchMoreProduct: () => void
  searchProductLoading?: boolean
  hasMoreProduct: boolean
  onFilter: (filter: OnFilter) => void
}

function ProductList({
  possibleRequisitionLines,
  lines,
  onFetchMoreProduct,
  searchProductLoading,
  hasMoreProduct,
  prNumber,
  prReference,
  onFilter,
}: ProductListProps) {
  const { t } = useTranslation()
  const { width, sm, twoXL } = useWindowSize()
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false)

  const [productView, setProductView] = useState<PRODUCT_VIEW>(PRODUCT_VIEW.TILE_VIEW)

  useEffect(() => {
    if (width <= Breakpoints.LG && productView === PRODUCT_VIEW.LIST_VIEW) {
      setProductView(PRODUCT_VIEW.TILE_VIEW)
    }
  }, [width])

  const searchFilterPlaceholder = () => {
    if (sm) {
      return t('shopPage.productList.searchPlaceholder1', 'Search by Product Name, Brand')
    } else {
      // if screen size is less than Breakpoints.SM
      return t('shopPage.productList.searchPlaceholder2', 'Search')
    }
  }

  return (
    <>
      <div data-testid="requisition-info" className="flex items-center justify-between">
        <div className="hidden items-center sm:flex">
          <ControlBarIcon className=" h-10 w-10" />
          <p className="ml-3 text-sm">
            {prNumber} {prReference}
          </p>
        </div>
        <div className="flex gap-x-5">
          <div className="hidden md:flex">
            <BestBuy />
          </div>
          <div data-testid="product-view-tabs" className="hidden gap-1.5 rounded-lg bg-gray-200 px-1.5 py-1 lg:flex">
            <Button
              className={classNames('h-11 rounded-md border px-5 text-sm', {
                'pointer-events-none border-gray-300 bg-white ': productView === PRODUCT_VIEW.TILE_VIEW,
                'text-gray-500 hover:bg-gray-300': productView === PRODUCT_VIEW.LIST_VIEW,
              })}
              onClick={() => setProductView(PRODUCT_VIEW.TILE_VIEW)}
            >
              {t('shopPage.productList.tileViewTab', 'Tile View')}
            </Button>
            <Button
              className={classNames('h-11 rounded-md border px-5 text-sm', {
                'pointer-events-none border-gray-300 bg-white ': productView === PRODUCT_VIEW.LIST_VIEW,
                'text-gray-500 hover:bg-gray-300': productView === PRODUCT_VIEW.TILE_VIEW,
              })}
              onClick={() => setProductView(PRODUCT_VIEW.LIST_VIEW)}
            >
              {t('shopPage.productList.listViewTab', 'List View')}
            </Button>
          </div>
        </div>
      </div>
      <SearchContainer
        showAdvancedSearch={showAdvancedSearch}
        setShowAdvancedSearch={setShowAdvancedSearch}
        className="2xl:hidden"
      >
        <SearchAndFilter
          className="w-full"
          testId="product-search-input"
          placeholder={searchFilterPlaceholder()}
          ariaLabel={t('shopPage.productList.ariaLabel', 'Searching for products')}
          onFilter={onFilter}
        />
      </SearchContainer>
      {showAdvancedSearch && (
        <div className="2xl:hidden">
          {!twoXL && <AdvancedSearch showSupplier showCategory showSearch shopPage showMoreOptions />}
        </div>
      )}
      <div className="flex gap-x-5">
        <div className="hidden h-fit w-full max-w-xs 2xl:block">
          {twoXL && <AdvancedSearch showSupplier showCategory showSearch shopPage showMoreOptions />}
        </div>
        <div className="flex-1">
          {searchProductLoading && <Spinner className="mt-5 h-14 md:w-16" />}
          {!searchProductLoading && (
            <InfiniteScroll
              dataLength={possibleRequisitionLines.length}
              next={onFetchMoreProduct}
              hasMore={hasMoreProduct}
              loader={<Spinner className="mt-5 h-14 md:w-16" />}
            >
              <Transition
                show={productView === PRODUCT_VIEW.TILE_VIEW}
                enter="transition-opacity duration-75"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <TileView possibleRequisitionLines={possibleRequisitionLines} lines={lines} />
              </Transition>
              <Transition
                show={productView === PRODUCT_VIEW.LIST_VIEW}
                enter="transition-opacity duration-75"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <RequisitionLinesListView possibleRequisitionLines={possibleRequisitionLines} lines={lines} />
              </Transition>
            </InfiniteScroll>
          )}
          {!searchProductLoading && possibleRequisitionLines.length === 0 && (
            <ProductListEmptyState
              message={t('shopPage.productList.noProductFound', "We couldn't find any products to display.")}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default ProductList
