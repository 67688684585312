import classNames from 'classnames'
import { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, generatePath } from 'react-router-dom'

import MatchPOToInvoiceModal from '../../invoice/page-header/MatchPOToInvoiceModal'
import InvoiceStatus from '../InvoiceStatus'

import { Invoice } from '@/graphql/purchasing/generated/purchasing_graphql'
import { InvoiceStatuses } from '@/modules/invoices/types'
import { Button, ConfirmDialog, Fallback, MoreOptionsMenu, PPNewDetailLink, Tooltip } from '@/modules/shared/components'
import Table from '@/modules/shared/components/table/Table'
import { useMoney } from '@/modules/shared/hooks'
import { InvoiceIcon, MatchIcon, OptionIcon } from '@/modules/shared/icons'
import { Dates, Themes } from '@/modules/shared/types'
import { formatDate } from '@/modules/shared/utils'

interface TableViewProps {
  invoices: Invoice[]
}

function TableView(props: TableViewProps) {
  const { invoices } = props
  const { t } = useTranslation()
  const { formatMoney } = useMoney()
  const [showMatchPOModal, setShowMatchPOModal] = useState(false)
  const [showCannotMatchPOModal, setShowCannotMatchPOModal] = useState(false)
  const [activeInvoiceId, setActiveInvoiceId] = useState<number | null>(null)

  const renderInvoice = ({ id, invoiceNumber }: Invoice) => {
    return (
      <Link
        data-testid="view-invoice-link"
        to={generatePath('/invoices/:invoiceId/summary', {
          invoiceId: String(id),
        })}
        className="text-primary"
      >
        {invoiceNumber}
      </Link>
    )
  }
  const renderReference = ({ reference }: Invoice) => {
    return <Fallback condition={!!reference}>{reference}</Fallback>
  }
  const renderPurchaseOrder = ({ purchaseOrder, id, invoiceNumber, supplier, state }: Invoice) => {
    const isMatchable = state === InvoiceStatuses.Flagged || state === InvoiceStatuses.Open
    return (
      <>
        <Fallback
          condition={!!purchaseOrder}
          fallbackValue={
            <Button
              data-testid="match-po"
              className={classNames('h-9 w-9 cursor-pointer rounded-full bg-gray-200 p-1.5 text-gray-500', {
                'opacity-50': !isMatchable,
              })}
              onClick={() => {
                setActiveInvoiceId(id)
                if (isMatchable) {
                  setShowMatchPOModal(true)
                } else {
                  setShowCannotMatchPOModal(true)
                }
              }}
            >
              <MatchIcon className="h-6 w-6" />
            </Button>
          }
        >
          <PPNewDetailLink type="purchase-order" id={String(purchaseOrder?.id)}>
            {purchaseOrder?.purchaseOrderNumber}
          </PPNewDetailLink>
        </Fallback>
        <MatchPOToInvoiceModal
          invoiceId={String(id)}
          invoiceNumber={invoiceNumber}
          supplierId={supplier?.id}
          supplierName={supplier?.name}
          showModal={showMatchPOModal && activeInvoiceId === id}
          setShowModal={setShowMatchPOModal}
        />
        <ConfirmDialog
          theme={Themes.Primary}
          isOpen={showCannotMatchPOModal && activeInvoiceId === id}
          setIsOpen={setShowCannotMatchPOModal}
          title={t('invoices.details.cannotMatchPO', 'Cannot Match Purchase Order')}
          description={t(
            'invoices.details.cannotMatchPODesc',
            'A Purchase Order can only be matched to an Invoice when the Invoice is in an Open or Flagged state.'
          )}
          primaryButtonLabel={t('general.okayGotIt', 'Okay, Got It')}
          onPrimaryBtnClick={() => setShowCannotMatchPOModal(false)}
        />
      </>
    )
  }
  const renderSupplier = ({ supplier }: Invoice) => {
    return supplier?.name
  }
  const renderAccountCode = ({ department, account }: Invoice) => {
    return (
      <Fallback condition={!!department || !!account}>
        <p>{department?.name}</p>
        <p className="text-xs">{account?.accountName}</p>
      </Fallback>
    )
  }
  const renderStatus = ({ state }: Invoice) => {
    return state && <InvoiceStatus state={state} />
  }
  const renderPDFCopy = ({ attachmentUrl }: Invoice) => {
    return (
      <Fallback condition={!!attachmentUrl}>
        <Tooltip content={t('invoices.details.pdfCopyTooltip', 'Click to view PDF Original.')}>
          <a
            className="flex rounded-full bg-primary/10 p-2"
            href={String(attachmentUrl)}
            target="_blank"
            rel="noreferrer"
          >
            <InvoiceIcon className="h-7 w-7 text-primary" />
          </a>
        </Tooltip>
      </Fallback>
    )
  }
  const renderInvoiceDate = ({ invoiceDate }: Invoice) => {
    return <Fallback condition={!!invoiceDate}>{formatDate(Dates.Short, invoiceDate)}</Fallback>
  }
  const renderTax = ({ totalTaxValue }: Invoice) => {
    return (
      <>
        <p className="text-base font-bold">{formatMoney(totalTaxValue)}</p>
        <p className="text-xs text-gray-600">{t('invoices.details.tax', 'Tax')}</p>
      </>
    )
  }
  const renderTotal = ({ totalValue }: Invoice) => {
    return (
      <>
        <p className="text-base font-bold">{formatMoney(totalValue)}</p>
        <p className="text-xs text-gray-600">{t('invoices.details.taxInclusive', 'Tax Inclusive')}</p>
      </>
    )
  }
  const renderMoreOption = ({ id }: Invoice) => {
    return (
      <MoreOptionsMenu showHelp showOpenInPPlus showPrint showInvoiceLink invoiceId={id}>
        <OptionIcon className="h-10 w-10 text-gray-500" />
      </MoreOptionsMenu>
    )
  }

  return (
    <Table
      dataTestId="invoices-table-view"
      dataSource={invoices}
      keyExtractor={(record) => String(record.id)}
      columns={[
        {
          title: t('invoices.details.invoice', 'Invoice'),
          key: 'invoice',
          headerCellStyles: 'px-4 py-3',
          cellStyles: 'px-4 py-3',
          minWidth: 130,
          maxWidth: 180,
          render: renderInvoice,
        },
        {
          title: t('invoices.details.reference', 'Reference'),
          key: 'reference',
          headerCellStyles: 'px-2 py-3',
          cellStyles: 'px-2 py-3',
          minWidth: 180,
          maxWidth: 220,
          render: renderReference,
        },
        {
          title: t('invoices.details.purchaseOrder', 'Purchase Order'),
          key: 'purchaseOrder',
          headerCellStyles: 'px-2 py-3',
          cellStyles: 'px-2 py-3',
          minWidth: 130,
          maxWidth: 130,
          render: renderPurchaseOrder,
        },
        {
          title: t('invoices.details.supplier', 'Supplier'),
          key: 'supplier',
          headerCellStyles: 'px-2 py-3',
          cellStyles: 'px-2 py-3',
          minWidth: 200,
          maxWidth: 240,
          render: renderSupplier,
        },
        {
          title: t('invoices.details.accountCode', 'Account Code'),
          key: 'accountCode',
          headerCellStyles: 'px-2 py-3',
          cellStyles: 'px-2 py-3',
          minWidth: 240,
          grow: true,
          render: renderAccountCode,
        },
        {
          title: t('invoices.details.status', 'Status'),
          key: 'status',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 120,
          maxWidth: 120,
          render: renderStatus,
        },
        {
          title: t('invoices.details.pdfCopy', 'PDF Copy'),
          key: 'pdfCopy',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 80,
          maxWidth: 80,
          render: renderPDFCopy,
        },
        {
          title: t('invoices.details.invoiceDate', 'Invoice Date'),
          key: 'invoiceDate',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 120,
          maxWidth: 120,
          render: renderInvoiceDate,
        },
        {
          title: t('invoices.details.tax', 'Tax'),
          key: 'tax',
          headerCellStyles: 'px-1 py-3 text-right',
          cellStyles: 'px-1 py-3 text-right',
          minWidth: 90,
          maxWidth: 130,
          render: renderTax,
        },
        {
          title: t('invoices.details.total', 'Total'),
          key: 'total',
          headerCellStyles: 'px-1 py-3 text-right',
          cellStyles: 'px-1 py-3 text-right',
          minWidth: 110,
          maxWidth: 150,
          render: renderTotal,
        },
        {
          title: t('general.more', 'More'),
          key: 'more',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 70,
          maxWidth: 70,
          render: renderMoreOption,
        },
      ]}
    />
  )
}

export default memo(TableView)
