import { useTranslation } from 'react-i18next'

import { Supplier } from '@/graphql/purchasing/generated/purchasing_graphql'
import { formatSupplierBillingAddress } from '@/modules/requisitions/utils'
import { Accordion, DetailsPanelItem } from '@/modules/shared/components'

interface SupplierDetailsProps {
  supplier: Supplier
}

export default function SupplierDetails({ supplier }: SupplierDetailsProps) {
  const { name, telephone, email } = supplier || {}
  const { t } = useTranslation()
  return (
    <Accordion
      title={t('shopPage.productModal.accordions.supplierDetails.title', 'Supplier Details')}
      buttonClassName="px-3 h-11 mt-5"
      titleClassName="font-bold"
    >
      <p className="my-3 text-sm">
        {t(
          'shopPage.productModal.accordions.supplierDetails.descriptionText',
          'Contact the Supplier if you require any further information about this product.'
        )}
      </p>
      <section data-testid="supplier-details" className="mt-2 border">
        <DetailsPanelItem
          title={t('shopPage.productModal.accordions.supplierDetails.supplier', 'Supplier')}
          value={name}
        />
        <DetailsPanelItem
          title={t('shopPage.productModal.accordions.supplierDetails.headQuarters', 'Headquarters')}
          value={supplier && formatSupplierBillingAddress(supplier)}
        />
        <DetailsPanelItem
          title={t('shopPage.productModal.accordions.supplierDetails.contactPhone', 'Contact Phone')}
          value={telephone}
        />
        <DetailsPanelItem
          title={t('shopPage.productModal.accordions.supplierDetails.contactEmail', 'Contact Email')}
          value={email}
        />
      </section>
    </Accordion>
  )
}
