import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useOutletContext } from 'react-router-dom'

import SupplierDetailsModal from './SupplierDetailsModal'
import { useGetRequisitionLinesBySuppliers } from './hooks/useGetRequisitionLinesBySuppliers'
import TableView from './table-view'

import ProductEmptyStateImg from '@/assets/images/product-empty-state.svg'
import {
  RequisitionLine,
  RequisitionLinesBySupplier,
  Supplier,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import LinesBySuppliersTileView from '@/modules/requisitions/components/lines-by-suppliers/LinesBySuppliersTileView'
import { ShoppingCartContext } from '@/modules/requisitions/types'
import { Button, QueryResult } from '@/modules/shared/components'
import EmptyState from '@/modules/shared/components/empty-state'
import { useWindowSize } from '@/modules/shared/hooks'
import { useMoney } from '@/modules/shared/hooks'
import { ControlBarIcon, CurrencyDollarIcon, TruckIcon } from '@/modules/shared/icons'

function Summary() {
  const { formatMoney } = useMoney()
  const { t } = useTranslation()
  const { isLargeScreen } = useWindowSize()

  const { readonly } = useOutletContext<ShoppingCartContext>()

  const [activeSupplierDetailsModal, setActiveSupplierDetailsModal] = useState<number | null>(null)

  const { loading, error, data, requisitionId } = useGetRequisitionLinesBySuppliers()

  const suppliers = data?.currentPurchaser?.requisition?.requisitionLinesBySuppliers || []

  const isLtMinimumOrderValue = (supplier: RequisitionLinesBySupplier) => {
    return supplier.subTotalExTax < Number(supplier.supplier.deliveryChargeConfiguration?.minimumOrderValueExTax)
  }

  return (
    <QueryResult loading={loading} error={error}>
      {requisitionId && data?.currentPurchaser && suppliers.length > 0 ? (
        <>
          {!readonly ? (
            <div className="my-6 flex items-center gap-2">
              <TruckIcon className="h-10 w-10" />
              <p className="text-sm">
                {t('shopPage.cart.summary.supplierCount', {
                  defaultValue_one: 'There is {{ count }} Supplier included in this Requisition',
                  defaultValue_many: 'There are {{ count }} Suppliers included in this Requisition',
                  defaultValue_other: 'There are {{ count }} Suppliers included in this Requisition',
                  count: suppliers.length,
                })}
              </p>
            </div>
          ) : (
            <div className=" flex items-center">
              <ControlBarIcon className=" h-10 w-10" />
              <p className="ml-3 text-sm">
                {data.currentPurchaser.requisition?.requisitionNumber}{' '}
                {t('requisitionInformation.summary.title', 'Product Summary')}
              </p>
            </div>
          )}

          <section className="mt-4 flex flex-col gap-y-12">
            {suppliers.map((supplier) => {
              return (
                <div key={supplier.supplier.id} className="flex flex-col gap-y-3">
                  <div className="flex justify-between gap-x-2 rounded-md bg-white px-5 py-3 shadow-sm">
                    <strong className="text-sm">{supplier.supplier.name}</strong>
                    <p
                      onClick={() => setActiveSupplierDetailsModal(supplier.supplier.id)}
                      className="cursor-pointer text-sm text-primary"
                      data-testid="supplier-details-button"
                    >
                      {t('shopPage.cart.summary.details.button', 'View Supplier Details')}
                    </p>
                  </div>
                  {isLargeScreen ? (
                    <TableView supplier={supplier.supplier as Supplier} lines={supplier.lines as RequisitionLine[]} />
                  ) : (
                    <div className="flex flex-col gap-y-3 text-sm">
                      {supplier.lines.map((line, index) => (
                        <LinesBySuppliersTileView
                          key={line.id}
                          lineIndex={index}
                          line={line as RequisitionLine}
                          supplier={supplier?.supplier as Supplier}
                        />
                      ))}
                    </div>
                  )}
                  {isLtMinimumOrderValue(supplier as RequisitionLinesBySupplier) && (
                    <div
                      className="flex items-center justify-between gap-3 rounded-md bg-white px-5 py-3 text-xs shadow-sm sm:text-sm"
                      data-testid="minimum-order"
                    >
                      <p className="text-error">
                        {t(
                          'shopPage.cart.summary.underMinimum',
                          'Order Total is less than {{ minimum }} Excluding Tax. You may be charged an additional delivery fee of {{ fee }} (Plus {{ tax }}% Tax).',
                          {
                            minimum: formatMoney(supplier.supplier.deliveryChargeConfiguration?.minimumOrderValueExTax),
                            fee: formatMoney(supplier.supplier.deliveryChargeConfiguration?.deliveryChargeExTax),
                            tax: supplier.supplier.deliveryChargeConfiguration?.deliveryChargeTaxPercentage,
                          }
                        )}
                      </p>
                      <Button
                        className="shrink-0 rounded-md bg-error px-3 py-2 text-xs text-white"
                        onClick={() => setActiveSupplierDetailsModal(supplier.supplier.id)}
                      >
                        {t('shopPage.cart.summary.details.learnMore', 'Learn More')}
                      </Button>
                    </div>
                  )}
                  <div className="flex flex-col gap-3 rounded-md bg-white py-3 text-sm shadow-sm">
                    <div className="flex justify-between px-5">
                      <span className="text-gray-500">
                        {t('shopPage.cart.summary.subTotal', 'Sub-Total (Tax Exclusive)')}
                      </span>
                      <span>{`${formatMoney(supplier.subTotalExTax)}`}</span>
                    </div>
                    <div className="flex justify-between px-5">
                      <span className="text-gray-500">{t('shopPage.cart.summary.totalTax', 'Total Tax')}</span>
                      <span>{`${formatMoney(supplier.totalTax)}`}</span>
                    </div>
                    <hr />
                    <div className="flex justify-between px-5">
                      <strong>
                        {t('shopPage.cart.summary.supplierTotalAndTax', 'Supplier Total (Tax Inclusive)')}
                      </strong>
                      <strong>{`${formatMoney(supplier.total)}`}</strong>
                    </div>
                  </div>
                  <SupplierDetailsModal
                    supplier={supplier.supplier as Supplier}
                    showModal={activeSupplierDetailsModal === supplier.supplier.id}
                    setShowModal={setActiveSupplierDetailsModal}
                  />
                </div>
              )
            })}
          </section>

          {Number(data.currentPurchaser.requisition?.requisitionLinesBySuppliers?.length) > 0 && (
            <section className="mt-12 flex flex-col gap-y-3">
              <div className="flex items-center gap-2 text-sm">
                <CurrencyDollarIcon className="h-10 w-10" />
                <p>{t('shopPage.cart.summary.calculation', 'The total for this Requisition is calculated below')}</p>
              </div>
              <div className="rounded-md bg-white px-5 py-3 shadow-sm">
                <strong className="text-sm">{t('shopPage.cart.summary.requisitionTotal', 'Requisition Total')}</strong>
              </div>
              <div className="flex flex-col gap-3 rounded-md bg-white py-3 text-sm shadow-sm">
                <div data-testid="cart-summary-subtotal" className="flex justify-between px-5">
                  <span className="text-gray-500">{t('shopPage.cart.summary.totalEx', 'Total (Tax Exclusive)')}</span>
                  <span>{`${formatMoney(data.currentPurchaser.requisition?.totalValueExcludingTax)}`}</span>
                </div>
                <div data-testid="cart-summary-totaltax" className="flex justify-between px-5">
                  <span className="text-gray-500">{t('shopPage.cart.summary.totalTax', 'Total Tax')}</span>
                  <span>{`${formatMoney(data.currentPurchaser.requisition?.totalTaxValue)}`}</span>
                </div>
                <hr />
                <div data-testid="cart-summary-total" className="flex justify-between px-5">
                  <strong>
                    {t('shopPage.cart.summary.requisitionTotalAndTax', 'Requisition Total (Tax Inclusive)')}
                  </strong>
                  <strong>{`${formatMoney(data.currentPurchaser.requisition?.totalValue)}`}</strong>
                </div>
              </div>
            </section>
          )}
        </>
      ) : (
        <EmptyState
          img={ProductEmptyStateImg}
          message={
            readonly
              ? t('requisition.noProductsInRequisition', 'There are no products in the Requisition.')
              : t('shopPage.cart.summary.noProductsFoundInCart', 'There are no products in your cart.')
          }
        />
      )}
    </QueryResult>
  )
}

export default Summary
