import { gql } from '@apollo/client'

export const GET_ADVANCED_POSSIBLE_REQUISITION_LINES = gql`
  query GetAdvancedPossibleRequisitionLines(
    $requisitionId: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
    $term: String
    $bestBuy: Boolean
    $excludeZeroQuantity: Boolean
  ) {
    currentPurchaser {
      id
      requisition(id: $requisitionId) {
        id
        requisitionNumber
        reference
        totalValue
        totalTaxValue
        commentsCount
        awaitingMyApproval
        state
        originName
        expectedDeliveryDate
        sendToSupplier
        creator {
          id
          fullName
          createdAt
        }
        approvalWorkflow {
          id
          title
        }
        department {
          id
          name
        }
        account {
          id
          accountName
        }
        deliveryAddress {
          id
          locationName
          line1
          line2
          city
          stateProvince
          country
        }
        lines {
          id
          productId
          cataloguedProductId
        }
        possibleRequisitionLines(
          after: $after
          before: $before
          first: $first
          last: $last
          bestBuy: $bestBuy
          searchText: $term
          excludeZeroQuantity: $excludeZeroQuantity
        ) {
          edges {
            node {
              id
              productId
              cataloguedProductId
              image
              unitPrice
              taxPercentage
              unitTax
              quantity
              total
              livePremium
              orderInstruction
              department {
                id
                name
              }
              account {
                id
                accountName
                code
              }
              product {
                id
                brand
                itemDescription
                concatenatedDescription
                concatenatedSellUnit
                itemPackName
                itemMeasure
                itemSellPackName
                itemSize
                category {
                  id
                  name
                  parent {
                    id
                    name
                  }
                }
              }
              supplier {
                id
                name
                billingAddressLine1
                billingAddressLine2
                billingAddressStateProvince
                billingAddressCity
                billingAddressCountry
                billingAddressPostalCode
                telephone
                email
                deliveryChargeConfiguration {
                  minimumOrderValueExTax
                  deliveryChargeExTax
                  deliveryChargeIncTax
                  deliveryChargeTaxPercentage
                }
              }
              supplierProductCode
              purchaserProductCode
              availableQuotes {
                unitPrice
                taxPercentage
                supplierName
                supplierId
                cataloguedProductId
              }
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    }
  }
`
