import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { PricedCatalogue } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Fallback, MoreOptionsMenu, PPNewDetailLink } from '@/modules/shared/components'
import Table from '@/modules/shared/components/table/Table'
import { OptionIcon } from '@/modules/shared/icons'
import { Dates } from '@/modules/shared/types'
import { formatDate } from '@/modules/shared/utils'

interface TableViewProps {
  pricedCatalogs: PricedCatalogue[]
}

function TableView(props: TableViewProps) {
  const { pricedCatalogs } = props
  const { t } = useTranslation()

  const renderCatalog = ({ id, title }: PricedCatalogue) => {
    return (
      <PPNewDetailLink type="catalog" id={String(id)}>
        {title}
      </PPNewDetailLink>
    )
  }
  const renderSupplier = ({ supplier }: PricedCatalogue) => {
    return (
      <Fallback as="p" className="break-words" condition={!!supplier?.name}>
        {supplier?.name}
      </Fallback>
    )
  }
  const renderOwner = ({ owner }: PricedCatalogue) => {
    return (
      <Fallback as="p" className="break-words" condition={!!owner?.name}>
        {owner?.name}
      </Fallback>
    )
  }
  const renderLastUpdated = ({ updatedAt }: PricedCatalogue) => {
    return (
      <>
        <p className="break-words text-sm leading-5">{formatDate(Dates.Short, updatedAt)}</p>
        <p className="text-xs text-gray-500">{formatDate(Dates.Time, updatedAt)}</p>
      </>
    )
  }
  const renderMoreOptions = ({ id }: PricedCatalogue) => {
    return (
      <MoreOptionsMenu showOpenInPPlus showHelp pricedCatalogId={id}>
        <OptionIcon className="h-10 w-10 text-gray-500" />
      </MoreOptionsMenu>
    )
  }

  return (
    <Table
      dataTestId="self-managed-catalogs-table"
      dataSource={pricedCatalogs}
      keyExtractor={(record) => String(record.id)}
      columns={[
        {
          title: t('catalogs.catalog', 'Catalog'),
          key: 'catalog',
          headerCellStyles: 'px-4 py-3',
          cellStyles: 'px-4 py-3',
          minWidth: 230,
          maxWidth: 280,
          render: renderCatalog,
        },
        {
          title: t('catalogs.supplier', 'Supplier'),
          key: 'supplier',
          headerCellStyles: 'px-2 py-3',
          cellStyles: 'px-2 py-3',
          minWidth: 230,
          maxWidth: 280,
          render: renderSupplier,
        },
        {
          title: t('catalogs.owner', 'Owner'),
          key: 'owner',
          headerCellStyles: 'px-2 py-3',
          cellStyles: 'px-2 py-3',
          minWidth: 230,
          grow: true,
          render: renderOwner,
        },
        {
          title: t('general.lastUpdated', 'Last Updated'),
          key: 'lastUpdated',
          headerCellStyles: 'px-0 py-3 text-center',
          cellStyles: 'px-0 py-3 text-center',
          minWidth: 120,
          maxWidth: 120,
          render: renderLastUpdated,
        },
        {
          title: t('general.more', 'More'),
          key: 'more',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 70,
          maxWidth: 70,
          render: renderMoreOptions,
        },
      ]}
    />
  )
}

export default memo(TableView)
