import { NetworkStatus } from '@apollo/client'
import { Combobox } from '@headlessui/react'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useInView } from 'react-intersection-observer'

import ComboboxOptionButton from '../shared/ComboboxOptionButton'
import { ComboboxOptionItem } from '../shared/ComboboxOptionItem'

import { ComboBoxBaseProps } from '@/modules/shared/components/combobox/types'
import { useDebounce } from '@/modules/shared/hooks'
import { Loading } from '@/modules/shared/icons-special'
import { checkNetworkStatus } from '@/modules/shared/utils'

interface ComboboxServerProps<DataType> extends ComboBoxBaseProps<DataType> {
  onDisplay: (e: DataType) => string
  onInputChange: (value: string) => void
  onFetchMore?: () => void
  hasMore?: boolean
  networkStatus: NetworkStatus
  resetSelectItem?: number | null
  comboItemSelected?: boolean
  disableSelected?: boolean
}

function ComboboxServer<T>({
  onInputChange,
  keyExtractor,
  onSelected,
  onFetchMore,
  networkStatus,
  disabled,
  className,
  placeholder,
  items = [],
  onDisplay,
  hasMore,
  hasError,
  errorMessage,
  testId,
  defaultValue,
  resetSelectItem,
  comboItemSelected,
  disableSelected,
}: ComboboxServerProps<T>) {
  const { t } = useTranslation()
  const { ref, inView } = useInView({ threshold: 1 })
  const [query, setQuery] = useState('')
  const [inputFocus, setInputFocus] = useState(false)
  const [selectedItem, setSelectedItem] = useState<T | null>(null)
  const debouncedQuery = useDebounce(query, 500)

  const { setVariablesLoading, loading, fetchMoreLoading } = checkNetworkStatus(networkStatus)

  useEffect(() => {
    if (inputFocus) {
      onInputChange(debouncedQuery)
    }
  }, [debouncedQuery])

  useEffect(() => {
    if (selectedItem) {
      onSelected(selectedItem)
    }
  }, [selectedItem])

  useEffect(() => {
    if (inView && hasMore) {
      onFetchMore && onFetchMore()
    }
  }, [inView, hasMore])

  useEffect(() => {
    if (defaultValue) {
      setSelectedItem(defaultValue)
    }
  }, [defaultValue])

  useEffect(() => {
    if (resetSelectItem) {
      setSelectedItem(null)
    }
  }, [resetSelectItem])

  const getSelectedItem = (item: T) => {
    return item && onDisplay(item)
  }

  const renderItems = () => {
    if (items.length > 0) {
      return items.map((item, index) => (
        <ComboboxOptionItem
          key={`combobox-option-item-${index}`}
          item={item}
          testId={testId}
          keyExtractor={keyExtractor}
          getSelectedItem={getSelectedItem}
        />
      ))
    }
    return (
      <div
        data-testid={`nothing-found${testId ? `-${testId}` : ''}`}
        className="cursor-default select-none px-4 py-2 text-gray-700"
      >
        <h1>{t('combobox.nothingFound', 'Nothing found.')}</h1>
      </div>
    )
  }

  return (
    <Combobox
      as="div"
      className={classNames(className, 'w-full')}
      disabled={disabled || loading}
      value={disableSelected ? null : selectedItem}
      data-testid={testId}
      onChange={setSelectedItem}
    >
      <div className={classNames('relative', 'mt-1', { 'pb-1': hasError })}>
        {/* Headless UI does not support open on focus so this is just a workaround by wrapping `Combobox.Input` with `Combobox.Button` as a `div` */}
        <Combobox.Button as="div">
          <Combobox.Input
            autoComplete="off"
            aria-invalid={hasError ? 'true' : 'false'}
            data-testid={`combobox-input${testId ? `-${testId}` : ''}`}
            className={classNames(
              'w-full rounded-md border border-gray-300 py-3 pl-3 pr-10 shadow-sm transition focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary sm:text-sm',
              {
                'bg-gray-200': disabled || loading,
                'cursor-not-allowed': disabled,
                'border-error': hasError,
                'bg-white': !(disabled || loading || comboItemSelected),
                'text-gray-500': loading,
                'border-primary bg-primary/10 text-primary': comboItemSelected,
              }
            )}
            placeholder={placeholder}
            onFocus={() => setInputFocus(true)}
            onChange={(event) => setQuery(event.target.value)}
            displayValue={(item: T) => getSelectedItem(item)}
          />
        </Combobox.Button>

        <ComboboxOptionButton testId={testId} disabled={disabled} loading={loading} />

        <Combobox.Options
          data-testid={`options-wrapper${testId ? `-${testId}` : ''}`}
          className=" absolute z-10 mt-1 max-h-72  w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-primary/5 focus:outline-none sm:text-sm"
        >
          {setVariablesLoading ? (
            <div className="cursor-default select-none px-4 py-2 text-center text-gray-700">
              <Loading className="h-5 w-5 fill-white text-gray-300" />
            </div>
          ) : (
            renderItems()
          )}

          {fetchMoreLoading && hasMore && (
            <div className="py-2 text-center">
              <Loading className="h-5 w-5 fill-white text-gray-300" />
            </div>
          )}
          {!setVariablesLoading && <div ref={ref} />}
        </Combobox.Options>
      </div>
      {hasError && (
        <span className="text-sm text-error" role="alert">
          {errorMessage}
        </span>
      )}
    </Combobox>
  )
}

export default ComboboxServer
