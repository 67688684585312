import { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import PriceHistoryModal from './PriceHistoryModal'
import UpdateCatalogProductModal from './UpdateCatalogProductModal'

import NoImage from '@/assets/images/no-image.svg'
import { PricedCataloguedProduct } from '@/graphql/purchasing/generated/purchasing_graphql'
import { formatProductItem } from '@/modules/requisitions/utils'
import { Button, Fallback, MoreOptionsMenu, PPNewDetailLink } from '@/modules/shared/components'
import Table from '@/modules/shared/components/table/Table'
import { useMoney } from '@/modules/shared/hooks'
import { OptionIcon } from '@/modules/shared/icons'

interface SelfManagedProductsTableViewProps {
  products: PricedCataloguedProduct[]
}

function SelfManagedProductsTableView({ products }: SelfManagedProductsTableViewProps) {
  const { t } = useTranslation()
  const { formatMoney } = useMoney()
  const { pricedCatalogueId } = useParams<{ pricedCatalogueId: string }>()

  const [priceHistoryModalId, setPriceHistoryModalId] = useState<number | null>(null)
  const [updatePriceModalId, setUpdatePriceModalId] = useState<number | null>(null)

  const renderImage = ({ image }: PricedCataloguedProduct) => (
    <img className="h-12 w-12 border" src={image || NoImage} alt="product" />
  )

  const renderItem = ({ product, id }: PricedCataloguedProduct) => {
    const { brand, itemDescription, itemMeasure, itemPackName, itemSize, concatenatedSellUnit } = product || {}
    return (
      <>
        <PPNewDetailLink
          type="catalog-product"
          testId="product-details-button"
          id={String(pricedCatalogueId)}
          secondaryId={String(id)}
        >
          {brand} {itemDescription}
        </PPNewDetailLink>
        <span className="text-xxs sm:text-xs">
          <p>
            {formatProductItem({
              itemMeasure: itemMeasure,
              itemPackName: itemPackName,
              itemSize: itemSize,
              itemSellUnit: concatenatedSellUnit,
            })}
          </p>
        </span>
      </>
    )
  }

  const renderCategory = ({ product }: PricedCataloguedProduct) => (
    <>
      <p>{product?.category?.parent?.name}</p>
      <p className="text-xs">{product?.category?.name}</p>
    </>
  )

  const renderPricedHistory = (record: PricedCataloguedProduct) => (
    <>
      <Button className="rounded-full bg-gray-200 px-7 py-2.5" onClick={() => setPriceHistoryModalId(record.id)}>
        {t('general.view', 'View')}
      </Button>
      <PriceHistoryModal
        cataloguedProduct={record as PricedCataloguedProduct}
        showModal={priceHistoryModalId === record.id}
        setModalProductId={setPriceHistoryModalId}
      />
    </>
  )

  const renderUnitPrice = (record: PricedCataloguedProduct) => (
    <div
      className="min-w-[100px] cursor-pointer rounded-md border py-3 pl-4 pr-2 text-right transition duration-200 hover:bg-gray-200"
      onClick={() => setUpdatePriceModalId(record.id)}
      data-testid="update-price-button"
    >
      {formatMoney(record?.sellUnitPrice)}
    </div>
  )

  const renderTax = (record: PricedCataloguedProduct) => (
    <>
      <div
        className="m-auto w-fit min-w-[80px] cursor-pointer rounded-md border px-4 py-3 text-center transition duration-200 hover:bg-gray-200"
        onClick={() => setUpdatePriceModalId(record.id)}
        data-testid="update-tax-button"
      >
        <Fallback condition={record?.sellUnitTaxPercentage !== null}>{`${record?.sellUnitTaxPercentage}%`}</Fallback>
      </div>
      <UpdateCatalogProductModal
        cataloguedProduct={record as PricedCataloguedProduct}
        showModal={updatePriceModalId === record.id}
        setModalProductId={setUpdatePriceModalId}
      />
    </>
  )

  const renderMore = (record: PricedCataloguedProduct) => (
    <MoreOptionsMenu catalogId={pricedCatalogueId} productId={record?.id} showProductLink>
      <OptionIcon className="h-10 w-10 text-gray-500" />
    </MoreOptionsMenu>
  )

  return (
    <Table
      dataTestId="self-managed-catalog-products-table"
      dataSource={products}
      keyExtractor={(record) => String(record.id)}
      columns={[
        {
          title: t('general.image', 'Image'),
          key: 'image',
          headerCellStyles: 'px-4 py-3 text-center',
          cellStyles: 'px-4 py-3 text-center',
          minWidth: 90,
          maxWidth: 90,
          render: renderImage,
        },
        {
          title: t('general.item', 'Item'),
          key: 'item',
          headerCellStyles: 'pl-0 pr-4 py-3',
          cellStyles: 'pl-0 pr-4 py-3',
          minWidth: 240,
          maxWidth: 240,
          render: renderItem,
        },
        {
          title: t('general.category', 'Category'),
          key: 'category',
          headerCellStyles: 'pl-0 pr-0 py-3',
          cellStyles: 'pl-0 pr-0 py-3',
          minWidth: 240,
          grow: true,
          render: renderCategory,
        },
        {
          title: t('general.pricedHistory', 'Priced History'),
          key: 'pricedHistory',
          headerCellStyles: 'text-center py-3',
          cellStyles: 'text-center py-3',
          minWidth: 120,
          maxWidth: 120,
          render: renderPricedHistory,
        },
        {
          title: t('general.unitPrice', 'Unit Price'),
          key: 'unitPrice',
          headerCellStyles: 'py-3 pr-2 pl-0 text-right',
          cellStyles: 'py-3 pr-2 pl-0 text-right',
          minWidth: 120,
          maxWidth: 120,
          render: renderUnitPrice,
        },
        {
          title: t('general.tax', 'Tax'),
          key: 'tax',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 100,
          maxWidth: 100,
          render: renderTax,
        },
        {
          title: t('general.more', 'More'),
          key: 'more',
          headerCellStyles: 'py-3 px-4 text-center',
          cellStyles: 'py-3 px-4 text-center',
          minWidth: 70,
          maxWidth: 70,
          render: renderMore,
        },
      ]}
    />
  )
}

export default memo(SelfManagedProductsTableView)
