import { useEffect, useState } from 'react'
import {
  BooleanParam,
  DelimitedArrayParam,
  DelimitedNumericArrayParam,
  NumberParam,
  StringParam,
  useQueryParam,
  withDefault,
} from 'use-query-params'

import { changeDateForQuery } from '../../utils'
import Input from '../input'

import { useDebounce } from '@/modules/shared/hooks'
import { MagnifyingGlassIcon } from '@/modules/shared/icons'
import { QueryParameter } from '@/modules/shared/types'

export interface OnFilter {
  searchValue: string
  filterValue: string
  fromDate?: string
  toDate?: string
  supplierIds?: (number | null)[] | null
  matchedInvoice?: string | null
  receivingNotes?: string | null
  periodYear?: number | null
  periodMonth?: number | null
  categoryIds?: (number | null)[] | null
  bestBuy?: boolean
  supplierProductCode?: string | null
  purchaserProductCode?: string | null
  pPlusProductCode?: string | null
  stockLocations?: (string | null)[] | null
  countStatus?: (string | null)[] | null
  hideZero?: boolean
}

interface SearchInputProps {
  placeholder: string
  testId?: string
  ariaLabel?: string
  onFilter?: (filter: OnFilter) => void
  queryParamFilterType?: QueryParameter
  searchTermURLParamType?:
    | QueryParameter.Search
    | 'supplier-product-code'
    | 'purchaser-product-code'
    | 'p-plus-product-code'
  className?: string
}

export default function SearchAndFilter({
  placeholder,
  testId,
  ariaLabel,
  onFilter,
  queryParamFilterType = QueryParameter.Search,
  searchTermURLParamType = QueryParameter.Search,
  className,
}: SearchInputProps) {
  const [searchValue, setSearchValue] = useState('')
  const [inputFocus, setInputFocus] = useState(false)
  const debouncedSearchValue = useDebounce(searchValue, 500)

  const [searchTermURLParam, setSearchTermURLParam] = useQueryParam(searchTermURLParamType, StringParam)
  const [filterURLParam] = useQueryParam(queryParamFilterType, withDefault(StringParam, 'all'))
  const [fromDateURLParam] = useQueryParam(QueryParameter.From, StringParam)
  const [toDateURLParam] = useQueryParam(QueryParameter.To, StringParam)
  const [suppliersParam] = useQueryParam(QueryParameter.Suppliers, DelimitedNumericArrayParam)
  const [matchedInvoiceURLParam] = useQueryParam(QueryParameter.MatchedInvoice, StringParam)
  const [receivingNotesURLParam] = useQueryParam(QueryParameter.ReceivingNotes, StringParam)
  const [periodYearParam] = useQueryParam(QueryParameter.PeriodYear, NumberParam)
  const [periodMonthParam] = useQueryParam(QueryParameter.PeriodMonth, NumberParam)
  const [categoriesURLParam] = useQueryParam(QueryParameter.Categories, DelimitedNumericArrayParam)
  const [bestBuyURLParam] = useQueryParam(QueryParameter.BestBuy, BooleanParam)
  const [supplierSearchURLParam] = useQueryParam(QueryParameter.SupplierProductCode, StringParam)
  const [purchaserSearchURLParam] = useQueryParam(QueryParameter.PurchaserProductCode, StringParam)
  const [ppSearchURLParam] = useQueryParam(QueryParameter.PPlusProductCode, StringParam)
  const [stockLocationsURLParam] = useQueryParam(QueryParameter.StockLocations, DelimitedArrayParam)
  const [countStatusURLParam] = useQueryParam(QueryParameter.CountStatus, DelimitedArrayParam)
  const [hideZeroQtyURLParam] = useQueryParam(QueryParameter.HideZero, BooleanParam)

  // this hook is used to update the search whenever a search url param is changed
  useEffect(() => {
    setSearchValue(searchTermURLParam || '')
    onFilter &&
      onFilter({
        searchValue: searchTermURLParam?.trim() || '',
        filterValue: filterURLParam || '',
        fromDate: changeDateForQuery(QueryParameter.From, fromDateURLParam),
        toDate: changeDateForQuery(QueryParameter.To, toDateURLParam),
        supplierIds: suppliersParam,
        matchedInvoice: matchedInvoiceURLParam,
        receivingNotes: receivingNotesURLParam,
        periodYear: periodYearParam,
        periodMonth: periodMonthParam,
        categoryIds: categoriesURLParam,
        bestBuy: !!bestBuyURLParam,
        supplierProductCode: supplierSearchURLParam,
        purchaserProductCode: purchaserSearchURLParam,
        pPlusProductCode: ppSearchURLParam,
        stockLocations: stockLocationsURLParam,
        countStatus: countStatusURLParam,
        hideZero: !!hideZeroQtyURLParam,
      })
  }, [
    filterURLParam,
    searchTermURLParam,
    fromDateURLParam,
    toDateURLParam,
    suppliersParam,
    matchedInvoiceURLParam,
    receivingNotesURLParam,
    periodYearParam,
    periodMonthParam,
    bestBuyURLParam,
    categoriesURLParam,
    supplierSearchURLParam,
    purchaserSearchURLParam,
    ppSearchURLParam,
    stockLocationsURLParam,
    countStatusURLParam,
    hideZeroQtyURLParam,
  ])

  useEffect(() => {
    if (inputFocus) {
      if (debouncedSearchValue === '') {
        setSearchTermURLParam(null)
      } else {
        setSearchTermURLParam(debouncedSearchValue)
      }
    }
  }, [debouncedSearchValue])

  useEffect(() => {
    if (searchTermURLParam) setSearchValue(searchTermURLParam)
  }, [])

  return (
    <div className={className ?? 'w-full'}>
      <Input
        data-testid={testId || 'search-input'}
        className="w-full rounded-md border border-gray-200 p-3 text-sm focus:ring-primary"
        placeholder={placeholder}
        aria-label={ariaLabel || placeholder}
        value={searchValue}
        onChange={(e) => setSearchValue(e)}
        onFocus={() => setInputFocus(true)}
        suffixIcon={MagnifyingGlassIcon}
      />
    </div>
  )
}
