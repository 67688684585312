import { Supplier } from '@/graphql/purchasing/generated/purchasing_graphql'

export function formatSupplierBillingAddress(supplier: Supplier): string {
  let {
    billingAddressLine1,
    billingAddressLine2,
    billingAddressCity,
    billingAddressStateProvince,
    billingAddressPostalCode,
  } = supplier

  const arr = [
    billingAddressLine1,
    billingAddressLine2,
    billingAddressCity,
    billingAddressStateProvince,
    billingAddressPostalCode,
  ] as string[]

  return arr
    .filter((e) => Boolean(e))
    .reduce((acc, curr) => `${acc} ${curr}`, '')
    .trim()
}
