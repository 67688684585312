import { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { StringParam, useQueryParam, withDefault } from 'use-query-params'

import TileView from './TileView'
import useGetPurchaseOrders from './hooks/useGetPurchaseOrders'
import TableView from './table-view'

import PurchaseOrdersEmptyStateImg from '@/assets/images/empty-purchase-orders.svg'
import { Spinner } from '@/modules/requisitions/components'
import { Breadcrumb, FilterTabs, QueryResult, TitleBar } from '@/modules/shared/components'
import AdvancedSearch from '@/modules/shared/components/advanced-search/AdvancedSearch'
import EmptyState from '@/modules/shared/components/empty-state'
import SearchAndFilter from '@/modules/shared/components/search-input/SearchAndFilter'
import { useWindowSize } from '@/modules/shared/hooks'
import { HomeIcon, OrderIcon } from '@/modules/shared/icons'
import SearchContainer from '@/modules/shared/search-container'
import { Document, FilterTabItem, QueryParameter } from '@/modules/shared/types'

export default function AllPurchaseOrders() {
  const { t } = useTranslation()
  const { isLargeScreen } = useWindowSize()

  const [filterURLParam] = useQueryParam(QueryParameter.Status, withDefault(StringParam, 'all'))
  const [supplierSearchURLParam] = useQueryParam('supplier-product-code', StringParam)
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(!!supplierSearchURLParam)

  const {
    setVariableLoading,
    refetchLoading,
    loading,
    data,
    error,
    purchaseOrders,
    onFetchMorePurchaseOrders,
    onFilter,
  } = useGetPurchaseOrders()

  const filterTabs: FilterTabItem[] = [
    {
      key: t('purchaseOrders.allPurchaseOrders.filter.viewAll', 'View All'),
      // Not an actual filter value
      filterValue: 'all',
    },
    {
      key: t('purchaseOrders.allPurchaseOrders.filter.notSent', 'Not Sent'),
      filterValue: 'Not Sent',
    },
    {
      key: t('purchaseOrders.allPurchaseOrders.filter.sent', 'Sent'),
      filterValue: 'Sent',
    },
    {
      key: t('purchaseOrders.allPurchaseOrders.filter.closed', 'Closed'),
      filterValue: 'Closed',
    },
    {
      key: t('purchaseOrders.allPurchaseOrders.filter.cancelled', 'Cancelled'),
      filterValue: 'Cancelled',
    },
  ]

  return (
    <>
      <Helmet>
        <title>{t('purchaseOrders.allPurchaseOrders.pageTitle', 'View All Purchase Orders')}</title>
      </Helmet>
      <main className="mx-auto space-y-5 px-4">
        <Breadcrumb className="overflow-x-auto rounded-md bg-gray-200 px-3 py-2">
          <Breadcrumb.Item icon={HomeIcon} href="/dashboard"></Breadcrumb.Item>
          <Breadcrumb.Item href="/purchase-orders">
            {t('breadcrumbs.purchaseOrders', 'Purchase Orders')}
          </Breadcrumb.Item>
          <Breadcrumb.Item>{filterTabs.find((e) => e.filterValue === filterURLParam)?.key}</Breadcrumb.Item>
        </Breadcrumb>
        <section className="space-y-2">
          <TitleBar
            icon={<OrderIcon className="h-10 w-10" />}
            title={t('purchaseOrders.allPurchaseOrders.title', 'All Purchase Orders')}
            subtitle={t('purchaseOrders.allPurchaseOrders.pageTitle', 'View All Purchase Orders')}
          />
          <FilterTabs tabs={filterTabs} />
        </section>
        <SearchContainer showAdvancedSearch={showAdvancedSearch} setShowAdvancedSearch={setShowAdvancedSearch}>
          <SearchAndFilter
            testId="purchase-order-search-input"
            placeholder={t('purchaseOrders.allPurchaseOrders.searchPlaceholder', 'Search by PO Number or Reference')}
            ariaLabel={t('purchaseOrders.allPurchaseOrders.searchPlaceholder', 'Search by PO Number or Reference')}
            onFilter={onFilter}
            queryParamFilterType={QueryParameter.Status}
          />
        </SearchContainer>
        {showAdvancedSearch && (
          <AdvancedSearch showSupplier type={Document.PurchaseOrder} showMatchedInvoices showReceivingNotes />
        )}
        <section>
          <QueryResult loading={loading || refetchLoading || setVariableLoading} error={error}>
            {data?.currentPurchaser && (
              <>
                <InfiniteScroll
                  dataLength={purchaseOrders.length}
                  next={onFetchMorePurchaseOrders}
                  hasMore={!!data?.currentPurchaser?.purchaseOrders?.pageInfo.hasNextPage}
                  loader={<Spinner className="mt-5 h-14 md:w-16" />}
                >
                  {isLargeScreen ? (
                    <TableView purchaseOrders={purchaseOrders} />
                  ) : (
                    <div className="block space-y-5">
                      <TileView purchaseOrders={purchaseOrders} />
                    </div>
                  )}
                </InfiniteScroll>
                {!setVariableLoading && purchaseOrders.length === 0 && (
                  <EmptyState
                    img={PurchaseOrdersEmptyStateImg}
                    message={t(
                      'purchaseOrders.allPurchaseOrders.noPurchaseOrderFound',
                      "We couldn't find any Purchase Orders to display."
                    )}
                  />
                )}
              </>
            )}
          </QueryResult>
        </section>
      </main>
    </>
  )
}
