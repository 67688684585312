import { useTranslation } from 'react-i18next'

import DetailsPanelItem from '../detail-panel-item/DetailsPanelItem'

import { Supplier } from '@/graphql/purchasing/generated/purchasing_graphql'
import { formatSupplierBillingAddress } from '@/modules/requisitions/utils'

export interface SupplierDetailsProps {
  supplier: Supplier
  showLess?: boolean
}

export default function SupplierDetails(props: SupplierDetailsProps) {
  const { supplier, showLess } = props
  const { t } = useTranslation()
  return (
    <section className="mt-2 border" data-testid="supplier-details">
      <DetailsPanelItem
        title={t('shopPage.productModal.accordions.supplierDetails.supplier', 'Supplier')}
        value={supplier.name}
      />
      <DetailsPanelItem
        title={t('shopPage.productModal.accordions.supplierDetails.headQuarters', 'Headquarters')}
        value={formatSupplierBillingAddress(supplier)}
      />
      <DetailsPanelItem
        title={t('shopPage.productModal.accordions.supplierDetails.contactPhone', 'Contact Phone')}
        value={supplier.telephone}
      />
      <DetailsPanelItem
        title={t('shopPage.productModal.accordions.supplierDetails.contactEmail', 'Contact Email')}
        value={supplier.email}
      />
      {!showLess && (
        <DetailsPanelItem
          title={t('shopPage.productModal.accordions.supplierDetails.businessNumber', 'Business Number')}
          value={supplier.businessNumber}
        />
      )}
    </section>
  )
}
