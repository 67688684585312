import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { ConfirmDialog } from '@/modules/shared/components'
import { ENTERPRISE_REQUISITION_URL } from '@/modules/shared/constants'
import { Themes } from '@/modules/shared/types'

interface ShoppingNotAvailableModalProps {
  requisitionId: number
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

export default function ShoppingNotAvailableModal(props: ShoppingNotAvailableModalProps) {
  const { requisitionId, open, setOpen } = props
  const { t } = useTranslation()
  return (
    <ConfirmDialog
      title={t('requisition.shoppingNotAvaiable', 'Shopping Not Available')}
      description={t(
        'requisition.shoppingNotAvaiableDesc',
        'This Requisition was created in PurchasePlus Enterprise and shopping cannot be continued here. You can open the Requisition in PurchasePlus Enterprise using the option below if you wish to modify it.'
      )}
      theme={Themes.Primary}
      primaryButtonLabel={t('general.okayGotIt', 'Okay, Got It')}
      secondaryButtonLabel={t('general.openInEnterprise', 'Open in P+ Enterprise')}
      isOpen={open}
      setIsOpen={setOpen}
      onPrimaryBtnClick={() => setOpen(false)}
      onSecondaryBtnClick={() => {
        window.open(`${ENTERPRISE_REQUISITION_URL}/${requisitionId}`)
        setOpen(false)
      }}
    />
  )
}
