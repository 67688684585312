import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { Invoice } from '@/graphql/purchasing/generated/purchasing_graphql'
import { InvoiceActionTypes, InvoiceStatuses } from '@/modules/invoices/types'
import { Button } from '@/modules/shared/components'

interface InvoiceActionsProps {
  invoice: Invoice
  onConfirmInvoiceAction: (action?: InvoiceActionTypes) => void
  confirmActionLoading: boolean
}

export default function InvoiceActions({ invoice, onConfirmInvoiceAction, confirmActionLoading }: InvoiceActionsProps) {
  const { t } = useTranslation()
  const { state, isOperationallyApproved, isFinanciallyApproved } = invoice || {}

  const showInvoiceAction =
    state === InvoiceStatuses.Open ||
    state === InvoiceStatuses.Parked ||
    state === InvoiceStatuses.Reconciled ||
    state === InvoiceStatuses.Flagged

  return (
    <>
      {showInvoiceAction && (
        <section
          className={classNames('flex items-center justify-between rounded-md bg-white text-sm shadow-sm', {
            'px-4 py-3': state !== InvoiceStatuses.Flagged,
          })}
        >
          {state === InvoiceStatuses.Open && (
            <>
              <p>{t('invoices.youCanMarkAsComplete', 'You can mark this Invoice as Complete')}</p>
              <Button
                className="rounded-md bg-success px-4 py-2 text-sm text-white"
                onClick={() => onConfirmInvoiceAction(InvoiceActionTypes.Complete)}
                loading={confirmActionLoading}
                data-testid="complete-invoice-button"
              >
                {t('invoices.markAsComplete', 'Mark As Complete')}
              </Button>
            </>
          )}
          {state === InvoiceStatuses.Parked && (
            <>
              <p>{t('invoices.youCanReopen', 'You can Re-Open this Invoice')}</p>
              <Button
                className="rounded-md bg-primary px-4 py-2 text-sm text-white"
                onClick={() => onConfirmInvoiceAction(InvoiceActionTypes.Reopen)}
                loading={confirmActionLoading}
                data-testid="reopen-invoice-button"
              >
                {t('invoices.reopen', 'Re-Open Invoice')}
              </Button>
            </>
          )}
          {state === InvoiceStatuses.Reconciled && (
            <>
              <p>{t('invoices.youCanProcess', 'You can mark this Invoice as Ready for Processing')}</p>
              <Button
                className="rounded-md bg-orange px-4 py-2 text-sm text-white"
                onClick={() => onConfirmInvoiceAction(InvoiceActionTypes.Process)}
                loading={confirmActionLoading}
                data-testid="process-invoice-button"
              >
                {t('invoices.readyForProcessing', 'Ready for Processing')}
              </Button>
            </>
          )}
          {state === InvoiceStatuses.Flagged && (!isFinanciallyApproved || !isOperationallyApproved) && (
            <div className="flex w-full flex-col divide-y">
              {!isOperationallyApproved && (
                <div className="flex items-center justify-between px-4 py-3">
                  <p>{t('invoices.operationallyApprove', 'You can Operationally Approve this Invoice')}</p>
                  <Button
                    className="rounded-md bg-success px-4 py-2 text-sm text-white"
                    onClick={() => onConfirmInvoiceAction(InvoiceActionTypes.OperationalApprove)}
                    loading={confirmActionLoading}
                    data-testid="operationally-approve-invoice-button"
                  >
                    {t('general.approve', 'Approve')}
                  </Button>
                </div>
              )}
              {!isFinanciallyApproved && (
                <div className="flex items-center justify-between px-4 py-3">
                  <p>{t('invoices.financiallyApprove', 'You can Financially Approve this Invoice')}</p>
                  <Button
                    className="rounded-md bg-success px-4 py-2 text-sm text-white"
                    onClick={() => onConfirmInvoiceAction(InvoiceActionTypes.FinancialApprove)}
                    loading={confirmActionLoading}
                    data-testid="financially-approve-invoice-button"
                  >
                    {t('general.approve', 'Approve')}
                  </Button>
                </div>
              )}
            </div>
          )}
        </section>
      )}
    </>
  )
}
