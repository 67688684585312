import { useTranslation } from 'react-i18next'

import BalanceContainerRow from './BalanceContainerRow'

import { Tooltip } from '@/modules/shared/components'
import { QuestionMarkIcon } from '@/modules/shared/icons'

export interface BalanceContainerProps {
  title: string
  tooltip: string
  valueExTax: number | string | null
  tax: number | string | null
  totals?: boolean
}

export default function BalanceContainer({ title, tooltip, valueExTax, tax, totals }: BalanceContainerProps) {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col gap-y-1">
      <div className="flex items-center gap-x-1 rounded-md bg-gray-100 p-2">
        <p className="font-bold">{title}</p>
        <Tooltip content={tooltip}>
          <span>
            <QuestionMarkIcon className="h-4 w-4" />
          </span>
        </Tooltip>
      </div>
      <BalanceContainerRow
        title={
          totals
            ? t('invoices.invoice.balance.totalExcludingTax', 'Total Excluding Tax')
            : t('invoices.invoice.balance.excludingTax', 'Excluding Tax')
        }
        value={valueExTax}
        canHover
      />
      <BalanceContainerRow
        title={totals ? t('invoices.invoice.balance.totalTax', 'Total Tax') : t('invoices.invoice.balance.tax', 'Tax')}
        value={tax}
        canHover
      />
    </div>
  )
}
