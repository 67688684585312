import { useTranslation } from 'react-i18next'

import InvoiceStatus from '../../all-invoices/InvoiceStatus'

import { CreditNote, Invoice, ReceivingDocument } from '@/graphql/purchasing/generated/purchasing_graphql'
import { InvoiceInceptionTypes } from '@/modules/invoices/types'
import { Fallback, PPNewDetailLink } from '@/modules/shared/components'
import { TableRowDouble, TableRowSingle } from '@/modules/shared/components/page-header-details-table'
import { ENTERPRISE_CREDIT_NOTES_URL, ENTERPRISE_GR_URL } from '@/modules/shared/constants'
import { useMoney } from '@/modules/shared/hooks'
import { DownloadIcon } from '@/modules/shared/icons'
import { Dates } from '@/modules/shared/types'
import { extractEdges, formatDate } from '@/modules/shared/utils'

interface DetailsProps {
  invoice: Invoice
}

export default function Details(props: DetailsProps) {
  const { invoice } = props
  const {
    invoiceNumber,
    invoiceDate,
    reference,
    state,
    purchaser,
    supplier,
    department,
    account,
    purchaseOrder,
    receivingDocuments,
    creditNotes,
    deliveryChargeExTax,
    deliveryChargeTax,
    totalTaxValue,
    totalValue,
    totalValueExTax,
    attachmentUrl,
    inception,
  } = invoice || {}

  const { formatMoney } = useMoney()
  const { t } = useTranslation()

  const inceptionTranslate = () => {
    switch (inception) {
      case InvoiceInceptionTypes.Manual:
        return t('invoices.detailsTable.inceptionManual', 'Manual')
      case InvoiceInceptionTypes.PDF:
        return t('invoices.detailsTable.inceptionPdf', 'PDF')
      default:
        return inception
    }
  }

  return (
    <section className="flex items-center justify-between rounded-md  bg-white p-4 text-sm shadow-sm">
      <div className="w-full divide-y divide-gray-300 overflow-auto rounded-md border border-gray-300">
        <TableRowDouble
          leftTitle={t('invoices.detailsTable.invoiceNumber', 'Invoice Number')}
          leftTooltip={t(
            'invoices.detailsTable.invoiceNumberTooltip',
            'The number used to identify this Invoice document.'
          )}
          leftTestId="invoiceNumber"
          leftValue={invoiceNumber}
          rightTitle={t('invoices.detailsTable.invoiceDate', 'Invoice Date')}
          rightTooltip={t('invoices.detailsTable.invoiceDateTooltip', 'The date recorded on the Invoice.')}
          rightTestId="dueDate"
          rightValue={formatDate(Dates.ShortReverse, invoiceDate)}
        />
        <TableRowSingle
          titleText={t('invoices.detailsTable.reference', 'Reference')}
          tooltipText={t(
            'invoices.detailsTable.referenceTooltip',
            'The reference that appears on the Purchase Order that has been matched to this Invoice.'
          )}
          testId="reference"
          value={<Fallback condition={!!reference}>{reference}</Fallback>}
        />
        <TableRowSingle
          titleText={t('invoices.detailsTable.status', 'Status')}
          tooltipText={t(
            'invoices.detailsTable.statusTooltip',
            'The current status of the Invoice. Click the status to see an explanation of each status type.'
          )}
          testId="status"
          value={<InvoiceStatus state={state || ''} />}
        />
        <TableRowSingle
          titleText={t('invoices.detailsTable.pdfCopy', 'PDF Copy')}
          tooltipText={t(
            'invoices.detailsTable.pdfCopyTooltip',
            'If this Invoice was created via Paperless Invoicing, a PDF copy of the Invoice will be available here.'
          )}
          testId="pdfCopy"
          value={
            <Fallback condition={!!attachmentUrl}>
              <a
                className="flex cursor-pointer items-center gap-x-1 rounded-md bg-gray-200 px-3 py-1.5 text-xs hover:brightness-95"
                href={attachmentUrl || ''}
                target="_blank"
                rel="noreferrer"
              >
                {t('invoices.detailsTable.downloadPDF', 'Download PDF')}
                <DownloadIcon className="h-4 w-4" />
              </a>
            </Fallback>
          }
        />
        <TableRowSingle
          titleText={t('invoices.detailsTable.invoiceType', 'Invoice Type')}
          tooltipText={t(
            'invoices.detailsTable.invoiceTypeTooltip',
            'If the Invoice was manually created the Invoice type will be "Manual". If the Invoice was created via Paperless Invoicing, the Invoice type will be "PDF".'
          )}
          testId="invoiceType"
          value={<Fallback condition={!!inception}>{inceptionTranslate()}</Fallback>}
        />
        <TableRowSingle
          titleText={t('invoices.detailsTable.purchaser', 'Purchaser')}
          tooltipText={t(
            'invoices.detailsTable.purchaserTooltip',
            'The Purchasing Organisation that this Invoice is made out to.'
          )}
          testId="purchaser"
          value={<Fallback condition={!!purchaser?.name}>{purchaser?.name}</Fallback>}
        />
        <TableRowDouble
          leftTitle={t('invoices.detailsTable.department', 'Department')}
          leftTooltip={t(
            'invoices.detailsTable.departmentTooltip',
            'The Department that this Invoice expense will be recorded against. Use Journal Entries if you with to record Invoice line expenses to a different Department.'
          )}
          leftTestId="department"
          leftValue={<Fallback condition={!!department?.name}>{department?.name}</Fallback>}
          rightTitle={t('invoices.detailsTable.accountCode', 'Account Code')}
          rightTooltip={t(
            'invoices.detailsTable.accountCodeTooltip',
            'The account code that this Invoice expense will be recorded against. Use Journal Entries if you with to record Invoice line expenses to a different Account Code.'
          )}
          rightTestId="accountCode"
          rightValue={<Fallback condition={!!account?.code}>{account?.code}</Fallback>}
        />
        <TableRowSingle
          titleText={t('invoices.detailsTable.supplier', 'Supplier')}
          tooltipText={t('invoices.detailsTable.supplierTooltip', 'The Supplier that sent the Invoice.')}
          testId="supplier"
          value={<Fallback condition={!!supplier?.name}>{supplier?.name}</Fallback>}
        />
        <TableRowDouble
          leftTitle={t('invoices.detailsTable.deliveryFeeExTax', 'Delivery Fee (Ex Tax)')}
          leftTooltip={t('invoices.detailsTable.deliveryFeeExTaxTooltip', 'The delivery fee, excluding taxes.')}
          leftTestId="deliveryFeeExTax"
          leftValue={formatMoney(deliveryChargeExTax)}
          rightTitle={t('invoices.detailsTable.deliveryFeeEInTax', 'Delivery Fee Tax')}
          rightTooltip={t(
            'invoices.detailsTable.deliveryFeeEInTaxTooltip',
            'The tax amount that will be added to the delivery fee.'
          )}
          rightTestId="deliveryFeeEInTax"
          rightValue={formatMoney(deliveryChargeTax)}
        />
        <TableRowDouble
          leftTitle={t('invoices.detailsTable.invoiceTotalExTax', 'Invoice Total (Ex Tax)')}
          leftTooltip={t(
            'invoices.detailsTable.invoiceTotalExTaxTooltip',
            'The Invoice total, including the Delivery Fee, but excluding taxes and the Delivery Fee Tax.'
          )}
          leftTestId="invoiceTotalExTax"
          leftValue={formatMoney(totalValueExTax)}
          rightTitle={t('invoices.detailsTable.totalTax', 'Total Tax')}
          rightTooltip={t(
            'invoices.detailsTable.totalTaxTooltip',
            'The total tax amount of the Invoice. This should equal the total of the tax on each Invoice line, plus the Delivery Fee Tax.'
          )}
          rightTestId="totalTax"
          rightValue={formatMoney(totalTaxValue)}
        />
        <TableRowSingle
          titleText={t('invoices.detailsTable.invoiceTotalInTax', 'Invoice Total')}
          tooltipText={t(
            'invoices.detailsTable.invoiceTotalInTaxTooltip',
            'The total of the Invoice including tax, plus the Delivery Fee and the Delivery Fee Tax.'
          )}
          testId="invoiceTotalInTax"
          value={formatMoney(totalValue)}
        />
        <TableRowSingle
          titleText={t('invoices.detailsTable.purchaseOrder', 'Purchase Order')}
          tooltipText={t(
            'invoices.detailsTable.purchaseOrderTooltip',
            'The Purchase Order that has been matched to this Invoice, if any. An Invoice must have a Purchase Order matched to it before it can be reconciled.'
          )}
          testId="purchaseOrder"
          value={
            <Fallback condition={!!purchaseOrder}>
              <PPNewDetailLink openNewTab type="purchase-order" id={String(purchaseOrder?.id)}>
                {purchaseOrder?.purchaseOrderNumber}
              </PPNewDetailLink>
            </Fallback>
          }
        />
        <TableRowSingle
          titleText={t('invoices.detailsTable.receivingNotes', 'Receiving Notes')}
          tooltipText={t(
            'invoices.detailsTable.receivingNotesTooltip',
            'The Goods Receiving Notes that are associated to this Invoice via the matched Purchase Order.'
          )}
          testId="receivingNotes"
          value={
            <Fallback condition={!!receivingDocuments?.edges?.length}>
              {extractEdges<ReceivingDocument>(receivingDocuments).map((gr) => (
                <a
                  key={gr.id}
                  className="cursor-pointer text-primary"
                  href={`${ENTERPRISE_GR_URL}/${gr.id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {gr.receivingDocumentNumber}
                </a>
              ))}
            </Fallback>
          }
        />
        <TableRowSingle
          titleText={t('invoices.detailsTable.creditNotes', 'Credit Notes')}
          tooltipText={t(
            'invoices.detailsTable.creditNotesTooltip',
            'If any Credit Notes have been associated with this Invoice they will appear here.'
          )}
          testId="creditNotes"
          value={
            <Fallback condition={!!creditNotes?.edges?.length}>
              {extractEdges<CreditNote>(creditNotes).map((creditNote) => (
                <a
                  key={creditNote.id}
                  className="cursor-pointer text-primary"
                  href={`${ENTERPRISE_CREDIT_NOTES_URL}/${creditNote.id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {creditNote.creditNoteNumber}
                </a>
              ))}
            </Fallback>
          }
        />
      </div>
    </section>
  )
}
