import classNames from 'classnames'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import SupplierOptions from '../SupplierOptions'

import { SupplierRelationship } from '@/graphql/purchasing/generated/purchasing_graphql'
import { PPNewDetailLink, SimpleTileViewItem } from '@/modules/shared/components'
import { OptionIcon } from '@/modules/shared/icons'

interface TileViewProps {
  suppliers: SupplierRelationship[]
}

function TileView(props: TileViewProps) {
  const { suppliers } = props
  const { t } = useTranslation()

  return (
    <>
      {suppliers.map((supplier) => {
        const { id, isEnabled, supplierName, supplierNumber } = supplier
        return (
          <div className="mb-6 rounded-md border bg-white" key={id}>
            <div className="p-4">
              <span className="break-all text-base font-bold">{supplierName}</span>
              <p className="text-sm text-gray-500">{supplierNumber}</p>
            </div>
            <hr />
            <section data-testid="supplier-details" className="p-4 text-sm">
              <SimpleTileViewItem
                title={t('mySuppliers.table.status', 'Status')}
                value={
                  <span
                    className={classNames(`rounded-full px-3 py-1 text-xs text-white`, {
                      'bg-success': isEnabled,
                      'bg-error': !isEnabled,
                    })}
                  >
                    {isEnabled ? t('mySuppliers.enabled', 'Enabled') : t('mySuppliers.disabled', 'Disabled')}
                  </span>
                }
              />
            </section>

            <hr />
            <div className={classNames('flex w-full items-center justify-between p-4')}>
              <PPNewDetailLink
                type="supplier"
                id={String(supplier.id)}
                className="flex h-11 items-center justify-center rounded-md bg-gray-200 p-4 text-center text-sm transition hover:brightness-95"
              >
                {t('mySuppliers.viewSupplier', 'View Supplier')}
              </PPNewDetailLink>

              <SupplierOptions id={id}>
                <OptionIcon className="h-10 w-10 text-gray-500" />
              </SupplierOptions>
            </div>
          </div>
        )
      })}
    </>
  )
}

export default memo(TileView)
