import { graphql } from 'msw'

import { GetRequisitionsAwaitingApprovalQuery } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseGetRequisitionsAwaitingApproval: GetRequisitionsAwaitingApprovalQuery = {
  currentPurchaser: {
    id: 44926,
    requisitionsAwaitingMyApproval: {
      edges: [
        {
          node: {
            id: 5235941,
            totalValue: 104.5,
            requisitionNumber: 'PR04207',
            state: 'awaiting_approval',
            originName: 'Beverage - Bar',
            creator: { id: 123, fullName: 'Johan W. Baaij', __typename: 'User' },
            createdAt: '2022-07-08T12:49:06+10:00',
            totalValueExcludingTax: 48.96,
            totalTaxValue: 4.896,
            reference: 'reference',
            nextApprovingAgentsNames: 'Kevin Milner',
            expectedDeliveryDate: '2022-07-12',
            sendToSupplier: true,
            approvalWorkflow: {
              id: 123,
              title: 'Demo',
              __typename: 'RequisitionApprovalWorkflow',
            },
            department: {
              id: 123,
              name: 'AStore Shop Demo',
              __typename: 'Purchaser',
            },
            account: {
              id: 123,
              accountName: '0060110 - Tickets for Attractions',
              __typename: 'Account',
            },
            __typename: 'Requisition',
          },
          __typename: 'RequisitionEdge',
        },
        {
          node: {
            id: 4956033,
            requisitionNumber: 'PR04130',
            reference: 'abc',
            state: 'awaiting_approval',
            totalValue: 539,
            originName: null,
            creator: { id: 123, fullName: 'Duong Nguyen', __typename: 'User' },
            createdAt: '2022-06-08T11:14:57+10:00',
            totalValueExcludingTax: 69.0,
            totalTaxValue: 0.816,
            nextApprovingAgentsNames: 'Kevin Milner',
            expectedDeliveryDate: '2022-06-25',
            sendToSupplier: true,
            approvalWorkflow: {
              id: 123,
              title: 'Demo',
              __typename: 'RequisitionApprovalWorkflow',
            },
            department: null,
            account: null,
            __typename: 'Requisition',
          },
          __typename: 'RequisitionEdge',
        },
        {
          node: {
            id: 4930350,
            requisitionNumber: 'PR04117',
            state: 'awaiting_approval',
            originName: null,
            totalValue: 158950,
            creator: { id: 123, fullName: 'Garrus Nguyen', __typename: 'User' },
            createdAt: '2022-05-04T18:01:26+10:00',
            totalValueExcludingTax: 955.534,
            totalTaxValue: 79.3164,
            reference: 'abc',
            nextApprovingAgentsNames: 'Kevin Milner',
            expectedDeliveryDate: null,
            sendToSupplier: true,
            approvalWorkflow: {
              id: 123,
              title: 'Demo',
              __typename: 'RequisitionApprovalWorkflow',
            },
            department: null,
            account: null,
            __typename: 'Requisition',
          },
          __typename: 'RequisitionEdge',
        },
        {
          node: {
            id: 4930336,
            requisitionNumber: 'PR04115',
            state: 'awaiting_approval',
            originName: null,
            totalValue: 2.98,
            creator: { id: 123, fullName: 'Garrus Nguyen', __typename: 'User' },
            createdAt: '2022-05-04T18:01:26+10:00',
            totalValueExcludingTax: 955.534,
            totalTaxValue: 79.3164,
            reference: 'abc',
            nextApprovingAgentsNames: 'Kevin Milner',
            expectedDeliveryDate: null,
            sendToSupplier: true,
            approvalWorkflow: {
              id: 123,
              title: 'Demo',
              __typename: 'RequisitionApprovalWorkflow',
            },
            department: null,
            account: null,
            __typename: 'Requisition',
          },
          __typename: 'RequisitionEdge',
        },
      ],
      __typename: 'RequisitionConnection',
    },
    __typename: 'Purchaser',
  },
}

export const GetRequisitionsAwaitingApproval = graphql.query<GetRequisitionsAwaitingApprovalQuery>(
  'GetRequisitionsAwaitingApproval',
  (req, res, ctx) => {
    return res(ctx.data(baseGetRequisitionsAwaitingApproval))
  }
)

export default baseGetRequisitionsAwaitingApproval
