import { useQuery } from '@apollo/client'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { StringParam, useQueryParam, withDefault } from 'use-query-params'

import TableView from './table-view'
import TileView from './tile-view'

import EmptyStateImg from '@/assets/images/empty-suppliers.svg'
import {
  GetSupplierRelationshipsDocument,
  SupplierRelationship,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { Spinner } from '@/modules/requisitions/components'
import { Breadcrumb, FilterTabs, QueryResult, ThemeWrapper } from '@/modules/shared/components'
import EmptyState from '@/modules/shared/components/empty-state'
import SearchAndFilter, { OnFilter } from '@/modules/shared/components/search-input/SearchAndFilter'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { useWindowSize } from '@/modules/shared/hooks'
import { HomeIcon, TruckIcon } from '@/modules/shared/icons'
import { FilterTabItem, QueryParameter, Themes } from '@/modules/shared/types'
import { checkNetworkStatus, extractEdges } from '@/modules/shared/utils'

export default function MySuppliers() {
  const { t } = useTranslation()
  const { isLargeScreen } = useWindowSize()

  const [filterURLParam] = useQueryParam(QueryParameter.Enabled, withDefault(StringParam, 'all'))

  const onFilter = ({ searchValue, filterValue }: OnFilter) => {
    refetch({
      filter: {
        q: [
          { property: 'supplierName_or_supplierNumber_cont', value: searchValue },
          { property: 'isEnabled_eq', value: filterValue },
        ],
      },
    })
  }

  const { data, error, fetchMore, refetch, networkStatus } = useQuery(GetSupplierRelationshipsDocument, {
    variables: {
      first: 25,
      after: null,
      filter: {},
    },
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
    notifyOnNetworkStatusChange: true,
  })

  const { setVariablesLoading, refetchLoading, loading } = checkNetworkStatus(networkStatus)

  const suppliers = extractEdges<SupplierRelationship>(data?.currentPurchaser?.supplierRelationships)

  const filterTabs: FilterTabItem[] = [
    { key: t('mySuppliers.viewAll', 'View All'), filterValue: 'all' },
    { key: t('mySuppliers.enabled', 'Enabled'), filterValue: 'true' },
    { key: t('mySuppliers.disabled', 'Disabled'), filterValue: 'false' },
  ]

  const onFetchMoreSuppliers = () => {
    fetchMore({
      variables: {
        after: data?.currentPurchaser?.supplierRelationships?.pageInfo?.endCursor,
      },
    })
  }
  return (
    <>
      <Helmet>
        <title>{t('mySuppliers.pageTitle', 'My Suppliers')}</title>
      </Helmet>
      <main className="mx-auto space-y-5 px-4" data-testid="my-suppliers">
        <Breadcrumb className="rounded-md bg-gray-200 px-3 py-2">
          <Breadcrumb.Item icon={HomeIcon} href="/dashboard"></Breadcrumb.Item>
          <Breadcrumb.Item href="/my-suppliers">{t('breadcrumbs.mySuppliers', 'My Suppliers')}</Breadcrumb.Item>
          <Breadcrumb.Item>{filterTabs.find((e) => e.filterValue === filterURLParam)?.key}</Breadcrumb.Item>
        </Breadcrumb>
        <section className="space-y-2">
          <section className="mt-5 flex rounded-md bg-white p-4 shadow-sm" data-testid="my-suppliers-header">
            <div className="flex items-center">
              <ThemeWrapper theme={Themes.Primary} className="flex h-14 w-14 items-center justify-center rounded-md ">
                <TruckIcon className="h-10 w-10" />
              </ThemeWrapper>
              <div className="ml-3">
                <p className="font-bold">{t('mySuppliers.title', 'My Suppliers')}</p>
                <p className="text-sm text-gray-500">{t('mySuppliers.subtitle', 'View All of Your Suppliers')}</p>
              </div>
            </div>
          </section>
          <div data-testid="my-suppliers-filters">
            <FilterTabs tabs={filterTabs} filterBy={QueryParameter.Enabled} />
          </div>
        </section>
        <SearchAndFilter
          testId="supplier-search-input"
          placeholder={t('mySuppliers.searchPlaceholder', 'Search by Supplier Name or Number')}
          ariaLabel={t('mySuppliers.ariaLabel', 'Searching for suppliers')}
          onFilter={onFilter}
          queryParamFilterType={QueryParameter.Enabled}
        />
        <QueryResult loading={loading || refetchLoading || setVariablesLoading} error={error}>
          {data?.currentPurchaser && (
            <>
              <InfiniteScroll
                dataLength={suppliers.length}
                next={onFetchMoreSuppliers}
                hasMore={!!data?.currentPurchaser?.supplierRelationships?.pageInfo?.hasNextPage}
                loader={<Spinner className="mt-5 h-14 md:w-16" />}
              >
                {isLargeScreen ? <TableView suppliers={suppliers} /> : <TileView suppliers={suppliers} />}
              </InfiniteScroll>
              {!setVariablesLoading && suppliers.length === 0 && (
                <EmptyState
                  img={EmptyStateImg}
                  message={t('mySuppliers.noSuppliers', "We couldn't find any suppliers to display.")}
                />
              )}
            </>
          )}
        </QueryResult>
      </main>
    </>
  )
}
