import { useTranslation } from 'react-i18next'
import { StringParam, useQueryParam } from 'use-query-params'

import AdvancedSearchFilter from './AdvancedSearchFilter'

import { Chip, DatePicker } from '@/modules/shared/components'
import { Dates, QueryParameter } from '@/modules/shared/types'
import { formatDate } from '@/modules/shared/utils'

interface DateRangeProps {
  title: string
}

export default function DateRange({ title }: DateRangeProps) {
  const { t } = useTranslation()
  const [fromDateURLParam, setFromDateURLParam] = useQueryParam(QueryParameter.From, StringParam)
  const [toDateURLParam, setToDateURLParam] = useQueryParam(QueryParameter.To, StringParam)

  return (
    <AdvancedSearchFilter buttonTestId="date-filter-section" buttonTitle={title}>
      <div className="flex flex-col gap-2 lg:flex-row">
        <DatePicker
          label={t('allRequisitions.filter.fromDateLabel', 'Select From Date')}
          defaultDate={fromDateURLParam || ''}
          onSelected={setFromDateURLParam}
          placeholder={t('allRequisitions.filter.fromDate', 'From Date')}
          isSelected={!!fromDateURLParam}
          canBeEmpty
          showRight
        />
        <DatePicker
          label={t('allRequisitions.filter.toDateLabel', 'Select To Date')}
          defaultDate={toDateURLParam || ''}
          onSelected={setToDateURLParam}
          placeholder={t('allRequisitions.filter.toDate', 'To Date')}
          isSelected={!!toDateURLParam}
          canBeEmpty
          showRight
        />
      </div>
      <div className="flex gap-x-2 text-sm">
        {fromDateURLParam && (
          <Chip
            label={t('allRequisitions.filter.fromDateChip', 'From {{ date }} ', {
              date: formatDate(Dates.Short, fromDateURLParam),
            })}
            onRemoveChip={() => setFromDateURLParam(null)}
          />
        )}
        {toDateURLParam && (
          <Chip
            label={t('allRequisitions.filter.toDateChip', 'To {{ date }} ', {
              date: formatDate(Dates.Short, toDateURLParam),
            })}
            onRemoveChip={() => setToDateURLParam(null)}
          />
        )}
      </div>
    </AdvancedSearchFilter>
  )
}
