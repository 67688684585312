import { graphql } from 'msw'

import {
  GetPurchaseOrderQuery,
  PurchaseOrderInvoicedStatusEnum,
  PurchaseOrderReceivedStatusEnum,
} from '@/graphql/purchasing/generated/purchasing_graphql'

const baseGetPurchaseOrder: GetPurchaseOrderQuery = {
  currentPurchaser: {
    id: 44926,
    purchaseOrder: {
      id: 16566710,
      purchaseOrderNumber: 'PO04331',

      department: {
        id: 85127,
        name: 'Beverage',
        __typename: 'Purchaser',
      },
      account: {
        id: 96391,
        accountName: 'Operating Account',
        code: 'Operating Account - 7',
        __typename: 'Account',
      },
      requisition: {
        id: 5271618,
        reference: 'test123abc',
        requisitionNumber: 'PR05479',
        __typename: 'Requisition',
      },
      deliveryChargeExTax: 25.55,
      deliveryChargeTax: 0,
      sentDate: null,
      invoicedStatus: PurchaseOrderInvoicedStatusEnum.FullyInvoiced,
      totalValueExcludingTax: 50.55,
      totalTaxValue: 1,
      totalValue: 51.55,
      sendToSupplier: true,
      expectedDeliveryDate: '2023-03-16',
      commentsCount: 0,
      deliveryAddress: {
        id: 1961,
        locationName: '',
        line1: 'test line 1 ',
        line2: 'test line 2',
        city: 'Hornsby',
        stateProvince: 'NSW',
        country: '',
        __typename: 'DeliveryAddress',
      },
      invoices: {
        edges: [
          {
            node: {
              id: 5913878,
              invoiceNumber: 'INV-0225',
              __typename: 'Invoice',
            },
            __typename: 'InvoiceEdge',
          },
        ],
        __typename: 'InvoiceConnection',
      },
      receivingDocuments: {
        edges: [
          {
            node: {
              id: 6659699,
              receivingDocumentNumber: 'GR02325',
              workflowState: 'open',
              __typename: 'ReceivingDocument',
            },
            __typename: 'ReceivingDocumentEdge',
          },
          {
            node: {
              id: 6659700,
              receivingDocumentNumber: 'GR02326',
              workflowState: 'open',
              __typename: 'ReceivingDocument',
            },
            __typename: 'ReceivingDocumentEdge',
          },
          {
            node: {
              id: 5213293,
              receivingDocumentNumber: 'GR02026',
              workflowState: 'open',
              __typename: 'ReceivingDocument',
            },
            __typename: 'ReceivingDocumentEdge',
          },
        ],
        __typename: 'ReceivingDocumentConnection',
      },
      deliveries: {
        edges: [],
        __typename: 'DeliveryConnection',
      },
      receivedStatus: PurchaseOrderReceivedStatusEnum.NotReceived,
      status: 'Not Sent',
      supplier: {
        id: 168,
        name: 'Zeus Wholesale',
        billingAddressCity: 'Surry Hills',
        billingAddressCountry: 'AU',
        billingAddressLine1: '1 Missenden',
        billingAddressLine2: 'Building 100 , Harbour bridge crescent street,',
        billingAddressPostalCode: '2011',
        billingAddressStateProvince: 'NSW',
        email: 'zeuswholesale@gmail.com',
        telephone: '+611300411014',
        deliveryChargeConfiguration: {
          minimumOrderValueExTax: 85.5,
          deliveryChargeExTax: 25.55,
          deliveryChargeIncTax: 25.55,
          deliveryChargeTaxPercentage: 0,
          __typename: 'DeliveryChargeConfiguration',
        },
        __typename: 'Supplier',
      },
      lines: {
        edges: [
          {
            node: {
              id: 60219623,
              quantity: 1,
              receivedQuantity: 1,
              unitPrice: 15,
              productId: 23,
              taxPercentage: 0,
              orderInstruction: null,
              productCode: null,
              lineTotal: 15,
              description: 'Radish Red 1 bunch each of 1',
              image: null,
              longDescription: null,
              supplierProductCode: null,
              receivedStatus: null,
              invoicedStatus: null,
              product: {
                id: 23,
                category: {
                  id: 109879,
                  name: 'Vegetables',
                  parent: {
                    id: 109460,
                    name: 'Fruit and Vegetables',
                    __typename: 'Category',
                  },
                  __typename: 'Category',
                },
                brand: '',
                itemDescription: 'Radish Red',
                concatenatedDescription: 'Radish Red 1 bunch',
                concatenatedSellUnit: 'each of 1',
                itemPackName: '',
                itemMeasure: 'bunch',
                itemSellPackName: 'each',
                itemSize: 1,
                __typename: 'Product',
              },
              department: null,
              account: null,
              __typename: 'PurchaseOrderLineItem',
            },
            __typename: 'PurchaseOrderLineItemEdge',
          },
          {
            node: {
              id: 60219624,
              quantity: 1,
              receivedQuantity: 1,
              unitPrice: 10,
              productId: 4756296,
              taxPercentage: 10,
              orderInstruction: 'please keep cool',
              productCode: null,
              lineTotal: 11,
              description: 'Akiko Gummy : Sour Berry Cocktail 18 g pack each of 1',
              image: null,
              longDescription: null,
              supplierProductCode: null,
              receivedStatus: null,
              invoicedStatus: null,
              product: {
                id: 4756296,
                category: {
                  id: 109517,
                  name: 'Chocolate and Confectionery',
                  parent: {
                    id: 109438,
                    name: 'Prepared Food and Meals',
                    __typename: 'Category',
                  },
                  __typename: 'Category',
                },
                brand: 'Akiko',
                itemDescription: 'Gummy : Sour Berry Cocktail',
                concatenatedDescription: 'Gummy : Sour Berry Cocktail 18 g pack',
                concatenatedSellUnit: 'each of 1',
                itemPackName: 'pack',
                itemMeasure: 'g',
                itemSellPackName: 'each',
                itemSize: 18,
                __typename: 'Product',
              },
              department: {
                id: 85127,
                name: 'Beverage',
                __typename: 'Purchaser',
              },
              account: {
                id: 123452,
                accountName: '0060008 - Operating Account - 8',
                code: '0060008',
                __typename: 'Account',
              },
              __typename: 'PurchaseOrderLineItem',
            },
            __typename: 'PurchaseOrderLineItemEdge',
          },
        ],
        __typename: 'PurchaseOrderLineItemConnection',
      },
      __typename: 'PurchaseOrder',
    },
    __typename: 'Purchaser',
  },
}

export const GetPurchaseOrder = graphql.query<GetPurchaseOrderQuery>('GetPurchaseOrder', (_, res, ctx) => {
  return res(ctx.data(baseGetPurchaseOrder))
})

export default baseGetPurchaseOrder
