import { Dispatch, SetStateAction } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useUpdateCataloguedProduct } from '../hooks/useUpdateCataloguedProduct'

import { Account, Purchaser } from '@/graphql/purchasing/generated/purchasing_graphql'
import { DeptAndAccount } from '@/modules/requisitions/pages/create/form-elements'
import { RequisitionFormInputs } from '@/modules/requisitions/types'
import { Button, Modal } from '@/modules/shared/components'

interface AddAccountCodeModalProps {
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<number | null>>
  cataloguedProductId: number
  department?: Purchaser
  account?: Account
}

export default function AddAccountCodeModal({
  cataloguedProductId,
  showModal,
  setShowModal,
  department,
  account,
}: AddAccountCodeModalProps) {
  const { t } = useTranslation()
  const formMethods = useForm<Pick<RequisitionFormInputs, 'accountId' | 'departmentId'>>()
  const [updateCataloguedProduct, { loading }] = useUpdateCataloguedProduct()

  const onCloseModal = () => {
    setShowModal(null)
  }

  const onSubmit: SubmitHandler<Pick<RequisitionFormInputs, 'accountId' | 'departmentId'>> = (data) => {
    updateCataloguedProduct({
      variables: {
        input: { ...data, id: cataloguedProductId },
      },
      onCompleted() {
        onCloseModal()
      },
    })
  }

  const onClearDepartmentAndAccount = () => {
    updateCataloguedProduct({
      variables: {
        input: { id: cataloguedProductId, departmentId: null, accountId: null },
      },
      onCompleted() {
        onCloseModal()
      },
    })
  }

  return (
    <Modal showModal={showModal} onCloseModal={onCloseModal}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Modal.Panel className="flex h-[60vh] w-full flex-col overflow-hidden rounded-md bg-white text-sm shadow-xl transition-all md:max-w-[600px]">
          <Modal.Title
            title={t('general.deptAndAccount', 'Department and Account Code')}
            onCloseModal={onCloseModal}
            testId="update-modal-title"
          />
          <Modal.Body className="pb-8">
            <FormProvider {...formMethods}>
              <DeptAndAccount
                title={t('createRequisition.deptAndAccount.title', 'Department and Account Code')}
                description={t(
                  'createRequisition.deptAndAccount.explain',
                  'If applicable, select a Department then an Account code to record this Requisition against. Your organisation may use Department and Account Codes for recording Requisition expenses.'
                )}
                defaultDepartmentValue={department}
                defaultAccountValue={account}
              />
            </FormProvider>
          </Modal.Body>
          <Modal.Footer className="flex flex-col-reverse items-center justify-between gap-2 md:flex-row">
            {department && account && (
              <Button
                type="button"
                className="h-11 w-full rounded-md bg-gray-200 px-8 text-sm md:w-fit"
                onClick={onClearDepartmentAndAccount}
                data-testid="clear-btn-bottom"
              >
                {t('general.clear', 'Clear')}
              </Button>
            )}
            <div className="flex w-full flex-col-reverse justify-end gap-2 md:flex-row">
              <Button
                type="button"
                className="h-11 w-full rounded-md bg-gray-200 px-8 text-sm md:w-fit"
                onClick={onCloseModal}
                data-testid="cancel-update-btn-bottom"
              >
                {t('general.cancel', 'Cancel')}
              </Button>
              <Button
                data-testid="save-changes-btn"
                type="submit"
                loading={loading}
                className="h-11 w-full rounded-md bg-primary px-5 text-sm text-white md:w-fit"
              >
                {t('general.saveChanges', 'Save Changes')}
              </Button>
            </div>
          </Modal.Footer>
        </Modal.Panel>
      </form>
    </Modal>
  )
}
