import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import UserStatus from '../UserStatus'

import { User } from '@/graphql/access/generated/access_graphql'
import { Fallback, MoreOptionsMenu, PPNewDetailLink } from '@/modules/shared/components'
import Table from '@/modules/shared/components/table/Table'
import { OptionIcon } from '@/modules/shared/icons'
import { Dates } from '@/modules/shared/types'
import { formatDate } from '@/modules/shared/utils'

interface TableViewProps {
  users: User[]
}

function TableView(props: TableViewProps) {
  const { users } = props
  const { t } = useTranslation()

  const renderUser = ({ fullName, id }: User) => {
    return (
      <PPNewDetailLink type="user" id={String(id)}>
        {fullName}
      </PPNewDetailLink>
    )
  }
  const renderEmailAddress = ({ email }: User) => {
    return email
  }
  const renderPhoneNumber = ({ telephone }: User) => {
    return (
      <Fallback as="p" className="break-words" condition={!!telephone}>
        {telephone}
      </Fallback>
    )
  }
  const renderLastSignIn = ({ lastSignInAt }: User) => {
    return (
      <>
        <p className="break-words">{formatDate(Dates.Short, lastSignInAt)}</p>
        <p className="break-words text-xs">{formatDate(Dates.Time, lastSignInAt)}</p>
      </>
    )
  }
  const renderStatus = ({ isDeleted }: User) => {
    return <UserStatus isDeleted={Boolean(isDeleted)} />
  }
  const renderMoreOptions = ({ id }: User) => {
    return (
      <MoreOptionsMenu showOpenInPPlus showHelp accessUserId={id}>
        <OptionIcon className="h-10 w-10 text-gray-500" />
      </MoreOptionsMenu>
    )
  }

  return (
    <Table
      dataTestId="all-users-table-view"
      dataSource={users}
      keyExtractor={(record) => String(record.id)}
      columns={[
        {
          title: t('systemSettings.details.user', 'User'),
          key: 'user',
          headerCellStyles: 'px-4 py-3',
          cellStyles: 'px-4 py-3',
          minWidth: 190,
          maxWidth: 220,
          render: renderUser,
        },
        {
          title: t('systemSettings.details.emailAddress', 'Email Address'),
          key: 'emailAddress',
          headerCellStyles: 'py-3',
          cellStyles: ' py-3',
          minWidth: 200,
          maxWidth: 320,
          render: renderEmailAddress,
        },
        {
          title: t('systemSettings.details.phoneNumber', 'Phone Number'),
          key: 'phoneNumber',
          headerCellStyles: 'px-4 py-3',
          cellStyles: ' px-4 py-3',
          minWidth: 200,
          grow: true,
          render: renderPhoneNumber,
        },
        {
          title: t('systemSettings.details.lastSignIn', 'Last Sign In'),
          key: 'lastSignIn',
          headerCellStyles: 'py-3 text-center',
          cellStyles: ' py-3 text-center',
          minWidth: 130,
          maxWidth: 130,
          render: renderLastSignIn,
        },
        {
          title: t('systemSettings.details.status', 'Status'),
          key: 'status',
          headerCellStyles: 'py-3 text-center',
          cellStyles: ' py-3 text-center',
          minWidth: 120,
          maxWidth: 120,
          render: renderStatus,
        },
        {
          title: t('systemSettings.details.more', 'More'),
          key: 'more',
          headerCellStyles: 'py-3 text-center',
          cellStyles: ' py-3 text-center',
          minWidth: 70,
          maxWidth: 70,
          render: renderMoreOptions,
        },
      ]}
    />
  )
}

export default memo(TableView)
