import classNames from 'classnames'
import { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import SupplierConnectModal from '../../supplier-directory/SupplierConnectModal'
import SupplierOptions from '../SupplierOptions'

import { Supplier, SupplierRelationship } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Button, PPNewDetailLink } from '@/modules/shared/components'
import Table from '@/modules/shared/components/table/Table'
import { OptionIcon } from '@/modules/shared/icons'

interface TableViewProps {
  suppliers: SupplierRelationship[]
}

function TableView(props: TableViewProps) {
  const { suppliers } = props
  const { t } = useTranslation()
  const [showConnect, setShowConnect] = useState<number | null>(null)

  const renderSupplier = (record: SupplierRelationship) => {
    return (
      <>
        <PPNewDetailLink type="supplier" id={String(record.id)}>
          {record.supplierName}
        </PPNewDetailLink>
        <p className="text-xs">{record.supplierNumber}</p>
      </>
    )
  }
  const renderContactInfo = ({ supplier }: SupplierRelationship) => {
    return (
      <>
        <p>{supplier?.telephone}</p>
        <p>{supplier?.email}</p>
      </>
    )
  }
  const renderRelationship = (record: SupplierRelationship) => {
    return (
      <>
        <Button
          className={classNames(`w-24 rounded-md py-3`, {
            'bg-success text-white': record.isEnabled,
            'bg-error text-white': !record.isEnabled,
          })}
          onClick={() => setShowConnect(record.supplierId)}
        >
          {record.isEnabled ? t('mySuppliers.enabled', 'Enabled') : t('mySuppliers.disabled', 'Disabled')}
        </Button>
        <SupplierConnectModal
          showModal={showConnect === record.supplierId}
          setShowModal={setShowConnect}
          supplier={{ ...record.supplier, supplierRelationship: { isEnabled: record.isEnabled } } as Supplier}
        />
      </>
    )
  }
  const renderMoreOptions = (record: SupplierRelationship) => {
    return (
      <SupplierOptions id={Number(record.id)}>
        <OptionIcon className="h-10 w-10 text-gray-500" />
      </SupplierOptions>
    )
  }

  return (
    <Table
      dataTestId="my-suppliers-table"
      dataSource={suppliers}
      keyExtractor={(record) => String(record.id)}
      columns={[
        {
          title: t('supplierDirectory.table.supplier', 'Supplier'),
          key: 'supplier',
          headerCellStyles: 'px-4 py-3',
          cellStyles: 'px-4 py-3',
          minWidth: 230,
          maxWidth: 280,
          render: renderSupplier,
        },
        {
          title: t('supplierDirectory.table.contactInfo', 'Contact Info'),
          key: 'contactInfo',
          headerCellStyles: 'px-4 py-3',
          cellStyles: 'px-4 py-3',
          minWidth: 230,
          grow: true,
          render: renderContactInfo,
        },

        {
          title: t('supplierDirectory.table.relationShip', 'Relationship'),
          key: 'relationship',
          headerCellStyles: 'px-0 py-3 text-center',
          cellStyles: 'px-0 py-3 text-center',
          minWidth: 120,
          maxWidth: 120,
          render: renderRelationship,
        },
        {
          title: t('supplierDirectory.table.more', 'More'),
          key: 'more',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 70,
          maxWidth: 70,
          render: renderMoreOptions,
        },
      ]}
    />
  )
}

export default memo(TableView)
