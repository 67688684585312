import { useQuery } from '@apollo/client'
import { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Link } from 'react-router-dom'
import { StringParam, useQueryParam, withDefault } from 'use-query-params'

import { Spinner } from '../../components'
import TableView from './table-view'
import TileView from './tile-view'

import ProductEmptyStateImg from '@/assets/images/product-empty-state.svg'
import {
  GetAllRequisitionsDocument,
  PageInfo,
  RansackDirection,
  Requisition,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { Breadcrumb, FilterTabs, MoreOptionsMenu, QueryResult, TitleBar, Tooltip } from '@/modules/shared/components'
import AdvancedSearch from '@/modules/shared/components/advanced-search/AdvancedSearch'
import EmptyState from '@/modules/shared/components/empty-state'
import SearchAndFilter, { OnFilter } from '@/modules/shared/components/search-input/SearchAndFilter'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { useWindowSize } from '@/modules/shared/hooks'
import { CartIcon, HomeIcon, OptionIcon, PlusIcon } from '@/modules/shared/icons'
import SearchContainer from '@/modules/shared/search-container'
import { Document } from '@/modules/shared/types'
import { FilterTabItem, QueryParameter } from '@/modules/shared/types'
import { checkNetworkStatus, extractEdges } from '@/modules/shared/utils'

export default function AllRequisitions() {
  const { t } = useTranslation()
  const { isLargeScreen } = useWindowSize()

  const [filterURLParam] = useQueryParam(QueryParameter.Status, withDefault(StringParam, 'all'))
  const [fromDateURLParam] = useQueryParam(QueryParameter.From, StringParam)
  const [toDateURLParam] = useQueryParam(QueryParameter.To, StringParam)
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(!!fromDateURLParam || !!toDateURLParam)
  const [loadingType, setLoadingType] = useState<string | null>(null)
  const paginationResultsPerPage = 20
  const { data, networkStatus, error, refetch, fetchMore } = useQuery(GetAllRequisitionsDocument, {
    variables: {
      first: paginationResultsPerPage,
      after: null,
      filter: {},
      sort: [
        {
          property: 'expectedDeliveryDate',
          direction: RansackDirection.Desc,
        },
      ],
    },
    context: { uri: PURCHASING_GRAPHQL_API },
    notifyOnNetworkStatusChange: true,
  })

  const { setVariablesLoading, refetchLoading, loading } = checkNetworkStatus(networkStatus)

  const filterTabs: FilterTabItem[] = [
    {
      key: t('allRequisitions.filter.viewAll', 'View All'),
      // Not an actual state
      filterValue: 'all',
    },
    {
      key: t('allRequisitions.filter.open', 'Open'),
      filterValue: 'open',
    },
    {
      key: t('allRequisitions.filter.awaitingApproval', 'Awaiting Approval'),
      filterValue: 'awaiting_approval',
    },
    {
      key: t('allRequisitions.filter.awaitingMyApproval', 'Awaiting My Approval'),
      // Not an actual state
      filterValue: 'awaiting_my_approval',
      target: '_blank',
    },
    {
      key: t('allRequisitions.filter.approved', 'Approved'),
      filterValue: 'approved',
    },
    {
      key: t('allRequisitions.filter.declined', 'Declined'),
      filterValue: 'declined',
    },
    {
      key: t('allRequisitions.filter.closed', 'Closed'),
      filterValue: 'closed',
    },
    {
      key: t('allRequisitions.filter.cancelled', 'Cancelled'),
      filterValue: 'cancelled',
    },
  ]

  const requisitions = extractEdges<Requisition>(data?.currentPurchaser?.requisitions)

  const onFilter = ({ searchValue, filterValue, fromDate, toDate }: OnFilter) => {
    setLoadingType('filter')
    refetch({
      filter: {
        q: [
          {
            property: 'requisitionNumber_or_reference_cont',
            value: searchValue.trim(),
          },
          {
            property: 'state_eq',
            value: filterValue === 'all' ? '' : filterValue,
          },
          {
            property: 'expected_delivery_date_gt',
            value: fromDate,
          },
          {
            property: 'expected_delivery_date_lt',
            value: toDate,
          },
        ],
      },
      first: paginationResultsPerPage, // Reset the pagination to the first page
      after: null, // Reset the pagination to the first page
    })
  }

  const onPreviousPage = () => {
    setLoadingType('onPreviousPage')
    const { startCursor } = data?.currentPurchaser?.requisitions?.pageInfo || {}
    refetch({
      before: startCursor,
      after: null,
      last: paginationResultsPerPage,
      first: null,
    })
  }

  const onNextPage = () => {
    setLoadingType('onNextPage')
    const { endCursor } = data?.currentPurchaser?.requisitions?.pageInfo || {}
    refetch({
      after: endCursor,
      before: null,
      first: paginationResultsPerPage,
      last: null,
    })
  }

  const onFetchMoreRequisitions = () => {
    fetchMore({ variables: { after: data?.currentPurchaser?.requisitions?.pageInfo.endCursor } })
  }

  return (
    <>
      <Helmet>
        <title>{t('allRequisitions.pageTitle', 'All Requisitions')}</title>
      </Helmet>

      <main className="mx-auto space-y-5 px-4">
        <Breadcrumb className="overflow-x-auto rounded-md bg-gray-200 px-3 py-2">
          <Breadcrumb.Item icon={HomeIcon} href="/dashboard"></Breadcrumb.Item>
          <Breadcrumb.Item href="/requisitions">{t('breadcrumbs.requisitions', 'Requisitions')}</Breadcrumb.Item>
          <Breadcrumb.Item>{filterTabs.find((e) => e.filterValue === filterURLParam)?.key}</Breadcrumb.Item>
        </Breadcrumb>
        <section className="space-y-2">
          <TitleBar
            icon={<CartIcon className="h-10 w-10" />}
            title={t('allRequisitions.pageTitle', 'All Requisitions')}
            subtitle={t('allRequisitions.viewAllRequisitions', 'View All Requisitions')}
          >
            <div className="flex items-center gap-x-2">
              <Tooltip content={t('createRequisition.createTooltip', 'Create a New Requisition')}>
                <Link to="/requisitions/create">
                  <PlusIcon className="h-11 w-11 cursor-pointer rounded-full bg-gray-200 p-1 text-gray-600" />
                </Link>
              </Tooltip>
              <MoreOptionsMenu showHelp showOpenInPPlus allRequisitions>
                <OptionIcon className="h-11 w-11 rounded-full bg-gray-200 p-1.5 text-gray-600 outline-none hover:brightness-95" />
              </MoreOptionsMenu>
            </div>
          </TitleBar>
          <FilterTabs tabs={filterTabs} />
        </section>
        <SearchContainer showAdvancedSearch={showAdvancedSearch} setShowAdvancedSearch={setShowAdvancedSearch}>
          <SearchAndFilter
            testId="requisitions-search-input"
            placeholder={t('allRequisitions.searchPlaceholder', 'Search by PR Number or Reference')}
            ariaLabel={t('allRequisitions.ariaLabel', 'Searching for requisitions')}
            onFilter={onFilter}
            queryParamFilterType={QueryParameter.Status}
          />
        </SearchContainer>
        {showAdvancedSearch && <AdvancedSearch type={Document.Requisition} />}
        <section>
          <QueryResult
            loading={loading || refetchLoading || (setVariablesLoading && loadingType === 'filter')}
            error={error}
          >
            {data?.currentPurchaser && (
              <>
                {requisitions.length > 0 && (
                  <>
                    {isLargeScreen ? (
                      <TableView
                        requisitions={requisitions.slice(0, paginationResultsPerPage)}
                        pageInfo={data?.currentPurchaser?.requisitions?.pageInfo as PageInfo}
                        paginationLoading={
                          setVariablesLoading && ['onPreviousPage', 'onNextPage'].includes(String(loadingType))
                        }
                        onNextPage={onNextPage}
                        onPreviousPage={onPreviousPage}
                      />
                    ) : (
                      <InfiniteScroll
                        dataLength={requisitions.length}
                        next={onFetchMoreRequisitions}
                        hasMore={!!data?.currentPurchaser?.requisitions?.pageInfo.hasNextPage}
                        loader={<Spinner className="mt-5 h-14 md:w-16" />}
                      >
                        <TileView requisitions={requisitions} />
                      </InfiniteScroll>
                    )}
                  </>
                )}
                {!setVariablesLoading && requisitions.length === 0 && (
                  <EmptyState
                    img={ProductEmptyStateImg}
                    message={t('allRequisitions.noPRs', "We couldn't find any Requisitions to display.")}
                  />
                )}
              </>
            )}
          </QueryResult>
        </section>
        <div className="h-96" />
      </main>
    </>
  )
}
