import { graphql } from 'msw'

import { GetSupplierRelationshipsQuery } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseGetSupplierRelationships: GetSupplierRelationshipsQuery = {
  currentPurchaser: {
    id: 44926,
    supplierRelationships: {
      edges: [
        {
          node: {
            id: 7234,
            isEnabled: false,
            supplierName: '01 Computer System Pty Ltd',
            supplierNumber: '121233424',
            supplierId: 1000,
            supplier: {
              id: 1000,
              telephone: '+65 63092711',
              email: 'internal@marketboomer.com',
              name: '01 Computer System Pte Ltd',
              creditApplicationUrl: null,
              businessNumber: '',
              __typename: 'Supplier',
            },
            __typename: 'SupplierRelationship',
          },
          __typename: 'SupplierRelationshipEdge',
        },
        {
          node: {
            id: 1235,
            isEnabled: true,
            supplierName: 'Random Company',
            supplierNumber: '532153',
            supplierId: 1001,
            supplier: {
              id: 1001,
              telephone: '+65 63092712',
              email: 'internalA@marketboomer.com',
              name: '01 Computer System Pte Ltd',
              creditApplicationUrl: null,
              businessNumber: '',
              __typename: 'Supplier',
            },
            __typename: 'SupplierRelationship',
          },
          __typename: 'SupplierRelationshipEdge',
        },
        {
          node: {
            id: 3211,
            isEnabled: false,
            supplierName: 'Zeus Wholesale',
            supplierNumber: '89412928',
            supplierId: 1002,
            supplier: {
              id: 1002,
              telephone: '+65 63092715',
              email: 'internalE@marketboomer.com',
              name: '01 Computer System Pte Ltd',
              creditApplicationUrl: null,
              businessNumber: '',
              __typename: 'Supplier',
            },
            __typename: 'SupplierRelationship',
          },
          __typename: 'SupplierRelationshipEdge',
        },
      ],
      pageInfo: {
        endCursor: 'MjU',
        hasNextPage: false,
        __typename: 'PageInfo',
      },
      __typename: 'SupplierRelationshipConnection',
    },
    __typename: 'Purchaser',
  },
}

export const GetSupplierRelationships = graphql.query<GetSupplierRelationshipsQuery>(
  'GetSupplierRelationships',
  (_, res, ctx) => {
    return res(ctx.data(baseGetSupplierRelationships))
  }
)

export default baseGetSupplierRelationships
