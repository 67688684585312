import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useParams } from 'react-router-dom'
import { StringParam, useQueryParam } from 'use-query-params'

import SelfManagedProductsTableView from './table-view/SelfManagedProductsTableView'
import TileView from './tile-view'

import SelfManagedCatalogProductsEmptyStateImg from '@/assets/images/empty-self-managed-catalogs.svg'
import {
  GetPricedCatalogueDocument,
  PricedCataloguedProduct,
  RansackDirection,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { Spinner } from '@/modules/requisitions/components'
import { QueryResult } from '@/modules/shared/components'
import EmptyState from '@/modules/shared/components/empty-state'
import SearchAndFilter from '@/modules/shared/components/search-input/SearchAndFilter'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { useWindowSize } from '@/modules/shared/hooks'
import { QueryParameter } from '@/modules/shared/types'
import { checkNetworkStatus, extractEdges } from '@/modules/shared/utils'

export default function SelfManagedProducts() {
  const { t } = useTranslation()
  const { isLargeScreen } = useWindowSize()

  const { pricedCatalogueId } = useParams<{ pricedCatalogueId: string }>()
  const [searchTermURLParam] = useQueryParam(QueryParameter.Search, StringParam)

  const { data, error, networkStatus, fetchMore } = useQuery(GetPricedCatalogueDocument, {
    variables: {
      pricedCatalogueId: Number(pricedCatalogueId),
      after: null,
      filter: {
        q: [
          {
            property: 'product_itemDescription_cont',
            value: searchTermURLParam || '',
          },
        ],
      },
      sort: [
        {
          property: 'product_itemDescription',
          direction: RansackDirection.Asc,
        },
      ],
    },
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  })

  const { setVariablesLoading, loading } = checkNetworkStatus(networkStatus)

  const products = extractEdges<PricedCataloguedProduct>(
    data?.currentPurchaser?.pricedCatalogue?.pricedCataloguedProducts
  )

  const onFetchMorePricedCatalogs = () => {
    fetchMore({
      variables: {
        after: data?.currentPurchaser?.pricedCatalogue?.pricedCataloguedProducts?.pageInfo.endCursor,
      },
    })
  }

  return (
    <section className="flex flex-col gap-y-5">
      <SearchAndFilter
        testId="self-managed-catalogs-search-input"
        placeholder={t('catalogs.searchSelfManagedCatalogPlaceholder', 'Search by Product Name')}
        ariaLabel={t('catalogs.searchSelfManagedCatalogPlaceholder', 'Search by Product Name')}
      />
      <QueryResult loading={loading || setVariablesLoading} error={error}>
        <InfiniteScroll
          dataLength={products.length}
          next={onFetchMorePricedCatalogs}
          hasMore={!!data?.currentPurchaser?.pricedCatalogue?.pricedCataloguedProducts?.pageInfo.hasNextPage}
          loader={<Spinner className="mt-5 h-14 md:w-16" />}
        >
          {isLargeScreen ? <SelfManagedProductsTableView products={products} /> : <TileView products={products} />}
        </InfiniteScroll>
        {products.length === 0 && (
          <EmptyState
            message={t('catalogs.selfManagedCatalogEmptyState', 'There are no products to display.')}
            img={SelfManagedCatalogProductsEmptyStateImg}
          />
        )}
      </QueryResult>
    </section>
  )
}
