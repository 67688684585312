import { useTranslation } from 'react-i18next'

import SelectAdvanced from './form-elements/SelectAdvanced'

import { Account, Purchaser } from '@/graphql/purchasing/generated/purchasing_graphql'
import { useCurrentOrganisation } from '@/modules/purchasing/hooks'
import { DeptAndAccount, SelectAddress, SendPurchaseOrder } from '@/modules/requisitions/pages/create/form-elements'
import FormDate from '@/modules/shared/components/create-form/FormDate'

export default function AllSuppliers() {
  const { t } = useTranslation()
  const { currentOrganisation } = useCurrentOrganisation()

  return (
    <>
      <SendPurchaseOrder
        label={t('createRequisition.sendPO.label', 'Send Purchase Orders to Suppliers')}
        title={t('createRequisition.sendPO.title', 'Send Purchase Orders to Suppliers?')}
        description={t(
          'createRequisition.sendPO.explain',
          "Decide if you'd like a Purchase Order to be sent to each Supplier once the Requisition has been fully approved, and the Purchase Orders are marked as Sent. If you leave this option un-checked, Purchase Orders will still be generated for each Supplier, but they will not be electronically sent to the Supplier, even when the Purchase Order is marked as Sent. There may be reasons why you don't need the Supplier to receive it, for example you may have phoned ahead and placed your order and you don't wish to send the Supplier a duplicate order."
        )}
      />
      <SelectAddress
        title={t('createRequisition.address.title', 'Delivery Address')}
        description={t(
          'createRequisition.address.explain',
          'Select a delivery address from the list below. Additional delivery addresses can be added by an administrator at your organisation. Please speak with your administrator to request additional delivery addresses.'
        )}
      />
      <FormDate
        title={t('createRequisition.deliveryDate.title', 'Delivery Date')}
        description={t(
          'createRequisition.deliveryDate.explain',
          "Please select the date you'd like to take delivery of this order. This date will be included on all Purchase Orders that are created when this Requisition is submitted and fully approved. The date will be visible to Suppliers and anyone at your organisation."
        )}
        register="expectedDeliveryDate"
      />
      <DeptAndAccount
        defaultDepartmentValue={currentOrganisation as Purchaser}
        defaultAccountValue={currentOrganisation?.defaultAccount as Account}
        title={t('createRequisition.deptAndAccount.title', 'Department and Account Code')}
        description={t(
          'createRequisition.deptAndAccount.explain',
          'If applicable, select a Department then an Account code to record this Requisition against. Your organisation may use Department and Account Codes for recording Requisition expenses.'
        )}
      />
      <SelectAdvanced />
    </>
  )
}
