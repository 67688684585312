import { Dispatch, SetStateAction } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Account, DeliveryAddress, Purchaser, Requisition } from '@/graphql/purchasing/generated/purchasing_graphql'
import { useUpdateRequisition } from '@/modules/requisitions/hooks'
import { DeptAndAccount, SelectAddress, SendPurchaseOrder } from '@/modules/requisitions/pages/create/form-elements'
import { RequisitionFormInputs } from '@/modules/requisitions/types'
import { Button, Modal } from '@/modules/shared/components'
import FormDate from '@/modules/shared/components/create-form/FormDate'
import FormInput from '@/modules/shared/components/create-form/FormInput'

interface EditRequisitionModalProps {
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<boolean>>
  requisition: Requisition
}

function EditRequisitionModal(props: EditRequisitionModalProps) {
  const { showModal, setShowModal, requisition } = props
  const {
    requisitionNumber,
    reference,
    sendToSupplier,
    expectedDeliveryDate,
    department,
    account,
    deliveryAddress,
    id: requisitionId,
  } = requisition
  const { t } = useTranslation()

  const formMethods = useForm<RequisitionFormInputs>({
    defaultValues: {
      reference: String(reference),
      sendToSupplier: Boolean(sendToSupplier),
      expectedDeliveryDate: String(expectedDeliveryDate),
      deliveryAddressId: deliveryAddress?.id,
      departmentId: department?.id,
      accountId: account?.id,
    },
  })

  const [updateRequistion, { loading }] = useUpdateRequisition()

  const onCloseModal = () => {
    formMethods.reset()
    setShowModal(false)
  }

  const onSubmit: SubmitHandler<RequisitionFormInputs> = (data) => {
    updateRequistion({
      variables: {
        input: {
          id: requisitionId,
          ...data,
        },
      },
      onCompleted(data) {
        const requisition = data.updateRequisition.requisition
        formMethods.reset({
          reference: String(requisition?.reference),
          sendToSupplier: Boolean(requisition?.sendToSupplier),
          expectedDeliveryDate: String(requisition?.expectedDeliveryDate),
          deliveryAddressId: requisition?.deliveryAddress?.id,
          departmentId: requisition?.department?.id,
          accountId: requisition?.account?.id,
        })
        setShowModal(false)
      },
    })
  }

  return (
    <Modal showModal={showModal} onCloseModal={onCloseModal}>
      <Modal.Panel
        as="form"
        onSubmit={formMethods.handleSubmit(onSubmit)}
        className="flex h-[80vh] w-full flex-col overflow-hidden rounded-md bg-white shadow-xl transition-all md:max-w-[600px]"
      >
        <Modal.Title
          title={t('shopPage.cart.settings.requisitionSettings', '{{ requisitionNumber }} Settings', {
            requisitionNumber,
          })}
          onCloseModal={onCloseModal}
        />

        <div className="flex-1 overflow-y-scroll p-5">
          <FormInput
            title={t('createRequisition.reference.label', 'Reference')}
            description={t(
              'createRequisition.reference.explain',
              'Please include a brief description of the Purchase Requisition. This reference will appear on all Purchase Orders that are created when this Requisition is submitted and fully approved. The reference will be visible to Suppliers and anyone at your organisation.'
            )}
            register="reference"
            placeholder={t('createRequisition.reference.placeholder', 'E.g. Beverage Order')}
          />
          <FormProvider {...formMethods}>
            <SendPurchaseOrder
              label={t('createRequisition.sendPO.label', 'Send Purchase Orders to Suppliers')}
              defaultValue={Boolean(sendToSupplier)}
              title={t('createRequisition.sendPO.title', 'Send Purchase Orders to Suppliers?')}
              description={t(
                'createRequisition.sendPO.explain',
                "Decide if you'd like a Purchase Order to be sent to each Supplier once the Requisition has been fully approved, and the Purchase Orders are marked as Sent. If you leave this option un-checked, Purchase Orders will still be generated for each Supplier, but they will not be electronically sent to the Supplier, even when the Purchase Order is marked as Sent. There may be reasons why you don't need the Supplier to receive it, for example you may have phoned ahead and placed your order and you don't wish to send the Supplier a duplicate order."
              )}
            />
            <SelectAddress
              defaultValue={deliveryAddress as DeliveryAddress}
              title={t('createRequisition.address.title', 'Delivery Address')}
              description={t(
                'createRequisition.address.explain',
                'Select a delivery address from the list below. Additional delivery addresses can be added by an administrator at your organisation. Please speak with your administrator to request additional delivery addresses.'
              )}
            />
            <FormDate
              defaultDate={expectedDeliveryDate}
              title={t('createRequisition.deliveryDate.title', 'Delivery Date')}
              description={t(
                'createRequisition.deliveryDate.explain',
                "Please select the date you'd like to take delivery of this order. This date will be included on all Purchase Orders that are created when this Requisition is submitted and fully approved. The date will be visible to Suppliers and anyone at your organisation."
              )}
              register="expectedDeliveryDate"
            />
            <DeptAndAccount
              defaultAccountValue={account as Account}
              defaultDepartmentValue={department as Purchaser}
              title={t('createRequisition.deptAndAccount.title', 'Department and Account Code')}
              description={t(
                'createRequisition.deptAndAccount.explain',
                'If applicable, select a Department then an Account code to record this Requisition against. Your organisation may use Department and Account Codes for recording Requisition expenses.'
              )}
            />
          </FormProvider>
          <div className="h-96" />
        </div>
        <Modal.Footer className="flex flex-col-reverse items-center justify-end gap-2 md:flex-row">
          <Button
            type="button"
            className="h-11 w-full rounded-md bg-gray-200 px-8 text-sm md:w-fit"
            onClick={onCloseModal}
          >
            {t('shopPage.cart.settings.cancelModalBtn', 'Cancel')}
          </Button>
          <Button
            data-testid="save-changes-requisition-btn"
            type="submit"
            className="h-11 w-full rounded-md bg-primary px-5 text-sm text-white md:w-fit"
            loading={loading}
            disabled={!formMethods.formState.isDirty}
          >
            {t('shopPage.cart.settings.saveChangesModalBtn', 'Save Changes')}
          </Button>
        </Modal.Footer>
      </Modal.Panel>
    </Modal>
  )
}

export default EditRequisitionModal
