import { useQuery } from '@apollo/client'
import classNames from 'classnames'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import {
  DeliveryChargeConfiguration,
  GetSupplierDocument,
  Supplier,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { DeliveryFees } from '@/modules/requisitions/components'
import { formatSupplierBillingAddress } from '@/modules/requisitions/utils'
import { formatSupplierPostalAddress } from '@/modules/requisitions/utils/formatSupplierPostalAddress'
import { Button, QueryResult, TextWithFallback } from '@/modules/shared/components'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { NewTabIcon } from '@/modules/shared/icons'
import SupplierConnectModal from '@/modules/suppliers/pages/supplier-directory/SupplierConnectModal'
import {
  handleSupplierConnectModalText,
  handleSupplierDirectoryText,
} from '@/modules/suppliers/pages/supplier-directory/SupplierDirectoryUtils'

export default function SupplierPublicDetails() {
  const { t } = useTranslation()
  const { supplierId } = useParams<{ supplierId: string }>()
  const [showConnect, setShowConnect] = useState<number | null>(null)

  const { data, loading, error } = useQuery(GetSupplierDocument, {
    variables: {
      supplierId: Number(supplierId),
    },
    context: { uri: PURCHASING_GRAPHQL_API },
  })

  const supplier = data?.supplier
  const { supplierRelationship, name, telephone, email, businessNumber, deliveryChargeConfiguration } = supplier || {}

  const { supplierTitle, supplierInfo } = handleSupplierConnectModalText(supplier as Supplier)

  return (
    <QueryResult loading={loading} error={error}>
      <div className="flex flex-col justify-between gap-10 xl:flex-row">
        <section className="flex w-full flex-col gap-y-5 text-sm xl:w-[840px]">
          <section>
            <div className="my-3 flex justify-between">
              <p className="text-lg font-bold">{t('mySuppliers.supplier.supplierDetails', 'Supplier Details')}</p>
            </div>
            <hr />
          </section>
          <section data-testid="supplier-public-trading-status">
            <p className="font-bold">{supplierTitle}</p>
            <p className="text-gray-500">{supplierInfo}</p>
            <Button
              className={classNames('mt-3 w-24 rounded-md py-3', {
                'bg-gray-200': !supplierRelationship,
                'bg-error text-white': !supplierRelationship?.isEnabled && supplierRelationship,
                'bg-success text-white': supplierRelationship?.isEnabled,
              })}
              data-testid="supplier-public-status-button"
              onClick={() => setShowConnect(Number(supplierId))}
            >
              {handleSupplierDirectoryText(supplier as Supplier)}
            </Button>
          </section>
          {!supplierRelationship && (
            <section>
              <p className="font-bold">{t('supplierDirectory.connectModal.connectCredit', 'Credit Application')}</p>
              <p className="text-gray-500">
                {t(
                  'supplierDirectory.connectModal.connectCreditInfo',
                  'The Supplier can choose to provide a link here to their Credit Application Form. If a link has been provided, you can follow the link, complete the form and submit it directly to the Supplier to get your trade relationship initiated.'
                )}
              </p>
              {supplier?.creditApplicationUrl ? (
                <a
                  href={supplier?.creditApplicationUrl}
                  target="_blank"
                  rel="noreferrer"
                  className="mt-3 flex cursor-pointer items-center justify-between rounded-md border border-primary bg-white p-4 text-primary"
                >
                  <p>{t('supplierDirectory.connectModal.connectCreditLink', 'Visit Credit Application Link')}</p>
                  <NewTabIcon className="h-6 w-6" />
                </a>
              ) : (
                <div className="mt-2 w-full rounded-md border bg-gray-200 px-3 py-5 text-center text-gray-500">
                  {t(
                    'supplierDirectory.connectModal.connectCreditNoLink',
                    'The Supplier has not provided a link. Please contact the Supplier.'
                  )}
                </div>
              )}
            </section>
          )}
        </section>
        <section className="flex w-full flex-col gap-y-4 text-sm xl:w-96">
          <section className="rounded-md bg-white p-5 shadow-sm" data-testid="supplier-public-details">
            <h2 className="mb-5 text-center text-lg font-bold">{name}</h2>
            <div className="flex flex-col gap-y-4">
              <TextWithFallback
                title={t('mySuppliers.supplier.billingAddress', 'Billing Address')}
                value={formatSupplierBillingAddress(supplier as Supplier)}
              />
              <TextWithFallback
                title={t('mySuppliers.supplier.postalAddress', 'Postal Address')}
                value={formatSupplierPostalAddress(supplier as Supplier)}
              />
              <TextWithFallback title={t('mySuppliers.supplier.phone', 'Contact Phone')} value={telephone} />
              <TextWithFallback title={t('mySuppliers.supplier.email', 'Contact Email')} value={email} />
              <TextWithFallback
                title={t('mySuppliers.supplier.businessNumber', 'Business Number')}
                value={businessNumber}
              />
            </div>
          </section>
          <section className="rounded-md bg-white p-5 shadow-sm" data-testid="supplier-public-delivery">
            <p className="font-bold">{t('shopPage.cart.summary.details.deliveryFees', 'Delivery Fees')}</p>
            <p>{t('mySuppliers.supplier.byTotalOrder', 'By total order value, excluding tax.')}</p>
            <DeliveryFees deliveryChargeConfiguration={deliveryChargeConfiguration as DeliveryChargeConfiguration} />
            <p className="text-xs text-gray-500">
              {t(
                'mySuppliers.supplier.deliveryFeeInfo',
                'Delivery fees are set by the Supplier based on your trading agreement. Delivery fees will be automatically applied to a Purchase Order. Delivery fees shown here are a general guide and the Supplier reserves the right to charge additional delivery fees based on other location or product criteria such as weight or volume of certain products.'
              )}
            </p>
          </section>
        </section>
        <SupplierConnectModal
          showModal={Boolean(showConnect)}
          setShowModal={setShowConnect}
          supplier={supplier as Supplier}
        />
      </div>
    </QueryResult>
  )
}
