import { t } from 'i18next'
import { Link, generatePath } from 'react-router-dom'

import { Activity, PurchaseOrder, Requisition } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Fallback } from '@/modules/shared/components'
import { NewTabIcon } from '@/modules/shared/icons'
import { Dates } from '@/modules/shared/types'
import { extractEdges, formatDate } from '@/modules/shared/utils'

interface ApprovedBodyProps {
  requisition: Requisition
  approveActivity: Activity
}

export default function ApprovedBody({ requisition, approveActivity }: ApprovedBodyProps) {
  return (
    <>
      <section>
        <p className="font-bold">{t('requisition.statusModal.approvedBy', 'Approved By')}</p>
        <p className="text-gray-500">
          {t('requisition.statusModal.approvedByBody', 'The user who granted final approval for this Requisition.')}
        </p>
        <div className="mt-2 flex w-full flex-col justify-between gap-x-2 rounded-md border bg-gray-100 px-3 py-4 sm:flex-row">
          <Fallback condition={!!approveActivity}>
            <span>{approveActivity?.user?.fullName}</span>
            <span className="text-gray-500">{formatDate(Dates.Long, approveActivity?.createdAt)}</span>
          </Fallback>
        </div>
      </section>
      <section>
        <p className="font-bold">{t('requisition.statusModal.purchaseOrders', 'Purchase Orders')}</p>
        <p className="text-gray-500">
          {t(
            'requisition.statusModal.purchaseOrdersBody',
            'The Purchase Orders that were created when this Requisition was Approved.'
          )}
        </p>
        <div className="mt-2 flex w-full flex-col divide-y rounded-md border bg-gray-100">
          {extractEdges<PurchaseOrder>(requisition?.purchaseOrders).map((purchaseOrder) => (
            <div className="flex flex-col justify-between gap-x-2 px-3 py-4 sm:flex-row" key={purchaseOrder.id}>
              <Link
                to={generatePath('/purchase-orders/:purchaseOrderId/summary', {
                  purchaseOrderId: String(purchaseOrder.id),
                })}
                className="flex cursor-pointer items-center gap-x-2 text-primary"
              >
                {purchaseOrder.purchaseOrderNumber} <NewTabIcon className="h-5 w-5" />
              </Link>
              <span className="text-gray-500">{purchaseOrder.status}</span>
            </div>
          ))}
        </div>
      </section>
    </>
  )
}
