import { Dispatch, SetStateAction } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import BalanceTableRow from './BalanceTableRow'
import VarianceDisplay from './VarianceDisplay'

import { Invoice } from '@/graphql/purchasing/generated/purchasing_graphql'
import { useBalanceModalCalculations } from '@/modules/invoices/hooks'
import { Button, Modal } from '@/modules/shared/components'

interface BalanceModalProps {
  invoice: Invoice
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<boolean>>
}

export default function BalanceModal({ invoice, showModal, setShowModal }: BalanceModalProps) {
  const { t } = useTranslation()
  const { lineTotal, lineTaxTotal } = invoice

  const {
    balanceModalState,
    onSetState,
    onSubmitBalance,
    onCloseModal,
    onCalculateTotals,
    totalExTaxVariance,
    totalTaxVariance,
    totalVariance,
    totalHasVariance,
    lineExTax,
    deliveryChargeTotal,
    adjustmentTotal,
    totalTotal,
    loading,
  } = useBalanceModalCalculations({ invoice, setShowModal })

  return (
    <Modal showModal={showModal} onCloseModal={onCloseModal}>
      <Modal.Panel
        as="div"
        className="flex w-full flex-col overflow-hidden rounded-md bg-white shadow-xl transition-all md:max-w-[900px]"
      >
        <Modal.Title title={t('invoices.invoices.balance.updateValues', 'Update Values')} onCloseModal={onCloseModal} />
        <Modal.Body className="text-sm">
          <p className="font-bold">{t('invoices.invoices.balance.updateValues', 'Update Values')}</p>
          <p className="text-gray-500">
            <Trans t={t} i18nKey="invoices.invoice.balance.modal.description">
              Here you can update the values within the Invoice that are directly editable. The Invoice lines appear
              here to show the calculated sum of each Invoice line, but these values only update when a change is made
              to an Invoice line. You can update the Delivery Fee fields, the Adjustment fields and you can also update
              the Invoice totals, or use the calculate option to pre-fill these values automatically. You can approve an
              Invoice when the totals do not add to the sum of each part, but it is not recommended.
            </Trans>
          </p>
          <div className="my-5 border">
            <div className="flex justify-between border-b text-xs text-gray-500">
              <div className="px-4 py-2.5 text-left">{t('invoices.invoice.balance.modal.table.item', 'Item')}</div>
              <div className="flex items-center gap-x-3">
                <div className="w-28 pr-6 text-right">
                  {t('invoices.invoice.balance.modal.table.exTaxTotal', 'Ex Tax Total')}
                </div>
                <div className="w-28 pr-6 text-right">{t('invoices.invoice.balance.modal.table.tax', 'Tax')}</div>
                <div className="w-28 pr-6 text-right">{t('invoices.invoice.balance.modal.table.total', 'Total')}</div>
              </div>
            </div>
            <div className="flex justify-between border-b px-4 py-3" data-testid="line-row">
              <BalanceTableRow
                title={t('invoices.invoice.balance.modal.table.invoiceLines', 'Invoice Lines')}
                tooltip={t(
                  'invoices.invoice.balance.modal.table.invoiceLinesTooltip',
                  'These values show the calculated total of all of the Invoice Lines. These values are updated automatically if a change is made to any of the Invoice Lines. They cannot be updated here.'
                )}
                inputs={[
                  {
                    id: 1,
                    value: Number(lineExTax).toFixed(2),
                    ariaLabel: t(
                      'invoices.invoice.balance.modal.table.lineExTaxAria',
                      'Invoice lines total excluding tax'
                    ),
                    isDisabled: true,
                  },
                  {
                    id: 2,
                    value: Number(lineTaxTotal).toFixed(2),
                    ariaLabel: t('invoices.invoice.balance.modal.table.lineTaxTotalAria', 'Invoices lines total tax'),
                    isDisabled: true,
                  },
                  {
                    id: 3,
                    value: Number(lineTotal).toFixed(2),
                    ariaLabel: t('invoices.invoice.balance.modal.table.lineTotalAria', 'Invoice lines total'),
                    isDisabled: true,
                  },
                ]}
              />
            </div>
            <div className="flex justify-between border-b px-4 py-3" data-testid="delivery-row">
              <BalanceTableRow
                title={t('invoices.invoice.balance.modal.table.deliveryCharge', 'Delivery Fees')}
                tooltip={t(
                  'invoices.invoice.balance.modal.table.deliveryFeesTooltip',
                  'These values are used to show the Delivery Fees applicable on the Invoice, if any. These values are set when the Invoice is created and you can make changes to these values here if needed.'
                )}
                inputs={[
                  {
                    id: 1,
                    value: balanceModalState.deliveryChargeExTax,
                    ariaLabel: t(
                      'invoices.invoice.balance.modal.table.deliveryChargeExTaxAria',
                      'Delivery charge excluding tax'
                    ),
                    onChange: (value) => onSetState('deliveryChargeExTax', value),
                  },
                  {
                    id: 2,
                    value: balanceModalState.deliveryChargeTax,
                    ariaLabel: t('invoices.invoice.balance.modal.table.deliveryChargeTaxAria', 'Delivery charge tax'),
                    onChange: (value) => onSetState('deliveryChargeTax', value),
                  },
                  {
                    id: 3,
                    value: deliveryChargeTotal,
                    ariaLabel: t(
                      'invoices.invoice.balance.modal.table.deliveryChargeTotalAria',
                      'Delivery charge total'
                    ),
                    isDisabled: true,
                  },
                ]}
              />
            </div>
            <div className="flex justify-between px-4 py-3" data-testid="adjustment-row">
              <BalanceTableRow
                title={t('invoices.invoice.balance.modal.table.adjustment', 'Adjustments')}
                tooltip={t(
                  'invoices.invoice.balance.modal.table.adjustmentsTooltip',
                  'These values can be used to make fine adjustments to the Invoice value if necessary,  for example to resolve a rounding error.'
                )}
                inputs={[
                  {
                    id: 1,
                    value: balanceModalState.adjustmentExTax,
                    ariaLabel: t(
                      'invoices.invoice.balance.modal.table.adjustmentExTaxAria',
                      'Adjustments excluding tax'
                    ),
                    onChange: (value) => onSetState('adjustmentExTax', value),
                  },
                  {
                    id: 2,
                    value: balanceModalState.adjustmentTax,
                    ariaLabel: t('invoices.invoice.balance.modal.table.adjustmentTaxAria', 'Adjustments tax'),
                    onChange: (value) => onSetState('adjustmentTax', value),
                  },
                  {
                    id: 3,
                    value: adjustmentTotal,
                    ariaLabel: t('invoices.invoice.balance.modal.table.adjustmentTotalAria', 'Adjustments total'),
                    isDisabled: true,
                  },
                ]}
              />
            </div>
          </div>
          <div className="mb-5 flex justify-between border border-black px-4 py-3" data-testid="totals-row">
            <BalanceTableRow
              title={t('invoices.invoice.balance.modal.table.totals', 'Totals')}
              tooltip={t(
                'invoices.invoice.balance.modal.table.totalsTooltip',
                'These values should be equal to the sum of all of the Invoice parts above. These values are set when the Invoice is created, and you can manually adjust them here or use the auto-calculate option to pre-fill the fields with the sum of the values above.'
              )}
              onCalculateTotals={onCalculateTotals}
              inputs={[
                {
                  id: 1,
                  value: balanceModalState.totalExTax,
                  ariaLabel: t('invoices.invoice.balance.modal.table.totalExTaxAria', 'Total excluding tax'),
                  onChange: (value) => onSetState('totalExTax', value),
                },
                {
                  id: 2,
                  value: balanceModalState.totalTax,
                  ariaLabel: t('invoices.invoice.balance.modal.table.totalTaxAria', 'Total tax'),
                  onChange: (value) => onSetState('totalTax', value),
                },
                {
                  id: 3,
                  value: totalTotal,
                  ariaLabel: t('invoices.invoice.balance.modal.table.totalValueAria', 'Total Value'),
                  isDisabled: true,
                },
              ]}
            />
          </div>
          <div className="mb-5 flex justify-between border px-4 py-3">
            <BalanceTableRow
              title={t('invoices.invoice.balance.modal.table.variance', 'Variance')}
              tooltip={t(
                'invoices.invoice.balance.modal.table.varianceTooltip',
                'The variance shows the differences between the total values and the calculated values. For the Invoice to be balanced the variance should be zero in all of these fields. You can edit the values in the fields above to make the Invoice total balance and achieve a variance of zero.'
              )}
            />
            <div className="flex items-center gap-x-3" data-testid="variance-row">
              <VarianceDisplay totalHasVariance={totalHasVariance} value={totalExTaxVariance} />
              <VarianceDisplay totalHasVariance={totalHasVariance} value={totalTaxVariance} />
              <VarianceDisplay totalHasVariance={totalHasVariance} value={totalVariance} />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="flex flex-col-reverse items-center justify-end gap-2 md:flex-row">
          <Button
            type="button"
            className="w-full rounded-md bg-gray-200 px-5 py-2.5 text-sm md:w-auto"
            onClick={() => onCloseModal()}
            data-testid="cancel"
          >
            {t('general.cancel', 'Cancel')}
          </Button>
          <Button
            type="button"
            className="w-full rounded-md bg-primary px-5 py-2.5 text-sm text-white md:w-auto"
            loading={loading}
            onClick={onSubmitBalance}
            disabled={false}
            data-testid="apply-updates"
          >
            {t('invoices.invoices.balance.modal.applyUpdates', 'Apply Updates')}
          </Button>
        </Modal.Footer>
      </Modal.Panel>
    </Modal>
  )
}
