import * as base64 from 'base-64'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon } from '../../icons'
import { Themes } from '../../types'
import Button from '../button'
import ConfirmDialog from '../confirm-dialog'
import { PaginationDefinition } from './Table'

interface PaginationControlProps extends PaginationDefinition {}

export default function PaginationControl({
  rowsPerPage,
  hasNextPage,
  hasPreviousPage,
  onNextPage,
  onPreviousPage,
  loading,
  endCursor,
}: PaginationControlProps) {
  const { t } = useTranslation()
  const [showComingSoon, setShowComingSoon] = useState(false)

  // Calculates the current page based on the endCursor and the rowsPerPage
  const calculateCurrentPage = (endCursor: string | null | undefined, itemsPerPage: number): number => {
    // if no endCursor then we are on the first page
    if (!endCursor) return 1
    const endCursorDecoded = base64.decode(endCursor) // converts the endCursor to a number string
    const endCursorInt = parseInt(endCursorDecoded, 10) // converts the endCursor to an integer
    const currentPage = Math.floor(endCursorInt / itemsPerPage) // calculates the current page from the rows

    // if the remainder of endCursorInt / itemsPerPage is greater than 0 then currenPage +1 otherwise we are on the last pages
    return endCursorInt % itemsPerPage > 0 ? currentPage + 1 : currentPage
  }

  const currentPage = calculateCurrentPage(endCursor, rowsPerPage || 20)

  return (
    <div className="my-5 flex items-center justify-between">
      <div className="flex items-center justify-between">
        <Button
          data-testid="rows-per-page-btn"
          className="flex items-center gap-x-2 rounded-md bg-gray-200 px-4 py-2 hover:brightness-95"
          onClick={() => setShowComingSoon(true)}
        >
          <span className="text-sm">
            {t('requisition.details.rowsPerPage.information', {
              defaultValue_one: '{{ count }} Row per Page',
              defaultValue_many: '{{ count }} Rows per Page',
              defaultValue_other: '{{ count }} Rows per Page',
              count: rowsPerPage || 20,
            })}
          </span>
          <ChevronDownIcon className="h-6 w-6" />
        </Button>
        <div className="ml-3 text-sm">
          {hasNextPage || loading
            ? t('requisition.details.pageNumberOnly', 'Page {{ pageNumber }}', {
                pageNumber: currentPage,
              })
            : t('requisition.details.pageNumberLast', 'Page {{ pageNumber }} of {{ pageNumber }}', {
                pageNumber: currentPage,
              })}
        </div>
      </div>
      <div className="flex items-center gap-x-2">
        <Button
          data-testid="previous-page-btn"
          disabled={!hasPreviousPage}
          loading={loading}
          className="rounded-md bg-gray-200 px-3 py-2"
          onClick={onPreviousPage}
        >
          <ChevronLeftIcon className="h-6 w-6" />
        </Button>
        <Button
          data-testid="next-page-btn"
          disabled={!hasNextPage}
          loading={loading}
          className="rounded-md bg-gray-200 px-3 py-2"
          onClick={onNextPage}
        >
          <ChevronRightIcon className="h-6 w-6" />
        </Button>
      </div>
      <ConfirmDialog
        theme={Themes.Primary}
        isOpen={showComingSoon}
        setIsOpen={setShowComingSoon}
        title={t('requisition.details.rowsPerPage.comingSoon', 'Coming Soon!')}
        description={t(
          'requisition.details.rowsPerPage.comingSoonContent',
          "Soon you will be able to change the Rows per Page here, but we're still working on it. We're also working on allowing you to set which columns you would like to show and hide."
        )}
        primaryButtonLabel={t('general.okayGotIt', 'Okay, Got It')}
        onPrimaryBtnClick={() => setShowComingSoon(false)}
      />
    </div>
  )
}
