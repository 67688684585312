import { Dispatch, SetStateAction, useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import NoImage from '@/assets/images/no-image.svg'
import { RequisitionLine } from '@/graphql/purchasing/generated/purchasing_graphql'
import { useUpdateRequisitionLine } from '@/modules/requisitions/hooks'
import { formatProductItem } from '@/modules/requisitions/utils'
import { Button } from '@/modules/shared/components'
import { Modal } from '@/modules/shared/components'

interface AddOrderInstructionModalProps {
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<boolean>>
  line: RequisitionLine
}

export default function AddOrderInstructionModal(props: AddOrderInstructionModalProps) {
  const { line, showModal, setShowModal } = props
  const { id: lineId, productId, image, orderInstruction, supplier, product } = line
  const { brand, itemDescription, itemMeasure, itemPackName, itemSize, concatenatedSellUnit } = product || {}
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm<{ orderInstruction: string }>()
  const [updateRequisitionLine, { loading }] = useUpdateRequisitionLine()

  useEffect(() => {
    reset({ orderInstruction: orderInstruction || '' })
  }, [orderInstruction])

  const onCloseModal = () => {
    reset()
    setShowModal(false)
  }

  const onSubmit: SubmitHandler<{ orderInstruction: string }> = (data) => {
    const { orderInstruction } = data
    updateRequisitionLine({
      variables: {
        input: { id: Number(lineId), productId, orderInstruction },
      },
      onCompleted() {
        setShowModal(false)
      },
    })
  }

  return (
    <Modal showModal={showModal} onCloseModal={onCloseModal}>
      <Modal.Panel
        as="form"
        onSubmit={handleSubmit(onSubmit)}
        className="flex w-full flex-col overflow-hidden rounded-md bg-white shadow-xl transition-all md:max-w-[600px]"
      >
        <Modal.Title
          title={t('shopPage.cart.summary.table.orderInstructionModalTitle', 'Add Order Instruction')}
          onCloseModal={onCloseModal}
        />

        <Modal.Body>
          <section className="flex items-center gap-x-4 rounded-md border p-4">
            <img className="h-28 w-28 border" src={image || NoImage} alt="product" />
            <span>
              <p className="text-sm text-primary">
                {brand} {itemDescription}
              </p>
              <span className="text-xxs text-gray-500 sm:text-xs">
                {formatProductItem({
                  itemMeasure: itemMeasure,
                  itemPackName: itemPackName,
                  itemSize: itemSize,
                  itemSellUnit: concatenatedSellUnit,
                })}
              </span>
              <p className="text-xs">{supplier?.name}</p>
            </span>
          </section>
          <section className="mt-4">
            <h2 className="text-sm font-semibold">
              {t('shopPage.cart.summary.table.orderInstruction', 'Order Instruction')}
            </h2>
            <p className="text-sm">
              {t(
                'shopPage.cart.summary.table.orderInstructionExplain',
                'Add order instructions here for this product. These instructions will be added to the Purchase Order and will appear to the Supplier for this product line.'
              )}
            </p>
            <textarea
              {...register('orderInstruction')}
              data-testid="order-instruction-input"
              className="mt-4 w-full rounded-md border border-gray-300 p-3 text-sm shadow-sm focus:outline-none focus:ring-primary"
              rows={6}
            ></textarea>
          </section>
        </Modal.Body>
        <Modal.Footer className="flex flex-col-reverse items-center justify-end gap-2 md:flex-row">
          <Button
            type="button"
            className="h-11 w-full rounded-md bg-gray-200 px-8 text-sm md:w-fit"
            onClick={onCloseModal}
          >
            {t('shopPage.cart.summary.table.cancelModalBtn', 'Cancel')}
          </Button>

          {isDirty && (
            <Button
              data-testid="save-changes-order-instruction-btn"
              type="submit"
              className="h-11 w-full rounded-md bg-primary px-5 text-sm text-white md:w-fit"
              loading={loading}
            >
              {t('shopPage.cart.summary.table.saveAndCloseModalBtn', 'Save & Close')}
            </Button>
          )}
        </Modal.Footer>
      </Modal.Panel>
    </Modal>
  )
}
