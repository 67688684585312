import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import {
  GetAdvancedPossibleRequisitionLinesDocument,
  RequisitionLine,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { OnFilter } from '@/modules/shared/components/search-input/SearchAndFilter'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { checkNetworkStatus, extractEdges } from '@/modules/shared/utils'

export const useGetAdvancedPossibleRequisitionLines = () => {
  const { requisitionId } = useParams()
  const paginationResultsPerPage = 20
  const { data, error, networkStatus, refetch } = useQuery(GetAdvancedPossibleRequisitionLinesDocument, {
    variables: {
      requisitionId: Number(requisitionId),
      first: paginationResultsPerPage,
      bestBuy: true,
    },
    context: { uri: PURCHASING_GRAPHQL_API },
    notifyOnNetworkStatusChange: true,
  })
  const loadingStates = checkNetworkStatus(networkStatus)

  const actions = {
    refetch: refetch,
    onFilter: ({ searchValue, hideZero }: OnFilter) => {
      refetch({
        term: searchValue.trim(),
        excludeZeroQuantity: !!hideZero,
      })
    },
  }

  return {
    loadingStates,
    actions,
    error,
    requisition: data?.currentPurchaser?.requisition,
    advancedPossibleRequisitionLines: extractEdges<RequisitionLine>(
      data?.currentPurchaser?.requisition?.possibleRequisitionLines
    ),
    pageInfo: data?.currentPurchaser?.requisition?.possibleRequisitionLines?.pageInfo,
    paginationResultsPerPage,
  }
}
