import { Dispatch, SetStateAction } from 'react'
import { Controller, ControllerRenderProps, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import NoImage from '@/assets/images/no-image.svg'
import { InvoiceLineItem } from '@/graphql/purchasing/generated/purchasing_graphql'
import { useUpdateInvoiceLineItem } from '@/modules/invoices/hooks'
import { UpdateInvoiceLineFormInputs } from '@/modules/invoices/types'
import { useCurrentPurchaser } from '@/modules/purchasing/hooks'
import { formatProductItem } from '@/modules/requisitions/utils'
import { Button, Modal } from '@/modules/shared/components'
import NumberInput from '@/modules/shared/components/number-input'

export interface UpdateInvoiceLineModalProps {
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<boolean>>
  invoiceLine: InvoiceLineItem
}

export default function UpdateInvoiceLineModal({ showModal, setShowModal, invoiceLine }: UpdateInvoiceLineModalProps) {
  const { t } = useTranslation()

  const { product } = invoiceLine
  const { brand, itemDescription, itemMeasure, itemPackName, itemSize, concatenatedSellUnit } = product || {}

  const [updateInvoiceLineItem, { loading }] = useUpdateInvoiceLineItem()
  const { currentPurchaser } = useCurrentPurchaser()

  const formMethods = useForm<UpdateInvoiceLineFormInputs>({
    defaultValues: {
      quantity: invoiceLine.quantity || 0,
      taxPercentage: invoiceLine.taxPercentage || 0,
      invoicePrice: Number(invoiceLine.unitPrice),
    },
  })

  const errorQuantity =
    formMethods.formState.errors.quantity && formMethods.formState.errors.quantity.type === 'required'
  const errorUnitPrice =
    formMethods.formState.errors.invoicePrice && formMethods.formState.errors.invoicePrice.type === 'required'
  const errorTax =
    formMethods.formState.errors.taxPercentage && formMethods.formState.errors.taxPercentage.type === 'required'

  const onCloseModal = () => {
    setShowModal(false)
    if (formMethods.formState.errors) formMethods.reset()
  }

  const onSubmit: SubmitHandler<UpdateInvoiceLineFormInputs> = (data) => {
    updateInvoiceLineItem({
      variables: {
        input: { id: invoiceLine.id, ...data },
      },
      onCompleted(data) {
        const { quantity, taxPercentage, unitPrice } = data.updateInvoiceLineItem.invoiceLineItem || {}
        formMethods.reset({
          quantity: quantity || 0,
          taxPercentage: taxPercentage || 0,
          invoicePrice: Number(unitPrice),
        })
        onCloseModal()
      },
    })
  }

  const onChangeInput = <K extends keyof UpdateInvoiceLineFormInputs>(
    field: ControllerRenderProps<UpdateInvoiceLineFormInputs, K>,
    value: number
  ) => {
    // To disable the apply changes button when no input is provided.
    if (isNaN(value)) field.onChange(null)
    else field.onChange(value)
  }

  return (
    <Modal showModal={showModal} onCloseModal={onCloseModal}>
      <Modal.Panel className="flex w-full flex-col overflow-hidden rounded-md bg-white shadow-xl transition-all md:max-w-[600px]">
        <Modal.Title
          title={t('invoices.invoice.updateInvoiceLine.updateInvoiceLine', 'Update Invoice Line')}
          onCloseModal={onCloseModal}
        />

        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <Modal.Body>
            <div className="mb-5 flex gap-x-3 rounded-md border p-4">
              <img className="h-24 w-24 flex-none border" src={NoImage} alt="product" />
              <div className="flex flex-col justify-center text-sm">
                <span className="text-primary">
                  {brand} {itemDescription}
                </span>
                <span className="text-xxs text-gray-500 sm:text-xs">
                  <p>
                    {formatProductItem({
                      itemMeasure: itemMeasure,
                      itemPackName: itemPackName,
                      itemSize: itemSize,
                      itemSellUnit: concatenatedSellUnit,
                    })}
                  </p>
                </span>
              </div>
            </div>
            <div className="space-y-4 text-sm">
              <div>
                <p className="font-bold">{t('general.quantity', 'Quantity')}</p>
                <p className="text-gray-500">
                  {t(
                    'invoices.invoice.updateInvoiceLine.quantityDescription',
                    'Set the quantity for this product on the Invoice line.'
                  )}
                </p>
                <Controller
                  control={formMethods.control}
                  name="quantity"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <NumberInput
                      className="mt-2 h-12 w-full rounded-md border text-sm shadow-sm"
                      leftUnit={t('general.qty', 'QTY')}
                      data-testid="qty-input"
                      aria-label={t('general.quantity', 'Quantity')}
                      hasError={errorQuantity}
                      errorMessage={t(
                        'invoices.invoice.updateInvoiceLine.quantityRequiredMsg',
                        'You must provide a Quantity.'
                      )}
                      defaultValue={Number(invoiceLine.quantity)}
                      onChange={(e) => onChangeInput(field, e)}
                    />
                  )}
                />
              </div>
              <div>
                <p className="font-bold">{t('general.unitPrice', 'Unit Price')}</p>
                <p className="text-gray-500">
                  {t(
                    'invoices.invoice.updateInvoiceLine.unitPriceDescription',
                    'Set the price for this product on Invoice line. The price is exclusive of tax, and the tax percentage to be added to the price is managed in the field below.'
                  )}
                </p>
                <Controller
                  control={formMethods.control}
                  name="invoicePrice"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <NumberInput
                      className="mt-2 h-12 w-full rounded-md border text-sm shadow-sm"
                      leftUnit={currentPurchaser?.currency}
                      data-testid="price-input"
                      aria-label={t('general.unitPrice', 'Unit Price')}
                      hasError={errorUnitPrice}
                      errorMessage={t(
                        'invoices.invoice.updateInvoiceLine.unitPriceRequiredMsg',
                        'You must provide a Unit Price.'
                      )}
                      defaultValue={Number(invoiceLine.unitPrice)}
                      formatOptions={{
                        minimumFractionDigits: 1,
                        maximumFractionDigits: 2,
                      }}
                      onChange={(e) => onChangeInput(field, e)}
                    />
                  )}
                />
              </div>
              <div>
                <p className="font-bold">{t('general.taxPercentage', 'Tax Percentage')}</p>
                <p className="text-gray-500">
                  {t(
                    'invoices.invoice.updateInvoiceLine.quantityDescription',
                    'Set the quantity for this product on the Invoice line.'
                  )}
                </p>
                <Controller
                  control={formMethods.control}
                  name="taxPercentage"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <NumberInput
                      className="mt-2 h-12 w-full rounded-md border text-sm shadow-sm"
                      leftUnit={t('general.taxWithPercentage', 'Tax %')}
                      data-testid="tax-input"
                      aria-label={t('general.taxPercentage', 'Tax Percentage')}
                      hasError={errorTax}
                      errorMessage={t(
                        'invoices.invoice.updateInvoiceLine.taxPercentageRequiredMsg',
                        'You must provide a Tax Percentage, even if it is zero.'
                      )}
                      defaultValue={Number(invoiceLine.taxPercentage)}
                      onChange={(e) => onChangeInput(field, e)}
                    />
                  )}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="flex flex-col-reverse items-center justify-end gap-2 md:flex-row">
            <Button
              className="h-11 w-full rounded-md bg-gray-200 px-8 text-sm md:w-fit"
              onClick={onCloseModal}
              type="button"
              data-testid="cancel-button"
            >
              {t('general.cancel', 'Cancel')}
            </Button>
            <Button
              className="h-11 w-full rounded-md bg-primary px-8 text-sm text-white md:w-fit"
              type="submit"
              loading={loading}
              data-testid="submit-button"
            >
              {t('general.applyUpdate', 'Apply Update')}
            </Button>
          </Modal.Footer>
        </form>
      </Modal.Panel>
    </Modal>
  )
}
