import { graphql } from 'msw'

import { GetInvoiceLineItemQuery } from '@/graphql/purchasing/generated/purchasing_graphql'

export const GetInvoiceLineItem = graphql.query<GetInvoiceLineItemQuery>('GetInvoiceLineItem', (_, res, ctx) => {
  return res(
    ctx.data({
      currentPurchaser: {
        id: 44926,
        invoice: {
          id: 7524140,
          invoiceLineItem: {
            id: 24586766,
            product: {
              id: 8,
              brand: '',
              itemDescription: 'Chives Garlic',
              concatenatedSellUnit: 'each of 1',
              itemPackName: '',
              itemMeasure: 'bunch',
              itemSize: 1,
              __typename: 'Product',
            },
            relatedReceivingDocuments: {
              edges: [
                {
                  node: {
                    id: 22275235,
                    receivedQuantity: 2.5,
                    unitPrice: 1,
                    totalValue: 1,
                    receivedToInventory: false,
                    stockLocation: null,
                    quantity: 1,
                    receivingDocument: {
                      id: 6660482,
                      receivingDocumentNumber: 'GR03049',
                      purchaseOrderId: 1,
                      receivedDate: '2023-05-17T15:22:46+10:00',
                      transfer: null,
                      receiver: {
                        id: 18544,
                        fullName: 'Sunny H Sun',
                      },
                    },
                    product: {
                      id: 8,
                    },
                    purchaseOrderLineItem: {
                      id: 60221418,
                    },
                  },
                },
                {
                  node: {
                    id: 22275236,
                    receivedQuantity: 2.5,
                    unitPrice: 1.25,
                    totalValue: 1.25,
                    receivedToInventory: false,
                    stockLocation: null,
                    quantity: 1,
                    receivingDocument: {
                      id: 6660482,
                      receivingDocumentNumber: 'GR03049',
                      purchaseOrderId: 1,
                      receivedDate: '2023-05-17T15:22:46+10:00',
                      transfer: null,
                      receiver: {
                        id: 18544,
                        fullName: 'Sunny H Sun',
                      },
                    },
                    product: {
                      id: 19307,
                    },
                    purchaseOrderLineItem: {
                      id: 60221419,
                    },
                  },
                },
                {
                  node: {
                    id: 22275237,
                    receivedQuantity: 2.5,
                    unitPrice: 1,
                    totalValue: 1.5,
                    receivedToInventory: false,
                    stockLocation: null,
                    quantity: 1,
                    receivingDocument: {
                      id: 6660483,
                      receivingDocumentNumber: 'GR03050',
                      purchaseOrderId: 1,
                      receivedDate: '2023-05-17T15:28:38+10:00',
                      transfer: null,
                      receiver: {
                        id: 18544,
                        fullName: 'Sunny H Sun',
                      },
                    },
                    product: {
                      id: 8,
                    },
                    purchaseOrderLineItem: {
                      id: 60221418,
                    },
                  },
                },
                {
                  node: {
                    id: 22275238,
                    receivedQuantity: 2.5,
                    unitPrice: 1.25,
                    totalValue: 1.875,
                    receivedToInventory: false,
                    stockLocation: null,
                    quantity: 1,
                    receivingDocument: {
                      id: 6660483,
                      receivingDocumentNumber: 'GR03050',
                      purchaseOrderId: 1,
                      receivedDate: '2023-05-17T15:28:38+10:00',
                      transfer: null,
                      receiver: {
                        id: 18544,
                        fullName: 'Sunny H Sun',
                      },
                    },
                    product: {
                      id: 19307,
                    },
                    purchaseOrderLineItem: {
                      id: 60221419,
                    },
                  },
                },
              ],
            },
            relatedInvoicesWithSamePo: {
              edges: [
                {
                  node: {
                    id: 7524315,
                    invoiceLineItems: {
                      edges: [
                        {
                          node: {
                            id: 24587460,
                            quantity: 5.5,
                            unitPrice: '15.0',
                            taxPercentage: 10,
                            purchaseOrderLineItem: {
                              id: 60221021,
                              __typename: 'PurchaseOrderLineItem',
                            },
                            invoice: {
                              id: 7524315,
                              invoiceNumber: 'INPO050122',
                              __typename: 'Invoice',
                            },
                            product: {
                              id: 11,
                              brand: '',
                              itemDescription: 'Sage',
                              itemSize: 1,
                              itemMeasure: 'bunch',
                              itemPackName: '',
                              concatenatedSellUnit: 'each of 1',
                              __typename: 'Product',
                            },
                            __typename: 'InvoiceLineItem',
                          },
                          __typename: 'InvoiceLineItemEdge',
                        },
                      ],
                    },
                    __typename: 'Invoice',
                  },
                  __typename: 'InvoiceEdge',
                },
                {
                  node: {
                    id: 7524313,
                    invoiceLineItems: {
                      edges: [
                        {
                          node: {
                            id: 24587458,
                            quantity: 1,
                            unitPrice: '14.0',
                            taxPercentage: 10,
                            purchaseOrderLineItem: {
                              id: 1,
                            },
                            invoice: {
                              id: 7524313,
                              invoiceNumber: 'INPO05012',
                              __typename: 'Invoice',
                            },

                            product: {
                              id: 11,
                              brand: '',
                              itemDescription: 'Sage',
                              itemSize: 1,
                              itemMeasure: 'bunch',
                              itemPackName: '',
                              concatenatedSellUnit: 'each of 1',
                              __typename: 'Product',
                            },
                            __typename: 'InvoiceLineItem',
                          },
                          __typename: 'InvoiceLineItemEdge',
                        },
                      ],
                      __typename: 'InvoiceLineItemConnection',
                    },
                  },
                  __typename: 'InvoiceEdge',
                },
              ],
              __typename: 'InvoiceConnection',
            },
            totalInvoicedQuantity: 20,
            receivedStatus: 'not_received',
            totalReceivedQuantity: 5,
            quantity: 1,
            unitPrice: '1.85',
            invoicePriceVariance: 0.1,
            taxPercentage: 0,
            purchaseOrderLineItem: {
              id: 60221021,
              image: null,
              product: {
                id: 14122,
                brand: '',
                itemDescription: 'Chives Garlic',
                concatenatedSellUnit: 'each of 1',
                itemPackName: '',
                itemMeasure: 'bunch',
                itemSize: 1,
                __typename: 'Product',
              },
              purchaseOrder: {
                id: 16567291,
                purchaseOrderNumber: 'PO04899',
                __typename: 'PurchaseOrder',
              },
              quantity: 4,
              receivedQuantity: 1,
              unitPrice: 1.75,
              taxPercentage: 0,
              __typename: 'PurchaseOrderLineItem',
            },
            __typename: 'InvoiceLineItem',
          },
          __typename: 'Invoice',
        },
        __typename: 'Purchaser',
      },
    })
  )
})
