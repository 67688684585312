import classNames from 'classnames'
import { useState } from 'react'

import ProductModal from '../../product-modal/ProductModal'

import {
  Product,
  PurchaseOrderLineItem,
  RequisitionLine,
  Supplier,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { formatProductItem } from '@/modules/requisitions/utils'

interface ProductItemProps {
  product: Product
  clickToOpenModal?: boolean
  supplier?: Supplier | null
  line?: RequisitionLine | PurchaseOrderLineItem
}

export const ProductItem = ({ product, clickToOpenModal, supplier, line }: ProductItemProps) => {
  const { itemDescription, brand, itemSize, itemMeasure, itemPackName, concatenatedSellUnit } = product
  const [showProductDetailsModal, setShowProductDetailsModal] = useState<boolean>(false)

  return (
    <>
      <p
        className={classNames('break-words text-sm leading-5 text-primary', { 'cursor-pointer': clickToOpenModal })}
        onClick={() => clickToOpenModal && setShowProductDetailsModal(true)}
      >
        {brand} {itemDescription}
      </p>
      <span className="text-xs">
        {formatProductItem({
          itemSize: itemSize,
          itemMeasure: itemMeasure,
          itemPackName: itemPackName,
          itemSellUnit: concatenatedSellUnit,
        })}
      </span>
      {line && (
        <ProductModal
          showModal={showProductDetailsModal}
          setShowModal={setShowProductDetailsModal}
          line={line}
          supplier={supplier as Supplier}
        />
      )}
    </>
  )
}
