import classNames from 'classnames'
import { Dispatch, SetStateAction } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { ReceivingDocumentLineItemInput } from '@/modules/purchase-orders/types'

interface ReceivingNoteLineCommentProps {
  isReadOnly: boolean
  setInputFocus: Dispatch<SetStateAction<boolean>>
}

export default function ReceivingNoteLineComment({ isReadOnly, setInputFocus }: ReceivingNoteLineCommentProps) {
  const { t } = useTranslation()
  const { register } = useFormContext<ReceivingDocumentLineItemInput>()
  return (
    <section>
      <strong className="text-sm">{t('receivingNotes.advancedModal.comment', 'Comment')}</strong>
      <p className="text-sm text-gray-500">
        {t(
          'receivingNotes.advancedModal.commentDesc',
          'Add an optional comment here about this item and it will be recorded on the Receiving Note.'
        )}
      </p>
      <textarea
        {...register('comment')}
        data-testid="receiving-note-comment-input"
        disabled={isReadOnly}
        placeholder={t('receivingNotes.advancedModal.commentPlaceholder', 'Type your comment here')}
        className={classNames(
          'mt-4 w-full rounded-md border border-gray-300 p-3 text-sm shadow-sm focus:outline-none focus:ring-primary',
          {
            'cursor-not-allowed border-gray-300 bg-gray-200': isReadOnly,
          }
        )}
        onFocus={() => setInputFocus(true)}
        rows={6}
      ></textarea>
    </section>
  )
}
