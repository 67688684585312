import { graphql } from 'msw'

import { GetDepartmentsQuery } from '@/graphql/purchasing/generated/purchasing_graphql'

export const GetDepartments = graphql.query<GetDepartmentsQuery>('GetDepartments', (_, res, ctx) => {
  return res(
    ctx.data({
      currentPurchaser: {
        id: 44926,
        departments: [
          {
            id: 44926,
            name: 'Test Account',
            __typename: 'Purchaser',
          },
          {
            id: 100617,
            name: 'Accounts',
            __typename: 'Purchaser',
          },
        ],
        __typename: 'Purchaser',
      },
    })
  )
})
