import { ApproveRequisition } from './ApproveRequisition'
import { CancelInvoice } from './CancelInvoice'
import { CancelReceivingDocument } from './CancelReceivingDocument'
import { CancelRequisition } from './CancelRequisition'
import { CompleteInvoice } from './CompleteInvoice'
import { CreateAdvancedRequisitionLine } from './CreateAdvancedRequisitionLine'
import { CreateBuyList } from './CreateBuyList'
import { CreateCataloguedProduct } from './CreateCataloguedProduct'
import { CreateComment } from './CreateComment'
import { CreateInvoice } from './CreateInvoice'
import { CreatePricedCatalog } from './CreatePricedCatalog'
import { CreateReceivingDocument } from './CreateReceivingDocument'
import { CreateRequisitionLine } from './CreateRequisitionLine'
import { DeclineRequisition } from './DeclineRequisition'
import { DeleteComment } from './DeleteComment'
import { DeleteRequisitionAttachment } from './DeleteRequisitionAttachment'
import { DeleteRequisitionLine } from './DeleteRequisitionLine'
import { FinancialApproveInvoice } from './FinancialApproveInvoice'
import { HaltInvoice } from './HaltInvoice'
import { MatchPurchaseOrderInvoice } from './MatchPurchaseOrderInvoice'
import { OperationalApproveInvoice } from './OperationalApproveInvoice'
import { ParkInvoice } from './ParkInvoice'
import { ProcessInvoice } from './ProcessInvoice'
import { ReceiveReceivingDocument } from './ReceiveReceivingDocument'
import { ReopenInvoice } from './ReopenInvoice'
import { SendPurchaseOrder } from './SendPurchaseOrder'
import { SubmitRequisition } from './SubmitRequisition'
import { UpdateAdvancedRequisitionLine } from './UpdateAdvancedRequisitionLine'
import { UpdateCataloguedProduct } from './UpdateCataloguedProduct'
import { UpdateInvoice } from './UpdateInvoice'
import { UpdateInvoiceLineItem } from './UpdateInvoiceLineItem'
import { UpdateOrganisation } from './UpdateOrganisation'
import { UpdatePricedCataloguedProduct } from './UpdatePricedCataloguedProduct'
import { UpdatePurchaseOrder } from './UpdatePurchaseOrder'
import { UpdateReceivingDocumentLine } from './UpdateReceivingDocumentLine'
import { UpdateRequisition } from './UpdateRequisition'
import { UpdateRequisitionLine } from './UpdateRequisitionLine'
import { UpdateSupplierRelationship } from './UpdateSupplierRelationship'

const handlers = [
  ApproveRequisition,
  CancelRequisition,
  CreateComment,
  CreateRequisitionLine,
  DeclineRequisition,
  DeleteComment,
  DeleteRequisitionLine,
  SendPurchaseOrder,
  SubmitRequisition,
  UpdatePurchaseOrder,
  UpdateRequisition,
  UpdateRequisitionLine,
  UpdateSupplierRelationship,
  DeleteRequisitionAttachment,
  CancelReceivingDocument,
  CreateReceivingDocument,
  ReceiveReceivingDocument,
  MatchPurchaseOrderInvoice,
  UpdatePricedCataloguedProduct,
  UpdateReceivingDocumentLine,
  UpdateInvoice,
  CreateCataloguedProduct,
  CompleteInvoice,
  UpdateInvoiceLineItem,
  ParkInvoice,
  HaltInvoice,
  ReopenInvoice,
  ProcessInvoice,
  CancelInvoice,
  CreateBuyList,
  CreatePricedCatalog,
  UpdateCataloguedProduct,
  CreateInvoice,
  CreateAdvancedRequisitionLine,
  UpdateAdvancedRequisitionLine,
  FinancialApproveInvoice,
  OperationalApproveInvoice,
  UpdateOrganisation,
]

export default handlers
