import { useTranslation } from 'react-i18next'
import { BooleanParam, useQueryParam } from 'use-query-params'

import { Checkbox, Tooltip } from '@/modules/shared/components'
import { QuestionMarkIcon } from '@/modules/shared/icons'
import { QueryParameter } from '@/modules/shared/types'

export interface HideZeroQtyProps {
  reverse?: boolean
}

export default function HideZeroQty({ reverse }: HideZeroQtyProps) {
  const { t } = useTranslation()
  const [hideZeroQtyURLParam, setHideZeroQtyURLParam] = useQueryParam(QueryParameter.HideZero, BooleanParam)

  return (
    <span
      className="ml-1 mr-2"
      onClick={() => {
        if (!!hideZeroQtyURLParam) setHideZeroQtyURLParam(undefined)
        else setHideZeroQtyURLParam(true)
      }}
      data-testid="hide-zero-qty-button"
    >
      <Checkbox
        isSelected={!!hideZeroQtyURLParam}
        defaultSelected={!!hideZeroQtyURLParam}
        aria-label={t('shopPage.productList.hideZeroQty', 'Hide Zero Quantity')}
        reverse={reverse}
      >
        <div className="flex">
          <p className="text-sm">{t('shopPage.productList.hideZeroQty', 'Hide Zero Quantity')}</p>
          <Tooltip
            content={t(
              'shopPage.productList.hideZeroQtyTooltip',
              'Only show products that have been added to the Requisition with a quantity greater than zero'
            )}
          >
            <span className="ml-0.5 flex items-center">
              <QuestionMarkIcon className="inline w-5" />
            </span>
          </Tooltip>
        </div>
      </Checkbox>
    </span>
  )
}
