import { Disclosure } from '@headlessui/react'
import classNames from 'classnames'
import { t } from 'i18next'

import SearchAndFilter from '@/modules/shared/components/search-input/SearchAndFilter'
import { ChevronDownIcon } from '@/modules/shared/icons'
import { QueryParameter } from '@/modules/shared/types'

export default function SearchSection() {
  return (
    <section className="px-4 pb-4 pt-3">
      <Disclosure defaultOpen>
        {({ open }) => (
          <>
            <Disclosure.Button
              className="flex w-full items-center justify-between py-2"
              data-testid="search-by-code-section"
            >
              <span className="text-sm">{t('shopPage.productList.searchByCode', 'Search By Code')}</span>
              <ChevronDownIcon
                className={classNames('h-5 w-5 transition-all duration-300', {
                  'rotate-180 transform': open,
                })}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="mt-2 flex flex-col gap-2 md:flex-row 2xl:flex-col">
              <SearchAndFilter
                testId="supplier-product-code-search-input"
                placeholder={t('shopPage.productList.searchBy.supplierProductCode', 'Supplier Product Code')}
                ariaLabel={t('shopPage.productList.searchBy.supplierProductCode', 'Supplier Product Code')}
                searchTermURLParamType={QueryParameter.SupplierProductCode}
              />
              <SearchAndFilter
                testId="purchaser-product-code-search-input"
                placeholder={t('shopPage.productList.searchBy.purchaserProductCode', 'Purchaser Product Code')}
                ariaLabel={t('shopPage.productList.searchBy.purchaserProductCode', 'Purchaser Product Code')}
                searchTermURLParamType={QueryParameter.PurchaserProductCode}
              />
              <SearchAndFilter
                testId="ppid-search-input"
                placeholder={t('shopPage.productList.searchBy.pPlusProductCode', 'P+ Product Code')}
                ariaLabel={t('shopPage.productList.searchBy.pPlusProductCode', 'P+ Product Code')}
                searchTermURLParamType={QueryParameter.PPlusProductCode}
              />
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </section>
  )
}
