import { useQuery } from '@apollo/client'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Link } from 'react-router-dom'
import { StringParam, useQueryParam } from 'use-query-params'

import TableView from './table-view'
import TileView from './tile-view'

import CatalogsEmptyState from '@/assets/images/empty-catalogs.svg'
import {
  GetPricedCataloguesDocument,
  PricedCatalogue,
  RansackDirection,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { Spinner } from '@/modules/requisitions/components'
import { Breadcrumb, MoreOptionsMenu, QueryResult, TitleBar, Tooltip } from '@/modules/shared/components'
import EmptyState from '@/modules/shared/components/empty-state'
import SearchAndFilter from '@/modules/shared/components/search-input/SearchAndFilter'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { useWindowSize } from '@/modules/shared/hooks'
import { CatalogIcon, HomeIcon, OptionIcon, PlusIcon } from '@/modules/shared/icons'
import { QueryParameter } from '@/modules/shared/types'
import { checkNetworkStatus, extractEdges } from '@/modules/shared/utils'

export default function AllSelfManaged() {
  const { t } = useTranslation()
  const { isLargeScreen } = useWindowSize()

  const [searchTermURLParam] = useQueryParam(QueryParameter.Search, StringParam)

  const { data, error, networkStatus, fetchMore } = useQuery(GetPricedCataloguesDocument, {
    variables: {
      after: null,
      filter: {
        q: [
          {
            property: 'title_cont',
            value: searchTermURLParam || '',
          },
        ],
      },
      sort: [
        {
          property: 'updatedAt',
          direction: RansackDirection.Desc,
        },
      ],
    },
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  })
  const pricedCatalogs = extractEdges<PricedCatalogue>(data?.currentPurchaser?.pricedCatalogues)

  const { setVariablesLoading, loading } = checkNetworkStatus(networkStatus)

  const onFetchMorePricedCatalogs = () => {
    fetchMore({
      variables: {
        after: data?.currentPurchaser?.pricedCatalogues?.pageInfo.endCursor,
      },
    })
  }

  return (
    <>
      <Helmet>
        <title>{t('catalogs.selfManagedCatalogs', 'Self-Managed Catalogs')}</title>
      </Helmet>
      <main className="mx-auto space-y-5 px-4">
        <Breadcrumb className="overflow-x-auto rounded-md bg-gray-200 px-3 py-2">
          <Breadcrumb.Item icon={HomeIcon} href="/dashboard"></Breadcrumb.Item>
          <Breadcrumb.Item href="#">{t('catalogs.catalogs', 'Catalogs')}</Breadcrumb.Item>
          <Breadcrumb.Item href="/catalogs/self-managed">{t('catalogs.selfManaged', 'Self-Managed')}</Breadcrumb.Item>
          <Breadcrumb.Item>{t('general.viewAll', 'View All')}</Breadcrumb.Item>
        </Breadcrumb>
        <TitleBar
          icon={<CatalogIcon className="h-10 w-10 " />}
          title={t('catalogs.selfManagedCatalogs', 'Self-Managed Catalogs')}
          subtitle={t('catalogs.viewAllSelfManagedCatalogs', 'View All Self-Managed Catalogs')}
        >
          <div className="flex items-center gap-x-2">
            <Tooltip content={t('createCatalog.createTooltip', 'Create a Catalog')}>
              <Link to="/catalogs/create">
                <PlusIcon className="h-11 w-11 cursor-pointer rounded-full bg-gray-200 p-1 text-gray-600" />
              </Link>
            </Tooltip>
            <MoreOptionsMenu showHelp showOpenInPPlus allSelfManagedCatalogs>
              <OptionIcon className="h-11 w-11 rounded-full bg-gray-200 p-1.5 text-gray-600 outline-none hover:brightness-95" />
            </MoreOptionsMenu>
          </div>
        </TitleBar>
        <SearchAndFilter
          testId="self-managed-catalogs-search-input"
          placeholder={t('catalogs.searchSelfManagedCatalogsPlaceholder', 'Search Self-Managed Catalogs')}
          ariaLabel={t('catalogs.searchSelfManagedCatalogsPlaceholder', 'Search Self-Managed Catalogs')}
        />
        <QueryResult loading={loading || setVariablesLoading} error={error}>
          <InfiniteScroll
            dataLength={pricedCatalogs.length}
            next={onFetchMorePricedCatalogs}
            hasMore={!!data?.currentPurchaser?.pricedCatalogues?.pageInfo.hasNextPage}
            loader={<Spinner className="mt-5 h-14 md:w-16" />}
          >
            {isLargeScreen ? (
              <TableView pricedCatalogs={pricedCatalogs} />
            ) : (
              <TileView pricedCatalogs={pricedCatalogs} />
            )}
          </InfiniteScroll>
          {!setVariablesLoading && pricedCatalogs.length === 0 && (
            <EmptyState
              img={CatalogsEmptyState}
              message={t('catalogs.emptyState', 'There are no Self-Managed Catalogs.')}
            />
          )}
        </QueryResult>
      </main>
    </>
  )
}
