import classNames from 'classnames'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useOutletContext } from 'react-router-dom'

import { RequisitionLine, Supplier } from '@/graphql/purchasing/generated/purchasing_graphql'
import AddDeptAndAccountModal from '@/modules/requisitions/components/lines-by-suppliers/AddDeptAndAccountModal'
import AddOrderInstructionModal from '@/modules/requisitions/components/lines-by-suppliers/AddOrderInstructionModal'
import { useChangeRequisitionLineQuantity, useUpdateRequisitionLine } from '@/modules/requisitions/hooks'
import { ShoppingCartContext } from '@/modules/requisitions/types'
import { formatProductItem } from '@/modules/requisitions/utils'
import { Button, Stepper, Tooltip } from '@/modules/shared/components'
import ProductModal from '@/modules/shared/components/product-modal/ProductModal'
import ExtraLine from '@/modules/shared/components/table/ExtraLine'
import Table from '@/modules/shared/components/table/Table'
import { SupplierQuotesButton } from '@/modules/shared/components/table/table-column-items'
import { useMoney } from '@/modules/shared/hooks'
import { NoteIcon, SettingsIcon, TrashIcon } from '@/modules/shared/icons'
import { Themes } from '@/modules/shared/types'

interface TableViewProps {
  lines: RequisitionLine[]
  supplier: Supplier
}

export default function TableView({ lines, supplier }: TableViewProps) {
  const { t } = useTranslation()
  const [showAddOrderInstructionModal, setShowAddOrderInstructionModal] = useState(false)
  const [showAddDeptAndAccountModal, setShowAddDeptAndAccountModal] = useState(false)
  const [showProductModal, setShowProductModal] = useState(false)
  const [activeLineId, setActiveLineId] = useState<number | null>(null)
  const [actionUpdateType, setActionUpdateType] = useState<string | null>(null)
  const [updateRequisitionLine, { loading: loadingUpdate }] = useUpdateRequisitionLine()
  const { onUpdateRequisitionLineQuantity, loading } = useChangeRequisitionLineQuantity()
  const { formatMoney } = useMoney()
  const { readonly } = useOutletContext<ShoppingCartContext>()

  const onUpdateRequisitionLine = (input: {
    id: number
    productId: number
    quantity: number
    cataloguedProductId: number
  }) => {
    setActiveLineId(input.id)
    setActionUpdateType(input.quantity ? 'quantity' : 'removeLine')
    onUpdateRequisitionLineQuantity({
      qty: input.quantity,
      lineId: input.id,
      productId: input.productId,
      cataloguedProductId: input.cataloguedProductId,
    })
  }

  const onRemoveOrderInstruction = (input: { id: number; productId: number }) => {
    setActiveLineId(input.id)
    setActionUpdateType('orderInstruction')
    updateRequisitionLine({
      variables: {
        input: {
          ...input,
          orderInstruction: null,
        },
      },
    })
  }

  const onRemoveDeptAndAccount = (input: { id: number; productId: number }) => {
    setActiveLineId(input.id)
    setActionUpdateType('deptAndAccount')
    updateRequisitionLine({
      variables: {
        input: {
          ...input,
          departmentId: null,
          accountId: null,
        },
      },
    })
  }

  const renderProduct = (record: RequisitionLine) => {
    const { brand, itemDescription, itemMeasure, itemPackName, itemSize, concatenatedSellUnit } = record.product || {}
    return (
      <>
        <span
          className="cursor-pointer text-primary"
          onClick={() => {
            setActiveLineId(record.id)
            setShowProductModal(true)
          }}
        >
          {brand} {itemDescription}
        </span>
        <span className="text-xxs text-gray-500 sm:text-xs">
          <p>
            {formatProductItem({
              itemMeasure: itemMeasure || '',
              itemPackName: itemPackName || '',
              itemSize: itemSize || 0,
              itemSellUnit: concatenatedSellUnit || '',
            })}
          </p>
        </span>
        <ProductModal
          line={record}
          showModal={showProductModal && activeLineId === record.id}
          setShowModal={setShowProductModal}
          supplier={supplier}
        />
      </>
    )
  }

  const renderQuantity = ({ quantity, id, productId, cataloguedProductId }: RequisitionLine) => {
    return (
      <div className="flex items-center justify-center">
        {!readonly ? (
          <Stepper
            loading={loading && activeLineId === id && actionUpdateType === 'quantity'}
            value={quantity || 0}
            stepperAriaLabel={t('shopPage.stepperAriaLabel', 'Entering number of products')}
            onChange={(newQty: number) => {
              onUpdateRequisitionLine({
                id: Number(id),
                productId: Number(productId),
                quantity: newQty,
                cataloguedProductId: Number(cataloguedProductId),
              })
            }}
          />
        ) : (
          <p>{quantity}</p>
        )}
      </div>
    )
  }

  const renderOptions = (record: RequisitionLine) => {
    const { orderInstruction, department, account, id, productId, cataloguedProductId } = record
    const hasDeptAndAccount = department && account
    return (
      <div className="flex items-center justify-center gap-x-1.5">
        <Tooltip
          content={t(
            'shopPage.cart.summary.table.noteTooltipContent',
            'Click to add order instruction notes to this product. The instruction will be included on the Purchase Order sent to the Supplier.'
          )}
        >
          <Button
            data-testid="add-order-instruction-btn"
            className={classNames('rounded-full  p-1 ', {
              'bg-primary/10 hover:bg-primary/20': orderInstruction,
              'text-primary': orderInstruction,
              'bg-gray-200': !orderInstruction,
              'text-gray-500': !orderInstruction,
            })}
            onClick={() => {
              setActiveLineId(record.id)
              setShowAddOrderInstructionModal(true)
            }}
          >
            <NoteIcon className="h-7 w-7" />
          </Button>
        </Tooltip>
        <Tooltip
          content={t(
            'shopPage.cart.summary.table.deptAndAccountTooltipContent',
            'Click to add a specific Department and Account cost centre code to this product. Your organisation may use cost centre codes to manage and track expenses.'
          )}
        >
          <Button
            data-testid="add-dept-account-btn"
            className={classNames('rounded-full  p-1 ', {
              'bg-primary/10 hover:bg-primary/20': hasDeptAndAccount,
              'text-primary': hasDeptAndAccount,
              'bg-gray-200': !hasDeptAndAccount,
              'text-gray-500': !hasDeptAndAccount,
            })}
            onClick={() => {
              setActiveLineId(record.id)
              setShowAddDeptAndAccountModal(true)
            }}
          >
            <SettingsIcon className="h-7 w-7" />
          </Button>
        </Tooltip>
        <Tooltip
          content={t(
            'shopPage.cart.summary.table.removeProductTooltipContent',
            'Click to remove this product from the Requisition.'
          )}
        >
          <Button
            className="rounded-full bg-gray-200 p-1 text-gray-500"
            disabled={loading && activeLineId === record.id && actionUpdateType === 'removeLine'}
            loading={loading && activeLineId === record.id && actionUpdateType === 'removeLine'}
            onClick={(e) => {
              e.stopPropagation()
              onUpdateRequisitionLine({
                id: Number(id),
                productId: Number(productId),
                cataloguedProductId: Number(cataloguedProductId),
                quantity: 0,
              })
            }}
          >
            <TrashIcon className="h-7 w-7" />
          </Button>
        </Tooltip>
        <AddOrderInstructionModal
          showModal={showAddOrderInstructionModal && activeLineId === record.id}
          setShowModal={setShowAddOrderInstructionModal}
          line={record as RequisitionLine}
        />
        <AddDeptAndAccountModal
          showModal={showAddDeptAndAccountModal && activeLineId === record.id}
          setShowModal={setShowAddDeptAndAccountModal}
          line={record as RequisitionLine}
        />
      </div>
    )
  }

  const renderUnitPrice = ({ unitPrice }: RequisitionLine) => {
    return <p>{formatMoney(unitPrice)}</p>
  }

  const renderTax = ({ taxPercentage }: RequisitionLine) => {
    return <p>{`${taxPercentage}%`}</p>
  }

  const renderTotal = ({ total }: RequisitionLine) => {
    return <p>{formatMoney(total)}</p>
  }

  const renderExtraLine = ({ orderInstruction, department, account, id, productId }: RequisitionLine) => {
    return (
      <>
        {orderInstruction && (
          <ExtraLine
            theme={Themes.Primary}
            dataTestId="order-instruction-block"
            removeActionTestId="remove-order-instruction-btn"
            title={t('shopPage.cart.summary.table.orderInstruction', 'Order Instruction')}
            value={orderInstruction}
            showRemoveLineAction={!readonly}
            removeLineLoading={loadingUpdate && activeLineId === id && actionUpdateType === 'orderInstruction'}
            removeLineTooltipContent={t(
              'shopPage.cart.summary.table.removeOrderInstructionTootipContent',
              'Remove Order Instruction'
            )}
            onLineClick={() => {
              setActiveLineId(id)
              setShowAddOrderInstructionModal(true)
            }}
            onRemoveLine={() => {
              onRemoveOrderInstruction({
                id: Number(id),
                productId: Number(productId),
              })
            }}
          />
        )}
        {department && account && (
          <ExtraLine
            theme={Themes.Primary}
            dataTestId="dept-account-block"
            removeActionTestId="remove-dept-account-btn"
            title={t('shopPage.cart.summary.table.deptAndAccount', 'Department and Account Code')}
            value={`${department.name} ${account.code}`}
            showRemoveLineAction={!readonly}
            removeLineLoading={loadingUpdate && activeLineId === id && actionUpdateType === 'deptAndAccount'}
            removeLineTooltipContent={t(
              'shopPage.cart.summary.table.removeDeptAndAccountTooltipContent',
              'Remove Department and Account Code'
            )}
            onLineClick={() => {
              setActiveLineId(id)
              setShowAddDeptAndAccountModal(true)
            }}
            onRemoveLine={() => {
              onRemoveDeptAndAccount({
                id: Number(id),
                productId: Number(productId),
              })
            }}
          />
        )}
      </>
    )
  }

  return (
    <Table
      dataTestId="summary-items"
      dataSource={lines}
      keyExtractor={(record) => String(record.id)}
      columnsHidden={readonly ? ['options'] : []}
      hasExtraLine={({ orderInstruction, department, account }) => {
        return !!(orderInstruction || (department && account))
      }}
      renderExtraLine={renderExtraLine}
      columns={[
        {
          title: t('shopPage.cart.summary.table.product', 'Product'),
          key: 'product',
          headerCellStyles: 'px-4 py-3',
          cellStyles: 'px-4 py-3',
          minWidth: 220,
          grow: true,
          render: renderProduct,
        },
        {
          title: t('general.quotes', 'Quotes'),
          key: 'quotes',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'text-center py-3',
          minWidth: 70,
          maxWidth: 70,
          render: ({ availableQuotes, product, supplier, id, image, quantity }: RequisitionLine) => (
            <SupplierQuotesButton
              product={product}
              prices={availableQuotes}
              currentSupplierId={supplier?.id}
              lineId={id}
              image={image}
              quantity={quantity}
            />
          ),
        },
        {
          title: t('shopPage.cart.summary.table.quantity', 'Quantity'),
          key: 'quantity',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 200,
          maxWidth: 200,
          render: renderQuantity,
        },
        {
          title: t('shopPage.cart.summary.table.options', 'Options'),
          key: 'options',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 128,
          maxWidth: 128,
          render: renderOptions,
        },
        {
          title: t('shopPage.cart.summary.table.unitPrice', 'Unit Price'),
          key: 'unitPrice',
          headerCellStyles: 'pr-4 py-3 text-right',
          cellStyles: 'pr-4 py-3 text-right',
          minWidth: 90,
          maxWidth: 120,
          render: renderUnitPrice,
        },
        {
          title: t('shopPage.cart.summary.table.tax', 'Tax'),
          key: 'tax',
          headerCellStyles: 'pr-4 py-3 text-right',
          cellStyles: 'pr-4 py-3 text-right',
          minWidth: 70,
          maxWidth: 70,
          render: renderTax,
        },
        {
          title: t('shopPage.cart.summary.table.total', 'Total'),
          key: 'total',
          headerCellStyles: 'pr-4 py-3 text-right',
          cellStyles: 'pr-4 py-3 text-right',
          minWidth: 90,
          maxWidth: 140,
          render: renderTotal,
        },
      ]}
    />
  )
}
