import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import StockTakeCountStatus from '../../../components/StockTakeCountStatus'
import StockTakeStatus from '../../../components/StockTakeStatus'

import { StockTake } from '@/graphql/inventory/generated/inventory_graphql'
import { PageInfo } from '@/graphql/purchasing/generated/purchasing_graphql'
import { MoreOptionsMenu, PPNewDetailLink } from '@/modules/shared/components'
import Table from '@/modules/shared/components/table/Table'
import { OptionIcon } from '@/modules/shared/icons'
import { Dates, StocktakeCountState, StocktakeState } from '@/modules/shared/types'
import { formatDate } from '@/modules/shared/utils'

export interface AllStockTakesTableViewProps {
  stocktakes: StockTake[]
  paginationLoading: boolean
  pageInfo?: Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'endCursor'>
  onNextPage: () => void
  onPreviousPage: () => void
}

function AllStockTakesTableView({
  stocktakes,
  pageInfo,
  onNextPage,
  onPreviousPage,
  paginationLoading,
}: AllStockTakesTableViewProps) {
  const { t } = useTranslation()

  const renderLocation = ({ stockLocation, id }: StockTake) => (
    <PPNewDetailLink type="stocktake" id={String(id)} secondaryId={String(stockLocation?.id)}>
      {stockLocation?.name}
    </PPNewDetailLink>
  )

  const renderStatus = ({ closedAt }: StockTake) => (
    <StockTakeStatus state={closedAt ? StocktakeState.Closed : StocktakeState.InProgress} />
  )

  const renderCountStatus = ({ stockTakeStatus }: StockTake) => (
    <StockTakeCountStatus state={stockTakeStatus as StocktakeCountState} />
  )

  const renderPeriod = ({ periodEndingOn }: StockTake) => formatDate(Dates.MonthYear, periodEndingOn)

  const renderBlind = ({ blind }: StockTake) => (blind ? t('general.yes', 'Yes') : t('general.no', 'No'))

  const renderMoreOptions = ({ stockLocation, id }: StockTake) => (
    <MoreOptionsMenu
      showOpenInPPlus
      showHelp
      stockTakeInfo={{ stockLocationId: Number(stockLocation?.id), stocktakeId: id }}
    >
      <OptionIcon className="h-10 w-10 text-gray-500" />
    </MoreOptionsMenu>
  )

  return (
    <Table
      dataTestId="stocktakes-table-view"
      dataSource={stocktakes || []}
      keyExtractor={(record) => String(record.id)}
      pagination={{
        hasNextPage: pageInfo?.hasNextPage,
        hasPreviousPage: pageInfo?.hasPreviousPage,
        onNextPage: onNextPage,
        onPreviousPage: onPreviousPage,
        loading: paginationLoading,
        endCursor: pageInfo?.endCursor,
      }}
      columns={[
        {
          title: t('general.Location', 'Location'),
          key: 'stocktake',
          headerCellStyles: 'px-4 py-3',
          cellStyles: 'px-4 py-3',
          minWidth: 130,
          maxWidth: 130,
          grow: true,
          render: renderLocation,
        },
        {
          title: t('stocktake.details.statusTableHeader', 'Status'),
          key: 'status',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 160,
          maxWidth: 160,
          render: renderStatus,
        },
        {
          title: t('general.countStatus', 'Count Status'),
          key: 'countStatus',
          headerCellStyles: 'px-2 py-3 text-center',
          cellStyles: 'px-2 py-3 text-center',
          minWidth: 160,
          maxWidth: 160,
          render: renderCountStatus,
        },
        {
          title: t('general.period', 'Period'),
          key: 'period',
          headerCellStyles: 'px-2 py-3 text-center',
          cellStyles: 'px-2 py-3 text-center',
          minWidth: 120,
          maxWidth: 120,
          render: renderPeriod,
        },
        {
          title: t('general.blind', 'Blind'),
          key: 'blind',
          headerCellStyles: 'px-2 py-3 text-center',
          cellStyles: 'px-2 py-3 text-center',
          minWidth: 70,
          maxWidth: 70,
          render: renderBlind,
        },
        {
          title: t('stocktake.details.moreTableHeader', 'More'),
          key: 'more',
          headerCellStyles: 'px-1 py-3 text-center',
          cellStyles: 'px-1 py-3 text-center',
          minWidth: 70,
          maxWidth: 70,
          render: renderMoreOptions,
        },
      ]}
    />
  )
}

export default memo(AllStockTakesTableView)
