import { useMutation } from '@apollo/client'
import { autoUpdate, useFloating } from '@floating-ui/react-dom'
import { Popover, Transition } from '@headlessui/react'
import { Fragment, PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, generatePath } from 'react-router-dom'

import Button from '../button'
import PPNewDetailLink from '../ppnew-detail-link'
import Tooltip from '../tooltip'
import MoreOptionsMenuInvoiceActions from './MoreOptionsMenuInvoiceActions'

import { ResendDeliveryDocument } from '@/graphql/purchasing/generated/purchasing_graphql'
import { InvoiceActionTypes } from '@/modules/invoices/types'
import { ReceivingNoteState } from '@/modules/receiving-notes/types'
import {
  ENTERPRISE_GR_URL,
  ENTERPRISE_INVOICE_URL,
  ENTERPRISE_PO_URL,
  ENTERPRISE_PRICED_CATALOGS_URL,
  ENTERPRISE_PURCHASING_CATALOGUES_URL,
  ENTERPRISE_PURCHASING_USERS_URL,
  ENTERPRISE_REQUISITION_URL,
  ENTERPRISE_STOCK_LOCATION_URL,
  ENTERPRISE_SUPPLIER_RELATIONSHIP_URL,
  ENTERPRISE_SUPPLIER_URL,
  ENTERPRISE_USERS_URL,
  PURCHASING_GRAPHQL_API,
  SUPPORT_LINK,
} from '@/modules/shared/constants'
import {
  ClockIcon,
  GetHelpIcon,
  HeartIcon,
  InfoIcon,
  NewTabIcon,
  PrintIcon,
  ReorderIcon,
  SettingsIcon,
  TrashIcon,
} from '@/modules/shared/icons'
import { Portal } from '@/modules/shared/layouts'

interface MoreOptionsMenuProps extends PropsWithChildren {
  showHelp?: boolean
  showPrint?: boolean
  showOpenInPPlus?: boolean
  showAuditLog?: boolean
  showInvoiceLink?: boolean
  showDetails?: (showModal: boolean) => void
  showAddToBuyList?: () => void
  showProductLink?: boolean
  productId?: string | number
  catalogId?: string | number
  buttonTheme?: 'light' | 'dark'
  requisitionId?: string | number
  buyListId?: string | number
  userId?: string | number
  accessUserId?: string | number
  pricedCatalogId?: string | number
  stockLocationId?: string | number
  invoiceId?: string | number
  sendEventId?: string | number
  purchaseOrderId?: string | number
  isResendable?: boolean
  receivingNoteId?: string | number
  deliveriesPurchaseOrderId?: string | number
  showViewRequisition?: boolean
  supplier?: boolean
  supplierId?: string | number
  showPurchaseOrderLink?: boolean
  showReceivingNoteLink?: boolean
  receivingNoteState?: ReceivingNoteState
  showReopenInvoice?: boolean
  showCancelInvoice?: boolean
  showHaltInvoice?: boolean
  showResendInvoice?: boolean
  showParkInvoice?: boolean
  onShowConfirmActionDialog?: (action: InvoiceActionTypes) => void
  allBuyLists?: boolean
  stockTakeInfo?: { stockLocationId: number; stocktakeId: number }
  allRequisitions?: boolean
  allInvoices?: boolean
  allSelfManagedCatalogs?: boolean
  showAccountConfig?: {
    onClick?: () => void
    hasAccount?: boolean
  }
  showRemoveFromBuyList?: () => void
}
export default function MoreOptionsMenu({
  children,
  showHelp,
  showPrint,
  showOpenInPPlus,
  showAddToBuyList,
  showAuditLog,
  buttonTheme,
  requisitionId,
  buyListId,
  userId,
  accessUserId,
  pricedCatalogId,
  showDetails,
  showProductLink,
  stockLocationId,
  invoiceId,
  showInvoiceLink,
  sendEventId,
  purchaseOrderId,
  deliveriesPurchaseOrderId,
  isResendable,
  receivingNoteId,
  productId,
  catalogId,
  showViewRequisition,
  supplier,
  supplierId,
  showPurchaseOrderLink,
  showReceivingNoteLink,
  receivingNoteState,
  showReopenInvoice,
  showCancelInvoice,
  showHaltInvoice,
  showResendInvoice,
  showParkInvoice,
  onShowConfirmActionDialog,
  allBuyLists,
  stockTakeInfo,
  allRequisitions,
  allInvoices,
  allSelfManagedCatalogs,
  showAccountConfig,
  showRemoveFromBuyList,
}: MoreOptionsMenuProps) {
  const { t } = useTranslation()
  const {
    x,
    y,
    strategy,
    refs: { setReference, setFloating },
  } = useFloating({
    placement: 'bottom-end',
    whileElementsMounted: autoUpdate,
  })
  const [resendDelivery, { loading }] = useMutation(ResendDeliveryDocument, {
    variables: {
      input: {
        id: Number(sendEventId),
      },
    },
    context: { uri: PURCHASING_GRAPHQL_API },
  })
  const OpenInEnterpriseURL = (): string | undefined => {
    let url = process.env.REACT_APP_NINJA_API_HOST
    if (requisitionId) {
      url = `${ENTERPRISE_REQUISITION_URL}/${requisitionId}`
    } else if (buyListId) {
      url = `${ENTERPRISE_PURCHASING_CATALOGUES_URL}/${buyListId}`
    } else if (userId) {
      url = `${ENTERPRISE_PURCHASING_USERS_URL}/${userId}`
    } else if (accessUserId) {
      url = `${ENTERPRISE_USERS_URL}/${accessUserId}`
    } else if (pricedCatalogId) {
      url = `${ENTERPRISE_PRICED_CATALOGS_URL}/${pricedCatalogId}`
    } else if (stockLocationId) {
      url = `${ENTERPRISE_STOCK_LOCATION_URL}/${stockLocationId}`
    } else if (invoiceId) {
      url = `${ENTERPRISE_INVOICE_URL}/${invoiceId}`
    } else if (purchaseOrderId) {
      url = `${ENTERPRISE_PO_URL}/${purchaseOrderId}`
    } else if (deliveriesPurchaseOrderId && sendEventId) {
      url = `${ENTERPRISE_PO_URL}/${purchaseOrderId}/deliveries`
    } else if (receivingNoteId) {
      url = `${ENTERPRISE_GR_URL}/${receivingNoteId}`
    } else if (supplier) {
      url = `${ENTERPRISE_SUPPLIER_URL}`
    } else if (supplierId) {
      url = `${ENTERPRISE_SUPPLIER_RELATIONSHIP_URL}/${supplierId}`
    } else if (allBuyLists) {
      url = ENTERPRISE_PURCHASING_CATALOGUES_URL
    } else if (stockTakeInfo) {
      url = `${ENTERPRISE_STOCK_LOCATION_URL}/${stockTakeInfo.stockLocationId}/stock_takes/${stockTakeInfo.stocktakeId}/stock_counts/count`
    } else if (allRequisitions) {
      url = ENTERPRISE_REQUISITION_URL
    } else if (allInvoices) {
      url = ENTERPRISE_INVOICE_URL
    } else if (allSelfManagedCatalogs) {
      url = ENTERPRISE_PRICED_CATALOGS_URL
    }
    return url
  }
  const buttonThemeStyle = (): string => {
    switch (buttonTheme) {
      case 'dark':
        return 'items-center justify-between rounded-full bg-gray-200 p-1.5 text-gray-600 outline-none hover:bg-gray-300'
      default:
        return 'items-center justify-between outline-none hover:opacity-75'
    }
  }
  return (
    <div className="flex h-full items-center justify-center">
      <Tooltip content={t('requisition.details.moreOptions.tooltip', 'More Options')} showArrow={false}>
        <Popover className="relative flex items-center justify-center">
          <Popover.Button className={buttonThemeStyle()} data-testid="more-options-button" ref={setReference}>
            {children}
          </Popover.Button>
          <Portal>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Popover.Panel
                data-testid="more-options-menu"
                className="z-10 rounded-md border bg-white shadow-lg"
                ref={setFloating}
                style={{
                  position: strategy,
                  top: y ?? 0,
                  left: x ?? 0,
                }}
              >
                <div className="py-1">
                  {showAuditLog && (
                    <Link
                      className="flex w-full items-center px-4 py-2 text-left text-sm text-gray-700 transition hover:bg-gray-100 hover:text-gray-900"
                      data-testid="audit-log"
                      to="audit-log"
                    >
                      <ClockIcon className="mr-2 h-5 w-5" />
                      {t('auditLog.view', 'View Audit Log')}
                    </Link>
                  )}
                  {showPurchaseOrderLink && purchaseOrderId && (
                    <PPNewDetailLink
                      type="purchase-order"
                      testId="view-purchase-order"
                      id={String(purchaseOrderId)}
                      className="flex w-full items-center px-4 py-2 text-left text-sm text-gray-700 transition hover:bg-gray-100 hover:text-gray-900"
                    >
                      <InfoIcon className="mr-2 h-5 w-5" />
                      {t('purchaseOrders.viewPurchaseOrder', 'View Purchase Order')}
                    </PPNewDetailLink>
                  )}
                  {showReceivingNoteLink && receivingNoteId && receivingNoteState && purchaseOrderId && (
                    <Link
                      className="flex w-full items-center px-4 py-2 text-left text-sm text-gray-700 transition hover:bg-gray-100 hover:text-gray-900"
                      data-testid="view-receiving-note"
                      to={generatePath(
                        `/purchase-orders/:purchaseOrderId/receiving-notes/:receivingNoteId/${
                          receivingNoteState === ReceivingNoteState.Open ? 'draft' : ''
                        }`,
                        {
                          purchaseOrderId: String(purchaseOrderId),
                          receivingNoteId: String(receivingNoteId),
                        }
                      )}
                    >
                      <InfoIcon className="mr-2 h-5 w-5" />
                      {t('receivingNotes.viewReceivingNote', 'View Receiving Note')}
                    </Link>
                  )}
                  {invoiceId && showInvoiceLink && (
                    <PPNewDetailLink
                      type="invoice"
                      testId="view-invoice"
                      id={String(invoiceId)}
                      className="flex w-full items-center px-4 py-2 text-left text-sm text-gray-700 transition hover:bg-gray-100 hover:text-gray-900"
                    >
                      <InfoIcon className="mr-2 h-5 w-5" />
                      {t('invoices.viewInvoice', 'View Invoice')}
                    </PPNewDetailLink>
                  )}
                  {showViewRequisition && requisitionId && (
                    <PPNewDetailLink
                      className="flex w-full items-center px-4 py-2 text-left text-sm text-gray-700 transition hover:bg-gray-100 hover:text-gray-900"
                      type="requisition"
                      id={String(requisitionId)}
                      testId="view-requisition"
                    >
                      <InfoIcon className="mr-2 h-5 w-5" />
                      {t('requisition.viewRequisition', 'View Requisition')}
                    </PPNewDetailLink>
                  )}
                  {showProductLink && catalogId && productId && (
                    <Link
                      className="flex w-full items-center px-4 py-2 text-left text-sm text-gray-700 transition hover:bg-gray-100 hover:text-gray-900"
                      data-testid="view-product"
                      to={generatePath('/catalogs/self-managed/:catalogId/products/:productId', {
                        catalogId: String(catalogId),
                        productId: String(productId),
                      })}
                    >
                      <InfoIcon className="mr-2 h-5 w-5" />
                      {t('catalogs.viewProduct', 'View Product')}
                    </Link>
                  )}
                  {isResendable && sendEventId && (
                    <Button
                      loading={loading}
                      className="flex w-full items-center px-4 py-2 text-left text-sm text-gray-700  hover:bg-gray-100 hover:text-gray-900"
                      onClick={() => resendDelivery()}
                    >
                      <ReorderIcon className="mr-2 h-5 w-5" />
                      {t('purchaseOrders.purchaseOrder.sendEvents.resend', 'Resend')}
                    </Button>
                  )}
                  {showPrint && (
                    <a
                      className="flex w-full items-center px-4 py-2 text-left text-sm text-gray-700 transition hover:bg-gray-100 hover:text-gray-900"
                      data-testid="print"
                      href={`${OpenInEnterpriseURL()}/print`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PrintIcon className="mr-2 h-5 w-5" />
                      {t('purchaseOrders.purchaseOrder.print', 'Print')}
                    </a>
                  )}
                  {showOpenInPPlus && (
                    <a
                      className="flex w-full items-center px-4 py-2 text-left text-sm text-gray-700 transition hover:bg-gray-100  hover:text-gray-900"
                      data-testid="view-in-enterprise"
                      href={OpenInEnterpriseURL()}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <NewTabIcon className="mr-2 h-5 w-5" />
                      {t('requisition.openInPPlusSignEnterprise', 'Open in P+ Enterprise')}
                    </a>
                  )}
                  {invoiceId && (
                    <MoreOptionsMenuInvoiceActions
                      invoiceId={invoiceId}
                      showReopenInvoice={showReopenInvoice}
                      showCancelInvoice={showCancelInvoice}
                      showHaltInvoice={showHaltInvoice}
                      showParkInvoice={showParkInvoice}
                      showResendInvoice={showResendInvoice}
                      onShowConfirmActionDialog={onShowConfirmActionDialog}
                    />
                  )}
                  {showAccountConfig && (
                    <button
                      className="flex w-full cursor-pointer items-center px-4 py-2 text-left text-sm text-gray-700 transition hover:bg-gray-100 hover:text-gray-900"
                      onClick={showAccountConfig.onClick}
                      data-testid="change-account-buy-list"
                    >
                      <SettingsIcon className="mr-2 h-5 w-5" />
                      {showAccountConfig.hasAccount
                        ? t('general.updateAccount', 'Update Account Code')
                        : t('general.addAccount', 'Add Account Code')}
                    </button>
                  )}
                  {showHelp && (
                    <a
                      className="flex w-full items-center px-4 py-2 text-left text-sm text-gray-700 transition hover:bg-gray-100 hover:text-gray-900"
                      href={SUPPORT_LINK}
                      data-testid="view-support-link"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <GetHelpIcon className="mr-2 h-5 w-5" />
                      {t('requisition.getHelp', 'Get Help')}
                    </a>
                  )}
                  {showRemoveFromBuyList && (
                    <button
                      className="flex w-full cursor-pointer items-center px-4 py-2 text-left text-sm text-gray-700 transition hover:bg-gray-100 hover:text-gray-900"
                      onClick={showRemoveFromBuyList}
                      data-testid="remove-from-buy-list"
                    >
                      <TrashIcon className="mr-2 h-5 w-5" />
                      {t('general.removeProduct', 'Remove from Buy List')}
                    </button>
                  )}
                  {showDetails && (
                    <p
                      className="flex w-full cursor-pointer items-center px-4 py-2 text-left text-sm text-gray-700 transition hover:bg-gray-100 hover:text-gray-900"
                      onClick={() => showDetails(true)}
                    >
                      <InfoIcon className="mr-2 h-5 w-5" />
                      {t('shopPage.productList.viewProductDetails', 'View Product Details')}
                    </p>
                  )}
                  {showAddToBuyList && (
                    <p
                      className="flex w-full cursor-pointer items-center px-4 py-2 text-left text-sm text-gray-700 transition hover:bg-gray-100 hover:text-gray-900"
                      onClick={showAddToBuyList}
                    >
                      <HeartIcon className="mr-2 h-5 w-5" />
                      {t('moreOptions.addToBuyList', 'Add to Buy List')}
                    </p>
                  )}
                </div>
              </Popover.Panel>
            </Transition>
          </Portal>
        </Popover>
      </Tooltip>
    </div>
  )
}
