import { ApolloError } from '@apollo/client/errors'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { InvoiceActionTypes } from '../types'
import {
  useCancelInvoice,
  useCompleteInvoice,
  useFinancialApproveInvoice,
  useGetInvoice,
  useHaltInvoice,
  useOperationalApproveInvoice,
  useParkInvoice,
  useProcessInvoice,
  useReopenInvoice,
  useResendInvoice,
} from './index'

import { useAlert } from '@/modules/shared/hooks'

export default function useInvoiceActions(invoiceId: number, refetch: () => void) {
  const [showConfirmActionDialog, setShowConfirmActionDialog] = useState<boolean>(false)
  const [invoiceAction, setInvoiceAction] = useState<InvoiceActionTypes | null>(null)
  const [showInvoiceActionSuccessModal, setShowInvoiceActionSuccessModal] = useState<boolean>(false)
  const { alertDialog } = useAlert()
  const { t } = useTranslation()

  const [operationalApproveInvoice, { loading: operationalApproveInvoiceLoading }] = useOperationalApproveInvoice()
  const [financialApproveInvoice, { loading: financialApproveInvoiceLoading }] = useFinancialApproveInvoice()
  const [completeInvoice, { loading: completeInvoiceLoading }] = useCompleteInvoice()
  const [processInvoice, { loading: processInvoiceLoading }] = useProcessInvoice()
  const [cancelInvoice, { loading: cancelInvoiceLoading }] = useCancelInvoice()
  const [reopenInvoice, { loading: reopenInvoiceLoading }] = useReopenInvoice()
  const [resendInvoice, { loading: resendInvoiceLoading }] = useResendInvoice()
  const [haltInvoice, { loading: haltInvoiceLoading }] = useHaltInvoice()
  const [parkInvoice, { loading: parkInvoiceLoading }] = useParkInvoice()
  const { invoice } = useGetInvoice()

  const mutationOptions = {
    variables: { input: { id: invoiceId } },
    onCompleted: () => {
      setShowConfirmActionDialog(false)
      setShowInvoiceActionSuccessModal(true)
    },
  }

  const approveErrors = {
    onError: ({ graphQLErrors }: ApolloError) => {
      graphQLErrors?.forEach((e) => {
        if (e.message.includes('is now reconciled')) {
          alertDialog({
            type: 'success',
            title: t('invoices.invoice.actionSuccess.approve.title', 'Successfully Reconciled'),
            message: t(
              'invoices.invoice.actionSuccess.approve.message',
              ' You have successfully granted Approval and the Invoice has been updated to Reconciled. Changes can no longer be made to the Invoice while it is in a Reconciled state. You may wish to mark the Invoice as Ready for Processing, and this will place the Invoice into your PurchasePlus Accounts Payable Hub, ready to be processed. Once the Invoice has been exported from Accounts Payable Hub to your external Accounting system it will be automatically marked as Processed.'
            ),
            buttonText: t('general.okay', 'Okay'),
          })
          refetch()
        } else {
          alertDialog({
            type: 'error',
            title: t('invoices.invoice.actionError.approve.title', 'Approval Not Permitted'),
            message: String(e.path![0]).includes('financial')
              ? t(
                  'invoices.invoice.actionSuccess.financialApprove.message',
                  "You don't have the permission to Financially Approve this Invoice. Please speak with a PurchasePlus administrator at your organisation if you think you need this permission."
                )
              : t(
                  'invoices.invoice.actionSuccess.operationalApprove.message',
                  "You don't have the permission to Operationally Approve this Invoice. Please speak with a PurchasePlus administrator at your organisation if you think you need this permission."
                ),
            buttonText: t('general.okay', 'Okay'),
          })
        }
      })
    },
  }

  const onShowConfirmActionDialog = (action: InvoiceActionTypes) => {
    setInvoiceAction(action)
    setShowConfirmActionDialog(true)
  }

  const onConfirmInvoiceAction = (action?: InvoiceActionTypes) => {
    setInvoiceAction(action ?? invoiceAction)
    switch (action ?? invoiceAction) {
      case InvoiceActionTypes.OperationalApprove:
        return operationalApproveInvoice({ ...mutationOptions, ...approveErrors })
      case InvoiceActionTypes.FinancialApprove:
        return financialApproveInvoice({ ...mutationOptions, ...approveErrors })
      case InvoiceActionTypes.Process:
        return processInvoice(mutationOptions)
      case InvoiceActionTypes.Cancel:
        return cancelInvoice(mutationOptions)
      case InvoiceActionTypes.Reopen:
        return reopenInvoice(mutationOptions)
      case InvoiceActionTypes.Resend:
        return resendInvoice(mutationOptions)
      case InvoiceActionTypes.Halt:
        return haltInvoice(mutationOptions)
      case InvoiceActionTypes.Park:
        return parkInvoice(mutationOptions)
      case InvoiceActionTypes.Complete:
        if (invoice?.outOfBalance) {
          onShowConfirmActionDialog(InvoiceActionTypes.Complete)
        } else {
          completeInvoice(mutationOptions)
        }
    }
  }

  const confirmActionLoading =
    operationalApproveInvoiceLoading ||
    financialApproveInvoiceLoading ||
    completeInvoiceLoading ||
    processInvoiceLoading ||
    cancelInvoiceLoading ||
    reopenInvoiceLoading ||
    resendInvoiceLoading ||
    haltInvoiceLoading ||
    parkInvoiceLoading

  return {
    showConfirmActionDialog,
    setShowConfirmActionDialog,
    showInvoiceActionSuccessModal,
    setShowInvoiceActionSuccessModal,
    onShowConfirmActionDialog,
    invoiceAction,
    confirmActionLoading,
    onConfirmInvoiceAction,
  }
}
