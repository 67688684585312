import classNames from 'classnames'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Fallback from '../../fallback'
import SupplierQuotesModal from '../../supplier-quotes-modal/SupplierQuotesModal'
import Tooltip from '../../tooltip'

import { Price, Product } from '@/graphql/purchasing/generated/purchasing_graphql'

export interface SupplierQuotesColumnProps {
  product: Product | null
  prices: Price[] | null
  currentSupplierId?: number | null // the supplier of the currently selected
  lineId?: number | null // a lineId is required to change the supplier
  image?: string | null
  basicShop?: boolean // displays the basic shop text
  cataloguedProductId?: number | null // only in advance mode
  quantity?: number | null // only in advance mode
}

export const SupplierQuotesButton = ({
  product,
  prices,
  currentSupplierId,
  lineId,
  image,
  cataloguedProductId,
  basicShop,
  quantity,
}: SupplierQuotesColumnProps) => {
  const { t } = useTranslation()
  const [showSupplierQuotesModal, setShowSupplierQuotesModal] = useState<boolean>(false)

  const hasQuotes = !!(cataloguedProductId ? prices?.length : prices?.length && prices?.length - 1)
  const numberOfQuotes = cataloguedProductId ? prices?.length || 0 : (prices?.length || 0) - 1

  const hasQuotesText = () => {
    if (cataloguedProductId) {
      return t('quotesButton.tooltip.advancedHasQuotes', 'There are {{numberOfQuotes}} available quotes.', {
        numberOfQuotes: hasQuotes ? numberOfQuotes : t('quotesButton.tooltip.no', 'no'),
      })
    }
    return t(
      'quotesButton.tooltip.hasQuotes',
      'There are {{numberOfQuotes}} supplier quotes available on this product.',
      { numberOfQuotes: hasQuotes ? numberOfQuotes : t('quotesButton.tooltip.no', 'no') }
    )
  }

  return (
    <>
      <Tooltip content={hasQuotesText()}>
        <div
          className={classNames('mx-auto flex h-10 w-10 items-center justify-center rounded-full', {
            'cursor-pointer bg-gray-200 hover:bg-gray-300': hasQuotes,
            'bg-gray-100': !cataloguedProductId && !hasQuotes,
          })}
          onClick={() => hasQuotes && setShowSupplierQuotesModal(true)}
          data-testid="supplier-quotes-button"
        >
          {/* Show count of prices not including the currently selected quote */}
          <Fallback condition={hasQuotes}>{numberOfQuotes}</Fallback>
        </div>
      </Tooltip>
      <SupplierQuotesModal
        showModal={showSupplierQuotesModal}
        setShowModal={setShowSupplierQuotesModal}
        product={product}
        prices={prices}
        currentSupplierId={currentSupplierId || undefined}
        lineId={lineId || undefined}
        image={image}
        basicShop={basicShop}
        cataloguedProductId={cataloguedProductId}
        originalQuantity={quantity}
      />
    </>
  )
}
