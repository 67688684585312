import classNames from 'classnames'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import DraftMoreOptionsMenu from './DraftMoreOptionsMenu'

import { Requisition } from '@/graphql/purchasing/generated/purchasing_graphql'
import { RefetchContext } from '@/modules/purchasing/pages/dashboard/draft-requisitions'
import { useCancelRequisition, useCheckShoppingAvailable } from '@/modules/requisitions/hooks'
import { generateShopPath } from '@/modules/requisitions/utils'
import { Button, ConfirmDialog, Fallback, SimpleTileViewItem } from '@/modules/shared/components'
import { useMoney } from '@/modules/shared/hooks'
import { Dates, Themes } from '@/modules/shared/types'
import { formatDate } from '@/modules/shared/utils'

interface DraftCardItemProps {
  item: Requisition
}

export default function DraftCardItem({ item }: DraftCardItemProps) {
  const { t } = useTranslation()
  const { formatMoney } = useMoney()
  const { ShoppingNotAvailableModal, modalProps, onCheckShoppingAvailable, onShowModal } = useCheckShoppingAvailable()
  const [isOpen, setIsOpen] = useState(false)

  const onCancelRefetch = useContext(RefetchContext)

  const [cancelRequistion, { loading }] = useCancelRequisition()

  const onCancelRequisition = () => {
    cancelRequistion({
      variables: { input: { id: item.id } },
      onCompleted() {
        onCancelRefetch && onCancelRefetch()
        setIsOpen(false)
      },
    })
  }
  return (
    <div key={item.id} className={classNames('mb-6', 'rounded-md', 'border', 'bg-white')}>
      <div data-testid="approval-item" className="flex flex-col justify-between p-4 md:flex-row">
        <div>
          <span className="break-all text-base font-bold">{item.reference || item.requisitionNumber}</span>
          <p className="text-sm text-gray-500">{item.requisitionNumber}</p>
        </div>
        <div>
          <div className="mt-4 text-left md:mt-0 md:text-right">
            <span className="text-base font-bold">{`${formatMoney(item.totalValue)}`}</span>
            <p className="text-sm text-gray-500">
              {t('requisition.total', 'Total')} ({t('requisition.includingTax', 'Including Tax')})
            </p>
          </div>
        </div>
      </div>
      <hr />
      <section data-testid="requisition-details" className="p-4 text-sm">
        <SimpleTileViewItem
          className="mb-3"
          title={t('requisition.details.deliveryDate', 'Delivery Date')}
          value={
            <Fallback condition={!!item.expectedDeliveryDate}>
              {formatDate(Dates.Short, item.expectedDeliveryDate)}
            </Fallback>
          }
        />
        <SimpleTileViewItem
          className="mb-3"
          title={t('requisition.details.department', 'Department')}
          value={item.department?.name}
        />
        <SimpleTileViewItem
          className="mb-3"
          title={t('requisition.details.accountCode', 'Account Code')}
          value={item.account?.accountName}
        />
      </section>
      <hr />
      <div className="flex w-full items-center justify-between gap-x-2 p-4">
        <Link
          to={generateShopPath(item?.id, item?.advanced)}
          onClick={(e) => {
            if (!onCheckShoppingAvailable(item)) {
              e.preventDefault()
              onShowModal()
            }
          }}
          data-testid="continue-shopping-button"
        >
          <Button className="rounded-md bg-gray-200 p-3.5 text-sm">
            {t('requisition.details.continueShopping', 'Continue Shopping')}
          </Button>
        </Link>
        <DraftMoreOptionsMenu requisitionId={item.id} setIsOpen={setIsOpen} />
      </div>
      <ConfirmDialog
        title={t('dashboard.draftRequisitions.confirm.cancelRequisition', 'Cancel Requisition?')}
        description={t(
          'dashboard.draftRequisitions.confirm.cancelDescription',
          'Please confirm below if you wish to cancel the Requisition. Once cancelled it will be removed from your drafts and will no longer be available.'
        )}
        primaryButtonLabel={t('dashboard.draftRequisitions.confirm.continue', 'Cancel Requisition')}
        secondaryButtonLabel={t('dashboard.draftRequisitions.confirm.cancel', 'Keep as Draft')}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onPrimaryBtnClick={() => onCancelRequisition()}
        theme={Themes.Primary}
        btnPrimaryLoading={loading}
      />
      <ShoppingNotAvailableModal {...modalProps} />
    </div>
  )
}
