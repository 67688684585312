import { useTranslation } from 'react-i18next'

import { InvoiceLineItem, ReceivingDocumentLineItem } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Fallback, TableLineItem } from '@/modules/shared/components'
import { extractEdges, formatNumber } from '@/modules/shared/utils'

interface OrderQtySummaryProps {
  receivingDocumentLineItem: ReceivingDocumentLineItem
}

export default function OrderQtySummary({ receivingDocumentLineItem }: OrderQtySummaryProps) {
  const { purchaseOrderLineItem, receivedQuantity } = receivingDocumentLineItem
  const yetToReceive = (purchaseOrderLineItem?.quantity || 0) - (receivedQuantity || 0)
  const totalInvoicedQuantity = extractEdges<InvoiceLineItem>(purchaseOrderLineItem?.invoiceLineItems).reduce(
    (acc, curr) => acc + (curr.quantity || 0),
    0
  )
  const { t } = useTranslation()
  return (
    <section>
      <strong className="text-sm">{t('receivingNotes.advancedModal.orderQtySummary', 'Order Quantity Summary')}</strong>
      <p className="text-sm text-gray-500">
        {t(
          'receivingNotes.advancedModal.orderQtySummaryDesc',
          'A summary of the ordered quantity compared to the quantity already received.'
        )}
      </p>
      <div className="mt-4 border">
        <TableLineItem
          title={t('general.orderedQty', 'Ordered Qty')}
          tooltip={t('receivingNotes.orderedQtyTooltip', 'The quantity on the Purchase Order.')}
          value={purchaseOrderLineItem?.quantity || 0}
        />
        <TableLineItem
          title={t('general.invoicedQty', 'Invoiced Qty')}
          tooltip={t('receivingNotes.invoicedQtyTooltip', 'The total Invoiced quantity of all linked Invoice lines.')}
          value={totalInvoicedQuantity}
        />
        <TableLineItem
          title={t('general.alreadyReceived', 'Already Received')}
          tooltip={t(
            'receivingNotes.alreadyReceivedTooltip',
            'The quantity that has been already been received on one or more Receiving Notes.'
          )}
          value={receivedQuantity || 0}
        />
        <TableLineItem
          title={t('general.yetToReceive', 'Yet To Receive')}
          tooltip={t('receivingNotes.yetToReceiveTooltip', 'The quantity still outstanding and yet to be received.')}
          value={
            <Fallback condition={yetToReceive >= 0} fallbackValue="0">
              {formatNumber(yetToReceive, 3)}
            </Fallback>
          }
        />
      </div>
    </section>
  )
}
