import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, generatePath } from 'react-router-dom'

import { PurchaseOrderStatuses } from '../../types'
import { isPOMatchable } from './table-view'

import { Invoice, PurchaseOrder } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Button, Modal } from '@/modules/shared/components'
import { useMoney } from '@/modules/shared/hooks'
import { NewTabIcon } from '@/modules/shared/icons'
import { extractEdges } from '@/modules/shared/utils'

interface InvoicesModalProps {
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<number | null>>
  purchaseOrder: PurchaseOrder
  setShowMatchModal: Dispatch<SetStateAction<number | null>>
}

function InvoicesModal(props: InvoicesModalProps) {
  const { showModal, setShowModal, setShowMatchModal, purchaseOrder } = props
  const { t } = useTranslation()
  const { formatMoney } = useMoney()

  const onCloseModal = () => {
    setShowModal(null)
  }

  const invoices = extractEdges<Invoice>(purchaseOrder.invoices)

  return (
    <Modal showModal={showModal} onCloseModal={onCloseModal} dataTestId="invoices-modal">
      <Modal.Panel
        as="div"
        className="flex max-h-[80vh] w-full flex-col overflow-hidden rounded-md bg-white shadow-xl transition-all md:max-w-[600px]"
      >
        <Modal.Title
          title={t('purchaseOrders.allPurchaseOrders.table.invoiceModal.title', '{{ purchaseOrderNumber }} Invoices', {
            purchaseOrderNumber: purchaseOrder.purchaseOrderNumber,
          })}
          onCloseModal={onCloseModal}
        />

        <Modal.Body className="text-sm">
          <p className="font-bold">
            {t('purchaseOrders.allPurchaseOrders.table.invoiceModal.subtitle', 'Already Matched')}
          </p>
          <p className="text-gray-500">
            {t(
              'purchaseOrders.allPurchaseOrders.table.invoiceModal.body',
              'The following Invoices are already matched to this Purchase Order. You may wish to inspect each Invoice to manually link Invoice Lines to Purchase Order lines.'
            )}
          </p>
          {invoices.length ? (
            <div className="mt-2 flex flex-col divide-y rounded-md border">
              {invoices.map((invoice) => (
                <div className="flex justify-between p-3" key={invoice.id}>
                  <Link
                    to={generatePath('/invoices/:invoiceId/summary', { invoiceId: String(invoice.id) })}
                    className="flex items-center gap-x-1 text-primary"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>{invoice.invoiceNumber}</span>
                    <NewTabIcon className="h-5 w-5" />
                  </Link>
                  <p className="font-bold">{formatMoney(invoice.totalValue)}</p>
                </div>
              ))}
            </div>
          ) : (
            <p className="mt-5 border p-4 text-center">
              {t(
                'purchaseOrders.allPurchaseOrders.table.invoiceModal.noInvoices',
                'No Invoices have been matched to this Purchase Order yet.'
              )}
            </p>
          )}
          {!isPOMatchable(purchaseOrder) && (
            <p className="mt-4 text-gray-500">
              {purchaseOrder.status === PurchaseOrderStatuses.Cancelled
                ? t(
                    `purchaseOrders.allPurchaseOrders.table.invoiceModal.unmatchableCancelled`,
                    '{{ purchaseOrderNumber }} is in a Closed state and Invoices can no longer be matched to it.',
                    { purchaseOrderNumber: purchaseOrder.purchaseOrderNumber }
                  )
                : t(
                    `purchaseOrders.allPurchaseOrders.table.invoiceModal.unmatchableClosed`,
                    '{{ purchaseOrderNumber }} is in a Cancelled state and Invoices can no longer be matched to it.',
                    { purchaseOrderNumber: purchaseOrder.purchaseOrderNumber }
                  )}
            </p>
          )}
        </Modal.Body>
        <Modal.Footer className="flex flex-col-reverse items-center justify-end gap-2 md:flex-row">
          <Button
            type="button"
            className="w-full rounded-md bg-gray-200 px-8 py-2.5 text-sm md:w-auto"
            onClick={onCloseModal}
            data-testid="invoices-close"
          >
            {t('general.close', 'Close')}
          </Button>
          {Boolean(invoices.length) && isPOMatchable(purchaseOrder) && (
            <Button
              type="button"
              className="w-full rounded-md bg-primary px-8 py-2.5 text-sm text-white md:w-auto"
              onClick={() => {
                setShowMatchModal(purchaseOrder.id)
              }}
            >
              {t('purchaseOrders.allPurchaseOrders.table.invoiceModal.matchAnother', 'Match Another')}
            </Button>
          )}
        </Modal.Footer>
      </Modal.Panel>
    </Modal>
  )
}

export default InvoicesModal
