import { autoUpdate, useFloating } from '@floating-ui/react-dom'
import { Popover, Transition } from '@headlessui/react'
import { Fragment, PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'

import { PPNewDetailLink } from '@/modules/shared/components'
import { ENTERPRISE_PO_URL } from '@/modules/shared/constants'
import { InfoIcon, NewTabIcon } from '@/modules/shared/icons'
import { Portal } from '@/modules/shared/layouts'

interface OrderOptionsProps extends PropsWithChildren {
  purchaseOrderId: string
}

export default function OrderOptions({ children, purchaseOrderId }: OrderOptionsProps) {
  const { t } = useTranslation()
  const {
    x,
    y,
    strategy,
    refs: { setReference, setFloating },
  } = useFloating({
    placement: 'bottom-end',
    whileElementsMounted: autoUpdate,
  })

  return (
    <Popover className="relative flex items-center justify-center">
      <Popover.Button
        data-testid="more-option-button"
        className="items-center justify-between hover:opacity-75"
        ref={setReference}
      >
        {children}
      </Popover.Button>
      <Portal>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Popover.Panel
            data-testid="more-option-menu"
            className="z-10 w-max rounded-md border bg-white shadow-lg"
            ref={setFloating}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
            }}
          >
            <div className="py-1">
              <PPNewDetailLink
                openNewTab
                type="purchase-order"
                testId="view-purchase-order"
                id={purchaseOrderId}
                className="flex w-full items-center px-4 py-2 text-left text-sm text-gray-700 transition hover:bg-gray-100 hover:text-gray-900"
              >
                <InfoIcon className="mr-2 h-5 w-5" />
                {t('purchaseOrders.viewPurchaseOrder', 'View Purchase Order')}
              </PPNewDetailLink>
              <a
                className="flex w-full items-center px-4 py-2 text-left text-sm text-gray-700 transition hover:bg-gray-100  hover:text-gray-900"
                data-testid="view-in-enterprise"
                href={`${ENTERPRISE_PO_URL}/${purchaseOrderId}`}
                target="_blank"
                rel="noreferrer"
              >
                <NewTabIcon className="mr-2 h-5 w-5" />
                {t('purchaseOrders.allPurchaseOrders.table.viewInEnterprise', 'Open in P+ Enterprise')}
              </a>
            </div>
          </Popover.Panel>
        </Transition>
      </Portal>
    </Popover>
  )
}
