import { useMutation } from '@apollo/client'
import dayjs from 'dayjs'
import { Helmet } from 'react-helmet-async'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { StringParam, useQueryParam } from 'use-query-params'

import { generateShopPath } from '../../utils'
import AllSuppliers from './AllSuppliers'
import BuyList from './BuyList'
import ReorderRequisition from './ReorderRequisition'
import SingleSupplier from './SingleSupplier'

import { CreateRequisitionDocument } from '@/graphql/purchasing/generated/purchasing_graphql'
import { useSession } from '@/modules/access/hooks'
import { useCurrentPurchaser } from '@/modules/purchasing/hooks/'
import { OriginType, RequisitionFormInputs, RequisitionTypeKey } from '@/modules/requisitions/types'
import CreateFooter from '@/modules/shared/components/create-form/CreateFooter'
import CreateTypeSelection from '@/modules/shared/components/create-form/CreateTypeSelection'
import CreateTypeSelectionRadioOption from '@/modules/shared/components/create-form/CreateTypeSelectionRadioOption'
import FormInput from '@/modules/shared/components/create-form/FormInput'
import { PURCHASING_GRAPHQL_API, SOURCE_APPLICATION } from '@/modules/shared/constants'
import { BuyListIcon, ReorderIcon, ShoppingCartIcon, TruckIcon } from '@/modules/shared/icons'
import { Dates } from '@/modules/shared/types'
import { formatDate } from '@/modules/shared/utils'

export default function CreateRequisition() {
  const { t } = useTranslation()
  const [selectedTypeURLParam, setSelectedTypeURLParam] = useQueryParam('type', StringParam)
  const formMethods = useForm<RequisitionFormInputs>({
    defaultValues: {
      sendToSupplier: true,
      expectedDeliveryDate: formatDate(Dates.ShortYear, dayjs().add(1, 'days')),
    },
  })

  const navigate = useNavigate()
  const { currentUser } = useSession()
  const { currentPurchaser } = useCurrentPurchaser()

  const [createRequisition, { loading }] = useMutation(CreateRequisitionDocument, {
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
    onCompleted(data) {
      const { id, advanced } = data.createRequisition.requisition || {}
      navigate(generateShopPath(id, advanced))
    },
  })

  const onSubmit: SubmitHandler<RequisitionFormInputs> = (data) => {
    let {
      reference,
      deliveryAddressId,
      sendToSupplier,
      expectedDeliveryDate,
      accountId,
      departmentId,
      originId,
      advanced,
    } = data
    const purchaserOrganisationId = currentPurchaser?.id as number
    const requestorId = currentUser?.id
    const sourceApplication = SOURCE_APPLICATION
    let originType = ''

    switch (selectedTypeURLParam) {
      case RequisitionTypeKey.AllSupplier:
        originType = OriginType.Purchaser
        originId = currentPurchaser?.id!
        break
      case RequisitionTypeKey.SingleSupplier:
        originType = OriginType.SupplierRelationship
        break
      case RequisitionTypeKey.BuyList:
        originType = OriginType.Catalogue
        break
      case RequisitionTypeKey.ReOrder:
        originType = OriginType.Requisition
        break
      default:
        originType = ''
    }

    createRequisition({
      variables: {
        input: {
          reference,
          requestorId,
          originId,
          originType,
          purchaserOrganisationId,
          deliveryAddressId,
          sendToSupplier,
          expectedDeliveryDate,
          accountId,
          departmentId,
          sourceApplication,
          advanced,
        },
      },
    })
  }

  const onSelectedRequisitionType = (key: string) => {
    setSelectedTypeURLParam(key)
  }

  return (
    <>
      <Helmet>
        <title>{t('createRequisition.createRequisitionPageTitle', 'Create New Requisition')}</title>
      </Helmet>
      <form onSubmit={formMethods.handleSubmit(onSubmit)} className="mb-80">
        <div className="mx-auto my-20 w-full p-4 md:w-[800px]">
          <h1 className="text-2xl font-semibold">
            {t('createRequisition.title', 'Create a New Purchase Requisition')}
          </h1>
          <FormProvider {...formMethods}>
            <FormInput
              title={t('createRequisition.reference.label', 'Reference')}
              description={t(
                'createRequisition.reference.explain',
                'Please include a brief description of the Purchase Requisition. This reference will appear on all Purchase Orders that are created when this Requisition is submitted and fully approved. The reference will be visible to Suppliers and anyone at your organisation.'
              )}
              register="reference"
              placeholder={t('createRequisition.reference.placeholder', 'E.g. Beverage Order')}
            />
            <CreateTypeSelection
              title={t('createRequisition.type.label', 'Requisition Type')}
              description={t(
                'createRequisition.type.explain',
                "Choose the type of requisition that you'd like to create."
              )}
              selectedTypeURLParam={String(selectedTypeURLParam)}
            >
              <CreateTypeSelectionRadioOption
                onSelectedType={onSelectedRequisitionType}
                type={RequisitionTypeKey.AllSupplier}
                name={t('createRequisition.types.all', 'All Suppliers')}
                desc={t('createRequisition.types.allExplain', 'Order items from all of your Suppliers')}
                icon={<ShoppingCartIcon className="h-10 w-10" />}
                isReleased
              />
              <CreateTypeSelectionRadioOption
                onSelectedType={onSelectedRequisitionType}
                type={RequisitionTypeKey.SingleSupplier}
                name={t('createRequisition.types.single', 'Single Supplier')}
                desc={t('createRequisition.types.singleExplain', 'Order items from a single Supplier')}
                icon={<TruckIcon className="h-10 w-10" />}
                isReleased
              />
              <CreateTypeSelectionRadioOption
                onSelectedType={onSelectedRequisitionType}
                type={RequisitionTypeKey.BuyList}
                name={t('createRequisition.types.buyList', 'Buy List')}
                desc={t('createRequisition.types.buyListExplain', 'Order items from a pre-defined Buy List')}
                icon={<BuyListIcon className="h-10 w-10" />}
                isReleased
              />
              <CreateTypeSelectionRadioOption
                onSelectedType={onSelectedRequisitionType}
                type={RequisitionTypeKey.ReOrder}
                name={t('createRequisition.types.reorder', 'Re-Order')}
                desc={t('createRequisition.types.reorderExplain', 'Re-order items from a past Requisition')}
                icon={<ReorderIcon className="h-10 w-10" />}
                isReleased={false}
              />
            </CreateTypeSelection>
            {selectedTypeURLParam === RequisitionTypeKey.AllSupplier && <AllSuppliers />}
            {selectedTypeURLParam === RequisitionTypeKey.SingleSupplier && <SingleSupplier />}
            {selectedTypeURLParam === RequisitionTypeKey.BuyList && <BuyList />}
            {selectedTypeURLParam === RequisitionTypeKey.ReOrder && <ReorderRequisition />}
          </FormProvider>
          <CreateFooter
            getHelpText={t('createRequisition.actions.help', 'Get Help with Requisitions')}
            createButtonText={t('createRequisition.actions.create', 'Create Requisition')}
            disabled={!selectedTypeURLParam}
            createLoading={loading}
          />
        </div>
      </form>
    </>
  )
}
