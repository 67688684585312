import { useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  Category,
  GetCategoriesDocument,
  GetSubCategoriesDocument,
  RansackDirection,
  SupplierRelationship,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { ComboboxServer } from '@/modules/shared/components'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { useDebounce } from '@/modules/shared/hooks'
import { SupplierFormInputs } from '@/modules/shared/types'
import { extractEdges } from '@/modules/shared/utils'

interface CatAndSubCatProps {
  relationship: SupplierRelationship
}

export default function CatAndSubCat(props: CatAndSubCatProps) {
  const { relationship } = props
  const [resetSubCategory, setResetSubCategory] = useState(0)
  const { t } = useTranslation()
  const [category, setCategory] = useState<Category | null>(null)
  const [query, setQuery] = useState('')
  const debouncedQuery = useDebounce(query, 500)
  const [loaded, setLoaded] = useState(false)
  const { resetField, control } = useFormContext<SupplierFormInputs>()

  const { data: categoriesData, networkStatus: categoriesNetworkStatus } = useQuery(GetCategoriesDocument, {
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
    variables: {
      sort: [
        {
          property: 'name',
          direction: RansackDirection.Asc,
        },
      ],
    },
  })

  const {
    data: subCategoriesData,
    networkStatus: subCategoriesNetworkStatus,
    refetch: refetchingSubCategories,
  } = useQuery(GetSubCategoriesDocument, {
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
    variables: {
      sort: [
        {
          property: 'name',
          direction: RansackDirection.Asc,
        },
      ],
    },
    onCompleted() {
      setLoaded(true)
    },
  })

  const onCategorySelect = (e: Category) => {
    setCategory(e)
    resetField('defaultCategoryId')
    refetchingSubCategories({ filter: { q: [{ property: 'id_eq', value: e.id }] } })
    setResetSubCategory(e.id)
  }

  useEffect(() => {
    if (loaded) {
      refetchingSubCategories({ filter: { q: [{ property: 'name_cont', value: debouncedQuery }] } })
    }
  }, [debouncedQuery])

  return (
    <section>
      <h2 className="font-semibold">
        {t('mySuppliers.supplier.defaultCatAndSubCat', 'Default Category and Sub-Category')}
      </h2>
      <p className="mb-3 text-gray-500">
        {t(
          'mySuppliers.supplier.defaultCatAndSubCatDescription',
          'Assinging a default Category and Sub-Category to the Supplier is optional but is used as a quick way to assign a category and sub-category to any self-managed products that are created for this Supplier. This field is also used in some reports.'
        )}
      </p>
      <div>
        <ComboboxServer
          testId="categories"
          placeholder={t('mySuppliers.supplier.catPlaceholder', 'Select a Category')}
          onDisplay={(e) => `${e.name}`}
          onInputChange={() => {}}
          networkStatus={categoriesNetworkStatus}
          keyExtractor={(e) => String(e.id)}
          items={extractEdges(categoriesData?.categories) as Category[]}
          onSelected={(e) => onCategorySelect(e)}
        />
        <div className="h-1" />
        <Controller
          control={control}
          name="defaultCategoryId"
          render={({ field }) => (
            <ComboboxServer
              testId="sub-categories"
              defaultValue={relationship?.defaultCategory as Category}
              disabled={!category && !relationship?.defaultCategory}
              placeholder={t('mySuppliers.supplier.subCatPlaceholder', 'Select a Sub-Category')}
              onDisplay={(e) => `${e.name}`}
              onInputChange={(e) => setQuery(e)}
              networkStatus={subCategoriesNetworkStatus}
              keyExtractor={(e) => String(e.id)}
              items={extractEdges(subCategoriesData?.categories) as Category[]}
              onSelected={(e) => field.onChange(e.id)}
              resetSelectItem={resetSubCategory}
            />
          )}
        />
      </div>
    </section>
  )
}
