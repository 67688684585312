import { Menu, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { StringParam, useQueryParam, withDefault } from 'use-query-params'

import { ChevronDownIcon } from '@/modules/shared/icons'
import { FilterTabItem, QueryParameter } from '@/modules/shared/types'

interface FilterTabsProps {
  tabs: FilterTabItem[]
  filterBy?: QueryParameter
}

export default function FilterTabs({ tabs, filterBy = QueryParameter.Status }: FilterTabsProps) {
  const { t } = useTranslation()
  const [filterURLParam] = useQueryParam(filterBy, withDefault(StringParam, 'all'))
  const location = useLocation()

  const currentSearch = () => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.delete(filterBy)
    return searchParams.toString()
  }

  const onSelectedFilter = (filterValue: string) => {
    switch (filterValue) {
      case 'all':
        return ''
      case 'awaiting_my_approval':
        return `/requisitions/awaiting-my-approval${currentSearch() ? '?' + currentSearch() : ''}`
      default:
        return `?${filterBy}=${filterValue}${currentSearch() ? '&' + currentSearch() : ''}`
    }
  }

  return (
    <div className="rounded-md bg-white p-4 shadow-sm">
      <div className="hidden flex-wrap gap-2 lg:flex">
        {tabs.map((tab) => (
          <Link
            to={onSelectedFilter(tab.filterValue)}
            data-testid={`${tab.filterValue.toLowerCase().split(' ').join('')}-tab-btn`}
            key={tab.key}
            className={classNames(
              'flex h-11 items-center rounded-md px-5 text-sm transition duration-200 hover:brightness-95',
              {
                'bg-primary/10 text-primary hover:bg-primary/20': tab.filterValue === filterURLParam,
                'bg-gray-100': tab.filterValue !== filterURLParam,
              }
            )}
          >
            {tab.key}
          </Link>
        ))}
      </div>
      <div className="block w-full lg:hidden">
        <Menu as="div" className=" relative  inline-block w-full text-left">
          <Menu.Button
            className="flex w-full items-center justify-between rounded-md bg-primary/10 p-2 text-sm text-primary"
            data-testid="filter-tabs-btn"
          >
            <div className="flex items-center gap-x-2">
              <div className="flex h-7 items-center justify-center rounded-md bg-primary/20 p-2">
                {t('general.currentTab', 'Current Tab')}
              </div>
              <p>{tabs.find((tab) => tab.filterValue === filterURLParam)?.key}</p>
            </div>
            <ChevronDownIcon className="h-5 w-5" />
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute left-1/2 z-10 mt-2 w-full origin-center  -translate-x-1/2 divide-y divide-gray-100 rounded-md border bg-white shadow-lg focus:outline-none">
              {tabs.map((tab) => (
                <Menu.Item key={tab.key}>
                  {({ active }) => (
                    <Link
                      to={onSelectedFilter(tab.filterValue)}
                      data-testid={`${tab.filterValue.toLowerCase().split(' ').join('')}-tab-btn-dropdown`}
                      className={classNames(
                        'flex w-full items-center px-4 py-3 text-left text-sm text-gray-700 transition',
                        {
                          'bg-gray-100 text-gray-900': active,
                        }
                      )}
                    >
                      {tab.key}
                    </Link>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  )
}
