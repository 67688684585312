import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Invoice,
  PurchaseOrder,
  ReceivingDocument,
  Requisition,
  Supplier,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { Button, Modal } from '@/modules/shared/components'
import { SUPPORT_LINK } from '@/modules/shared/constants'
import { NewTabIcon } from '@/modules/shared/icons'
import { extractEdges } from '@/modules/shared/utils'

// only needs the current document passed in
interface RelatedDocumentsModalProps {
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<boolean>>
  requisition?: Requisition
  purchaseOrder?: PurchaseOrder
}

export default function RelatedDocumentsModal(props: RelatedDocumentsModalProps) {
  const { showModal, setShowModal, requisition, purchaseOrder } = props
  const { t } = useTranslation()
  const onCloseModal = () => setShowModal(false)

  const { number, requisitions, purchaseOrders, receivingDocs, invoices, suppliers } = getData(
    requisition,
    purchaseOrder
  )

  return (
    <Modal showModal={showModal} onCloseModal={onCloseModal}>
      <Modal.Panel
        as="div"
        data-testid="related-docs-modal"
        className="flex h-[80vh] w-full flex-col overflow-hidden rounded-md bg-white shadow-xl transition-all md:max-w-[600px]"
      >
        <Modal.Title
          title={t('requisition.relatedDocs.title', '{{ number }} Related Documents', { number })}
          onCloseModal={onCloseModal}
        />

        <Modal.Body className="space-y-4">
          <section className="rounded-md bg-gray-200 p-4">
            <p className="text-sm">
              {t(
                'requisition.relatedDocs.description',
                "Here you can see the documents across your organisation that are related to the document you're currently viewing. You can click on a related document below to view it in a new tab, given that you have the permission to view it."
              )}
            </p>
          </section>
          <section className="space-y-5">
            {/*  Will remove `show` when we have all needed gql fields for that */}
            {[
              {
                id: 1,
                title: t('requisition.relatedDocs.purchaseRequisitions', 'Purchase Requisitions'),
                docs: requisitions.map((e) => ({ id: e.id, value: e.requisitionNumber })),
                navigate: (id: string) => {
                  window.open(`/requisitions/${id}/summary`, '__blank', 'noopener,noreferrer')
                },
                show: true,
                first: Boolean(requisition),
              },
              {
                id: 2,
                title: t('requisition.relatedDocs.buyLists', 'Buy Lists'),
                docs: [],
                show: false,
                first: false,
              },
              {
                id: 3,
                title: t('requisition.relatedDocs.purchaseOrders', 'Purchase Orders'),
                docs: purchaseOrders.map((e) => ({ id: e.id, value: e.purchaseOrderNumber })),
                navigate: (id: string) => {
                  window.open(`/purchase-orders/${id}/summary`, '__blank', 'noopener,noreferrer')
                },
                show: true,
                first: Boolean(purchaseOrder),
              },
              {
                id: 4,
                title: t('requisition.relatedDocs.grNotes', 'Goods Receiving Notes'),
                docs: receivingDocs.map((e) => ({ id: e.id, value: e.receivingDocumentNumber })),
                navigate: (id: string) => {
                  window.open(
                    `${process.env.REACT_APP_NINJA_API_HOST}/purchasing/receiving_documents/${id}`,
                    '__blank',
                    'noopener,noreferrer'
                  )
                },
                show: true,
                first: false,
              },
              {
                id: 5,
                title: t('requisition.relatedDocs.invoices', 'Invoices'),
                docs: invoices.map((e) => ({ id: e.id, value: e.invoiceNumber })),
                navigate: (id: string) => {
                  window.open(`/invoices/${id}/summary`, '__blank', 'noopener,noreferrer')
                },
                show: true,
                first: false,
              },
              {
                id: 6,
                title: t('requisition.relatedDocs.suppliers', 'Suppliers'),
                docs: suppliers.map((e) => ({ id: e.id, value: e.name })),
                navigate: (id: string) => {
                  window.open(`/my-suppliers/${id}/supplier-details`, '__blank', 'noopener,noreferrer')
                },
                show: false,
                first: false,
              },
            ]
              // finds the main document type by seeing which prop is passed in and sorting it to be first
              .sort((a, b) => {
                return Number(b.first) - Number(a.first)
              })
              .filter((e) => e.show)
              .map((e) => (
                <div key={e.id} data-testid={`doc-${e.id}`}>
                  <strong className="text-sm">{e.title}</strong>
                  {e.docs?.length > 0 ? (
                    <div className="mt-1 rounded-md border">
                      {e.docs.map((doc) => (
                        <div key={doc.id} className="flex items-center justify-between border-b p-3 last:border-b-0">
                          <span
                            className="flex cursor-pointer items-center text-sm text-primary"
                            data-testid="related-docs-link"
                            onClick={() => e.navigate && e.navigate(String(doc.id))}
                          >
                            <p>{doc.value}</p>
                            <NewTabIcon className="ml-1 h-5 w-5" />
                          </span>
                          {e.first && (
                            <p className="text-sm text-gray-500">
                              {t('requisition.relatedDocs.currentDoc', 'Current Documents')}
                            </p>
                          )}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="mt-1 border bg-gray-100 py-4">
                      <p className="text-center text-sm text-gray-500">
                        {t('requisition.relatedDocs.noRelatedDocs', 'No related {{ doc }}', { doc: e.title })}
                      </p>
                    </div>
                  )}
                </div>
              ))}
          </section>
        </Modal.Body>
        <Modal.Footer className="flex flex-col-reverse items-center  justify-center gap-2 md:flex-row md:justify-between">
          <a
            className="text-sm text-primary"
            data-testid="learn-more-related-docs-link"
            href={SUPPORT_LINK}
            target="_blank"
            rel="noreferrer"
          >
            {t('requisition.relatedDocs.learnMore', 'Learn More about Related Documents')}
          </a>
          <Button className="h-11 w-full rounded-md bg-gray-200 px-5 text-sm md:w-fit" onClick={() => onCloseModal()}>
            {t('requisition.relatedDocs.closeBtn', 'Close')}
          </Button>
        </Modal.Footer>
      </Modal.Panel>
    </Modal>
  )
}

function getData(requisition?: Requisition, purchaseOrder?: PurchaseOrder) {
  let number: string | null = null
  let requisitions: Requisition[] = []
  let purchaseOrders: PurchaseOrder[] = []
  let receivingDocs: ReceivingDocument[] = []
  let invoices: Invoice[] = []
  let suppliers: Supplier[] = []

  if (requisition) {
    number = requisition.requisitionNumber
    requisitions = requisition ? [requisition] : []
    purchaseOrders = extractEdges<PurchaseOrder>(requisition.purchaseOrders)
    receivingDocs = extractEdges<ReceivingDocument>(requisition.receivingDocuments)
    invoices = extractEdges<Invoice>(requisition.invoices)
    suppliers = extractEdges<Supplier>(requisition.suppliers)
  } else if (purchaseOrder) {
    number = purchaseOrder.purchaseOrderNumber
    requisitions = purchaseOrder.requisition ? [purchaseOrder.requisition as Requisition] : []
    purchaseOrders = purchaseOrder ? [purchaseOrder] : []
    receivingDocs = extractEdges<ReceivingDocument>(purchaseOrder.receivingDocuments)
    invoices = extractEdges<Invoice>(purchaseOrder.invoices)
    suppliers = purchaseOrder.supplier ? [purchaseOrder.supplier as Supplier] : []
  }
  return { number, requisitions, purchaseOrders, receivingDocs, invoices, suppliers }
}
