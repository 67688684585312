import { RequisitionLine } from '@/graphql/purchasing/generated/purchasing_graphql'
import { useMoney } from '@/modules/shared/hooks'

interface PRLineTaxValueProps {
  requisitionLine: RequisitionLine
}

export default function PRLineTaxValue({ requisitionLine }: PRLineTaxValueProps) {
  const { unitTax } = requisitionLine
  const { formatMoney } = useMoney()

  return <strong>{formatMoney(unitTax)}</strong>
}
