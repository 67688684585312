import { graphql } from 'msw'

import { GetAllBuyListsQuery } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseGetAllBuyListsObject: GetAllBuyListsQuery = {
  currentPurchaser: {
    id: 44926,
    catalogues: {
      edges: [
        {
          node: {
            id: 182663,
            owner: {
              id: 44926,
              name: 'Test Account',
              __typename: 'Purchaser',
            },
            title: 'regression test',
            updatedAt: '2022-12-06T14:29:00+11:00',
            cataloguedProducts: {
              edges: [
                {
                  node: {
                    id: 22676790,
                    productId: 5851115,
                    __typename: 'CataloguedProduct',
                  },
                  __typename: 'CataloguedProductEdge',
                },
                {
                  node: {
                    id: 21695830,
                    productId: 5660576,
                    __typename: 'CataloguedProduct',
                  },
                  __typename: 'CataloguedProductEdge',
                },
                {
                  node: {
                    id: 22676859,
                    productId: 22217,
                    __typename: 'CataloguedProduct',
                  },
                  __typename: 'CataloguedProductEdge',
                },
              ],
              __typename: 'CataloguedProductConnection',
            },
            chargingDefinitions: {
              edges: [
                {
                  node: {
                    id: 23698,
                    department: {
                      id: 116657,
                      name: 'Engineering',
                      __typename: 'Purchaser',
                    },
                    account: {
                      id: 96390,
                      accountName: '0060006 - Operating Account - 6',
                      __typename: 'Account',
                    },
                    __typename: 'CatalogueChargingDefinition',
                  },
                  __typename: 'CatalogueChargingDefinitionEdge',
                },
              ],
              __typename: 'CatalogueChargingDefinitionConnection',
            },
            __typename: 'Catalogue',
          },
          __typename: 'CatalogueEdge',
        },
        {
          node: {
            id: 182666,
            owner: {
              id: 44926,
              name: 'Test Account',
              __typename: 'Purchaser',
            },
            title: 'sunny test new products',
            updatedAt: '2022-10-28T11:09:38+11:00',
            cataloguedProducts: {
              edges: [
                {
                  node: { id: 22676790, productId: 5851115, __typename: 'CataloguedProduct' },

                  __typename: 'CataloguedProductEdge',
                },
                {
                  node: { id: 21695830, productId: 5660576, __typename: 'CataloguedProduct' },

                  __typename: 'CataloguedProductEdge',
                },
                {
                  node: { id: 22676859, productId: 22217, __typename: 'CataloguedProduct' },

                  __typename: 'CataloguedProductEdge',
                },
              ],
              __typename: 'CataloguedProductConnection',
            },
            chargingDefinitions: {
              edges: [
                {
                  node: {
                    id: 23700,
                    department: null,
                    account: null,
                    __typename: 'CatalogueChargingDefinition',
                  },
                  __typename: 'CatalogueChargingDefinitionEdge',
                },
              ],
              __typename: 'CatalogueChargingDefinitionConnection',
            },
            __typename: 'Catalogue',
          },
          __typename: 'CatalogueEdge',
        },
        {
          node: {
            id: 182661,
            owner: {
              id: 44926,
              name: 'Test Account',
              __typename: 'Purchaser',
            },
            title: 'best buy test',
            updatedAt: '2022-10-12T12:01:47+11:00',
            cataloguedProducts: {
              edges: [
                {
                  node: { id: 22676790, productId: 5851115, __typename: 'CataloguedProduct' },

                  __typename: 'CataloguedProductEdge',
                },
                {
                  node: { id: 21695830, productId: 5660576, __typename: 'CataloguedProduct' },

                  __typename: 'CataloguedProductEdge',
                },
                {
                  node: { id: 22676859, productId: 22217, __typename: 'CataloguedProduct' },

                  __typename: 'CataloguedProductEdge',
                },
              ],
              __typename: 'CataloguedProductConnection',
            },
            chargingDefinitions: {
              edges: [
                {
                  node: {
                    id: 23697,
                    department: null,
                    account: null,
                    __typename: 'CatalogueChargingDefinition',
                  },
                  __typename: 'CatalogueChargingDefinitionEdge',
                },
              ],
              __typename: 'CatalogueChargingDefinitionConnection',
            },
            __typename: 'Catalogue',
          },
          __typename: 'CatalogueEdge',
        },
        {
          node: {
            id: 182632,
            owner: {
              id: 44926,
              name: 'Test Account',
              __typename: 'Purchaser',
            },
            title: 'Peter Informal Test',
            updatedAt: '2022-08-25T13:10:50+10:00',
            cataloguedProducts: {
              edges: [
                {
                  node: { id: 22676790, productId: 5851115, __typename: 'CataloguedProduct' },

                  __typename: 'CataloguedProductEdge',
                },
                {
                  node: { id: 21695830, productId: 5660576, __typename: 'CataloguedProduct' },

                  __typename: 'CataloguedProductEdge',
                },
                {
                  node: { id: 22676859, productId: 22217, __typename: 'CataloguedProduct' },

                  __typename: 'CataloguedProductEdge',
                },
              ],
              __typename: 'CataloguedProductConnection',
            },
            chargingDefinitions: {
              edges: [
                {
                  node: {
                    id: 23683,
                    department: {
                      id: 100617,
                      name: 'Accounts',
                      __typename: 'Purchaser',
                    },
                    account: {
                      id: 97162,
                      accountName: '9999911 - No. of Guest Arrivals',
                      __typename: 'Account',
                    },
                    __typename: 'CatalogueChargingDefinition',
                  },
                  __typename: 'CatalogueChargingDefinitionEdge',
                },
              ],
              __typename: 'CatalogueChargingDefinitionConnection',
            },
            __typename: 'Catalogue',
          },
          __typename: 'CatalogueEdge',
        },
        {
          node: {
            id: 182630,
            owner: {
              id: 44926,
              name: 'Test Account',
              __typename: 'Purchaser',
            },
            title: 'informal',
            updatedAt: '2022-08-15T13:41:55+10:00',
            cataloguedProducts: {
              edges: [
                {
                  node: { id: 22676790, productId: 5851115, __typename: 'CataloguedProduct' },

                  __typename: 'CataloguedProductEdge',
                },
                {
                  node: { id: 21695830, productId: 5660576, __typename: 'CataloguedProduct' },

                  __typename: 'CataloguedProductEdge',
                },
                {
                  node: { id: 22676859, productId: 22217, __typename: 'CataloguedProduct' },

                  __typename: 'CataloguedProductEdge',
                },
              ],
              __typename: 'CataloguedProductConnection',
            },
            chargingDefinitions: {
              edges: [
                {
                  node: {
                    id: 23682,
                    department: null,
                    account: null,
                    __typename: 'CatalogueChargingDefinition',
                  },
                  __typename: 'CatalogueChargingDefinitionEdge',
                },
              ],
              __typename: 'CatalogueChargingDefinitionConnection',
            },
            __typename: 'Catalogue',
          },
          __typename: 'CatalogueEdge',
        },
        {
          node: {
            id: 182623,
            owner: {
              id: 44926,
              name: 'Test Account',
              __typename: 'Purchaser',
            },
            title: 'inventory receive test sunny',
            updatedAt: '2022-07-14T16:12:15+10:00',
            cataloguedProducts: {
              edges: [
                {
                  node: { id: 22676790, productId: 5851115, __typename: 'CataloguedProduct' },

                  __typename: 'CataloguedProductEdge',
                },
                {
                  node: { id: 21695830, productId: 5660576, __typename: 'CataloguedProduct' },

                  __typename: 'CataloguedProductEdge',
                },
                {
                  node: { id: 22676859, productId: 22217, __typename: 'CataloguedProduct' },

                  __typename: 'CataloguedProductEdge',
                },
              ],
              __typename: 'CataloguedProductConnection',
            },
            chargingDefinitions: {
              edges: [
                {
                  node: {
                    id: 23678,
                    department: null,
                    account: null,
                    __typename: 'CatalogueChargingDefinition',
                  },
                  __typename: 'CatalogueChargingDefinitionEdge',
                },
              ],
              __typename: 'CatalogueChargingDefinitionConnection',
            },
            __typename: 'Catalogue',
          },
          __typename: 'CatalogueEdge',
        },
        {
          node: {
            id: 182606,
            owner: {
              id: 44926,
              name: 'Test Account',
              __typename: 'Purchaser',
            },
            title: 'Printer Paper',
            updatedAt: '2022-05-10T10:10:47+10:00',
            cataloguedProducts: {
              edges: [
                {
                  node: { id: 22676790, productId: 5851115, __typename: 'CataloguedProduct' },

                  __typename: 'CataloguedProductEdge',
                },
                {
                  node: { id: 21695830, productId: 5660576, __typename: 'CataloguedProduct' },

                  __typename: 'CataloguedProductEdge',
                },
                {
                  node: { id: 22676859, productId: 22217, __typename: 'CataloguedProduct' },

                  __typename: 'CataloguedProductEdge',
                },
              ],
              __typename: 'CataloguedProductConnection',
            },
            chargingDefinitions: {
              edges: [
                {
                  node: {
                    id: 23671,
                    department: {
                      id: 100619,
                      name: 'Front Desk ',
                      __typename: 'Purchaser',
                    },
                    account: {
                      id: 96391,
                      accountName: '0060007 - Operating Account - 7',
                      __typename: 'Account',
                    },
                    __typename: 'CatalogueChargingDefinition',
                  },
                  __typename: 'CatalogueChargingDefinitionEdge',
                },
              ],
              __typename: 'CatalogueChargingDefinitionConnection',
            },
            __typename: 'Catalogue',
          },
          __typename: 'CatalogueEdge',
        },
      ],
      pageInfo: {
        hasNextPage: false,
        endCursor: 'MjU',
        __typename: 'PageInfo',
      },
      __typename: 'CatalogueConnection',
    },
    __typename: 'Purchaser',
  },
}

export const GetAllBuyLists = graphql.query<GetAllBuyListsQuery>('GetAllBuyLists', (_, res, ctx) => {
  return res(ctx.data(baseGetAllBuyListsObject))
})

export default baseGetAllBuyListsObject
