import { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { StringParam, useQueryParam, withDefault } from 'use-query-params'

import { useGetAllStocktakes } from '../../hooks/useGetAllStocktakes'
import AllStocktakesTableView from './table-view/AllStocktakesTableView'

import StocktakesEmptyStateImg from '@/assets/images/empty-stocktakes.svg'
import { Breadcrumb, FilterTabs, QueryResult, TitleBar } from '@/modules/shared/components'
import AdvancedSearch from '@/modules/shared/components/advanced-search/AdvancedSearch'
import EmptyState from '@/modules/shared/components/empty-state'
import SearchAndFilter from '@/modules/shared/components/search-input/SearchAndFilter'
import { HomeIcon, InventoryIcon } from '@/modules/shared/icons'
import SearchContainer from '@/modules/shared/search-container'
import { Document, StocktakeState } from '@/modules/shared/types'
import { FilterTabItem, QueryParameter } from '@/modules/shared/types'

export default function AllStocktakes() {
  const { t } = useTranslation()

  const [filterURLParam] = useQueryParam(QueryParameter.Status, withDefault(StringParam, 'all'))
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false)

  const {
    loadingType,
    error,
    setVariablesLoading,
    refetchLoading,
    loading,
    onFilter,
    onPreviousPage,
    onNextPage,
    pageInfo,
    stocktakes,
  } = useGetAllStocktakes()

  const filterTabs: FilterTabItem[] = [
    {
      key: t('general.viewAll', 'View All'),
      // Not an actual state
      filterValue: 'all',
    },
    {
      key: t('general.inProgress', 'In Progress'),
      filterValue: StocktakeState.InProgress,
    },
    {
      key: t('general.closed', 'Closed'),
      filterValue: StocktakeState.Closed,
    },
  ]

  return (
    <>
      <Helmet>
        <title>{t('general.stocktakes', 'Stocktakes')}</title>
      </Helmet>

      <main className="mx-auto space-y-5 px-4">
        <Breadcrumb className="overflow-x-auto rounded-md bg-gray-200 px-3 py-2">
          <Breadcrumb.Item icon={HomeIcon} href="/dashboard"></Breadcrumb.Item>
          <Breadcrumb.Item href="/requisitions">{t('general.stocktakes', 'Stocktakes')}</Breadcrumb.Item>
          <Breadcrumb.Item>{filterTabs.find((e) => e.filterValue === filterURLParam)?.key}</Breadcrumb.Item>
        </Breadcrumb>
        <section className="space-y-2">
          <TitleBar
            icon={<InventoryIcon className="h-10 w-10 " />}
            title={t('general.stocktakes', 'Stocktakes')}
            subtitle={t('stocktakes.header.subtitle', 'View All Stocktakes')}
          />
          <FilterTabs tabs={filterTabs} />
        </section>
        <SearchContainer showAdvancedSearch={showAdvancedSearch} setShowAdvancedSearch={setShowAdvancedSearch}>
          <SearchAndFilter
            testId="stocktakes-search-input"
            placeholder={t('stocktakes.search.searchPlaceholder', 'Search by Stock Location')}
            ariaLabel={t('stocktakes.search.ariaLabel', 'Searching for stocktakes')}
            onFilter={onFilter}
            queryParamFilterType={QueryParameter.Status}
          />
        </SearchContainer>
        {showAdvancedSearch && (
          <AdvancedSearch type={Document.Stocktake} showFilterByStockLocation showFilterByCountStatus />
        )}
        <section>
          <QueryResult loading={loading || refetchLoading} error={error}>
            <AllStocktakesTableView
              stocktakes={stocktakes}
              pageInfo={pageInfo}
              paginationLoading={setVariablesLoading || !!loadingType}
              onNextPage={onNextPage}
              onPreviousPage={onPreviousPage}
            />
            {!setVariablesLoading && stocktakes.length === 0 && (
              <EmptyState
                img={StocktakesEmptyStateImg}
                message={t('stocktakes.search.noStocktakes', "We couldn't find any Stocktakes to display.")}
              />
            )}
          </QueryResult>
        </section>
        <div className="h-96" />
      </main>
    </>
  )
}
