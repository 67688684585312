export type UpdatePriceFormInputs = {
  sellUnitPrice: number
  sellUnitTaxPercentage: number | null
}

export type UpdateProductFormInputs = {
  sellUnitPrice: number
  sellUnitTaxPercentage: number | null
  longDescription: string
}

export type CatalogFormInputs = {
  title: string
  originId: string
}

export enum CatalogueTypes {
  Product = 'Product',
  Priced = 'Priced',
}
