import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { Tooltip } from '@/modules/shared/components'

interface InvoiceStatusProps {
  purchaseOrder: boolean
}

export default function InvoiceStatus(props: InvoiceStatusProps) {
  const { purchaseOrder } = props
  const { t } = useTranslation()

  return (
    <Tooltip
      content={
        purchaseOrder
          ? t(
              'invoices.invoice.linkedTooltip',
              'This Invoice line has been linked to a line on the matched Purchase Order.'
            )
          : t(
              'invoices.invoice.notLinkedTooltip',
              'This Invoice line has not yet been linked to a line on the matched Purchase Order.'
            )
      }
      delay={100}
    >
      <span
        className={classNames('inline-flex rounded-full px-3 py-1.5 text-center text-xs', {
          'bg-success text-white': purchaseOrder,
          'bg-gray-200': !purchaseOrder,
        })}
      >
        {purchaseOrder ? t('invoices.invoice.linked', 'Linked') : t('invoices.invoice.notLinked', 'Not Linked')}
      </span>
    </Tooltip>
  )
}
