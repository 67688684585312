import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import TableView from './table-view'
import TileView from './tile-view'

import PurchaseOrdersEmptyStateImg from '@/assets/images/empty-purchase-orders.svg'
import { GetRequisitionPurchaseOrdersDocument, PurchaseOrder } from '@/graphql/purchasing/generated/purchasing_graphql'
import { QueryResult } from '@/modules/shared/components'
import EmptyState from '@/modules/shared/components/empty-state'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { useWindowSize } from '@/modules/shared/hooks'
import { ControlBarIcon } from '@/modules/shared/icons'
import { extractEdges } from '@/modules/shared/utils'

export default function RequisitionPurchaseOrders() {
  const { t } = useTranslation()
  const { isLargeScreen } = useWindowSize()
  const { requisitionId } = useParams<{ requisitionId: string }>()

  const { data, loading, error } = useQuery(GetRequisitionPurchaseOrdersDocument, {
    variables: { requisitionId: Number(requisitionId) },
    context: { uri: PURCHASING_GRAPHQL_API },
  })

  const purchaseOrders = extractEdges<PurchaseOrder>(data?.currentPurchaser?.requisition?.purchaseOrders)

  return (
    <QueryResult loading={loading} error={error}>
      <div className=" flex items-center">
        <ControlBarIcon className=" h-10 w-10" />
        <p className="ml-3 text-sm">
          {data?.currentPurchaser?.requisition?.requisitionNumber}{' '}
          {t('requisitionInformation.purchaseOrders.title', 'Purchase Orders')}
        </p>
      </div>
      {isLargeScreen ? (
        <TableView purchaseOrders={purchaseOrders} />
      ) : (
        <div className="block space-y-5">
          <TileView purchaseOrders={purchaseOrders} />
        </div>
      )}
      {purchaseOrders.length === 0 && (
        <EmptyState
          img={PurchaseOrdersEmptyStateImg}
          message={t(
            'requisition.noPurchaseOrdersInRequisition',
            'No Purchase Orders have been created from this Requisition yet.'
          )}
        />
      )}
    </QueryResult>
  )
}
