import { useMutation, useQuery } from '@apollo/client'
import classNames from 'classnames'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useOutletContext, useParams } from 'react-router-dom'

import CatAndSubCat from './form-elements/CatAndSubCat'
import SaveFooter from './form-elements/SaveFooter'

import {
  Account,
  DeliveryChargeConfiguration,
  GetSupplierRelationshipDocument,
  Purchaser,
  Supplier,
  SupplierRelationship,
  UpdateSupplierRelationshipDocument,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { DeliveryFees } from '@/modules/requisitions/components'
import { DeptAndAccount } from '@/modules/requisitions/pages/create/form-elements'
import { formatSupplierBillingAddress } from '@/modules/requisitions/utils'
import { formatSupplierPostalAddress } from '@/modules/requisitions/utils/formatSupplierPostalAddress'
import { Button, QueryResult, TextWithFallback } from '@/modules/shared/components'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { SupplierFormInputs } from '@/modules/shared/types'

export default function SupplierDetails() {
  const { t } = useTranslation()
  const { supplierId } = useParams<{ supplierId: string }>()
  const formMethods = useForm<SupplierFormInputs>()
  const { getValues, watch, handleSubmit, register } = formMethods
  const [showSave, setShowSave] = useState(false)
  const { key, setKey } = useOutletContext<{ key: number; setKey: Dispatch<SetStateAction<number>> }>()

  const { data, loading, error } = useQuery(GetSupplierRelationshipDocument, {
    variables: {
      id: Number(supplierId),
    },
    context: { uri: PURCHASING_GRAPHQL_API },
  })

  const [updateSupplier, { loading: updateSupplierLoading }] = useMutation(UpdateSupplierRelationshipDocument, {
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
  })

  const onSubmit: SubmitHandler<SupplierFormInputs> = (data) => {
    data.id = Number(relationship?.id)
    updateSupplier({
      variables: {
        input: data,
      },
      onCompleted() {
        setShowSave(false)
      },
    })
  }

  const checkChanges = () => {
    const values = getValues()
    if (
      values.accountId !== relationship?.account?.id ||
      values.defaultCategoryId !== relationship?.defaultCategory?.id ||
      values.departmentId !== relationship?.department?.id ||
      values.supplierNumber !== relationship?.supplierNumber
    ) {
      setShowSave(true)
    } else {
      setShowSave(false)
    }
  }

  const discardChanges = () => {
    setKey(key + 1)
  }

  const relationship = data?.currentPurchaser?.supplierRelationship
  const supplier = relationship?.supplier

  useEffect(() => {
    checkChanges()
  }, watch(['accountId', 'defaultCategoryId', 'departmentId', 'supplierNumber']))

  return (
    <>
      <QueryResult loading={loading} error={error}>
        <div className="flex flex-col justify-between gap-10 xl:flex-row">
          <section className="flex w-full flex-col gap-y-5 text-sm xl:w-[840px]">
            <section>
              <div className="my-3 flex justify-between">
                <p className="text-lg font-bold">{t('mySuppliers.supplier.supplierDetails', 'Supplier Details')}</p>
              </div>
              <hr />
            </section>
            <section data-testid="supplier-status">
              <p className="font-bold">
                {relationship?.isEnabled
                  ? t('mySuppliers.supplier.trueEnabledTitle', 'Trading Enabled')
                  : t('mySuppliers.supplier.falseEnabledTitle', 'Trading Disabled')}
              </p>
              <p className="text-gray-500">
                {relationship?.isEnabled
                  ? t(
                      'mySuppliers.supplier.trueEnabledBody',
                      'Your trading relationship with Zeus Wholesale is currently Enabled, meaning that you can place orders with this Supplier. The Supplier can assign catalogues, products and prices to your organisation, and you can add these to your Cart when creating a Requisition. Speak with an administrator at your organisation if you wish to disable this Supplier.'
                    )
                  : t(
                      'mySuppliers.supplier.falseEnabledBody',
                      "Your trading relationship with Zeus Wholesale is currently Disabled. This means that you can't place orders with this Supplier at the moment. Speak with an administrator at your organisation or contact our friendly customer success team if you wish to have your trading relationship with this Supplier re-enabled."
                    )}
              </p>
              <Button
                className={classNames('mt-3 rounded-md px-4 py-3 text-white', {
                  'bg-success': relationship?.isEnabled,
                  'bg-error': !relationship?.isEnabled,
                })}
              >
                {relationship?.isEnabled
                  ? t('mySuppliers.supplier.trueEnabledButton', 'Enabled')
                  : t('mySuppliers.supplier.falseEnabledButton', 'Disabled')}
              </Button>
            </section>
            <FormProvider {...formMethods}>
              <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-y-5">
                <section>
                  <p className="font-bold">{t('mySuppliers.supplier.supplierNumber', 'Supplier Number')}</p>
                  <p className="mb-1 text-gray-500">
                    {t(
                      'mySuppliers.supplier.supplierNumberDescription',
                      'The Supplier Number here is optional but can help people at your organisation identify this Supplier.'
                    )}
                  </p>
                  <input
                    data-testid="supplier-number-input"
                    className="mt-2 w-full rounded-md border border-gray-200 p-3 text-sm outline-primary md:w-1/2"
                    defaultValue={String(relationship?.supplierNumber)}
                    aria-label={t('mySuppliers.supplier.ariaLabel', 'Supplier Number Input')}
                    {...register('supplierNumber')}
                  />
                </section>
                <section>
                  <h2 className="text-sm font-semibold">
                    {t('mySuppliers.supplier.defaultDeptAndAcc', 'Default Department and Account Code')}
                  </h2>
                  <p className="text-sm text-gray-500">
                    {t(
                      'mySuppliers.supplier.defaultDeptAndAccDescription',
                      'The default Department and Account Code is optional but can be used to pre-fill the Department and Account Code fields when a Purchase Requisition is created for this Supplier by users at your organisation.'
                    )}
                  </p>
                  <DeptAndAccount
                    defaultDepartmentValue={relationship?.department as Purchaser}
                    defaultAccountValue={relationship?.account as Account}
                    showExplanation={false}
                    title={t('createRequisition.deptAndAccount.title', 'Department and Account Code')}
                    description={t(
                      'createRequisition.deptAndAccount.explain',
                      'If applicable, select a Department then an Account code to record this Requisition against. Your organisation may use Department and Account Codes for recording Requisition expenses.'
                    )}
                  />
                </section>
                <CatAndSubCat relationship={relationship as SupplierRelationship} />
                {showSave && <SaveFooter onDiscardChanges={discardChanges} loading={updateSupplierLoading} />}
              </form>
            </FormProvider>
          </section>
          <section className="flex w-full flex-col gap-y-4 text-sm xl:w-96">
            <section className="rounded-md bg-white p-5 shadow-sm" data-testid="supplier-details">
              <h2 className="mb-5 text-center text-lg font-bold">{supplier?.name}</h2>
              <div className="flex flex-col gap-y-4">
                <TextWithFallback
                  title={t('mySuppliers.supplier.billingAddress', 'Billing Address')}
                  value={formatSupplierBillingAddress(supplier as Supplier)}
                />
                <TextWithFallback
                  title={t('mySuppliers.supplier.postalAddress', 'Postal Address')}
                  value={formatSupplierPostalAddress(supplier as Supplier)}
                />
                <TextWithFallback
                  title={t('mySuppliers.supplier.phone', 'Contact Phone')}
                  value={supplier?.telephone}
                />
                <TextWithFallback title={t('mySuppliers.supplier.email', 'Contact Email')} value={supplier?.email} />
                <TextWithFallback
                  title={t('mySuppliers.supplier.businessNumber', 'Business Number')}
                  value={supplier?.businessNumber}
                />
              </div>
            </section>
            <section className="rounded-md bg-white p-5 shadow-sm" data-testid="supplier-delivery">
              <p className="font-bold">{t('shopPage.cart.summary.details.deliveryFees', 'Delivery Fees')}</p>
              <p>{t('mySuppliers.supplier.byTotalOrder', 'By total order value, excluding tax.')}</p>
              <DeliveryFees
                deliveryChargeConfiguration={supplier?.deliveryChargeConfiguration as DeliveryChargeConfiguration}
              />
              <p className="text-xs text-gray-500">
                {t(
                  'mySuppliers.supplier.deliveryFeeInfo',
                  'Delivery fees are set by the Supplier based on your trading agreement. Delivery fees will be automatically applied to a Purchase Order. Delivery fees shown here are a general guide and the Supplier reserves the right to charge additional delivery fees based on other location or product criteria such as weight or volume of certain products.'
                )}
              </p>
            </section>
          </section>
        </div>
      </QueryResult>
    </>
  )
}
