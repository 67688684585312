import { graphql } from 'msw'

import { FlaggingMessageTypeEnum, GetInvoiceQuery } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseGetInvoiceObject: GetInvoiceQuery = {
  currentPurchaser: {
    id: 44926,
    invoice: {
      id: 7524140,
      invoiceNumber: 'INGR02385',
      createdAt: '2023-02-14T14:43:20+11:00',
      reference: 'test',
      state: 'open',
      invoiceDate: '2022-12-16',
      attachmentUrl: 'https://www.accountsflow.com.au/invoices/d172c660af4941069fc57d41b16400fc.pdf',
      commentsCount: 0,
      purchaseOrderId: 16566710,
      inception: 'pdf',
      isFinanciallyApproved: false,
      isOperationallyApproved: false,
      flaggingMessages: [],
      purchaser: {
        id: 44926,
        name: 'Test Account',
      },
      receivingDocuments: {
        edges: [],
      },
      creditNotes: {
        edges: [],
      },
      purchaseOrder: null,
      department: {
        id: 44926,
        name: 'Test Account',
      },
      account: null,
      supplier: {
        id: 5426,
        name: 'Simon George & Sons Pty Ltd - Cairns',
        billingAddressCity: 'The Brisbane Markets',
        billingAddressCountry: 'AU',
        billingAddressLine1: 'Block K',
        billingAddressLine2: '',
        billingAddressPostalCode: '4106',
        billingAddressStateProvince: 'QLD',
        email: 'orders-cairns@simongeorge.com.au',
        telephone: '+617 4041 9100',
        deliveryChargeConfiguration: null,
        __typename: 'Supplier',
      },
      lines: {
        edges: [
          {
            node: {
              id: 24586766,
              quantity: 20,
              unitPrice: '2.8',
              productId: 8,
              taxPercentage: 0,
              orderInstruction: '',
              lineTotal: 56,
              invoiceId: 7524140,
              purchaseOrderLineItemId: 60218580,
              totalInvoicedQuantity: 5,
              totalReceivedQuantity: 3,
              receivedStatus: 'PARTLY_INVOICED',
              purchaseOrderLineItem: {
                id: 60218580,
                quantity: 20,
                unitPrice: 2.8,
                taxPercentage: 0,
                product: {
                  id: 8,
                  category: {
                    id: 109877,
                    name: 'Herbs and Spices',
                    parent: {
                      id: 109460,
                      name: 'Fruit and Vegetables',
                      __typename: 'Category',
                    },
                    __typename: 'Category',
                  },
                  brand: '',
                  itemDescription: 'Chives Garlic',
                  concatenatedSellUnit: 'each of 1',
                  itemPackName: '',
                  itemMeasure: 'bunch',
                  itemSellPackName: 'each',
                  itemSize: 1,
                  __typename: 'Product',
                },
                __typename: 'PurchaseOrderLineItem',
              },
              product: {
                id: 8,
                category: {
                  id: 109877,
                  name: 'Herbs and Spices',
                  parent: {
                    id: 109460,
                    name: 'Fruit and Vegetables',
                    __typename: 'Category',
                  },
                  __typename: 'Category',
                },
                brand: '',
                itemDescription: 'Chives Garlic',
                concatenatedDescription: 'Chives Garlic 1 bunch',
                concatenatedSellUnit: 'each of 1',
                itemPackName: '',
                itemMeasure: 'bunch',
                itemSellPackName: 'each',
                itemSize: 1,
                __typename: 'Product',
              },
              flaggingMessages: [
                {
                  type: FlaggingMessageTypeEnum.InvoiceFlaggedLineBlockage,
                  values: [],
                },
              ],
              __typename: 'InvoiceLineItem',
            },
            __typename: 'InvoiceLineItemEdge',
          },
        ],
        __typename: 'InvoiceLineItemConnection',
      },
      totalValue: 56,
      totalTaxValue: 0,
      totalValueExTax: '56.0',
      deliveryChargeTax: 2,
      deliveryChargeExTax: 20,
      supplierNumber: 'Please edit',
      lineTotal: '50',
      lineTaxTotal: '5',
      adjustmentExTax: 6,
      adjustmentTax: 4,
      outOfBalance: false,
      __typename: 'Invoice',
    },
    __typename: 'Purchaser',
  },
}

export const GetInvoice = graphql.query<GetInvoiceQuery>('GetInvoice', (_, res, ctx) => {
  return res(ctx.data(baseGetInvoiceObject))
})

export default baseGetInvoiceObject
