import i18n from 'i18next'

/**
 * returns a translation from the requisitionState
 * possible states and translations:
 * @param requisitionState
 * @returns
 */
export function formatStateForLocale(requisitionState: string): string {
  switch (requisitionState) {
    case 'open':
      return i18n.t('requisition.details.state.open', 'Open')
    case 'awaiting_approval':
      return i18n.t('requisition.details.state.awaitingApproval', 'Awaiting Approval')
    case 'approved':
      return i18n.t('requisition.details.state.approved', 'Approved')
    case 'declined':
      return i18n.t('requisition.details.state.declined', 'Declined')
    case 'closed':
      return i18n.t('requisition.details.state.closed', 'Closed')
    case 'cancelled':
      return i18n.t('requisition.details.state.cancelled', 'Cancelled')
    default:
      return requisitionState
  }
}
