import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import InvoiceRowItem from './InvoiceRowItem'

import { Invoice } from '@/graphql/purchasing/generated/purchasing_graphql'

interface TableviewProps {
  invoices: Invoice[]
}

function TableView(props: TableviewProps) {
  const { invoices } = props
  const { t } = useTranslation()

  if (invoices.length === 0) return null

  return (
    <div className="overflow-x-auto" data-testid="invoices-table-view">
      <table className="w-full text-left text-sm">
        <thead className="whitespace-nowrap border bg-white text-xs text-gray-500">
          <tr>
            <th scope="col" className="px-4 py-3">
              {t('invoices.details.invoice', 'Invoice')}
            </th>
            <th scope="col" className="px-4 py-3">
              {t('invoices.details.purchaseOrder', 'Purchase Order')}
            </th>
            <th scope="col" className="px-4 py-3">
              {t('invoices.details.accountCode', 'Account Code')}
            </th>
            <th scope="col" className="px-4 py-3 text-center">
              {t('invoices.details.status', 'Status')}
            </th>
            <th scope="col" className="px-4 py-3 text-center">
              {t('invoices.details.invoiceDate', 'Invoice Date')}
            </th>
            <th scope="col" className="px-4 py-3 text-right">
              {t('invoices.details.tax', 'Tax')}
            </th>
            <th scope="col" className="px-4 py-3 text-right">
              {t('invoices.details.total', 'Total')}
            </th>
            <th scope="col" className="px-4 py-3 text-center">
              {t('invoices.details.more', 'More')}
            </th>
          </tr>
        </thead>
        <tbody className="border">
          {invoices.map((invoice) => (
            <InvoiceRowItem key={invoice.id} invoice={invoice} />
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default memo(TableView)
