import classNames from 'classnames'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { StockCount, StockLocation } from '@/graphql/inventory/generated/inventory_graphql'
import { formatProductItem } from '@/modules/requisitions/utils'
import Table from '@/modules/shared/components/table/Table'
import { CheckAltIcon, TransferIcon } from '@/modules/shared/icons'

export interface StocktakeStockCountTableProps {
  stockCounts: StockCount[]
  stockLocation: StockLocation
}

function StocktakeStockCountTable({ stockCounts, stockLocation }: StocktakeStockCountTableProps) {
  const { t } = useTranslation()

  // closedAt is null unless there is a closed date.
  const stocktakeClosed = !!stockLocation?.stockTake?.closedAt

  const renderStockItem = ({ product }: StockCount) => {
    const { brand, itemDescription, itemMeasure, itemPackName, itemSize, concatenatedSellUnit } = product || {}
    return (
      <>
        <span className="cursor-pointer text-primary">
          {brand} {itemDescription}
        </span>
        <span className="text-xxs text-gray-500 sm:text-xs">
          <p>
            {formatProductItem({
              itemMeasure: itemMeasure,
              itemPackName: itemPackName,
              itemSize: itemSize,
              itemSellUnit: concatenatedSellUnit,
            })}
          </p>
        </span>
      </>
    )
  }

  const renderCounted = ({ quantity }: StockCount) => (
    <p
      data-testid="stocktake-stock-count-table-counted"
      className={classNames(
        'm-auto w-fit min-w-[60px] cursor-pointer rounded-md border px-4 py-3 text-center transition duration-200 ',
        {
          'hover:bg-gray-200': !stocktakeClosed,
          'bg-gray-200': stocktakeClosed,
        }
      )}
    >
      {quantity || 0}
    </p>
  )

  const renderTransfer = ({ variance }: StockCount) =>
    variance ? (
      <div className="flex h-full items-center justify-center">
        <div className="cursor-pointer items-center justify-between rounded-full bg-gray-200 p-1.5 text-gray-600 outline-none hover:bg-gray-300">
          <TransferIcon className="h-8 w-8" />
        </div>
      </div>
    ) : (
      <div className="flex h-full items-center justify-center">
        <div className="items-center justify-between rounded-full bg-success p-1.5 text-white outline-none">
          <CheckAltIcon className="h-8 w-8  " />
        </div>
      </div>
    )

  // hide expected, variance, and transfer if closed, and show opening. If 'blind' and not closed, then hide expected,
  //    variance, and transfer.
  const hiddenColumns = () => {
    if (stocktakeClosed) {
      return ['expected', 'variance', 'transfer']
    } else {
      if (stockLocation?.stockTake?.blind) {
        return ['opening', 'expected', 'variance', 'transfer']
      }
      return ['opening']
    }
  }

  return (
    <Table
      dataTestId="stocktake-stock-count-table-view"
      dataSource={stockCounts || []}
      keyExtractor={(record) => String(record.id)}
      columnsHidden={hiddenColumns()}
      columns={[
        {
          title: t('general.stockItem', 'Stock Item'),
          key: 'stockItem',
          headerCellStyles: 'px-4 py-3',
          cellStyles: 'px-4 py-3',
          minWidth: 130,
          maxWidth: 130,
          grow: true,
          render: renderStockItem,
        },
        // TODO: Product code should be 'purchaserCode, which is not yet available in graphql.
        // {
        //   title: t('general.productCode', 'Product Code'),
        //   key: 'productCode',
        //   headerCellStyles: 'py-3 text-center',
        //   cellStyles: 'py-3 text-center',
        //   minWidth: 100,
        //   maxWidth: 100,
        //   render: ({ product }: StockCount) => product?.itemId || product?.id || null,
        // },
        {
          title: t('general.opening', 'Opening'),
          key: 'opening',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 100,
          maxWidth: 100,
          render: ({ openingQuantity }: StockCount) => openingQuantity || 0,
        },
        {
          title: t('general.expected', 'Expected'),
          key: 'expected',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 100,
          maxWidth: 100,
          render: ({ expectedQuantity }: StockCount) => expectedQuantity || 0,
        },
        {
          title: t('general.counted', 'Counted'),
          key: 'counted',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 100,
          maxWidth: 100,
          render: renderCounted,
        },
        {
          title: t('general.variance', 'Variance'),
          key: 'variance',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 100,
          maxWidth: 100,
          render: ({ variance }: StockCount) => variance || 0,
        },
        {
          title: t('general.transfer', 'Transfer'),
          key: 'transfer',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 100,
          maxWidth: 100,
          render: renderTransfer,
        },
      ]}
    />
  )
}

export default memo(StocktakeStockCountTable)
