import { useQuery } from '@apollo/client'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Link } from 'react-router-dom'

import { Spinner } from '../../components'

import ProductEmptyStateImg from '@/assets/images/product-empty-state.svg'
import { RansackDirection } from '@/graphql/access/generated/access_graphql'
import { GetRequisitionsDraftDocument, Requisition } from '@/graphql/purchasing/generated/purchasing_graphql'
import { useSession } from '@/modules/access/hooks'
import { RefetchContext } from '@/modules/purchasing/pages/dashboard/draft-requisitions'
import ListView from '@/modules/requisitions/pages/my-drafts/draft-list/list-view/index'
import TileView from '@/modules/requisitions/pages/my-drafts/draft-list/tile-view/index'
import { Breadcrumb, MoreOptionsMenu, QueryResult, TitleBar, Tooltip } from '@/modules/shared/components'
import EmptyState from '@/modules/shared/components/empty-state'
import { PURCHASING_GRAPHQL_API, SOURCE_APPLICATION } from '@/modules/shared/constants'
import { useWindowSize } from '@/modules/shared/hooks'
import { CartIcon, HomeIcon, OptionIcon, PlusIcon } from '@/modules/shared/icons'
import { checkNetworkStatus, extractEdges } from '@/modules/shared/utils'

function MyDrafts() {
  const { t } = useTranslation()
  const { isLargeScreen } = useWindowSize()

  const { currentUser } = useSession()

  const { data, refetch, fetchMore, networkStatus } = useQuery(GetRequisitionsDraftDocument, {
    variables: {
      first: 25,
      filter: {
        q: [
          { property: 'workflow_state_eq', value: 'open' },
          { property: 'requestor_id_eq', value: currentUser?.id },
          { property: 'source_application_eq', value: SOURCE_APPLICATION },
        ],
      },
      sort: [
        {
          property: 'locationName',
          direction: RansackDirection.Desc,
        },
      ],
    },
    fetchPolicy: 'cache-and-network',
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
    notifyOnNetworkStatusChange: true,
  })

  const { setVariablesLoading, loading: initialLoading } = checkNetworkStatus(networkStatus)

  const onFetchMoreRequisitions = () => {
    fetchMore({
      variables: {
        after: data?.currentPurchaser?.requisitions?.pageInfo?.endCursor,
      },
    })
  }

  const onRefetch = () => {
    refetch()
  }

  const draftList = extractEdges<Requisition>(data?.currentPurchaser?.requisitions)

  return (
    <>
      <Helmet>
        <title>{t('requisition.myDraftsPageTitle', 'My Drafts')}</title>
      </Helmet>
      <main className="mx-auto space-y-5 px-4" data-testid="my-drafts">
        <Breadcrumb className="overflow-x-auto rounded-md bg-gray-200 px-3 py-2">
          <Breadcrumb.Item icon={HomeIcon} href="/dashboard"></Breadcrumb.Item>
          <Breadcrumb.Item href="/requisitions">{t('breadcrumbs.requisitions', 'Requisitions')}</Breadcrumb.Item>
          <Breadcrumb.Item>{t('breadcrumbs.myDrafts', 'My Drafts')}</Breadcrumb.Item>
        </Breadcrumb>
        <TitleBar
          icon={<CartIcon className="h-10 w-10" />}
          title={t('requisition.numberOfDrafts', '{{ number }} Drafts', { number: draftList?.length })}
          subtitle={t('requisition.myDrafts', 'My Draft Requisitions')}
        >
          <div className="flex items-center gap-x-2">
            <Tooltip content={t('createRequisition.createTooltip', 'Create a New Requisition')}>
              <Link to="/requisitions/create">
                <PlusIcon className="h-11 w-11 cursor-pointer rounded-full bg-gray-200 p-1 text-gray-600" />
              </Link>
            </Tooltip>
            <MoreOptionsMenu showHelp showOpenInPPlus allRequisitions>
              <OptionIcon className="h-11 w-11 rounded-full bg-gray-200 p-1.5 text-gray-600 outline-none hover:brightness-95" />
            </MoreOptionsMenu>
          </div>
        </TitleBar>
        <section>
          <QueryResult loading={initialLoading || setVariablesLoading}>
            <RefetchContext.Provider value={onRefetch}>
              <InfiniteScroll
                dataLength={draftList.length}
                next={onFetchMoreRequisitions}
                hasMore={!!data?.currentPurchaser?.requisitions?.pageInfo?.hasNextPage}
                loader={<Spinner className="mt-5 h-14 md:w-16" />}
                endMessage={
                  !setVariablesLoading &&
                  draftList.length === 0 && (
                    <EmptyState
                      img={ProductEmptyStateImg}
                      message={t('dashboard.draftRequisitions.noDrafts', "You don't have any Draft Requisitions")}
                    />
                  )
                }
              >
                {isLargeScreen ? <ListView draftList={draftList} /> : <TileView draftList={draftList} />}
              </InfiniteScroll>
            </RefetchContext.Provider>
          </QueryResult>
        </section>
      </main>
    </>
  )
}

export default MyDrafts
