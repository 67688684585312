import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import {
  FlaggedInvoiceLine,
  GetInvoiceDocument,
  Invoice,
  InvoiceLineItem,
  Supplier,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { extractEdges } from '@/modules/shared/utils'

export default function useGetInvoice() {
  const { invoiceId } = useParams<{ invoiceId: string }>()

  const { loading, error, data, refetch } = useQuery(GetInvoiceDocument, {
    variables: {
      invoiceId: Number(invoiceId),
    },
    context: { uri: PURCHASING_GRAPHQL_API },
  })

  const invoice = data?.currentPurchaser?.invoice

  const {
    lines,
    invoiceNumber,
    reference,
    supplier,
    deliveryChargeExTax,
    deliveryChargeTax,
    totalValueExTax,
    totalValue,
    totalTaxValue,
    state,
    purchaseOrderId,
    commentsCount,
    inception,
    purchaseOrder,
    department,
    account,
    createdAt,
    invoiceDate,
    lineTotal,
    lineTaxTotal,
    adjustmentExTax,
    adjustmentTax,
    flaggingMessages,
  } = invoice || {}
  const invoiceLines = extractEdges<InvoiceLineItem>(lines)
  const flaggedLines = invoiceLines.map((line) => line.flagged)

  const addFlagsToInvoiceLines = (
    invoiceLines: InvoiceLineItem[],
    flaggedInvoiceLines?: FlaggedInvoiceLine[]
  ): InvoiceLineItem[] => {
    // const combinedInvoiceLines = invoiceLines.map((invoiceLine) => {
    //   const flaggedLine = flaggedInvoiceLines.find((flaggedLine) => flaggedLine.id === invoiceLine.id)

    //   if (flaggedLine) {
    //     return {
    //       ...invoiceLine,
    //       flaggingMessages: flaggedLine.flaggingMessages,
    //     }
    //   }

    //   return invoiceLine
    // })

    return invoiceLines
  }

  const getDefaultValues = (invoice: Invoice) => {
    return {
      accountId: Number(invoice?.accountId),
      departmentId: Number(invoice?.departmentId),
      reference: String(invoice?.reference),
      invoiceNumber: String(invoice?.invoiceNumber),
      invoiceDate: String(invoice?.invoiceDate),
    }
  }

  return {
    invoice,
    loading,
    error,
    invoiceLinesWithFlags: addFlagsToInvoiceLines(invoiceLines),
    invoiceNumber,
    reference,
    supplier: supplier as Supplier,
    deliveryChargeExTax,
    deliveryChargeTax,
    totalValueExTax,
    totalValue,
    totalTaxValue,
    state,
    purchaseOrderId,
    invoiceId,
    commentsCount,
    flaggedLines,
    inception,
    purchaseOrder,
    department,
    account,
    createdAt,
    invoiceDate,
    lineTotal,
    lineTaxTotal,
    adjustmentExTax,
    adjustmentTax,
    getDefaultValues,
    lines,
    flaggingMessages,
    refetch,
  }
}
