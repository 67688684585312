import { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, generatePath } from 'react-router-dom'

import { Requisition } from '@/graphql/purchasing/generated/purchasing_graphql'
import { useApproveRequisition } from '@/modules/requisitions/hooks'
import { Button, Fallback, MoreOptionsMenu, PPNewDetailLink, Tooltip } from '@/modules/shared/components'
import Table from '@/modules/shared/components/table/Table'
import { useMoney } from '@/modules/shared/hooks'
import { CheckAltIcon, CloseIcon, OptionIcon } from '@/modules/shared/icons'
import { Loading } from '@/modules/shared/icons-special'
import { Dates } from '@/modules/shared/types'
import { formatDate } from '@/modules/shared/utils'

interface TableViewProps {
  approvalList: Requisition[]
}

function TableView(props: TableViewProps) {
  const { approvalList } = props
  const { t } = useTranslation()
  const { formatMoney } = useMoney()
  const [actionLoadingById, setActionloadingById] = useState<number | null>(null)

  const [approveRequisition, { loading: actionLoading }] = useApproveRequisition()

  const onApprove = (requisitionId: number) => {
    setActionloadingById(requisitionId)
    approveRequisition({
      variables: {
        input: {
          id: requisitionId,
        },
      },
    })
  }

  const renderRequisition = ({ id, requisitionNumber }: Requisition) => {
    return (
      <PPNewDetailLink type="requisition" id={String(id)}>
        {requisitionNumber}
      </PPNewDetailLink>
    )
  }

  const renderReference = ({ reference }: Requisition) => {
    return <Fallback condition={!!reference}>{reference}</Fallback>
  }

  const renderCreator = ({ creator }: Requisition) => {
    return <p>{creator?.fullName}</p>
  }

  const renderType = ({ originName }: Requisition) => {
    return <Fallback condition={!!originName}>{originName}</Fallback>
  }

  const renderDeptAndAccount = ({ department, account }: Requisition) => {
    return (
      <Fallback condition={!!department || !!account}>
        <p>{department?.name}</p>
        <p className="text-xs">{account?.accountName}</p>
      </Fallback>
    )
  }

  const renderExpectedDeliveryDate = ({ expectedDeliveryDate }: Requisition) => {
    return <Fallback condition={!!expectedDeliveryDate}>{formatDate(Dates.Short, expectedDeliveryDate)}</Fallback>
  }

  const renderSendPO = ({ sendToSupplier }: Requisition) => {
    return <p>{sendToSupplier ? t('requisition.details.sendPOYes', 'Yes') : t('requisition.details.sendPONo', 'No')}</p>
  }

  const renderTotalvalue = ({ totalValue }: Requisition) => {
    return (
      <>
        <p className="text-base font-bold">{formatMoney(totalValue)}</p>
        <p className="text-xs text-gray-600">{t('requisition.details.taxInclusive', 'Tax Inclusive')}</p>
      </>
    )
  }

  const renderActions = ({ id }: Requisition) => {
    const loading = actionLoading && actionLoadingById === id
    return (
      <div className="flex items-center justify-center gap-x-1.5">
        <Tooltip content={t('requisition.details.tooltipActionDecline', 'Click to Decline')}>
          <Link
            data-testid="decline-button"
            to={generatePath('/requisitions/:id/decline', {
              id: String(id),
            })}
            className="flex h-10 w-10 items-center justify-center rounded-full bg-error text-white transition hover:brightness-90"
          >
            <CloseIcon className="h-6 w-6" />
          </Link>
        </Tooltip>
        <Tooltip content={t('requisition.details.tooltipActionApprove', 'Click to Approve')}>
          <Button
            data-testid="approve-button"
            disabled={loading}
            className="flex h-10 w-10 items-center justify-center rounded-full bg-success text-white"
            onClick={() => onApprove(id)}
          >
            {loading ? <Loading className="h-6 w-6 fill-white text-gray-300" /> : <CheckAltIcon className="h-5 w-5" />}
          </Button>
        </Tooltip>
      </div>
    )
  }

  const renderMoreOptions = ({ id }: Requisition) => {
    return (
      <MoreOptionsMenu showOpenInPPlus showPrint showHelp showViewRequisition requisitionId={id}>
        <OptionIcon className="h-10 w-10 text-gray-500" />
      </MoreOptionsMenu>
    )
  }

  return (
    <Table
      dataTestId="requisition-table-view"
      dataSource={approvalList}
      keyExtractor={(record) => String(record.id)}
      columns={[
        {
          title: t('requisition.details.requisitionTableHeader', 'Requisition'),
          key: 'requisition',
          headerCellStyles: 'px-4 py-3',
          cellStyles: 'px-4 py-3 ',
          minWidth: 130,
          maxWidth: 130,
          render: renderRequisition,
        },
        {
          title: t('requisitions.details.reference', 'Reference'),
          key: 'reference',
          headerCellStyles: 'px-2 py-3',
          cellStyles: 'px-2 py-3 ',
          minWidth: 180,
          maxWidth: 220,
          render: renderReference,
        },
        {
          title: t('requisition.details.requestor', 'Requestor'),
          key: 'requestor',
          headerCellStyles: 'px-2 py-3',
          cellStyles: 'px-2 py-3',
          minWidth: 200,
          maxWidth: 200,
          render: renderCreator,
        },
        {
          title: t('requisition.details.type', 'Type'),
          key: 'type',
          headerCellStyles: 'px-2 py-3',
          cellStyles: 'px-2 py-3 ',
          minWidth: 220,
          maxWidth: 240,
          render: renderType,
        },
        {
          title: t('requisition.details.accountTableHeader', 'Account Code'),
          key: 'account',
          headerCellStyles: 'px-2 py-3',
          cellStyles: ' px-2 py-3 ',
          grow: true,
          minWidth: 240,
          render: renderDeptAndAccount,
        },
        {
          title: t('requisition.details.deliveryDate', 'Delivery Date'),
          key: 'deliveryDate',
          headerCellStyles: 'px-1 py-3 text-center',
          cellStyles: 'px-1 py-3 text-center',
          minWidth: 120,
          maxWidth: 120,
          render: renderExpectedDeliveryDate,
        },
        {
          title: t('requisition.details.sendPOTableHeader', 'Send PO'),
          key: 'sendPO',
          headerCellStyles: 'px-1 py-3 text-center',
          cellStyles: 'px-1 py-3 text-center',
          minWidth: 80,
          maxWidth: 80,
          render: renderSendPO,
        },
        {
          title: t('requisition.details.totalValue', 'Total Value'),
          key: 'totalValue',
          headerCellStyles: 'p-3 text-right',
          cellStyles: 'p-3 text-right',
          minWidth: 110,
          maxWidth: 150,
          render: renderTotalvalue,
        },
        {
          title: t('requisition.details.actionsTableHeader', 'Actions'),
          key: 'actions',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 110,
          maxWidth: 110,
          render: renderActions,
        },
        {
          title: t('requisition.details.moreTableHeader', 'More'),
          key: 'more',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 70,
          maxWidth: 70,
          render: renderMoreOptions,
        },
      ]}
    />
  )
}

export default memo(TableView)
