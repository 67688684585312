import { Route, Routes } from 'react-router-dom'

import PurchaserRoutes from './PurchaserRoutes'
import SupplierRoutes from './SupplierRoutes'

import { useSession } from '@/modules/access/hooks'
import { useCurrentLegalEntity } from '@/modules/purchasing/hooks'
import { AccessDenied, Home, NotFound } from '@/modules/shared/pages'

export default function AllRoutes() {
  const { currentLegalEntity, loading: currentLegalEntityLoading } = useCurrentLegalEntity()
  const { loading: currentUserLoading } = useSession()

  if (currentLegalEntityLoading || currentUserLoading) return null

  // user is signed in
  if (currentLegalEntity) {
    const { ppnewAccess, isAPurchaser, isASupplier } = currentLegalEntity

    if (!ppnewAccess) return <AccessDenied />
    if (isASupplier) return <SupplierRoutes />
    if (isAPurchaser) return <PurchaserRoutes />
  }

  // user is not signed in
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
