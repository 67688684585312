import { useTranslation } from 'react-i18next'

import TaxPercentage from '../../../shared/components/table/table-column-items/TaxPercentage'
import UnitPrice from '../../../shared/components/table/table-column-items/UnitPrice'
import PRLineActions from './product-list-table-columns/PRLineActions'
import PRLineCategory from './product-list-table-columns/PRLineCategory'
import PRLineMoreOption from './product-list-table-columns/PRLineMoreOption'
import PRLineOrderInstruction from './product-list-table-columns/PRLineOrderInstruction'
import PRLinePurchaseCode from './product-list-table-columns/PRLinePurchaseCode'
import PRLineQuantity from './product-list-table-columns/PRLineQuantity'
import PRLineSupplierCode from './product-list-table-columns/PRLineSupplierCode'
import PRLineTaxValue from './product-list-table-columns/PRLineTaxValue'
import PRLineTotal from './product-list-table-columns/PRLineTotal'
import PRLinesDeptAndAccount from './product-list-table-columns/PRLinesDeptAndAccount'

import { PageInfo, Product, Requisition, RequisitionLine } from '@/graphql/purchasing/generated/purchasing_graphql'
import Table from '@/modules/shared/components/table/Table'
import {
  BestBuyButton,
  ProductImage,
  ProductItem,
  SupplierQuotesButton,
  SupplierSelectorButton,
} from '@/modules/shared/components/table/table-column-items'

interface AdvancedShopProductListProps {
  requisitionLines: RequisitionLine[]
  requisition: Requisition
  paginationLoading: boolean
  pageInfo: Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'endCursor'>
  onNextPage: () => void
  onPreviousPage: () => void
  refetch: () => void
  refetchLoading?: boolean
}

export default function AdvancedShopProductList({
  requisitionLines,
  requisition,
  pageInfo: { hasNextPage, hasPreviousPage, endCursor },
  onNextPage,
  onPreviousPage,
  paginationLoading,
  refetch,
  refetchLoading,
}: AdvancedShopProductListProps) {
  const { t } = useTranslation()

  const isAddedToCart = (cataloguedProductId: number) => {
    return !!(requisition.lines || []).find((line) => line.cataloguedProductId === cataloguedProductId)
  }

  return (
    <Table
      dataTestId="advanced-product-list"
      dataSource={requisitionLines}
      tableRowStyles="hover:bg-gray-50"
      keyExtractor={(record) => String(record.cataloguedProductId)}
      hasExtraLine={({ orderInstruction, department, account }) => {
        return !!(orderInstruction || (department && account))
      }}
      renderExtraLine={(record) => (
        <>
          {record.orderInstruction && <PRLineOrderInstruction requisitionLine={record} />}
          {record.department && record.account && <PRLinesDeptAndAccount requisitionLine={record} />}
        </>
      )}
      pagination={{
        hasNextPage,
        hasPreviousPage,
        onNextPage: onNextPage,
        onPreviousPage: onPreviousPage,
        loading: paginationLoading || refetchLoading,
        endCursor: endCursor,
      }}
      columns={[
        {
          title: t('general.image', 'Image'),
          key: 'image',
          headerCellStyles: 'px-4 py-3 text-center',
          cellStyles: 'px-4 py-3 text-center',
          minWidth: 90,
          maxWidth: 90,
          render: (record) => <ProductImage line={record} image={record.image || ''} clickToOpenModal />,
        },
        {
          title: t('general.item', 'Item'),
          key: 'item',
          headerCellStyles: 'pl-0 pr-4 py-3',
          cellStyles: 'pl-0 pr-4 py-3',
          minWidth: 280,
          maxWidth: 300,
          render: (record) => (
            <ProductItem
              line={record}
              product={record.product as Product}
              clickToOpenModal
              supplier={record.supplier}
            />
          ),
        },
        {
          title: t('general.supplier', 'Supplier'),
          key: 'supplier',
          headerCellStyles: 'pl-0 pr-4 py-3',
          cellStyles: 'pl-0 pr-4 py-3',
          minWidth: 280,
          maxWidth: 300,
          render: (record) => (
            <SupplierSelectorButton
              product={record.product}
              prices={record.availableQuotes}
              supplier={record.supplier}
              lineId={record.id}
              refetch={refetch}
              image={record.image}
              cataloguedProductId={record?.cataloguedProductId}
              quantity={record?.quantity}
            />
          ),
        },
        {
          title: t('general.category', 'Category'),
          key: 'category',
          headerCellStyles: 'pl-0 pr-4 py-3',
          cellStyles: 'pl-0 pr-4 py-3',
          minWidth: 280,
          maxWidth: 300,
          render: (record) => <PRLineCategory requisitionLine={record} />,
        },
        {
          title: t('general.supplierCode', 'Supplier Code'),
          key: 'supplierCode',
          headerCellStyles: 'px-4 py-3 text-center',
          cellStyles: 'px-4 py-3 text-center',
          minWidth: 120,
          maxWidth: 120,
          render: (record) => <PRLineSupplierCode requisitionLine={record} />,
        },
        {
          title: t('general.purchaserCode', 'Purchaser Code'),
          key: 'purchaserCode',
          headerCellStyles: 'px-4 py-3 text-center',
          cellStyles: 'px-4 py-3 text-center',
          minWidth: 120,
          grow: true,
          render: (record) => <PRLinePurchaseCode requisitionLine={record} />,
        },
        {
          title: t('general.bestBuy', 'Best Buy'),
          key: 'bestBuy',
          headerCellStyles: 'px-4 py-3 text-center',
          cellStyles: 'px-4 py-3 text-center',
          minWidth: 70,
          maxWidth: 70,
          render: (record) => (
            <BestBuyButton
              livePremium={record.livePremium}
              cataloguedProductId={record.cataloguedProductId}
              isAddedToCart={isAddedToCart}
              product={record.product}
              prices={record.availableQuotes}
              supplier={record.supplier}
              lineId={record.id}
              image={record.image}
              quantity={record?.quantity}
            />
          ),
        },
        {
          title: t('general.quotes', 'Quotes'),
          key: 'quotes',
          headerCellStyles: 'px-4 py-3 text-center',
          cellStyles: 'px-4 py-3 text-center',
          minWidth: 70,
          maxWidth: 70,
          render: (record) => (
            <SupplierQuotesButton
              product={record?.product}
              prices={record?.availableQuotes}
              currentSupplierId={record?.supplier?.id}
              lineId={record?.id}
              image={record?.image}
              cataloguedProductId={record?.cataloguedProductId}
              quantity={record?.quantity}
            />
          ),
        },
        {
          title: t('general.unitPrice', 'Unit Price'),
          key: 'unitPrice',
          headerCellStyles: 'py-3 text-right',
          cellStyles: 'py-3 text-right',
          minWidth: 90,
          maxWidth: 90,
          render: (record) => <UnitPrice unitPrice={record?.unitPrice} />,
        },
        {
          title: t('general.tax%', 'Tax %'),
          key: 'taxPercentage',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 90,
          maxWidth: 90,
          render: (record) => <TaxPercentage taxPercentage={record?.taxPercentage} />,
        },
        {
          title: t('general.tax', 'Tax'),
          key: 'tax',
          headerCellStyles: 'py-3 px-4 text-right',
          cellStyles: 'py-3 px-4 text-right',
          minWidth: 90,
          maxWidth: 90,
          render: (record) => <PRLineTaxValue requisitionLine={record} />,
        },
        {
          title: t('general.quantity', 'Quantity'),
          key: 'quantity',
          headerCellStyles: 'px-4 py-3 text-center',
          cellStyles: 'px-4 py-3 text-center',
          minWidth: 90,
          maxWidth: 90,
          render: (record) => <PRLineQuantity requisitionLine={record} isAddedToCart={isAddedToCart} />,
        },
        {
          title: t('general.total', 'Total'),
          key: 'total',
          headerCellStyles: 'py-3 px-4 text-right',
          cellStyles: 'py-3 px-4 text-right',
          minWidth: 90,
          maxWidth: 90,
          render: (record) => <PRLineTotal requisitionLine={record} />,
        },
        {
          title: t('general.actions', 'Actions'),
          key: 'actions',
          headerCellStyles: 'px-4 py-3 text-center',
          cellStyles: 'px-4 py-3 text-center',
          minWidth: 120,
          maxWidth: 120,
          render: (record) => <PRLineActions requisitionLine={record} isAddedToCart={isAddedToCart} />,
        },
        {
          title: t('general.more', 'More'),
          key: 'more',
          headerCellStyles: 'py-3 px-4 text-center',
          cellStyles: 'py-3 px-4 text-center',
          minWidth: 70,
          maxWidth: 70,
          render: (record) => <PRLineMoreOption requisitionLine={record} />,
        },
      ]}
    />
  )
}
