import { NetworkStatus, useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import POReceivingNotesEmptyState from './POReceivingNotesEmptyState'
import TableView from './table-view'
import TileView from './tile-view'

import {
  GetPoReceivingNotesDocument,
  RansackDirection,
  ReceivingDocument,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { useCreateReceivingDocument } from '@/modules/purchase-orders/hooks'
import { Button, QueryResult } from '@/modules/shared/components'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { useWindowSize } from '@/modules/shared/hooks'
import { useMoney } from '@/modules/shared/hooks'
import { ControlBarIcon, TruckIcon } from '@/modules/shared/icons'
import { extractEdges } from '@/modules/shared/utils'

export default function ReceivingNotes() {
  const { purchaseOrderId } = useParams<{ purchaseOrderId: string }>()
  const { t } = useTranslation()
  const { formatMoney } = useMoney()
  const { isLargeScreen } = useWindowSize()

  const { onCreateReceivingDocument, createReceivingDocumentLoading, modalProps, InventoryModal } =
    useCreateReceivingDocument()

  const { data, error, networkStatus } = useQuery(GetPoReceivingNotesDocument, {
    variables: {
      purchaseOrderId: Number(purchaseOrderId),
      sort: [
        {
          direction: RansackDirection.Desc,
          property: 'workflowState',
        },
        {
          direction: RansackDirection.Desc,
          property: 'receivedDate',
        },
      ],
    },
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  })

  const receivingDocs = extractEdges<ReceivingDocument>(data?.currentPurchaser?.purchaseOrder?.receivingDocuments)

  return (
    <QueryResult
      loading={networkStatus === NetworkStatus.loading || networkStatus === NetworkStatus.refetch}
      error={error}
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <ControlBarIcon className=" h-10 w-10" />
          <p className="ml-3 text-sm">
            {data?.currentPurchaser?.purchaseOrder?.purchaseOrderNumber}{' '}
            {t('receivingNotes.receivingNotes', 'Receiving Notes')}
          </p>
        </div>
        <Button
          loading={createReceivingDocumentLoading}
          data-testid="receive-goods-btn"
          className="flex h-10 items-center gap-x-2 rounded-md bg-gray-200 px-4 text-sm"
          onClick={() => onCreateReceivingDocument(purchaseOrderId)}
        >
          {t('receivingNotes.receiveGoods', 'Receive Goods')}
          <TruckIcon className="h-6 w-6" />
        </Button>
      </div>
      <section className="mt-4 flex flex-col gap-y-3">
        {receivingDocs.length > 0 && (
          <>
            {isLargeScreen ? (
              <div className="hidden lg:flex lg:flex-col">
                <TableView receivingDocs={receivingDocs} />
              </div>
            ) : (
              <div className="flex flex-col">
                <TileView receivingDocs={receivingDocs} />
              </div>
            )}
            <section className="flex flex-col gap-3 rounded-md bg-white py-3 text-sm">
              <div className="flex justify-between px-4">
                <span className="text-gray-500">{t('receivingNotes.orderTotal', 'Ordered Total (Tax Exclusive)')}</span>
                <span>{formatMoney(data?.currentPurchaser?.purchaseOrder?.totalValueExcludingTax)}</span>
              </div>
              <div className="flex justify-between px-4">
                <span className="text-gray-500">
                  {t('receivingNotes.outstandingTotal', 'Outstanding Total (Tax Exclusive)')}
                </span>
                <span>{formatMoney(data?.currentPurchaser?.purchaseOrder?.outstandingTotal)}</span>
              </div>
              <hr />
              <div className="flex justify-between px-4">
                <strong>{t('receivingNotes.receivedTotal', 'Received Total (Tax Exclusive)')}</strong>
                <strong>{formatMoney(data?.currentPurchaser?.purchaseOrder?.receivedTotal)}</strong>
              </div>
            </section>
          </>
        )}
        {receivingDocs.length === 0 && (
          <POReceivingNotesEmptyState
            createReceivingDocument={() => onCreateReceivingDocument(purchaseOrderId)}
            createReceivingDocumentLoading={createReceivingDocumentLoading}
          />
        )}
      </section>
      <InventoryModal {...modalProps} purchaseOrderId={String(purchaseOrderId)} />
    </QueryResult>
  )
}
