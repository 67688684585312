import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, generatePath } from 'react-router-dom'

import { Requisition } from '@/graphql/purchasing/generated/purchasing_graphql'
import CancelRequisitionButton from '@/modules/requisitions/components/cancel-requisition-button/CancelRequisitionButton'
import { useCheckShoppingAvailable } from '@/modules/requisitions/hooks'
import { generateShopPath } from '@/modules/requisitions/utils'
import { Fallback, MoreOptionsMenu, PPNewDetailLink, Tooltip } from '@/modules/shared/components'
import Table from '@/modules/shared/components/table/Table'
import { useMoney } from '@/modules/shared/hooks'
import { OptionIcon, ShoppingCartIcon } from '@/modules/shared/icons'
import SubmitIcon from '@/modules/shared/icons/SubmitIcon'
import { Dates } from '@/modules/shared/types'
import { formatDate } from '@/modules/shared/utils'

interface TableViewProps {
  draftList: Requisition[]
}

function ListView(props: TableViewProps) {
  const { draftList } = props
  const { t } = useTranslation()
  const { formatMoney } = useMoney()
  const { ShoppingNotAvailableModal, modalProps, onCheckShoppingAvailable, onShowModal } = useCheckShoppingAvailable()

  const renderRequisition = ({ id, requisitionNumber }: Requisition) => {
    return (
      <PPNewDetailLink type="requisition" id={String(id)}>
        {requisitionNumber}
      </PPNewDetailLink>
    )
  }

  const renderReference = ({ reference }: Requisition) => {
    return <Fallback condition={!!reference}>{reference}</Fallback>
  }

  const renderDeptAndAccount = ({ department, account }: Requisition) => {
    return (
      <Fallback condition={!!department || !!account}>
        <p>{department?.name}</p>
        <p className="text-xs">{account?.accountName}</p>
      </Fallback>
    )
  }

  const renderExpectedDeliveryDate = ({ expectedDeliveryDate }: Requisition) => {
    return <Fallback condition={!!expectedDeliveryDate}>{formatDate(Dates.Short, expectedDeliveryDate)}</Fallback>
  }

  const renderTotalValue = ({ totalValue }: Requisition) => {
    return (
      <>
        <p className="text-base font-bold">{formatMoney(totalValue)}</p>
        <p className="text-xs text-gray-600">{t('requisition.details.taxInclusive', 'Tax Inclusive')}</p>
      </>
    )
  }

  const renderActions = (record: Requisition) => {
    const { id, advanced } = record
    return (
      <div className="flex items-center justify-center gap-x-1.5">
        <Tooltip content={t('requisition.details.tooltipActionResume', 'Resume Shopping')} delay={100}>
          <Link
            data-testid="resume-button"
            to={generateShopPath(id, advanced)}
            onClick={(e) => {
              if (!onCheckShoppingAvailable(record)) {
                e.preventDefault()
                onShowModal()
              }
            }}
            className="flex h-11 w-11 items-center justify-center rounded-full bg-gray-200 text-gray-600 transition hover:brightness-95"
          >
            <ShoppingCartIcon className="h-8 w-8" />
          </Link>
        </Tooltip>
        <Tooltip content={t('requisition.details.tooltipActionSubmit', 'Submit for Approval')} delay={100}>
          <Link
            data-testid="submit-button"
            to={generatePath('/requisitions/:id/submit', {
              id: String(id),
            })}
            className="flex h-11 w-11 items-center justify-center rounded-full bg-gray-200 text-gray-600 transition hover:brightness-95"
          >
            <SubmitIcon className="h-8 w-8" />
          </Link>
        </Tooltip>
        <CancelRequisitionButton requisitionId={id} />
        <ShoppingNotAvailableModal {...modalProps} />
      </div>
    )
  }

  const renderMoreOptions = ({ id }: Requisition) => {
    return (
      <MoreOptionsMenu showOpenInPPlus showPrint showHelp showViewRequisition requisitionId={id}>
        <OptionIcon className="h-8 w-8 text-gray-500" />
      </MoreOptionsMenu>
    )
  }

  return (
    <Table
      dataTestId="requisition-table-view"
      dataSource={draftList}
      keyExtractor={(record) => String(record.id)}
      columns={[
        {
          title: t('requisition.details.requisitionTableHeader', 'Requisition'),
          key: 'requisition',
          headerCellStyles: 'px-4 py-3',
          cellStyles: 'px-4 py-3',
          minWidth: 130,
          maxWidth: 130,
          render: renderRequisition,
        },
        {
          title: t('requisitions.details.reference', 'Reference'),
          key: 'reference',
          headerCellStyles: 'px-2 py-3',
          cellStyles: 'px-2 py-3 ',
          minWidth: 180,
          maxWidth: 220,
          render: renderReference,
        },
        {
          title: t('requisition.details.accountTableHeader', 'Account Code'),
          key: 'account',
          headerCellStyles: 'px-2 py-3',
          cellStyles: 'px-2 py-3 ',
          minWidth: 240,
          grow: true,
          render: renderDeptAndAccount,
        },
        {
          title: t('requisition.details.deliveryDate', 'Delivery Date'),
          key: 'deliveryDate',
          headerCellStyles: 'px-1 py-3 text-center',
          cellStyles: 'px-1 py-3 text-center',
          minWidth: 120,
          maxWidth: 120,
          render: renderExpectedDeliveryDate,
        },
        {
          title: t('requisition.details.totalValue', 'Total Value'),
          key: 'totalValue',
          headerCellStyles: 'p-3 text-right',
          cellStyles: 'p-3 text-right',
          minWidth: 110,
          maxWidth: 150,
          render: renderTotalValue,
        },
        {
          title: t('requisition.details.actionsTableHeader', 'Actions'),
          key: 'actions',
          headerCellStyles: 'py-3 text-center pl-4 pr-0',
          cellStyles: 'pl-4 pr-0 text-center',
          minWidth: 150,
          maxWidth: 150,
          render: renderActions,
        },
        {
          title: t('requisition.details.moreTableHeader', 'More'),
          key: 'more',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 70,
          maxWidth: 70,
          render: renderMoreOptions,
        },
      ]}
    />
  )
}

export default memo(ListView)
