import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import UserStatus from '../UserStatus'

import { User } from '@/graphql/access/generated/access_graphql'
import { MoreOptionsMenu, PPNewDetailLink, SimpleTileViewItem } from '@/modules/shared/components'
import { OptionIcon } from '@/modules/shared/icons'
import { formatDate } from '@/modules/shared/utils'

interface TileViewProps {
  users: User[]
}

function TileView(props: TileViewProps) {
  const { users } = props
  const { t } = useTranslation()

  return (
    <>
      {users.map((user) => {
        const { id, fullName, email, telephone, lastSignInAt, isDeleted } = user
        return (
          <div className="mb-6 rounded-md border bg-white" key={id} data-testid="user-tile-view">
            <div className="p-4">
              <span className="break-all text-base font-bold">{fullName}</span>
              <p className="text-sm text-gray-500">{email}</p>
            </div>
            <hr />
            <section className="p-4 text-sm" data-testid="user-details">
              <SimpleTileViewItem
                className="mb-3"
                title={t('systemSettings.details.emailAddress', 'Email Address')}
                value={email}
              />
              <SimpleTileViewItem
                className="mb-3"
                title={t('systemSettings.details.phoneNumber', 'Phone Number')}
                value={telephone}
              />
              <SimpleTileViewItem
                className="mb-3"
                title={t('systemSettings.details.lastSignIn', 'Last Sign In')}
                value={formatDate('DD MMMM YYYY h:mma', lastSignInAt)}
              />
              <SimpleTileViewItem
                className="mb-3"
                title={t('systemSettings.details.status', 'Status')}
                value={<UserStatus isDeleted={Boolean(isDeleted)} />}
              />
            </section>
            <hr />
            <div className="flex w-full items-center justify-between p-4">
              <PPNewDetailLink
                type="user"
                id={String(id)}
                className="flex h-11 items-center justify-center rounded-md bg-gray-200 p-4 text-center text-sm transition hover:brightness-95"
              >
                {t('systemSettings.viewUser', 'View User')}
              </PPNewDetailLink>

              <MoreOptionsMenu showOpenInPPlus showHelp accessUserId={id}>
                <OptionIcon className="h-10 w-10 text-gray-500" />
              </MoreOptionsMenu>
            </div>
          </div>
        )
      })}
    </>
  )
}

export default memo(TileView)
