import { Route, Routes } from 'react-router-dom'

import { AllUsers, UserDetails, UserPageHeader } from '@/modules/access/pages'
import { AllBuyLists, BuyListPageHeader, BuyListProducts, CreateBuyList } from '@/modules/buy-lists/pages'
import {
  AllSelfManaged,
  CreateCatalog,
  SelfManagedAuditLog,
  SelfManagedHeader,
  SelfManagedProductDetails,
  SelfManagedProductHeader,
  SelfManagedProducts,
} from '@/modules/catalogs/pages'
import { AllStockLocations } from '@/modules/inventory/pages'
import AllStocktakes from '@/modules/inventory/pages/stocktakes/pages/all-stocktakes/AllStocktakes'
import { StocktakeStockCount } from '@/modules/inventory/pages/stocktakes/pages/view-stocktake/StocktakeStockCount'
import ViewStocktake from '@/modules/inventory/pages/stocktakes/pages/view-stocktake/ViewStocktake'
import {
  AllInvoices,
  CreateInvoice,
  InvoiceAuditLog,
  Comments as InvoiceComments,
  InvoiceFlags,
  InvoicePageHeader,
  InvoicePurchaseOrder,
  InvoiceReceivingNotes,
  InvoiceSettings,
  InvoiceSummary,
} from '@/modules/invoices/pages'
import {
  AllPurchaseOrders,
  AllReceivingNotes,
  POAdvanced,
  POAuditLog,
  POComments,
  POInvoices,
  POPageHeader,
  POReceivingNote,
  POReceivingNoteDraft,
  POReceivingNotes,
  POSettings,
  POSummary,
} from '@/modules/purchase-orders/pages'
import ReceivingDocSuccessState from '@/modules/purchase-orders/pages/purchase-order/receiving-notes/ReceivingDocSuccessState'
import { Dashboard } from '@/modules/purchasing/pages'
import {
  AdvancedShop,
  AllRequisitions,
  ApproveRedirect,
  AwaitingMyApproval,
  CreateRequisition,
  Decline,
  MyDrafts,
  Shop,
  Submit,
  SuccessAction,
} from '@/modules/requisitions/pages'
import AdvancedShopLayout from '@/modules/requisitions/pages/advanced-shop/AdvancedShopLayout'
import {
  RequisitionAttachments,
  RequisitionAuditLog,
  RequisitionComments,
  RequisitionInvoices,
  RequisitionPageHeader,
  RequisitionPurchaseOrders,
} from '@/modules/requisitions/pages/requisition'
import { PageHeader, Setting, Summary } from '@/modules/requisitions/pages/view-cart'
import { AppLayout } from '@/modules/shared/layouts'
import { AdminCustomize, AdminHeader, NotFound, Root } from '@/modules/shared/pages'
import { MySuppliers, SupplierDirectory } from '@/modules/suppliers/pages'
import { SupplierDetails, SupplierPageHeader } from '@/modules/suppliers/pages/supplier'
import {
  SupplierPublicDetails,
  SupplierPublicPageHeader,
  SupplierPublicProducts,
} from '@/modules/suppliers/pages/supplier-public'
import { AdminRoute, PurchaserRoute } from '@/routes'

export default function PurchaserRoutes() {
  return (
    <Routes>
      <Route element={<PurchaserRoute />}>
        <Route path="/" element={<Root />} />
        <Route path="/requisitions/:requisitionId" element={<SuccessAction />} />
        <Route path="/requisitions/create" element={<CreateRequisition />} />
        <Route path="/requisitions/:requisitionId/submit" element={<Submit />} />
        <Route path="/requisitions/:requisitionId/decline" element={<Decline />} />
        <Route path="/requisitions/:requisitionId/approve" element={<ApproveRedirect />} />
        <Route path="/requisitions/:requisitionId/shop" element={<Shop />} />
        <Route path="/requisitions/:requisitionId/shop/advanced" element={<AdvancedShopLayout />}>
          <Route index element={<AdvancedShop />} />
          <Route path="settings" element={<Setting />} />
          <Route path="comments" element={<RequisitionComments />} />
          <Route path="attachments" element={<RequisitionAttachments />} />
        </Route>
        <Route path="/requisitions/:requisitionId/shop" element={<PageHeader />}>
          <Route path="summary" element={<Summary />} />
          <Route path="settings" element={<Setting />} />
          <Route path="comments" element={<RequisitionComments />} />
          <Route path="attachments" element={<RequisitionAttachments />} />
        </Route>
        <Route path="/buy-lists/create" element={<CreateBuyList />} />
        <Route path="/catalogs/create" element={<CreateCatalog />} />
        <Route path="/invoices/create" element={<CreateInvoice />} />
        <Route element={<AppLayout />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/requisitions" element={<AllRequisitions />} />
          <Route path="/requisitions/awaiting-my-approval" element={<AwaitingMyApproval />} />
          <Route path="/requisitions/my-drafts" element={<MyDrafts />} />
          <Route path="/requisitions/:requisitionId" element={<RequisitionPageHeader />}>
            <Route path="summary" element={<Summary />} />
            <Route path="settings" element={<Setting />} />
            <Route path="audit-log" element={<RequisitionAuditLog />} />
            <Route path="comments" element={<RequisitionComments />} />
            <Route path="purchase-orders" element={<RequisitionPurchaseOrders />} />
            <Route path="attachments" element={<RequisitionAttachments />} />
            <Route path="invoices" element={<RequisitionInvoices />} />
          </Route>
          <Route path="/my-suppliers" element={<MySuppliers />} />
          <Route path="/my-suppliers/:supplierId">
            <Route path="" element={<SupplierPageHeader />}>
              <Route path="supplier-details" element={<SupplierDetails />} />
            </Route>
          </Route>
          <Route path="/supplier-directory" element={<SupplierDirectory />} />
          <Route path="/supplier-directory/:supplierId">
            <Route path="" element={<SupplierPublicPageHeader />}>
              <Route path="supplier-details" element={<SupplierPublicDetails />} />
              <Route path="products" element={<SupplierPublicProducts />} />
            </Route>
          </Route>
          <Route path="/purchase-orders" element={<AllPurchaseOrders />} />
          <Route path="/purchase-orders/receiving-notes" element={<AllReceivingNotes />} />
          <Route path="/purchase-orders/:purchaseOrderId" element={<POPageHeader />}>
            <Route path="summary" element={<POSummary />} />
            <Route path="settings" element={<POSettings />} />
            <Route path="receiving-notes" element={<POReceivingNotes />} />
            <Route path="comments" element={<POComments />} />
            <Route path="invoices" element={<POInvoices />} />
            <Route path="advanced" element={<POAdvanced />} />
            <Route path="audit-log" element={<POAuditLog />} />
          </Route>
          <Route
            path="/purchase-orders/:purchaseOrderId/receiving-notes/:receivingNoteId"
            element={<POReceivingNote />}
          />
          <Route path="/invoices" element={<AllInvoices />} />
          <Route path="/invoices/:invoiceId" element={<InvoicePageHeader />}>
            <Route path="summary" element={<InvoiceSummary />} />
            <Route path="flags" element={<InvoiceFlags />} />
            <Route path="settings" element={<InvoiceSettings />} />
            <Route path="comments" element={<InvoiceComments />} />
            <Route path="purchase-order" element={<InvoicePurchaseOrder />} />
            <Route path="receiving-notes" element={<InvoiceReceivingNotes />} />
            <Route path="audit-log" element={<InvoiceAuditLog />} />
          </Route>
          <Route path="/buy-lists" element={<AllBuyLists />} />
          <Route path="/buy-lists/:buyListId" element={<BuyListPageHeader />}>
            <Route path="products" element={<BuyListProducts />} />
          </Route>
          <Route path="/catalogs/self-managed" element={<AllSelfManaged />} />
          <Route path="/catalogs/self-managed/:pricedCatalogueId" element={<SelfManagedHeader />}>
            <Route path="products" element={<SelfManagedProducts />} />
            <Route path="audit-log" element={<SelfManagedAuditLog />} />
          </Route>
          <Route
            path="/catalogs/self-managed/:pricedCatalogueId/products/:productId"
            element={<SelfManagedProductHeader />}
          >
            <Route path="product-details" element={<SelfManagedProductDetails />} />
          </Route>
          <Route path="/stock-locations" element={<AllStockLocations />} />
          <Route path="/stocktakes" element={<AllStocktakes />} />
          <Route path="/stocktakes/stock-location/:stockLocationId/stocktake/:stocktakeId" element={<ViewStocktake />}>
            <Route path="stock-count" element={<StocktakeStockCount />} />
          </Route>
          <Route path="/users" element={<AllUsers />} />
          <Route path="/users/:userId" element={<UserPageHeader />}>
            <Route path="details" element={<UserDetails />} />
          </Route>
          <Route element={<AdminRoute />}>
            <Route path="/admin/organisations/:organisationId" element={<AdminHeader />}>
              <Route path="customize" element={<AdminCustomize />} />
            </Route>
          </Route>
        </Route>
        <Route
          path="/purchase-orders/:purchaseOrderId/receiving-notes/:receivingNoteId/draft"
          element={<POReceivingNoteDraft />}
        />
        <Route
          path="/purchase-orders/:purchaseOrderId/receiving-notes/:receivingNoteId/received-success"
          element={<ReceivingDocSuccessState />}
        />
        <Route path="/stock-locations" element={<AllStockLocations />} />
        <Route path="/stocktakes" element={<AllStocktakes />} />
        <Route path="/stocktakes/stock-location/:stockLocationId/stocktake/:stocktakeId" element={<ViewStocktake />}>
          <Route path="stock-count" element={<StocktakeStockCount />} />
        </Route>
        <Route path="/users" element={<AllUsers />} />
        <Route path="/users/:userId" element={<UserPageHeader />}>
          <Route path="details" element={<UserDetails />} />
        </Route>
      </Route>
      <Route
        path="/purchase-orders/:purchaseOrderId/receiving-notes/:receivingNoteId/draft"
        element={<POReceivingNoteDraft />}
      />
      <Route
        path="/purchase-orders/:purchaseOrderId/receiving-notes/:receivingNoteId/received-success"
        element={<ReceivingDocSuccessState />}
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
