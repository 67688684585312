import { useMutation } from '@apollo/client'
import { generatePath, useNavigate } from 'react-router-dom'

import { CreateInvoiceDocument } from '@/graphql/purchasing/generated/purchasing_graphql'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'

export default function useCreateInvoice() {
  const navigate = useNavigate()

  const mutation = useMutation(CreateInvoiceDocument, {
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
    onCompleted(data) {
      navigate(
        generatePath('/invoices/:invoiceId/summary', {
          invoiceId: String(data?.createInvoice?.invoice?.id),
        })
      )
    },
  })

  return mutation
}
