import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { Activity, GetPricedCatalogueActivitiesDocument } from '@/graphql/purchasing/generated/purchasing_graphql'
import { QueryResult } from '@/modules/shared/components'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { AuditLogTable } from '@/modules/shared/layouts'
import { extractEdges } from '@/modules/shared/utils'

function SelfManagedAuditLog() {
  const { pricedCatalogueId } = useParams<{ pricedCatalogueId: string }>()
  const { loading, error, data } = useQuery(GetPricedCatalogueActivitiesDocument, {
    fetchPolicy: 'cache-and-network',
    context: { uri: PURCHASING_GRAPHQL_API },
    variables: {
      pricedCatalogueId: Number(pricedCatalogueId),
    },
  })

  const auditLogsList = extractEdges<Activity>(data?.currentPurchaser?.pricedCatalogue?.activities)

  return (
    <QueryResult loading={loading} error={error}>
      <div className="overflow-x-auto">
        {auditLogsList.length > 0 && <AuditLogTable auditLogsList={auditLogsList} />}
      </div>
    </QueryResult>
  )
}

export default SelfManagedAuditLog
