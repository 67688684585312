import { graphql } from 'msw'

import { GetPossibleRequisitionLinesQuery } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseGetPossibleRequisitionLines: GetPossibleRequisitionLinesQuery = {
  currentPurchaser: {
    id: 44926,
    requisition: {
      possibleRequisitionLines: {
        edges: [
          {
            node: {
              id: null,
              productId: 5814401,
              cataloguedProductId: 5841814,
              quantity: null,
              image: null,
              unitPrice: 11.99,
              taxPercentage: 10,
              supplierProductCode: '185139-EA',
              longDescription: null,
              product: {
                id: 5814401,
                category: {
                  id: 109680,
                  name: 'Pencils Chalk Crayons and Accessories',
                  parent: {
                    id: 109449,
                    name: 'Stationery',
                    __typename: 'Category',
                  },
                  __typename: 'Category',
                },
                brand: 'Alperstein',
                itemDescription: 'Apron Shield',
                concatenatedDescription: 'Apron Shield',
                concatenatedSellUnit: 'each of 1',
                itemPackName: null,
                itemMeasure: 'bunch',
                itemSize: 1,
                itemSellPackName: null,
                __typename: 'Product',
              },
              supplier: {
                id: 67087,
                name: 'AAPC Limited',
                billingAddressLine1: '548-572 Clayton Road',
                billingAddressLine2: '',
                billingAddressStateProvince: 'Victoria',
                billingAddressCity: 'Clayton',
                billingAddressCountry: 'AU',
                billingAddressPostalCode: '3168',
                telephone: '33000228231',
                email: 'ecommerce@bidvest.com.au',
                deliveryChargeConfiguration: null,
                __typename: 'Supplier',
              },
              availableQuotes: [
                {
                  unitPrice: 21.4,
                  taxPercentage: 0,
                  supplierName: 'In2Food (Operations) Pty Ltd - All States ',
                  supplierId: 4,
                  cataloguedProductId: 1,
                },
                {
                  unitPrice: 0.3,
                  taxPercentage: 0,
                  supplierName: 'Simon George & Sons Pty Ltd - Cairns',
                  supplierId: 5426,
                  cataloguedProductId: 2,
                },
                {
                  unitPrice: 26.35,
                  taxPercentage: 0,
                  supplierName: 'Sydney Wholesale Co',
                  supplierId: 15065,
                  cataloguedProductId: 3,
                },
                {
                  unitPrice: 26.35,
                  taxPercentage: 0,
                  supplierName: 'Local Supermarket',
                  supplierId: 15389,
                  cataloguedProductId: 4,
                },
              ],
              __typename: 'RequisitionLine',
            },
            __typename: 'PossibleRequisitionLinesEdge',
          },
        ],
        pageInfo: {
          endCursor: 'MQ',
          hasNextPage: false,
          __typename: 'PageInfo',
        },
        __typename: 'PossibleRequisitionLinesConnection',
      },
      id: 5235941,
      requisitionNumber: 'PR00040',
      reference: 'reference',
      totalTaxValue: 0,
      totalValueExcludingTax: 0,
      totalValue: 0,
      lines: [],
      __typename: 'Requisition',
    },
    __typename: 'Purchaser',
  },
}

export const GetPossibleRequisitionLines = graphql.query<GetPossibleRequisitionLinesQuery>(
  'GetPossibleRequisitionLines',
  (_, res, ctx) => {
    return res(ctx.data(baseGetPossibleRequisitionLines))
  }
)

export default baseGetPossibleRequisitionLines
