import { useMutation } from '@apollo/client'
import { Dispatch, SetStateAction } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  Account,
  DeliveryAddress,
  Purchaser,
  Requisition,
  UpdateRequisitionDocument,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { DeptAndAccount, SelectAddress, SendPurchaseOrder } from '@/modules/requisitions/pages/create/form-elements'
import { EditRequisitionModalEnum, RequisitionFormInputs } from '@/modules/requisitions/types'
import { Button, Modal } from '@/modules/shared/components'
import FormDate from '@/modules/shared/components/create-form/FormDate'
import FormInput from '@/modules/shared/components/create-form/FormInput'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'

interface UpdateModalProps {
  showModal: string | null
  setShowModal: Dispatch<SetStateAction<string | null>>
  requisition: Requisition
}

export default function UpdateModal({ showModal, setShowModal, requisition }: UpdateModalProps) {
  const { id, reference, expectedDeliveryDate, deliveryAddress, department, account, sendToSupplier } = requisition
  const { t } = useTranslation()
  const formMethods = useForm<RequisitionFormInputs>({
    defaultValues: {
      reference: String(reference),
      expectedDeliveryDate: expectedDeliveryDate,
      deliveryAddressId: deliveryAddress?.id,
      departmentId: department?.id,
      accountId: account?.id,
      sendToSupplier: Boolean(sendToSupplier),
    },
  })

  const [updateRequistion, { loading }] = useMutation(UpdateRequisitionDocument, {
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
  })

  const onSubmit: SubmitHandler<RequisitionFormInputs> = (data) => {
    updateRequistion({
      variables: {
        input: {
          id,
          ...data,
        },
      },
      onCompleted(data) {
        const requisition = data.updateRequisition.requisition
        formMethods.reset({
          reference: String(requisition?.reference),
          sendToSupplier: Boolean(requisition?.sendToSupplier),
          expectedDeliveryDate: String(requisition?.expectedDeliveryDate),
          deliveryAddressId: requisition?.deliveryAddress?.id,
          departmentId: requisition?.department?.id,
          accountId: requisition?.account?.id,
        })
        setShowModal(null)
      },
    })
  }

  /**
   * return the translated string from requisition.details.updateModal with the provided key
   *
   * @param {string} translationKey
   *
   * @returns {string}
   */
  const handleUpdateModalText = (translationKey: string) => {
    switch (translationKey) {
      case 'Reference':
        return t('requisition.details.updateModal.Reference', 'Edit Reference')
      case 'DeliveryAddress':
        return t('requisition.details.updateModal.DeliveryAddress', 'Edit Delivery Address')
      case 'DeptAndAcc':
        return t('requisition.details.updateModal.DeptAndAcc', 'Edit Department and Account Code')
      case 'ExpectedDeliveryDate':
        return t('requisition.details.updateModal.ExpectedDeliveryDate', 'Edit Delivery Date')
      default: // "SendToSupplier"
        return t('requisition.details.updateModal.SendToSupplier', 'Edit Send PO To Suppliers')
    }
  }

  return (
    <Modal showModal={Boolean(showModal)} onCloseModal={() => setShowModal(null)}>
      <Modal.Panel className="flex w-full flex-col overflow-hidden rounded-md bg-white text-sm shadow-xl transition-all md:max-w-[600px]">
        <Modal.Title
          title={showModal ? handleUpdateModalText(showModal) : ''}
          onCloseModal={() => setShowModal(null)}
          testId="update-modal-title"
        />

        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <Modal.Body className="pb-8">
            <FormProvider {...formMethods}>
              {showModal === EditRequisitionModalEnum.Reference && (
                <FormInput
                  title={t('createRequisition.reference.label', 'Reference')}
                  description={t(
                    'createRequisition.reference.explain',
                    'Please include a brief description of the Purchase Requisition. This reference will appear on all Purchase Orders that are created when this Requisition is submitted and fully approved. The reference will be visible to Suppliers and anyone at your organisation.'
                  )}
                  register="reference"
                  placeholder={t('createRequisition.reference.placeholder', 'E.g. Beverage Order')}
                />
              )}
              {showModal === EditRequisitionModalEnum.ExpectedDeliveryDate && (
                <FormDate
                  defaultDate={expectedDeliveryDate}
                  title={t('createRequisition.deliveryDate.title', 'Delivery Date')}
                  description={t(
                    'createRequisition.deliveryDate.explain',
                    "Please select the date you'd like to take delivery of this order. This date will be included on all Purchase Orders that are created when this Requisition is submitted and fully approved. The date will be visible to Suppliers and anyone at your organisation."
                  )}
                  register="expectedDeliveryDate"
                />
              )}
              {showModal === EditRequisitionModalEnum.DeliveryAddress && (
                <SelectAddress
                  defaultValue={deliveryAddress as DeliveryAddress}
                  title={t('createRequisition.address.title', 'Delivery Address')}
                  description={t(
                    'createRequisition.address.explain',
                    'Select a delivery address from the list below. Additional delivery addresses can be added by an administrator at your organisation. Please speak with your administrator to request additional delivery addresses.'
                  )}
                />
              )}
              {showModal === EditRequisitionModalEnum.DeptAndAcc && (
                <DeptAndAccount
                  defaultAccountValue={requisition?.account as Account}
                  defaultDepartmentValue={requisition?.department as Purchaser}
                  title={t('createRequisition.deptAndAccount.title', 'Department and Account Code')}
                  description={t(
                    'createRequisition.deptAndAccount.explain',
                    'If applicable, select a Department then an Account code to record this Requisition against. Your organisation may use Department and Account Codes for recording Requisition expenses.'
                  )}
                />
              )}
              {showModal === EditRequisitionModalEnum.SendToSupplier && (
                <SendPurchaseOrder
                  label={t('createRequisition.sendPO.label', 'Send Purchase Orders to Suppliers')}
                  defaultValue={Boolean(sendToSupplier)}
                  title={t('createRequisition.sendPO.title', 'Send Purchase Orders to Suppliers?')}
                  description={t(
                    'createRequisition.sendPO.explain',
                    "Decide if you'd like a Purchase Order to be sent to each Supplier once the Requisition has been fully approved, and the Purchase Orders are marked as Sent. If you leave this option un-checked, Purchase Orders will still be generated for each Supplier, but they will not be electronically sent to the Supplier, even when the Purchase Order is marked as Sent. There may be reasons why you don't need the Supplier to receive it, for example you may have phoned ahead and placed your order and you don't wish to send the Supplier a duplicate order."
                  )}
                />
              )}
              {showModal !== EditRequisitionModalEnum.Reference &&
                showModal !== EditRequisitionModalEnum.SendToSupplier && <div className="h-96" />}
            </FormProvider>
          </Modal.Body>
          <Modal.Footer className="flex flex-col-reverse items-center justify-end gap-2 md:flex-row">
            <Button
              type="button"
              className="h-11 w-full rounded-md bg-gray-200 px-8 text-sm md:w-fit"
              onClick={() => setShowModal(null)}
              data-testid="cancel-update-btn-bottom"
            >
              {t('shopPage.cart.settings.cancelModalBtn', 'Cancel')}
            </Button>
            <Button
              data-testid="save-changes-requisition-btn"
              type="submit"
              className="h-11 w-full rounded-md bg-primary px-5 text-sm text-white md:w-fit"
              loading={loading}
              disabled={!formMethods.formState.isDirty}
            >
              {t('shopPage.cart.settings.saveChangesModalBtn', 'Save Changes')}
            </Button>
          </Modal.Footer>
        </form>
      </Modal.Panel>
    </Modal>
  )
}
