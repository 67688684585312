import classNames from 'classnames'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, generatePath } from 'react-router-dom'

import RequisitionStatus from '../RequisitionStatus'

import { Requisition } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Fallback, MoreOptionsMenu, SimpleTileViewItem } from '@/modules/shared/components'
import { useMoney } from '@/modules/shared/hooks'
import { OptionIcon } from '@/modules/shared/icons'
import { Dates } from '@/modules/shared/types'
import { formatDate } from '@/modules/shared/utils'

interface TileViewProps {
  requisitions: Requisition[]
}

function TileView(props: TileViewProps) {
  const { requisitions } = props
  const { t } = useTranslation()
  const { formatMoney } = useMoney()

  return (
    <>
      {requisitions.map((requisition) => {
        const {
          id,
          reference,
          requisitionNumber,
          state,
          expectedDeliveryDate,
          sendToSupplier,
          totalValue,
          originName,
          account,
        } = requisition
        return (
          <div key={id} className="mb-6 rounded-md border bg-white">
            <div className="flex justify-between p-4">
              <div>
                <span className="break-all text-base font-bold">{reference || requisitionNumber}</span>
                <p className="text-sm text-gray-500">{requisitionNumber}</p>
              </div>
              <div>
                <div className="mt-0 text-right">
                  <span className="text-base font-bold">{`${formatMoney(totalValue)}`}</span>
                  <p className="text-sm text-gray-500">
                    {t('requisition.total', 'Total')} ({t('requisition.includingTax', 'Including Tax')})
                  </p>
                </div>
              </div>
            </div>
            <hr />
            <section data-testid="requisition-details" className="p-4 text-sm">
              <SimpleTileViewItem
                className="mb-3"
                title={t('requisition.details.requisitionType', 'Requisition Type')}
                value={originName}
              />
              <SimpleTileViewItem
                className="mb-3"
                title={t('requisition.details.status', 'Status')}
                value={<RequisitionStatus state={state || ''} requisitionId={id} />}
              />
              <SimpleTileViewItem
                className="mb-3"
                title={t('requisition.details.deliveryDate', 'Delivery Date')}
                value={
                  <Fallback condition={!!expectedDeliveryDate}>
                    {formatDate(Dates.Short, expectedDeliveryDate)}
                  </Fallback>
                }
              />
              <SimpleTileViewItem
                className="mb-3"
                title={t('requisition.details.sendPO', "Send PO's to Suppliers?")}
                value={`${
                  sendToSupplier ? t('requisition.details.sendPOYes', 'Yes') : t('requisition.details.sendPONo', 'No')
                }`}
              />
              <SimpleTileViewItem
                className="mb-3"
                title={t('requisition.details.accountCode', 'Account Code')}
                value={account?.accountName}
              />
            </section>

            <hr />
            <div className={classNames('flex w-full items-center justify-between p-4')}>
              <Link
                data-testid="view-requisition-link"
                to={generatePath('/requisitions/:id', {
                  id: id.toString(),
                })}
                className="flex h-12 items-center justify-center rounded-md bg-gray-200 p-4 text-center text-sm transition hover:brightness-95"
              >
                {t('requisition.viewRequisition', 'View Requisition')}
              </Link>
              <MoreOptionsMenu showOpenInPPlus showPrint showHelp showViewRequisition requisitionId={id}>
                <OptionIcon className="h-10 w-10 text-gray-500" />
              </MoreOptionsMenu>
            </div>
          </div>
        )
      })}
    </>
  )
}

export default memo(TileView)
