import { useLazyQuery, useQuery } from '@apollo/client'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { DelimitedNumericArrayParam, useQueryParam } from 'use-query-params'

import AdvancedSearchFilter from './AdvancedSearchFilter'
import FilterBySupplierModal from './FilterBySupplierModal'

import {
  GetRequisitionOriginTypeDocument,
  GetSupplierRelationshipsDocument,
  SupplierRelationship,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { OriginType } from '@/modules/requisitions/types'
import { Chip, FilterButton } from '@/modules/shared/components'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { useWindowSize } from '@/modules/shared/hooks'
import { ChevronDownIcon } from '@/modules/shared/icons'
import { QueryParameter } from '@/modules/shared/types'
import { extractEdges } from '@/modules/shared/utils'

interface SupplierFiltersSectionProps {
  shopPage?: boolean
}

export default function SupplierFiltersSection({ shopPage }: SupplierFiltersSectionProps) {
  const { t } = useTranslation()
  const { requisitionId } = useParams()
  const [suppliersURLParam, setSuppliersURLParam] = useQueryParam(QueryParameter.Suppliers, DelimitedNumericArrayParam)
  const [showFilterModal, setShowFilterModal] = useState<boolean>(false)
  const [showSuppliersChip, setShowSuppliersChip] = useState(false)
  const [originType, setOriginType] = useState<String>('')
  const { md, lg, twoXL } = useWindowSize()
  const [getSupplierChips, { data }] = useLazyQuery(GetSupplierRelationshipsDocument, {
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted() {
      setShowSuppliersChip(true)
    },
  })

  const { data: originTypeData } = useQuery(GetRequisitionOriginTypeDocument, {
    variables: {
      requisitionId: Number(requisitionId),
    },
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
  })

  useEffect(() => {
    if (originTypeData) {
      setOriginType(originTypeData?.currentPurchaser?.requisition?.originType || '')
    }
  }, [originTypeData])

  useEffect(() => {
    if (suppliersURLParam && suppliersURLParam.length > 0) {
      getSupplierChips({ variables: { filter: { q: [{ property: 'supplierId_in', value: suppliersURLParam }] } } })
    } else {
      setShowSuppliersChip(false)
    }
  }, [suppliersURLParam])

  return (
    <AdvancedSearchFilter
      buttonTestId="filters-section"
      buttonTitle={t('shopPage.productList.filterBySuppliers', 'Filter By Suppliers')}
    >
      <div className="flex flex-col gap-2 md:flex-row 2xl:flex-col">
        <FilterButton
          data-testid={`filter-by-supplier`}
          className={classNames('flex h-11 w-full items-center justify-between rounded-md border px-4', {
            'max-w-[250px]': (shopPage && !twoXL && md) || (!shopPage && lg),
          })}
          isActive={!!suppliersURLParam}
          disabled={originType === OriginType.SupplierRelationship}
          onClick={() => setShowFilterModal(true)}
        >
          <span className="text-sm">{t('shopPage.productList.supplierFilter.supplier', 'Supplier')}</span>
          <ChevronDownIcon className="h-5 w-5" />
        </FilterButton>
      </div>
      {showSuppliersChip && suppliersURLParam && (
        <div className="flex flex-wrap items-center gap-2 text-sm" data-testid="chips">
          {extractEdges<SupplierRelationship>(data?.currentPurchaser?.supplierRelationships).map((e) => (
            <Chip
              key={e.id}
              onRemoveChip={() => {
                setSuppliersURLParam((prevParams) => {
                  const newParams = prevParams?.filter((param) => param !== e.supplierId) || []
                  if (newParams.length > 0) return newParams
                  return undefined
                })
              }}
              label={String(e.supplierName)}
            />
          ))}
        </div>
      )}
      <FilterBySupplierModal showModal={showFilterModal} setShowModal={setShowFilterModal} />
    </AdvancedSearchFilter>
  )
}
