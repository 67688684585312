import { useQuery } from '@apollo/client'
import { useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { DeptAndAccount, SelectAddress, SendPurchaseOrder } from '../../create/form-elements'

import {
  Account,
  DeliveryAddress,
  GetRequisitionDocument,
  Purchaser,
  Requisition,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import useUpdateRequisition from '@/modules/requisitions/hooks/useUpdateRequisition'
import { REQUISITION_STATUS, RequisitionFormInputs } from '@/modules/requisitions/types'
import { formatStateForLocale } from '@/modules/requisitions/utils'
import { QueryResult, SimpleTableItemWithTooltip } from '@/modules/shared/components'
import FormDate from '@/modules/shared/components/create-form/FormDate'
import FormInput from '@/modules/shared/components/create-form/FormInput'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { useMoney } from '@/modules/shared/hooks'
import SaveFooter from '@/modules/suppliers/pages/supplier/supplier-details/form-elements/SaveFooter'

function Setting() {
  const { t } = useTranslation()
  const { requisitionId } = useParams<{ requisitionId: string }>()
  const [remount, setRemount] = useState(0)
  const [updateRequisition, { loading: updateLoading }] = useUpdateRequisition()
  const { formatMoney } = useMoney()

  const { data, loading, error } = useQuery(GetRequisitionDocument, {
    variables: {
      requisitionId: Number(requisitionId),
    },
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
  })

  const onSubmit: SubmitHandler<RequisitionFormInputs> = (data) => {
    updateRequisition({
      variables: {
        input: {
          id: Number(requisitionId),
          ...data,
        },
      },
      onCompleted: (data) => {
        formMethods.reset(getDefaultValues(data?.updateRequisition?.requisition as Requisition))
      },
    })
  }

  const {
    state,
    department,
    account,
    deliveryAddress,
    expectedDeliveryDate,
    sendToSupplier,
    requisitionNumber,
    totalValueExcludingTax,
    totalTaxValue,
    creator,
    approvalWorkflow,
    totalValue,
    originName,
  } = data?.currentPurchaser?.requisition || {}

  const getDefaultValues = (requisition: Requisition) => {
    return {
      accountId: requisition?.account?.id,
      departmentId: requisition?.department?.id,
      reference: String(requisition?.reference),
      deliveryAddressId: requisition?.deliveryAddress?.id,
      expectedDeliveryDate: requisition?.expectedDeliveryDate,
      sendToSupplier: Boolean(requisition?.sendToSupplier),
    }
  }

  const formMethods = useForm<RequisitionFormInputs>({
    defaultValues: getDefaultValues(data?.currentPurchaser?.requisition as Requisition),
  })

  const disabled = ![
    REQUISITION_STATUS.OPEN,
    REQUISITION_STATUS.DECLINED,
    REQUISITION_STATUS.AWAITING_APPROVAL,
  ].includes(state as REQUISITION_STATUS)

  const onDiscard = () => {
    formMethods.reset(getDefaultValues(data?.currentPurchaser?.requisition as Requisition))
    setRemount(remount + 1)
  }

  return (
    <QueryResult loading={loading} error={error}>
      {requisitionId && data?.currentPurchaser && (
        <section
          className="flex flex-col-reverse justify-between gap-10 xl:flex-row"
          data-testid="requisition-editable-settings"
        >
          <section className="mb-20 xl:w-[840px]">
            <p className="my-3 text-lg font-bold">{t('requisition.general', 'General')}</p>
            <hr />
            {state && disabled && (
              <p className="mt-8 rounded-md bg-gray-200 px-5 py-4 text-sm" data-testid="not-editable-message">
                {t(
                  'requisition.update.notEditableMessage',
                  "Changes can't be made to the Settings of a Requisition in a {{state}} state.",
                  {
                    state: formatStateForLocale(state),
                  }
                )}
              </p>
            )}
            <FormProvider {...formMethods}>
              <form key={remount} onSubmit={formMethods.handleSubmit(onSubmit)}>
                <FormInput
                  disabled={disabled}
                  title={t('createRequisition.reference.label', 'Reference')}
                  description={t(
                    'createRequisition.reference.explain',
                    'Please include a brief description of the Purchase Requisition. This reference will appear on all Purchase Orders that are created when this Requisition is submitted and fully approved. The reference will be visible to Suppliers and anyone at your organisation.'
                  )}
                  register="reference"
                  placeholder={t('createRequisition.reference.placeholder', 'E.g. Beverage Order')}
                />
                <SendPurchaseOrder
                  defaultValue={Boolean(sendToSupplier)}
                  disabled={disabled}
                  label={t('createRequisition.sendPO.label', 'Send Purchase Orders to Suppliers')}
                  title={t('createRequisition.sendPO.title', 'Send Purchase Orders to Suppliers?')}
                  description={t(
                    'createRequisition.sendPO.explain',
                    "Decide if you'd like a Purchase Order to be sent to each Supplier once the Requisition has been fully approved, and the Purchase Orders are marked as Sent. If you leave this option un-checked, Purchase Orders will still be generated for each Supplier, but they will not be electronically sent to the Supplier, even when the Purchase Order is marked as Sent. There may be reasons why you don't need the Supplier to receive it, for example you may have phoned ahead and placed your order and you don't wish to send the Supplier a duplicate order."
                  )}
                />
                <SelectAddress
                  defaultValue={deliveryAddress as DeliveryAddress}
                  disabled={disabled}
                  title={t('createRequisition.address.title', 'Delivery Address')}
                  description={t(
                    'createRequisition.address.explain',
                    'Select a delivery address from the list below. Additional delivery addresses can be added by an administrator at your organisation. Please speak with your administrator to request additional delivery addresses.'
                  )}
                />
                <FormDate
                  defaultDate={expectedDeliveryDate}
                  disabled={disabled}
                  title={t('createRequisition.deliveryDate.title', 'Delivery Date')}
                  description={t(
                    'createRequisition.deliveryDate.explain',
                    "Please select the date you'd like to take delivery of this order. This date will be included on all Purchase Orders that are created when this Requisition is submitted and fully approved. The date will be visible to Suppliers and anyone at your organisation."
                  )}
                  register="expectedDeliveryDate"
                />
                <DeptAndAccount
                  key={`${department}${account}`}
                  defaultDepartmentValue={department as Purchaser}
                  defaultAccountValue={account as Account}
                  disabled={disabled}
                  title={t('createRequisition.deptAndAccount.title', 'Department and Account Code')}
                  description={t(
                    'createRequisition.deptAndAccount.explain',
                    'If applicable, select a Department then an Account code to record this Requisition against. Your organisation may use Department and Account Codes for recording Requisition expenses.'
                  )}
                />
                <div className="h-96" />
                {formMethods.formState.isDirty && <SaveFooter onDiscardChanges={onDiscard} loading={updateLoading} />}
              </form>
            </FormProvider>
          </section>
          <section className="xl:w-96">
            <section className="divide-y rounded-md bg-white shadow-sm" data-testid="requisition-information">
              <h2 className="px-5 py-4 font-bold">
                {t('requisition.update.requisitionDetails', 'Requisition Details', 'Requisition Details')}
              </h2>
              <div className="flex flex-col gap-y-3 p-5 text-sm">
                <SimpleTableItemWithTooltip
                  title={t('requisition.update.requisitionNumber', 'Requisition Number', 'Requisition Number')}
                  value={requisitionNumber}
                />
                <SimpleTableItemWithTooltip
                  title={t('requisition.update.totalExTax', 'Total Excluding Tax', 'Total Excluding Tax')}
                  value={formatMoney(totalValueExcludingTax)}
                />
                <SimpleTableItemWithTooltip
                  title={t('requisition.update.tax', 'Tax', 'Tax')}
                  value={formatMoney(totalTaxValue)}
                />
                <SimpleTableItemWithTooltip
                  title={t('requisition.update.totalIncTax', 'Total Including Tax', 'Total Including Tax')}
                  value={formatMoney(totalValue)}
                />
                <SimpleTableItemWithTooltip
                  title={t('requisition.update.requisitionType', 'Requisition Type', 'Requisition Type')}
                  value={originName}
                />
                <SimpleTableItemWithTooltip
                  title={t('requisition.update.requestedBy', 'Requested By', 'Requested By')}
                  value={creator?.fullName}
                />
                <SimpleTableItemWithTooltip
                  title={t('requisition.update.approvalWorkflow', 'Approval Workflow', 'Approval Workflow')}
                  value={approvalWorkflow?.title}
                  tooltip={t(
                    'requisition.update.approvalWorkflowTooltip',
                    'The workflow of individual users who will be required to approve this Purchase Requisition. The workflow is determined by the Department and Account Code set on the Requisition.',
                    'The workflow of individual users who will be required to approve this Purchase Requisition. The workflow is determined by the Department and Account Code set on the Requisition.'
                  )}
                />
              </div>
            </section>
          </section>
        </section>
      )}
    </QueryResult>
  )
}

export default Setting
