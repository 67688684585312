import { memo } from 'react'

import ProductCardTileView from './product-card-tile-view'

import { RequisitionLine } from '@/graphql/purchasing/generated/purchasing_graphql'

interface TileViewProps {
  possibleRequisitionLines: RequisitionLine[]
  lines: RequisitionLine[]
}

function TileView(props: TileViewProps) {
  const { possibleRequisitionLines, lines } = props
  return (
    <div className="grid gap-2 md:grid-cols-2 lg:grid-cols-3" data-testid="basic-product-list">
      {possibleRequisitionLines.map((requisitionLine) => (
        <ProductCardTileView
          key={requisitionLine.cataloguedProductId}
          requisitionLine={requisitionLine}
          isAddedToCart={Boolean(
            lines.find((line) => line.cataloguedProductId === requisitionLine.cataloguedProductId)
          )}
        />
      ))}
    </div>
  )
}

export default memo(TileView)
