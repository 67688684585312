import { graphql } from 'msw'

import { GetAllInvoicesQuery } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseGetAllInvoices: GetAllInvoicesQuery = {
  currentPurchaser: {
    id: 44926,
    invoices: {
      edges: [
        {
          node: {
            id: 7524145,
            invoiceNumber: 'INV111',
            state: 'cancelled',
            reference: '',
            invoiceDate: '2022-09-15',
            attachmentUrl: 'https://purchaseplus.com',
            purchaseOrder: {
              id: 16566008,
              purchaseOrderNumber: 'PO03645',
              requisition: {
                id: 5270674,
                reference: '',
                __typename: 'Requisition',
              },
              __typename: 'PurchaseOrder',
            },
            supplier: {
              id: 168,
              name: 'Zeus Wholesale',
              __typename: 'Supplier',
            },
            department: {
              id: 100617,
              name: 'Accounts',
              __typename: 'Purchaser',
            },
            account: {
              id: 96388,
              accountName: '0060004 - Operating Account - 4',
              code: '0060004',
              __typename: 'Account',
            },
            totalTaxValue: 0.3,
            totalValue: 25.3,
            __typename: 'Invoice',
          },
          __typename: 'InvoiceEdge',
        },
        {
          node: {
            id: 75241433,
            invoiceNumber: 'INGR02316test-1',
            state: 'open',
            reference: 'test',
            invoiceDate: '2022-09-09',
            attachmentUrl: null,
            purchaseOrder: null,
            supplier: {
              id: 168,
              name: 'Zeus Wholesale',
              __typename: 'Supplier',
            },
            department: {
              id: 100617,
              name: 'Accounts',
              __typename: 'Purchaser',
            },
            account: {
              id: 97162,
              accountName: '9999911 - No. of Guest Arrivals',
              code: '9999911',
              __typename: 'Account',
            },
            totalTaxValue: 5.6,
            totalValue: 56,
            __typename: 'Invoice',
          },
          __typename: 'InvoiceEdge',
        },
        {
          node: {
            id: 7524138,
            invoiceNumber: 'INGR02384test',
            state: 'parked',
            reference: 'test- 12334',
            invoiceDate: '2022-09-01',
            attachmentUrl: null,
            purchaseOrder: null,
            supplier: {
              id: 168,
              name: 'Zeus Wholesale',
              __typename: 'Supplier',
            },
            department: {
              id: 85126,
              name: 'Food',
              __typename: 'Purchaser',
            },
            account: {
              id: 96388,
              accountName: '0060004 - Operating Account - 4',
              code: '0060004',
              __typename: 'Account',
            },
            totalTaxValue: 3.6,
            totalValue: 39.5,
            __typename: 'Invoice',
          },
          __typename: 'InvoiceEdge',
        },
        {
          node: {
            id: 7524140,
            invoiceNumber: 'INGR02385',
            state: 'processing',
            reference: 'test',
            invoiceDate: '2022-08-30',
            attachmentUrl: null,
            purchaseOrder: {
              id: 16566002,
              purchaseOrderNumber: 'PO03641',
              requisition: {
                id: 5270688,
                reference: 'test',
                __typename: 'Requisition',
              },
              __typename: 'PurchaseOrder',
            },
            supplier: {
              id: 5426,
              name: 'Simon George & Sons Pty Ltd - Cairns',
              __typename: 'Supplier',
            },
            department: {
              id: 85126,
              name: 'Food',
              __typename: 'Purchaser',
            },
            account: {
              id: 96388,
              accountName: '0060004 - Operating Account - 4',
              code: '0060004',
              __typename: 'Account',
            },
            totalTaxValue: 0,
            totalValue: 56,
            __typename: 'Invoice',
          },
          __typename: 'InvoiceEdge',
        },
        {
          node: {
            id: 7524137,
            invoiceNumber: 'INGR02316test',
            state: 'reconciled',
            reference: 'test',
            invoiceDate: '2022-08-19',
            attachmentUrl: null,
            purchaseOrder: {
              id: 16565952,
              purchaseOrderNumber: 'PO03595',
              requisition: {
                id: 5270601,
                reference: 'test',
                __typename: 'Requisition',
              },
              __typename: 'PurchaseOrder',
            },
            supplier: {
              id: 168,
              name: 'Zeus Wholesale',
              __typename: 'Supplier',
            },
            department: {
              id: 85126,
              name: 'Food',
              __typename: 'Purchaser',
            },
            account: {
              id: 96388,
              accountName: '0060004 - Operating Account - 4',
              code: '0060004',
              __typename: 'Account',
            },
            totalTaxValue: 0,
            totalValue: 0,
            __typename: 'Invoice',
          },
          __typename: 'InvoiceEdge',
        },
        {
          node: {
            id: 7524135,
            invoiceNumber: 'INGR02383test',
            state: 'reconciled',
            reference: 'Beer 333',
            invoiceDate: '2022-08-11',
            attachmentUrl: null,
            purchaseOrder: {
              id: 16565795,
              purchaseOrderNumber: 'PO03497',
              requisition: {
                id: 5270528,
                reference: 'Beer 333',
                __typename: 'Requisition',
              },
              __typename: 'PurchaseOrder',
            },
            supplier: {
              id: 5426,
              name: 'Simon George & Sons Pty Ltd - Cairns',
              __typename: 'Supplier',
            },
            department: {
              id: 100617,
              name: 'Accounts',
              __typename: 'Purchaser',
            },
            account: {
              id: 97162,
              accountName: '9999911 - No. of Guest Arrivals',
              code: '9999911',
              __typename: 'Account',
            },
            totalTaxValue: 0,
            totalValue: 4,
            __typename: 'Invoice',
          },
          __typename: 'InvoiceEdge',
        },
        {
          node: {
            id: 7524130,
            invoiceNumber: 'INVGR02376',
            state: 'cancelled',
            reference: 'test',
            invoiceDate: '2022-07-28',
            attachmentUrl: null,
            purchaseOrder: {
              id: 16565163,
              purchaseOrderNumber: 'PO03308',
              requisition: {
                id: 5270427,
                reference: 'test',
                __typename: 'Requisition',
              },
              __typename: 'PurchaseOrder',
            },
            supplier: {
              id: 734,
              name: 'Bidfood Australia Ltd - National',
              __typename: 'Supplier',
            },
            department: {
              id: 85127,
              name: 'Beverage',
              __typename: 'Purchaser',
            },
            account: {
              id: 96391,
              accountName: '0060007 - Operating Account - 7',
              code: '0060007',
              __typename: 'Account',
            },
            totalTaxValue: 0,
            totalValue: 24,
            __typename: 'Invoice',
          },
          __typename: 'InvoiceEdge',
        },
        {
          node: {
            id: 7524131,
            invoiceNumber: 'INGR02380',
            state: 'reconciled',
            reference: 'test',
            invoiceDate: '2022-07-28',
            attachmentUrl: null,
            purchaseOrder: {
              id: 16565191,
              purchaseOrderNumber: 'PO03312',
              requisition: {
                id: 5270432,
                reference: 'test',
                __typename: 'Requisition',
              },
              __typename: 'PurchaseOrder',
            },
            supplier: {
              id: 734,
              name: 'Bidfood Australia Ltd - National',
              __typename: 'Supplier',
            },
            department: {
              id: 85127,
              name: 'Beverage',
              __typename: 'Purchaser',
            },
            account: {
              id: 96391,
              accountName: '0060007 - Operating Account - 7',
              code: '0060007',
              __typename: 'Account',
            },
            totalTaxValue: 40.52,
            totalValue: 445.7,
            __typename: 'Invoice',
          },
          __typename: 'InvoiceEdge',
        },
        {
          node: {
            id: 7524126,
            invoiceNumber: 'INVPO03204',
            state: 'reconciled',
            reference: 'test',
            invoiceDate: '2022-07-13',
            attachmentUrl: null,
            purchaseOrder: {
              id: 16565021,
              purchaseOrderNumber: 'PO03240',
              requisition: {
                id: 5270307,
                reference: 'test',
                __typename: 'Requisition',
              },
              __typename: 'PurchaseOrder',
            },
            supplier: {
              id: 168,
              name: 'Zeus Wholesale',
              __typename: 'Supplier',
            },
            department: {
              id: 44926,
              name: 'Test Account',
              __typename: 'Purchaser',
            },
            account: {
              id: 96388,
              accountName: '0060004 - Operating Account - 4',
              code: '0060004',
              __typename: 'Account',
            },
            totalTaxValue: 5.5,
            totalValue: 60.5,
            __typename: 'Invoice',
          },
          __typename: 'InvoiceEdge',
        },
        {
          node: {
            id: 7524125,
            invoiceNumber: 'INVPO03239',
            state: 'flagged',
            reference: 'test',
            invoiceDate: '2022-07-13',
            attachmentUrl: null,
            purchaseOrder: {
              id: 16565020,
              purchaseOrderNumber: 'PO03239',
              requisition: {
                id: 5270306,
                reference: 'test',
                __typename: 'Requisition',
              },
              __typename: 'PurchaseOrder',
            },
            supplier: {
              id: 168,
              name: 'Zeus Wholesale',
              __typename: 'Supplier',
            },
            department: {
              id: 44926,
              name: 'Test Account',
              __typename: 'Purchaser',
            },
            account: {
              id: 96388,
              accountName: '0060004 - Operating Account - 4',
              code: '0060004',
              __typename: 'Account',
            },
            totalTaxValue: 5.48,
            totalValue: 60.28,
            __typename: 'Invoice',
          },
          __typename: 'InvoiceEdge',
        },
      ],
      pageInfo: {
        endCursor: 'MjU',
        hasNextPage: false,
        __typename: 'PageInfo',
      },
      __typename: 'InvoiceConnection',
    },
    __typename: 'Purchaser',
  },
}

export const GetAllInvoices = graphql.query<GetAllInvoicesQuery>('GetAllInvoices', (_, res, ctx) => {
  return res(ctx.data(baseGetAllInvoices))
})

export default baseGetAllInvoices
