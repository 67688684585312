import { useTranslation } from 'react-i18next'

import { useGetAdvancedPossibleRequisitionLines } from '../../hooks/useGetAdvancedPossibleRequisitionLines'
import AdvancedShopProductList from './AdvancedShopProductList'
import HideZeroQty from './HideZeroQty'

import { PageInfo, Requisition } from '@/graphql/purchasing/generated/purchasing_graphql'
import { QueryResult } from '@/modules/shared/components'
import SearchAndFilter from '@/modules/shared/components/search-input/SearchAndFilter'
import { useWindowSize } from '@/modules/shared/hooks'
import { ControlBarIcon } from '@/modules/shared/icons'

export default function AdvancedShop() {
  const {
    loadingStates,
    actions,
    error,
    requisition,
    advancedPossibleRequisitionLines,
    pageInfo,
    paginationResultsPerPage,
  } = useGetAdvancedPossibleRequisitionLines()
  const { t } = useTranslation()
  const { sm } = useWindowSize()

  const onPreviousPage = () => {
    const { startCursor } = pageInfo || {}
    actions.refetch({
      before: startCursor,
      after: null,
      last: paginationResultsPerPage,
      first: null,
    })
  }
  const onNextPage = () => {
    const { endCursor } = pageInfo || {}
    actions.refetch({
      after: endCursor,
      before: null,
      first: paginationResultsPerPage,
      last: null,
    })
  }

  const searchFilterPlaceholder = () => {
    if (sm) {
      return t('shopPage.productList.searchPlaceholder1', 'Search by Product Name, Brand')
    } else {
      // if screen size is less than Breakpoints.SM
      return t('shopPage.productList.searchPlaceholder2', 'Search')
    }
  }

  return (
    <>
      <main className="mx-auto w-full space-y-5 lg:max-w-[2200px]">
        <QueryResult loading={loadingStates.loading} error={error}>
          <section className="flex items-center justify-between">
            <div className="hidden items-center sm:flex">
              <ControlBarIcon className=" h-10 w-10" />
              <p className="ml-3 text-sm">
                {requisition?.requisitionNumber} {requisition?.reference}
              </p>
            </div>
            <div className="hidden md:flex">
              <HideZeroQty reverse />
            </div>
          </section>
          <SearchAndFilter
            className="w-full"
            testId="product-search-input"
            placeholder={searchFilterPlaceholder()}
            ariaLabel={t('shopPage.productList.ariaLabel', 'Searching for products')}
            onFilter={actions.onFilter}
          />
          <AdvancedShopProductList
            requisitionLines={advancedPossibleRequisitionLines}
            requisition={requisition as Requisition}
            paginationLoading={loadingStates.setVariablesLoading}
            pageInfo={pageInfo as PageInfo}
            onNextPage={onNextPage}
            onPreviousPage={onPreviousPage}
            refetch={actions.refetch}
            refetchLoading={loadingStates.refetchLoading}
          />
        </QueryResult>
      </main>
    </>
  )
}
