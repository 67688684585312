import { useQuery } from '@apollo/client'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, generatePath, matchPath, useLocation, useNavigate, useParams } from 'react-router-dom'

import { GetPricedCatalogueProductDocument } from '@/graphql/purchasing/generated/purchasing_graphql'
import { formatProductItem } from '@/modules/requisitions/utils'
import { Breadcrumb, QueryResult, ThemeWrapper } from '@/modules/shared/components'
import HeaderTabs, { TabItem } from '@/modules/shared/components/header-tabs'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { ControlBarIcon, HomeIcon } from '@/modules/shared/icons'
import { Themes } from '@/modules/shared/types'
import { findActiveRoute } from '@/modules/shared/utils'

export default function SelfManagedProductHeader() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { pricedCatalogueId, productId } = useParams<{ pricedCatalogueId: string; productId: string }>()

  const location = useLocation()

  const { data, loading, error } = useQuery(GetPricedCatalogueProductDocument, {
    variables: {
      pricedCatalogueId: Number(pricedCatalogueId),
      pricedCataloguedProductId: Number(productId),
    },
    context: { uri: PURCHASING_GRAPHQL_API },
  })

  const pricedCatalog = data?.currentPurchaser?.pricedCatalogue
  const { brand, itemDescription, itemMeasure, itemPackName, itemSize, concatenatedSellUnit } =
    pricedCatalog?.pricedCataloguedProduct?.product || {}

  const actionList: TabItem[] = [
    { target: 'product-details', label: t('catalogs.selfManagedCatalog.product.productDetails', 'Product Details') },
  ]

  const isRedirect = matchPath('/catalogs/self-managed/:pricedCatalogueId/products/:productId', location.pathname)

  useEffect(() => {
    if (isRedirect) {
      navigate(
        generatePath('/catalogs/self-managed/:pricedCatalogueId/products/:productId/product-details', {
          pricedCatalogueId: String(pricedCatalogueId),
          productId: String(productId),
        })
      )
    }
  }, [])

  return (
    <QueryResult loading={loading} error={error}>
      <div className="mx-auto max-w-[1400px] px-4">
        <Breadcrumb className="rounded-md bg-gray-200 px-3 py-2" data-testid="self-managed-catalog-product-breadcrumb">
          <Breadcrumb.Item icon={HomeIcon} href="/dashboard"></Breadcrumb.Item>
          <Breadcrumb.Item href="/catalogs/self-managed">{t('catalogs.catalogs', 'Catalogs')}</Breadcrumb.Item>
          <Breadcrumb.Item href="/catalogs/self-managed">{t('catalogs.selfManaged', 'Self-Managed')}</Breadcrumb.Item>
          <Breadcrumb.Item href={`/catalogs/self-managed/${pricedCatalog?.id}`}>{pricedCatalog?.title}</Breadcrumb.Item>
          <Breadcrumb.Item href="#">
            {brand} {itemDescription}
          </Breadcrumb.Item>
          <Breadcrumb.Item>{findActiveRoute(actionList, location.pathname)}</Breadcrumb.Item>
        </Breadcrumb>
        <section className="my-5 flex flex-col gap-y-2">
          <section className="flex justify-between rounded-md bg-white p-4 shadow-sm">
            <div className="flex items-center">
              <ThemeWrapper theme={Themes.Primary} className="flex h-14 w-14 items-center justify-center rounded-md ">
                <ControlBarIcon className="h-10 w-10 " />
              </ThemeWrapper>
              <span className="ml-3">
                <p className="font-bold">
                  {brand} {itemDescription}
                </p>
                <p className="text-sm text-gray-500">
                  {formatProductItem({
                    itemMeasure: itemMeasure,
                    itemPackName: itemPackName,
                    itemSize: itemSize,
                    itemSellUnit: concatenatedSellUnit,
                  })}
                </p>
              </span>
            </div>
          </section>
          <HeaderTabs actionList={actionList} />
        </section>
        <Outlet />
      </div>
    </QueryResult>
  )
}
