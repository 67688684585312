import { Trans, useTranslation } from 'react-i18next'

import InvoiceTotalsRow from './InvoiceTotalsRow'

export default function InvoiceTotals() {
  const { t } = useTranslation()
  return (
    <section className="mt-8">
      <h2 className="text-sm font-semibold">{t('createInvoice.invoiceTotals.title', 'Invoice Totals')}</h2>
      <p className="text-sm text-gray-500">
        <Trans i18nKey="createInvoice.invoiceTotals.explain">
          Please provide the Tax Exclusive Total of the Invoice, as well as the Total Tax. The Tax Inclusive total will
          be calculated from these two values. You must provide these values here when creating the Invoice, but you can
          update them if needed, once the Invoice has been created.
        </Trans>
      </p>
      <div className="mt-4 flex flex-col divide-y border bg-white">
        <InvoiceTotalsRow
          title={t('createInvoice.invoiceTotals.totalExTax', 'Total (Excluding Tax)')}
          tooltip={t(
            'createInvoice.invoiceTotal.totalExTaxToolTip',
            'The Total of the Invoice, excluding Tax. The Total Tax below will be added to this value to calculate the Total Including Tax.'
          )}
          register="totalExTax"
        />
        <InvoiceTotalsRow
          title={t('createInvoice.invoiceTotals.totalTax', 'Total Tax')}
          tooltip={t(
            'createInvoice.invoiceTotal.totalTaxToolTip',
            'The total Tax component of the Invoice. This will be added to the Ex Tax Total above.'
          )}
          register="totalTaxValue"
        />
        <InvoiceTotalsRow
          title={t('createInvoice.invoiceTotals.total', 'Total (Including Tax)')}
          tooltip={t(
            'createInvoice.invoiceTotal.totalToolTip',
            'The Total of the Invoice, including Tax. This will be calculated from the sum of the two values above.'
          )}
          register="totalValue"
          disabled
        />
      </div>
    </section>
  )
}
