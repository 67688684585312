import { useQuery } from '@apollo/client'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  DeliveryAddress,
  GetDeliveryAddressesDocument,
  RansackDirection,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { RequisitionFormInputs } from '@/modules/requisitions/types'
import { ComboboxServer } from '@/modules/shared/components'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'

interface SelectAddressProps {
  defaultValue?: DeliveryAddress
  showExplanation?: boolean
  disabled?: boolean
  title: string
  description: string
}

export default function SelectAddress(props: SelectAddressProps) {
  const { defaultValue, disabled, showExplanation = true, title, description } = props
  const {
    formState: { errors },
    control,
  } = useFormContext<RequisitionFormInputs>()

  const { t } = useTranslation()
  const {
    data: deliveryAddressesData,
    refetch,
    networkStatus,
    fetchMore,
  } = useQuery(GetDeliveryAddressesDocument, {
    variables: {
      after: null,
      filter: null,
      sort: [
        {
          property: 'locationName',
          direction: RansackDirection.Asc,
        },
      ],
      first: 25,
    },
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
    notifyOnNetworkStatusChange: true,
  })

  return (
    <section className="mt-8">
      {showExplanation && (
        <>
          <h2 className="text-sm font-semibold">{title}</h2>
          <p className="text-sm text-gray-500">{description}</p>
        </>
      )}
      <div className="mt-4">
        <Controller
          control={control}
          name="deliveryAddressId"
          rules={{ required: true }}
          render={({ field }) => (
            <ComboboxServer
              placeholder={t('createRequisition.address.placeholder', 'Select a Delivery Address...')}
              networkStatus={networkStatus}
              onDisplay={(e) => `${e.locationName} ${e.line1} ${e.line2} ${e.city} ${e.stateProvince} ${e.country}`}
              hasError={Boolean(errors.deliveryAddressId)}
              errorMessage={t('createRequisition.address.required', 'You must select a Delivery Address.')}
              keyExtractor={(e) => `${e.id}`}
              items={
                deliveryAddressesData?.currentPurchaser?.deliveryAddresses?.edges?.map(
                  (e) => e?.node
                ) as DeliveryAddress[]
              }
              onInputChange={(e) =>
                refetch({
                  filter: {
                    q: [
                      {
                        property: 'search_text_or_locationName_or_country_cont',
                        value: e,
                      },
                    ],
                  },
                })
              }
              onSelected={(e) => field.onChange(e.id)}
              onFetchMore={() =>
                fetchMore({
                  variables: {
                    after: deliveryAddressesData?.currentPurchaser?.deliveryAddresses?.pageInfo.endCursor,
                  },
                })
              }
              hasMore={Boolean(deliveryAddressesData?.currentPurchaser?.deliveryAddresses?.pageInfo.hasNextPage)}
              defaultValue={
                defaultValue || (deliveryAddressesData?.currentPurchaser?.defaultDeliveryAddress as DeliveryAddress)
              }
              disabled={disabled}
              testId="select-address"
            />
          )}
        />
      </div>
    </section>
  )
}
