import classNames from 'classnames'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import { useFindMatchRoutes } from '../../hooks'
import { SideBarContext } from '../app-layout'
import CreatePurchaseOrderModal from './CreatePurchaseOrderModal'
import SideNavLink from './SideNavLink'

import { useSignOut } from '@/modules/access/hooks'
import { useCurrentOrganisation } from '@/modules/purchasing/hooks'
import SwitchOrganisation from '@/modules/requisitions/layouts/header/switch-organisation'
import { Accordion, Button } from '@/modules/shared/components'
import { SUPPORT_LINK } from '@/modules/shared/constants'
import {
  ArrowsRightLeftIcon,
  BuyListIcon,
  CartIcon,
  CatalogIcon,
  GetHelpIcon,
  HomeIcon,
  InventoryIcon,
  InvoiceIcon,
  OrderIcon,
  PlusIcon,
  SettingsIcon,
  SignOutIcon,
  TruckIcon,
} from '@/modules/shared/icons'
import { Themes } from '@/modules/shared/types'

export default function SideNavigation() {
  const sideBar = useContext(SideBarContext)

  const { t } = useTranslation()

  const {
    requisitionRoutesMatch,
    purchaseOrdersRoutesMatch,
    invoiceRoutesMatch,
    buyListRoutesMatch,
    catalogRoutesMatch,
    supplierRoutesMatch,
    inventoryRoutesMatch,
    systemSettingRoutesMatch,
    accordionDefaultKey,
  } = useFindMatchRoutes()

  const [signOut, { loading: actionLoading }] = useSignOut()
  const [openSwitchOrg, setOpenSwitchOrg] = useState(false)
  const [openAccordionKey, setOpenAccordionKey] = useState<number | undefined>()
  const [showCreatePurchaseOrderModal, setShowCreatePurchaseOrderModal] = useState(false)
  const { currentOrganisation } = useCurrentOrganisation()
  const { featureFlags } = currentOrganisation || {}

  useEffect(() => {
    if (accordionDefaultKey) {
      setOpenAccordionKey(accordionDefaultKey)
    }
  }, [accordionDefaultKey])

  const changeOpenAccordionKey = (key: number | undefined) => {
    if (key === openAccordionKey) {
      setOpenAccordionKey(undefined)
    } else {
      setOpenAccordionKey(key)
    }
  }

  const onCloseDrawer = () => sideBar?.setDrawerOpen && sideBar.setDrawerOpen(false)

  return (
    <>
      <nav className="flex-1 pb-1 text-sm" data-testid="side-navigation-items">
        <section className="space-y-1.5">
          <NavLink
            data-testid="home-navigation-link"
            to="/dashboard"
            onClick={() => onCloseDrawer()}
            className={({ isActive }) => {
              return classNames(
                'flex h-11 items-center gap-x-2 rounded-md  px-3  transition duration-200 hover:brightness-95',
                {
                  'bg-primary text-white': isActive,
                  'bg-gray-200': !isActive,
                }
              )
            }}
          >
            <HomeIcon className="h-7 w-7" />
            {t('sideNavigation.home', 'Home')}
          </NavLink>
          <Accordion
            titlePrefixIcon={<CartIcon className="h-7 w-7" />}
            title={t('sideNavigation.requisitions', 'Requisitions')}
            buttonClassName="h-11 px-3"
            activeColor={requisitionRoutesMatch ? Themes.Primary : undefined}
            defaultOpen={openAccordionKey === 0}
            handleTriggerClick={() => changeOpenAccordionKey(0)}
            keepActiveColor
          >
            <SideNavLink
              onCloseDrawer={() => onCloseDrawer()}
              testId="requisition-navigation"
              sideNavLinkItems={[
                {
                  key: t('sideNavigation.createRequisition', 'New Requisition'),
                  target: '/requisitions/create',
                  icon: <PlusIcon className="h-6 w-6 text-gray-500" />,
                },
                {
                  key: t('sideNavigation.viewAll', 'View All'),
                  target: '/requisitions',
                },
                {
                  key: t('sideNavigation.awaitingMyApproval', 'Awaiting My Approval'),
                  target: '/requisitions/awaiting-my-approval',
                },
                {
                  key: t('sideNavigation.myDrafts', 'My Drafts'),
                  target: '/requisitions/my-drafts',
                },
              ]}
            />
          </Accordion>
          <Accordion
            titlePrefixIcon={<OrderIcon className="h-7 w-7" />}
            title={t('sideNavigation.purchaseOrders', 'Purchase Orders')}
            buttonClassName="h-11 px-3"
            activeColor={purchaseOrdersRoutesMatch ? Themes.Primary : undefined}
            defaultOpen={openAccordionKey === 1}
            handleTriggerClick={() => changeOpenAccordionKey(1)}
            keepActiveColor
          >
            <SideNavLink
              onCloseDrawer={() => onCloseDrawer()}
              testId="purchaseorder-navigation"
              sideNavLinkItems={[
                {
                  key: t('sideNavigation.newPurchaseOrder', 'New Purchase Order'),
                  icon: <PlusIcon className="h-6 w-6 text-gray-500" />,
                  onClick: () => setShowCreatePurchaseOrderModal(true),
                },
                { key: t('sideNavigation.viewAll', 'View All'), target: '/purchase-orders' },
                {
                  key: t('sideNavigation.receivingNotes', 'Receiving Notes'),
                  target: '/purchase-orders/receiving-notes',
                },
              ]}
            />
          </Accordion>
          <Accordion
            titlePrefixIcon={<InvoiceIcon className="h-7 w-7" />}
            title={t('sideNavigation.invoices', 'Invoices')}
            activeColor={invoiceRoutesMatch ? Themes.Primary : undefined}
            defaultOpen={openAccordionKey === 2}
            handleTriggerClick={() => changeOpenAccordionKey(2)}
            buttonClassName="h-11 px-3"
            keepActiveColor
          >
            <SideNavLink
              onCloseDrawer={() => onCloseDrawer()}
              testId="invoice-navigation"
              sideNavLinkItems={[
                {
                  key: t('sideNavigation.createInvoice', 'New Invoice'),
                  target: '/invoices/create',
                  icon: <PlusIcon className="h-6 w-6 text-gray-500" />,
                },
                {
                  key: t('sideNavigation.viewAll', 'View All'),
                  target: '/invoices',
                },
              ]}
            />
          </Accordion>
          <Accordion
            titlePrefixIcon={<BuyListIcon className="h-7 w-7" />}
            title={t('sideNavigation.buyLists', 'Buy Lists')}
            activeColor={buyListRoutesMatch ? Themes.Primary : undefined}
            defaultOpen={openAccordionKey === 3}
            handleTriggerClick={() => changeOpenAccordionKey(3)}
            buttonClassName="h-11 px-3"
            keepActiveColor
          >
            <SideNavLink
              onCloseDrawer={() => onCloseDrawer()}
              testId="buyList-navigation"
              sideNavLinkItems={[
                {
                  key: t('sideNavigation.createBuyList', 'New Buy List'),
                  target: '/buy-lists/create',
                  icon: <PlusIcon className="h-6 w-6 text-gray-500" />,
                },
                {
                  key: t('sideNavigation.viewAll', 'View All'),
                  target: '/buy-lists',
                },
              ]}
            />
          </Accordion>
          <Accordion
            titlePrefixIcon={<CatalogIcon className="h-7 w-7" />}
            title={t('catalogs.catalogs', 'Catalogs')}
            activeColor={catalogRoutesMatch ? Themes.Primary : undefined}
            defaultOpen={openAccordionKey === 4}
            handleTriggerClick={() => changeOpenAccordionKey(4)}
            buttonClassName="h-11 px-3"
            keepActiveColor
          >
            <SideNavLink
              onCloseDrawer={() => onCloseDrawer()}
              testId="catalogs-navigation"
              sideNavLinkItems={[
                {
                  key: t('sideNavigation.createCatalog', 'New Catalog'),
                  target: '/catalogs/create',
                  icon: <PlusIcon className="h-6 w-6 text-gray-500" />,
                },
                {
                  key: t('catalogs.selfManaged', 'Self-Managed'),
                  target: '/catalogs/self-managed',
                },
              ]}
            />
          </Accordion>
          <Accordion
            titlePrefixIcon={<TruckIcon className="h-7 w-7" />}
            title={t('sideNavigation.suppliers', 'Suppliers')}
            activeColor={supplierRoutesMatch ? Themes.Primary : undefined}
            defaultOpen={openAccordionKey === 5}
            handleTriggerClick={() => changeOpenAccordionKey(5)}
            buttonClassName="h-11 px-3"
            keepActiveColor
          >
            <SideNavLink
              onCloseDrawer={() => onCloseDrawer()}
              testId="suppliers-navigation"
              sideNavLinkItems={[
                {
                  key: t('sideNavigation.mySuppliers', 'My Suppliers'),
                  target: '/my-suppliers',
                },
                {
                  key: t('sideNavigation.supplierDirectory', 'Supplier Directory'),
                  target: '/supplier-directory',
                },
              ]}
            />
          </Accordion>
          {featureFlags?.inventory && (
            <Accordion
              titlePrefixIcon={<InventoryIcon className="h-7 w-7" />}
              title={t('sideNavigation.inventory', 'Inventory')}
              activeColor={inventoryRoutesMatch ? Themes.Primary : undefined}
              defaultOpen={openAccordionKey === 6}
              handleTriggerClick={() => changeOpenAccordionKey(6)}
              buttonClassName="h-11 px-3"
              keepActiveColor
            >
              <SideNavLink
                onCloseDrawer={() => onCloseDrawer()}
                testId="inventory-navigation"
                sideNavLinkItems={[
                  {
                    key: t('sideNavigation.stockLocations', 'Stock Locations'),
                    target: '/stock-locations',
                  },
                  {
                    key: t('sideNavigation.stocktakes', 'Stocktakes'),
                    target: '/stocktakes',
                  },
                ]}
              />
            </Accordion>
          )}
        </section>

        <section className="mt-7 space-y-1.5">
          <h1 className="mb-2 px-3 text-sm text-gray-500">{t('sideNavigation.account', 'Account')}</h1>
          <a
            data-testid="view-support-link"
            href={SUPPORT_LINK}
            target="_blank"
            rel="noreferrer"
            className="flex h-11 cursor-pointer items-center gap-x-2 rounded-md bg-gray-200 px-3  hover:brightness-95"
          >
            <GetHelpIcon className="h-7 w-7" />
            {t('sideNavigation.helpCenter', 'Help Center')}
          </a>
          <Accordion
            titlePrefixIcon={<SettingsIcon className="h-7 w-7" />}
            title={t('sideNavigation.systemSettings', 'System Settings')}
            activeColor={systemSettingRoutesMatch ? Themes.Primary : undefined}
            defaultOpen={openAccordionKey === 7}
            handleTriggerClick={() => changeOpenAccordionKey(7)}
            buttonClassName="h-11 px-3"
            keepActiveColor
          >
            <SideNavLink
              onCloseDrawer={() => onCloseDrawer()}
              testId="systemSettings-navigation"
              sideNavLinkItems={[
                {
                  key: t('sideNavigation.users', 'Users'),
                  target: '/users',
                },
              ]}
            />
          </Accordion>
          <Button
            data-testid="switch-org-btn"
            className="flex h-11 w-full items-center gap-x-2 rounded-md bg-gray-200 px-3 "
            onClick={() => setOpenSwitchOrg(true)}
          >
            <ArrowsRightLeftIcon className=" h-7 w-7" />
            {t('sideNavigation.switchOrganisation', 'Switch Organisation')}
          </Button>
          <Button
            data-testid="signout-btn"
            loading={actionLoading}
            className="flex h-11 w-full items-center gap-x-2 rounded-md bg-gray-200 px-3 "
            onClick={() => signOut()}
          >
            <SignOutIcon className=" h-7 w-7" />
            {t('sideNavigation.signOut', 'Sign Out')}
          </Button>
        </section>
      </nav>
      <SwitchOrganisation isOpen={openSwitchOrg} setIsOpen={setOpenSwitchOrg} />
      <CreatePurchaseOrderModal
        showModal={showCreatePurchaseOrderModal}
        setShowModal={setShowCreatePurchaseOrderModal}
      />
    </>
  )
}
